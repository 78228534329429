import { Box, Stack, Typography } from "@mui/material";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useInvoices } from "../../../../../../../providers/InvoicesProvider";
import { COLORS } from "../../../../../../../theme";
import { formattedAmount } from "../../../../../../../utils/common";

const TotalOfInvoice = () => {
  const { selectedInvoice, detailsLoading, proceedToPayment } = useInvoices();

  const renderText = (title: string, text: string) => {
    return (
      <Stack gap={"12px"}>
        <AtsTitleText text={title} fs={14} fw={600} />
        <AtsTitleText text={text} fs={14} fw={400} loading={detailsLoading} />
      </Stack>
    );
  };

  const renderTotalText = (
    title: string,
    text: string,
    color: string,
    isBold?: boolean
  ) => {
    return (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <AtsTitleText
          text={title}
          fs={isBold ? 16 : 14}
          fw={isBold ? 600 : 400}
        />
        <Stack textAlign={"end"}>
          <AtsTitleText
            text={text}
            fs={isBold ? 16 : 14}
            fw={isBold ? 600 : 400}
          />
        </Stack>
      </Stack>
    );
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
        flexWrap={"wrap"}
      >
        <Stack gap={"30px"} maxWidth={474}>
          {renderText(
            "Message",
            "Thanks for your business. Please find attached the invoice for our services."
          )}
          {renderText("Notes", "Thank you for your continued partnership.")}
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: COLORS.BLACK,
            }}
          >
            If you have any questions or need further assistance, please do not
            hesitate to contact us at{" "}
            <a
              target="_blank"
              href="https://www.finance@esteemed.io"
              style={{
                color: COLORS.PRIMARY,
                textDecoration: "none",
              }}
              rel="noreferrer"
            >
              finance@esteemed.io.
            </a>
          </Typography>
        </Stack>
        <Box
          sx={{
            // py: { xs: 2, md: "28px" },
            // px: { xs: 2, md: "24px" },
            minWidth: 335,
          }}
        >
          {/* <Stack
            gap={"20px"}
            sx={{
              pb: "25px",
              borderBottom: "1px solid",
            }}
          >
            {renderTotalText(
              "Subtotal",
              `$ ${formattedAmount(selectedInvoice?.amount)}`,
              COLORS.BLACK
            )}
          </Stack> */}
          <Stack
            py={"23px"}
            sx={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
            }}
          >
            {renderTotalText(
              "Total",
              `$ ${formattedAmount(Number(selectedInvoice?.amount))}`,
              "#000",
              true
            )}
          </Stack>
          {selectedInvoice?.status === "Not paid" && (
            <Box mt={"32px"}>
              <AtsRoundedButton
                text="Proceed to Payment"
                buttonWidth={"100%"}
                onClick={proceedToPayment}
              />
            </Box>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default TotalOfInvoice;
