import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import {
  BackwordIcon,
  ForwordIcon,
  TaskCollapseIcon,
} from "../../../assets/icons/Icons";
import AtsTitleText from "../../../components/AtsTitleText";
import TaskSidebar from "./components/TaskSidebar";

const Taskbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <motion.div
        animate={{
          width: isOpen ? (!isLargeXl && isLarge ? "306px" : "300px") : "65px",
          transition: {
            duration: 0.3,
          },
        }}
        className={`taskbar`}
      >
        <div className="toggle_icon2" onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <ForwordIcon /> : <BackwordIcon />}
        </div>

        {isOpen ? (
          <TaskSidebar isOpen={isOpen} />
        ) : (
          <Box
            pl={1}
            sx={{
              pr: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            pt={7}
          >
            <TaskCollapseIcon />
            <Stack
              sx={{
                pt: 0.5,
              }}
            >
              <AtsTitleText fs={14} fw={600} text="Tasks" />
            </Stack>
          </Box>
        )}
      </motion.div>
    </>
  );
};

export default Taskbar;
