import React, { useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../assets/styles/dateRangePicker.css";
import moment from "moment";

interface IProps {
  children: JSX.Element;
  handleEvent: (event: any, picker: any) => void;
  handleClear?: () => void;
  minDate?: string | Date;
  startDate?: string;
  endDate?: string;
}

const AtsDateRangePicker = ({
  children,
  handleClear,
  handleEvent,
  minDate,
  startDate = new Date().toString(),
  endDate = new Date().toString(),
}: IProps) => {
  const pickerRef = useRef(null);
  const onClear = (evnet, picker) => {
    handleClear && handleClear();
    if (pickerRef.current) {
      pickerRef.current.setStartDate(moment().startOf("day"));
      pickerRef.current.setEndDate(moment().startOf("day"));
    }
  };
  return (
    <>
      <DateRangePicker
        initialSettings={{
          locale: {
            daysOfWeek: moment.weekdaysShort(),
          },

          startDate: moment(startDate).startOf("day"),
          endDate: moment(endDate).startOf("day"),
          minDate: minDate ? moment(minDate) : undefined,
        }}
        onApply={handleEvent}
        onCancel={onClear}
      >
        {children}
      </DateRangePicker>
    </>
  );
};

export default AtsDateRangePicker;
