import { Box, Grid, Stack } from "@mui/material";
import { Add, Edit2 } from "iconsax-react";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import BillingInfo from "../components/BillingInfo";
import PaymentMethod from "../components/PaymentMethod";
import { useState } from "react";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { billingInfoValidation } from "../../../utils/validationUtils";
import { BillingServices } from "../../../Api/billingServices";
import toast from "react-hot-toast";
import { CreateUpdateBillingInfoRequest } from "../../../types/billingTypes";

const BillingPaymentDetails = () => {
  const {
    billingInfoState,
    isBillingInfo,
    searchQueryForBilling,
    getBillingInfo,
  } = useCreateAccount();
  console.log("isBillingInfo", isBillingInfo);
  const [isEdit, setIsEdit] = useState(false);
  const [bilingDetailsError, setBilingDetailsError] = useState<any>({});
  const [billingUpdateLoading, setBillingUpdateLoading] =
    useState<boolean>(false);

  const submitBillingDetails = async () => {
    const errorObj = billingInfoValidation(
      billingInfoState,
      searchQueryForBilling
    );
    setBilingDetailsError(errorObj.error);
    if (errorObj.isValid) {
      setBillingUpdateLoading(true);
      try {
        if (isBillingInfo) {
          const body = {
            ...billingInfoState,
            full_name: `${billingInfoState.first_name} ${billingInfoState.last_name}`,
          } as CreateUpdateBillingInfoRequest;
          await BillingServices.updateBillingInfo(body);
          toast.success("Billing info has been updated");
        } else {
          const body = {
            ...billingInfoState,
            full_name: `${billingInfoState.first_name} ${billingInfoState.last_name}`,
          } as CreateUpdateBillingInfoRequest;
          console.log("body", body);
          await BillingServices.createBillingInfo(body);
          toast.success("Billing info has been created");
        }
        getBillingInfo();
        setIsEdit(false);
      } catch (error) {
        console.error(error);
      } finally {
        setBillingUpdateLoading(false);
      }
    }
  };
  return (
    <>
      <Stack gap={"16px"}>
        <AtsTitleText
          fs={18}
          fw={600}
          textColor={"#000"}
          text={"Billing and Payment Details"}
        />
        <Grid container spacing={"20px"}>
          <Grid item xs={12} sm={6}>
            <BoxWrapper
              title={"Billing information"}
              renderButtons={() => {
                if (isEdit) {
                  return (
                    <AtsRoundedButton
                      text="Save and Update"
                      buttonHeight={30}
                      buttonWidth={185}
                      disabled={billingUpdateLoading}
                      loading={billingUpdateLoading}
                      onClick={async () => {
                        await submitBillingDetails();
                        // setIsEdit(false);
                      }}
                    />
                  );
                } else {
                  return (
                    <AtsRoundedButton
                      text="Update information"
                      buttonVariant="outline"
                      buttonHeight={30}
                      buttonWidth={185}
                      startIcon={<Edit2 size="14" color={COLORS.PRIMARY} />}
                      onClick={() => setIsEdit(true)}
                    />
                  );
                }
              }}
            >
              <Box pl={"10px"}>
                <BillingInfo
                  isEdit={isEdit}
                  bilingDetailsError={bilingDetailsError}
                />
              </Box>
            </BoxWrapper>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <BoxWrapper
              title={"Payment Method"}
              renderButtons={() => (
                <AtsRoundedButton
                  text="Add payment method"
                  buttonVariant="outline"
                  buttonHeight={30}
                  buttonWidth={203}
                  startIcon={<Add size="18" color={COLORS.PRIMARY} />}
                />
              )}
            >
              <>
                <PaymentMethod />
              </>
            </BoxWrapper>
          </Grid> */}
        </Grid>
      </Stack>
    </>
  );
};

export default BillingPaymentDetails;

const BoxWrapper = ({ title, children, renderButtons }) => {
  return (
    <Box
      sx={{
        padding: "24px 35px 30px 20px",

        border: BORDERS.GRAY,
        height: "430px",
        borderRadius: "6px",
      }}
    >
      <Stack pb={"23px"} pl={"10px"}>
        <AtsTitleText fs={16} fw={600} text={title} />
      </Stack>
      <Box
        sx={{
          height: "calc(100% - 73px)",
        }}
      >
        {children}
      </Box>
      <Box>{renderButtons()}</Box>
    </Box>
  );
};
