import { Close } from "@mui/icons-material";
import { Box, Chip, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { BG_COLORS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import { emailValidation } from "../../../utils/common";
import {
  getInviteMembersState,
  InviteMemberProps,
  setInviteError,
  setInviteMember,
} from "../../../redux/slices/inviteMembersSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { inviteMemberValidation } from "../../../utils/validationUtils";

const EmailTo = () => {
  const dispatch = useAppDispatch();
  const { inviteMember, inviteError } = useAppSelector(getInviteMembersState);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSelect = (emails: string[]) => {
    const updateInviteMember = { ...inviteMember, emails } as InviteMemberProps;
    dispatch(setInviteMember(updateInviteMember));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      if (emailValidation(inputValue)) {
        const updateEmail = [...inviteMember.emails, inputValue.trim()];
        handleSelect(updateEmail);
        dispatch(setInviteError({}));
      } else {
        dispatch(
          setInviteError({
            ...inviteError,
            emails: "Please enter valid email address",
          })
        );
      }
      setInputValue("");
    }
  };

  const handleDelete = (chipToDelete) => () => {
    const deletedEmails = inviteMember.emails.filter(
      (chip) => chip !== chipToDelete
    );
    handleSelect(deletedEmails);
  };
  return (
    <Stack gap={"6px"}>
      <AtsTitleText text={"To"} fs={14} fw={600} textColor={COLORS.BLACK}>
        <span
          style={{
            color: "red",
          }}
        >
          *
        </span>
      </AtsTitleText>
      <AtsTitleText
        text={"Separate each email address with a comma"}
        fs={12}
        fw={400}
        textColor={COLORS.BLACK}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          border: "1px solid #c4c4c4",
          py: inviteMember.emails.length > 0 ? 2 : 1,
          borderRadius: "8px",
          px: 2,
        }}
      >
        {inviteMember.emails.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleDelete(chip)}
            sx={{
              margin: "4px",
              bgcolor: BG_COLORS.PRIMARY,
              "& .MuiChip-label": {
                color: "#1c1c1c",
                fontSize: 14,
                fontWeight: 400,
              },
              "& .MuiChip-deleteIcon": {
                color: "#1c1c1c",
                fontSize: "17px",
              },
            }}
            deleteIcon={<Close fontSize="small" />}
          />
        ))}
        <TextField
          placeholder="Type and press Enter"
          variant="standard"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            disableUnderline: true,
            sx: { width: "auto", flexGrow: 1, marginLeft: "4px" },
          }}
        />
      </Box>
      <Stack textAlign={"end"}>
        <AtsTitleText
          text={inviteError["emails"]}
          fs={12}
          fw={500}
          textColor={COLORS.RED_LIGHT}
        />
      </Stack>
    </Stack>
  );
};

export default EmailTo;
