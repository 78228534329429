import { Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../../../../../theme";

interface IProps {
  heading: string;
  children: JSX.Element;
}

const OnboardingWrapper = ({ heading, children }: IProps) => {
  return (
    <>
      <Stack pb={"16px"}>
        <AtsTitleText
          text={heading}
          fs={16}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Stack>
      {children}
    </>
  );
};

export default OnboardingWrapper;
