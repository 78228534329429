import { Box, Collapse, MenuItem, Stack } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import { useEffect, useState } from "react";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useContracts } from "../../../providers/ContractsProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import { ContractorDropdownList } from "../../../types/contractTypes";
import { useClickOutSide } from "../../../utils/common";

const ContractorSelector = () => {
  const { selectedContractors, setSelectedContractors, sowContracts } =
    useContracts();
  const [contractors, setContractors] = useState<ContractorDropdownList[]>([]);
  const [showProject, setShowProject] = useState<boolean>(false);

  const domNodeProject = useClickOutSide(() => {
    setShowProject(false);
  });

  const handleSelect = (select: string) => {
    if (selectedContractors.includes(select)) {
      const preSelected = selectedContractors.filter((val) => val !== select);
      setSelectedContractors(preSelected);
    } else {
      setSelectedContractors([...selectedContractors, select]);
    }
  };

  useEffect(() => {
    if (sowContracts.length) {
      const filterContracts = sowContracts.map((value) => ({
        name: value.candidate_name,
        id: value.candidate_id,
      }));
      setContractors(filterContracts);
    }
  }, [sowContracts]);
  return (
    <>
      <Box
        ref={domNodeProject}
        sx={{
          position: "relative",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          sx={{
            cursor: "pointer",
            px: "14px",
            py: "6px",
            border: BORDERS.GRAY,
            borderRadius: "38px",
          }}
          onClick={() => setShowProject(!showProject)}
        >
          <Stack direction={"row"} gap={"6px"} alignItems={"center"}>
            <AtsTitleText
              text={"Select Contractor"}
              textColor={COLORS.DARK_GRAY}
              fs={14}
              fw={500}
            />
            {selectedContractors.length > 0 && (
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: COLORS.PRIMARY,
                }}
              >
                <AtsTitleText
                  text={selectedContractors.length}
                  textColor={"white"}
                  fs={10}
                  fw={600}
                />
              </Box>
            )}
          </Stack>
          <Stack>
            <ArrowDown2 size={16} />
          </Stack>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            left: "0px",
            // top: "50px",
            zIndex: 9999,
            // width: "-webkit-fill-available",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "6px",
            }}
            in={showProject}
          >
            <Stack
              id="projectDiv"
              // onScroll={loadMoreItems}
              style={{
                maxHeight: 200,
                padding: "10px",
                overflow: "auto",
              }}
            >
              <Stack gap={"5px"}>
                {contractors.length ? (
                  contractors.map((option: ContractorDropdownList, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      onClick={() => {
                        handleSelect(option.id);
                      }}
                      sx={{
                        borderRadius: "6px",
                        bgcolor: selectedContractors.includes(option.id)
                          ? BG_COLORS.PRIMARY
                          : BG_COLORS.GRAY,
                      }}
                    >
                      <AtsRow gap={"10px"}>
                        <>
                          <Box>
                            <AtsCheckbox
                              checked={selectedContractors.includes(option.id)}
                            />
                          </Box>
                          <AtsTitleText
                            text={option.name}
                            fs={14}
                            fw={500}
                            textColor={
                              selectedContractors.includes(option.id)
                                ? COLORS.PRIMARY
                                : COLORS.BLACK
                            }
                          />
                        </>
                      </AtsRow>
                    </MenuItem>
                  ))
                ) : (
                  <AtsTitleText text={"No Result"} />
                )}
              </Stack>
            </Stack>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default ContractorSelector;
