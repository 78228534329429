/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowForwardIos } from "@mui/icons-material";
import { Skeleton, Stack, Typography } from "@mui/material";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import UserProfileInfo from "../../../../../../../components/common/UserProfileInfo";
import { useMakeAnOffer } from "../../../../../../../providers/MakeAnOfferProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import { useEffect, useState } from "react";
import { MakeAnOfferServices } from "../../../../../../../Api/makeAnOfferServices";
import { getSubmissionState } from "../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../redux/store";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";
import { isNotNullOrUndefined } from "../../../../../../../utils/stringUtils";
import { JobTypeEnum } from "../../../../../../../enums/jobsEnums";
import { Box } from "@mui/material";
import { getContractorDetailsState } from "../../../../../../../redux/slices/contractorSlice";
import moment from "moment";
import { getDateFormatMMMDYYYYY } from "../../../../../../../utils/dateUtils";
import { formattedAmount } from "../../../../../../../utils/common";
import { useRequestToConvert } from "../../../../../../../providers/RequestToConvert";
import AtsCollapse from "../../../../../../../components/AtsCollapse";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { TimesheetServices } from "../../../../../../../Api/timesheetServices";
import { getAuthState } from "../../../../../../../redux/slices/authSlice";

const OfferSummary = () => {
  const { id } = useParams();
  const { userInfo } = useAppSelector(getAuthState);
  const { setIsCompleteRequest, formattedCandidateDetails } = useMakeAnOffer();
  const { annualRate, startDate, setIsSendOffer } = useRequestToConvert();
  const { candidateDetails } = useAppSelector(getSubmissionState);
  const [loading, setLoading] = useState<boolean>(false);
  const { contractorPlacmentDetails } = useAppSelector(
    getContractorDetailsState
  );
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contractorEffortsDetails, setContractorEffortsDetails] = useState<{
    company_hrs: number;
  }>({ company_hrs: 0 });

  const sendOffer = async () => {
    if (!candidateDetails) return;
    setLoading(true);
    try {
      const body = {
        candidate_id: candidateDetails.id,
        project_id: id,
        start_date: startDate.format("YYYY-MM-DD"),
        salary: annualRate,
        perm_fee_perc: conversationFeeCalculate(
          contractorEffortsDetails.company_hrs
        ),
      };
      await MakeAnOfferServices.convertOffer(body);
      setIsSendOffer(true);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const getEfforts = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await TimesheetServices.getCandidateEfforts({
        company_id: userInfo?.company?.id,
        email,
      });
      if (response) {
        setContractorEffortsDetails({
          company_hrs: isNotNullOrUndefined(response.company_hrs)
            ? typeof response.company_hrs === "string"
              ? Number(response.company_hrs)
              : response.company_hrs
            : 0,
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    candidateDetails && getEfforts(candidateDetails.email_address);
  }, []);
  console.log(
    "contractorEffortsDetails?.company_hrs",
    contractorEffortsDetails?.company_hrs
  );
  return (
    <>
      <Stack
        sx={{
          px: 3,
          py: 2,
          bgcolor: BG_COLORS.GRAY,
          borderRadius: 2,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText fs={18} fw={600} text="Offer Summary" />
        </Stack>
        <UserProfileInfo isRequestToConvert />
        <Stack gap={"12px"} sx={{ pt: 2, pb: 2, borderBottom: BORDERS.GRAY }}>
          <RenderTextWrap
            text="Employment type"
            value={
              isNotNullOrUndefined(contractorPlacmentDetails)
                ? parseFloat(contractorPlacmentDetails?.hours_per_week) >= 30
                  ? "Full-time"
                  : "Part-time"
                : ""
            }
          />
          <RenderTextWrap
            text="Hours per week"
            value={`${contractorPlacmentDetails?.hours_per_week} hrs`}
          />
          <RenderTextWrap
            text="Start date"
            value={startDate ? startDate.format("MMM DD, YYYY") : "--"}
          />
          <RenderTextWrap
            text={"Annual rate"}
            value={annualRate.length ? `$${formattedAmount(annualRate)}` : "--"}
          />
          {/* {formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE && (
            <RenderTextWrap
              text={"Annual rate"}
              value={formattedCandidateDetails.annualrate}
            />
          )} */}
        </Stack>
        <Stack sx={{ pt: "12px", pb: "12px", borderBottom: BORDERS.GRAY }}>
          {formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE ? (
            <Stack gap={"5px"}>
              <AtsRowBetween>
                <>
                  <AtsTitleText fs={14} fw={600} text="Direct Hire Fee" />
                  <AtsTitleText
                    fs={14}
                    fw={600}
                    text={`${formattedCandidateDetails.directHireFeee}`}
                  />
                </>
              </AtsRowBetween>
              <Box
                sx={{
                  width: "70%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 10,
                    color: COLORS.BLACK,
                    textDecoration: "underline",
                  }}
                >
                  20% of the annual salary will be payable to Esteemed.
                </Typography>
              </Box>
            </Stack>
          ) : (
            <Stack gap={"25px"}>
              <AtsRowBetween>
                <>
                  <AtsTitleText fs={14} fw={600} text="Total hours worked" />
                  {isLoading ? (
                    <Skeleton variant="rounded" width={60} height={16} />
                  ) : (
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      text={`${contractorEffortsDetails.company_hrs} hrs`}
                    />
                  )}
                </>
              </AtsRowBetween>
              <Stack gap={"5px"}>
                <AtsRowBetween>
                  <>
                    <AtsTitleText fs={14} fw={600} text="Direct Hire Fee" />
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      text={`$${formattedAmount(
                        (Number(annualRate) *
                          conversationFeeCalculate(
                            contractorEffortsDetails.company_hrs
                          )) /
                          100
                      )}`}
                    />
                  </>
                </AtsRowBetween>
                <Box
                  sx={{
                    width: "70%",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 10,
                      color: COLORS.BLACK,
                      textDecoration: "underline",
                    }}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                  >
                    {`${conversationFeeCalculate(
                      contractorEffortsDetails?.company_hrs
                    )}% of the annual salary will be payable to Esteemed.`}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      bgcolor: "white",
                      left: 0,
                      top: "30px",
                      zIndex: 9999,
                      minWidth: 380,
                      borderRadius: "6px",
                    }}
                  >
                    <AtsCollapse in={show}>
                      <Box
                        sx={{
                          border: BORDERS.GRAY,
                          borderRadius: "6px",
                          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                          padding: "10px",
                          bgcolor: "white",
                        }}
                      >
                        <Stack gap={"20px"}>
                          <AtsTitleText
                            fs={10}
                            fw={400}
                            text={`If you want to hire your Contractor as a permanent employee before they've worked six months, you'll need to pay a conversion fee, based on their annual compensation.`}
                          />
                          <Stack gap={"10px"}>
                            <AtsTitleText
                              fs={10}
                              fw={600}
                              text={`For Contractors`}
                            />
                            <Stack gap={"5px"} ml={"5px"}>
                              <RederFeeRateText hours={"0 - 260"} fee={"20"} />
                              <RederFeeRateText
                                hours={"261 - 520"}
                                fee={"15"}
                              />
                              <RederFeeRateText
                                hours={"521 - 780"}
                                fee={"10"}
                              />
                              <RederFeeRateText
                                hours={"781 - 1040"}
                                fee={"5"}
                              />
                              <RederFeeRateText hours={"1041+"} fee={"2"} />
                            </Stack>
                          </Stack>
                          <Stack gap={"7px"}>
                            <AtsTitleText
                              fs={14}
                              fw={500}
                              text={`Calculation`}
                              textColor={COLORS.LIGHT_GRAY}
                            />
                            <Typography
                              sx={{
                                fontWeight: 600,
                                color: "black",
                              }}
                            >
                              {`${conversationFeeCalculate(
                                contractorEffortsDetails.company_hrs
                              )}% X (Annual rate)`}{" "}
                              <span
                                style={{
                                  color: COLORS.PRIMARY,
                                }}
                              >
                                {" "}
                                = Conversaion Fee
                              </span>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </AtsCollapse>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack gap={3.5} py={2}>
          <AtsTitleText
            fs={10}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
            text="By selecting the button below, I agree to the Colleagues Seller Terms, Colleagues Employer Agreement, and Refund Policy, and that Esteemed can charge my payment method. I agree to pay the total amount shown if my hiring request is accepted."
          />
          <AtsRoundedButton
            text="Send offer"
            fw={400}
            isInitial
            endIcon={<ArrowForwardIos />}
            onClick={sendOffer}
            disabled={!annualRate.length}
            loading={loading}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default OfferSummary;

const RenderTextWrap = ({ text, value }: { text: string; value: string }) => {
  return (
    <AtsRowBetween>
      <>
        <AtsTitleText
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          text={text}
        />
        <AtsTitleText fs={12} fw={400} text={value} />
      </>
    </AtsRowBetween>
  );
};

const conversationFeeCalculate = (hours: string | number): number => {
  const hourFromat = typeof hours === "string" ? Number(hours) : hours;

  if (hourFromat >= 0 && hourFromat < 260) {
    return 20;
  } else if (hourFromat > 261 && hourFromat < 520) {
    return 15;
  } else if (hourFromat > 521 && hourFromat < 780) {
    return 10;
  } else if (hourFromat > 781 && hourFromat < 1040) {
    return 5;
  } else if (hourFromat > 1041) {
    return 2;
  }
};

const RederFeeRateText = ({ hours, fee }) => {
  return (
    <li
      style={{
        fontSize: 10,
        fontWeight: 400,
        color: COLORS.BLACK,
      }}
    >
      {`who have worked ${hours} hours, the Conversation
    Fee will be ${fee}%`}
    </li>
  );
};
