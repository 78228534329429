import { Stack, StackProps } from "@mui/material";
import React from "react";

interface IAtsRowBetweenProps extends StackProps {
  children: JSX.Element;
}

const AtsRowBetween = ({ children, ...rest }: IAtsRowBetweenProps) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default AtsRowBetween;
