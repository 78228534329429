import { Stack } from "@mui/material";
import { HomeIcon, StarIcon } from "../../pages/Jobs/assets/Icons";
import { useMakeAnOffer } from "../../providers/MakeAnOfferProvider";
import { BORDERS, BORDERS_2X, COLORS } from "../../theme";
import AtsTitleText from "../AtsTitleText";
import AtsRow from "../AtsRow";
import { getContractorDetailsState } from "../../redux/slices/contractorSlice";
import { useAppSelector } from "../../redux/store";
import { getSubmissionState } from "../../redux/slices/submissionSlice";
import { isNotNullOrUndefined } from "../../utils/stringUtils";
import { convertTimestampToDate } from "../../utils/dateUtils";

interface IProps {
  isRequestToConvert?: boolean;
}

const UserProfileInfo = ({ isRequestToConvert }: IProps) => {
  const { formattedCandidateDetails } = useMakeAnOffer();
  const { contractorPlacmentDetails } = useAppSelector(
    getContractorDetailsState
  );
  const { candidateDetails } = useAppSelector(getSubmissionState);

  const firstName = isNotNullOrUndefined(candidateDetails?.first_name)
    ? candidateDetails?.first_name
    : "";
  const lastName = isNotNullOrUndefined(candidateDetails?.last_name)
    ? candidateDetails?.last_name
    : "";
  const image = isNotNullOrUndefined(candidateDetails?.image)
    ? candidateDetails?.image
    : require("../../assets/images/candidate.png");
  const name = `${firstName} ${lastName}`;
  const designation = isNotNullOrUndefined(candidateDetails?.job_title)
    ? candidateDetails?.job_title
    : "";
  return (
    <>
      <Stack
        direction={"row"}
        gap={2}
        sx={{ py: 2, borderBottom: BORDERS_2X.GRAY }}
      >
        <Stack>
          <img
            src={image}
            alt="Nvidia Logo"
            style={{
              height: "auto",
              marginRight: "10px",
              width: "66px",
              borderRadius: "100%",
            }}
          />
        </Stack>
        <Stack gap={0.5}>
          <AtsTitleText fs={14} fw={600} textColor={COLORS.BLACK} text={name} />
          <AtsTitleText
            text={designation}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
          {isRequestToConvert ? (
            <AtsRow gap={"3px"}>
              <>
                <AtsTitleText
                  fs={12}
                  fw={600}
                  text={"Onboarded: "}
                  textColor={COLORS.LIGHT_GRAY}
                />
                <AtsTitleText
                  fs={12}
                  fw={600}
                  text={
                    contractorPlacmentDetails?.onboarding_date.length
                      ? convertTimestampToDate(
                          contractorPlacmentDetails?.onboarding_date
                        )
                      : "--"
                  }
                  textColor="#000"
                />
              </>
            </AtsRow>
          ) : (
            <Stack direction={"row"} gap={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                pr={1}
                sx={{ borderRight: BORDERS.GRAY }}
              >
                <AtsTitleText
                  text={formattedCandidateDetails?.rating}
                  fs={12}
                  fw={400}
                  textColor={COLORS.BLACK}
                />
                <StarIcon />
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                pl={formattedCandidateDetails?.rating ? 1 : 0}
              >
                <HomeIcon />
                <AtsTitleText
                  text={formattedCandidateDetails?.address}
                  fs={12}
                  fw={600}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default UserProfileInfo;
