export enum ContractFontsEnum {
  CedarvilleCursive = `font-cedarvilleCursive`,
  Whisper = `font-whisper`,
  CutiePatootieSkinny = `font-cutiePatootieSkinny`,
  GattewaySignature = `font-gattewaySignature`,
  Rhesmanisa = `font-rhesmanisa`,
}
export enum SignatureStepEnum {
  SignatureOptions = `SignatureOptions`,
  SignatureFonts = `SignatureFonts`,
  SignatureDraw = `SignatureDraw`,
  SignatureUpload = `SignaturUploads`,
  SignatureCrop = `SignatureCrop`,
}
