import { Box, Stack } from "@mui/material";
import { CheckFillIcon } from "../assets/icons/Icons";
import { COLORS } from "../theme";

interface IProps {
  value?: string;
}

const AtsIconLabel = ({ value }: IProps) => {
  return (
    <Stack
      direction={"row"}
      gap={"8px"}
      sx={{
        py: "2px",
        pl: "2px",
        pr: "10px",
        alignItems: "center",
      }}
      className="checkBtnList"
    >
      <Box
        sx={{
          width: 23,
          height: 23,
          borderRadius: "50%",
          bgcolor: COLORS.PRIMARY,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckFillIcon />
      </Box>
      {value ?? ""}
    </Stack>
  );
};

export default AtsIconLabel;
