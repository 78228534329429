import React from "react";
import { BORDERS } from "../theme";
import { SearchNormal1 } from "iconsax-react";
import { Stack } from "@mui/material";

interface IProps {
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: number;
}

const AtsRoundedSearchInput = ({
  value,
  onChange,
  placeholder,
  width = 232,
}: IProps) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        minHeight={30}
        width={width}
        sx={{
          px: "16px",
          border: BORDERS.GRAY,
          borderRadius: "38px",
        }}
      >
        <input
          type="text"
          className="customInput"
          placeholder={placeholder ?? ""}
          style={{
            width: "100%",
          }}
          value={value}
          onChange={onChange}
        />
        <SearchNormal1 size={16} />
      </Stack>
    </>
  );
};

export default AtsRoundedSearchInput;
