import React from "react";
import AtsTitleText from "./AtsTitleText";
import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { BG_COLORS, BORDERS, COLORS } from "../theme";

type IAtsInputProps = {
  header?: string;
  loading?: boolean;
  isTextArea?: boolean;
  height?: string;
  fs?: string;
  bgcolor?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
} & TextFieldProps;

const AtsTextInput = ({
  header,
  loading,
  isTextArea,
  height,
  startAdornment,
  endAdornment,
  fs,
  bgcolor = "trasparant",
  ...rest
}: IAtsInputProps) => {
  const { type } = rest;
  const getEndAdornment = () => {
    if (loading) {
      return (
        <InputAdornment position="end">
          <CircularProgress size={"30px"} />
        </InputAdornment>
      );
    }
    if (endAdornment) {
      return <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }
  };
  return (
    <Stack gap={0.5}>
      {header && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"relative"}
        >
          <AtsTitleText
            text={header}
            fs={12}
            fw={600}
            textColor={COLORS.BLACK}
          />
        </Stack>
      )}
      <TextField
        fullWidth
        onWheel={(e: any) => e.target.blur()}
        autoComplete={"off"}
        type={type}
        sx={{
          input: {
            bgcolor,
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 30px white inset",
              WebkitTextFillColor: "#000",
            },
          },
          "& .MuiInputBase-input": {
            fontSize: fs ? fs : "16px",
            color: COLORS.BLACK,
            fontWeight: 400,
            mb: "3px",
            "::placeholder": {
              fontSize: "12px",
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
            },
            ".Mui-disabled": {
              color: COLORS.BLACK,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: BORDERS.GRAY,
            "& .Mui-disabled": {
              border: "none !important",
            },
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            "&.Mui-focused fieldset": {
              border: BORDERS.GRAY,
              "& .Mui-disabled": {
                border: "none !important",
              },
            },
            ":hover fieldset": {
              border: BORDERS.GRAY,
              "& .Mui-disabled": {
                border: "none !important",
              },
            },
            "&.Mui-disabled": {
              bgcolor: "#f5f5f5",
              border: "none !important",
            },
            "& .MuiInputBase-inputMultiline": {
              "::placeholder": {
                fontSize: "14px",
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
              },
            },
          },
          ...rest.sx,
        }}
        InputProps={{
          style: {
            borderRadius: "8px",
            height: isTextArea ? "" : height ? height : "37px",
          },
          endAdornment: getEndAdornment(),
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                mr: startAdornment ? 1 : 0,
              }}
            >
              {startAdornment}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Stack>
  );
};

export default AtsTextInput;
