import { Container, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { JobsService } from "../../Api/jobsListingServices";
import AtsCard from "../../components/AtsCard";
import AtsTabs from "../../components/AtsTabs";
import AtsText from "../../components/AtsText";
import AtsTitleText from "../../components/AtsTitleText";
import { COLORS } from "../../theme";
import { JobDetailsResponse } from "../../types/jobsTypes";
import SubmissionHeader from "./Submissions/SubmissionHeader";
import Pipeline from "./Submissions/pipeline/Pipeline";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  getSubmissionState,
  setJobDetailsForSubmission,
} from "../../redux/slices/submissionSlice";
import ContractorDetailsDrawer from "../Projects/modules/ProjectDetailsModules/Team/ContractorDetails/ContractorDetailsDrawer";

const Submissions = () => {
  const dispatch = useAppDispatch();
  const { jobDetailsForSubmission } = useAppSelector(getSubmissionState);
  const { nid } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const getJobDetails = async () => {
    setLoading(true);

    try {
      const response = await JobsService.getJobDetailsById({ nid });
      if (response.results.length) {
        const details = response.results[0];
        dispatch(setJobDetailsForSubmission(details));
        // setJobDetails(details);
      } else {
        dispatch(setJobDetailsForSubmission(null));
        // setJobDetails(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nid) {
      getJobDetails();
    }
  }, [nid]);

  const contractorDetailsDrawerMemo = useMemo(
    () => <ContractorDetailsDrawer />,
    []
  );

  return (
    <div
      className="main-container"
      style={{
        height: "100%",
      }}
    >
      <Container maxWidth="xl" sx={{ overflowX: "scroll" }}>
        <Stack px={3} sx={{ pt: "8px", pb: "20px" }}>
          <Stack
            gap={"6px"}
            direction={"row"}
            alignItems={"center"}
            sx={{ minHeight: 22 }}
          >
            <AtsTitleText
              lineHeight={2.2}
              text={"All Jobs"}
              fs={10}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
            <AtsTitleText
              lineHeight={2.2}
              text={">"}
              fs={10}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
            <AtsTitleText
              lineHeight={2.2}
              text={jobDetailsForSubmission?.title ?? "-"}
              fs={10}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
            <AtsTitleText
              lineHeight={2.2}
              text={">"}
              fs={10}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
            <AtsTitleText
              lineHeight={2.2}
              text={"Submissions"}
              fs={10}
              fw={400}
              textColor={"#000"}
            />
          </Stack>
        </Stack>
        <Stack px={2} gap={1} sx={{ mb: 5, height: "calc(100% - 90px)" }}>
          <AtsCard sx={{ height: "100%" }}>
            <Stack
              gap={2}
              px={2}
              py={1}
              sx={{ height: "98%", overflowX: "scroll" }}
            >
              <SubmissionHeader
                jobDetails={jobDetailsForSubmission}
                loading={loading}
              />
              <AtsTabs
                value={tabValue}
                onChange={(event, newValue) => {
                  handleTabChange(newValue);
                }}
                // tabData={["Pipeline", "Talent Insights"]}
                tabData={["Pipeline"]}
              />

              {tabValue === 0 ? (
                <Pipeline />
              ) : (
                <Stack>
                  <AtsText
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: COLORS.LIGHT_GRAY,
                    }}
                    text="Talent Insights"
                  />
                </Stack>
              )}
            </Stack>
          </AtsCard>
        </Stack>
      </Container>
      {contractorDetailsDrawerMemo}
    </div>
  );
};

export default Submissions;
