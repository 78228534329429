import React, { useEffect, useState } from "react";
import { useClickOutSide } from "../../../utils/common";
import { ProjectService } from "../../../Api/projectServices";
import {
  ProjectDropdownList,
  ProjectListParams,
  ProjectListProps,
} from "../../../types/projectTypes";
import { Box, Collapse, MenuItem, Stack } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import AtsRoundedSearchInput from "../../../components/AtsRoundedSearchInput";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import InfiniteScroll from "react-infinite-scroll-component";
import AtsRow from "../../../components/AtsRow";
import { isNotNullOrUndefined } from "../../../utils/stringUtils";
import { useContracts } from "../../../providers/ContractsProvider";

const ProjectSelectForSOWContract = () => {
  const { selectedProjects, setSelectedProjects, sowContracts } =
    useContracts();
  const [projectList, setProjectList] = useState<ProjectDropdownList[]>([]);
  const [showProject, setShowProject] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [projects, setProjects] = useState<ProjectListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleSelect = (select: string) => {
    if (selectedProjects.includes(select)) {
      const preSelected = selectedProjects.filter((val) => val !== select);
      setSelectedProjects(preSelected);
    } else {
      setSelectedProjects([...selectedProjects, select]);
    }
  };

  const domNodeProject = useClickOutSide(() => {
    setShowProject(false);
  });

  const fetchMoreData = () => {
    if (totalItem !== projects.length) {
      setPage((prevPage) => prevPage + 1); // Increment the page
    }
  };

  const getProjects = async () => {
    if (sowContracts.length) {
      const getProjectId: string[] = sowContracts.map(
        (value) => value.project_id
      );
      const uniqueProjectId = Array.from(new Set(getProjectId));
      const projectDetailsById = uniqueProjectId.map(getProjectDetails);
      const promiseAllProjects = await Promise.all(projectDetailsById);
      setProjectList(promiseAllProjects);
    }
  };
  const getProjectDetails = async (projectId: string) => {
    setIsLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({
        nid: projectId,
      });
      return {
        title: response.title,
        image: response.project_image,
        id: response.nid,
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, [sowContracts]);
  return (
    <>
      <Box
        ref={domNodeProject}
        sx={{
          position: "relative",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          sx={{
            cursor: "pointer",
            px: "14px",
            py: "6px",
            border: BORDERS.GRAY,
            borderRadius: "38px",
          }}
          onClick={() => setShowProject(!showProject)}
        >
          <Stack direction={"row"} gap={"6px"} alignItems={"center"}>
            <AtsTitleText
              text={"Select Project"}
              textColor={COLORS.DARK_GRAY}
              fs={14}
              fw={500}
            />
            {selectedProjects.length > 0 && (
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: COLORS.PRIMARY,
                }}
              >
                <AtsTitleText
                  text={selectedProjects.length}
                  textColor={"white"}
                  fs={10}
                  fw={600}
                />
              </Box>
            )}
          </Stack>
          <Stack>
            <ArrowDown2 size={16} />
          </Stack>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            left: "0px",
            // top: "50px",
            zIndex: 9999,
            // width: "-webkit-fill-available",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "6px",
            }}
            in={showProject}
          >
            <Stack
              id="projectDiv"
              // onScroll={loadMoreItems}
              style={{
                maxHeight: 200,
                padding: "10px",
                overflow: "auto",
              }}
            >
              <InfiniteScroll
                dataLength={projects.length}
                next={fetchMoreData}
                hasMore={projects.length < totalItem}
                loader={<h4>Loading...</h4>}
                scrollableTarget="projectDiv"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Stack gap={"5px"}>
                  {projectList.length ? (
                    projectList.map((option: ProjectDropdownList, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        onClick={() => {
                          handleSelect(option.id);
                        }}
                        sx={{
                          borderRadius: "6px",
                          bgcolor: selectedProjects.includes(option.id)
                            ? BG_COLORS.PRIMARY
                            : BG_COLORS.GRAY,
                        }}
                      >
                        <AtsRow gap={"10px"}>
                          <>
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                border: BORDERS.GRAY,
                              }}
                            >
                              <img
                                width={"100%"}
                                style={{
                                  borderRadius: "50%",
                                }}
                                src={
                                  isNotNullOrUndefined(option.image)
                                    ? option.image
                                    : require("../../../assets/images/projectImg.png")
                                }
                                alt=""
                              />
                            </Box>
                            <AtsTitleText
                              text={option.title}
                              fs={14}
                              fw={500}
                              textColor={
                                selectedProjects.includes(option.id)
                                  ? COLORS.PRIMARY
                                  : COLORS.BLACK
                              }
                            />
                          </>
                        </AtsRow>
                      </MenuItem>
                    ))
                  ) : (
                    <AtsTitleText text={"No Result"} />
                  )}
                </Stack>
              </InfiniteScroll>
            </Stack>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default ProjectSelectForSOWContract;
