import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import TotalNumberCard from "../components/TotalNumberCard";
import JobRequisitionsByLocations from "../modules/JobRequisitionsByLocations";
import { BORDERS } from "../../../theme";
import CompanyOverview from "../modules/CompanyOverview";
import KeyInsights from "../modules/KeyInsights";
import GenderDiversity from "../modules/GenderDiversity";
import HeadCountOpenPosition from "../modules/HeadCountOpenPosition";
import WorkforceExperience from "../modules/WorkforceExperience";
import HiringFunnel from "../modules/HiringFunnel";
import UserHire from "../icons/UserHire.svg";
import Suitcase from "../icons/Suitcase.svg";
import UserDiary from "../icons/UserDiary.svg";
import Pileline from "../icons/Pileline.svg";
import { useInsights } from "../../../providers/InsightsProvider";
import { SummaryHiringFunnelProps } from "../../../types/insightsTypes";

const TalentTab = () => {
  const { othersInsightsData, othersInsightsLoading } = useInsights();

  const getNumbers = (keyVal: keyof SummaryHiringFunnelProps) => {
    if (
      othersInsightsData &&
      othersInsightsData.summary_and_hiring_funnel &&
      !Array.isArray(othersInsightsData.summary_and_hiring_funnel)
    ) {
      return othersInsightsData.summary_and_hiring_funnel[keyVal];
    }
    return 0;
  };
  const getPipeline = () => {
    if (
      othersInsightsData &&
      othersInsightsData.summary_and_hiring_funnel &&
      !Array.isArray(othersInsightsData.summary_and_hiring_funnel)
    ) {
      const totalSum = Object.values(
        othersInsightsData.summary_and_hiring_funnel
      ).reduce((sum, value) => sum + value, 0);
      return totalSum;
    }
    return 0;
  };
  const dataCount = [
    {
      number: getNumbers("total_submissions"),
      title: "Total submissions",
      icon: UserDiary,
    },
    {
      number: getNumbers("total_active_jobs"),
      title: "Total active jobs",
      icon: Suitcase,
    },
    {
      number: getNumbers("total_hires"),
      title: "Total hires",
      icon: UserHire,
    },
    {
      number: getPipeline(),
      title: "Total in pipeline",
      icon: Pileline,
    },
  ];
  const data = [
    {
      number: 34,
      title: "Total submissions",
      icon: UserDiary,
    },
    {
      number: 18,
      title: "Total active jobs",
      icon: Suitcase,
    },
    {
      number: 1224,
      title: "Total hires",
      icon: UserHire,
    },
    {
      number: 552,
      title: "Total in pipeline",
      icon: Pileline,
    },
  ];
  return (
    <>
      <Stack gap={"20px"}>
        <Grid container spacing={"30px"}>
          {othersInsightsLoading
            ? data.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <TotalNumberCard
                    icon={item.icon}
                    number={item.number}
                    title={item.title}
                    loading={othersInsightsLoading}
                  />
                </Grid>
              ))
            : dataCount.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <TotalNumberCard
                    icon={item.icon}
                    number={item.number}
                    title={item.title}
                  />
                </Grid>
              ))}
        </Grid>
        <Grid container spacing={"30px"}>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <JobRequisitionsByLocations />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Box
              sx={{
                p: "25px",
                border: BORDERS.GRAY,
                borderRadius: "10px",
                bgcolor: "white",
              }}
            >
              <Stack>
                <CompanyOverview />
              </Stack>
              <Stack>
                <KeyInsights />
              </Stack>
              {/* <Stack>
                <GenderDiversity />
              </Stack> */}
            </Box>
          </Grid>
          <Grid item container spacing={"30px"} xs={12} sm={12} md={8} lg={9}>
            <Grid item xs={12} sm={12} md={6}>
              <HeadCountOpenPosition />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <WorkforceExperience />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <HiringFunnel />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default TalentTab;
