import { Box, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import CompanyCulture from "./CompanyCulture";
import FormWrapper from "./FormWrapper";
import OfficeTours from "./OfficeTours";
import OnboardingWrapper from "./OnboardingWrapper";
import OrientationSession from "./OrientationSession";
import { ArrowDown2, ArrowUp2, Edit2 } from "iconsax-react";
import { COLORS } from "../../../../../../../../../theme";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsLabel from "../../../../../../../../../components/AtsLabel";

const OnboardingSchedule = () => {
  const [collapseRow, setCollapseRow] = useState<string[]>([]);
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);
  const { editState, handleEditState } = useOnboarding();

  const onCollapse = (value: string) => {
    if (collapseRow.includes(value)) {
      const filterValue = collapseRow.filter((selected) => selected !== value);
      setCollapseRow(filterValue);
    } else {
      setCollapseRow([...collapseRow, value]);
    }
  };
  return (
    <OnboardingWrapper heading="Provide detailed information on initial orientation, meetings, and introduction activities.">
      <Stack gap={"40px"}>
        {/* <FormWrapper heading="Schedule Kickoff Meeting">
          <Stack gap={"30px"}>
            <Grid container columnSpacing={"40px"} rowSpacing={"30px"}>
              <Grid item xs={12} sm={6}>
                <Stack gap={"5px"}>
                  <AtsTitleText text={"Meeting Name"} fs={14} fw={600} />
                  <AtsTextInput />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack gap={"5px"}>
                  <AtsTitleText
                    text={"Select Key team Members"}
                    fs={14}
                    fw={600}
                  />
                  <ProjectMemberSelector
                    project_id={id}
                    selected={selectedMembers}
                    onChange={(select) => {
                      if (selectedMembers.includes(select)) {
                        const filteredMemeber = selectedMembers.filter(
                          (selected) => selected !== select
                        );
                        setSelectedMembers(filteredMemeber);
                      } else {
                        setSelectedMembers([...selectedMembers, select]);
                      }
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack gap={"5px"}>
                  <AtsTitleText
                    text={
                      "Please share anything that will help prepare for the meeting."
                    }
                    fs={14}
                    fw={600}
                  />
                  <AtsTextArea />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </FormWrapper> */}
        <FormWrapper
          heading="Orientation Session"
          renderRightComponent={
            isContractorOnboarded ? (
              <AtsRow gap={"10px"}>
                <>
                  <AtsLabel
                    fs={12}
                    px={2}
                    text={"Confirmed"}
                    variant="success"
                    isCapital
                    height="25px"
                  />
                  {editState.isEditOrientationSession ? (
                    <AtsRoundedButton
                      text="Save"
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="darkGreenContained"
                      onClick={() =>
                        handleEditState("isEditOrientationSession", false)
                      }
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Edit"
                      startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="whiteContained"
                      onClick={() =>
                        handleEditState("isEditOrientationSession", true)
                      }
                    />
                  )}
                </>
              </AtsRow>
            ) : (
              <>
                <IconButton
                  sx={{
                    p: "2px",
                  }}
                  onClick={() => onCollapse("Orientation Session")}
                >
                  {collapseRow.includes("Orientation Session") ? (
                    <ArrowDown2 size="20" color={COLORS.PRIMARY} />
                  ) : (
                    <ArrowUp2 size="20" color={COLORS.PRIMARY} />
                  )}
                </IconButton>
              </>
            )
          }
        >
          {isContractorOnboarded ? (
            <Box pt={"20px"}>
              <OrientationSession />
            </Box>
          ) : collapseRow.includes("Orientation Session") ? (
            <Box pt={"20px"}>
              <OrientationSession />
            </Box>
          ) : (
            <></>
          )}
        </FormWrapper>
        <FormWrapper
          heading="Company Culture and Values"
          renderRightComponent={
            isContractorOnboarded ? (
              <AtsRow gap={"10px"}>
                <>
                  <AtsLabel
                    fs={12}
                    px={2}
                    text={"Confirmed"}
                    variant="success"
                    isCapital
                    height="25px"
                  />
                  {editState.isEditCompanyCulture ? (
                    <AtsRoundedButton
                      text="Save"
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="darkGreenContained"
                      onClick={() =>
                        handleEditState("isEditCompanyCulture", false)
                      }
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Edit"
                      startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="whiteContained"
                      onClick={() =>
                        handleEditState("isEditCompanyCulture", true)
                      }
                    />
                  )}
                </>
              </AtsRow>
            ) : (
              <>
                <IconButton
                  sx={{
                    p: "2px",
                  }}
                  onClick={() => onCollapse("Company Culture and Values")}
                >
                  {collapseRow.includes("Company Culture and Values") ? (
                    <ArrowDown2 size="20" color={COLORS.PRIMARY} />
                  ) : (
                    <ArrowUp2 size="20" color={COLORS.PRIMARY} />
                  )}
                </IconButton>
              </>
            )
          }
        >
          {isContractorOnboarded ? (
            <Box pt={"20px"}>
              <CompanyCulture />
            </Box>
          ) : collapseRow.includes("Company Culture and Values") ? (
            <Box pt={"20px"}>
              <CompanyCulture />
            </Box>
          ) : (
            <></>
          )}
        </FormWrapper>
        <FormWrapper
          heading="Office Tours or Virtual Introductions"
          renderRightComponent={
            isContractorOnboarded ? (
              <AtsRow gap={"10px"}>
                <>
                  <AtsLabel
                    fs={12}
                    px={2}
                    text={"Confirmed"}
                    variant="success"
                    isCapital
                    height="25px"
                  />
                  {editState.isEditOfficceTours ? (
                    <AtsRoundedButton
                      text="Save"
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="darkGreenContained"
                      onClick={() =>
                        handleEditState("isEditOfficceTours", false)
                      }
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Edit"
                      startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="whiteContained"
                      onClick={() =>
                        handleEditState("isEditOfficceTours", true)
                      }
                    />
                  )}
                </>
              </AtsRow>
            ) : (
              <>
                <IconButton
                  sx={{
                    p: "2px",
                  }}
                  onClick={() =>
                    onCollapse("Office Tours or Virtual Introductions")
                  }
                >
                  {collapseRow.includes(
                    "Office Tours or Virtual Introductions"
                  ) ? (
                    <ArrowDown2 size="20" color={COLORS.PRIMARY} />
                  ) : (
                    <ArrowUp2 size="20" color={COLORS.PRIMARY} />
                  )}
                </IconButton>
              </>
            )
          }
        >
          {isContractorOnboarded ? (
            <Box pt={"20px"}>
              <OfficeTours />
            </Box>
          ) : collapseRow.includes("Office Tours or Virtual Introductions") ? (
            <Box pt={"20px"}>
              <OfficeTours />
            </Box>
          ) : (
            <></>
          )}
        </FormWrapper>
      </Stack>
    </OnboardingWrapper>
  );
};

export default OnboardingSchedule;
