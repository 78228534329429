import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../components/AtsRowBetween";
import { BORDERS } from "../../../theme";

interface IProps {
  backText: string;
  nextText: string;
  onBack: () => void;
  onNext: () => void;
  isDisabled?: boolean;
  loading?: boolean;
}

const SignatureBottomBox = ({
  backText,
  nextText,
  onBack,
  onNext,
  isDisabled,
  loading,
}: IProps) => {
  return (
    <AtsRowBetween
      sx={{
        borderTop: BORDERS.GRAY,
        px: "40px",
        py: "25px",
      }}
    >
      <>
        <AtsRoundedButton
          fs={14}
          fw={400}
          text={backText}
          buttonVariant="outline"
          buttonHeight={42}
          buttonWidth={130}
          onClick={onBack}
        />
        <AtsRoundedButton
          fs={14}
          fw={400}
          text={nextText}
          buttonHeight={42}
          buttonWidth={130}
          onClick={onNext}
          disabled={isDisabled}
          loading={loading}
        />
      </>
    </AtsRowBetween>
  );
};

export default SignatureBottomBox;
