import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import { IColumnProps } from "../../../../../../../types/commonType";
import { BORDERS } from "../../../../../../../theme";
import { useInvoices } from "../../../../../../../providers/InvoicesProvider";
import { InvoiceDetailsResponse } from "../../../../../../../types/invoiceTypes";
import { JobTypeEnum } from "../../../../../../../enums/jobsEnums";

const cellStyles = {
  padding: "5px 10px",
};

const InvoiceItemsList = () => {
  const { invoiceDetails, detailsLoading } = useInvoices();

  const LEAD_COLUMN: IColumnProps[] = [
    {
      key: "date",
      header: "Time Period",
      width: "auto",
      minWidth: "150px",
    },
    {
      key: "description",
      header: "Contractor",
      width: "auto",
      minWidth: "250px",
    },
    {
      key: "quantity",
      header:
        invoiceDetails[0]?.invoice_type === JobTypeEnum.DIRECT_HIRE
          ? "Offer Rate"
          : invoiceDetails[0]?.invoice_type === "Converted"
          ? "Offer Rate "
          : "Hours",
      width: "auto",
      minWidth: "100px",
    },
    {
      key: "rate",
      header:
        invoiceDetails[0]?.invoice_type === JobTypeEnum.DIRECT_HIRE
          ? "Direct Hite %"
          : invoiceDetails[0]?.invoice_type === "Converted"
          ? "Conversion %"
          : "Hourly Rate",
      width: "auto",
      minWidth: "100px",
    },

    {
      key: "amount",
      header: "Amount",
      width: "auto",
      minWidth: "100px",
    },
  ];

  const customizeRow = (
    column: IColumnProps,
    invoice: InvoiceDetailsResponse
  ) => {
    switch (column.key) {
      case "date":
        const dateValue = `${invoice?.start_date} - ${invoice?.end_date}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={dateValue}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "description":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={600}
                  text={invoice.description}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "quantity":
        const value = `${invoice.quantity} ${
          invoice.invoice_type !== JobTypeEnum.DIRECT_HIRE &&
          invoice?.invoice_type !== "Converted"
            ? "hrs"
            : ""
        }`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={value}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "rate":
        const rateValue =
          invoice.invoice_type === JobTypeEnum.DIRECT_HIRE ||
          invoice.invoice_type === "Converted"
            ? `${invoice.rate}%`
            : `$${invoice.rate}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={rateValue}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "amount":
        const amountValue = `$${invoice.amount}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={amountValue}
                />
              </Stack>
            </Stack>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = invoiceDetails.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "12px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsTitleText
              text={column.header}
              fs={12}
              fw={600}
              textColor="#4f4f4f"
              isCursor
            />
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Box mb={"23px"}>
        <AtsTitleText text={"Invoice items"} fs={16} fw={600} />
      </Box>
      <Box>
        {detailsLoading ? (
          <Stack gap={"5px"}>
            {Array.from(new Array(3)).map((_, index) => (
              <Skeleton width={"100%"} variant="rounded" height={30} />
            ))}
          </Stack>
        ) : (
          <AtsDataTable
            isNotStickyHeader
            isTopBorder
            data={invoiceDetails}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={false}
          />
        )}
      </Box>
    </>
  );
};

export default InvoiceItemsList;
