import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import ReactApexChart from "react-apexcharts";
import AtsRowBetween from "../../../components/AtsRowBetween";
import { useInsights } from "../../../providers/InsightsProvider";
import AtsEmptyState from "../../../components/AtsEmptyState";
import WorkforceExperienceGraph from "./WorkforceExperienceGraph";

const data = [
  {
    title: "High Experienced Functions",
    items: [
      { text: "Operations", value: "23.3 yrs" },
      { text: "Education", value: "20.4 yrs" },
      { text: "Information Technology", value: "18.3 yrs" },
    ],
  },
  {
    title: "Low Experienced Functions",
    items: [
      { text: "Military and Protective Services", value: "2.4 yrs" },
      { text: "Sales", value: "2.4 yrs" },
      { text: "Customer Success and Support", value: "2.4 yrs" },
    ],
  },
];

const WorkforceExperience = () => {
  const { othersInsightsData, othersInsightsLoading } = useInsights();
  const isWorkforce =
    othersInsightsData &&
    othersInsightsData.workforce_and_gender_diversity &&
    !Array.isArray(othersInsightsData.workforce_and_gender_diversity) &&
    othersInsightsData.workforce_and_gender_diversity.workforce;

  const getHighValueOfExperieance = (workforce) => {
    const experiance = Object.entries(workforce)
      .flatMap(([category, subObj]) =>
        Object.entries(subObj).map(([id, value]) => ({
          category,
          id,
          value,
        }))
      )
      .sort((a, b) => b.value - a.value)
      .slice(0, 3);

    const categoryMap = new Map();

    experiance.forEach((item) => {
      const currentMax = categoryMap.get(item.category);
      if (!currentMax || currentMax.value < item.value) {
        categoryMap.set(item.category, item);
      }
    });

    const uniqueCategories = Array.from(categoryMap.values());
    return uniqueCategories;
  };

  const getLowValueOfExperieance = (workforce) => {
    const experiance = Object.entries(workforce)
      .flatMap(([category, subObj]) =>
        Object.entries(subObj).map(([id, value]) => ({
          category,
          id,
          value,
        }))
      )
      .sort((a, b) => a.value - b.value)
      .slice(0, 3);

    const categoryMap = new Map();

    experiance.forEach((item) => {
      const currentMax = categoryMap.get(item.category);
      if (!currentMax || currentMax.value > item.value) {
        categoryMap.set(item.category, item);
      }
    });

    const uniqueCategories = Array.from(categoryMap.values());
    return uniqueCategories;
  };
  return (
    <>
      <Box
        sx={{
          p: "25px",
          border: BORDERS.GRAY,
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        <Stack gap={"15px"}>
          <AtsTitleText text={"Workforce experience"} fs={18} fw={600} />
          {othersInsightsLoading ? (
            <Skeleton variant="rounded" width={"100%"} height={200} />
          ) : isWorkforce ? (
            <WorkforceExperienceGraph
              data={othersInsightsData.workforce_and_gender_diversity.workforce}
            />
          ) : (
            <AtsEmptyState
              text="No Workforce experience"
              subText=""
              imgHeight={25}
              imgWidth={25}
            />
          )}
          <Grid container rowSpacing={"30px"}>
            {othersInsightsLoading ? (
              <CategoryGridItem
                title={"High Experienced Functions"}
                items={[]}
                loading={othersInsightsLoading}
              />
            ) : isWorkforce ? (
              <CategoryGridItem
                title={"High Experienced Functions"}
                items={getHighValueOfExperieance(
                  othersInsightsData.workforce_and_gender_diversity.workforce
                )}
                loading={othersInsightsLoading}
              />
            ) : (
              <Grid item xs={12}>
                <AtsEmptyState
                  text="No Hight experienced"
                  subText=""
                  imgHeight={25}
                  imgWidth={25}
                />
              </Grid>
            )}
            {othersInsightsLoading ? (
              <CategoryGridItem
                title={"High Experienced Functions"}
                items={[]}
                loading={othersInsightsLoading}
              />
            ) : isWorkforce ? (
              <CategoryGridItem
                title={"Low Experienced Functions"}
                items={getLowValueOfExperieance(
                  othersInsightsData.workforce_and_gender_diversity.workforce
                )}
                loading={othersInsightsLoading}
              />
            ) : (
              <Grid item xs={12}>
                <AtsEmptyState
                  text="No Low experienced"
                  subText=""
                  imgHeight={25}
                  imgWidth={25}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default WorkforceExperience;

const CategoryGridItem = ({
  title,
  items,
  loading,
}: {
  title: string;
  items: { category: string; id: string; value: number }[];
  loading: boolean;
}) => {
  return (
    <Grid item xs={12}>
      <Stack gap={"5px"}>
        <Typography
          sx={{
            letterSpacing: "2px",
            fontSize: 12,
            color: COLORS.LIGHT_GRAY,
            mb: "5px",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Stack gap={"5px"}>
          {loading
            ? Array.from(new Array(3)).map((_, index) => (
                <AtsRowBetween key={index}>
                  <>
                    <Skeleton width={150} />
                    <Skeleton width={10} height={10} variant="circular" />
                  </>
                </AtsRowBetween>
              ))
            : items.map((item, index) => (
                <TextBetweenWrapper
                  key={index}
                  text={item.category}
                  value={`${item.value} yrs`}
                />
              ))}
        </Stack>
      </Stack>
    </Grid>
  );
};

const TextBetweenWrapper = ({ text, value }) => {
  return (
    <AtsRowBetween>
      <>
        <AtsTitleText text={text} fs={12} fw={500} color={COLORS.DARK_GRAY} />
        <AtsTitleText text={value} fs={12} fw={700} />
      </>
    </AtsRowBetween>
  );
};
