import axios from "axios";
import {
  AcceptInviteRequest,
  ClientUser,
  CustomProfileRequest,
  GetEmailFromHashKey,
  GetInterestResponse,
  GetLanguageResponse,
  GetSchoolResponse,
  SendInviteUserRequest,
  UserImageResponse,
  UserInformation,
  UserUpdateRequest,
} from "../types/userManagementTypes";
import { PaginatedResponse, TitleIdObjResponse } from "../types/commonType";
import { StatusEnum } from "../enums/commonEnums";

const Endpoint = {
  BASE: "ats/api/v1/",
  CLIENT_ROLES: "client-roles?_format=json",
  ACTIVE_USERS: "active-users",
  INACTIVE_USERS: "inactive-users",
  PENDING_USERS: "pending-users",
  BLOCK_UNBLOCK_USER: "user-block-unblock",
  INVITE_USERS: "invite",
  USERINFO: "userinfo?_format=json",
  ACCEPT_INVITE: "accept-invite",
  GET_USER_IMAGE: "users-image",
  USER_UPDATE: "user/update",
  GET_CONTACT_DETAILS: "contact/get",
  CHANGE_PASSWORD: "change-password/update",
  RESET_PASSWORD: "reset-password-email",
  SET_PASSWORD: "user-set-password",
  GET_EMAIL: "get-data-from-invite-hash",
  GET_CUSTOM_PROFILE: "user-profile/get",
  UPDATE_CUSTOM_PROFILE: "user-profile/create-update",
  INTERESTS: "interests",
  UPDATE_PROFILE_PIC: "user-profile-pic/update?_format=json",
};

const CLIENT_ROLES_URL = Endpoint.BASE + Endpoint.CLIENT_ROLES;
const ACTIVE_USERS_URL = Endpoint.BASE + Endpoint.ACTIVE_USERS;
const INACTIVE_USERS_URL = Endpoint.BASE + Endpoint.INACTIVE_USERS;
const PENDING_USERS_URL = Endpoint.BASE + Endpoint.PENDING_USERS;
const BLOCK_UNBLOCK_USER_URL = Endpoint.BASE + Endpoint.BLOCK_UNBLOCK_USER;
const INVITE_USERS_URL = Endpoint.BASE + Endpoint.INVITE_USERS;
const USERINFO_URL = Endpoint.BASE + Endpoint.USERINFO;
const ACCEPT_INVITE_URL = Endpoint.BASE + Endpoint.ACCEPT_INVITE;
const GET_USER_IMAGE_URL = Endpoint.BASE + Endpoint.GET_USER_IMAGE;
const USER_UPDATE_URL = Endpoint.BASE + Endpoint.USER_UPDATE;
const GET_EMAIL_URL = Endpoint.BASE + Endpoint.GET_EMAIL;
const GET_CONTACT_DETAILS_URL = Endpoint.BASE + Endpoint.GET_CONTACT_DETAILS;

const CHANGE_PASSWORD_URL = Endpoint.BASE + Endpoint.CHANGE_PASSWORD;
const RESET_PASSWORD_URL = Endpoint.BASE + Endpoint.RESET_PASSWORD;
const SET_PASSWORD_URL = Endpoint.BASE + Endpoint.SET_PASSWORD;

const UPDATE_PROFILE_PIC_URL = Endpoint.BASE + Endpoint.UPDATE_PROFILE_PIC;
const INTERESTS_URL = Endpoint.BASE + Endpoint.INTERESTS;
const GET_CUSTOM_PROFILE_URL = Endpoint.BASE + Endpoint.GET_CUSTOM_PROFILE;
const UPDATE_CUSTOM_PROFILE_URL =
  Endpoint.BASE + Endpoint.UPDATE_CUSTOM_PROFILE;
const SCHOOL_URL = `https://autocomplete.clearbit.com/v1/companies/suggest`;
const LANGUAGE_URL = `https://cprodapi.esteemed.io/v1/global-preference/language`;

export class UserManagementServices {
  static async getUserInfo(access_token: string): Promise<UserInformation> {
    const endpoint = USERINFO_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  }
  static async getClientRoles(): Promise<
    PaginatedResponse<TitleIdObjResponse>
  > {
    const endpoint = CLIENT_ROLES_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getActiveUsers(
    status: StatusEnum
  ): Promise<PaginatedResponse<ClientUser>> {
    const endpoint =
      status === "PENDING"
        ? PENDING_USERS_URL
        : status === "INACTIVE"
        ? INACTIVE_USERS_URL
        : ACTIVE_USERS_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async userBlockUnBlock(request: {
    email: string;
    status: string;
  }): Promise<PaginatedResponse<ClientUser>> {
    const response = await axios.patch(BLOCK_UNBLOCK_USER_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async sendInviteUsers(request: SendInviteUserRequest) {
    const endpoint = INVITE_USERS_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async acceptInvite(request: AcceptInviteRequest) {
    const endpoint = ACCEPT_INVITE_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `cookie`,
      },
    });
    return response.data;
  }
  static async getEmailFromHash(request: Partial<GetEmailFromHashKey>) {
    const endpoint = GET_EMAIL_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getUserImage(params: {
    email: string;
  }): Promise<PaginatedResponse<UserImageResponse>> {
    const endpoint = GET_USER_IMAGE_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `cookie`,
      },
    });
    return response.data;
  }
  static async getContactDetails(params: { email: string }) {
    const endpoint = GET_CONTACT_DETAILS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateUser(
    request: UserUpdateRequest
  ): Promise<PaginatedResponse<UserImageResponse>> {
    const endpoint = USER_UPDATE_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async changePassword(request: {
    password: string;
  }): Promise<PaginatedResponse<UserImageResponse>> {
    const endpoint = CHANGE_PASSWORD_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async resetPasswordFromEmail(request: { email: string }) {
    const endpoint = RESET_PASSWORD_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async setPassword(
    request: AcceptInviteRequest
  ): Promise<PaginatedResponse<UserImageResponse>> {
    const endpoint = SET_PASSWORD_URL;
    const response = await axios.patch(endpoint, request);
    return response.data;
  }

  static async getSchool(params: {
    query: string;
  }): Promise<GetSchoolResponse[]> {
    const endpoint = SCHOOL_URL;
    const response = await axios.get(endpoint, {
      params,
    });
    return response.data;
  }

  static async getLanguage(): Promise<GetLanguageResponse[]> {
    const endpoint = LANGUAGE_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getCustomProfile() {
    const endpoint = GET_CUSTOM_PROFILE_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateCustomProfile(request: CustomProfileRequest) {
    const endpoint = UPDATE_CUSTOM_PROFILE_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getInterests(): Promise<GetInterestResponse[]> {
    const endpoint = INTERESTS_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateProfilePic(binary: ArrayBuffer, fileName?: string) {
    const endpoint = UPDATE_PROFILE_PIC_URL;
    const response = await axios.post(endpoint, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file;filename="${fileName ?? "user"}"`,
      },
    });
    return response.data;
  }
}
