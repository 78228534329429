import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import AtsRow from "../../../components/AtsRow";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import { useInsights } from "../../../providers/InsightsProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import LocationMapContainer from "./LocationMapContainer";
import { GetModifyRequisitionResponse } from "../../../types/insightsTypes";

const JobRequisitionsByLocations = () => {
  const { requisitionsByLocation, requisitionsLoading } = useInsights();
  console.log("requisitionsLoading", requisitionsLoading);
  return (
    <>
      <Box
        sx={{
          p: "25px",
          border: BORDERS.GRAY,
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        <Stack gap={"20px"}>
          <AtsTitleText
            text={"Job requisitions by locations"}
            fs={18}
            fw={600}
          />
          <Box
            sx={{
              borderRadius: "10px",
              height: 400,
              bgcolor: BG_COLORS.PRIMARY,
            }}
          >
            {requisitionsByLocation.length > 0 && (
              <LocationMapContainer
                requisitionsByLocation={requisitionsByLocation}
              />
            )}
            {/* <MapsComponent
              zoomSettings={{
                enable: true,
                zoomFactor: 1,
                pinchZooming: true,
                mouseWheelZoom: true,
              }}
            >
              <Inject services={[Bubble, MapsTooltip, Zoom]} />
              <LayersDirective>
                <LayerDirective
                  shapeData={world_map}
                  shapePropertyPath="name"
                  shapeDataPath="job_location"
                  
                  shapeSettings={{
                    fill: "#E5E5E5",
                  }}
                >
                  <BubblesDirective>
                    <BubbleDirective
                      visible={true}
                      dataSource={bubbleData}
                      latitudeValuePath="latitude"
                      longitudeValuePath="longitude"
                      valuePath="count"
                      bubbleType="Circle"
                      animationDuration={500}
                      minRadius={3}
                      maxRadius={15}
                      colorValuePath="Color"
                      tooltipSettings={{
                        visible: true,
                        valuePath: "count",
                        format:
                          "Internet Users in ${job_location} <br> ${count} ",
                      }}
                    ></BubbleDirective>
                  </BubblesDirective>
                </LayerDirective>
              </LayersDirective>
            </MapsComponent> */}
          </Box>
          <AtsRow gap={"5px"}>
            <>
              <AtsTitleText text={"Bubble size"} fs={12} fw={700} />
              <AtsTitleText text={"-"} fs={12} fw={700} />
              <AtsTitleText
                text={"Number of professionals"}
                fs={12}
                fw={400}
                color={COLORS.LIGHT_GRAY}
              />
            </>
          </AtsRow>
          <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
            <CategoryGridItem
              title={"MOST EMPLOYEES"}
              items={requisitionsByLocation.slice(0, 3)}
              loading={requisitionsLoading}
            />
            <CategoryGridItem
              title={"FAST GROWING"}
              items={requisitionsByLocation
                .sort((a, b) => b.count - a.count)
                .slice(0, 3)}
              loading={requisitionsLoading}
            />
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default JobRequisitionsByLocations;

const CategoryGridItem = ({
  title,
  items,
  loading,
}: {
  title: string;
  items: GetModifyRequisitionResponse[];
  loading: boolean;
}) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
    <Stack gap={"5px"}>
      <Typography
        sx={{
          letterSpacing: "2px",
          fontSize: 12,
          color: COLORS.LIGHT_GRAY,
          mb: "5px",
        }}
      >
        {title}
      </Typography>
      <Stack gap={"5px"}>
        {loading
          ? Array.from(new Array(3)).map((_, index) => (
              <AtsRowBetween key={index}>
                <>
                  <Skeleton width={150} />
                  <Skeleton width={10} height={10} variant="circular" />
                </>
              </AtsRowBetween>
            ))
          : items.map((item, index) => (
              <TextBetweenWrapper
                key={index}
                text={item.job_location}
                value={item.count}
              />
            ))}
      </Stack>
    </Stack>
  </Grid>
);

const TextBetweenWrapper = ({ text, value }) => {
  return (
    <AtsRowBetween>
      <>
        <AtsTitleText text={text} fs={12} fw={500} color={COLORS.DARK_GRAY} />

        <AtsTitleText text={value} fs={12} fw={700} />
      </>
    </AtsRowBetween>
  );
};
