import { Box, Skeleton, Stack } from "@mui/material";
import AtsEmptyState from "../../../components/AtsEmptyState";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useInsights } from "../../../providers/InsightsProvider";
import { BORDERS } from "../../../theme";
import HeadCountGraph from "../components/HeadCountGraph";

const HeadCountOpenPosition = () => {
  const { othersInsightsData, othersInsightsLoading } = useInsights();

  return (
    <div>
      <Box
        sx={{
          p: "25px",
          border: BORDERS.GRAY,
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        <Stack gap={"20px"}>
          <AtsTitleText
            text={"Head count and open positions"}
            fs={18}
            fw={600}
          />
          {othersInsightsLoading ? (
            <AtsRow gap={"10px"} sx={{ width: 350, maxWidth: 350 }}>
              <>
                {[...Array(5)].map((_, index) => (
                  <Box key={index}>
                    <Skeleton variant="rectangular" width={50} height={350} />
                  </Box>
                ))}
              </>
            </AtsRow>
          ) : othersInsightsData &&
            !Array.isArray(othersInsightsData.head_count_and_positions) ? (
            <HeadCountGraph
              hadCountData={othersInsightsData.head_count_and_positions}
            />
          ) : (
            <AtsEmptyState
              sx={{
                minHeight: 232,
              }}
              text="No Head count and open positions"
              subText=""
              imgHeight={100}
              imgWidth={100}
            />
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default HeadCountOpenPosition;
