import { Check } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { BORDERS, COLORS } from "../../../theme";
import { useAppSelector } from "../../../redux/store";
import { getAuthState } from "../../../redux/slices/authSlice";

const CreateAccountStepper = () => {
  const { activeStep, stepperData } = useCreateAccount();

  return (
    <Stack gap={"50px"}>
      <AtsTitleText
        text={"Create your account"}
        textColor={COLORS.PRIMARY}
        fs={56}
        fw={400}
      />
      <Stack gap={"56px"}>
        {stepperData.map((step, index) => (
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"44px"}
            position={"relative"}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border:
                    index === activeStep
                      ? BORDERS.PRIMARY
                      : index < activeStep
                      ? BORDERS.PRIMARY
                      : "1px solid #a6b4d3",
                  borderRadius: "50%",
                  height: 26,
                  width: 26,
                }}
              >
                {index === activeStep ? (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: COLORS.PRIMARY,
                      height: 18,
                      width: 18,
                    }}
                  ></Box>
                ) : index < activeStep ? (
                  <Check
                    sx={{
                      fontSize: 20,
                      color: COLORS.PRIMARY,
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
              {index < stepperData.length - 1 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 27,
                    left: 28 / 2,
                    width: "1px",
                    height: 56,
                    backgroundColor:
                      index < activeStep ? COLORS.PRIMARY : "#a6b4d3",
                    zIndex: 0,
                  }}
                ></Box>
              )}
              <AtsTitleText
                text={step.text}
                textColor={
                  index === activeStep
                    ? COLORS.PRIMARY
                    : index < activeStep
                    ? COLORS.PRIMARY
                    : "#a6b4d3"
                }
                fs={20}
                fw={index === activeStep ? 500 : index < activeStep ? 600 : 400}
              />
            </>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default CreateAccountStepper;
