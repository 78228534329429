import { Close } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import {
  ArrowRight,
  CloseCircle,
  InfoCircle,
  Repeat,
  TickCircle,
} from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import AtsDataTable from "../../../../../../../../../../components/AtsDataTable";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../../components/AtsTitleText";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import { getSubmissionState } from "../../../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../../../redux/store";
import {
  BG_COLORS,
  BORDERS,
  COLORS,
} from "../../../../../../../../../../theme";
import { IColumnProps } from "../../../../../../../../../../types/commonType";
import { StatusHistory } from "../../../../../../../../../../types/makeAnOfferTypes";
import { formattedAmount } from "../../../../../../../../../../utils/common";
import {
  getDateFormatMMMDYYYYY,
  getTime,
} from "../../../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../../../utils/stringUtils";
import OfferDetailsInfo from "./OfferDetailsInfo";

const UnderNegotiation = () => {
  const { candidateDetails } = useAppSelector(getSubmissionState);

  const {
    offerDetails,
    offerStatusLoading,
    updateCandidateOffer,
    formattedCandidateDetails,
  } = useMakeAnOffer();
  const [isModify, setIsModify] = useState<boolean>(false);
  const [rateInput, setRateInput] = useState<string>("");
  const divRef = useRef(null);

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [isModify]);
  const offerHistory = isNotNullOrUndefined(offerDetails)
    ? isNotNullOrUndefined(offerDetails.status_history)
      ? offerDetails.status_history
      : []
    : [];
  const cellStyles = {
    padding: "0px 10px",
  };

  const isCandidateSendOffer = isNotNullOrUndefined(offerDetails)
    ? isNotNullOrUndefined(offerDetails.status_history)
      ? offerDetails.status_history[offerDetails.status_history.length - 1]
          .candidate_id !== candidateDetails?.id &&
        (offerDetails.status_history[offerDetails.status_history.length - 1]
          .status === "Offer Extended" ||
          offerDetails.status_history[offerDetails.status_history.length - 1]
            .status === "Offer Revised")
        ? true
        : false
      : false
    : false;

  const findLastOffer = isNotNullOrUndefined(offerDetails)
    ? isNotNullOrUndefined(offerDetails.status_history)
      ? offerDetails.status_history[offerDetails.status_history.length - 1]
      : null
    : null;

  const isAnnual = offerDetails?.employment_type === "36";

  const LEAD_COLUMN: IColumnProps[] = [
    {
      key: "first_name",
      header: "From",
      width: "auto",
      minWidth: "120px",
    },
    {
      key: "date",
      header: "Date",
      width: "150px",
      minWidth: "150px",
    },
    {
      key: "rate",
      header: isAnnual ? "Salary" : "Salary / Rate per hr",
      width: "auto",
      minWidth: "120px",
    },

    {
      key: "action",
      header: "",
      width: "45px",
      minWidth: "45px",
    },
  ];

  const customizeRow = (column: IColumnProps, list: StatusHistory) => {
    switch (column.key) {
      case "first_name":
        const name = `${list?.first_name ?? ""} ${list?.last_name ?? ""}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={name} />
            </Stack>
          </td>
        );
      case "date":
        const dateWithTime = `${getDateFormatMMMDYYYYY(list.date)}, ${getTime(
          list.date
        )}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={dateWithTime} />
            </Stack>
          </td>
        );
      case "rate":
        const rateText = isAnnual
          ? `$${formattedAmount(list?.salary ?? 0)}/ yr`
          : `$${formattedAmount(list?.rate ?? 0)}/ per hr`;

        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={rateText} />
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };
  const addRow = (column: IColumnProps) => {
    switch (column.key) {
      case "first_name":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={"You"} />
            </Stack>
          </td>
        );
      case "date":
        const dateWithTime = `${getDateFormatMMMDYYYYY(
          new Date().toString()
        )}, ${getTime(new Date().toString())}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={dateWithTime} />
            </Stack>
          </td>
        );
      case "rate":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                width: 50,
              }}
              gap={1}
            >
              <input
                className="inputstyle"
                style={{
                  height: "30px",
                  width: "80px",
                  borderRadius: "5px",
                  border: BORDERS.GRAY,
                  fontSize: "12px",
                  padding: "0px 5px",
                }}
                type="number"
                value={rateInput}
                onChange={(e) => setRateInput(e.target.value)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <IconButton
                onClick={() => setIsModify(false)}
                sx={{
                  p: 1,
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = offerHistory.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              backgroundColor: "white",
              height: "56px",
              margin: "0px 8px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    const extraRow = isModify
      ? Array.from(new Array(1)).map((value, index: number) => {
          return (
            <React.Fragment key={index}>
              <tr
                style={{
                  backgroundColor: "white",
                  height: "56px",
                  margin: "0px 8px",
                  borderBottom: BORDERS.GRAY,
                }}
              >
                {LEAD_COLUMN.map((val: IColumnProps) => addRow(val))}
              </tr>
            </React.Fragment>
          );
        })
      : [];

    return [...leadsData, ...extraRow];
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "10px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsTitleText
              fs={12}
              fw={500}
              text={column.header}
              textColor={COLORS.LIGHT_GRAY}
            />
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  const RejectedBox = () => {
    return (
      <AtsRow
        gap={"6px"}
        sx={{
          px: "20px",
          py: "10px",
          bgcolor: BG_COLORS.RED,
          borderRadius: "10px",
          width: "fit-content",
        }}
      >
        <>
          <Box mt={"3px"}>
            <CloseCircle size="16" color={COLORS.RED} />
          </Box>
          <Typography
            sx={{
              color: COLORS.RED,
              fontWeight: 400,
              fontSize: 12,
            }}
          >
            Offer rejected by{" "}
            <span
              style={{
                fontWeight: 700,
              }}
            >
              {`${findLastOffer?.first_name ?? ""} ${
                findLastOffer?.last_name ?? ""
              }`}
            </span>
          </Typography>
        </>
      </AtsRow>
    );
  };
  return (
    <Stack gap={"20px"}>
      <OfferDetailsInfo />
      <Stack>
        <AtsTitleText
          fs={18}
          fw={600}
          text="Negotiation Details"
          textColor="#000"
        />
      </Stack>
      <Box
        sx={{
          maxHeight: "200px",
          overflow: "auto",
        }}
      >
        <AtsDataTable
          isHidBottomBorder
          isBoxShadow
          data={offerHistory}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          scrollDiv={() => {
            return <div ref={divRef}></div>;
          }}
          loading={offerStatusLoading}
        />
      </Box>
      <Stack gap={"22px"}>
        {offerStatusLoading ? (
          <Skeleton variant="rounded" width={355} height={43} />
        ) : findLastOffer?.status === "Rejected" ? (
          <RejectedBox />
        ) : findLastOffer?.status === "Offer Accepted" ? (
          // <AtsRow
          //   gap={"6px"}
          //   sx={{
          //     px: "20px",
          //     py: "10px",
          //     bgcolor: BG_COLORS.GREEN,
          //     borderRadius: "10px",
          //     width: "fit-content",
          //   }}
          // >
          //   <>
          //     <Box mt={"3px"}>
          //       <TickCircle size="16" color={COLORS.GREEN} />
          //     </Box>
          //     <Typography
          //       sx={{
          //         color: COLORS.GREEN,
          //         fontWeight: 400,
          //         fontSize: 12,
          //       }}
          //     >
          //       Modified rate accepted by{" "}
          //       <span
          //         style={{
          //           fontWeight: 700,
          //         }}
          //       >
          //         {`${findLastOffer?.first_name ?? ""} ${
          //           findLastOffer?.last_name ?? ""
          //         }`}
          //       </span>
          //     </Typography>
          //   </>
          // </AtsRow>
          <></>
        ) : !isCandidateSendOffer ? (
          isModify ? (
            <AtsRow justifyContent={"center"}>
              <AtsRoundedButton
                fs={12}
                fw={600}
                px="10px"
                text="Send"
                buttonHeight={23}
                buttonWidth={82}
                disabled={rateInput.length === 0 || offerStatusLoading}
                endIcon={<ArrowRight size={12} color="white" />}
                loading={offerStatusLoading}
                onClick={async () => {
                  await updateCandidateOffer(
                    "Offer Revised",
                    Number(rateInput)
                  );
                  setRateInput("");
                  setIsModify(false);
                }}
              />
            </AtsRow>
          ) : (
            <AtsRowBetween>
              <>
                <AtsRoundedButton
                  fs={12}
                  fw={600}
                  px="10px"
                  text="Deny"
                  buttonVariant="redContained"
                  buttonHeight={23}
                  buttonWidth={70}
                  disabled={offerStatusLoading}
                  startIcon={<CloseCircle size={12} color="white" />}
                  onClick={async () => {
                    const rate =
                      offerDetails?.employment_type === "36"
                        ? findLastOffer?.salary
                        : findLastOffer?.rate;
                    await updateCandidateOffer("Rejected", rate);
                  }}
                />
                <AtsRoundedButton
                  fs={12}
                  fw={600}
                  px="10px"
                  text="Modify your offer"
                  buttonVariant="outline"
                  buttonHeight={23}
                  buttonWidth={145}
                  startIcon={<Repeat size={12} color={COLORS.PRIMARY} />}
                  onClick={() => setIsModify(!isModify)}
                />
                <AtsRoundedButton
                  fs={12}
                  fw={600}
                  px="10px"
                  text="Accept"
                  buttonVariant="darkGreenContained"
                  buttonHeight={23}
                  buttonWidth={82}
                  disabled={offerStatusLoading}
                  startIcon={<TickCircle size={12} color="white" />}
                  onClick={async () => {
                    const rate =
                      offerDetails?.employment_type === "36"
                        ? findLastOffer?.salary
                        : findLastOffer?.rate;
                    await updateCandidateOffer("Offer Accepted", rate);
                  }}
                />
              </>
            </AtsRowBetween>
          )
        ) : isNotNullOrUndefined(findLastOffer) ? (
          findLastOffer.status !== "Offer Extended" ? (
            <Stack
              sx={{
                px: "20px",
                py: "10px",
                bgcolor: BG_COLORS.GREEN,
                borderRadius: "10px",
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  color: COLORS.GREEN,
                  fontWeight: 400,
                  fontSize: 12,
                }}
              >
                Modified rate sent to{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {formattedCandidateDetails?.name}
                </span>
              </Typography>
              <AtsTitleText text={``} />
            </Stack>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {offerStatusLoading ? (
          <Skeleton variant="rounded" width={355} height={43} />
        ) : (
          findLastOffer?.status !== "Offer Accepted" &&
          findLastOffer?.status !== "Rejected" && (
            <Stack direction={"row"} alignItems={"flex-start"} gap={"6px"}>
              <Box mt={"3px"}>
                <InfoCircle size="16" color="#EC7249" />
              </Box>
              <Typography
                sx={{
                  color: COLORS.BLACK,
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "22px",
                }}
              >
                Your project won’t be confirmed until your service provider
                accepts your request (within{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  24 hours
                </span>
                ).
              </Typography>
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
};

export default UnderNegotiation;
