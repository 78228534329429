import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import AtsTitleText from "../../components/AtsTitleText";
import {
  getInviteMembersState,
  setInitialInviteMemeberState,
} from "../../redux/slices/inviteMembersSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { COLORS } from "../../theme";
import InvitationsSent from "./modules/InvitationsSent";
import InviteMoreMember from "./modules/InviteMoreMember";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InviteMembers = ({ isOpen, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { isSend } = useAppSelector(getInviteMembersState);
  return (
    <Box
      sx={{
        position: "relative",
        py: 3.5,
        px: 4,
        height: "100%",
      }}
    >
      <AtsTitleText
        text={"Invite members"}
        fs={24}
        fw={600}
        textColor={COLORS.BLACK}
      />
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <IconButton
          sx={{
            color: "initial",
          }}
          onClick={() => {
            dispatch(setInitialInviteMemeberState());
            setOpen(!isOpen);
          }}
        >
          <Close sx={{ color: COLORS.BLACK }} />
        </IconButton>
      </Box>

      {isSend ? <InvitationsSent setOpen={setOpen} /> : <InviteMoreMember />}
    </Box>
  );
};

export default InviteMembers;
