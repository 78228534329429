import { Star } from "@mui/icons-material";
import { Skeleton, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../../../../../theme";
import RatingComponent from "../components/RatingComponent";
import { StarRating } from "../../../../../../../../../types/submissionTypes";
import { formateOneDigitNum } from "../../../../../../../../../utils/stringUtils";

interface IProps {
  startRatting: StarRating;
  totalReview: number;
  average_rating_star: number;
  loading: boolean;
}

const AverageRating = ({
  startRatting,
  totalReview,
  average_rating_star,
  loading,
}: IProps) => {
  const progress5 = (startRatting.star_5 / totalReview) * 100;
  const progress4 = (startRatting.star_4 / totalReview) * 100;
  const progress3 = (startRatting.star_3 / totalReview) * 100;
  const progress2 = (startRatting.star_2 / totalReview) * 100;
  const progress1 = (startRatting.star_1 / totalReview) * 100;
  return (
    <>
      <Stack gap={1}>
        <Stack direction={"row"} gap={1}>
          <AtsTitleText
            text={formateOneDigitNum(average_rating_star)}
            fs={34}
            fw={500}
            textColor={COLORS.BLACK}
          />
          <Star sx={{ fontSize: 34, color: "#ffcd34" }} />
        </Stack>
        <AtsTitleText
          text={`Based on ${totalReview} ratings`}
          fs={12}
          fw={500}
          textColor={COLORS.LIGHT_GRAY}
        />
        <Stack gap={0.6} pt={1}>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <AtsTitleText
              text={"5"}
              fs={12}
              fw={500}
              textColor={COLORS.BLACK}
            />
            {loading ? (
              <Skeleton variant="rectangular" width={100} height={5} />
            ) : (
              <RatingComponent progress={progress5} />
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <AtsTitleText
              text={"4"}
              fs={12}
              fw={500}
              textColor={COLORS.BLACK}
            />
            {loading ? (
              <Skeleton variant="rectangular" width={100} height={5} />
            ) : (
              <RatingComponent progress={progress4} />
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <AtsTitleText
              text={"3"}
              fs={12}
              fw={500}
              textColor={COLORS.BLACK}
            />
            {loading ? (
              <Skeleton variant="rectangular" width={100} height={5} />
            ) : (
              <RatingComponent progress={progress3} />
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <AtsTitleText
              text={"2"}
              fs={12}
              fw={500}
              textColor={COLORS.BLACK}
            />
            {loading ? (
              <Skeleton variant="rectangular" width={100} height={5} />
            ) : (
              <RatingComponent progress={progress2} />
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.8}>
            <AtsTitleText
              text={"1"}
              fs={12}
              fw={500}
              textColor={COLORS.BLACK}
            />
            {loading ? (
              <Skeleton variant="rectangular" width={100} height={5} />
            ) : (
              <RatingComponent progress={progress1} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default AverageRating;
