import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProjectService } from "../Api/projectServices";
import { useParams } from "react-router-dom";
import {
  ContractorByProjectResponse,
  CreateUpdateTaskRequest,
  TasksListResponse,
} from "../types/projectTypes";
import { toast } from "react-toastify";
import { TaskStatusEnum } from "../enums/commonEnums";
import { useDebounce } from "../hooks/useDebounce";
import { title } from "process";

type TaskModuleContextType = {
  loading: boolean;
  searchValue: string;
  tasks: TasksListResponse[];
  contractors: ContractorByProjectResponse[];
  setTasks: React.Dispatch<React.SetStateAction<TasksListResponse[]>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  addNewTaskFunc: (request: CreateUpdateTaskRequest) => void;
  updateTaskFunc: (request: CreateUpdateTaskRequest) => void;
  fetchTaskList: () => void;
  getContractorsList: () => void;
  deleteTaskFunc: (nid: string) => void;
  selectedStatus: TaskStatusEnum;
  setSelectedStatus: React.Dispatch<React.SetStateAction<TaskStatusEnum>>;
};

const TaskModule = createContext<TaskModuleContextType | null>(null);

export const useTask = () => useContext(TaskModule) as TaskModuleContextType;

export const TaskProvider = ({ children }: any) => {
  const { id } = useParams();
  const [tasks, setTasks] = useState<TasksListResponse[]>([]);
  const [contractors, setContractors] = useState<ContractorByProjectResponse[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [totlaItem, setTotalItem] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearch = useDebounce(searchValue);
  const [selectedStatus, setSelectedStatus] = useState<TaskStatusEnum>(
    TaskStatusEnum.All
  );

  const addNewTaskFunc = useCallback(
    async (request: CreateUpdateTaskRequest) => {
      setLoading(true);
      try {
        const response = await ProjectService.createTask(request);
        toast.success(response.message);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const updateTaskFunc = useCallback(
    async (request: CreateUpdateTaskRequest) => {
      setLoading(true);
      try {
        const response = await ProjectService.updateTask(request);
        toast.success(response.message);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const deleteTaskFunc = useCallback(async (nid: string) => {
    setLoading(true);
    try {
      const response = await ProjectService.deleteTask({ nid });
      fetchTaskList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getContractorsList = useCallback(async () => {
    try {
      const response = await ProjectService.contractorListByProjectId(id, {});
      setContractors(response.results);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  const fetchTaskList = async () => {
    setLoading(true);
    try {
      const taskListBody = {
        ...(selectedStatus !== TaskStatusEnum.All && {
          status: selectedStatus,
        }),
        ...(searchValue.length && {
          title: searchValue,
        }),
      };
      const response = await ProjectService.taskListByProjectId(
        id,
        taskListBody
      );
      setTasks(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskList();
  }, [selectedStatus, debouncedSearch]);

  return (
    <TaskModule.Provider
      value={{
        loading,
        tasks,
        selectedStatus,
        searchValue,
        contractors,
        setSearchValue,
        setSelectedStatus,
        setTasks,
        fetchTaskList,
        addNewTaskFunc,
        updateTaskFunc,
        deleteTaskFunc,
        getContractorsList,
      }}
    >
      {children}
    </TaskModule.Provider>
  );
};
