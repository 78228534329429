import React, { useMemo } from "react";
import StepWrapper from "../../components/common/StepWrapper";
import { ATS_ROUTES } from "../../router/routes";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  getProjectsState,
  setInitialProjectState,
  setIsProjectQuestionRoute,
} from "../../redux/slices/projectsSlice";
import CreateProjectStepper from "./modules/CreateNewProjectModules/CreateProjectStepper";
import ProjectCreated from "./modules/CreateNewProjectModules/ProjectCreated";
import ProjectQuestion from "./modules/CreateNewProjectModules/ProjectQuestion";
import { useNavigate } from "react-router-dom";
import AddProjectDetails from "./modules/CreateNewProjectModules/AddProjectDetails";
import SetProjectVisibility from "./modules/CreateNewProjectModules/SetProjectVisibility";
import SelectStartEndDate from "./modules/CreateNewProjectModules/SelectStartEndDate";
import ProjectLoaction from "./modules/CreateNewProjectModules/ProjectLoaction";
import ProjectBudget from "./modules/CreateNewProjectModules/ProjectBudget";
import ProjectDocument from "./modules/CreateNewProjectModules/ProjectDocument";
import ExistingMembers from "./modules/CreateNewProjectModules/ExistingMembers";
import ChooseProjectImage from "./modules/CreateNewProjectModules/ChooseProjectImage";
import ReviewProjectDetails from "./modules/CreateNewProjectModules/ReviewProjectDetails";

const CreateNewProject = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isCreatedProject,
    isProjectQuestionRoute,
    activeProjectStep,
    activeProjectStep2,
    activeProjectStep3,
  } = useAppSelector(getProjectsState);

  const AddProjectDetailsMemo = useMemo(() => <AddProjectDetails />, []);
  const SetProjectVisibilityMemo = useMemo(() => <SetProjectVisibility />, []);
  const SelectStartEndDateMemo = useMemo(() => <SelectStartEndDate />, []);
  const ProjectLoactionMemo = useMemo(() => <ProjectLoaction />, []);
  const ProjectBudgetMemo = useMemo(() => <ProjectBudget />, []);
  const ProjectDocumentMemo = useMemo(() => <ProjectDocument />, []);
  const ExistingMembersMemo = useMemo(() => <ExistingMembers />, []);
  const ChooseProjectImageMemo = useMemo(() => <ChooseProjectImage />, []);
  const ReviewProjectDetailsMemo = useMemo(() => <ReviewProjectDetails />, []);

  const stepMap = {
    1: AddProjectDetailsMemo,
    2: SetProjectVisibilityMemo,
    3: SelectStartEndDateMemo,
  };

  const step2Map = {
    1: ProjectLoactionMemo,
    2: ProjectBudgetMemo,
    3: ProjectDocumentMemo,
  };

  const step3Map = {
    1: ExistingMembersMemo,
    2: ChooseProjectImageMemo,
    3: ReviewProjectDetailsMemo,
  };

  const renderComponent = () => {
    if (activeProjectStep <= 3 && activeProjectStep2 === 0) {
      return stepMap[activeProjectStep] || AddProjectDetailsMemo;
    } else if (
      activeProjectStep === 3 &&
      activeProjectStep2 <= 3 &&
      activeProjectStep3 === 0
    ) {
      return step2Map[activeProjectStep2] || ProjectLoactionMemo;
    } else if (activeProjectStep2 === 3 && activeProjectStep3 <= 3) {
      return step3Map[activeProjectStep3] || ExistingMembersMemo;
    }
  };
  return (
    <>
      <StepWrapper
        headerText="Create a project"
        backText="Back to projects"
        backRoute={() => {
          navigate(ATS_ROUTES.PROJECTS);
          dispatch(setInitialProjectState());
        }}
        questionAction={() => dispatch(setIsProjectQuestionRoute(true))}
        isQuestionRoute={isProjectQuestionRoute}
        isFinalStep={isCreatedProject}
        QuestionComponent={ProjectQuestion}
        FinalStepComponent={ProjectCreated}
        StepperComponent={CreateProjectStepper}
        renderStepComponent={renderComponent}
        isImgShow={true}
      />
    </>
  );
};

export default CreateNewProject;
