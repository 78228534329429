import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useAppDispatch } from "../../../redux/store";
import {
  setInitialInviteMemeberState,
  setIsSend,
} from "../../../redux/slices/inviteMembersSlice";
import { setIsOpenSubMenu } from "../../../redux/slices/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvitationsSent = ({ setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          minHeight: 550,
          borderBottom: BORDERS.GRAY,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          gap={4}
          sx={{
            textAlign: "center",
          }}
        >
          <Box>
            <img
              width={45}
              src={require("../../PostOpportunity/assets/images/check.gif")}
              alt="check"
            />
          </Box>
          <AtsTitleText
            text={"Invitations sent successfully"}
            fs={24}
            fw={400}
            textColor="#000"
          />
          <Stack
            sx={{
              borderRadius: 2,
              bgcolor: BG_COLORS.GREEN,
              minWidth: 475,
              py: 1.2,
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: COLORS.GREEN,
              }}
            >
              Track{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                recent invitations
              </span>{" "}
              under the pending tab.
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction={"row"}
        sx={{
          pt: "30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AtsRoundedButton
          buttonHeight={42}
          fs={14}
          fw={500}
          isInitial
          text="Invite more members"
          endIcon={<KeyboardArrowRight />}
          ml={0}
          buttonWidth={196}
          buttonVariant="outline"
          onClick={() => {
            dispatch(setIsSend(false));
          }}
        />
        <AtsRoundedButton
          buttonHeight={42}
          fs={14}
          fw={500}
          buttonWidth={146}
          isInitial
          text="Go to user list"
          endIcon={<KeyboardArrowRight />}
          ml={0}
          onClick={() => {
            setOpen(false);
            dispatch(setInitialInviteMemeberState());
            dispatch(setIsOpenSubMenu(true));
            navigate(ATS_ROUTES.USER_MANAGEMENT);
          }}
        />
      </Stack>
    </>
  );
};

export default InvitationsSent;
