import { Box, Grid, Skeleton, Stack } from "@mui/material";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";

const LoadingSOWContract = () => {
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 161,
          mx: "9px",
          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 177,
                minHeight: 161,
                bgcolor: BG_COLORS.PRIMARY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <img src={require("../assets/images/sowDocs.png")} alt="" />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <img src={require("../assets/images/talentSOW.png")} alt="" />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 161,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      <Grid container spacing={"20px"}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"SOW No."} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"Contractor Name"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"Job"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"Start date"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"End date"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"Project"} />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      minWidth: 202,

                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pr: "19px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton variant="rounded" width={119} height={26} />
                  </Box>
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
    </>
  );
};

export default LoadingSOWContract;

const TextWrapper = ({ text }) => {
  return (
    <Stack gap={"2px"}>
      <AtsTitleText
        text={text}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
      />
      <Skeleton variant="text" width={"100%"} />
    </Stack>
  );
};
