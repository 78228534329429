import { createSlice } from "@reduxjs/toolkit";
import {
  CandidateListResponse,
  ContractorRoleResponse,
} from "../../types/projectTypes";
import { RootState } from "../store";
import { DisputeTabsEnum } from "../../types/contractorTypes";

export interface ContractorTimesheetProps {
  id: string;
  date: string;
  task: string;
  DescriptionOfWork: string;
  startEndTime: string;
  time: string;
  isApproved: boolean;
  isDisputed: boolean;
}

export const pendingUser: ContractorTimesheetProps[] = [
  {
    id: "1",
    date: "Aug 05, 2024",
    task: "Create a new API and fix bugs",
    DescriptionOfWork: "Lorem ipsum dolor sit...",
    startEndTime: "9:00 am to 2:00 pm",
    time: "10.00 hrs",
    isApproved: false,
    isDisputed: false,
  },
  {
    id: "2",
    date: "Aug 05, 2024",
    task: "Create a new API and fix bugs",
    DescriptionOfWork: "Lorem ipsum dolor sit...",
    startEndTime: "9:00 am to 2:00 pm",
    time: "10.00 hrs",
    isApproved: false,
    isDisputed: false,
  },
  {
    id: "3",
    date: "Aug 05, 2024",
    task: "Create a new API and fix bugs",
    DescriptionOfWork: "Lorem ipsum dolor sit...",
    startEndTime: "9:00 am to 2:00 pm",
    time: "10.00 hrs",
    isApproved: false,
    isDisputed: false,
  },
];

interface ContractorTimesheetSliceState {
  isDisputed: boolean;
  isApprovedAll: boolean;
  showDisputeModal: boolean;
  selectedContractorTimesheets: ContractorTimesheetProps[];
  timesheetsData: ContractorTimesheetProps[];
  selectedDisputeFlowTab: string;
}

const initialState: ContractorTimesheetSliceState = {
  isDisputed: false,
  isApprovedAll: false,
  showDisputeModal: false,
  selectedContractorTimesheets: [],
  timesheetsData: pendingUser,
  selectedDisputeFlowTab: DisputeTabsEnum.DisputeEntries,
};

const contractorTimesheetSlice = createSlice({
  name: "contractorTimesheet",
  initialState,
  reducers: {
    setSelectedContractorTimesheets: (
      state: ContractorTimesheetSliceState,
      action
    ) => {
      const { payload } = action;
      state.selectedContractorTimesheets = payload;
    },
    setSelectedDisputeFlowTab: (
      state: ContractorTimesheetSliceState,
      action
    ) => {
      const { payload } = action;
      state.selectedDisputeFlowTab = payload;
    },
    setShowDisputeModal: (state: ContractorTimesheetSliceState, action) => {
      const { payload } = action;
      state.showDisputeModal = payload;
    },
    setIsDisputed: (state: ContractorTimesheetSliceState, action) => {
      const { payload } = action;
      state.isDisputed = payload;
    },
    setIsApprovedAll: (state: ContractorTimesheetSliceState, action) => {
      const { payload } = action;
      state.isApprovedAll = payload;
    },
    setTimesheetsData: (state: ContractorTimesheetSliceState, action) => {
      const { payload } = action;
      state.timesheetsData = payload;
    },
    setInitialContractorTimeSheetState: () => initialState,
  },
});

export const {
  setShowDisputeModal,
  setSelectedDisputeFlowTab,
  setIsDisputed,
  setIsApprovedAll,
  setTimesheetsData,
  setSelectedContractorTimesheets,
  setInitialContractorTimeSheetState,
} = contractorTimesheetSlice.actions;
export const getContractorTimesheetState = (state: RootState) =>
  state.contractorTimesheet;
export default contractorTimesheetSlice.reducer;
