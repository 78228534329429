import { Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../components/AtsTitleText";
import AtsIcon from "../../../../../components/AtsIcon";
import { COLORS } from "../../../../../theme";
import { useMessages } from "../../../../../providers/MessagesProvider";
import linkdinImg from "../../../../../assets/images/linkdin.jpg";

const ContactInformation = () => {
  const { messengerState } = useMessages();
  return (
    <Stack gap={"12px"}>
      <AtsTitleText text={"Contact Information"} fs={14} fw={600} noTextWrap />
      <Stack gap={"17px"}>
        <RenderTextWrapper
          keyvalue="Email address"
          value={messengerState?.email}
          icon="sms"
        />
        <RenderTextWrapperWithImg
          keyvalue="Phone number"
          value={"+1 (455) 220 - 1654"}
          Icon={
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.646 12.22c0 .24-.053.487-.166.727a2.75 2.75 0 0 1-.454.68c-.326.36-.686.62-1.093.786-.4.167-.833.254-1.3.254-.68 0-1.407-.16-2.173-.487a11.703 11.703 0 0 1-2.294-1.32 19.173 19.173 0 0 1-2.186-1.867 18.95 18.95 0 0 1-1.86-2.18c-.547-.76-.987-1.52-1.307-2.273-.32-.76-.48-1.487-.48-2.18 0-.453.08-.887.24-1.287.16-.406.413-.78.767-1.113.426-.42.893-.627 1.386-.627.187 0 .374.04.54.12.174.08.327.2.447.374l1.547 2.18c.12.166.206.32.266.466.06.14.094.28.094.407 0 .16-.047.32-.14.473a2.267 2.267 0 0 1-.374.474l-.506.526a.357.357 0 0 0-.107.267c0 .053.007.1.02.153.02.054.04.094.053.134.12.22.327.506.62.853.3.347.62.7.967 1.053.36.354.707.68 1.06.98.347.294.633.494.86.614.033.013.073.033.12.053a.46.46 0 0 0 .167.027c.113 0 .2-.04.273-.114l.507-.5c.166-.166.326-.293.48-.373a.888.888 0 0 1 .473-.14c.127 0 .26.027.407.087.146.06.3.146.466.26l2.207 1.566c.173.12.293.26.367.427.066.167.106.333.106.52z"
                  stroke="#231F20"
                  strokeMiterlimit="10"
                />
                <path
                  d="M12.333 6c0-.4-.313-1.013-.78-1.513-.426-.46-.993-.82-1.553-.82M14.667 6A4.663 4.663 0 0 0 10 1.333"
                  stroke="#231F20"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          }
        />
        <RenderTextWrapperWithImg
          keyvalue="LinkedIn"
          value={"linkedin.com/marisolshelton"}
          Icon={
            <>
              <img width={16} height={16} src={linkdinImg} alt="images" />
            </>
          }
        />
      </Stack>
    </Stack>
  );
};

export default ContactInformation;

const RenderTextWrapper = ({
  keyvalue,
  value,
  icon,
}: {
  keyvalue: string;
  value: string;
  icon: string;
}) => {
  return (
    <Stack direction={"row"} alignItems={"flex-start"} gap={"16px"}>
      <AtsIcon icon={`icon-${icon}`} height="16px" fs={16} />
      <Stack>
        <AtsTitleText
          text={keyvalue}
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          noTextWrap
        />
        <AtsTitleText text={value} fs={14} fw={400} noTextWrap />
      </Stack>
    </Stack>
  );
};
const RenderTextWrapperWithImg = ({
  keyvalue,
  value,
  Icon,
}: {
  keyvalue: string;
  value: string;
  Icon: any;
}) => {
  return (
    <Stack direction={"row"} alignItems={"flex-start"} gap={"16px"}>
      {Icon}
      <Stack>
        <AtsTitleText
          text={keyvalue}
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          noTextWrap
        />
        <AtsTitleText text={value} fs={14} fw={400} noTextWrap />
      </Stack>
    </Stack>
  );
};
