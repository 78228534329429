import { Box, Stack } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { IconButton } from "@mui/material";

interface IProps {
  heading: string;
  children: JSX.Element;
  renderRightComponent?: JSX.Element;
}

const FormWrapper = ({ heading, children, renderRightComponent }: IProps) => {
  return (
    <Box
      sx={{
        border: BORDERS.GRAY,
        borderRadius: "10px",
        p: "30px",
      }}
    >
      <AtsRowBetween>
        <>
          <AtsTitleText
            text={heading}
            fs={16}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
          {renderRightComponent && <>{renderRightComponent}</>}
        </>
      </AtsRowBetween>
      {children}
    </Box>
  );
};

export default FormWrapper;
