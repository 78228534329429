import React from "react";
import AtsRow from "../../../../../../../../../components/AtsRow";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { Divider } from "@mui/material";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";

interface IProps {
  placeholder?: string;
  keyValue: string;
  value: string;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadInput = ({
  placeholder,
  keyValue,
  handleFileChange,
  handleChange,
  value,
}: IProps) => {
  return (
    <AtsRow
      gap={"19px"}
      sx={{
        borderRadius: "8px",
        border: BORDERS.GRAY,
        px: "21px",
        py: "12px",
      }}
    >
      <>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder ?? ""}
          className="customInput"
          style={{
            flexGrow: 1,
            marginBottom: "26px",
          }}
        />
        <Divider variant="fullWidth" orientation="vertical" flexItem />
        <label
          htmlFor={`upload_${keyValue}`}
          style={{
            marginBottom: "0px",
            cursor: "pointer",
          }}
        >
          <AtsRow gap={"5px"}>
            <>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.979 9v2.625a2.628 2.628 0 0 0 2.625 2.625 2.628 2.628 0 0 0 2.625-2.625V7.5a5.246 5.246 0 0 0-5.25-5.25 5.246 5.246 0 0 0-5.25 5.25V12c0 2.482 2.017 4.5 4.5 4.5"
                  stroke="#36C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <AtsTitleText
                text={"Upload file"}
                fs={14}
                fw={600}
                textColor={COLORS.PRIMARY}
              />
            </>
          </AtsRow>
          <input
            multiple
            type="file"
            id={`upload_${keyValue}`}
            accept=".pdf, .csv"
            hidden
            onChange={(e) => handleFileChange(e)}
          />
        </label>
      </>
    </AtsRow>
  );
};

export default UploadInput;
