import { Skeleton, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { ProjectTimesheetTabsEnum } from "../../../../../../../enums/projectEnums";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import { COLORS } from "../../../../../../../theme";
import { formattedAmount } from "../../../../../../../utils/common";
import { isNotNullOrUndefined } from "../../../../../../../utils/stringUtils";

interface IProps {
  email: string;
}

const DisputedColumn = ({ email }: IProps) => {
  const { selectedStatus, timelogLoading, candidateTimelog } = useTimesheet();
  const getTimeLog = () => {
    const findLog = candidateTimelog.find((log) => log.mail === email);
    if (findLog) {
      return (
        <AtsTitleText
          fs={14}
          fw={selectedStatus === ProjectTimesheetTabsEnum.Disputed ? 600 : 400}
          textColor={
            selectedStatus === ProjectTimesheetTabsEnum.Disputed
              ? COLORS.RED
              : COLORS.BLACK
          }
          text={`${formattedAmount(
            selectedStatus === ProjectTimesheetTabsEnum.Approved
              ? isNotNullOrUndefined(findLog?.approved_time)
                ? findLog?.approved_time
                : 0
              : selectedStatus === ProjectTimesheetTabsEnum.Disputed
              ? isNotNullOrUndefined(findLog?.disputed_time)
                ? findLog?.disputed_time
                : 0
              : isNotNullOrUndefined(findLog?.pending_time)
              ? findLog?.pending_time
              : 0
          )} hrs`}
        />
      );
    } else {
      return (
        <AtsTitleText
          fs={14}
          fw={400}
          textColor={COLORS.BLACK}
          text={`0.00 hrs`}
        />
      );
    }
  };
  return (
    <>
      <Stack direction={"row"} gap={1}>
        {timelogLoading ? (
          <Skeleton variant="text" width={50} height={25} />
        ) : (
          getTimeLog()
        )}
      </Stack>
    </>
  );
};

export default DisputedColumn;
