import React, { useEffect, useMemo, useState } from "react";
import AtsCard from "../../../../../../../components/AtsCard";
import { Box, Skeleton } from "@mui/material";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { FilterIcon } from "../../../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import { Stack } from "@mui/material";
import { IColumnProps } from "../../../../../../../types/commonType";
import { BORDERS, COLORS } from "../../../../../../../theme";
import AtsIcon from "../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { ProjectContractorsStatusEnum } from "../../../../../../../enums/projectEnums";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import {
  getContractorDetailsState,
  setContractorEffortsDetails,
  setContractorOnboardingDetails,
  setContractorPlacmentDetails,
  setIsContractorDetailsDrawerOpen,
  setIsContractorOnboarded,
  setSelectedContractorDrawerTab,
} from "../../../../../../../redux/slices/contractorSlice";
import { ProjectService } from "../../../../../../../Api/projectServices";
import { useParams } from "react-router-dom";
import {
  CandidateListResponse,
  ContractorByProjectResponse,
} from "../../../../../../../types/projectTypes";
import {
  setCandidateDetails,
  setIsCandidateDetailsLoading,
} from "../../../../../../../redux/slices/submissionSlice";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import { SubmissionService } from "../../../../../../../Api/submissionServices";
import ContractorDetailsDrawer from "../../ContractorDetails/ContractorDetailsDrawer";
import { OnboardingServices } from "../../../../../../../Api/onboardingServices";
import { isNotNullOrUndefined } from "../../../../../../../utils/stringUtils";
import { TimesheetServices } from "../../../../../../../Api/timesheetServices";
import { getAuthState } from "../../../../../../../redux/slices/authSlice";
import AtsNoUserImage from "../../../../../../../components/AtsNoUserImage";
import AtsEmptyState from "../../../../../../../components/AtsEmptyState";
import AtsUserNameTextAvatar from "../../../../../../../components/AtsUserNameTextAvatar";

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Team Member",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

interface IProps {
  status: ProjectContractorsStatusEnum;
}

const ContractorsActiveList = ({ status }: IProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const { selectedContractor, isContractorDetailsDrawerOpen } = useAppSelector(
    getContractorDetailsState
  );
  const { setSelectedContractorTimesheet } = useTimesheet();
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [contractors, setContractors] = useState<CandidateListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getContractorDetails = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getContractorsForProject({
        project_id: id,
        job_id: selectedContractor?.nid,
        status,
      });
      setContractors(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Object.keys(selectedContractor).length && getContractorDetails();
  }, [selectedContractor]);

  const openContractDetailsDrawer = (contractor: CandidateListResponse) => {
    setSelectedContractorTimesheet(contractor);
    getCandidateDetials(contractor.candidate_id);

    dispatch(setIsContractorDetailsDrawerOpen(true));
    dispatch(setSelectedContractorDrawerTab(0));
  };
  const getEfforts = async (email: string) => {
    try {
      const response = await TimesheetServices.getCandidateEfforts({
        email,
        project_id: id,
        company_id: userInfo?.company?.id,
      });
      if (response) {
        dispatch(setContractorEffortsDetails(response));
      }
    } catch (error) {}
  };
  const getPlacement = async (email: string) => {
    try {
      const response = await OnboardingServices.getCandidatePlacementDetails({
        project_id: id,
        email,
      });
      if (response && response.length) {
        dispatch(setContractorPlacmentDetails(response[0]));
        return response;
      }
    } catch (error) {}
  };
  const getContractOnboardingDetails = async (email: string) => {
    try {
      const response = await OnboardingServices.getOnboardingDetails({
        project_id: id,
        email,
      });
      if (response) {
        dispatch(setContractorOnboardingDetails(response));
        dispatch(setIsContractorOnboarded(true));
      }
    } catch (error) {
      dispatch(setContractorOnboardingDetails(null));
      dispatch(setIsContractorOnboarded(false));
    }
  };

  const getCandidateDetials = async (id: string) => {
    dispatch(setIsCandidateDetailsLoading(true));
    try {
      const response = await SubmissionService.getCandidateDetailsById({ id });
      if (response.results.length) {
        dispatch(setCandidateDetails(response.results[0]));
        const placementResponse = await getPlacement(
          response.results[0].email_address
        );
        await getEfforts(response.results[0].email_address);
        if (placementResponse) {
          placementResponse[0]?.onboarding_status !== "Pending" &&
            getContractOnboardingDetails(response.results[0].email_address);
        }
      } else {
        dispatch(setCandidateDetails(null));
      }
    } catch (error) {
    } finally {
      dispatch(setIsCandidateDetailsLoading(false));
    }
  };

  const customizeRow = (
    column: IColumnProps,
    contractor: CandidateListResponse,
    index: number
  ) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  padding: 10,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                  <Stack>
                    {isNotNullOrUndefined(contractor.image) ? (
                      <img
                        src={contractor.image}
                        alt=""
                        width={58}
                        height={58}
                        style={{
                          borderRadius: "50%",
                          marginRight: "12px",
                        }}
                      />
                    ) : (
                      <Stack
                        sx={{
                          mr: "12px",
                          width: 58,
                          height: 58,
                        }}
                      >
                        <AtsUserNameTextAvatar
                          name={`${contractor.first_name} ${contractor.last_name}`}
                        />
                      </Stack>
                    )}
                  </Stack>
                  <Stack gap={1}>
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      textColor="#000"
                      text={`${contractor.first_name} ${contractor.last_name}`}
                    />

                    <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={selectedContractor?.title ?? ""}
                    />
                    {/* <AtsTitleText
                      fs={12}
                      fw={500}
                      text={contractor?.team ?? "Java Team"}
                    /> */}
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={contractor[column.key]}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton
              px={0.6}
              onClick={() => openContractDetailsDrawer(contractor)}
            >
              <AtsIcon icon="icon-eye" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = contractors.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: "pointer",
              backgroundColor:
                value.candidate_id === hoveredRow ? "#f2f6ff" : "white",
            }}
            onMouseEnter={() => setHoveredRow(value.candidate_id)}
            onMouseLeave={() => {
              setHoveredRow(null);
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) =>
              customizeRow(val, value, index)
            )}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px 5px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  const contractorDetailsDrawerMemo = useMemo(
    () => <ContractorDetailsDrawer />,
    []
  );

  return (
    <>
      <Stack gap={"13px"}>
        <AtsTitleText
          fs={14}
          fw={600}
          text={
            contractors.length > 0 &&
            `${contractors.length} ${
              status === ProjectContractorsStatusEnum.ACTIVE
                ? "Active"
                : "Inactive"
            } contractors`
          }
        />

        <Stack gap={"7px"}>
          {loading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Skeleton
                key={index}
                width={"100%"}
                variant="rounded"
                height={50}
              />
            ))
          ) : contractors.length > 0 ? (
            <AtsCard sx={{ mb: "10px" }} isOnlyBorder>
              <Box
                sx={{
                  px: "19px",
                  pb: "27px",

                  // py: 2,
                }}
              >
                <AtsDataTable
                  // isTopBorder
                  isNotStickyHeader
                  data={contractors}
                  theadComponent={headerComponent}
                  rowComponent={renderRowComponent}
                  loading={loading}
                />
              </Box>
            </AtsCard>
          ) : (
            <AtsEmptyState
              imgHeight={68}
              imgWidth={74}
              text={`No ${
                status === ProjectContractorsStatusEnum.ACTIVE
                  ? "Active"
                  : "Inactive"
              } contractors recently.`}
              subText=""
            />
          )}
        </Stack>
      </Stack>
      {isContractorDetailsDrawerOpen && contractorDetailsDrawerMemo}
    </>
  );
};

export default ContractorsActiveList;
