import axios from "axios";
import { Pager, PaginatedResponse } from "../types/commonType";
import { JobListingResponse } from "../types/jobsTypes";
import {
  ActiveJobsForProjectParams,
  CandidateListResponse,
  ContractorByProjectResponse,
  ContractorRoleResponse,
  CotractorsForProjectParams,
  CreateAndUpdateProjectDocsRequest,
  CreateMemberRequest,
  createProjectParams,
  CreateUpdateTaskRequest,
  CreateUpdateTaskResponse,
  ProjectCreationResponse,
  ProjectDetailsParams,
  ProjectDocsListResponse,
  ProjectDocumantResponse,
  ProjectDocumentResponse,
  ProjectIdParams,
  ProjectListParams,
  ProjectListProps,
  projectResponse,
  ProjectStats,
  ProjectStatsParams,
  RolesForProjectParams,
  TaskListParams,
  TasksListResponse,
  TeamMemberProps,
} from "../types/projectTypes";
import { isNotNullOrUndefined } from "../utils/stringUtils";
import { PROJECT_BASE_URL } from "../utils/url";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  UPLOAD_BASE: "/file/upload/node/",
  MY_PROJECT: "my-projects/get?_format=json",
  TEAM_MEMBER: "team-members",
  UPLOAD_FILE: "project_documents/field_ats_attchments?_format=json",
  UPLOAD_LOGO: "project/field_ats_project_image?_format=json",
  CREATE_PROJECT: "project/create?_format=json",
  UPDATE_PROJECT: "project/update?_format=json",
  PROJECT_LIST: "projects",
  PROJECT_STATS: "project-stats?_format=json",
  PROJECT_DETAILS: "project/get?_format=json",
  PROJECT_ACTIVE_JOBS: "project-active-jobs",
  PROJECT_ROLES: "project-placement-jobs",
  PROJECT_CONTRACTORS: "project-contractors",
  DOCUMENT_BY_PROJECTID: "project-documents",
  DOCUMENT_TYPE: "project-document-types",
  CREATE_PROJECT_DOCUMENT: "project-documents/create",
  UPDATE_PROJECT_DOCUMENT: "project-documents/update",
  UPDATE_PROJECT_MEMBER: "project-members-update",
  ACTIVE_PROJECT_MEMBER: "project-active-team-members",
  INACTIVE_PROJECT_MEMBER: "project-in-active-team-members",
  ///////////// TASK \\\\\\\\\\\\\\\\\
  TASK_LIST_BY_PROJECT_ID: "tasks/project",
  TASK_CREATE: "task/create",
  TASK_UPDATE: "task/edit",
  TASK_DELETE: "task/delete",
  CONTRACTOR_LIST_BY_PROJECT_ID: "contractors/get-by-project-id",

  ///////////// TASK \\\\\\\\\\\\\\\\\
};
const PROJECT_ENDPOINT = `${PROJECT_BASE_URL}${Endpoint.BASE}`;
const MY_PROJECT_URL = PROJECT_ENDPOINT + Endpoint.MY_PROJECT;
const TEAM_MEMBER_URL = Endpoint.BASE + Endpoint.TEAM_MEMBER;
const UPLOAD_FILE_URL =
  PROJECT_BASE_URL + Endpoint.UPLOAD_BASE + Endpoint.UPLOAD_FILE;
const UPLOAD_LOGO_URL =
  PROJECT_BASE_URL + Endpoint.UPLOAD_BASE + Endpoint.UPLOAD_LOGO;
const CREATE_PROJECT_URL = PROJECT_ENDPOINT + Endpoint.CREATE_PROJECT;
const UPDATE_PROJECT_URL = PROJECT_ENDPOINT + Endpoint.UPDATE_PROJECT;
const PROJECT_LIST_URL = PROJECT_ENDPOINT + Endpoint.PROJECT_LIST;
const PROJECT_DETAILS_URL = PROJECT_ENDPOINT + Endpoint.PROJECT_DETAILS;
const PROJECT_STATS_URL = Endpoint.BASE + Endpoint.PROJECT_STATS;
const PROJECT_ACTIVE_JOBS_URL = Endpoint.BASE + Endpoint.PROJECT_ACTIVE_JOBS;
const PROJECT_ROLES_URL = Endpoint.BASE + Endpoint.PROJECT_ROLES;
const PROJECT_CONTRACTORS_URL = Endpoint.BASE + Endpoint.PROJECT_CONTRACTORS;
const DOCUMENT_BY_PROJECTID_URL =
  PROJECT_ENDPOINT + Endpoint.DOCUMENT_BY_PROJECTID;
const DOCUMENT_TYPE_URL = PROJECT_ENDPOINT + Endpoint.DOCUMENT_TYPE;
const CREATE_PROJECT_DOCUMENT_URL =
  PROJECT_ENDPOINT + Endpoint.CREATE_PROJECT_DOCUMENT;
const UPDATE_PROJECT_DOCUMENT_URL =
  PROJECT_ENDPOINT + Endpoint.UPDATE_PROJECT_DOCUMENT;
const UPDATE_PROJECT_MEMBER_URL =
  Endpoint.BASE + Endpoint.UPDATE_PROJECT_MEMBER;
const ACTIVE_PROJECT_MEMBER_URL =
  Endpoint.BASE + Endpoint.ACTIVE_PROJECT_MEMBER;
const INACTIVE_PROJECT_MEMBER_URL =
  Endpoint.BASE + Endpoint.INACTIVE_PROJECT_MEMBER;

//////////////////////// TASKS \\\\\\\\\\\\\\\\\\\\\\\\\\
const TASK_LIST_BY_PROJECT_ID_URL =
  PROJECT_ENDPOINT + Endpoint.TASK_LIST_BY_PROJECT_ID;
const TASK_CREATE_URL = PROJECT_ENDPOINT + Endpoint.TASK_CREATE;
const TASK_UPDATE_URL = PROJECT_ENDPOINT + Endpoint.TASK_UPDATE;
const TASK_DELETE_URL = PROJECT_ENDPOINT + Endpoint.TASK_DELETE;
const CONTRACTOR_LIST_BY_PROJECT_ID_URL =
  Endpoint.BASE + Endpoint.CONTRACTOR_LIST_BY_PROJECT_ID;
//////////////////////// TASKS \\\\\\\\\\\\\\\\\\\\\\\\\\

export class ProjectService {
  static async getMyProject(
    params: Partial<Pager>
  ): Promise<PaginatedResponse<projectResponse>> {
    const endpoint = MY_PROJECT_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getProjectMember(
    params: Partial<Pager>
  ): Promise<PaginatedResponse<TeamMemberProps>> {
    const response = await axios.get(TEAM_MEMBER_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async uploadDocumentForProject(
    binary: ArrayBuffer,
    fileName?: string
  ): Promise<any> {
    const name = isNotNullOrUndefined(fileName) ? fileName : "test.pdf";
    const response = await axios.post(UPLOAD_FILE_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file;filename="${name}"`,
      },
    });
    return response.data;
  }
  static async uploadLogoForProject(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<any> {
    const response = await axios.post(UPLOAD_LOGO_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }
  static async createProject(
    request: createProjectParams
  ): Promise<ProjectCreationResponse> {
    const response = await axios.post(CREATE_PROJECT_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async updateProject(
    request: createProjectParams
  ): Promise<ProjectCreationResponse> {
    const response = await axios.patch(UPDATE_PROJECT_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getProjectList(
    params: Partial<ProjectListParams>
  ): Promise<PaginatedResponse<ProjectListProps>> {
    const response = await axios.get(PROJECT_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getProjectState(
    params: Partial<ProjectStatsParams>
  ): Promise<ProjectStats> {
    const response = await axios.get(PROJECT_STATS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  // PROJECT DETAILS

  static async getProjectDetails(
    params: Partial<ProjectDetailsParams>
  ): Promise<ProjectListProps> {
    const response = await axios.get(PROJECT_DETAILS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getActiveJobsForProject(
    params: Partial<ActiveJobsForProjectParams>
  ): Promise<PaginatedResponse<JobListingResponse>> {
    const response = await axios.get(PROJECT_ACTIVE_JOBS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  // PROJECT CONTRACTORS

  static async getRolesForProject(
    params: Partial<RolesForProjectParams>
  ): Promise<PaginatedResponse<ContractorRoleResponse>> {
    const response = await axios.get(PROJECT_ROLES_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getContractorsForProject(
    params: Partial<CotractorsForProjectParams>
  ): Promise<PaginatedResponse<CandidateListResponse>> {
    const response = await axios.get(PROJECT_CONTRACTORS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getDocumentByProjectId(
    params: Partial<ProjectIdParams>
  ): Promise<ProjectDocumantResponse> {
    const response = await axios.get(DOCUMENT_BY_PROJECTID_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getDocumentTypeForProject(): Promise<ProjectDocumentResponse[]> {
    const response = await axios.get(DOCUMENT_TYPE_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getActiveProjectMember(
    params: Partial<ProjectIdParams>
  ): Promise<PaginatedResponse<TeamMemberProps>> {
    const response = await axios.get(ACTIVE_PROJECT_MEMBER_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getInActiveProjectMember(
    params: ProjectIdParams
  ): Promise<PaginatedResponse<TeamMemberProps>> {
    const response = await axios.get(INACTIVE_PROJECT_MEMBER_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createProjectDocument(
    request: Partial<CreateAndUpdateProjectDocsRequest>
  ): Promise<ProjectDocumentResponse[]> {
    const response = await axios.post(CREATE_PROJECT_DOCUMENT_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateProjectMember(
    request: Partial<CreateMemberRequest>
  ): Promise<ProjectDocumentResponse[]> {
    const response = await axios.post(UPDATE_PROJECT_MEMBER_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateProjectDocument(
    request: Partial<CreateAndUpdateProjectDocsRequest>
  ): Promise<ProjectDocumentResponse[]> {
    const response = await axios.patch(UPDATE_PROJECT_DOCUMENT_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  //////////////// Project tasks \\\\\\\\\\\\\\\\\\\\\\\\\\

  static async contractorListByProjectId(
    projectId: string,
    params: Partial<{ name: string }>
  ): Promise<PaginatedResponse<ContractorByProjectResponse>> {
    const URL = `${CONTRACTOR_LIST_BY_PROJECT_ID_URL}/${projectId}`;
    const response = await axios.get(URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async taskListByProjectId(
    projectId: string,
    params: Partial<TaskListParams>
  ): Promise<PaginatedResponse<TasksListResponse>> {
    const URL = `${TASK_LIST_BY_PROJECT_ID_URL}/${projectId}`;
    const response = await axios.get(URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createTask(
    request: Partial<CreateUpdateTaskRequest>
  ): Promise<CreateUpdateTaskResponse> {
    const response = await axios.post(TASK_CREATE_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateTask(
    request: Partial<CreateUpdateTaskRequest>
  ): Promise<CreateUpdateTaskResponse> {
    const response = await axios.patch(TASK_UPDATE_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async deleteTask(params: {
    nid: string;
  }): Promise<ProjectDocumentResponse[]> {
    const response = await axios.delete(TASK_DELETE_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  //////////////// Project tasks \\\\\\\\\\\\\\\\\\\\\\\\\\
}
