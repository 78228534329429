import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BORDERS } from "../../../../../../../../../theme";
import AverageRating from "./AverageRating";
import RatingView from "../components/RatingView";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { SubmissionService } from "../../../../../../../../../Api/submissionServices";
import { RatingSummary } from "../../../../../../../../../types/submissionTypes";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";

const initialStarRatting = {
  star_1: 0,
  star_2: 0,
  star_3: 0,
  star_4: 0,
  star_5: 0,
};

const AverageRatingView = () => {
  const { submissionDetails } = useAppSelector(getSubmissionState);
  const [rattingSummary, setRattingSummary] = useState<RatingSummary>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getRattingOfCandidate = async (candidate_id: string) => {
    setLoading(true);
    try {
      const response = await SubmissionService.getCandidateRattingSummary({
        candidate_id,
      });
      setRattingSummary(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    submissionDetails?.candidate_id &&
      getRattingOfCandidate(submissionDetails?.candidate_id);
  }, []);

  const rattingComponent = (rate: string) => {
    if (isNotNullOrUndefined(rate)) {
      return rate;
    } else return "0.0";
  };

  return (
    <>
      <Stack
        direction={"row"}
        pt={2}
        sx={{
          width: "100%",
          // overflowX: "auto",
          pb: 1,
        }}
      >
        <Stack
          sx={{
            minWidth: 130,
            // width: "100%",
            pr: 3,
            borderRight: BORDERS.GRAY,
          }}
        >
          <AverageRating
            loading={loading}
            average_rating_star={
              isNotNullOrUndefined(rattingSummary)
                ? rattingSummary.average_rating_star
                : 0
            }
            totalReview={
              isNotNullOrUndefined(rattingSummary)
                ? rattingSummary.total_reviews
                : 0
            }
            startRatting={
              isNotNullOrUndefined(rattingSummary)
                ? rattingSummary.star_rating
                : initialStarRatting
            }
          />
        </Stack>
        <Stack
          sx={{
            minWidth: 98,
            width: 135,
            pr: 3,
            pl: 2,
            borderRight: BORDERS.GRAY,
          }}
        >
          <RatingView
            loading={loading}
            title="Work likability"
            rate={
              isNotNullOrUndefined(rattingSummary)
                ? rattingComponent(rattingSummary?.average_value)
                : "0.0"
            }
            icon="icon-like-1"
          />
        </Stack>
        <Stack
          sx={{
            minWidth: 98,
            width: 135,
            pr: 3,
            pl: 2,
            borderRight: BORDERS.GRAY,
          }}
        >
          <RatingView
            loading={loading}
            title="Requirements satisfied"
            rate={
              isNotNullOrUndefined(rattingSummary)
                ? rattingComponent(rattingSummary?.average_quality)
                : "0.0"
            }
            icon="icon-task-square"
          />
        </Stack>
        <Stack
          sx={{
            minWidth: 98,
            width: 135,
            pr: 3,
            pl: 2,
            borderRight: BORDERS.GRAY,
          }}
        >
          <RatingView
            loading={loading}
            title="Work matches experience"
            rate={
              isNotNullOrUndefined(rattingSummary)
                ? rattingComponent(rattingSummary?.average_service_as_described)
                : "0.0"
            }
            icon="icon-speedometer"
          />
        </Stack>
        <Stack
          sx={{
            minWidth: 98,
            width: 135,
            pr: 3,
            pl: 2,
            borderRight: BORDERS.GRAY,
          }}
        >
          <RatingView
            loading={loading}
            title="Communication"
            rate={
              isNotNullOrUndefined(rattingSummary)
                ? rattingComponent(rattingSummary?.average_communication)
                : "0.0"
            }
            icon="icon-messages-2"
          />
        </Stack>
        <Stack
          sx={{
            minWidth: 98,
            width: 135,
            pr: 3,
            pl: 2,
          }}
        >
          <RatingView
            loading={loading}
            title="Recommended"
            rate={
              isNotNullOrUndefined(rattingSummary)
                ? rattingComponent(rattingSummary?.average_recommendation)
                : "0.0"
            }
            icon="icon-medal-star"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AverageRatingView;
