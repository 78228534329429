import React, { createContext, useContext, useEffect, useState } from "react";
import { CompanyServices } from "../Api/companyServices";
import { UserManagementServices } from "../Api/userManagementServices";
import { getAuthState, setUserInfo } from "../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { OptionProps } from "../types/commonType";
import {
  CompanyAddressProps,
  UpdateCompanyInfoRequest,
} from "../types/companyTypes";
import {
  ImageStateProps,
  UserUpdateRequest,
} from "../types/userManagementTypes";
import {
  billingInfoValidation,
  companyUpdateValidation,
  updateUserValidation,
} from "../utils/validationUtils";

import toast from "react-hot-toast";
import { BillingServices } from "../Api/billingServices";
import {
  AdrenalineSportsIcon,
  AnimalsIcon,
  ArchitectureIcon,
  BasketballIcon,
  FilmsIcon,
  FootballIcon,
  LiveSportIcon,
  ReadingIcon,
  TrackIcon,
  TravelIcon,
  TVIcon,
  UltimateFrisbeeIcon,
  VideoGamesIcon,
  VollyballIcon,
  VolunteeringIcon,
  WalkingIcon,
  WaterPoloIcon,
  WaterSportIcon,
  WeigthLiftingIcon,
  WineIcon,
  WrestlingIcon,
  WritingIcon,
  YogaIcon,
} from "../pages/MyProfile/assets/ProfileIcon";
import { COLORS } from "../theme";
import { CreateUpdateBillingInfoRequest } from "../types/billingTypes";
import { isNotNullOrUndefined } from "../utils/stringUtils";

export interface UsedPlatformProps {
  icon: any;
  text: string;
  subText: string;
  state: string;
}

export const interestes = [
  {
    title: "Adrenaline sports",
    icon: <AdrenalineSportsIcon />,
    activeIcon: <AdrenalineSportsIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Animals",
    icon: <AnimalsIcon />,
    activeIcon: <AnimalsIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Architecture",
    icon: <ArchitectureIcon />,
    activeIcon: <ArchitectureIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Basket ball",
    icon: <BasketballIcon />,
    activeIcon: <BasketballIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Films",
    icon: <FilmsIcon />,
    activeIcon: <FilmsIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Football",
    icon: <FootballIcon />,
    activeIcon: <FootballIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Live sport",
    icon: <LiveSportIcon />,
    activeIcon: <LiveSportIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Readinng",
    icon: <ReadingIcon />,
    activeIcon: <ReadingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Track & field",
    icon: <TrackIcon />,
    activeIcon: <TrackIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Travel",
    icon: <TravelIcon />,
    activeIcon: <TravelIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "TV",
    icon: <TVIcon />,
    activeIcon: <TVIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Ultimate frisbee",
    icon: <UltimateFrisbeeIcon />,
    activeIcon: <UltimateFrisbeeIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Video games",
    icon: <VideoGamesIcon />,
    activeIcon: <VideoGamesIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Volleyball",
    icon: <VollyballIcon />,
    activeIcon: <VollyballIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Volunteering",
    icon: <VolunteeringIcon />,
    activeIcon: <VolunteeringIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Walking",
    icon: <WalkingIcon />,
    activeIcon: <WalkingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Water polo",
    icon: <WaterPoloIcon />,
    activeIcon: <WaterPoloIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Water sport",
    icon: <WaterSportIcon />,
    activeIcon: <WaterSportIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Weight lifting",
    icon: <WeigthLiftingIcon />,
    activeIcon: <WeigthLiftingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Wine",
    icon: <WineIcon />,
    activeIcon: <WineIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Wrestling",
    icon: <WrestlingIcon />,
    activeIcon: <WrestlingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Writing",
    icon: <WritingIcon />,
    activeIcon: <WritingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Yoga",
    icon: <YogaIcon />,
    activeIcon: <YogaIcon strokeColor={COLORS.PRIMARY} />,
  },
];

const createAccountStepperForClient = [
  {
    text: "Account details",
    value: "ACCOUNT",
  },
  {
    text: "Company details",
    value: "COMPANY",
  },
  {
    text: "Billing details",
    value: "BILLING",
  },
  {
    text: "Submit",
    value: "SUBMIT",
  },
];
const createAccountStepper = [
  {
    text: "Account details",
    value: "ACCOUNT",
  },
  {
    text: "Submit",
    value: "SUBMIT",
  },
];

export const initialBillingInfoState = {
  full_name: "",
  first_name: "",
  last_name: "",
  email_1: "",
  email_2: "",
  job_title: "",
  job_title_id: "",
  phone_number: "",
  address: {
    country_code: "",
    country: "",
    administrative_area: "",
    locality: "",
    postal_code: "",
    address_line1: "",
  },
};

type CreateAccountModuleContextType = {
  selectedStep: string;
  searchQuery: string;
  searchQueryForBilling: string;
  stepperData: any;
  activeStep: number;
  loading: boolean;
  updateUserLoading: boolean;
  companyLogo: ImageStateProps;
  userImageState: ImageStateProps;

  companyInfoLoading: boolean;
  companyUpdateLoading: boolean;
  billingUpdateLoading: boolean;
  billingInfoLoading: boolean;
  isUpdateSuccessFully: boolean;
  fileLoading: boolean;
  isBillingInfo: boolean;
  isShowYourProfile: boolean;
  selectedPlatform: UsedPlatformProps | null;
  accountDetailsState: UserUpdateRequest;
  companySize: OptionProps[];
  selectedInterestes: string[];
  companyInfoState: UpdateCompanyInfoRequest | null;
  billingInfoState: CreateUpdateBillingInfoRequest;
  accountDetailsError: any;
  companyDetailsError: any;
  bilingDetailsError: any;
  companySizeText: string;
  selectedProjectId: string;
  setCompanyLogo: React.Dispatch<React.SetStateAction<ImageStateProps>>;
  setUserImageState: React.Dispatch<React.SetStateAction<ImageStateProps>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSearchQueryForBilling: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedInterestes: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPlatform: React.Dispatch<
    React.SetStateAction<UsedPlatformProps | null>
  >;
  setIsShowYourProfile: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccountDetailsChange: (
    key: keyof UserUpdateRequest,
    value: string
  ) => void;
  handleCompanyDetailsChange: (
    key: keyof UpdateCompanyInfoRequest,
    value: string | CompanyAddressProps
  ) => void;
  handleBillingDetailsChange: (
    key: keyof CreateUpdateBillingInfoRequest,
    value: string | CompanyAddressProps
  ) => void;
  updateUserDetails: () => void;
  updateCompanyDetails: () => void;
  getCompanyInfo: () => void;
  getCompanySizeInfo: () => void;
  getBillingInfo: () => void;
  submitBillingDetails: () => void;
  handleFiles: (file: any) => void;
};

const CreateAccountModule =
  createContext<CreateAccountModuleContextType | null>(null);

export const useCreateAccount = () =>
  useContext(CreateAccountModule) as CreateAccountModuleContextType;

export const CreateAccountProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const isClient = userInfo?.roles?.some((role) => role === "client");
  const stepperData =
    isClient && userInfo?.sub_role?.role_name === "Admin"
      ? createAccountStepperForClient
      : createAccountStepper;

  const isAdminAndClient =
    isClient && userInfo?.sub_role?.role_name === "Admin";
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedStep, setSelectedStep] = useState<string>("ACCOUNT");
  const [companyLogo, setCompanyLogo] = useState<ImageStateProps>({
    fid: null,
    image: "",
  });
  const [userImageState, setUserImageState] = useState<ImageStateProps>({
    fid: null,
    image: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [updateUserLoading, setUpdateUserLoading] = useState<boolean>(false);
  const [companyInfoLoading, setCompanyInfoLoading] = useState<boolean>(false);
  const [billingInfoLoading, setBillingInfoLoading] = useState<boolean>(false);
  const [isShowYourProfile, setIsShowYourProfile] = useState<boolean>(false);
  const [isBillingInfo, setIsBillingInfo] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState(false);
  const [companyUpdateLoading, setCompanyUpdateLoading] =
    useState<boolean>(false);
  const [billingUpdateLoading, setBillingUpdateLoading] =
    useState<boolean>(false);
  const [isUpdateSuccessFully, setIsUpdateSuccessFully] =
    useState<boolean>(false);
  const [accountDetailsState, setAccountDetailsState] =
    useState<UserUpdateRequest>({
      last_name: isNotNullOrUndefined(userInfo)
        ? isNotNullOrUndefined(userInfo?.last_name) &&
          userInfo?.last_name.length
          ? userInfo?.last_name
          : ""
        : "",
      phone_number: isNotNullOrUndefined(userInfo)
        ? isNotNullOrUndefined(userInfo?.phone_number) &&
          userInfo?.phone_number.length
          ? userInfo?.phone_number
          : ""
        : "",
      profile_pic_fid: "",
      first_name: isNotNullOrUndefined(userInfo)
        ? isNotNullOrUndefined(userInfo?.first_name) &&
          userInfo?.first_name.length
          ? userInfo?.first_name
          : ""
        : "",
      job_title: isNotNullOrUndefined(userInfo)
        ? isNotNullOrUndefined(userInfo?.title) && userInfo?.title.length
          ? userInfo?.title
          : ""
        : "",
      linkedin_profile_url: isNotNullOrUndefined(userInfo)
        ? isNotNullOrUndefined(userInfo?.linkedin_profile_url) &&
          userInfo?.linkedin_profile_url.length
          ? userInfo?.linkedin_profile_url
          : ""
        : "",
    });
  const [accountDetailsError, setAccountDetailsError] = useState<any>({});
  const [companyDetailsError, setCompanyDetailsError] = useState<any>({});
  const [bilingDetailsError, setBilingDetailsError] = useState<any>({});
  const [companySizeText, setCompanySizeText] = useState<string>("");
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [selectedInterestes, setSelectedInterestes] = useState<string[]>([]);
  const [selectedPlatform, setSelectedPlatform] =
    useState<UsedPlatformProps | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryForBilling, setSearchQueryForBilling] = useState("");
  const [companyInfoState, setCompanyInfoState] =
    useState<UpdateCompanyInfoRequest>(null);
  const [billingInfoState, setBillingInfoState] =
    useState<CreateUpdateBillingInfoRequest>(initialBillingInfoState);
  const [companySize, setCompanySize] = useState<OptionProps[]>([]);

  const handleAccountDetailsChange = (
    key: keyof UserUpdateRequest,
    value: string
  ) => {
    setAccountDetailsState({ ...accountDetailsState, [key]: value });
  };

  const handleCompanyDetailsChange = (key: keyof any, value: string) => {
    setCompanyInfoState({ ...companyInfoState, [key]: value });
  };

  const handleBillingDetailsChange = (
    key: keyof CreateUpdateBillingInfoRequest,
    value: string | CompanyAddressProps
  ) => {
    setBillingInfoState({ ...billingInfoState, [key]: value });
  };

  const getUserInfoFromToken = async (access_token: string) => {
    try {
      const response = await UserManagementServices.getUserInfo(access_token);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleBinaryUploadForLogo = async (file) => {
    if (!file) return;
    const allowedTypes = [
      "image/svg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/jpeg",
      "image/webp",
    ];
    if (!allowedTypes.includes(file.type)) {
      console.error(
        "Invalid file type. Only png, gif, jpg, jpeg, and webp are allowed."
      );
      return;
    }
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await UserManagementServices.updateProfilePic(
            arrayBuffer,
            file.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles) => {
    setFileLoading(true);
    try {
      const fileArray = Array.from(selectedFiles) as File[];

      const uploadChooseProjectImgPromises = fileArray.map(
        handleBinaryUploadForLogo
      );

      const resultsForProjectImg: any = await Promise.all(
        uploadChooseProjectImgPromises
      );
      const accessToken = localStorage.getItem("accessToken");
      const userInfo = await getUserInfoFromToken(accessToken);
      if (userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        dispatch(setUserInfo(userInfo));
      }
    } catch (error) {
      setFileLoading(false);
    } finally {
      setFileLoading(false);
    }
    // if (fileArray.length > 0) {
    //   const file = fileArray[0];
    //   setImageURL(URL.createObjectURL(file));
    // }
  };

  const updateUserDetails = async () => {
    const errorObj = updateUserValidation(accountDetailsState);
    setAccountDetailsError(errorObj.error);
    if (errorObj.isValid) {
      setUpdateUserLoading(true);
      try {
        await UserManagementServices.updateUser(accountDetailsState);
        toast.success("User has been updated");
        const access_token = localStorage.getItem("accessToken");
        const userInfo = await getUserInfoFromToken(access_token);

        if (userInfo) {
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          dispatch(setUserInfo(userInfo));
        }
        setIsUpdateSuccessFully(true);
        isAdminAndClient ? setActiveStep(1) : setActiveStep(3);
      } catch (error) {
        console.error(error);
        setIsUpdateSuccessFully(false);
      } finally {
        console.log("false");
        setUpdateUserLoading(false);
      }
    }
  };

  const updateCompanyDetails = async () => {
    const errorObj = companyUpdateValidation(companyInfoState, searchQuery);
    setCompanyDetailsError(errorObj.error);
    if (errorObj.isValid) {
      setCompanyUpdateLoading(true);
      try {
        const updateCompanyObj = {
          ...companyInfoState,
          ...(companyLogo.fid && { logo_fid: companyLogo.fid }),
        } as UpdateCompanyInfoRequest;
        await CompanyServices.updateCompanyInfo(updateCompanyObj);
        const access_token = localStorage.getItem("accessToken");
        const userInfo = await getUserInfoFromToken(access_token);

        if (userInfo) {
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          dispatch(setUserInfo(userInfo));
        }
        await getCompanyInfo();
        setActiveStep(2);
        setIsUpdateSuccessFully(true);
        toast.success("Company has been updated");
      } catch (error) {
        console.error(error);
        setIsUpdateSuccessFully(false);
      } finally {
        setCompanyUpdateLoading(false);
      }
    }
  };
  const submitBillingDetails = async () => {
    const errorObj = billingInfoValidation(
      billingInfoState,
      searchQueryForBilling
    );
    setBilingDetailsError(errorObj.error);
    if (errorObj.isValid) {
      setBillingUpdateLoading(true);
      try {
        if (isBillingInfo) {
          const body = {
            ...billingInfoState,
            full_name: `${billingInfoState.first_name} ${billingInfoState.last_name}`,
          } as CreateUpdateBillingInfoRequest;
          await BillingServices.updateBillingInfo(body);
          toast.success("Billing info has been updated");
        } else {
          const body = {
            ...billingInfoState,
            full_name: `${billingInfoState.first_name} ${billingInfoState.last_name}`,
          } as CreateUpdateBillingInfoRequest;
          await BillingServices.createBillingInfo(body);
          toast.success("Billing info has been created");
        }
        setActiveStep(3);
      } catch (error) {
        console.error(error);
      } finally {
        setBillingUpdateLoading(false);
      }
    }
  };

  const getCompanySizeInfo = async () => {
    try {
      const response = await CompanyServices.getCompanySize();
      const companyOptions = response.results.map(
        (info) => ({ label: info.title, value: info.id } as OptionProps)
      );
      setCompanySize(companyOptions);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getCompanyInfo = async () => {
    setCompanyInfoLoading(true);
    try {
      const response = await CompanyServices.getCompanyInfoById({
        nid: userInfo?.company?.id,
      });

      if (response.results.length) {
        const comapnyDetails = response.results[0];
        setCompanyLogo({
          fid: null,
          image: comapnyDetails.logo_image_url,
        });
        setCompanySizeText(comapnyDetails.size);
        const companyInfo = {
          nid: comapnyDetails.nid,
          company_size_id: comapnyDetails.size_id,
          title: comapnyDetails.title,
          description: comapnyDetails.description,
          linkedin: comapnyDetails.linkedin,
          website: comapnyDetails.website,
          founded: comapnyDetails?.founded ?? "",
          industry: comapnyDetails?.industry.length
            ? comapnyDetails?.industry[0]
            : "",
          phone: comapnyDetails.phone,
          company_address: {
            country: comapnyDetails.country,
            administrative_area: comapnyDetails.administrative_area,
            locality: comapnyDetails.locality,
            postal_code: comapnyDetails.postal_code,
            address_line1: comapnyDetails.address_line1,
            country_code: comapnyDetails?.country_code ?? "",
          },
        } as UpdateCompanyInfoRequest;
        setCompanyInfoState(companyInfo);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCompanyInfoLoading(false);
    }
  };

  const getBillingInfo = async () => {
    setBillingInfoLoading(true);
    try {
      const response = await BillingServices.getBillingInfo();
      if (Array.isArray(response?.billing_contact)) {
        setBillingInfoState(initialBillingInfoState);
        setIsBillingInfo(false);
      } else if (
        typeof response?.billing_contact === "object" &&
        response.billing_contact !== null
      ) {
        if (response.billing_contact?.address) {
          const address = response.billing_contact
            .address as CompanyAddressProps;
          if (
            address?.locality ||
            address?.address_line1 ||
            address?.country ||
            address?.administrative_area ||
            address?.postal_code
          ) {
            setSearchQueryForBilling(
              `${address.address_line1} ${address.locality} ${address.administrative_area} ${address.country} ${address.postal_code}`
            );
          }
        }
        setBillingInfoState(response.billing_contact);
        setIsBillingInfo(true);
      } else {
        setBillingInfoState(initialBillingInfoState);
        setIsBillingInfo(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBillingInfoLoading(false);
    }
  };
  const getContactInfo = async () => {
    setLoading(true);
    try {
      const response = await UserManagementServices.getContactDetails({
        email: userInfo?.email,
      });
      if (response || response.length) {
        const contactInfo: any = response as CreateUpdateBillingInfoRequest;
        if (contactInfo && contactInfo?.length) {
          setAccountDetailsState({
            ...accountDetailsState,
            first_name: contactInfo?.first_name ?? "",
            last_name: contactInfo?.last_name ?? "",
            job_title: contactInfo?.job_title ?? "",
            linkedin_profile_url: contactInfo?.linkedin_profile ?? "",
            phone_number: contactInfo?.phone_number ?? "",
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContactInfo();
    getCompanyInfo();
    getBillingInfo();
    getCompanySizeInfo();
  }, []);

  return (
    <CreateAccountModule.Provider
      value={{
        activeStep,
        selectedInterestes,
        selectedStep,
        companySizeText,
        searchQuery,
        searchQueryForBilling,
        setSelectedStep,
        setCompanyLogo,
        stepperData,
        companyLogo,
        userImageState,
        loading,
        isShowYourProfile,
        companyUpdateLoading,
        billingUpdateLoading,
        billingInfoLoading,
        billingInfoState,
        updateUserLoading,
        companyInfoLoading,
        fileLoading,
        isBillingInfo,
        isUpdateSuccessFully,
        accountDetailsState,
        companyInfoState,
        accountDetailsError,
        companyDetailsError,
        bilingDetailsError,
        companySize,
        selectedPlatform,
        selectedProjectId,
        updateUserDetails,
        updateCompanyDetails,
        getCompanyInfo,
        getBillingInfo,
        getCompanySizeInfo,
        submitBillingDetails,
        setActiveStep,
        setSelectedProjectId,
        setSelectedInterestes,
        setSearchQuery,
        setSearchQueryForBilling,
        setSelectedPlatform,
        setIsShowYourProfile,
        setUserImageState,
        handleAccountDetailsChange,
        handleCompanyDetailsChange,
        handleBillingDetailsChange,
        handleFiles,
      }}
    >
      {children}
    </CreateAccountModule.Provider>
  );
};
