import { Box, Drawer } from "@mui/material";
import {
  OfferTypeEnum,
  useMakeAnOffer,
} from "../../../../../../providers/MakeAnOfferProvider";
import { setCondidateTabValue } from "../../../../../../redux/slices/candidateSlice";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
} from "../../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import CandidateDetailsSection from "./CandidateDetailsSection";
import CandidateFullDetails from "./CandidateFullDetails";

const CandidateDetailsDrawer = () => {
  const dispatch = useAppDispatch();
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  const { setOfferType } = useMakeAnOffer();

  return (
    <>
      <>
        <Drawer
          sx={{}}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              width: "1130px",
              overflow: "hidden",
            },
          }}
          anchor="right"
          open={isCandidateDetailsDrawerOpen}
          onClose={() => {
            dispatch(
              setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen)
            );
            dispatch(setCondidateTabValue(0));
            setOfferType(OfferTypeEnum.JobOffers);
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              border: "0px solid #000",
              boxShadow: 24,
              outline: "0",
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              pb: 2,
              height: "100%",
            }}
          >
            <CandidateDetailsSection />
            <CandidateFullDetails />
          </Box>
        </Drawer>
      </>
    </>
  );
};

export default CandidateDetailsDrawer;
