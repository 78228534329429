import { Box, Collapse, Container, Skeleton, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectService } from "../../Api/projectServices";
import AtsBackLink from "../../components/AtsBackLink";
import AtsCard from "../../components/AtsCard";
import AtsIcon from "../../components/AtsIcon";
import AtsTitleText from "../../components/AtsTitleText";
import { InvoicesProvider } from "../../providers/InvoicesProvider";
import { TaskProvider } from "../../providers/TaskProvider";
import { useTimesheet } from "../../providers/TimesheetProvider";
import { setIsInvoiceView } from "../../redux/slices/invoicesSlice";
import { setInitialProjectDetailsState } from "../../redux/slices/projectDetailsSlice";
import {
  getProjectsState,
  setInitialProjectState,
  setProjectsDetails,
  setSelectedProjectTab,
  setSelectedTeamTab,
} from "../../redux/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ATS_ROUTES } from "../../router/routes";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import ProjectDetailsHeaderComponent from "./components/ProjectDetailsComponent/ProjectDetailsHeaderComponent";
import ActiveJobs from "./modules/ProjectDetailsModules/ActiveJobs/ActiveJobs";
import DetailsOfProject from "./modules/ProjectDetailsModules/Details/DetailsOfProject";
import Invoices from "./modules/ProjectDetailsModules/Invoices/Invoices";
import Overview from "./modules/ProjectDetailsModules/Overview/Overview";
import Contractors from "./modules/ProjectDetailsModules/Team/Contractors/Contractors";
import Onboarding from "./modules/ProjectDetailsModules/Team/Onboarding/Onboarding";
import Tasks from "./modules/ProjectDetailsModules/Team/Tasks/Tasks";
import Timesheets from "./modules/ProjectDetailsModules/Team/Timesheets/Timesheets";

const tabData = [
  {
    title: "Overview",
    isIcon: false,
  },
  {
    title: "Details",
    isIcon: false,
  },
  {
    title: "Active Jobs",
    isIcon: false,
  },
  {
    title: "Teams",
    isIcon: true,
  },
  {
    title: "Invoices",
    isIcon: false,
  },
];

const teamData = [
  {
    title: "Onboarding",
    icon: "icon-3square",
  },
  {
    title: "Tasks",
    icon: "icon-tick-circle",
  },
  {
    title: "Contractors",
    icon: "icon-profile-2user",
  },
  {
    title: "Timesheets",
    icon: "icon-calendar-1",
  },
];

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedProjectTab, selectedTeamTab, projectsDetails } =
    useAppSelector(getProjectsState);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setSelectedUsers } = useTimesheet();

  const handleClick = (index) => {
    if (index === 3) {
      setShow(!show);
    } else {
      setShow(false);
      dispatch(setSelectedTeamTab(null));
      dispatch(setSelectedProjectTab(index));
      dispatch(setIsInvoiceView(false));
    }
  };

  const handleTeamTab = (index: number) => {
    setShow(false);
    dispatch(setSelectedProjectTab(3));
    dispatch(setSelectedTeamTab(index));
  };

  const taskTabMemo = useMemo(
    () => (
      <TaskProvider>
        <Tasks />
      </TaskProvider>
    ),
    []
  );
  const timesheetTabMemo = useMemo(() => <Timesheets />, []);

  const renderTeamComponent = () => {
    switch (selectedTeamTab) {
      case 0:
        return <Onboarding />;
      case 1:
        return taskTabMemo;
      case 2:
        return <Contractors />;
      case 3:
        return timesheetTabMemo;

      default:
        return null;
    }
  };

  const detailsOfProject = useMemo(() => <DetailsOfProject />, []);
  const invoicesMemo = useMemo(
    () => (
      <InvoicesProvider>
        <Invoices />
      </InvoicesProvider>
    ),
    []
  );

  const renderProjectDetailsTab = () => {
    switch (selectedProjectTab) {
      case 0:
        return <Overview />;
      case 1:
        return detailsOfProject;
      case 2:
        return <ActiveJobs />;
      case 3:
        return renderTeamComponent();
      case 4:
        return invoicesMemo;
      case 5:
        return <Overview />;
    }
  };

  const fetxhProjectDetails = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({ nid: id });
      dispatch(setProjectsDetails(response));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetxhProjectDetails();
  }, []);
  return (
    <div
      className="main-container"
      style={{
        height: "100%",
      }}
    >
      <Container maxWidth="xl" sx={{ overflowX: "scroll" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={3}
          sx={{ pt: "13px", pb: "13px" }}
        >
          <Stack
            gap={"6px"}
            direction={"row"}
            alignItems={"center"}
            sx={{ minHeight: 22 }}
          >
            <AtsTitleText
              lineHeight={2.2}
              text={"All Projects"}
              fs={10}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />

            <AtsTitleText
              lineHeight={2.2}
              text={">"}
              fs={10}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
            {loading ? (
              <Skeleton height={10} width={100} />
            ) : (
              <AtsTitleText
                lineHeight={2.2}
                text={projectsDetails?.title ?? ""}
                fs={10}
                fw={400}
                textColor={"#000"}
              />
            )}
          </Stack>
          <Stack>
            <AtsBackLink
              text="Back to projects"
              onClick={() => {
                dispatch(setInitialProjectState());
                setSelectedUsers([]);
                navigate(ATS_ROUTES.PROJECTS);
              }}
            />
          </Stack>
        </Stack>
        <Stack px={2} gap={1} sx={{ height: "calc(100% - 70px)" }}>
          <AtsCard sx={{ height: "92%", p: "30px" }}>
            <>
              <ProjectDetailsHeaderComponent
                projectsDetails={projectsDetails}
                loading={loading}
              />
              <Box
                sx={{
                  mt: 3,
                  mb: "30px",
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  {tabData.map((tab, index) => (
                    <Stack
                      key={index}
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                        py={"12px"}
                        px={"16px"}
                        sx={{
                          cursor: "pointer",
                          height: 17,
                          borderBottom:
                            index === selectedProjectTab
                              ? "3px solid var(--primary)"
                              : "3px solid transparant",
                        }}
                        onClick={() => {
                          if (tab.title !== "Teams") {
                            dispatch(setInitialProjectDetailsState());
                          }
                          handleClick(index);
                          setSelectedUsers([]);
                        }}
                      >
                        <AtsTitleText
                          text={tab.title}
                          fs={14}
                          fw={index === selectedProjectTab ? 600 : 400}
                          textColor={
                            index === selectedProjectTab
                              ? COLORS.PRIMARY
                              : COLORS.LIGHT_GRAY
                          }
                        />
                        {tab.isIcon && (
                          <AtsIcon
                            icon="icon-arrow-down-1"
                            color={
                              index === selectedProjectTab
                                ? COLORS.PRIMARY
                                : COLORS.BLACK
                            }
                            height="16px"
                          />
                        )}
                      </Stack>
                      {tab.isIcon && (
                        <Box
                          sx={{
                            position: "absolute",
                            bgcolor: "white",
                            left: "0px",
                            top: "30px",
                            zIndex: 9,
                            width: "150px",
                          }}
                        >
                          <Collapse
                            sx={{
                              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                              borderRadius: "6px",
                              border: BORDERS.GRAY,
                            }}
                            in={show}
                          >
                            <Box
                              sx={{
                                p: "14px",
                              }}
                            >
                              <Stack gap={"8px"}>
                                {teamData.map((event, index) => (
                                  <Stack
                                    key={index}
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={"8px"}
                                    p="5px"
                                    borderRadius={"5px"}
                                    bgcolor={
                                      index === selectedTeamTab
                                        ? BG_COLORS.PRIMARY
                                        : "transparent"
                                    }
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleTeamTab(index)}
                                  >
                                    <AtsIcon
                                      icon={event.icon}
                                      height="16px"
                                      color={
                                        index === selectedTeamTab
                                          ? COLORS.PRIMARY
                                          : COLORS.BLACK
                                      }
                                    />
                                    <AtsTitleText
                                      text={event.title}
                                      fs={14}
                                      fw={500}
                                      textColor={
                                        index === selectedTeamTab
                                          ? COLORS.PRIMARY
                                          : COLORS.BLACK
                                      }
                                    />
                                  </Stack>
                                ))}
                              </Stack>
                            </Box>
                          </Collapse>
                        </Box>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Box>
              <Box
                className="scrollHide"
                sx={{
                  height: "calc(100% - 135px)",
                  overflow: "auto",
                }}
              >
                {renderProjectDetailsTab()}
              </Box>
            </>
          </AtsCard>
        </Stack>
      </Container>
    </div>
  );
};

export default ProjectDetails;
