import { Box, Skeleton, Stack } from "@mui/material";
import AtsRow from "../../../../../components/AtsRow";

const ConversationLoadingBox = () => {
  return (
    <>
      <AtsRow justifyContent={"center"}>
        <Skeleton variant="text" width={50} />
      </AtsRow>

      <Stack pb={"30px"}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={"10px"}>
          <Box>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
          <Stack gap={"8px"} pt={"4px"}>
            <AtsRow gap={"10px"}>
              <>
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={40} />
              </>
            </AtsRow>
            <Skeleton variant="rectangular" width={200} height={40} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ConversationLoadingBox;
