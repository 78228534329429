import { Moment } from "moment";
import { Pager } from "./commonType";

export enum AppointmentStatusCode {
  Overdue = "OVERDUE",
  due = "DUE",
  contractStart = "CONTRACT_START",
  contractEnd = "CONTRACT_END",
  interview = "INTERVIEW",
}
export enum CalendarEventTypes {
  All = "All",
  Interviews = "Interviews",
  // Reminders = "Reminders",
  Contracts = "Contracts",
  Invoices = "Invoices",
}

export const EVENT_STATUS_BG_COLORS = {
  OVERDUE: "#ffd6d6",
  DUE: "#def5e6",
  CONTRACT_START: "#d9d2ff",
  CONTRACT_END: "#d9d2ff",
  INTERVIEW: "#cdf4ff",
};
export const EVENT_STATUS_COLORS = {
  OVERDUE: "#af1f1f",
  DUE: "#0e4f28",
  CONTRACT_START: "#381d7f",
  CONTRACT_END: "#381d7f",
  INTERVIEW: "#3c4f74",
};

export type CandidateDataType =
  | ContractEndEvent[]
  | ContractStartEvent[]
  | InterviewEventsResponse[];
export type Appointment = {
  id: string;
  status: string;
  text: string;
  length: string;
  cadidateData: any;
};

export type Blockout = { id: number; name: string };

export type EventItem = {
  start?: Date;
  end?: Date;
  data?: { appointment?: Appointment; blockout?: Blockout };
  isDraggable?: boolean;
  allDay?: boolean;
  resourceId?: string;
};

export interface Slot {
  start: string;
  end: string;
}
export interface EventsParams {
  start_date: number | Moment;
  end_date: number | Moment;
}
export interface DayAvailability {
  day: string;
  is_unavailable: string | number;
  slots: Slot[];
}
export interface GetDayAvailabilityResponse {
  nid: string;
  timezone: string;
  availability: DayAvailability[];
}

export interface AllUserAvailabilityResponse {
  [email: string]: GetDayAvailabilityResponse;
}
export interface ConnectedGoogleCalendarParams {
  authorization: string;
  successRedirect: string;
  failureRedirect: string;
}
export interface GetCandidateInterviewByJobParams extends Pager {
  job_id: string | number;
  candidate_id: string | number;
}
export interface GetAvailabilityOfUsersRequest {
  date: string;
  emails: string[];
}

export interface GetUnAvailabilityOfUsersResponse {
  message: string;
  data: {
    slots: Slot[];
  };
}

export interface EventTime {
  dateTime: string;
  timeZone: string;
}

export interface EventData {
  start: EventTime;
  end: EventTime;
  title: string;
  description: string;
}
export interface GoogleCalendarEventResponse {
  email?: string;
  status: string;
  data?: EventData[];
}

export interface GetCandidateInterviewListByJobResponse {
  title: string;
  job_id: string;
  job_title: string;
  type: string;
  nid: string;
  company_id: string;
  date: string;
  duration: string;
  status: string;
  candidate_id: string;
}

export interface CandidateData {
  candidate_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  image: string;
}

export interface InterviewEventsResponse {
  nid: string;
  job_id: string;
  job_title: string;
  project_id: string;
  interview_subject: string;
  interview_date_time: string;
  interview_date_timezone: string;
  interview_duration: string;
  interview_description: string;
  interview_meet_link: string | null;
  interview_owner_data: {
    user_id: string;
    first_name: string;
    last_name: string;
    email: string;
    image: string;
  };
  interview_candidate_data: CandidateData;
}

export interface ContractStartEvent {
  nid: string;
  contract_start_date: string;
  candidate_data: CandidateData;
}
export interface ContractEndEvent {
  nid: string;
  contract_end_date: string;
  candidate_data: CandidateData;
}

export interface ContractsEventsResponse {
  contract_start: ContractStartEvent[];
  contract_end: ContractEndEvent[];
}

export interface InvoiceDueEventsResponse {
  amount: string;
  company: string;
  due_date: string;
  invoice_id: string;
  project: string;
}

/////////////////////  Google event \\\\\\\\\\\\\\\\\

export interface GoogleEventRequest {
  description: string;
  title: string;
  attendees: string[];
  startTime: string;
  endTime: string;
}
interface Attendee {
  email: string;
  responseStatus: string;
}

interface EntryPoint {
  entryPointType: string;
  uri: string;
  label?: string;
  pin?: string;
  regionCode?: string;
}

interface ConferenceSolutionKey {
  type: string;
}

interface ConferenceSolution {
  key: ConferenceSolutionKey;
  name: string;
  iconUri: string;
}

interface ConferenceData {
  createRequest: {
    requestId: string;
    conferenceSolutionKey: ConferenceSolutionKey;
    status: {
      statusCode: string;
    };
  };
  entryPoints: EntryPoint[];
  conferenceSolution: ConferenceSolution;
  conferenceId: string;
}

interface Creator {
  email: string;
  self: boolean;
}

interface Organizer {
  email: string;
  self: boolean;
}

interface EventDateTime {
  dateTime: string;
  timeZone: string;
}

export interface CalendarEventResponse {
  kind: string;
  etag: string;
  id: string;
  status: string;
  htmlLink: string;
  created: string;
  updated: string;
  summary: string;
  description: string;
  creator: Creator;
  organizer: Organizer;
  start: EventDateTime;
  end: EventDateTime;
  iCalUID: string;
  sequence: number;
  attendees?: Attendee[];
  hangoutLink?: string;
  conferenceData?: ConferenceData;
  reminders: {
    useDefault: boolean;
  };
  eventType: string;
}
