import { Box, Stack } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";

interface IProps {
  invoiceNumber: string;
  amount: string;
  renderBottomView: JSX.Element;
}

const InvoiceComponent = ({
  invoiceNumber,
  amount,
  renderBottomView,
}: IProps) => {
  return (
    <>
      <Box
        sx={{
          p: "10px",
          bgcolor: BG_COLORS.GRAY,
          borderRadius: "5PX",
        }}
      >
        <Stack
          gap={"16px"}
          sx={{
            borderBottom: BORDERS.GRAY,
            pb: "15px",
          }}
        >
          {renderTextWrapComponent("Invoice number", invoiceNumber)}
          {renderTextWrapComponent("Amount", amount)}
        </Stack>
        {renderBottomView ?? renderBottomView}
      </Box>
    </>
  );
};

export default InvoiceComponent;

const renderTextWrapComponent = (title: string, value: string) => {
  return (
    <Stack gap={"8px"}>
      <AtsTitleText text={title} fs={14} fw={600} textColor="#000" />
      <AtsTitleText text={value} fs={14} fw={400} textColor={COLORS.BLACK} />
    </Stack>
  );
};
