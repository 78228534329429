import { Stack } from "@mui/material";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import LocationComponent from "../../components/LocationComponent";

const ProjectLoaction = () => {
  return (
    <JobCardWrapper
      header="Where is the project located?"
      subText="Your address will only be shared with potential prospects when they are hired."
    >
      <Stack pr={1} mb={1} gap={1}>
        <LocationComponent />
      </Stack>
    </JobCardWrapper>
  );
};

export default ProjectLoaction;
