import { Box, Container, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FileIcon, MarkerIcon, TimeIcon } from "../../../assets/icons/Icons";
import AtsCard from "../../../components/AtsCard";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsText from "../../../components/AtsText";
import AtsTitleText from "../../../components/AtsTitleText";
import { getPostOpportunityState } from "../../../redux/slices/postOpportunitySlice";
import { useAppSelector } from "../../../redux/store";
import { ATS_ROUTES } from "../../../router/routes";
import { BORDERS, COLORS } from "../../../theme";
import { JobListingResponse } from "../../../types/jobsTypes";
import { commaSeparatedAddress } from "../../../utils/stringUtils";

const OpportunityPosted = () => {
  const navigate = useNavigate();
  const { jobDetailsById } = useAppSelector(getPostOpportunityState);
  const job = {
    nid: `R-${jobDetailsById?.nid}`,
    job_location: commaSeparatedAddress({
      location: jobDetailsById?.work_mode,
    }),
    job_priority: jobDetailsById?.job_priority,
    job_status: jobDetailsById?.job_status,
    job_type: jobDetailsById?.job_type,
    title: jobDetailsById?.title,
    work_mode: jobDetailsById?.work_mode,
  } as JobListingResponse;
  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack gap={2} sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <img
                width={"80%"}
                src={require("../assets/images/postedJob.png")}
                alt="posted"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack gap={2}>
                <Box>
                  <img
                    width={45}
                    src={require("../assets/images/check.gif")}
                    alt="check"
                  />
                </Box>
                <AtsTitleText
                  text={"Success! Your opportunity is posted."}
                  fw={600}
                  fs={20}
                />
                <Grid container spacing={2}>
                  <Grid item sm={12} md={8}>
                    <AtsCard>
                      <Stack gap={1}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={3}
                          py={1}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Box
                              sx={{
                                width: "6px",
                                height: "6px",
                                bgcolor: COLORS.GREEN,
                                borderRadius: "20px",
                              }}
                            ></Box>
                            <AtsText
                              sx={{
                                fontSize: 11,
                                fontWeight: 700,
                                color: COLORS.GREEN,
                                // clear: "both",
                                // display: "inline-block",
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                // width: 90,
                                // textWrap: "nowrap",
                              }}
                              text={`${job.job_status}`}
                            />
                          </Stack>
                          {job?.job_priority === "High" && (
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <AtsText
                                sx={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  color: COLORS.RED,
                                  textTransform: "uppercase",
                                }}
                                text={`HIGH PRIORITY`}
                              />
                            </Stack>
                          )}
                        </Stack>
                        <Stack sx={{}}>
                          <AtsText
                            text={job?.title}
                            sx={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#231f20",
                            }}
                          />
                        </Stack>
                        <Stack
                          py={2}
                          gap={1}
                          sx={{ borderBottom: BORDERS.GRAY }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                          >
                            <MarkerIcon />
                            <AtsText
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: COLORS.LIGHT_GRAY,
                              }}
                              text={`${job.job_location}`}
                            />
                          </Stack>
                          <Stack direction={"row"} gap={2}>
                            <TimeIcon />
                            <AtsText
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: COLORS.LIGHT_GRAY,
                              }}
                              text={`${job.job_type}`}
                            />
                          </Stack>
                          <Stack direction={"row"} gap={2}>
                            <FileIcon />
                            <AtsText
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: COLORS.LIGHT_GRAY,
                              }}
                              text={`${job.nid}`}
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          py={1}
                        >
                          <AtsText
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: COLORS.PRIMARY,
                            }}
                            text={`View Job`}
                          />
                        </Stack>
                      </Stack>
                    </AtsCard>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            py={1}
          >
            <AtsRoundedButton
              text={`Go to jobs`}
              onClick={() => navigate(ATS_ROUTES.JOBS)}
            />
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default OpportunityPosted;
