import { Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../../../theme";
import { isNotNullOrUndefined } from "../../../../../../../utils/stringUtils";
import { convertTimestampToDate } from "../../../../../../../utils/dateUtils";
import { useAppSelector } from "../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../redux/slices/submissionSlice";
import { getContractorDetailsState } from "../../../../../../../redux/slices/contractorSlice";
import ProfileImg from "../../../../../../../assets/images/candidate.png";
import AtsUserNameTextAvatar from "../../../../../../../components/AtsUserNameTextAvatar";

const ContractorInfo = () => {
  const { isCandidateDetailsLoading, candidateDetails } =
    useAppSelector(getSubmissionState);
  const { contractorPlacmentDetails } = useAppSelector(
    getContractorDetailsState
  );
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
        <Stack>
          {isCandidateDetailsLoading ? (
            <Skeleton variant="circular" width={110} height={110} />
          ) : isNotNullOrUndefined(candidateDetails?.image) ? (
            <img
              src={candidateDetails?.image}
              alt="Nvidia Logo"
              style={{
                height: "auto",

                width: "97px",
                borderRadius: "100%",
              }}
            />
          ) : (
            <Stack
              sx={{
                width: 97,
                height: 97,
              }}
            >
              <AtsUserNameTextAvatar
                fs={24}
                name={`${candidateDetails?.first_name} ${candidateDetails?.last_name}`}
              />
            </Stack>
          )}
        </Stack>
        <Stack gap={"6px"}>
          {isCandidateDetailsLoading ? (
            <Skeleton width={150} height={20} />
          ) : (
            <AtsTitleText
              fs={18}
              fw={700}
              textColor={"#000"}
              text={`${candidateDetails?.first_name ?? ""} ${
                candidateDetails?.last_name ?? ""
              }`}
            />
          )}
          <AtsTitleText
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
            text={candidateDetails?.job_title ?? ""}
          />
          {/* <AtsTitleText
            fs={12}
            fw={400}
            textColor={"#000"}
            text={"Java Team"}
          /> */}
          <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
                text="Onboarded:"
              />
              {isCandidateDetailsLoading ? (
                <Skeleton width={150} height={20} />
              ) : (
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={"#000"}
                  text={
                    contractorPlacmentDetails?.onboarding_date.length
                      ? convertTimestampToDate(
                          contractorPlacmentDetails?.onboarding_date
                        )
                      : "--"
                  }
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ContractorInfo;
