import { Avatar, Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";

interface IProps {
  contractorName: string;
  projectName: string;
  contractorImg: string;
  role: string;
  renderBottomView: JSX.Element;
}

const ContractorComponent = ({
  contractorName,
  projectName,
  contractorImg,
  role,
  renderBottomView,
}: IProps) => {
  return (
    <>
      <Box
        sx={{
          p: "10px",
          bgcolor: BG_COLORS.GRAY,
          borderRadius: "5PX",
        }}
      >
        <Stack
          gap={"16px"}
          sx={{
            borderBottom: BORDERS.GRAY,
            pb: "15px",
          }}
        >
          {renderImgTextWrapComponent(
            "Contractor name",
            contractorName,
            contractorImg
          )}
          {renderTextWrapComponent("Project name", projectName)}
          {renderTextWrapComponent("Role", role)}
        </Stack>
        {renderBottomView ?? renderBottomView}
      </Box>
    </>
  );
};

export default ContractorComponent;

const renderImgTextWrapComponent = (
  title: string,
  value: string,
  contractorImg: string
) => {
  return (
    <Stack gap={"8px"}>
      <AtsTitleText text={title} fs={14} fw={600} textColor="#000" />
      <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
        <Avatar src={contractorImg} />
        <AtsTitleText text={value} fs={14} fw={400} textColor={COLORS.BLACK} />
      </Stack>
    </Stack>
  );
};
const renderTextWrapComponent = (title: string, value: string) => {
  return (
    <Stack gap={"8px"}>
      <AtsTitleText text={title} fs={14} fw={600} textColor="#000" />
      <AtsTitleText text={value} fs={14} fw={400} textColor={COLORS.BLACK} />
    </Stack>
  );
};

// const ProjectName =({projectId})=>{
//   // useEffect(() => {
//   //   projectId && projectId.length
//   // }, [])

//   return (
//     <Stack gap={"8px"}>
//       <AtsTitleText text={'Project name'} fs={14} fw={600} textColor="#000" />
//       <AtsTitleText text={value} fs={14} fw={400} textColor={COLORS.BLACK} />
//     </Stack>
//   );
// }
