import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import EmailTo from "../components/EmailTo";
import InviteAs from "../components/InviteAs";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getInviteMembersState,
  setInviteError,
  setIsSend,
} from "../../../redux/slices/inviteMembersSlice";
import { UserManagementServices } from "../../../Api/userManagementServices";
import { inviteMemberValidation } from "../../../utils/validationUtils";
import {
  AcceptInviteRequest,
  SendInviteUserRequest,
} from "../../../types/userManagementTypes";

const InviteMoreMember = () => {
  const dispatch = useAppDispatch();
  const { inviteMember } = useAppSelector(getInviteMembersState);

  const sendInviteUser = async () => {
    try {
      const validation = await inviteMemberValidation(inviteMember);
      dispatch(setInviteError(validation.error));
      if (validation.isValid) {
        const updateInviteMember = {
          ...inviteMember,
          role_id: Number(inviteMember.role_id),
        } as SendInviteUserRequest;
        await UserManagementServices.sendInviteUsers(updateInviteMember);
        dispatch(setIsSend(true));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const acceptInvite = async () => {
    try {
      const updateInviteMember = {
        hash: "cGVOd2pxV3VsM09ZSmFZa255K3d4MWxpaFRNUTVYbElqL2xlRS9hVzdSbFFxaUptTnQ5dS9ON0NvdnFXTllSUzo6aTViMFR0cU9SREZST1VobGdhdW8xZz09",
        password: "testpassword",
        first_name: "First Name",
        last_name: "Last Name",
      } as AcceptInviteRequest;
      await UserManagementServices.acceptInvite(updateInviteMember);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Stack
        gap={"28px"}
        sx={{
          mt: 5,
          pb: "170px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <AtsTitleText
          text={"Add emails you want to invite"}
          fs={18}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
        <Stack>
          <EmailTo />
        </Stack>
        <InviteAs />
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          pt: "30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          gap={0.2}
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <Box
            sx={{
              transform: "rotate(317deg)",
            }}
          >
            <AtsIcon
              icon="icon-link-21"
              color={COLORS.PRIMARY}
              fw={600}
              fs={18}
            />
          </Box>
          <AtsTitleText
            text={"Copy invite link"}
            fs={14}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
        </Stack>
        <AtsRoundedButton
          text="Send"
          endIcon={<KeyboardArrowRight />}
          ml={0}
          buttonWidth={134}
          buttonHeight={42}
          onClick={sendInviteUser}
          // onClick={acceptInvite}
        />
      </Stack>
    </>
  );
};

export default InviteMoreMember;
