import React, { useCallback, useMemo, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import FontUtil from "../../../../../../../utils/FontUtil";
import { Box, Stack, Typography } from "@mui/material";
import AtsIcon from "../../../../../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../../../../../theme";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";
import {
  DisputedData,
  DisputedTimeLog,
  TimeEntry,
} from "../../../../../../../types/timesheetTypes";
import { formattedAmount } from "../../../../../../../utils/common";

const slideVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: (direction) => ({
    x: direction > 0 ? -1000 : 1000,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  }),
};

interface IDisputedEntrieCardProps {
  disputedEntries: DisputedData;
  selectedTimesheet: number;
  setSelectedTimesheet: React.Dispatch<React.SetStateAction<number>>;
}

const DisputedEntriesCard = ({
  disputedEntries,
  selectedTimesheet,
  setSelectedTimesheet,
}: IDisputedEntrieCardProps) => {
  const entries = disputedEntries.disputed_time_logs;
  const { font14, font12 } = FontUtil();

  const [direction, setDirection] = React.useState(0);

  const date = (date) => {
    const formatted = new Date(Number(date) * 1000);
    const formattedDate = formatted.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const getTimeFormat = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const getTime = (timelog: TimeEntry) => {
    const start = getTimeFormat(new Date(Number(timelog.start_time) * 1000));
    const end = getTimeFormat(new Date(Number(timelog.end_time) * 1000));
    return `${start} to ${end}`;
  };

  const disputeData = useCallback(() => {
    if (!entries[selectedTimesheet]) return [];
    const tim_log = entries[selectedTimesheet]?.time_entries?.map((log) => {
      return {
        date: date(log.date),
        task: log?.time_entry_id,
        description: log?.work_description,
        time: getTime(log),
      };
    });

    return tim_log;
  }, [entries, selectedTimesheet]);

  const prevItem = () => {
    if (selectedTimesheet !== 0) {
      setSelectedTimesheet(selectedTimesheet - 1);
    }
  };
  const lastIndex = entries.length - 1;
  const nextItem = () => {
    if (lastIndex !== selectedTimesheet) {
      setSelectedTimesheet(selectedTimesheet + 1);
    }
  };

  const handleNext = () => {
    setDirection(1);
    nextItem();
  };

  const handlePrev = () => {
    setDirection(-1);
    prevItem();
  };

  return (
    <>
      <Box
        sx={{
          mx: "30px",

          position: "relative",
        }}
      >
        {entries.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: -30,
              cursor: "pointer",
              ...(selectedTimesheet === 0 && {
                opacity: "0.5",
                cursor: "default",
              }),
            }}
          >
            <Box px={0} py={0} onClick={handlePrev}>
              <AtsIcon icon="icon-arrow-left-2" height="25px" fs={25} />
            </Box>
          </Box>
        )}
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={selectedTimesheet}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            <Box
              sx={{
                px: "54px",
                py: "16px",
                bgcolor: "#f5f6f8",
                borderRadius: "6px",
              }}
            >
              <Stack
                textAlign={"center"}
                sx={{
                  pb: "13px",
                }}
              >
                <Typography
                  sx={{
                    ...font12,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    {" "}
                    {selectedTimesheet + 1}{" "}
                  </span>
                  of <span style={{ fontWeight: 600 }}>{entries.length}</span>{" "}
                  dispute entries
                </Typography>
              </Stack>
              <Stack
                className="scrollHide"
                sx={{
                  height: 127,
                  overflow: "auto",
                }}
              >
                {disputeData().map((data, index) => (
                  <Stack
                    key={index}
                    gap={"14px"}
                    sx={{
                      pt: "14px",
                      pb: "13px",
                      borderTop: BORDERS.GRAY,
                      borderBottom: BORDERS.GRAY,
                    }}
                  >
                    <TextWrapper title={"Date"} value={data.date} />
                    <TextWrapper title={"Task"} value={data.task} />
                    <TextWrapper
                      title={"Description of work"}
                      value={data.description}
                    />
                    <TextWrapper
                      title={"Start time - end time"}
                      value={data.time}
                    />
                  </Stack>
                ))}
              </Stack>
              <AtsRowBetween
                sx={{
                  pt: "14px",
                  pb: "13px",
                  borderBottom: BORDERS.GRAY,
                }}
              >
                <>
                  <AtsTitleText
                    text={"Time"}
                    fs={12}
                    fw={600}
                    textColor={"#000"}
                  />
                  <AtsTitleText
                    text={`${formattedAmount(
                      entries[selectedTimesheet]?.total_time
                    )} hrs`}
                    fs={12}
                    fw={600}
                    textColor={"#000"}
                  />
                </>
              </AtsRowBetween>
              <AtsRowBetween
                sx={{
                  pt: "14px",
                }}
              >
                <>
                  <AtsTitleText
                    text={"Time disputed in this entry"}
                    fs={14}
                    fw={600}
                  />
                  <AtsRow gap={"6px"}>
                    <>
                      <AtsRowBetween
                        gap={"5px"}
                        sx={{
                          px: "10px",
                        }}
                      >
                        <>
                          <AtsTitleText
                            text={entries[selectedTimesheet]?.disputed_hrs ?? 0}
                            fs={14}
                            fw={600}
                          />
                          <AtsTitleText
                            text={"hrs"}
                            fs={14}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                          />
                        </>
                      </AtsRowBetween>
                      <AtsRowBetween
                        gap={"5px"}
                        sx={{
                          px: "10px",
                        }}
                      >
                        <>
                          <AtsTitleText
                            text={
                              entries[selectedTimesheet]?.disputed_mins ?? 0
                            }
                            fs={14}
                            fw={600}
                          />
                          <AtsTitleText
                            text={"mins"}
                            fs={14}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                          />
                        </>
                      </AtsRowBetween>
                    </>
                  </AtsRow>
                </>
              </AtsRowBetween>
              <Stack
                gap={"5px"}
                sx={{
                  pt: "14px",
                }}
              >
                <>
                  <AtsTitleText text={"Dispute description"} fs={14} fw={600} />
                  <AtsTitleText
                    text={
                      entries[selectedTimesheet]?.dispute_description ?? "--"
                    }
                    fs={12}
                    fw={400}
                  />
                </>
              </Stack>
            </Box>
          </motion.div>
        </AnimatePresence>

        {entries.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: -30,
              cursor: "pointer",
              ...(lastIndex === selectedTimesheet && {
                opacity: "0.5",
                cursor: "default",
              }),
            }}
          >
            <Box px={0} py={0} onClick={handleNext}>
              <AtsIcon icon="icon-arrow-right-3" height="25px" fs={25} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DisputedEntriesCard;

const TextWrapper = ({ title, value }) => {
  return (
    <AtsRow>
      <>
        <Stack width={"50%"}>
          <AtsTitleText
            text={title}
            fs={12}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
        <Stack width={"50%"} textAlign={"end"}>
          <AtsTitleText
            text={value}
            fs={12}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
      </>
    </AtsRow>
  );
};
