import axios from "axios";
import {
  CreateInvoiceRequest,
  InvoiceDetailsResponse,
  InvoiceListResponse,
  InvoicesListParams,
  InvoicesStatsResponse,
} from "../types/invoiceTypes";
import { PROJECT_BASE_URL } from "../utils/url";
import { PaginatedResponse } from "../types/commonType";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  INVOICE_LIST: "client/invoices",
  INVOICE_DETAILS: "client/invoice/details",
  INVOICE_CREATE: "invoice/create",
  INVOICE_STATS: "invoice-stats",
};

const PROJECT_ENDPOINT = `${PROJECT_BASE_URL}${Endpoint.BASE}`;

const INVOICE_LIST_URL = PROJECT_ENDPOINT + Endpoint.INVOICE_LIST;
const INVOICE_DETAILS_URL = PROJECT_ENDPOINT + Endpoint.INVOICE_DETAILS;
const INVOICE_CREATE_URL = PROJECT_ENDPOINT + Endpoint.INVOICE_CREATE;
const INVOICE_STATS_URL = PROJECT_ENDPOINT + Endpoint.INVOICE_STATS;

export class InvoiceServices {
  static async getInvoices(
    params: Partial<InvoicesListParams>
  ): Promise<PaginatedResponse<InvoiceListResponse>> {
    const response = await axios.get(INVOICE_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getInvoiceData(params: {
    nid: string;
  }): Promise<InvoiceDetailsResponse[]> {
    const response = await axios.get(INVOICE_DETAILS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getInvoiceStats(params: {
    project_id: string;
  }): Promise<InvoicesStatsResponse[]> {
    const response = await axios.get(INVOICE_STATS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createInvoice(
    request: CreateInvoiceRequest
  ): Promise<InvoiceDetailsResponse[]> {
    const response = await axios.post(INVOICE_CREATE_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
