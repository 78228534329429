import React from "react";
import AtsCard from "../../../components/AtsCard";
import { Grid, Stack } from "@mui/material";
import { Skeleton } from "@mui/material";

const LoadingInterviewCard = () => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
      <img
        src={require("../../../assets/emptyStateImages/emptyInterviewCard.png")}
        alt=""
      />
    </Grid>
  );
};

export default LoadingInterviewCard;
