import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import { MyProfileTabsEnums } from "../../../enums/myProfileEnums";
import { useMyProfile } from "../../../providers/MyProfileProvider";
import { COLORS } from "../../../theme";
import JobsSwitch from "../../Dashboard/PostedJobs/JobsSwitch";

const MyProfileTabs = () => {
  const { selectedMyProfileTab, setSelectedMyProfileTab } = useMyProfile();

  return (
    <>
      <AtsTitleText text="Profile" fs={24} fw={600} textColor={COLORS.BLACK} />
      <AtsRowBetween sx={{ pt: "22px", pb: "19px" }}>
        <>
          <JobsSwitch
            width="40px"
            jobs={Object.values(MyProfileTabsEnums).map((value) => ({
              title: value,
              type: value,
            }))}
            active={selectedMyProfileTab}
            onChange={(value) => {
              setSelectedMyProfileTab(value as MyProfileTabsEnums);
            }}
          />
        </>
      </AtsRowBetween>
    </>
  );
};

export default MyProfileTabs;
