import moment from "moment";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { InsightsServices } from "../Api/insightsServices";
import { InsightTypeTabs } from "../enums/insightsEnums";
import { getAuthState } from "../redux/slices/authSlice";
import { useAppSelector } from "../redux/store";
import {
  GetModifyRequisitionResponse,
  GetOtherInsightsResponse,
} from "../types/insightsTypes";
import { getDateFormatMMMDYYYYY } from "../utils/dateUtils";

type InsightsModuleContextType = {
  selectedInsightsTab: InsightTypeTabs;
  dateText: string;
  startDate: string;
  endDate: string;
  requisitionsLoading: boolean;
  othersInsightsLoading: boolean;
  remoteLocationsLoading: boolean;
  requisitionsByLocation: GetModifyRequisitionResponse[];
  othersInsightsData: GetOtherInsightsResponse | null;
  handleChangeTab: (value: InsightTypeTabs) => void;
};

const InsightsModule = createContext<InsightsModuleContextType | null>(null);

export const useInsights = () =>
  useContext(InsightsModule) as InsightsModuleContextType;

export const InsightsProvider = ({ children }: any) => {
  const { userInfo } = useAppSelector(getAuthState);
  const [selectedInsightsTab, setSelectedInsightsTab] =
    useState<InsightTypeTabs>(InsightTypeTabs.Talent);
  const [startDate, setStartDate] = useState("");

  const [requisitionsByLocation, setRequisitionsByLocation] = useState<
    GetModifyRequisitionResponse[]
  >([]);
  const [othersInsightsData, setOthersInsightsData] =
    useState<GetOtherInsightsResponse | null>(null);
  const [endDate, setEndDate] = useState("");

  const [requisitionsLoading, setRequisitionsLoading] = useState(false);
  const [remoteLocationsLoading, setRemoteLocationsLoading] = useState(false);
  const [othersInsightsLoading, setOthersInsightsLoading] = useState(false);

  const handleChangeTab = (value: InsightTypeTabs) => {
    setSelectedInsightsTab(value);
  };

  const dateText = useMemo(() => {
    const from = moment(new Date())?.startOf("week").toString();
    setStartDate(from);
    const to = moment(new Date())?.endOf("week").toString();
    setEndDate(to);
    return `${getDateFormatMMMDYYYYY(from)} - ${getDateFormatMMMDYYYYY(to)}`;
  }, []);

  const getCoordinatesFromAddress = async (address: string) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        return { lat: location.lat, lng: location.lng };
      } else {
        console.error("Geocoding failed:", data.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
      return null;
    }
  };

  const getRequisitionsByLocation = async (company_id: string) => {
    setRequisitionsLoading(true);
    try {
      const response = await InsightsServices.getRequisitionsLocation({
        company_id,
      });
      if (response.length) {
        const getLogLatResponse = await Promise.all(
          response.map(async (value) => {
            const latLngResponse = await getCoordinatesFromAddress(
              value.job_location
            );
            return {
              job_location: value.job_location,
              count:
                typeof value.count === "string"
                  ? Number(value.count)
                  : value.count,
              coordinates: [latLngResponse?.lng || 0, latLngResponse?.lat || 0],
            };
          })
        );

        setRequisitionsByLocation(getLogLatResponse);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRequisitionsLoading(false);
    }
  };
  const getOtherInsights = async (company_id: string) => {
    setOthersInsightsLoading(true);
    try {
      const response = await InsightsServices.getCompanyInsights({
        company_id,
      });
      setOthersInsightsData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setOthersInsightsLoading(false);
    }
  };
  const getRemoteLocations = async (company_id: string) => {
    setRemoteLocationsLoading(true);
    try {
      const response = await InsightsServices.getRemoteLocation({ company_id });
    } catch (error) {
      console.error(error);
    } finally {
      setRemoteLocationsLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.company) {
      getRequisitionsByLocation(userInfo.company.id);
      getOtherInsights(userInfo.company.id);
      getRemoteLocations(userInfo.company.id);
    }
  }, []);

  return (
    <InsightsModule.Provider
      value={{
        startDate,
        othersInsightsData,
        requisitionsByLocation,
        endDate,
        dateText,
        selectedInsightsTab,
        remoteLocationsLoading,
        requisitionsLoading,
        othersInsightsLoading,
        handleChangeTab,
      }}
    >
      {children}
    </InsightsModule.Provider>
  );
};
