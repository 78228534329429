import { Stack, StackProps } from "@mui/material";
import React from "react";

interface IAtsRowBetweenProps extends StackProps {
  children: JSX.Element;
}

const AtsRow = ({ children, ...rest }: IAtsRowBetweenProps) => {
  return (
    <Stack direction={"row"} alignItems={"center"} {...rest}>
      {children}
    </Stack>
  );
};

export default AtsRow;
