import { Box, Stack } from "@mui/material";
import { NoInterviewIcon } from "../../../../../../../../../assets/icons/Icons";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { setIsOpenInterviewDetailsDialog } from "../../../../../../../../../redux/slices/interviewSlice";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { BORDERS } from "../../../../../../../../../theme";

const NoInterviewDetails = () => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        maxWidth: "295px",
        mx: "auto",
      }}
    >
      <Stack
        mt={3}
        direction={"row"}
        gap={3}
        sx={{
          alignItems: "cneter",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <NoInterviewIcon />
      </Stack>
      <Stack
        textAlign={"center"}
        pt={3}
        pb={5}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        <AtsTitleText
          text={"No interviews scheduled with Trevor"}
          fs={"0.875rem"}
          fw={400}
          textColor="#000"
        />
      </Stack>
      <Stack textAlign={"center"} pt={5}>
        <Box>
          <AtsRoundedButton
            text="Schedule New"
            buttonVariant="outline"
            onClick={() => dispatch(setIsOpenInterviewDetailsDialog(true))}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default NoInterviewDetails;
