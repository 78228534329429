import { Close } from "@mui/icons-material";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsModal from "../../../../components/AtsModal";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import AtsRow from "../../../../components/AtsRow";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useMessages } from "../../../../providers/MessagesProvider";
import { BORDERS, COLORS } from "../../../../theme";
import FontUtil from "../../../../utils/FontUtil";
import MemberSelector from "./components/MemberSelector";
import MembersSelector from "./components/MembersSelector";
import { ProjectService } from "../../../../Api/projectServices";
import { useEffect, useState } from "react";
import {
  ProjectListProps,
  TeamMemberProps,
} from "../../../../types/projectTypes";
import { isNotNullOrUndefined } from "../../../../utils/stringUtils";
import {
  CreateGroupRequest,
  GroupUsers,
} from "../../../../types/messagesTypes";
import { CreateUpdateEnum } from "../../../../enums/commonEnums";

const AddMembersModal = () => {
  const { font12 } = FontUtil();
  const {
    handleOpenAddMembersModal,
    openAddMembersModal,
    messenger,
    projectId,
    createOrUpdateGroup,
    createGroupLoading,
  } = useMessages();
  const [projectsDetails, setProjectsDetails] =
    useState<ProjectListProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<
    TeamMemberProps[]
  >([]);
  //   const onNextBackClick = (value: string) => {
  //     setSelectedStep(value);
  //   };

  const onClose = () => {
    handleOpenAddMembersModal(false);
  };
  const onUpdateMember = () => {
    const users = selectedTeamMembers.map((member) => {
      return {
        firstName: member?.first_name ?? "",
        lastName: member?.last_name ?? "",
        picture: member?.image ?? "",
        email: member?.email ?? "",
        id: member?.uid,
      } as GroupUsers;
    });
    const chatName = projectId.length
      ? `${messenger.chatName}?projectId=${projectId}`
      : messenger.chatName;
    const updatedUser = {
      id: messenger.id,
      chatName,
      users,
    } as CreateGroupRequest;
    createOrUpdateGroup(updatedUser, CreateUpdateEnum.Update);
  };

  const fetchProjectDetails = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({
        nid: projectId,
      });
      setProjectsDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    projectId.length ? fetchProjectDetails() : setProjectsDetails(null);
  }, [projectId]);
  return (
    <>
      <AtsModal width={633} open={openAddMembersModal} onClose={onClose}>
        <Box height={"100%"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              text={"Add Members"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsCustomButton px={0} py={0} onClick={onClose}>
              <Close />
            </AtsCustomButton>
          </Stack>
          <Stack px={"30px"} pt={"40px"} gap={"10px"}>
            <Stack gap={"6px"}>
              <AtsTitleText
                fs={14}
                fw={600}
                textColor="#000"
                text={`Add member to ${messenger?.chatName} group`}
              />
              <MembersSelector
                project_id={projectId}
                selected={selectedMembers}
                onSelect={(selected) => setSelectedMembers(selected)}
                selectedTeamMembers={selectedTeamMembers}
                setSelectedTeamMembers={setSelectedTeamMembers}
              />
              {/* <MemberSelector selected={[]} onChange={() => {}} /> */}
            </Stack>
            <Typography
              sx={{
                ...font12,
                fontWeight: 400,
                color: COLORS.DARK_GRAY,
              }}
            >
              Showing all team members working on{" "}
              {loading ? (
                <Skeleton width={50} height={25} variant="text" />
              ) : (
                isNotNullOrUndefined(projectsDetails) && (
                  <span
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {projectsDetails.title}
                  </span>
                )
              )}
            </Typography>
          </Stack>
          <AtsRow
            sx={{ justifyContent: "end", pt: "29px", pb: "38px", pr: "30px" }}
          >
            {selectedTeamMembers.length > 0 ? (
              <AtsRoundedButton
                buttonWidth={132}
                buttonHeight={30}
                text="Add"
                onClick={onUpdateMember}
                loading={createGroupLoading}
              />
            ) : (
              <AtsRoundedButton
                buttonWidth={132}
                buttonHeight={30}
                isInitial
                buttonVariant="outline"
                // disabled={isDisabled || isLoading}
                text="Skip for now"
                onClick={onClose}
                // loading={isLoading}
              />
            )}
          </AtsRow>
        </Box>
      </AtsModal>
    </>
  );
};

export default AddMembersModal;
