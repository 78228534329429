import { Stack } from "@mui/material";
import AtsTextInput from "../../../../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  getInterviewState,
  setCreateInterview,
} from "../../../../../../../../redux/slices/interviewSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { COLORS } from "../../../../../../../../theme";
import { CreateUpdateInterviewParams } from "../../../../../../../../types/interviewTypes";
import SelectInterviewer from "./components/SelectInterviewer";
import { getAuthState } from "../../../../../../../../redux/slices/authSlice";
import { useEffect } from "react";
import AtsRow from "../../../../../../../../components/AtsRow";

interface IProps {
  error: any;
}

const AttendeeDetails = ({ error }: IProps) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const { createInterview } = useAppSelector(getInterviewState);

  const handleInterviewChange = (
    name: keyof CreateUpdateInterviewParams,
    value: string
  ) => {
    const interviewState = {
      ...createInterview,
      [name]: value,
    } as CreateUpdateInterviewParams;
    dispatch(setCreateInterview(interviewState));
  };

  const handleInterviewer = (
    name: keyof CreateUpdateInterviewParams,
    value: string
  ) => {
    const initailInterviewer = [...createInterview.interviewers];
    if (initailInterviewer.includes(value)) {
      const filteredInterviewer = initailInterviewer.filter(
        (email) => email !== value
      );
      const interviewState = {
        ...createInterview,
        [name]: [...filteredInterviewer],
      } as CreateUpdateInterviewParams;
      dispatch(setCreateInterview(interviewState));
    } else {
      const interviewState = {
        ...createInterview,
        [name]: [...initailInterviewer, value],
      } as CreateUpdateInterviewParams;
      dispatch(setCreateInterview(interviewState));
    }
  };

  useEffect(() => {
    const interviewState = {
      ...createInterview,
      ["interviewers"]: [userInfo?.email],
    } as CreateUpdateInterviewParams;
    dispatch(setCreateInterview(interviewState));
  }, []);

  return (
    <>
      <Stack sx={{ pt: 0, pb: 2, pl: 0, pr: 0 }}>
        <Stack gap={3}>
          <Stack gap={1} sx={{ position: "relative" }}>
            <AtsTitleText
              text={"Meeting Name"}
              fs={12}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsTextInput
              placeholder="Enter meeting name"
              value={createInterview.subject}
              onChange={(e) => handleInterviewChange("subject", e.target.value)}
            />
            <Stack sx={{ position: "absolute", left: 0, bottom: -15 }}>
              <AtsTitleText
                text={error["subject"]}
                fs={12}
                fw={500}
                textColor={COLORS.RED_LIGHT}
              />
            </Stack>
          </Stack>
          <Stack gap={1} sx={{ position: "relative" }}>
            <AtsRow gap={0.3} flexWrap={"wrap"}>
              <>
                <AtsTitleText
                  text={"Selecte Interviewer"}
                  fs={12}
                  fw={500}
                  textColor={COLORS.DARK_GRAY}
                />
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                  text={
                    "(Interviewer is the one who will conduct the interview)"
                  }
                />
              </>
            </AtsRow>
            <SelectInterviewer
              selected={createInterview.interviewers}
              onChange={(selected) =>
                handleInterviewer("interviewers", selected)
              }
            />

            <Stack sx={{ position: "absolute", left: 0, bottom: -15 }}>
              <AtsTitleText
                text={error["interviewers"]}
                fs={12}
                fw={500}
                textColor={COLORS.RED_LIGHT}
              />
            </Stack>
          </Stack>
          <Stack gap={1} sx={{ position: "relative" }}>
            <AtsTitleText
              text={
                "Please share anything that will help prepare for our meeting."
              }
              fs={12}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsTextInput
              placeholder="Enter your details here"
              isTextArea
              rows={5}
              multiline
              value={createInterview.description}
              onChange={(e) =>
                handleInterviewChange("description", e.target.value)
              }
            />
            <Stack sx={{ position: "absolute", left: 0, bottom: -15 }}>
              <AtsTitleText
                text={error["description"]}
                fs={12}
                fw={500}
                textColor={COLORS.RED_LIGHT}
              />
            </Stack>
          </Stack>
          <AtsTitleText
            fs={12}
            fw={500}
            textColor={COLORS.PRIMARY}
            lineHeight={1.3}
            text="By proceeding, you confirm that you have read and agree to our Terms of Use and Privacy Policy."
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AttendeeDetails;
