import { Checkbox, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import AtsTextArea from "../../../components/AtsTextArea";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setCheckedAIGenreted,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BG_COLORS, COLORS } from "../../../theme";
import JobCardWrapper from "../components/JobCardWrapper";
import AtsCheckbox from "../../../components/AtsCheckbox";

const JobDescription = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, checkedAIGenreted } = useAppSelector(
    getPostOpportunityState
  );

  const [loading, setLoading] = useState<boolean>(false);
  const chatAPI = `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`;
  // const demoProxyServerUrl = "https://gptalks.api.nlux.dev/openai/chat/stream";
  const handleChange = (key: keyof PostOpportunityProps, value: string) => {
    const opportunityObj = {
      ...postOpportunity,
      [key]: value,
    } as PostOpportunityProps;
    dispatch(setPostOpportunity(opportunityObj));
  };

  const handleCheck = (e) => {
    const checked = e.target.checked;
    dispatch(setCheckedAIGenreted(checked));
    if (!checked) {
      const opportunityObj = {
        ...postOpportunity,
        job_decription: "",
        job_what_you_ll_do: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const getDescription = async () => {
    const newMessage = {
      role: "user",
      // content: `Sample job posting for a ${postOpportunity?.job_title} job with detailed Job description, Key Responsibilities, Requirements/Skills and fix this text to be marked up with semantic HTML using only lists, headers, or paragraph tags and remove first paragaph, job title heading, job posting heading`,
      content: `give me only job description for ${postOpportunity?.job_title} and Responsibilities under 500 word`,
    };
    const newMsg = [newMessage];
    const url = "https://api.openai.com/v1/chat/completions";
    const token = `Bearer ${chatAPI}`;
    const model = "gpt-4";
    setLoading(true);
    try {
      const response = await axios.post(
        url,
        {
          model: model,
          messages: newMsg,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const content =
        response.data?.choices && response.data?.choices[0]?.message?.content;

      const descriptionStart = content.indexOf("Job Description:");
      const responsibilitiesStart = content.indexOf("Responsibilities:");

      const jobDescription = content
        .substring(
          descriptionStart + "Job Description:".length,
          responsibilitiesStart
        )
        .trim();
      const responsibilities = content
        .substring(responsibilitiesStart + "Responsibilities:".length)
        .trim();
      const opportunityObj = {
        ...postOpportunity,
        job_decription: jobDescription,
        job_what_you_ll_do: responsibilities,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkedAIGenreted) {
      if (
        postOpportunity?.job_title.length &&
        !postOpportunity?.job_decription.length &&
        !postOpportunity?.job_what_you_ll_do.length
      ) {
        getDescription();
      }
    }
  }, [checkedAIGenreted]);

  return (
    <>
      <JobCardWrapper
        header="Create a job description"
        subText="Let opportunity seekers know what you are looking for, and what they will be doing."
      >
        <Stack gap={"25px"} pr={1} mb={1}>
          <Stack gap={"55px"}>
            <AtsTextArea
              header="Overview"
              loading={loading}
              cols={12}
              rows={4}
              className="textArea"
              value={postOpportunity.job_decription}
              onChange={(event) =>
                handleChange("job_decription", event.target.value)
              }
            />
            <AtsTextArea
              header="What they'll be doing"
              loading={loading}
              cols={12}
              rows={4}
              className="textArea"
              value={postOpportunity.job_what_you_ll_do}
              onChange={(event) =>
                handleChange("job_what_you_ll_do", event.target.value)
              }
            />
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            sx={{
              bgcolor: BG_COLORS.PRIMARY,
              borderRadius: 2,
              px: 1,
              py: 0.5,
            }}
          >
            <AtsCheckbox
              disabled={loading}
              onChange={(e) => handleCheck(e)}
              checked={checkedAIGenreted}
            />
            <AtsTitleText text={"Use AI to create your job descriptions"} />
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobDescription;
