import { Close } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setJobDetailsById,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import {
  JobDetailByIdResponse,
  JobDetailsResponse,
  JobIndustriesResponse,
} from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import JobTitleSelector from "../components/JobTitleSelector";
import ButtonSelection from "../components/ButtonSelection";
import { truncate } from "fs";

const ChooseJobTitle = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById } = useAppSelector(
    getPostOpportunityState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<JobIndustriesResponse[]>([]);

  const handleChange = (value: string) => {
    // console.log("value", value);
    if (postOpportunity.job_title === value) {
      const opportunityObj = {
        ...postOpportunity,
        job_title: "",
        job_what_you_ll_do: "",
        job_decription: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          title: "",
        } as JobDetailByIdResponse)
      );
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_title: value,
        job_what_you_ll_do: "",
        job_decription: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          title: value,
        } as JobDetailByIdResponse)
      );
    }
  };

  const fetchRelatedOption = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobTitle({
        name: jobDetailsById.job_category,
      });
      setJobTitle(response.results);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRelatedOption();
  }, []);
  return (
    <>
      <JobCardWrapper header="Next, choose a job title for your opportunity.">
        <Stack mt={2} px={1}>
          <Stack gap={2}>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <JobTitleSelector
                selected={postOpportunity.job_title}
                onSelected={(seleted) => handleChange(seleted)}
              />
            </Box>
            <Stack
              direction={"row"}
              gap={1}
              flexWrap={"wrap"}
              pb={2}
              sx={{ borderBottom: BORDERS.GRAY }}
            >
              {postOpportunity.job_title?.length ? (
                <ButtonSelection
                  onSelect={() => handleChange(postOpportunity.job_title)}
                  text={postOpportunity.job_title}
                  isSelected={true}
                />
              ) : (
                <AtsTitleText
                  text={"Eg. Software Engineer, UX Designer"}
                  fs={"0.875rem"}
                  fw={600}
                  textColor={COLORS.LIGHT_GRAY}
                />
              )}
            </Stack>
          </Stack>
          <Stack gap={1} pt={2}>
            <AtsTitleText
              text={"Related options"}
              fs={"0.875rem"}
              fw={600}
              textColor={COLORS.LIGHT_GRAY}
            />
            <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
              {loading
                ? Array.from(new Array(8)).map((_, index) => (
                    <AtsRoundedButton
                      key={index}
                      text={"...loading"}
                      loading={loading}
                      buttonVariant={"whiteContained"}
                    />
                  ))
                : jobTitle.map((title, index) => (
                    <ButtonSelection
                      onSelect={() => handleChange(title.name)}
                      key={index}
                      text={title.name}
                      loading={loading}
                      isSelected={postOpportunity.job_title === title.name}
                    />
                  ))}
            </Stack>
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default ChooseJobTitle;
