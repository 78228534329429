import { Add } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BackwordIcon, ForwordIcon, StarIcon } from "../assets/icons/Icons";
import AtsRoundedButton from "../components/AtsRoundedButton";
import AtsTitleText from "../components/AtsTitleText";
import { getAuthState, setIsOpen } from "../redux/slices/authSlice";
import { setInitialPostOpportunityState } from "../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { ROUTES, RoutesProps, SUB_ROUTES } from "../router/ATS_routes";
import { ATS_ROUTES } from "../router/routes";
import { COLORS } from "../theme";
import "./css/sidebar.css";
import AtsSidebar from "./components/AtsSidebar";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector(getAuthState);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",

      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        animate={{
          width: isOpen ? "250px" : "85px",
          transition: {
            duration: 0.3,
          },
        }}
        className={`sidebar`}
      >
        <div
          className="toggle_icon"
          onClick={() => dispatch(setIsOpen(!isOpen))}
        >
          {isOpen ? <ForwordIcon /> : <BackwordIcon />}
        </div>
        <div className="top_section mb-20" style={{ marginTop: "10px" }}>
          <AnimatePresence>
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <Box
                sx={{
                  height: "50px",
                  cursor: "pointer",
                  ...(isOpen ? { mx: "35px" } : { ml: "20px" }),
                }}
                onClick={() => {
                  navigate(ATS_ROUTES.DASHBOARD);
                }}
              >
                {isOpen ? (
                  <img
                    height={"100%"}
                    src={require("../assets/images/logo.png")}
                    alt="esteemed"
                  />
                ) : (
                  <StarIcon />
                )}
              </Box>
            </motion.div>
          </AnimatePresence>
        </div>

        <AtsSidebar isOpen={isOpen} />
      </motion.div>
    </>
  );
};

export default Sidebar;
