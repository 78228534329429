import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { JobDetailsResponse } from "../../types/jobsTypes";
import {
  CandidateDetailsProps,
  CandidateSkillsMatchResponse,
  GetSubmissionResponse,
} from "../../types/submissionTypes";

interface SubmissionState {
  isCandidateDetailsDrawerOpen: boolean;
  isCandidateDetailsLoading: boolean;
  jobDetailsForSubmission: JobDetailsResponse | null;
  candidateDetails: CandidateDetailsProps | null;
  submissionDetails: GetSubmissionResponse | null;
  skill: CandidateSkillsMatchResponse;
  submissions: GetSubmissionResponse[];
  candidateId: string;
}

const initialState: SubmissionState = {
  isCandidateDetailsDrawerOpen: false,
  isCandidateDetailsLoading: false,
  jobDetailsForSubmission: null,
  candidateDetails: null,
  submissionDetails: null,
  skill: {
    matched: 0,
    total: 0,
  },
  submissions: [],
  candidateId: "",
};

const submissionSlice = createSlice({
  name: "submissions",
  initialState,
  reducers: {
    setJobDetailsForSubmission: (state: SubmissionState, action) => {
      const { payload } = action;
      state.jobDetailsForSubmission = payload;
    },
    setIsCandidateDetailsDrawerOpen: (state: SubmissionState, action) => {
      const { payload } = action;
      state.isCandidateDetailsDrawerOpen = payload;
    },
    setCandidateDetails: (state: SubmissionState, action) => {
      const { payload } = action;
      state.candidateDetails = payload;
    },
    setSubmissionDetails: (state: SubmissionState, action) => {
      const { payload } = action;
      state.submissionDetails = payload;
    },
    setIsCandidateDetailsLoading: (state: SubmissionState, action) => {
      const { payload } = action;
      state.isCandidateDetailsLoading = payload;
    },
    setSubmissions: (state: SubmissionState, action) => {
      const { payload } = action;
      state.submissions = payload;
    },
    setCandidateId: (state: SubmissionState, action) => {
      const { payload } = action;
      state.candidateId = payload;
    },
    setSkill: (state: SubmissionState, action) => {
      const { payload } = action;
      state.skill = payload;
    },
  },
});

export const {
  setIsCandidateDetailsDrawerOpen,
  setJobDetailsForSubmission,
  setCandidateDetails,
  setSubmissionDetails,
  setSubmissions,
  setIsCandidateDetailsLoading,
  setCandidateId,
  setSkill,
} = submissionSlice.actions;
export const getSubmissionState = (state: RootState) => state.submissions;
export default submissionSlice.reducer;
