import { Box, Stack } from "@mui/material";
import React from "react";
import {
  OnboardingStatusEnum,
  OnboardingStepEnum,
  useOnboarding,
} from "../../../../../../../../../providers/OnboardingProvider";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import { BG_COLORS, COLORS } from "../../../../../../../../../theme";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import OnboardingSchedule from "../components/OnboardingSchedule";
import SelectPolicyDocuments from "../components/SelectPolicyDocuments";
import SetupItEquipment from "../components/SetupItEquipment";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import AtsRow from "../../../../../../../../../components/AtsRow";
import ResponsibilitesStep from "../components/ResponsibilitesStep";

const OnboardingSteps = () => {
  const { selectedOnboardingStep, setSelectedOnboardingStep } = useOnboarding();
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <AtsRowBetween
        sx={{
          bgcolor: BG_COLORS.PRIMARY,
          py: "19px",
          px: "30px",
          borderRadius: "10px",
        }}
      >
        <>
          {Object.keys(OnboardingStepEnum).map((step, index) => {
            return (
              <Stack
                key={step}
                sx={{
                  cursor: "pointer",
                }}
                // onClick={() =>
                //   setSelectedOnboardingStep(OnboardingStepEnum[step])
                // }
              >
                <AtsRow gap={"5px"}>
                  <>
                    {isContractorOnboarded ? (
                      ""
                    ) : (
                      <AtsTitleText
                        text={`${index + 1}.`}
                        fs={16}
                        fw={600}
                        textColor={
                          selectedOnboardingStep === OnboardingStepEnum[step]
                            ? COLORS.PRIMARY
                            : "#93b1e6"
                        }
                      />
                    )}
                    <AtsTitleText
                      text={`${OnboardingStepEnum[step]}`}
                      fs={16}
                      fw={600}
                      textColor={
                        selectedOnboardingStep === OnboardingStepEnum[step]
                          ? COLORS.PRIMARY
                          : "#93b1e6"
                      }
                    />
                  </>
                </AtsRow>
              </Stack>
            );
          })}
        </>
      </AtsRowBetween>
      <Box
        sx={{
          mt: "34px",
          height: "calc(100% - 85px)",
          overflow: "auto",
        }}
      >
        {selectedOnboardingStep === OnboardingStatusEnum.OnboardingSchedule ? (
          <OnboardingSchedule />
        ) : selectedOnboardingStep ===
          OnboardingStatusEnum.SelectPolicyDocuments ? (
          <SelectPolicyDocuments />
        ) : selectedOnboardingStep === OnboardingStatusEnum.Responsibilites ? (
          <ResponsibilitesStep />
        ) : (
          <SetupItEquipment />
        )}
      </Box>
    </Box>
  );
};

export default OnboardingSteps;
