import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "./callingSlice";
import { RootState } from "../store";

interface Conversation {
  id: string;
  recipient?: UserInfo;
  unReadCount?: number;
  isGroupChat?: boolean;
  // Add other conversation fields as needed
}

interface ConversationState {
  conversations: Conversation[];
  currentConversation: Conversation | null;
  isConversationLoading: boolean;
}

const initialState: ConversationState = {
  conversations: [],
  currentConversation: null,
  isConversationLoading: false,
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setCurrentConversation(state, action: PayloadAction<Conversation | null>) {
      state.currentConversation = action.payload;
    },
    setConversations(state, action: PayloadAction<Conversation[]>) {
      state.conversations = action.payload;
    },
    setUnReadCount(state, action: PayloadAction<string>) {
      const conversation = state.conversations.find(
        (c) => c.id === action.payload
      );
      if (conversation) {
        conversation.unReadCount = (conversation.unReadCount || 0) + 1;
      }
    },
    setConversationLoading(state, action: PayloadAction<boolean>) {
      state.isConversationLoading = action.payload;
    },
    // Additional conversation-related actions can go here
  },
});

export const {
  setCurrentConversation,
  setConversations,
  setUnReadCount,
  setConversationLoading,
} = conversationSlice.actions;
export const getConversationState = (state: RootState) => state.conversation;
export default conversationSlice.reducer;
