import { Box, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { Close } from "@mui/icons-material";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTextInput from "../../../components/AtsTextInput";
import { UserManagementServices } from "../../../Api/userManagementServices";
import { useAutoLogout } from "../../../providers/AutoLogoutProvider";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";
import { useAppDispatch } from "../../../redux/store";
import { setIsAuth } from "../../../redux/slices/authSlice";

interface IProps {
  onClose: () => void;
}

const ChangePassword = ({ onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const { handleLogout } = useAutoLogout();
  const [errorState, setErrorState] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordState, setPasswordState] = useState({
    password: "",
    confirmPassword: "",
  });

  const confirm = async () => {
    if (passwordState.password === passwordState.confirmPassword) {
      setErrorState("");
      setLoading(true);
      try {
        const decodedPassword = btoa(
          unescape(encodeURIComponent(passwordState.password))
        );
        await UserManagementServices.changePassword({
          password: decodedPassword,
        });
        dispatch(setIsAuth(false));
        handleLogout();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorState("password does not match");
    }
  };
  return (
    <>
      <Box
        sx={{
          px: "30px",
          py: "20px",
          borderBottom: BORDERS.GRAY,
          position: "relative",
        }}
      >
        <AtsRow gap={"10px"}>
          <>
            <AtsTitleText text={"Change your password"} fs={18} fw={600} />
          </>
        </AtsRow>
        <IconButton
          onClick={onClose}
          sx={{ p: 1, position: "absolute", top: 10, right: 10, color: "#000" }}
        >
          <Close />
        </IconButton>
      </Box>
      <Stack
        gap={"15px"}
        sx={{
          px: "30px",
          py: "20px",
        }}
      >
        <AtsTextInput
          header="New password"
          placeholder="Enter new password"
          value={passwordState.password}
          onChange={(e) =>
            setPasswordState({ ...passwordState, password: e.target.value })
          }
        />
        <Box
          sx={{
            position: "relative",
          }}
        >
          <AtsTextInput
            header="Confirm new password"
            placeholder="Enter confirm new password"
            value={passwordState.confirmPassword}
            onChange={(e) =>
              setPasswordState({
                ...passwordState,
                confirmPassword: e.target.value,
              })
            }
          />
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={errorState}
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
      <Box
        sx={{
          px: "30px",
          py: "20px",
          borderTop: BORDERS.GRAY,
          position: "relative",
        }}
      >
        <AtsRowBetween>
          <>
            <Box></Box>
            <AtsRoundedButton
              text="Confirm"
              buttonHeight={35}
              buttonWidth={130}
              onClick={confirm}
              disabled={
                loading ||
                !passwordState.password.length ||
                !passwordState.confirmPassword.length
              }
              loading={loading}
            />
          </>
        </AtsRowBetween>
      </Box>
    </>
  );
};

export default ChangePassword;
