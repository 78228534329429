import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BG_COLORS, BORDERS } from "../../../../theme";
import ChatRoomTopSection from "./components/ChatRoomTopSection";
import { useMessages } from "../../../../providers/MessagesProvider";
import { isNotNullOrUndefined } from "../../../../utils/stringUtils";
import AtsRow from "../../../../components/AtsRow";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsIcon from "../../../../components/AtsIcon";
import ConversationBox from "./components/ConversationBox";
import AddMembersInGroup from "./components/AddMembersInGroup";
import AtsTitleText from "../../../../components/AtsTitleText";
import Picker from "emoji-picker-react";
import ConversationLoadingBox from "./components/ConversationLoadingBox";

const ChatRoom = () => {
  const {
    messenger,
    storeTyping,
    conversations,
    isConversationsLoading,
    handleSendMessage,
    setTyping,
    onStartTyping,
    onStopTyping,
  } = useMessages();
  const messagesEndRef = useRef(null);
  const [text, setText] = useState<string>("");
  const [showPicker, setShowPicker] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  let previousDate = null;
  const messagesWithTimestamps = conversations?.map((message) => {
    const updatedAt = message?.updatedAt;
    const messageDate = updatedAt
      ? new Date(updatedAt).toISOString().split("T")[0]
      : null;

    const showTime = previousDate !== messageDate;

    previousDate = messageDate;

    return {
      ...message,
      showTime,
    };
  });

  const onSubmit = async (e) => {
    try {
      await handleSendMessage(e, text, messenger);
      setText("");
    } catch (error) {}
  };
  const onEmojiClick = (event) => {
    setText((prevInput) => prevInput + event.emoji);
    setShowPicker(false);
  };
  const handleInputChange = (value: string) => {
    setText(value);
    if (!isTyping) {
      // setTyping(true);
      onStartTyping();
    }

    setTimeout(() => {
      // setTyping(false);
      onStopTyping();
    }, 2000);
  };

  const isTyping = messenger ? storeTyping[messenger.id] : false;
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "white",
        height: "100%",
      }}
    >
      {isNotNullOrUndefined(messenger) ? (
        <>
          <Box
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <ChatRoomTopSection />
          </Box>
          <AtsRow
            gap={"16px"}
            justifyContent={"end"}
            sx={{
              py: "10px",
              pr: "16px",
            }}
          >
            <>
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-gallery" height="16px" fs={16} />
              </AtsCustomButton>
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-emoji-normal" height="16px" fs={16} />
              </AtsCustomButton>
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-emoji-normal" height="16px" fs={16} />
              </AtsCustomButton>
            </>
          </AtsRow>
          <Box
            sx={{
              height: "calc(100% - 230px)",
              overflowY: "auto",
            }}
          >
            <Box px={"30px"} py={"5px"}>
              {messenger?.isGroupChat && (
                <Stack pb={"28px"}>
                  <AddMembersInGroup />
                </Stack>
              )}
              {isConversationsLoading
                ? Array.from(new Array(5)).map((_, index) => (
                    <ConversationLoadingBox key={index} />
                  ))
                : messagesWithTimestamps.map((conversation, index) => {
                    return (
                      <ConversationBox
                        key={conversation.id}
                        conversationInfo={conversation}
                      />
                    );
                  })}
              <div ref={messagesEndRef} />
            </Box>
          </Box>
          <Box
            m={"30px"}
            component={"form"}
            sx={{
              position: "relative",
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            <AtsRow
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "82px",
                p: "16px",
                bgcolor: BG_COLORS.GRAY,
              }}
              gap={"16px"}
            >
              <>
                <input
                  value={text}
                  type="text"
                  placeholder="Your message"
                  className="customInput"
                  style={{
                    flexGrow: 1,
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleInputChange(value);
                  }}
                />
                <AtsCustomButton px={0} py={0}>
                  <AtsIcon icon="icon-gallery" height="20px" fs={20} />
                </AtsCustomButton>
                <AtsCustomButton
                  px={0}
                  py={0}
                  onClick={() => setShowPicker(!showPicker)}
                >
                  <AtsIcon icon="icon-emoji-normal" height="20px" fs={20} />
                </AtsCustomButton>
              </>
            </AtsRow>
            <Stack sx={{ px: 2, position: "absolute", bottom: -15 }}>
              {isTyping && <AtsTitleText text={"typing..."} fs={12} fw={600} />}
            </Stack>
            <Stack sx={{ px: 2, position: "absolute", bottom: 55, right: 0 }}>
              {showPicker && (
                <Picker
                  style={{ width: "350px" }}
                  onEmojiClick={onEmojiClick}
                />
              )}
            </Stack>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ChatRoom;
