import React, { useState } from "react";
import AtsDataTable from "../../../components/AtsDataTable";
import { Box, Collapse, Stack } from "@mui/material";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  AccountCircle,
  CircleOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@mui/icons-material";
import AtsLabel from "../../../components/AtsLabel";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsCustomButton from "../../../components/AtsCustomButton";
import { FilterIcon, XlFileIcon } from "../../../assets/icons/Icons";
import { getButtonVariant, IUserManagemnetProps } from "../UserManagement";
import { ClientUser } from "../../../types/userManagementTypes";
import { concatenateStrings } from "../../../utils/stringUtils";
import AtsUserNameTextAvatar from "../../../components/AtsUserNameTextAvatar";
import { TickCircle } from "iconsax-react";
import AtsEmptyState from "../../../components/AtsEmptyState";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "uid",
    header: "User",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "phone",
    header: "Phone no.",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "client_role",
    header: "Role",
    width: "auto",
    minWidth: "150px",
  },
  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const ActiveUsers = ({
  invitedUsers,
  loading,
  userBlockUnBlock,
}: IUserManagemnetProps) => {
  const [selectedId, setSelectedId] = useState<string>("");

  const userBlock = async (email: string) => {
    await userBlockUnBlock(email, "block");
    setSelectedId("");
  };

  const customizeRow = (column: IColumnProps, user: ClientUser) => {
    switch (column.key) {
      case "uid":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={0.3}>
              <Stack
                sx={{
                  mr: "12px",
                  width: 58,
                  height: 58,
                }}
              >
                <AtsUserNameTextAvatar
                  name={`${user.first_name} ${user.last_name}`}
                />
              </Stack>
              <Stack gap={1}>
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor="#000"
                  text={concatenateStrings(user.first_name, user.last_name)}
                />

                <AtsTitleText
                  fs={12}
                  fw={500}
                  textColor={COLORS.LIGHT_GRAY}
                  text={user.title}
                />
                <AtsTitleText
                  fs={12}
                  fw={500}
                  textColor={COLORS.BLACK}
                  text={user.roles}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "email":
      case "phone":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={500}
                textColor={COLORS.BLACK}
                text={user[column.key]}
              />
            </Stack>
          </td>
        );
      case "client_role":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={12}
                fw={600}
                text={user.client_role}
                buttonVariant={getButtonVariant(user.client_role)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Box sx={{ position: "relative", cursor: "pointer" }}>
              <AtsCustomButton
                px={0.6}
                onClick={() => {
                  setSelectedId((prevSelectedId) =>
                    prevSelectedId === user.uid ? "" : user.uid
                  );
                }}
              >
                <MoreVert />
              </AtsCustomButton>
              <Box
                sx={{
                  position: "absolute",
                  bgcolor: "white",
                  right: "50px",
                  top: "20px",
                  zIndex: 9999,
                  width: "150px",
                }}
              >
                <Collapse
                  sx={{
                    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                    borderRadius: "6px",
                  }}
                  in={user.uid === selectedId}
                >
                  <Box
                    sx={{
                      border: BORDERS.GRAY,
                      borderRadius: "6px",
                      boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      bgcolor: "white",
                    }}
                    onClick={() => userBlock(user.email)}
                  >
                    <Stack gap={1}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <CircleOutlined
                          sx={{
                            fontSize: 18,
                            color: COLORS.GREEN,
                          }}
                        />

                        <AtsTitleText text={"Inactive"} fs={12} fw={500} />
                      </Stack>
                    </Stack>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = invitedUsers
      .filter((user) => user.invitation_status === "Accepted")
      .map((value, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                height: "68px",
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
            </tr>
          </React.Fragment>
        );
      });
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={12}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {/* {column.header.length > 0 && <FilterIcon />} */}
              </Stack>
            </AtsCustomButton>
            {/* {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )} */}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      {/* <Stack
        direction={"row"}
        alignItems={"center"}
        pb={2}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        <AtsCustomButton px={0.5} hoverColor="transaparant">
          <Stack direction={"row"} alignItems={"center"} gap={0.5}>
            <AtsIcon icon="icon-setting-4" height="16px" />
            <AtsTitleText text={"Filter"} />
          </Stack>
        </AtsCustomButton>
        <Stack direction={"row"} alignItems={"center"} gap={3}>
          <AtsRoundedButton
            text="Bulk Upload"
            buttonVariant="whiteContained"
            startIcon={<XlFileIcon />}
          />
          <AtsCustomButton px={0.5} hoverColor="transaparant">
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <AtsIcon icon="icon-export-3" height="16px" />
              <AtsTitleText text={"Export"} />
            </Stack>
          </AtsCustomButton>
        </Stack>
      </Stack> */}
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        {loading ? (
          <AtsDataTable
            isNotStickyHeader
            data={[]}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : invitedUsers.length ? (
          <AtsDataTable
            isNotStickyHeader
            data={invitedUsers}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : (
          <AtsEmptyState
            sx={{
              height: "100%",
            }}
            text="No active users"
            subText=""
          />
        )}
      </Box>
    </>
  );
};

export default ActiveUsers;
