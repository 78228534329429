import { Skeleton, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import AtsRoundedButton from "../../../../../../../../components/AtsRoundedButton";
import { useTimesheet } from "../../../../../../../../providers/TimesheetProvider";
import ImportantThings from "../../../../../../../PostOpportunity/components/ImportantThings";
import ContractorTimesheetList from "../../components/ContractorTimesheetList";
import DisputeModal from "./components/DisputeModal";
import TimesheetBottomActionSection from "./components/TimesheetBottomActionSection";
import TimeshhetActionFilter from "./components/TimeshhetActionFilter";

const TimesheetTab = () => {
  const { id } = useParams();
  const { contractorTimeEntries, timeEntryLoading, onApproveTimesheet } =
    useTimesheet();

  const isAllApproved = contractorTimeEntries.some(
    (log) => Number(log.approved_time) > 0
  );

  const isAllDisputed = contractorTimeEntries.some((log) => {
    return Number(log.disputed_time) > 0;
  });
  const isNotApprovedAndDipute = contractorTimeEntries.some((log) => {
    return (
      Number(log.approved_time.length ? log.approved_time : "0") === 0 &&
      Number(log.disputed_time.length ? log.disputed_time : "0") === 0
    );
  });
  const onApproveAll = () => {
    const nids = contractorTimeEntries.map((timesheet) =>
      Number(timesheet.nid)
    );
    onApproveTimesheet(nids, id);
  };
  return (
    <>
      <TimeshhetActionFilter />
      <ContractorTimesheetList />

      {!isAllApproved && isAllDisputed && contractorTimeEntries.length > 0 && (
        <TimesheetBottomActionSection isAllDisputed={isAllDisputed} />
      )}
      {isAllApproved && isAllDisputed && contractorTimeEntries.length > 0 && (
        <TimesheetBottomActionSection isAllDisputed={isAllDisputed} />
      )}
      {contractorTimeEntries.length > 0 && (
        <Stack
          mt={3}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {timeEntryLoading ? (
            <Skeleton variant="rounded" width={"100%"} height={32} />
          ) : (
            <>
              <ImportantThings
                header="Important things to remember"
                subText="Timesheet entries will be considered all approved after 48 hours"
              />
              {!isAllApproved && !isAllDisputed && isNotApprovedAndDipute && (
                <AtsRoundedButton
                  text="Approve all"
                  isInitial
                  fw={400}
                  buttonWidth={264}
                  buttonHeight={38}
                  onClick={onApproveAll}
                />
              )}
            </>
          )}
        </Stack>
      )}
      <DisputeModal />
    </>
  );
};

export default TimesheetTab;
