import { Box, Stack, Typography } from "@mui/material";
import { InfoCircle } from "iconsax-react";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { formattedAmount } from "../../../utils/common";
import { SummaryHiringFunnelProps } from "../../../types/insightsTypes";

interface IProps {
  hiringFunnelData: SummaryHiringFunnelProps;
}

const HiringFunnelList = ({ hiringFunnelData }: IProps) => {
  const data = [
    {
      number: hiringFunnelData.total_submissions,
      type: "Submissions",
    },
    {
      number: hiringFunnelData.total_interviews,
      type: "Interview",
    },
    {
      number: hiringFunnelData.total_sent_offers,
      type: "Send Offer",
    },
    {
      number: hiringFunnelData.total_accepted_offers,
      type: "Accept Offer",
    },
  ];

  const maxValue = Math.max(
    hiringFunnelData.total_submissions,
    hiringFunnelData.total_active_jobs,
    hiringFunnelData.total_hires,
    hiringFunnelData.total_interviews,
    hiringFunnelData.total_sent_offers,
    hiringFunnelData.total_accepted_offers
  );

  const updatedData = data.map((item) => ({
    ...item,
    percentage: (item.number / maxValue) * 100,
  }));
  return (
    <>
      {updatedData.map((item, index) => (
        <Box
          key={index}
          sx={{
            py: "10px",
            borderBottom: BORDERS.GRAY,
          }}
        >
          <AtsRow>
            <>
              <Stack
                gap={"10px"}
                sx={{
                  width: "30%",
                }}
              >
                <AtsTitleText
                  text={formattedAmount(item.number)}
                  textColor={COLORS.PRIMARY}
                  fs={16}
                  fw={700}
                />
                <AtsRow gap={"10px"}>
                  <>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: COLORS.BLACK,
                      }}
                    >
                      Passed through{" "}
                      <span
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        {item.type}
                      </span>
                    </Typography>
                    <InfoCircle size={16} />
                  </>
                </AtsRow>
              </Stack>
              <Stack
                sx={{
                  width: "70%",
                }}
              >
                <Box
                  sx={{
                    width: item.percentage === 0 ? `2%` : `${item.percentage}%`,
                    borderRadius: "6px",
                    height: 30,
                    bgcolor: item.percentage === 0 ? "#BFDBFE" : "#2563EB",
                  }}
                ></Box>
              </Stack>
            </>
          </AtsRow>
        </Box>
      ))}
    </>
  );
};

export default HiringFunnelList;
