import { Box, Collapse, MenuItem, Stack } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import { useState } from "react";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  SortContractEnums,
  useContracts,
} from "../../../providers/ContractsProvider";
import { BG_COLORS, COLORS } from "../../../theme";
import { useClickOutSide } from "../../../utils/common";

const ContractsSortingSelector = () => {
  const { selectedSortBy, setSelectedSortBy } = useContracts();
  const [showProject, setShowProject] = useState<boolean>(false);

  const domNodeProject = useClickOutSide(() => {
    setShowProject(false);
  });

  const handleSelect = (select: SortContractEnums) => {
    setSelectedSortBy(select);
  };

  return (
    <>
      <Box
        ref={domNodeProject}
        sx={{
          position: "relative",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          sx={{
            cursor: "pointer",
            px: "14px",
            py: "6px",
          }}
          onClick={() => setShowProject(!showProject)}
        >
          <Stack direction={"row"} gap={"6px"} alignItems={"center"}>
            <AtsTitleText
              text={`Sort by : ${selectedSortBy}`}
              textColor={COLORS.DARK_GRAY}
              fs={14}
              fw={500}
            />
          </Stack>
          <Stack>
            <ArrowDown2 size={16} />
          </Stack>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            left: "0px",
            // top: "50px",
            zIndex: 9999,
            // width: "-webkit-fill-available",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "6px",
            }}
            in={showProject}
          >
            <Stack
              id="projectDiv"
              // onScroll={loadMoreItems}
              style={{
                maxHeight: 200,
                padding: "10px",
                overflow: "auto",
              }}
            >
              <Stack gap={"5px"}>
                {Object.values(SortContractEnums).map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option}
                    onClick={() => {
                      handleSelect(option);
                    }}
                    sx={{
                      borderRadius: "6px",
                      bgcolor:
                        selectedSortBy === option
                          ? BG_COLORS.PRIMARY
                          : BG_COLORS.GRAY,
                    }}
                  >
                    <AtsRow gap={"10px"}>
                      <>
                        <AtsTitleText
                          text={option}
                          fs={14}
                          fw={500}
                          textColor={
                            selectedSortBy === option
                              ? COLORS.PRIMARY
                              : COLORS.BLACK
                          }
                        />
                      </>
                    </AtsRow>
                  </MenuItem>
                ))}
              </Stack>
            </Stack>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default ContractsSortingSelector;
