import { Container } from "@mui/material";
import React from "react";

interface PageContainerProps {
  children: JSX.Element;
  isEqualPading?: boolean;
}

const AtsPageContainer = ({
  children,
  isEqualPading = false,
}: PageContainerProps) => {
  return (
    <div
      className="main-container"
      style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          mb: 4,
          ...(!isEqualPading && { pr: "14px !important" }),
        }}
      >
        {children}
      </Container>
    </div>
  );
};

export default AtsPageContainer;
