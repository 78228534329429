import { Box, Container, Grid, Stack } from "@mui/material";
import { BORDERS, COLORS } from "../../../theme";
import AddSpecificHours from "./SetAvailability/AddSpecificHours";
import ConnectGoogleCalendar from "./SetAvailability/ConnectGoogleCalendar";
import SetBusinessHours from "./SetAvailability/SetBusinessHours";
import WeeklyAvailability from "./SetAvailability/WeeklyAvailability";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsRow from "../../../components/AtsRow";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsIcon from "../../../components/AtsIcon";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";
import { useMemo } from "react";

const SetAvailability = () => {
  const navigate = useNavigate();

  const WeeklyAvailabilityMemo = useMemo(() => <WeeklyAvailability />, []);
  const ConnectGoogleCalendarMemo = useMemo(
    () => <ConnectGoogleCalendar />,
    []
  );
  return (
    <>
      <div
        className="main-container"
        style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Container
          maxWidth="xl"
          sx={{
            mt: 3,
            mb: 4,
            pr: "14px !important",
          }}
        >
          <Stack sx={{ pr: "10px" }}>
            <AtsRowBetween>
              <>
                <AtsTitleText
                  text="Availability"
                  fs={24}
                  fw={600}
                  textColor={COLORS.BLACK}
                />

                <AtsCustomButton
                  px={0}
                  py={0}
                  onClick={() => navigate(ATS_ROUTES.CALENDAR)}
                >
                  <AtsRow gap={"8px"}>
                    <>
                      <AtsIcon
                        icon="icon-calendar"
                        height="16px"
                        color="#000"
                      />
                      <AtsTitleText
                        text="Events"
                        fs={14}
                        fw={400}
                        textColor={"#000"}
                      />
                    </>
                  </AtsRow>
                </AtsCustomButton>
              </>
            </AtsRowBetween>
          </Stack>
          <Box
            sx={{
              height: "calc(100% - 95px)",
              bgcolor: "white",
              border: BORDERS.GRAY,
              borderRadius: "10px",
              mt: "22px",
              p: "30px",
            }}
          >
            <Grid container columnSpacing={"20px"} height={"100%"}>
              <Grid
                item
                xs={12}
                sm={6}
                md={8}
                lg={8}
                xl={9}
                xxl={10}
                height={"100%"}
              >
                {WeeklyAvailabilityMemo}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
                <Box
                  sx={{
                    pt: "30px",
                    px: "30px",
                    pb: "53px",
                    border: BORDERS.GRAY,
                    borderRadius: "6px",
                  }}
                >
                  <Stack
                    sx={
                      {
                        // pb: "29px",
                        // borderBottom: BORDERS.GRAY,
                      }
                    }
                  >
                    {ConnectGoogleCalendarMemo}
                  </Stack>
                  {/* <Stack
                    sx={{
                      py: "30px",
                      borderBottom: BORDERS.GRAY,
                    }}
                  >
                    <SetBusinessHours />
                  </Stack>
                  <Stack
                    sx={{
                      pt: "30px",
                    }}
                  >
                    <AddSpecificHours />
                  </Stack> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default SetAvailability;
