import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import {
  getContractorTimesheetState,
  setSelectedDisputeFlowTab,
  setShowDisputeModal,
} from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../../theme";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";
import { useTimesheet } from "../../../../../../../../../providers/TimesheetProvider";
import PdfIcon from "../../../../../../../../../assets/images/pdfIcon.png";
import { formattedAmount } from "../../../../../../../../../utils/common";

const DisputedSuccessfully = () => {
  const dispatch = useAppDispatch();
  const { font14 } = FontUtil();
  const { disputedTimesheet, selectedTimelogOfContractorDetails } =
    useTimesheet();

  const onRemainingApprovHours = () => {
    dispatch(setShowDisputeModal(false));
    dispatch(setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries));
  };
  return (
    <>
      <Box
        sx={{
          px: "30px",
          pt: "20px",

          height: "calc(100% - 180px)",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Stack alignItems={"center"} mb={"20px"}>
            <img
              width={45}
              src={require("../../../../../../../../../assets/images/check.gif")}
              alt="check"
            />
          </Stack>
          <Stack textAlign={"center"} mb={"20px"}>
            <Typography
              sx={{
                ...font14,
                fontWeight: 400,
                color: "#000",
              }}
            >
              You have disputed{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedTimelogOfContractorDetails.length} entries{" "}
              </span>
            </Typography>
          </Stack>
          <AtsRowBetween
            sx={{
              minHeight: 43,
              px: "14px",
              bgcolor: "#fff3f3",
              borderRadius: "6px",
              mb: "40px",
            }}
          >
            <>
              <AtsTitleText
                text={"Total time disputed"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
              <AtsTitleText
                text={
                  disputedTimesheet
                    ? `${formattedAmount(
                        disputedTimesheet.total_disputed_time
                      )} hrs`
                    : ""
                }
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
            </>
          </AtsRowBetween>
          <Stack gap={"9px"} mb={"40px"}>
            <AtsTitleText text={"Your Evidence"} fs={14} fw={600} />
            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsRow rowGap={"8px"} columnGap={"10px"} flexWrap={"wrap"}>
              <>
                {disputedTimesheet &&
                  disputedTimesheet.evidences.map((evidance) => (
                    <Stack gap={"3px"} key={evidance.fid}>
                      <Box
                        sx={{
                          width: 80,
                          height: 80,
                        }}
                      >
                        {evidance.path.split(".")[1] === "pdf" ? (
                          <AtsRow
                            justifyContent={"center"}
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                              border: BORDERS.GRAY,
                            }}
                          >
                            <img
                              src={PdfIcon}
                              alt="Nvidia Logo"
                              style={{ width: 21 }}
                            />
                          </AtsRow>
                        ) : (
                          <img
                            src={evidance.path}
                            alt=""
                            width={"100%"}
                            height={"100%"}
                            style={{
                              borderRadius: "7px",
                            }}
                          />
                        )}
                      </Box>
                      <AtsTitleText
                        fs={12}
                        fw={500}
                        text={`${evidance.path.substring(0, 5)}.${
                          evidance.path.split(".")[1]
                        }`}
                      />
                    </Stack>
                  ))}
              </>
            </AtsRow>
          </Stack>
          <Stack gap={"9px"}>
            <AtsTitleText text={"Your note to Esteemed"} fs={14} fw={600} />
            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsRowBetween
              sx={{
                minHeight: 43,
                px: "14px",
                bgcolor: BG_COLORS.GRAY,
                borderRadius: "6px",
              }}
            >
              <>
                <AtsTitleText
                  text={disputedTimesheet && disputedTimesheet.note}
                  fs={14}
                  fw={400}
                  textColor={"#1c1c1c"}
                />
              </>
            </AtsRowBetween>
          </Stack>
        </Stack>
      </Box>
      <AtsRow pt={"20px"} pb={"40px"} justifyContent={"center"}>
        <AtsRoundedButton
          text="Approve remaining hours"
          fw={400}
          isInitial
          buttonWidth={226}
          buttonHeight={37}
          onClick={onRemainingApprovHours}
        />
      </AtsRow>
    </>
  );
};

export default DisputedSuccessfully;
