import { createContext, useContext, useState } from "react";
import { MyProfileTabsEnums } from "../enums/myProfileEnums";
import { CustomProfileRequest } from "../types/userManagementTypes";
import { UsedPlatformProps } from "./CreateAccountProvider";
import { UserManagementServices } from "../Api/userManagementServices";
import toast from "react-hot-toast";

export enum UsedPlatformEnums {
  school = "school",
  work = "work",
  live = "live",
  languagesSpeak = "languagesSpeak",
  born = "born",
  favoriteSong = "favoriteSong",
  obsessedWith = "obsessedWith",
  sfunFactchool = "funFact",
  unlessSkill = "unlessSkill",
  funFact = "funFact",
  biographyTitle = "biographyTitle",
  spendTime = "spendTime",
  pets = "pets",
}

export const usedPlatforms: UsedPlatformProps[] = [
  {
    icon: require("../pages/MyProfile/assets/school.svg"),
    text: "Where I went to school?",

    subText:
      "Whether it's home school, secondary school or trade school, name the school that made you who you are.",
    state: UsedPlatformEnums.school,
  },
  {
    icon: require("../pages/MyProfile/assets/work.svg"),
    text: "My work",
    subText: "",
    state: UsedPlatformEnums.work,
  },
  {
    icon: require("../pages/MyProfile/assets/live.svg"),
    text: "Where I live?",
    subText:
      "Tell us a little bit about yourself so your future Hosts or guests can get to know you.",
    state: UsedPlatformEnums.live,
  },
  {
    icon: require("../pages/MyProfile/assets/language.svg"),
    text: "Languages I speak",
    subText: "",
    state: UsedPlatformEnums.languagesSpeak,
  },
  {
    icon: require("../pages/MyProfile/assets/decade.svg"),
    text: "Decade I was born",
    subText:
      "Don't worry, other people won't be able to see your exact birthday.",
    state: UsedPlatformEnums.born,
  },
  {
    icon: require("../pages/MyProfile/assets/music.svg"),
    text: "My favourite song in secondary school",
    subText: "",
    state: UsedPlatformEnums.favoriteSong,
  },
  {
    icon: require("../pages/MyProfile/assets/obsesed.svg"),
    text: `I'm obsessed with`,
    subText: "",
    state: UsedPlatformEnums.obsessedWith,
  },
  {
    icon: require("../pages/MyProfile/assets/fact.svg"),
    text: "My fun fact",
    subText: "",
    state: UsedPlatformEnums.funFact,
  },
  {
    icon: require("../pages/MyProfile/assets/fact.svg"),
    text: "My most useless skill",
    subText: "",
    state: UsedPlatformEnums.unlessSkill,
  },
  {
    icon: require("../pages/MyProfile/assets/biography.svg"),
    text: "My biography title would be",
    subText: "",
    state: UsedPlatformEnums.biographyTitle,
  },
  {
    icon: require("../pages/MyProfile/assets/time.svg"),
    text: "I spend too much time",
    subText: "",
    state: UsedPlatformEnums.spendTime,
  },
  {
    icon: require("../pages/MyProfile/assets/pets.svg"),
    text: "Pets",
    subText: "",
    state: UsedPlatformEnums.pets,
  },
];

export enum ProfileToggleState {
  Initial = "Initial",
  Edit = "Edit",
  Create = "Create",
}

type MyProfileModuleContextType = {
  profileToggleState: ProfileToggleState;
  loading: boolean;
  isEdit: boolean;
  selectedMyProfileTab: MyProfileTabsEnums;
  updateProfileState: CustomProfileRequest;
  setSelectedMyProfileTab: React.Dispatch<
    React.SetStateAction<MyProfileTabsEnums>
  >;
  setProfileToggleState: React.Dispatch<
    React.SetStateAction<ProfileToggleState>
  >;
  handleProfileChange: (keyVal: keyof CustomProfileRequest, value: any) => void;
  getUserProfile: () => void;
  onUpdate: () => void;
};

const initialProfileState = {
  about: "",
  dob: "",
  show_decade: "0",
  obessed_with: "",
  spend_to_much_time: "",
  languages: [],
  biography_title: "",
  favorite_song: "",
  fun_fact: "",
  useless_skill: "",
  my_work: "",
  pets: "",
  you_are_into: [],
  where_i_live: "",
  where_i_went_to_school: "",
} as CustomProfileRequest;

const MyProfileModule = createContext<MyProfileModuleContextType | null>(null);

export const useMyProfile = () =>
  useContext(MyProfileModule) as MyProfileModuleContextType;

export const MyProfileProvider = ({ children }: any) => {
  const [selectedMyProfileTab, setSelectedMyProfileTab] =
    useState<MyProfileTabsEnums>(MyProfileTabsEnums.Account);

  const [updateProfileState, setUpdateProfileState] =
    useState<CustomProfileRequest>(initialProfileState);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [profileToggleState, setProfileToggleState] =
    useState<ProfileToggleState>(ProfileToggleState.Initial);

  const handleProfileChange = (
    keyVal: keyof CustomProfileRequest,
    value: any
  ) => {
    setUpdateProfileState({ ...updateProfileState, [keyVal]: value });
  };

  const onUpdate = async () => {
    setLoading(true);
    try {
      const body = {
        ...updateProfileState,
        you_are_into: updateProfileState.you_are_into.map((val) => val.id),
      } as CustomProfileRequest;
      await UserManagementServices.updateCustomProfile(body);
      toast.success("Profile Update successfully!");
      getUserProfile();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const response = await UserManagementServices.getCustomProfile();
      if (Array.isArray(response)) {
        setUpdateProfileState(initialProfileState);
        setProfileToggleState(ProfileToggleState.Initial);
        setIsEdit(false);
      } else if (typeof response === "object" && response !== null) {
        setUpdateProfileState(response);
        setProfileToggleState(ProfileToggleState.Edit);
        setIsEdit(true);
      } else {
        setUpdateProfileState(initialProfileState);
        setProfileToggleState(ProfileToggleState.Initial);
        setIsEdit(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MyProfileModule.Provider
      value={{
        loading,
        isEdit,
        profileToggleState,
        getUserProfile,
        selectedMyProfileTab,
        updateProfileState,
        setSelectedMyProfileTab,
        setProfileToggleState,
        handleProfileChange,
        onUpdate,
      }}
    >
      {children}
    </MyProfileModule.Provider>
  );
};
