import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import AtsTextInput from "../../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import {
  getProjectDetailsState,
  setCompanyInfoState,
} from "../../../../../../redux/slices/projectDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BG_COLORS, COLORS } from "../../../../../../theme";
import { CompanyInfoResponse } from "../../../../../../types/companyTypes";
import { CompanyDetailsProps } from "../modules/CompanyDetails";

const DirectBillingContact = ({ isEdit, loading }: CompanyDetailsProps) => {
  const dispatch = useAppDispatch();
  const { companyInfoState, companyStateError } = useAppSelector(
    getProjectDetailsState
  );
  const handleChange = (key: string, value: string) => {
    const primaryInfo = { ...companyInfoState.billing_contact };
    const updatePrimary = { ...primaryInfo, [key]: value };
    const preCompanyInfo = {
      ...companyInfoState,
      billing_contact: updatePrimary,
    } as CompanyInfoResponse;
    dispatch(setCompanyInfoState(preCompanyInfo));
  };
  const renderTextInputComponent = (
    isEdit: boolean,
    title: string,
    value: string,
    key: string,
    handleChange: (key: string, value: string) => void,
    loading?: boolean
  ) => {
    return (
      <Stack gap={"5px"}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: COLORS.BLACK,
          }}
        >
          {title}
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        </Typography>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {!isEdit ? (
            loading ? (
              <Skeleton height={40} />
            ) : (
              <Box
                sx={{
                  bgcolor: BG_COLORS.GRAY,
                  borderRadius: "8px",
                  height: 41,
                  display: "flex",
                  alignItems: "center",
                  pl: "21px",
                }}
              >
                <AtsTitleText
                  text={value}
                  fs={14}
                  fw={400}
                  textColor="#1c1c1c"
                />
              </Box>
            )
          ) : (
            <AtsTextInput
              height="41px"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          )}
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={
                companyInfoState?.billing_contact
                  ? companyStateError[`billing_contact.${key}`]
                  : companyStateError[`billing_contact`]
              }
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
    );
  };
  return (
    <>
      <Grid container columnSpacing={"44px"} rowSpacing={"30px"}>
        <Grid item xs={12} sm={6}>
          {renderTextInputComponent(
            isEdit,
            "Name",
            companyInfoState?.billing_contact
              ? companyInfoState?.billing_contact?.first_name
              : "",
            "first_name",
            handleChange
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextInputComponent(
            isEdit,
            "Email",
            companyInfoState?.billing_contact
              ? companyInfoState?.billing_contact?.email
              : "",
            "email",
            handleChange
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextInputComponent(
            isEdit,
            "Phone number",
            companyInfoState?.billing_contact
              ? companyInfoState?.billing_contact?.phone
              : "",
            "phone",
            handleChange
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DirectBillingContact;
