import React from "react";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRow from "../../../../../../../../components/AtsRow";
import { Box, Skeleton, Stack } from "@mui/material";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import { DisputeResolutionProps } from "../DisputeResolutionDrawer";
import PdfIcon from "../../../../../../../../assets/images/pdfIcon.png";

const Evidence = ({
  status,
  loading,
  disputedTimesheet,
}: DisputeResolutionProps) => {
  return (
    <>
      <AtsTitleText text={"Evidence"} fs={18} fw={600} textColor={"#000"} />
      <Stack direction={"row"} alignItems={"flex-start"} gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Your evidence"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <AtsRow columnGap={"16px"} rowGap={"10px"} flexWrap={"wrap"}>
            <>
              {loading
                ? Array.from(new Array(4)).map((_, index) => (
                    <Stack gap={"9px"}>
                      <Skeleton variant="rounded" width={50} height={50} />
                      <Skeleton variant="text" width={50} />
                    </Stack>
                  ))
                : disputedTimesheet &&
                  disputedTimesheet.evidences.map((evidance) => (
                    <Stack gap={"9px"} key={evidance.fid}>
                      <Box
                        sx={{
                          border: BORDERS.GRAY,
                          borderRadius: "10px",
                          width: 50,
                          height: 50,
                        }}
                      >
                        {evidance.path.includes("pdf") ? (
                          <AtsRow
                            justifyContent={"center"}
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                            }}
                          >
                            <img
                              src={PdfIcon}
                              alt="Nvidia Logo"
                              style={{ width: 21 }}
                            />
                          </AtsRow>
                        ) : (
                          <img
                            src={evidance.path}
                            alt=""
                            width={"100%"}
                            height={"100%"}
                            style={{
                              borderRadius: "7px",
                            }}
                          />
                        )}
                      </Box>
                      <AtsTitleText
                        text={`${evidance.path.substring(0, 5)}.${
                          evidance.path.split(".")[1]
                        }`}
                        fs={11}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </Stack>
                  ))}
            </>
          </AtsRow>
        </>
      </Stack>
      <Stack direction={"row"} alignItems={"flex-start"} gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Contractor’s evidence"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <AtsRow columnGap={"16px"} rowGap={"10px"} flexWrap={"wrap"}>
            <>
              {loading
                ? Array.from(new Array(4)).map((_, index) => (
                    <Stack gap={"9px"}>
                      <Skeleton variant="rounded" width={50} height={50} />
                      <Skeleton variant="text" width={50} />
                    </Stack>
                  ))
                : disputedTimesheet &&
                  disputedTimesheet.contractor_evidences.map((evidance) => (
                    <Stack gap={"9px"} key={evidance.fid}>
                      <Box
                        sx={{
                          border: BORDERS.GRAY,
                          borderRadius: "10px",
                          width: 50,
                          height: 50,
                        }}
                      >
                        {evidance.path.includes("pdf") ? (
                          <AtsRow
                            justifyContent={"center"}
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                            }}
                          >
                            <img
                              src={PdfIcon}
                              alt="Nvidia Logo"
                              style={{ width: 21 }}
                            />
                          </AtsRow>
                        ) : (
                          <img
                            src={evidance.path}
                            alt=""
                            width={"100%"}
                            height={"100%"}
                            style={{
                              borderRadius: "7px",
                            }}
                          />
                        )}
                      </Box>
                      <AtsTitleText
                        text={`${evidance.path.substring(0, 5)}.${
                          evidance.path.split(".")[1]
                        }`}
                        fs={11}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </Stack>
                  ))}
            </>
          </AtsRow>
        </>
      </Stack>
    </>
  );
};

export default Evidence;
