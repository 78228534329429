import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { COLORS } from "../theme";
import AtsTitleText from "./AtsTitleText";

interface IProps {
  text: string;
  onClick?: () => void;
  isRightIcon?: boolean;
}

const AtsBackLink = ({ text, onClick, isRightIcon }: IProps) => {
  return (
    <Stack
      gap={0.2}
      direction={"row"}
      alignItems={"center"}
      sx={{ cursor: "pointer" }}
      onClick={onClick ? onClick : () => {}}
    >
      {!isRightIcon && (
        <ChevronLeft sx={{ fontSize: 16, color: COLORS.PRIMARY }} />
      )}

      <AtsTitleText fs={12} textColor={COLORS.PRIMARY} text={text} />
      {isRightIcon && (
        <ChevronRight sx={{ fontSize: 16, color: COLORS.PRIMARY }} />
      )}
    </Stack>
  );
};

export default AtsBackLink;
