import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import { ProjectService } from "../../../Api/projectServices";
import { Skeleton } from "@mui/material";

interface IProps {
  projectId: string;
}

const ProjectNameById = ({ projectId }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const getProjectData = async (nid: string) => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({ nid });
      if (response) {
        setProjectName(response.title);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    projectId && getProjectData(projectId);
  }, []);

  return (
    <>
      <Stack gap={"2px"}>
        <AtsTitleText
          text={"Project"}
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
        />
        {loading ? (
          <Skeleton variant="text" width={"100%"} />
        ) : (
          <AtsTitleText
            text={projectName}
            fs={14}
            fw={500}
            textColor={COLORS.DARK_GRAY}
          />
        )}
      </Stack>
    </>
  );
};

export default ProjectNameById;
