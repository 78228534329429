import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BORDERS, BOX_SHADOW, COLORS } from "../../../../theme";
import "../../css/project.css";

const projectDetails = [
  {
    title: "Active Jobs",
    value: "52",
  },
  {
    title: "New Submissions",
    value: "144",
  },
  {
    title: "Invoices",
    value: "12",
  },
  {
    title: "Contractors",
    value: "40",
  },
];

interface ProjectProps {
  loading?: boolean;
}

const LoadingProjectCard = ({ loading }: ProjectProps) => {
  return (
    <Box
      sx={{
        minHeight: 295,
        borderRadius: "10px",
        padding: "20px 9.4px 19px 20px",
        bgcolor: "white",
        boxShadow: BOX_SHADOW.GRAY,
        border: BORDERS.GRAY,
        cursor: "pointer",
      }}
    >
      <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Box
            sx={{
              width: 50,
              height: 50,
            }}
          >
            <Skeleton variant="circular" width={50} height={50} />
          </Box>
        </Stack>
        <Stack
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </Stack>

          <Stack gap={"9px"} mt={"35px"}>
            <Stack>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="rectangular" width={"94%"} height={40} />
            </Stack>

            <Stack
              mt={"20px"}
              mr={"10.6px"}
              direction={"row"}
              sx={{
                padding: "15px 19px 9px",
                bgcolor: "#f0f5ff",
                borderRadius: "8px",
              }}
            >
              {projectDetails.map((item, index) => (
                <Stack key={index} gap={"6px"} sx={{ flex: 1 }}>
                  <AtsTitleText
                    text={item.title}
                    fw={400}
                    fs={12}
                    textColor={COLORS.LIGHT_GRAY}
                  />

                  <Skeleton width={50} height={20} />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LoadingProjectCard;

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: 40,
        height: 40,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#e0e0e0 !important",
          "& .MuiCircularProgress-circle": {
            stroke: "#e0e0e0", // Unfilled (background) color
          },
          "& .MuiCircularProgress-circleDeterminate": {
            stroke: "#3c854d", // Unfilled (background) color
          },
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const CustomCircularProgress = ({
  progress = 0,
  size = 42,
  strokeWidth = 4,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg width={size} height={size} className="circular-progress">
      <circle
        className="circular-progress-background"
        stroke="lightgray"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="circular-progress-bar"
        stroke="#3c854d"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
      <g
        dominantBaseline="middle"
        textAnchor="middle"
        transform={`rotate(90 ${size / 2} ${size / 2})`}
      >
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={12}
          fill="#231f20"
        >
          {Math.round(progress)}%
        </text>
      </g>
    </svg>
  );
};
