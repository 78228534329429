import { Box, Skeleton, Stack } from "@mui/material";
import AtsCard from "../../../components/AtsCard";
import AtsIcon from "../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../theme";

const LoadingPostedJobCard = () => {
  return (
    <>
      <AtsCard>
        <Stack gap={1} px={1}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"20px"}
            sx={{ minHeight: 17 }}
            py={1}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Skeleton width={55} />
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: "44px",
            }}
          >
            <Skeleton width={200} height={24} variant="rounded" />
          </Stack>
          <Stack
            pt={2}
            pb={3}
            gap={1}
            sx={{ borderBottom: BORDERS.LIGHT_GRAY }}
          >
            <Stack
              sx={{ height: "22px" }}
              direction={"row"}
              alignItems={"center"}
              gap={1}
            >
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <AtsIcon
                  icon="icon-location"
                  height="16px"
                  color={COLORS.LIGHT_GRAY}
                />
              </Box>
              <Skeleton width={55} />
            </Stack>
            <Stack
              sx={{ height: "22px" }}
              direction={"row"}
              gap={1}
              alignItems={"center"}
            >
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <AtsIcon
                  icon="icon-clock"
                  height="16px"
                  color={COLORS.LIGHT_GRAY}
                />
              </Box>
              <Skeleton width={55} />
            </Stack>
            <Stack
              sx={{ height: "22px" }}
              direction={"row"}
              gap={1}
              alignItems={"center"}
            >
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <AtsIcon
                  icon="icon-document-text"
                  fs={16}
                  height="16px"
                  color={COLORS.LIGHT_GRAY}
                />
              </Box>
              <Skeleton width={55} />
            </Stack>
            <Stack
              sx={{ height: "22px" }}
              direction={"row"}
              gap={1}
              alignItems={"center"}
            >
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <AtsIcon
                  icon="icon-monitor"
                  fs={16}
                  height="16px"
                  color={COLORS.LIGHT_GRAY}
                />
              </Box>
              <Skeleton width={55} />
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            py={"5px"}
          >
            <Skeleton width={55} />
            <Skeleton width={55} />
          </Stack>
        </Stack>
      </AtsCard>
    </>
  );
};

export default LoadingPostedJobCard;
