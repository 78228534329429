export enum ContractorTabsEnum {
  Profile = "Profile",
  Timesheet = "Timesheet",
  Responsibilities = "Responsibilities",
  Contracts = "Contracts",
  AssignedTasks = "Assigned Tasks",
  Onboarding = "Onboarding",
  PerformanceReview = "Performance Review",
}
export enum ContractorUpdatedTabsEnum {
  Profile = "Profile",
  Timesheet = "Timesheet",
  Responsibilities = "Responsibilities",
  Contracts = "Contracts",
  AssignedTasks = "Assigned Tasks",
  Onboarding = "Update Onboarding",
  PerformanceReview = "Performance Review",
}

export enum DisputeTabsEnum {
  DisputeEntries = "Dispute entries",
  EvidenceDetails = "Evidence details",
}
export enum DisputeResolutionTabsEnum {
  Disputed = "Disputed",
  UnderReview = "Under review",
  Resolved = "Resolved",
}

export interface PerformanceReviewRequest {
  title: string;
  description: string;
  communication: number;
  service_as_described: number;
  quality: number;
  recommendation: number;
  value: number;
  rating: number;
  project_id: string;
  project_name: string;
  candidate_id: string;
  nid: string;
}
