import { Skeleton, Stack, Tab, Tabs } from "@mui/material";
import { useMemo } from "react";
import { OnboardingProvider } from "../../../../../../../providers/OnboardingProvider";
import {
  getContractorDetailsState,
  setSelectedContractorDrawerTab,
} from "../../../../../../../redux/slices/contractorSlice";
import { setInitialContractorTimeSheetState } from "../../../../../../../redux/slices/contractorTimesheetSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { COLORS } from "../../../../../../../theme";
import {
  ContractorTabsEnum,
  ContractorUpdatedTabsEnum,
} from "../../../../../../../types/contractorTypes";
import AssignedTasksTab from "../Tabs/AssignedTasksTab";
import ContractsTab from "../Tabs/ContractsTab";
import OnboardingTab from "../Tabs/OnboardingTab/OnboardingTab";
import PerformanceReviewTab from "../Tabs/PerformanceReviewTab";
import ProfileTab from "../Tabs/ProfileTab/ProfileTab";
import ResponsibilitiesTab from "../Tabs/ResponsibilitiesTab";
import TimesheetTab from "../Tabs/Timesheet/TimesheetTab";
import { getSubmissionState } from "../../../../../../../redux/slices/submissionSlice";

const ContractorDetailBottomSection = () => {
  const dispatch = useAppDispatch();
  const { selectedContractorDrawerTab, isContractorOnboarded } = useAppSelector(
    getContractorDetailsState
  );
  const { isCandidateDetailsLoading } = useAppSelector(getSubmissionState);
  const profileTab = useMemo(() => <ProfileTab />, []);
  const timesheetTab = useMemo(() => <TimesheetTab />, []);
  const responsibilitiesTab = useMemo(() => <ResponsibilitiesTab />, []);
  const contractsTab = useMemo(() => <ContractsTab />, []);
  const assignedTasksTab = useMemo(() => <AssignedTasksTab />, []);
  const performanceReviewTab = useMemo(() => <PerformanceReviewTab />, []);
  const onboardingTab = useMemo(
    () => (
      <OnboardingProvider>
        <OnboardingTab />
      </OnboardingProvider>
    ),
    []
  );

  const tabsEnum = isContractorOnboarded
    ? ContractorUpdatedTabsEnum
    : ContractorTabsEnum;
  return (
    <>
      <Stack
        sx={{
          height: "calc(100% - 260px)",
          p: "30px",
        }}
      >
        <Stack direction={"row"} height={"100%"} gap={"50px"}>
          <Stack
            sx={{
              minWidth: "170px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              py: "14px",
              height: "fit-content",
            }}
          >
            {isCandidateDetailsLoading ? (
              <Stack gap={"10px"}>
                {Array.from(new Array(7)).map((_, index) => (
                  <Skeleton height={26} width={100} variant="rounded" />
                ))}
              </Stack>
            ) : (
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedContractorDrawerTab}
                onChange={(e, newValue) => {
                  dispatch(setSelectedContractorDrawerTab(newValue));
                  dispatch(setInitialContractorTimeSheetState());
                }}
                aria-label="Vertical tabs example"
                sx={{
                  ".MuiTab-root": {
                    textAlign: "left",
                    alignItems: "flex-start",
                    pl: 3,
                    textTransform: "capitalize",
                    minHeight: 40,
                  },
                  ".Mui-selected": {
                    color: "#3366cc !important",
                    fontWeight: 600,
                  },
                  ".MuiTabs-indicator": {
                    left: 0,
                    height: "26px !important",
                    width: "4px",
                    backgroundColor: COLORS.PRIMARY,
                    borderRadius: "10px",
                    marginTop: "8px",
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    left: 0,
                    width: "5px",
                  },
                }}
              >
                {Object.keys(tabsEnum).map((tab) => (
                  <Tab key={tab} label={tabsEnum[tab]} />
                ))}
              </Tabs>
            )}
          </Stack>
          <Stack
            className="scrollHide"
            style={{
              overflow: "auto",
              height: "100%",
              flex: 1,
            }}
          >
            {selectedContractorDrawerTab === 1
              ? timesheetTab
              : selectedContractorDrawerTab === 2
              ? responsibilitiesTab
              : selectedContractorDrawerTab === 3
              ? contractsTab
              : selectedContractorDrawerTab === 4
              ? assignedTasksTab
              : selectedContractorDrawerTab === 5
              ? onboardingTab
              : selectedContractorDrawerTab === 6
              ? performanceReviewTab
              : profileTab}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ContractorDetailBottomSection;
