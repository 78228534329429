import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { BillingTabsEnums } from "../../../providers/BillingProvider";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { COLORS } from "../../../theme";
import JobsSwitch from "../../Dashboard/PostedJobs/JobsSwitch";
import { InvoiceStatusEnum } from "../../../enums/invoiceEnums";

const BillingTabs = () => {
  const { selectedBillingTab, setSelectedBillingTab, getInvoiceData } =
    useInvoices();
  return (
    <>
      <AtsTitleText text="Billing" fs={24} fw={600} textColor={COLORS.BLACK} />
      <Stack
        sx={{ pt: "22px", pb: "19px" }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <JobsSwitch
          width="78px"
          jobs={Object.values(BillingTabsEnums).map((value) => ({
            title: value,
            type: value,
          }))}
          active={selectedBillingTab}
          onChange={(value) => {
            console.log("value", value);
            setSelectedBillingTab(value as BillingTabsEnums);
            if (value === BillingTabsEnums.BillingDetails) {
              getInvoiceData(InvoiceStatusEnum.not_paid);
            } else {
              getInvoiceData(InvoiceStatusEnum.paid);
            }
          }}
        />
      </Stack>
    </>
  );
};

export default BillingTabs;
