import { Box, Skeleton, Stack } from "@mui/material";
import { TickCircle } from "iconsax-react";
import AtsTitleText from "../../../../../../../../../../components/AtsTitleText";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import { COLORS } from "../../../../../../../../../../theme";
import {
  getDateFormatMMMDYYYYY,
  getTime,
} from "../../../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../../../utils/stringUtils";

interface IProps {
  activeStep: number;
  stepData: string[];
}
const GREEN_COLOR = "#3c854d";
const GRAY_COLOR = "#c4c4c4";
const OfferStatusStepper = ({ activeStep, stepData }: IProps) => {
  const { selectedOffer, offerDetails, offerStatusLoading } = useMakeAnOffer();

  const offerHistory = isNotNullOrUndefined(offerDetails)
    ? isNotNullOrUndefined(offerDetails.status_history)
      ? offerDetails.status_history
      : []
    : [];
  console.log("statusStep", offerHistory);
  return (
    <>
      <Stack gap={"56px"}>
        {offerStatusLoading
          ? Array.from(new Array(4)).map((_, index) => (
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                gap={"24px"}
                position={"relative"}
              >
                <>
                  {" "}
                  <Skeleton variant="circular" width={15} height={15} />
                  {index < 3 && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 20,
                        left: 8,
                        width: "1px",
                        height: 48,
                        backgroundColor:
                          index < activeStep ? GREEN_COLOR : GRAY_COLOR,
                        zIndex: 0,
                      }}
                    ></Box>
                  )}
                  <Stack gap={"10px"}>
                    <Skeleton variant="text" width={120} height={17} />

                    <Stack
                      sx={{
                        position: "absolute",
                        top: 28,
                      }}
                    >
                      <Skeleton variant="text" width={120} height={15} />
                    </Stack>
                  </Stack>
                </>
              </Stack>
            ))
          : offerHistory.map((step, index) => (
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                gap={"24px"}
                position={"relative"}
              >
                <>
                  {offerStatusLoading ? (
                    <Skeleton variant="circular" width={15} height={15} />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // border:
                        //   index === activeStep
                        //     ? `1px solid ${GREEN_COLOR}`
                        //     : index < activeStep
                        //     ? `1px solid ${GREEN_COLOR}`
                        //     : `1px solid ${GRAY_COLOR}`,
                        border: `1px solid ${GREEN_COLOR}`,
                        borderRadius: "50%",
                        height: 15,
                        width: 15,
                      }}
                    >
                      {index === activeStep ? (
                        <TickCircle
                          variant="Bold"
                          color={GREEN_COLOR}
                          size={15}
                        />
                      ) : index < activeStep ? (
                        <TickCircle
                          variant="Bold"
                          color={GREEN_COLOR}
                          size={15}
                        />
                      ) : (
                        <TickCircle
                          variant="Bold"
                          color={GREEN_COLOR}
                          size={15}
                        />
                      )}
                    </Box>
                  )}
                  {index < offerHistory.length - 1 && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 20,
                        left: 8,
                        width: "1px",
                        height: 48,
                        // backgroundColor:
                        //   index < activeStep ? GREEN_COLOR : GRAY_COLOR,
                        backgroundColor: GREEN_COLOR,
                        zIndex: 0,
                      }}
                    ></Box>
                  )}
                  <Stack gap={"10px"}>
                    {offerStatusLoading ? (
                      <Skeleton variant="text" width={120} height={17} />
                    ) : (
                      <AtsTitleText
                        text={step.status}
                        // textColor={
                        //   index === activeStep
                        //     ? GREEN_COLOR
                        //     : index < activeStep
                        //     ? GREEN_COLOR
                        //     : GRAY_COLOR
                        // }
                        textColor={GREEN_COLOR}
                        fs={14}
                        fw={700}
                      />
                    )}
                    <Stack
                      sx={{
                        position: "absolute",
                        top: 28,
                      }}
                    >
                      <AtsTitleText
                        text={`${getDateFormatMMMDYYYYY(step.date)} | ${getTime(
                          step.date
                        )}`}
                        fs={11}
                        fw={400}
                        textColor={COLORS.BLACK}
                      />
                    </Stack>
                  </Stack>
                </>
              </Stack>
            ))}
        {}
      </Stack>
    </>
  );
};

export default OfferStatusStepper;
