import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import { Dayjs } from "dayjs";
import { BORDERS, COLORS } from "../theme";
import { Calendar } from "iconsax-react";

interface IDatePickerProps extends DatePickerProps<Dayjs> {}

const AtsDatePicker = ({ ...rest }: IDatePickerProps) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...rest}
          slots={{
            openPickerIcon: Calendar,
          }}
          sx={{
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 30px white inset",
                WebkitTextFillColor: "#000",
              },
            },
            "& .MuiInputBase-input": {
              fontSize: "16px",
              color: COLORS.BLACK,
              fontWeight: 400,
              padding: "6px 14px",
              mb: "3px",
              "::placeholder": {
                fontSize: "12px",
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
              },
              ".Mui-disabled": {
                color: COLORS.BLACK,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: BORDERS.GRAY,
              "& .Mui-disabled": {
                border: "none !important",
              },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              lineHeight: 1,
              "&.Mui-focused fieldset": {
                border: BORDERS.GRAY,
                "& .Mui-disabled": {
                  border: "none !important",
                },
              },
              ":hover fieldset": {
                border: BORDERS.GRAY,
                "& .Mui-disabled": {
                  border: "none !important",
                },
              },
              "&.Mui-disabled": {
                bgcolor: "#f5f5f5",
                border: "none !important",
              },
              "& .MuiInputBase-inputMultiline": {
                "::placeholder": {
                  fontSize: "14px",
                  color: COLORS.LIGHT_GRAY,
                  fontWeight: 400,
                },
              },
            },
            ...rest.sx,
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default AtsDatePicker;
