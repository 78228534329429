import { Box, Stack } from "@mui/material";
import SignaturePad from "react-signature-canvas";
import AtsTitleText from "../../../components/AtsTitleText";
import { useContracts } from "../../../providers/ContractsProvider";
import { BORDERS, COLORS } from "../../../theme";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsCustomButton from "../../../components/AtsCustomButton";
import { useEffect } from "react";
import SignatureBottomBox from "./SignatureBottomBox";
import { SignatureStepEnum } from "../../../enums/contractsEnums";
import { isNotNullOrUndefined } from "../../../utils/stringUtils";

const SignatureDrawBox = () => {
  const {
    sigCanvas,
    clear,
    setIsSignDraw,
    setSelectedSignatureStep,
    signLoading,
    onSign,
  } = useContracts();

  useEffect(() => {
    const checkSignature = () => {
      if (sigCanvas?.current && sigCanvas?.current.isEmpty()) {
        setIsSignDraw(true);
      } else {
        setIsSignDraw(false);
      }
    };

    checkSignature();

    const interval = setInterval(checkSignature, 500);

    return () => clearInterval(interval);
  }, []);

  const onSignClick = () => {
    const dataURL = sigCanvas.current.toDataURL();
    onSign(dataURL);
  };

  return (
    <>
      <Box
        sx={{
          px: "40px",
          pt: "29px",
          pb: "46px",
        }}
      >
        <Stack gap={"11px"}>
          <AtsRowBetween>
            <>
              <AtsTitleText
                text={"Draw your signature here using your mouse or trackpad"}
                textColor="#000"
                fs={16}
                fw={600}
              />
              <AtsCustomButton px={0} py={0} onClick={clear}>
                <AtsTitleText
                  text={"Clear all"}
                  textColor={COLORS.PRIMARY}
                  fs={14}
                  fw={400}
                />
              </AtsCustomButton>
            </>
          </AtsRowBetween>
          <Box
            sx={{
              border: BORDERS.PRIMARY,
              borderRadius: "10px",
              height: 392,
            }}
          >
            <SignaturePad
              canvasProps={{
                style: {
                  width: "100%",
                  height: "100%",
                },
              }}
              ref={sigCanvas}
            />
          </Box>
        </Stack>
      </Box>
      <SignatureBottomBox
        backText="Back"
        nextText="Sign"
        isDisabled={
          !isNotNullOrUndefined(sigCanvas) ||
          (sigCanvas && sigCanvas?.current?.isEmpty()) ||
          signLoading
        }
        loading={signLoading}
        onBack={() =>
          setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
        }
        onNext={onSignClick}
      />
    </>
  );
};

export default SignatureDrawBox;
