/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../Api/authService";
import AtsRoundedButton from "../components/AtsRoundedButton";
import AtsTitleText from "../components/AtsTitleText";
import AtsTextInput from "../components/AtsTextInput";
import { setIsAuth, setUserInfo } from "../redux/slices/authSlice";
import { useAppDispatch } from "../redux/store";
import AtsIcon from "../components/AtsIcon";
import { COLORS } from "../theme";
import { UserManagementServices } from "../Api/userManagementServices";
import toast from "react-hot-toast";
import { useAutoLogout } from "../providers/AutoLogoutProvider";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../router/routes";
import { FormTypeEmun } from "./Login";
import Submit from "./CreateAccount/components/Submit";

export interface IAuthProps {
  mail: string;
  pass: string;
}

interface IProps {
  formType: FormTypeEmun;
  setFormType: React.Dispatch<React.SetStateAction<FormTypeEmun>>;
}

const LoginForm = ({ formType, setFormType }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { scheduleAutoLogout } = useAutoLogout();
  const [login, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<IAuthProps>({
    mail: "",
    pass: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const getUserInfoFromToken = async (access_token: string) => {
    try {
      const response = await UserManagementServices.getUserInfo(access_token);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleLogin = async () => {
    const eightHoursInMs = 8 * 60 * 60 * 1000;
    try {
      const response = await login(formData).unwrap();
      const userInfo = await getUserInfoFromToken(response.access_token);

      if (userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        dispatch(setUserInfo(userInfo));
      }
      localStorage.setItem("accessToken", response.access_token);
      localStorage.setItem("csrf_token", response.csrf_token);
      localStorage.setItem("logout_token", response.logout_token);
      const loginTime = Date.now();
      localStorage.setItem("loginTime", loginTime.toString());
      dispatch(setIsAuth(true));
      scheduleAutoLogout(eightHoursInMs);
      toast.success("Login successful!");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const forgotPassword = async () => {
    setLoading(true);
    try {
      await UserManagementServices.resetPasswordFromEmail({
        email: formData.mail,
      });
      setFormType(FormTypeEmun.SetPassword);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = () => {
    if (isValidEmail) {
      if (formType === FormTypeEmun.ForgotPassword) {
        if (!formData.mail.length) {
          return true;
        }
      } else {
        if (!formData.mail.length) {
          return true;
        } else if (!formData.pass.length) {
          return true;
        }
      }

      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!formData.mail.length) {
      setIsValidEmail(false);
    }
  }, []);
  return (
    <>
      {formType === FormTypeEmun.SetPassword ? (
        <Box sx={{ width: "100%" }}>
          <Submit
            text="Your reset password is completed"
            renderButton={() => (
              <AtsRoundedButton
                buttonVariant="outline"
                type="button"
                isInitial
                text={"Back to login"}
                isUppercase={false}
                buttonHeight={40}
                buttonWidth={250}
                onClick={() => setFormType(FormTypeEmun.Login)}
              />
            )}
          />
        </Box>
      ) : (
        <Container maxWidth="xs">
          <Box sx={{ width: "100%" }}>
            <Stack
              sx={{
                width: "100%",
                textAlign: "center",
                mt: 2,
                pb: 2,
              }}
            >
              <Box
                sx={{
                  height: "50px",
                  mb: 2,
                }}
              >
                <img
                  height={"100%"}
                  src={require("../assets/images/logo.png")}
                  alt="wsteemed"
                />
              </Box>
              <AtsTitleText
                text={
                  formType === FormTypeEmun.ForgotPassword ? "Hey!" : "Hello!"
                }
                fs={36}
                fw={300}
              />
              <AtsTitleText
                text={
                  formType === FormTypeEmun.ForgotPassword
                    ? "Forgot your password"
                    : "Welcome back!"
                }
                fs={36}
                fw={300}
              />
            </Stack>
            <Box
              my={3}
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                if (formType === FormTypeEmun.Login) {
                  handleLogin();
                } else {
                  forgotPassword();
                }
              }}
            >
              <Box mb={formType === FormTypeEmun.Login ? 2 : 4}>
                <AtsTextInput
                  height="45px"
                  header="Email"
                  type="email"
                  error={formData.mail.length > 0 ? !isValidEmail : undefined}
                  value={formData.mail}
                  placeholder="Enter Email"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ): void => {
                    const inputValue = event.target.value;
                    setFormData({ ...formData, mail: inputValue });

                    setIsValidEmail(validateEmail(inputValue));
                  }}
                />
              </Box>
              {formType === FormTypeEmun.Login && (
                <Box mb={3}>
                  <AtsTextInput
                    height="45px"
                    header="Password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? (
                            <AtsIcon
                              icon="icon-eye"
                              color={COLORS.PRIMARY}
                              height="16px"
                            />
                          ) : (
                            <AtsIcon
                              icon="icon-eye-slash"
                              color={COLORS.PRIMARY}
                              height="16px"
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={formData.pass}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ): void => {
                      const inputValue = event.target.value;
                      setFormData({ ...formData, pass: inputValue });
                    }}
                  />
                </Box>
              )}
              <Stack gap={"10px"}>
                <AtsRoundedButton
                  type="submit"
                  text={formType === FormTypeEmun.Login ? "Login" : "Submit"}
                  isUppercase={false}
                  buttonHeight={40}
                  disabled={isLoading || loading || isDisabled()}
                  loading={isLoading || loading}
                />
                {formType === FormTypeEmun.ForgotPassword && (
                  <AtsRoundedButton
                    buttonVariant="outline"
                    type="button"
                    isInitial
                    text={"Back to login"}
                    isUppercase={false}
                    buttonHeight={40}
                    onClick={() => setFormType(FormTypeEmun.Login)}
                  />
                )}
              </Stack>
            </Box>
            {formType === FormTypeEmun.Login && (
              <Box
                sx={{ width: "fit-content", mt: 3 }}
                onClick={() => setFormType(FormTypeEmun.ForgotPassword)}
              >
                <AtsTitleText
                  text={"Forgot Password ?"}
                  fs={12}
                  fw={400}
                  isCursor
                  isUnderline
                />
              </Box>
            )}
            <Stack
              sx={{
                width: "100%",
                textAlign: "center",
                mt: "100px",
                pb: 2,
              }}
            >
              <AtsTitleText text="Powered by Esteemed Inc." fs={10} fw={400} />
            </Stack>
          </Box>
        </Container>
      )}
    </>
  );
};

export default LoginForm;
