import React, { createContext, useContext, useRef, useState } from "react";
import {
  MSAcontractResponse,
  SOWcontractReposnse,
  SOWcontractsParams,
  SOWResponse,
  UpdatecontractRequest,
} from "../types/contractTypes";
import { ContractFontsEnum, SignatureStepEnum } from "../enums/contractsEnums";
import SignaturePad from "react-signature-canvas";
import { ContractServices } from "../Api/contractServices";
import moment from "moment";
import { ProjectService } from "../Api/projectServices";

export enum ContractsTabsEnums {
  MasterContracts = "Master Contracts",
  StatementsOfWork = "Statements of Work",
}
export enum SortContractEnums {
  Newest = "Newest",
  Oldest = "Oldest",
}

export const initialMSAcontract = {
  nid: "",
  title: "",
  contract_type: "",
  start_date: "",
  end_date: "",
  signed: "",
  contract_link: "",
};
export const initialSOWcontract = {
  nid: "",
  title: "",
  contract_type: "",
  start_date: "",
  end_date: "",
  signed: "",
  contract_link: "",
  project_id: "",
  job_title: "",
  job_id: "",
  candidate_name: "",
  candidate_id: "",
};

const dummyStatementOfWork = [
  {
    isActive: false,
    isE_Sign: true,
    isRenew: false,
    id: "1234",
    contractorName: "Frank Kenneth",
    job: "Front-end Drupal developer",
    startDate: "Sep 19, 2024",
    endDate: "Feb 20, 2025",
    project: "Globex Interactive",
  },
  {
    isActive: true,
    isE_Sign: false,
    isRenew: true,
    id: "1234",
    contractorName: "Frank Kenneth",
    job: "Front-end Drupal developer",
    startDate: "Sep 19, 2024",
    endDate: "Feb 20, 2025",
    project: "Globex Interactive",
  },
  {
    isActive: true,
    isE_Sign: false,
    isRenew: false,
    id: "1234",
    contractorName: "Frank Kenneth",
    job: "Front-end Drupal developer",
    startDate: "Sep 19, 2024",
    endDate: "Feb 20, 2025",
    project: "Globex Interactive",
  },
];

type ContractsModuleContextType = {
  sigCanvas: SignaturePad | null;
  selectedContractTab: ContractsTabsEnums;
  selectedSignatureStep: SignatureStepEnum;
  font: ContractFontsEnum;
  selectedSortBy: SortContractEnums;
  signatureText: string;
  signatureImg: string;
  selectedProjects: string[];
  selectedContractors: string[];
  showSignatureModal: boolean;
  msaLoading: boolean;
  sowLoading: boolean;
  showEsignForm: boolean;
  isSignDraw: boolean;
  signLoading: boolean;
  sowData: SOWResponse[];
  msaContracts: MSAcontractResponse[];
  sowContracts: SOWcontractReposnse[];
  selectedMsaContracts: MSAcontractResponse;
  selectedSowContracts: SOWcontractReposnse;
  setSelectedContractTab: React.Dispatch<
    React.SetStateAction<ContractsTabsEnums>
  >;
  setFont: React.Dispatch<React.SetStateAction<ContractFontsEnum>>;
  setSignatureText: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSortBy: React.Dispatch<React.SetStateAction<SortContractEnums>>;
  setSelectedProjects: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedContractors: React.Dispatch<React.SetStateAction<string[]>>;
  setShowSignatureModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEsignForm: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSignDraw: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMsaContracts: React.Dispatch<
    React.SetStateAction<MSAcontractResponse>
  >;
  setSelectedSowContracts: React.Dispatch<
    React.SetStateAction<SOWcontractReposnse>
  >;
  setSelectedSignatureStep: React.Dispatch<
    React.SetStateAction<SignatureStepEnum>
  >;

  toggleSignatureModal: () => void;
  clear: () => void;
  onSign: (sign: string) => void;
  getMSA_Contracts: () => void;
  getSOW_Contracts: (params: Partial<SOWcontractsParams>) => void;
  onSelectSignatureStep: (step: SignatureStepEnum) => void;
};

const ContractsModule = createContext<ContractsModuleContextType | null>(null);

export const useContracts = () =>
  useContext(ContractsModule) as ContractsModuleContextType;

export const ContractsProvider = ({ children }: any) => {
  const sigCanvas = useRef<SignaturePad | null>(null);
  const [isSignDraw, setIsSignDraw] = useState<boolean>(false);

  const [selectedContractTab, setSelectedContractTab] =
    useState<ContractsTabsEnums>(ContractsTabsEnums.MasterContracts);
  const [selectedSortBy, setSelectedSortBy] = useState<SortContractEnums>(
    SortContractEnums.Newest
  );
  const [sowData, setSowData] = useState<SOWResponse[]>(dummyStatementOfWork);
  const [msaContracts, setMsaContracts] = useState<MSAcontractResponse[]>([]);
  const [sowContracts, setSowContracts] = useState<SOWcontractReposnse[]>([]);
  const [selectedMsaContracts, setSelectedMsaContracts] =
    useState<MSAcontractResponse>(initialMSAcontract);
  const [selectedSowContracts, setSelectedSowContracts] =
    useState<SOWcontractReposnse>(initialSOWcontract);
  const [signatureText, setSignatureText] = useState<string>("");
  const [selectedSignatureStep, setSelectedSignatureStep] =
    useState<SignatureStepEnum>(SignatureStepEnum.SignatureOptions);
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
  const [showEsignForm, setShowEsignForm] = useState<boolean>(false);
  const [font, setFont] = useState<ContractFontsEnum>(
    ContractFontsEnum.CedarvilleCursive
  );

  const [signatureImg, setSignatureImg] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [msaLoading, setMsaLoading] = useState(false);
  const [sowLoading, setSowLoading] = useState(false);
  const [signLoading, setSignLoading] = useState(false);

  const toggleSignatureModal = () => {
    setShowSignatureModal(!showSignatureModal);
    setSelectedSignatureStep(SignatureStepEnum.SignatureOptions);
    setFont(ContractFontsEnum.CedarvilleCursive);
  };

  const onSelectSignatureStep = (step: SignatureStepEnum) => {
    setSelectedSignatureStep(step);
  };

  const clear = () => {
    setIsSignDraw(false);
    sigCanvas.current.clear();
  };
  const onSign = async (sign: string) => {
    setSignLoading(true);
    try {
      const body = {
        contract_id: selectedMsaContracts.nid,
        sign,
        date: moment(new Date()).format("YYYY-MM-DD"),
      } as UpdatecontractRequest;
      console.log("onSign", body);
      const response = await ContractServices.updateContract(body);
      if (selectedContractTab === ContractsTabsEnums.MasterContracts) {
        try {
          const response = await ContractServices.getMsaContracts();
          if (response.length) {
            const findMsaContract = response.find(
              (value) => value.nid === selectedMsaContracts.nid
            );
            setSelectedMsaContracts(findMsaContract);
          }
        } catch (error) {}
      } else {
        try {
          const response = await ContractServices.getSowContracts({});
          if (response.length) {
            const findMsaContract = response.find(
              (value) => value.nid === selectedMsaContracts.nid
            );
            setSelectedMsaContracts(findMsaContract);
          }
        } catch (error) {}
      }
      toggleSignatureModal();
    } catch (error) {
    } finally {
      setSignLoading(false);
    }
  };

  const getMSA_Contracts = async () => {
    setMsaLoading(true);
    try {
      const response = await ContractServices.getMsaContracts();
      setMsaContracts(response);
    } catch (error) {
      console.error(error);
    } finally {
      setMsaLoading(false);
    }
  };

  const getSOW_Contracts = async (params: Partial<SOWcontractsParams>) => {
    setSowLoading(true);
    try {
      const response = await ContractServices.getSowContracts({});
      setSowContracts(response);
    } catch (error) {
      console.error(error);
    } finally {
      setSowLoading(false);
    }
  };

  return (
    <ContractsModule.Provider
      value={{
        selectedSortBy,
        msaLoading,
        sowLoading,
        signLoading,
        isSignDraw,
        showEsignForm,
        msaContracts,
        selectedMsaContracts,
        sigCanvas,
        sowData,
        font,
        signatureText,
        signatureImg,
        showSignatureModal,
        selectedContractTab,
        selectedSignatureStep,
        selectedSowContracts,
        sowContracts,
        selectedProjects,
        selectedContractors,
        setSelectedSortBy,
        setSelectedContractors,
        setSelectedProjects,
        setIsSignDraw,
        setSelectedSowContracts,
        setSelectedMsaContracts,
        setSelectedSignatureStep,
        setSelectedContractTab,
        setShowEsignForm,
        setFont,
        setSignatureText,
        setShowSignatureModal,
        toggleSignatureModal,
        onSelectSignatureStep,
        clear,
        onSign,
        getMSA_Contracts,
        getSOW_Contracts,
      }}
    >
      {children}
    </ContractsModule.Provider>
  );
};
