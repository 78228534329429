import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { Grid, Stack } from "@mui/material";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import {
  ProfileToggleState,
  useMyProfile,
} from "../../../providers/MyProfileProvider";
import biography from "../assets/biography.svg";
import decade from "../assets/decade.svg";
import fact from "../assets/fact.svg";
import language from "../assets/language.svg";
import live from "../assets/live.svg";
import music from "../assets/music.svg";
import obsesed from "../assets/obsesed.svg";
import pets from "../assets/pets.svg";
import school from "../assets/school.svg";
import time from "../assets/time.svg";
import work from "../assets/work.svg";
import moment from "moment";
import AtsRow from "../../../components/AtsRow";
import { BORDERS, COLORS } from "../../../theme";

const ExistingProfile = () => {
  const { accountDetailsState } = useCreateAccount();
  const { setProfileToggleState, updateProfileState } = useMyProfile();
  const userName = `${accountDetailsState.first_name} ${accountDetailsState.last_name}`;

  const usedPlatforms = [
    {
      icon: decade,
      text: `Born in the ${
        updateProfileState.dob && updateProfileState.dob.length
          ? moment(updateProfileState.dob).format("YYYY")
          : "00"
      }s`,
    },
    {
      icon: school,
      text: `Where I went to school: ${
        updateProfileState.where_i_went_to_school &&
        updateProfileState.where_i_went_to_school.length
          ? updateProfileState.where_i_went_to_school
          : "--"
      }`,
    },
    {
      icon: work,
      text: `My work:  ${
        updateProfileState.my_work && updateProfileState.my_work.length
          ? updateProfileState.my_work
          : "--"
      }`,
    },
    {
      icon: time,
      text: `spend too much time:  ${
        updateProfileState.spend_to_much_time &&
        updateProfileState.spend_to_much_time.length
          ? updateProfileState.spend_to_much_time
          : "--"
      }`,
    },
    {
      icon: music,
      text: `Favourite song in secondary school:  ${
        updateProfileState.favorite_song &&
        updateProfileState.favorite_song.length
          ? updateProfileState.favorite_song
          : "--"
      }`,
    },
    {
      icon: obsesed,
      text: `I'm obsessed with:  ${
        updateProfileState.obessed_with &&
        updateProfileState.obessed_with.length
          ? updateProfileState.obessed_with
          : "--"
      }`,
    },
    {
      icon: language,
      text: `Speaks  ${
        updateProfileState.languages && updateProfileState.languages.length
          ? updateProfileState.languages.join(" and ")
          : "--"
      }`,
    },
    {
      icon: live,
      text: `Lives in  ${
        updateProfileState.where_i_live &&
        updateProfileState.where_i_live.length
          ? updateProfileState.where_i_live
          : "--"
      }`,
    },
  ];
  return (
    <Stack gap={"20px"}>
      <AtsTitleText
        text={`About ${userName ?? ""}`}
        fs={18}
        fw={600}
        textColor="#000"
      />
      <AtsRoundedButton
        text="Edit profile"
        buttonVariant="outline"
        buttonWidth={127}
        buttonHeight={30}
        onClick={() => setProfileToggleState(ProfileToggleState.Create)}
      />
      <Stack
        pb={"30px"}
        sx={{
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Grid container columnSpacing={"30px"} rowSpacing={"10px"}>
          {usedPlatforms.map((platform, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <AtsRow
                gap={"12px"}
                sx={{
                  px: "10px",
                  py: "10px",
                }}
              >
                <>
                  <img
                    src={platform?.icon}
                    width={25}
                    height={25}
                    alt={platform?.icon}
                  />
                  <AtsTitleText
                    text={platform.text}
                    fs={14}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                  />
                </>
              </AtsRow>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack gap={"24px"}>
        <Stack gap={"13px"}>
          <AtsTitleText
            text={`Ask ${userName ?? ""} about`}
            fs={24}
            fw={600}
            textColor="#000"
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"20px"}
          flexWrap={"wrap"}
        >
          {updateProfileState.you_are_into.length > 0 &&
            updateProfileState.you_are_into.map((item, index) => {
              return (
                <AtsRow
                  key={index}
                  gap={"8px"}
                  sx={{
                    borderRadius: "50px",
                    border: BORDERS.GRAY,
                    px: "16px",

                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <>
                    {" "}
                    {/* {updateProfileState.you_are_into.includes(item.title)
                        ? item.activeIcon
                        : item.icon} */}
                    <AtsTitleText
                      text={item?.title}
                      fs={12}
                      fw={400}
                      textColor={"#000"}
                    />
                  </>
                </AtsRow>
              );
            })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ExistingProfile;
