import { Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../theme";

const WorkingExperiance = () => {
  return (
    <Stack gap={"4px"}>
      <AtsTitleText
        text={"Working with Esteemed"}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
        noTextWrap
      />
      <AtsTitleText text={"4+ years"} fs={14} fw={400} noTextWrap />
    </Stack>
  );
};

export default WorkingExperiance;
