import { Close } from "@mui/icons-material";
import { Dialog, Paper, PaperProps, Stack } from "@mui/material";
import Draggable from "react-draggable";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsIcon from "../../../../components/AtsIcon";
import AtsRow from "../../../../components/AtsRow";
import AtsRowBetween from "../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useCallingStore } from "../../../../providers/CallingProvider";
import { useMessages } from "../../../../providers/MessagesProvider";
import { getAuthState } from "../../../../redux/slices/authSlice";
import { useAppSelector } from "../../../../redux/store";
import { BORDERS, COLORS } from "../../../../theme";
import ContactInformation from "./components/ContactInformation";
import ContractorInfo from "./components/ContractorInfo";
import VideoCallingBox from "./components/VideoCallingBox";
import WorkingExperiance from "./components/WorkingExperiance";

const ContractorDetails = () => {
  const { userInfo } = useAppSelector(getAuthState);
  const { handleShowContractorDetails, socket, messenger } = useMessages();
  const {
    openCallingModal,
    storeCallInfo,
    isVoiceCalling,
    setIsVoiceCalling,
    setAcceptCall,
    setCallIsDisconnected,
    setOpenCallingModal,
  } = useCallingStore();

  const onClick = () => {};
  const onClose = () => {
    handleShowContractorDetails(false);
  };
  const onCalling = async (type) => {
    try {
      const isGroupChat = messenger?.isGroupChat;

      let fullName = `${userInfo?.first_name ?? ""} ${
        userInfo?.last_name ?? ""
      }`;
      if (isGroupChat) {
        fullName = messenger.chatName;
      }

      const payload = {
        id: messenger.id,
        conversationId: isGroupChat ? messenger.id : messenger.recipient.id,
        type: type,
        isGroupChat,
        callBy: {
          // id: userInfo.uid,
          id: `405953a0-9fd5-4495-8103-8d8853b65dc1`,
          fullName: `Shailendra Chodavadiya`,
        },
        callTo: {
          id: messenger.id,
          fullName: `${messenger?.recipient?.firstName ?? ""} ${
            messenger?.recipient?.lastName ?? ""
          }`,
        },
      };

      socket.emit("callInitiate", payload);
      setOpenCallingModal(true);
    } catch (error) {
      console.error("GOT ERROR", error);
    }
  };

  const onVoiceCalling = async () => {
    setIsVoiceCalling(true);
    onCalling("VOICE");
  };

  const onVideoCalling = async () => {
    setIsVoiceCalling(false);
    onCalling("VIDEO");
  };

  return (
    <>
      <AtsRowBetween
        sx={{
          px: "30px",
          pt: "16px",
        }}
      >
        <>
          <AtsTitleText
            text={"Contractor details"}
            fs={18}
            fw={600}
            noTextWrap
          />
          <AtsCustomButton px={0} py={0} onClick={onClose}>
            <Close />
          </AtsCustomButton>
        </>
      </AtsRowBetween>
      <Stack
        sx={{
          px: "30px",
          pt: "27px",
          pb: "15px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <ContractorInfo />
      </Stack>
      <Stack
        sx={{
          px: "30px",
          py: "18px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <WorkingExperiance />
      </Stack>
      <AtsRow
        sx={{
          px: "30px",
          py: "18px",
          borderBottom: BORDERS.GRAY,
        }}
        gap={"20px"}
      >
        <>
          <RenderButton text={"Audio Call"} icon={"call"} onClick={onClick} />
          <RenderButton
            text={"Video Call"}
            icon={"video"}
            onClick={onVideoCalling}
          />
        </>
      </AtsRow>
      <Stack
        sx={{
          px: "30px",
          pt: "20px",
          pb: "15px",
        }}
      >
        <ContactInformation />
      </Stack>
      {isVoiceCalling ? (
        <Dialog
          open={openCallingModal}
          // onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <VideoCallingBox
          // callInfo={storeCallInfo}
          // isVoiceCalling={isVoiceCalling}
          // onAccept={onAcceptCall}
          // onDecline={onOutgoingCallEnd}
          />
        </Dialog>
      ) : (
        <Dialog open={openCallingModal} fullScreen>
          <VideoCallingBox
          // callInfo={storeCallInfo}
          // isVoiceCalling={isVoiceCalling}
          // onAccept={onAcceptCall}
          // onDecline={onOutgoingCallEnd}
          />
        </Dialog>
      )}
    </>
  );
};

export default ContractorDetails;

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const RenderButton = ({
  text,
  icon,
  onClick,
}: {
  text: string;
  icon: string;
  onClick: () => void;
}) => {
  return (
    <AtsRow
      gap={"6px"}
      sx={{
        justifyContent: "center",
        height: 28,
        width: 106,
        border: BORDERS.PRIMARY,
        borderRadius: "20px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <>
        <AtsIcon
          icon={`icon-${icon}`}
          height="16px"
          fs={16}
          color={COLORS.PRIMARY}
        />
        <AtsTitleText
          text={text}
          fs={12}
          fw={600}
          textColor={COLORS.PRIMARY}
          noTextWrap
        />
      </>
    </AtsRow>
  );
};
