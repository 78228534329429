import { Box, Container, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AtsTitleText from "../../components/AtsTitleText";
import { COLORS } from "../../theme";
import JobsSwitch from "../Dashboard/PostedJobs/JobsSwitch";
import { StatusEnum } from "../../enums/commonEnums";
import AtsCard from "../../components/AtsCard";
import ActiveUsers from "./modules/ActiveUsers";
import PendingUsers from "./modules/PendingUsers";
import InactiveUsers from "./modules/InactiveUsers";
import { toLowerCase } from "../../utils/stringUtils";
import { UserManagementServices } from "../../Api/userManagementServices";
import { ClientUser } from "../../types/userManagementTypes";

export interface IUserManagemnetProps {
  invitedUsers: ClientUser[];
  loading: boolean;
  userBlockUnBlock?: (email: string, status: "block" | "unblock") => void;
}

const UserManagement = () => {
  const [selectedStatus, setSelectedStatus] = useState<StatusEnum>(
    StatusEnum.ACTIVE
  );
  const [invitedUsers, setInvitedUsers] = useState<ClientUser[]>([]);
  const [loading, setLoading] = useState(false);

  const userStatuses = Object.keys(StatusEnum).map((status) => ({
    title: toLowerCase(status),
    type: status,
  }));

  const userBlockUnBlock = async (
    email: string,
    status: "block" | "unblock"
  ) => {
    setLoading(true);
    try {
      await UserManagementServices.userBlockUnBlock({ email, status });
      getUserList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUserList = async () => {
    setLoading(true);
    try {
      const response = await UserManagementServices.getActiveUsers(
        selectedStatus
      );
      setInvitedUsers(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, [selectedStatus]);

  const activeUsersList = useMemo(
    () => (
      <ActiveUsers
        invitedUsers={invitedUsers}
        loading={loading}
        userBlockUnBlock={userBlockUnBlock}
      />
    ),
    [invitedUsers, loading]
  );
  const inactiveUsersList = useMemo(
    () => (
      <InactiveUsers
        invitedUsers={invitedUsers}
        loading={loading}
        userBlockUnBlock={userBlockUnBlock}
      />
    ),
    [invitedUsers, loading]
  );
  const pendingUsersList = useMemo(
    () => <PendingUsers invitedUsers={invitedUsers} loading={loading} />,
    [invitedUsers, loading]
  );

  return (
    <div
      className="main-container"
      style={{
        height: "100%",
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 3, mb: 4 }}>
        <Stack
          px={2}
          sx={{
            height: "100%",
          }}
        >
          <AtsTitleText
            text="User Management"
            fs={24}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Stack
            sx={{ pt: "22px", pb: "19px" }}
            direction={"row"}
            alignItems={"center"}
          >
            <JobsSwitch
              width="70px"
              jobs={userStatuses}
              active={selectedStatus}
              onChange={(value) => {
                setSelectedStatus(value as StatusEnum);
              }}
            />
            <Stack
              gap={0.2}
              direction={"row"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
            ></Stack>
          </Stack>
          <Box
            sx={{
              mt: "22px",
              height: "calc(100% - 130px)",
            }}
          >
            <AtsCard
              sx={{
                height: "95%",
              }}
            >
              <Stack px={2} height={"100%"}>
                {selectedStatus === StatusEnum.INACTIVE
                  ? inactiveUsersList
                  : selectedStatus === StatusEnum.PENDING
                  ? pendingUsersList
                  : activeUsersList}
              </Stack>
            </AtsCard>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default UserManagement;

export const getButtonVariant = (role: string) => {
  if (role === "Admin") {
    return "greenContained";
  }
  return "blueContained";
};
