import { Stack } from "@mui/material";
import AtsTitleText from "../../components/AtsTitleText";
import ActiveContracts from "./modules/ActiveContracts";
import ContractFilterSection from "./modules/ContractFilterSection";
import InActiveContractCard from "./modules/InActiveContractCard";
import { useEffect } from "react";
import { useContracts } from "../../providers/ContractsProvider";
import LoadingMSAContract from "./modules/LoadingMSAContract";

const MasterContracts = () => {
  const { getMSA_Contracts, msaLoading, msaContracts } = useContracts();
  useEffect(() => {
    getMSA_Contracts();
  }, []);

  return (
    <>
      <Stack px={"9px"}>
        <ContractFilterSection />
      </Stack>
      <Stack gap={"15px"} mt={"20px"}>
        {/* <Stack px={"9px"}>
          <AtsTitleText text={"1 Active"} textColor="#000" fs={18} fw={700} />
        </Stack> */}
        {msaLoading
          ? Array.from(new Array(3)).map((_, index) => (
              <LoadingMSAContract key={index} />
            ))
          : msaContracts.map((contract) => (
              <ActiveContracts key={contract.nid} contract={contract} />
            ))}
      </Stack>
      {/* <Stack gap={"15px"} mt={"70px"}>
        <Stack px={"9px"}>
          <AtsTitleText
            text={"12 Inactive"}
            textColor="#000"
            fs={18}
            fw={700}
          />
        </Stack>

        <InActiveContractCard />
        <InActiveContractCard />
        <InActiveContractCard />
        <InActiveContractCard />
        <InActiveContractCard />
      </Stack> */}
    </>
  );
};

export default MasterContracts;
