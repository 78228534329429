import { Slider, SliderProps, SliderThumb, styled } from "@mui/material";
import React from "react";
import { formattedAmount } from "../utils/common";

interface ISliderProps extends SliderProps {}
interface SliderThumbComponentProps extends React.HTMLAttributes<unknown> {}

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "var(--primary)",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "4px solid var(--primary)",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "var(--primary)",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "var(--primary)",
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

const SliderThumbComponent = (props: SliderThumbComponentProps) => {
  const { children, ...other } = props;
  return <SliderThumb {...other}>{children}</SliderThumb>;
};

const AtsSlider = ({ ...rest }: ISliderProps) => {
  return (
    <>
      <CustomSlider
        slots={{ thumb: SliderThumbComponent }}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => {
          return `$${formattedAmount(value)}`;
        }}
        {...rest}
      />
    </>
  );
};

export default AtsSlider;
