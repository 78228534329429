import { Box, Stack } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRow from "../../../components/AtsRow";
import Menu from "../icons/Menu.svg";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";

const CompanyOverview = () => {
  const { companyInfoState } = useCreateAccount();

  const line1 = companyInfoState?.company_address?.address_line1.length
    ? `${companyInfoState?.company_address?.address_line1},`
    : "";
  const administrative_area = companyInfoState?.company_address
    ?.administrative_area.length
    ? `${companyInfoState?.company_address?.administrative_area},`
    : "";
  const country = companyInfoState?.company_address?.country.length
    ? `${companyInfoState?.company_address?.country}`
    : "";

  const address = `${line1} ${administrative_area} ${country}`;
  return (
    <>
      <Stack
        gap={"20px"}
        pb={"30px"}
        sx={{
          borderBottom: BORDERS.GRAY,
        }}
      >
        <AtsTitleText text={"Company overview"} fs={18} fw={600} />
        <TextWrapper
          title={"Industry"}
          value={companyInfoState?.industry ?? ""}
        />
        <TextWrapper title={"Headquarters"} value={address} />
        <AtsRow gap={"5px"}>
          <>
            <img src={Menu} alt="" />
            <AtsTitleText
              text={"See Company description"}
              fs={12}
              fw={600}
              textColor={COLORS.PRIMARY}
            />
          </>
        </AtsRow>
      </Stack>
    </>
  );
};

export default CompanyOverview;

const TextWrapper = ({ title, value }) => {
  return (
    <Stack gap={"5px"}>
      <AtsTitleText
        text={title}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
      />
      <AtsTitleText
        text={value}
        fs={14}
        fw={700}
        textColor={COLORS.DARK_GRAY}
      />
    </Stack>
  );
};
