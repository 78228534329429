import { Box, Grid, Stack } from "@mui/material";
import { Eye, InfoCircle } from "iconsax-react";
import PdfIcon from "../../../assets/images/pdfIcon.png";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useContracts } from "../../../providers/ContractsProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import { SOWcontractReposnse } from "../../../types/contractTypes";
import { getDateFormatMMMDYYYYY } from "../../../utils/dateUtils";
import ProjectNameById from "../components/ProjectNameById";
import moment from "moment";
import { useState } from "react";
import AtsModal from "../../../components/AtsModal";
import AtsCustomButton from "../../../components/AtsCustomButton";
import { Close } from "@mui/icons-material";

const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

interface IProps {
  data: SOWcontractReposnse;
}

const StatementOfWorkCard = ({ data }: IProps) => {
  const { setShowEsignForm, setSelectedMsaContracts } = useContracts();
  const [contractLink, setContractLink] = useState("");
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  const onView = (contract: SOWcontractReposnse) => {
    if (contract && contract.contract_link && contract.contract_link.length) {
      toggleModal();
      setContractLink(contract.contract_link);
    }
  };

  const EsignComponent = ({ contract }) => {
    return (
      <Stack direction={"row"} alignItems={"flex-start"} gap={"7px"}>
        <Box>
          <InfoCircle size={16} variant="Bold" color="#e63030" />
        </Box>
        <Stack gap={"18px"}>
          <AtsTitleText
            text={`Your Master Services Agreement (MSA) is ready for e-signature. 
  Please e-sign it now to access the Dashboard.`}
            fs={12}
            fw={400}
          />
          <AtsRoundedButton
            text="E-Signature"
            isInitial
            buttonWidth={119}
            buttonHeight={26}
            fs={12}
            onClick={() => {
              setShowEsignForm(true);
              setSelectedMsaContracts(contract);
            }}
          />
        </Stack>
      </Stack>
    );
  };
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 161,
          mx: "9px",
          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 177,
                minHeight: 161,
                bgcolor: BG_COLORS.PRIMARY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <img src={require("../assets/images/sowDocs.png")} alt="" />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <img src={require("../assets/images/talentSOW.png")} alt="" />
              </Box>
              {/* {data.isActive && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    padding: "2px 7px 3px",
                    bgcolor: "#1bbd40",
                    borderTopLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <AtsTitleText
                    text={"Active"}
                    textColor={"white"}
                    fs={11}
                    fw={600}
                  />
                </Box>
              )} */}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 161,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      <Grid container spacing={"20px"}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"SOW No."}
                            value={`SOW${data.nid}`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"Contractor Name"}
                            value={data?.candidate_name ?? "--"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"Job"}
                            value={data?.job_title ?? "--"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"Start date"}
                            value={
                              data?.start_date
                                ? getDateFormatMMMDYYYYY(data?.start_date)
                                : "--"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"End date"}
                            value={
                              data?.end_date
                                ? getDateFormatMMMDYYYYY(data?.end_date)
                                : "--"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <ProjectNameById projectId={data?.project_id} />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      ...(data.signed === "0"
                        ? { minWidth: 190, maxWidth: 210 }
                        : { minWidth: 202 }),

                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pr: "19px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {data.signed === "0" ? (
                      <EsignComponent contract={data} />
                    ) : data.end_date === moment(new Date()).format() ? (
                      <RenewComponent
                        contract={data}
                        onView={() => onView(data)}
                      />
                    ) : (
                      <ViewContractComponent
                        contract={data}
                        onView={() => onView(data)}
                      />
                    )}
                  </Box>
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
      <AtsModal width={800} height={550} open={open} onClose={toggleModal}>
        <Box height={"100%"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              text={"Contract Details"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsCustomButton px={0} py={0} onClick={toggleModal}>
              <Close />
            </AtsCustomButton>
          </Stack>
          <Stack
            px={"30px"}
            pt={"40px"}
            gap={"10px"}
            sx={{
              height: "calc(100% - 120px)",
            }}
          >
            <iframe
              src={contractLink}
              title="PDF Viewer"
              width="100%"
              height="100%"
              style={{ border: "none", borderRadius: "10px" }}
            />
          </Stack>
        </Box>
      </AtsModal>
    </>
  );
};

export default StatementOfWorkCard;

const TextWrapper = ({ text, value }) => {
  return (
    <Stack gap={"2px"}>
      <AtsTitleText
        text={text}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
      />
      <AtsTitleText
        text={value}
        fs={14}
        fw={500}
        textColor={COLORS.DARK_GRAY}
      />
    </Stack>
  );
};

const ViewContractComponent = ({
  contract,
  onView,
}: {
  contract: SOWcontractReposnse;
  onView: () => void;
}) => {
  return (
    <Stack gap={"18px"} sx={{}}>
      <AtsRoundedButton
        text="View contract"
        isInitial
        buttonVariant="outline"
        buttonWidth={129}
        buttonHeight={26}
        fs={12}
        fw={400}
        startIcon={<Eye color={COLORS.PRIMARY} size={16} />}
        onClick={onView}
      />
      <AtsRoundedButton
        text="Download contract"
        isInitial
        buttonVariant="outline"
        buttonWidth={158}
        buttonHeight={26}
        fs={12}
        fw={400}
        startIcon={<img src={PdfIcon} alt="pdf" height={16} />}
        onClick={() => window.open(contract.contract_link, "_blank")}
      />
    </Stack>
  );
};
const RenewComponent = ({
  contract,
  onView,
}: {
  contract: SOWcontractReposnse;
  onView: () => void;
}) => {
  return (
    <Stack direction={"row"} alignItems={"flex-start"} gap={"7px"}>
      <Box>
        <InfoCircle size={16} variant="Bold" color="#e63030" />
      </Box>
      <Stack gap={"18px"} sx={{}}>
        <AtsTitleText text={`Contract ends today`} fs={12} fw={400} />
        <AtsRoundedButton
          text="Renew"
          isInitial
          buttonWidth={68}
          buttonHeight={26}
          fs={12}
          fw={400}
          onClick={onView}
        />
        <AtsRoundedButton
          text="Download contract"
          isInitial
          buttonVariant="outline"
          buttonWidth={158}
          buttonHeight={26}
          fs={12}
          fw={400}
          startIcon={<img src={PdfIcon} alt="pdf" height={16} />}
          onClick={() => window.open(contract.contract_link, "_blank")}
        />
      </Stack>
    </Stack>
  );
};
