import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@mui/icons-material";
import { Box, Collapse, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsDataTable from "../../../components/AtsDataTable";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { FilterIcon, TickCircleIcon } from "../../../assets/icons/Icons";
import { getButtonVariant, IUserManagemnetProps } from "../UserManagement";
import { ClientUser } from "../../../types/userManagementTypes";
import AtsUserNameTextAvatar from "../../../components/AtsUserNameTextAvatar";
import { concatenateStrings } from "../../../utils/stringUtils";
import { TickCircle } from "iconsax-react";
import AtsEmptyState from "../../../components/AtsEmptyState";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "uid",
    header: "User",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "client_role",
    header: "Role",
    width: "auto",
    minWidth: "150px",
  },
  {
    key: "action",
    header: "",
    width: "150px",
    minWidth: "150px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const firstCellStyles = {
  padding: "5px 0px",
};

const InactiveUsers = ({
  invitedUsers,
  loading,
  userBlockUnBlock,
}: IUserManagemnetProps) => {
  const userBlock = async (email: string) => {
    await userBlockUnBlock(email, "unblock");
  };

  const customizeRow = (column: IColumnProps, user: ClientUser) => {
    switch (column.key) {
      case "uid":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={0.3}>
              <Stack
                sx={{
                  mr: "12px",
                  width: 58,
                  height: 58,
                }}
              >
                <AtsUserNameTextAvatar
                  name={`${user.first_name} ${user.last_name}`}
                />
              </Stack>
              <Stack gap={1}>
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor="#000"
                  text={concatenateStrings(user.first_name, user.last_name)}
                />

                <AtsTitleText
                  fs={12}
                  fw={500}
                  textColor={COLORS.LIGHT_GRAY}
                  text={user.title}
                />
                <AtsTitleText
                  fs={12}
                  fw={500}
                  textColor={COLORS.BLACK}
                  text={user.roles}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={500}
                textColor={COLORS.BLACK}
                text={user[column.key]}
              />
            </Stack>
          </td>
        );
      case "client_role":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={12}
                fw={600}
                text={user.client_role}
                buttonVariant={getButtonVariant(user.client_role)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={"12"}
                fw={600}
                text="Reactivate"
                buttonVariant="greenOutline"
                startIcon={<TickCircleIcon strokeColor={COLORS.GREEN} />}
                onClick={() => userBlock(user.email)}
              />
            </Stack>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = invitedUsers.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={12}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {/* {column.header.length > 0 && <FilterIcon />} */}
              </Stack>
            </AtsCustomButton>
            {/* {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )} */}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        {loading ? (
          <AtsDataTable
            isNotStickyHeader
            data={[]}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : invitedUsers.length ? (
          <AtsDataTable
            isNotStickyHeader
            data={invitedUsers}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : (
          <AtsEmptyState
            sx={{
              height: "100%",
            }}
            text="No inactive users"
            subText=""
          />
        )}
      </Box>
    </>
  );
};

export default InactiveUsers;
