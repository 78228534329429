import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilterIcon } from "../../../assets/icons/Icons";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsDataTable from "../../../components/AtsDataTable";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { ClientUser } from "../../../types/userManagementTypes";
import { getButtonVariant, IUserManagemnetProps } from "../UserManagement";
import AtsEmptyState from "../../../components/AtsEmptyState";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "uid",
    header: "User",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "email",
    header: "Email",
    width: "200px",
    minWidth: "200px",
  },

  {
    key: "client_role",
    header: "Role",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "200px",
    minWidth: "200px",
  },
];

const cellStyles = {
  padding: "9px 10px",
};
const firstCellStyles = {
  padding: "9px 0px",
};

const PendingUsers = ({ invitedUsers, loading }: IUserManagemnetProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(selectedUsers.length === invitedUsers.length);
  }, [selectedUsers]);

  const handleUserSelect = (id: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllUser = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedUsers(newSelectAll ? invitedUsers.map((user) => user.uid) : []);
  };
  const customizeRow = (column: IColumnProps, user: ClientUser) => {
    switch (column.key) {
      case "uid":
        return (
          <td style={firstCellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <AtsCheckbox
                checked={selectedUsers.includes(user.uid)}
                onChange={() => handleUserSelect(user.uid)}
                p={0}
              />
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={500}
                textColor={COLORS.BLACK}
                text={user[column.key]}
              />
            </Stack>
          </td>
        );
      case "client_role":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={"12"}
                fw={600}
                text={user.client_role}
                buttonVariant={getButtonVariant(user.client_role)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <AtsCustomButton px={0.6}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsIcon
                    icon="icon-close-circle"
                    height="16px"
                    color={COLORS.LIGHT_GRAY}
                  />
                  <AtsTitleText
                    fs={12}
                    fw={500}
                    textColor={COLORS.LIGHT_GRAY}
                    text={"Cancel"}
                    lineHeight={"normal"}
                  />
                </Stack>
              </AtsCustomButton>
              <Divider variant="fullWidth" orientation="vertical" flexItem />
              <AtsCustomButton px={0.6}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsIcon
                    icon="icon-sms-tracking"
                    height="16px"
                    color={COLORS.GREEN}
                  />
                  <AtsTitleText
                    fs={12}
                    fw={500}
                    textColor={COLORS.GREEN}
                    text={"Resend"}
                    lineHeight={"normal"}
                  />
                </Stack>
              </AtsCustomButton>
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = invitedUsers.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              minHeight: "44px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const isIndeterminate =
      selectedUsers.length > 0 && selectedUsers.length < invitedUsers.length;
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: index === 0 ? "50px" : column.width,
            minWidth: index === 0 ? "50px" : column.minWidth,
            padding: index === 0 ? "15px 0px" : "15px 10px",
            textAlign: "start",
          }}
        >
          {index === 0 ? (
            <AtsCheckbox
              checked={selectAll}
              indeterminate={isIndeterminate}
              onChange={handleSelectAllUser}
              p={0}
            />
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <AtsCustomButton px={0.5}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsTitleText
                    text={column.header}
                    fs={12}
                    fw={600}
                    textColor="#4f4f4f"
                    isCursor
                  />
                  {/* {column.header.length > 0 && <FilterIcon />} */}
                </Stack>
              </AtsCustomButton>
              {/* {column.header.length > 0 && (
                <Stack>
                  <KeyboardArrowUp
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mb: -0.4,
                      cursor: "pointer",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mt: -0.5,
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              )} */}
            </Stack>
          )}
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        {loading ? (
          <AtsDataTable
            isNotStickyHeader
            data={[]}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : invitedUsers.length ? (
          <AtsDataTable
            isNotStickyHeader
            data={invitedUsers}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : (
          <AtsEmptyState
            sx={{
              height: "100%",
            }}
            text="No pending users"
            subText=""
          />
        )}
      </Box>
    </>
  );
};

export default PendingUsers;
