import { Chip, ChipProps } from "@mui/material";

interface IChipsProps extends ChipProps {
  text: string | number;
}

const AtsChip = ({ text, ...rest }: IChipsProps) => {
  return <Chip {...rest} label={text} size="small" />;
};

export default AtsChip;
