import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../components/AtsTitleText";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import { CheckCircleOutline } from "@mui/icons-material";
import { COLORS } from "../../../../theme";

interface INotificationCardProps {
  headerText: string;
  buttonText?: string;
  timeText?: string;
  isUnread?: boolean;
  messageRender: JSX.Element;
}

const NotificationCard = ({
  headerText,
  buttonText,
  messageRender,
  timeText,
  isUnread,
}: INotificationCardProps) => {
  return (
    <Stack gap={1}>
      <Stack
        pl={2}
        py={2}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <AtsTitleText text={headerText} fs={14} fw={500} />
        {buttonText && (
          <Stack
            sx={{
              cursor: "pointer",
              bgcolor: "#f5f5f5",
              borderRadius: "2px",
              py: 0.5,
              px: 2,
            }}
          >
            <AtsTitleText text="Mark All as read" fs={12} fw={600} />
          </Stack>
        )}
      </Stack>
      <Stack
        py={2}
        px={"22px"}
        sx={{
          bgcolor: isUnread ? "var(--bg-primary)" : "white",
          position: "relative",
        }}
      >
        <Stack direction={"row"} alignItems={"start"} gap={1} sx={{}}>
          <Avatar sx={{ width: 32, height: 32 }} />
          <Stack gap={1.5}>
            {messageRender}

            {isUnread && (
              <AtsTitleText
                text="Check out their profile, or send them a message."
                fs={10}
                fw={400}
              />
            )}
            {isUnread ? (
              <AtsRoundedButton
                fs={12}
                text="Accept"
                buttonHeight={"26px"}
                buttonWidth={"100px"}
                lineHeight={"0"}
                startIcon={<CheckCircleOutline />}
              />
            ) : (
              <AtsTitleText
                text="View Job"
                isCursor
                fs={12}
                fw={600}
                textColor={COLORS.PRIMARY}
              />
            )}
            <AtsTitleText
              text={timeText}
              fs={12}
              fw={300}
              textColor={COLORS.LIGHT_GRAY}
            />
          </Stack>
        </Stack>
        {isUnread && (
          <Box
            component={"div"}
            sx={{
              position: "absolute",
              left: 10,
              top: 30,
              width: 6,
              height: 6,
              borderRadius: "50%",
              bgcolor: COLORS.RED_LIGHT,
            }}
          ></Box>
        )}
      </Stack>
    </Stack>
  );
};

export default NotificationCard;
