import { ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LogoutIcon } from "../../assets/icons/Icons";
import AtsRow from "../../components/AtsRow";
import AtsTitleText from "../../components/AtsTitleText";
import AtsUserNameTextAvatar from "../../components/AtsUserNameTextAvatar";
import { useAutoLogout } from "../../providers/AutoLogoutProvider";
import { getAuthState, setFontSizeType } from "../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { RoutesProps, USER_ROUTES } from "../../router/ATS_routes";
import { BG_COLORS, COLORS } from "../../theme";
import { useClickOutSide } from "../../utils/common";
import "../css/header.css";

const fontSizeTypeData = [
  {
    text: "REM",
    value: "rem",
  },
  {
    text: "EM",
    value: "em",
  },
  {
    text: "PX",
    value: "px",
  },
];

const UserInfo = () => {
  const dispatch = useAppDispatch();
  const { handleLogout } = useAutoLogout();
  const { userInfo, fontSizeType } = useAppSelector(getAuthState);
  const { pathname } = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const onLogout = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      // await SubmissionService.logOut({ token });
      // localStorage.clear();
      // dispatch(setIsAuth(false));
      handleLogout();
    } catch (error) {}
  };
  const getUserInfo = () => {
    if (userInfo) {
      return {
        userName: `${userInfo?.first_name ?? ""} ${userInfo?.last_name ?? ""}`,
        role: userInfo?.sub_role?.role_name ?? "",
        companyName: userInfo?.company?.title ?? "",
        userTitle: userInfo?.title ?? "",
      };
    } else {
      return {
        userName: "",
        role: "",
        companyName: "",
        userTitle: "",
      };
    }
  };

  return (
    <>
      <Box ref={domNode} sx={{ position: "relative" }}>
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", cursor: "pointer" }}
          gap={1}
          onClick={() => setShow(!show)}
        >
          <Box
            sx={{
              height: "40px",
              width: "40px",
              border: "1px solid #dadada",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <AtsUserNameTextAvatar
              name={getUserInfo().userName}
              image={
                userInfo.picture && userInfo.picture.length > 0
                  ? userInfo.picture
                  : undefined
              }
            />
          </Box>
          <ExpandMore />
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            right: "0px",
            top: "50px",
            zIndex: 9999,
            width: "250px",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "10px",
            }}
            in={show}
          >
            <Box sx={{ m: 1 }}>
              <Stack
                gap={0.5}
                sx={{
                  px: 2,
                  pt: 1,
                  pb: 2,
                  borderBottom: "1px solid #dadada",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {getUserInfo().userName}
                </Typography>
                <Typography sx={{ color: "var(--light-gray)", fontSize: 12 }}>
                  {`${getUserInfo().userTitle}, ${getUserInfo().role}`}
                </Typography>
                <Typography variant="body2" sx={{ color: "var(--primary)" }}>
                  {getUserInfo().companyName}
                </Typography>
                {/* <AtsRow gap={"10px"}>
                  <>
                    {fontSizeTypeData.map((val) => (
                      <Box
                        key={val.value}
                        sx={{
                          width: "33.33%",
                          py: "5px",
                          bgcolor:
                            fontSizeType === val.value
                              ? BG_COLORS.PRIMARY
                              : BG_COLORS.GRAY,
                          cursor: "pointer",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                        onClick={() => dispatch(setFontSizeType(val.value))}
                      >
                        <AtsTitleText
                          text={val.text}
                          fs={9}
                          fw={500}
                          textColor={
                            fontSizeType === val.value
                              ? COLORS.PRIMARY
                              : COLORS.BLACK
                          }
                        />
                      </Box>
                    ))}
                  </>
                </AtsRow> */}
              </Stack>
              <Stack
                gap={0.5}
                sx={{
                  // px: 2,
                  pt: 1,
                  pb: 2,
                  borderBottom: "1px solid #dadada",
                }}
              >
                {USER_ROUTES.map((route: RoutesProps, index: number) => (
                  <NavLink to={route.path} key={index} className="link2">
                    <AtsRow gap={"10px"}>
                      <>
                        <Box
                          sx={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {route.path === pathname
                            ? route.activIcon
                            : route.icon}
                        </Box>
                        <AtsTitleText
                          fs={14}
                          fw={500}
                          textColor="#171717"
                          text={route.label}
                        />
                      </>
                    </AtsRow>
                  </NavLink>
                ))}
              </Stack>
              <Stack
                gap={0.5}
                sx={{
                  // px: 2,
                  pt: 1,
                  pb: 2,
                }}
                onClick={onLogout}
              >
                <AtsRow gap={"10px"} className="link2">
                  <>
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LogoutIcon />
                    </Box>

                    <AtsTitleText
                      fs={14}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={"Log out"}
                    />
                  </>
                </AtsRow>
              </Stack>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default UserInfo;
