import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import AtsTextInput from "../../../../../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { ResponsibilitiesResponse } from "../../../../../../../../../types/onboardingTypes";
import FormWrapper from "./FormWrapper";
import axios from "axios";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { ArrowDown2, Edit2 } from "iconsax-react";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";

const ResponsibilitesStep = () => {
  const { contractorPlacmentDetails, isContractorOnboarded } = useAppSelector(
    getContractorDetailsState
  );
  const {
    createOnboardingState,
    handleCreateOnboardingState,
    setResponsibilitiesLoading,
    responsibilitiesLoading,
    handleEditState,
    editState,
  } = useOnboarding();

  const getResponsibilities = async (job_title) => {
    setResponsibilitiesLoading(true);
    try {
      const newMessage = {
        role: "user",
        // content: `Sample job posting for a ${postOpportunity?.job_title} job with detailed Job description, Key Responsibilities, Requirements/Skills and fix this text to be marked up with semantic HTML using only lists, headers, or paragraph tags and remove first paragaph, job title heading, job posting heading`,
        content: `Structured list of typical roles and responsibilities for a "${job_title}" position, laid out in headings and bullet points in Json array with keys category and responsibilities`,
      };
      const newMsg = [newMessage];
      const url = "https://api.openai.com/v1/chat/completions";
      // const token = `Bearer ${process.env.CHAT_GPT_API_KEY}`;
      const token = `Bearer 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`;
      const model = "gpt-4";
      const response = await axios.post(
        url,
        {
          model: model,
          messages: newMsg,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const content =
        response.data?.choices && response.data?.choices[0]?.message?.content;
      if (content) {
        handleCreateOnboardingState(
          "job_responsibilities",
          JSON.parse(content.trim())
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setResponsibilitiesLoading(false);
    }
  };
  useEffect(() => {
    contractorPlacmentDetails &&
      contractorPlacmentDetails.job_title.length &&
      !createOnboardingState.job_responsibilities.length &&
      getResponsibilities(contractorPlacmentDetails.job_title);
  }, []);

  const handleChange = (
    key: string,
    value: string | string[],
    index: number
  ) => {
    const preResp = [...createOnboardingState.job_responsibilities];
    const updatedRespo = {
      ...createOnboardingState.job_responsibilities[index],
      [key]: value,
    };
    preResp.splice(index, 1, updatedRespo);
    handleCreateOnboardingState("job_responsibilities", preResp);
    // setResponsibilities(preResp);
  };
  const handleResponsibilitiesChange = (
    response: ResponsibilitiesResponse,
    value: string,
    childIndex: number,
    index: number
  ) => {
    const reponString = [...response.responsibilities];
    reponString.splice(childIndex, 1, value);
    handleChange("responsibilities", reponString, index);
  };

  return (
    <>
      <FormWrapper
        heading="Responsibilities"
        renderRightComponent={
          isContractorOnboarded ? (
            <AtsRow gap={"10px"}>
              <>
                {editState.isEditResponsibilities ? (
                  <AtsRoundedButton
                    text="Save"
                    buttonHeight={25}
                    fs={12}
                    buttonVariant="darkGreenContained"
                    onClick={() =>
                      handleEditState("isEditResponsibilities", false)
                    }
                  />
                ) : (
                  <AtsRoundedButton
                    text="Edit"
                    startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                    buttonHeight={25}
                    fs={12}
                    buttonVariant="whiteContained"
                    onClick={() =>
                      handleEditState("isEditResponsibilities", true)
                    }
                  />
                )}
              </>
            </AtsRow>
          ) : (
            <></>
          )
        }
      >
        <Stack gap={"20px"} pt={"20px"}>
          {responsibilitiesLoading ? (
            <FormWrapper heading="">
              <Stack gap={"20px"}>
                <Stack gap={"4px"}>
                  <AtsTitleText text={"Role"} fs={12} fw={500} />
                  <Skeleton variant="rounded" width={"100"} height={37} />
                </Stack>
                <Stack gap={"4px"}>
                  <Stack gap={"3px"}>
                    <AtsTitleText text={"Responsibilities"} fs={12} fw={500} />
                    {Array.from(new Array(4)).map((_, idx) => (
                      <Skeleton
                        key={idx}
                        variant="rounded"
                        width={"100"}
                        height={37}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </FormWrapper>
          ) : (
            createOnboardingState.job_responsibilities.map((role, index) => (
              <Accordion
                disableGutters
                // expanded={expandedPanels.includes(index)}
                // onChange={handleChange}
                sx={{
                  "&.MuiPaper-root": {
                    boxShadow: "none !important",
                    borderBottom: BORDERS.GRAY,
                    backgroundColor: "transparent",
                    "::before": {
                      bgcolor: "transparent ",
                    },
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    px: 0,
                    "&.Mui-focusVisible": {
                      bgcolor: "transparent",
                    },
                  }}
                  expandIcon={<ArrowDown2 size="24" color={COLORS.BLACK} />}
                >
                  <AtsRow
                    sx={{
                      width: "100%",
                    }}
                    gap={"13px"}
                  >
                    <>
                      <Box>
                        <svg
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity=".3"
                            d="M26.936 6.24c3.29 3.68 1.178 10.938-4.72 16.21-5.897 5.274-13.345 6.566-16.636 2.886-3.291-3.68 2.901-13.824 8.798-19.097 5.898-5.274 9.267-3.68 12.558 0z"
                            fill="#D03660"
                          />
                          <path
                            d="m13.086 17.925 1.625 1.625 4.333-4.333"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.833 8.5h4.333c2.167 0 2.167-1.084 2.167-2.167 0-2.167-1.084-2.167-2.167-2.167h-4.333c-1.084 0-2.167 0-2.167 2.167 0 2.166 1.083 2.166 2.167 2.166z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.333 6.355c3.608.195 5.417 1.528 5.417 6.479v6.5c0 4.333-1.083 6.5-6.5 6.5h-6.5c-5.417 0-6.5-2.167-6.5-6.5v-6.5c0-4.94 1.81-6.284 5.417-6.479"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                      {isContractorOnboarded &&
                      editState.isEditResponsibilities ? (
                        <input
                          className="customInput"
                          type="text"
                          value={role.category}
                          onChange={(e) =>
                            handleChange("category", e.target.value, index)
                          }
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: COLORS.BLACK,
                            width: "100%",
                          }}
                        />
                      ) : (
                        <AtsTitleText text={role.category} fs={14} fw={600} />
                      )}
                    </>
                  </AtsRow>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    px: 0,
                  }}
                >
                  <Stack gap={"10px"} ml={"50px"}>
                    {role.responsibilities.map((value, idx) => (
                      <Stack
                        key={idx}
                        direction={"row"}
                        alignItems={"flex-start"}
                        gap={"5px"}
                      >
                        <Box
                          sx={{
                            mt: "5px",
                            minWidth: "5px",
                            minHeight: "5px",
                            bgcolor: COLORS.BLACK,
                            borderRadius: "50%",
                          }}
                        ></Box>
                        {isContractorOnboarded &&
                        editState.isEditResponsibilities ? (
                          <input
                            className="customInput"
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleResponsibilitiesChange(
                                role,
                                e.target.value,
                                idx,
                                index
                              )
                            }
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: COLORS.BLACK,
                              width: "100%",
                            }}
                          />
                        ) : (
                          <AtsTitleText
                            text={value}
                            fs={12}
                            fw={400}
                            lineHeight={1.5}
                          />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))
            // createOnboardingState.job_responsibilities.map(
            //   (response, index) => (
            //     <FormWrapper key={index} heading="">
            //       <Stack gap={"20px"}>
            //         <Stack gap={"4px"}>
            //           <AtsTitleText text={"Category"} fs={12} fw={500} />
            //           <AtsTextInput
            //             value={response.category}
            //             onChange={(e) =>
            //               handleChange("category", e.target.value, index)
            //             }
            //           />
            //         </Stack>
            //         <Stack gap={"4px"}>
            //           <Stack gap={"3px"}>
            //             <AtsTitleText
            //               text={"Responsibilities"}
            //               fs={12}
            //               fw={500}
            //             />
            //             {response.responsibilities.length > 0 &&
            //               response.responsibilities.map((item, idx) => (
            //                 <AtsTextInput
            //                   value={item}
            //                   onChange={(e) =>
            //                     handleResponsibilitiesChange(
            //                       response,
            //                       e.target.value,
            //                       idx,
            //                       index
            //                     )
            //                   }
            //                 />
            //               ))}
            //           </Stack>
            //         </Stack>
            //       </Stack>
            //     </FormWrapper>
            //   )
            // )
          )}
        </Stack>
      </FormWrapper>
    </>
  );
};

export default ResponsibilitesStep;
