import React from "react";
import Chart from "react-apexcharts";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";

interface DonutChartProps {
  title: string;
  donutHeight?: number;
  donutSize?: string;
  series: (number | null | undefined)[];
  labels: (string | null | undefined)[];
  colors?: string[];
  isDataLabelsShow?: boolean;
  isPieLabelsShow?: boolean;
}

const DonutChart: React.FC<DonutChartProps> = ({
  title,
  series,
  labels,
  colors = ["#3366cc", "#63abfd", "#2ba899"],
  isDataLabelsShow = false,
  isPieLabelsShow = false,
  donutSize = "70%",
  donutHeight = 200,
}) => {
  const sanitizedSeries =
    series?.filter((value) => typeof value === "number" && value !== null) ||
    [];
  const sanitizedLabels =
    labels?.filter(
      (label) => typeof label === "string" && label.trim() !== ""
    ) || [];

  if (
    sanitizedSeries.length === 0 ||
    sanitizedLabels.length === 0 ||
    sanitizedSeries.length !== sanitizedLabels.length
  ) {
    console.error("Invalid or mismatched series and labels provided:", {
      series,
      labels,
    });
    return (
      <>
        <AtsEmptyState
          textFs={12}
          imgHeight={50}
          text={`No ${title} data`}
          subText=""
        />
      </>
    );
  }
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "donut",
      height: donutHeight,
      fontFamily: "Inter",
    },
    labels: sanitizedLabels,
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: isDataLabelsShow,
      // formatter: (value) => {
      //   console.log("value", value);
      //   return `${value} %`;
      // },
    },
    stroke: {
      width: 0,
    },
    colors: colors,
    plotOptions: {
      pie: {
        donut: {
          size: donutSize,
          labels: {
            show: isPieLabelsShow,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: donutHeight,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div id="chart">
      <Chart
        options={options}
        series={sanitizedSeries}
        type="donut"
        height={donutHeight}
      />
    </div>
  );
};

export default DonutChart;
