import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { EditIcon } from "../../../assets/icons/Icons";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import AtsTextInput from "../../../components/AtsTextInput";
import { BG_COLORS, COLORS } from "../../../theme";
import { companyDetailsValidation } from "../../../utils/validationUtils";
import {
  CompanyAddressProps,
  UpdateCompanyInfoRequest,
} from "../../../types/companyTypes";
import { Grid } from "@mui/material";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import defaultImage from "../../../assets/images/projectImg.png";
import { CompanyServices } from "../../../Api/companyServices";
import { isNotNullOrUndefined } from "../../../utils/stringUtils";
import linkdinImg from "../../../assets/images/linkdin.jpg";
import AtsSelect from "../../../components/AtsSelect";
import { Edit2 } from "iconsax-react";
import AtsTextArea from "../../../components/AtsTextArea";

const CompanyDetails = () => {
  const {
    handleCompanyDetailsChange,
    setCompanyLogo,
    companyInfoState,
    companySize,
    companyInfoLoading,
    companyLogo,
    companyDetailsError,
    searchQuery,
    setSearchQuery,
  } = useCreateAccount();
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(50);
  const [imageURL, setImageURL] = useState(defaultImage);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileLength, setFileLength] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (companyInfoState?.company_address) {
      const address = companyInfoState.company_address as CompanyAddressProps;
      if (
        address?.locality ||
        address?.address_line1 ||
        address?.country ||
        address?.administrative_area ||
        address?.postal_code
      ) {
        setSearchQuery(
          `${address.address_line1} ${address.locality} ${address.administrative_area} ${address.country} ${address.postal_code}`
        );
      }
    }
  }, [companyInfoState?.company_address]);

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSearchQuery(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";
      let country_code = "";

      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
          country_code = component.short_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        }
      }
      const address = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
        country_code,
      } as CompanyAddressProps;
      handleCompanyDetailsChange("company_address", address);
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const renderTextInputComponent = (
    isEdit: boolean,
    title: string,
    value: string | number,
    key: keyof UpdateCompanyInfoRequest,
    handleChange: (key: keyof UpdateCompanyInfoRequest, value: string) => void,
    loading?: boolean
  ) => {
    return (
      <Stack gap={"5px"}>
        <AtsRow gap={"5px"}>
          <>
            {key === "linkedin" && (
              <img width={16} height={16} src={linkdinImg} alt="images" />
            )}
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
            >
              {title}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>
          </>
        </AtsRow>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {key === "company_address" ? (
            <Autocomplete
              onLoad={handleLoad}
              onPlaceChanged={handlePlaceSelect}
            >
              <AtsTextInput
                height="41px"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Autocomplete>
          ) : key === "company_size_id" ? (
            <AtsSelect
              placeholder="Select company size"
              selected={value}
              onSelect={(select: string) => handleChange(key, select)}
              options={companySize}
            />
          ) : key === "description" ? (
            <AtsTextArea
              placeholder={`Enter ${title}`}
              value={value as string}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          ) : (
            <AtsTextInput
              placeholder={`Enter ${title}`}
              height="41px"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          )}
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={companyDetailsError[key]}
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
    );
  };

  const handleBinaryUploadForLogo = async (file) => {
    if (!file) return;
    const allowedTypes = [
      "image/png",
      "image/gif",
      "image/jpg",
      "image/jpeg",
      "image/webp",
    ];
    if (!allowedTypes.includes(file.type)) {
      console.error(
        "Invalid file type. Only png, gif, jpg, jpeg, and webp are allowed."
      );
      return;
    }
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await CompanyServices.uploadLogoForCompany(
            arrayBuffer,
            file.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles) => {
    setFileLoading(true);
    try {
      const fileArray = Array.from(selectedFiles) as File[];
      setFileLength(fileArray?.length);
      const uploadChooseProjectImgPromises = fileArray.map(
        handleBinaryUploadForLogo
      );
      const resultsForProjectImg: any = await Promise.all(
        uploadChooseProjectImgPromises
      );

      const result = resultsForProjectImg.map((item) => {
        const fidValue = item?.fid[0]?.value;
        const fileNameValue = item?.uri[0]?.url;
        return fidValue && fileNameValue
          ? { fid: fidValue, image: fileNameValue }
          : null;
      });
      console.log("result", result);
      setCompanyLogo(result[0]);
    } catch (error) {
      setFileLoading(false);
    } finally {
      setFileLoading(false);
    }
    // if (fileArray.length > 0) {
    //   const file = fileArray[0];
    //   setImageURL(URL.createObjectURL(file));
    // }
  };

  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  if (!isLoaded) {
    return <Skeleton />;
  }
  return (
    <>
      <Stack gap={"20px"}>
        <AtsRow gap={"10px"}>
          <>
            <AtsTitleText
              text={"Company details"}
              textColor={"#1c1c1c"}
              fs={24}
              fw={600}
            />
            {/* {!isEdit && (
              <AtsRoundedButton
                text="Edit"
                startIcon={<EditIcon />}
                buttonVariant="whiteContained"
                buttonHeight={29}
                buttonWidth={65}
                onClick={() => setIsEdit(!isEdit)}
              />
            )} */}
          </>
        </AtsRow>
        <AtsTitleText
          text={
            "Please review and confirm that the account details entered below are correct before proceeding to the next step."
          }
          textColor={"#000"}
          fs={12}
          fw={400}
        />
      </Stack>
      <Box mt={"28px"}>
        <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Company name",
              isNotNullOrUndefined(companyInfoState)
                ? companyInfoState?.title
                : "",
              "title",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} alignItems={"start"} gap={"25px"}>
              {fileLoading ? (
                <Skeleton variant="rounded" width={71} height={61} />
              ) : companyLogo?.image ? (
                <Box
                  sx={{
                    width: 61,
                    height: 61,
                    borderRadius: "10px",
                    position: "relative",
                    border: "1px dashed #3366cc",
                    p: "1px",
                    // border: BORDERS.GRAY,
                  }}
                >
                  <img
                    style={{
                      width: 61,
                      height: 61,
                      // objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={companyLogo?.image}
                    alt=""
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -7,
                      right: -7,
                      padding: "5px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                      height: 14,
                      width: 14,
                      boxShadow: "0 4px 5px 0 rgba(0, 0, 0, 0.2)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      htmlFor="chooseVideo"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Edit2
                        size="16"
                        color={COLORS.PRIMARY}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <input
                        type="file"
                        name=""
                        accept="image/*"
                        id="chooseVideo"
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </label>
                  </Box>
                </Box>
              ) : (
                <Stack
                  gap={"5px"}
                  sx={{
                    minWidth: 61,
                    minHeight: 61,
                    borderRadius: "10px",
                    position: "relative",
                    border: "1px dashed #3366cc",
                    p: "1px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AtsIcon
                    icon="icon-gallery"
                    height="25px"
                    fs={25}
                    color={COLORS.LIGHT_GRAY}
                    fw={400}
                  />

                  <AtsTitleText
                    text={"Upload"}
                    fs={12}
                    fw={600}
                    textColor={COLORS.PRIMARY}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -7,
                      right: -7,
                      padding: "5px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                      height: 14,
                      width: 14,
                      boxShadow: "0 4px 5px 0 rgba(0, 0, 0, 0.2)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      htmlFor="chooseVideo"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Edit2
                        size="16"
                        color={COLORS.PRIMARY}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <input
                        type="file"
                        name=""
                        accept="image/*"
                        id="chooseVideo"
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </label>
                  </Box>
                </Stack>
              )}
              <Stack gap={"9px"}>
                <AtsTitleText text={"Company logo"} fs={14} fw={600} />
                <AtsTitleText
                  text={
                    "Image size: 300x300px recommended. PNG, JPEG, JPG supported."
                  }
                  fs={10}
                  fw={400}
                  textColor="#7f7f7f"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            {renderTextInputComponent(
              isEdit,
              "Company description",
              isNotNullOrUndefined(companyInfoState)
                ? companyInfoState?.description
                : "",
              "description",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Company LinkedIn profile",
              isNotNullOrUndefined(companyInfoState)
                ? companyInfoState?.linkedin
                : "",
              "linkedin",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Company website",
              isNotNullOrUndefined(companyInfoState)
                ? companyInfoState?.website
                : "",
              "website",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Company phone no.",
              isNotNullOrUndefined(companyInfoState)
                ? companyInfoState?.phone
                : "",
              "phone",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "No. of employees",
              isNotNullOrUndefined(companyInfoState)
                ? companyInfoState?.company_size_id
                : "",
              "company_size_id",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            {renderTextInputComponent(
              isEdit,
              "Company address",
              isNotNullOrUndefined(companyInfoState) ? searchQuery : "",
              "company_address",
              handleCompanyDetailsChange,
              companyInfoLoading
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompanyDetails;
