import { Circle, MoreVert } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Edit2 } from "iconsax-react";
import { useEffect, useState } from "react";
import { ProjectService } from "../../../../Api/projectServices";
import AtsCollapse from "../../../../components/AtsCollapse";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsIcon from "../../../../components/AtsIcon";
import AtsTitleText from "../../../../components/AtsTitleText";
import { getAuthState } from "../../../../redux/slices/authSlice";
import { setCreateProjectState } from "../../../../redux/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { BG_COLORS, BORDERS, BOX_SHADOW, COLORS } from "../../../../theme";
import {
  ProjectListProps,
  ProjectStatsParams,
} from "../../../../types/projectTypes";
import { useClickOutSide } from "../../../../utils/common";
import FontUtil from "../../../../utils/FontUtil";
import CreateProjectDrawer from "../../../PostOpportunity/module/CreateProjectDrawer";
import "../../css/project.css";
import UserImageForProject from "./UserImageForProject";
import { InvoiceServices } from "../../../../Api/invoiceServices";

export const projectDetails = [
  {
    title: "Active Jobs",
    value: "52",
  },
  {
    title: "New Submissions",
    value: "144",
  },
  {
    title: "Invoices",
    value: "12",
  },
  {
    title: "Contractors",
    value: "40",
  },
];

interface Projectstats {
  title: string;
  value: number;
}

interface ProjectProps {
  projectData?: ProjectListProps;
  minHeight?: number;
  isImagesShow?: boolean;
  isEdit?: boolean;
  loading?: boolean;
  progress: number;
  image: string;
  projectName: string;
  location: string;
  pNumber: string;
  description: string;
  projectId: string;
  teamMember?: string[];
  onClick?: () => void;
  getProjects?: () => void;
}

const ProjectCard = ({
  projectData,
  minHeight,
  isImagesShow,
  isEdit,
  progress,
  image,
  projectName,
  location,
  pNumber,
  description,
  projectId,
  loading,
  teamMember,
  onClick,
  getProjects,
}: ProjectProps) => {
  const dispatch = useAppDispatch();
  const { font12 } = FontUtil();
  const { userInfo } = useAppSelector(getAuthState);
  const [projectStatsCount, setProjectStatsCount] = useState<Projectstats[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showCreateProjectDrawer, setShowCreateProjectDrawer] =
    useState<boolean>(false);
  const [desc, setDesc] = useState<string>(description);
  const [show, setShow] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  const toggleCollapse = () => {
    dispatch(setCreateProjectState(projectData));
    setShow(!show);
  };

  const toggleDrawer = () => {
    setShowCreateProjectDrawer(!showCreateProjectDrawer);
    // dispatch(setCreateProjectState(initailCreateProjectState));
  };

  const getSubDesc = () => {
    if (showMore) {
      setDesc(description);
    } else if (description.length > 200) {
      const subDes = description.substring(0, 200);
      setDesc(`${subDes}...`);
    } else {
      setDesc(description);
    }
  };

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const getProjectStatsCount = async () => {
    setIsLoading(true);
    try {
      const projectStatsParams = {
        company_id: userInfo?.company?.id,
        project_id: projectId,
      } as ProjectStatsParams;
      const response = await ProjectService.getProjectState(projectStatsParams);
      const invoiceCountresponse = await InvoiceServices.getInvoiceStats({
        project_id: projectId,
      });

      const invoiceCount =
        invoiceCountresponse && invoiceCountresponse.length
          ? invoiceCountresponse[0].count
          : "0";

      const projectDetails = [
        {
          title: "Active Jobs",
          value: response?.jobs_count ?? 0,
        },
        {
          title: "New Submissions",
          value: response?.submission_count ?? 0,
        },
        {
          title: "Invoices",
          value: Number(invoiceCount) ?? 0,
        },
        {
          title: "Contractors",
          value: response?.contractors_count ?? 0,
        },
      ];
      setProjectStatsCount(projectDetails);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    projectId.length && getProjectStatsCount();
  }, []);

  useEffect(() => {
    getSubDesc();
  }, [showMore, description]);

  return (
    <>
      <Box
        sx={{
          minHeight: minHeight ?? 295,
          borderRadius: "10px",
          padding: "20px 9.4px 19px 20px",
          bgcolor: "white",
          boxShadow: BOX_SHADOW.GRAY,
          border: BORDERS.GRAY,
          cursor: "pointer",
        }}
      >
        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
            <Box
              sx={{
                width: 50,
                height: 50,
              }}
            >
              {loading ? (
                <Skeleton variant="circular" width={50} height={50} />
              ) : (
                <img
                  width={"100%"}
                  style={{ borderRadius: "50%" }}
                  src={
                    image.length
                      ? image
                      : require("../../../../assets/images/projectImg.png")
                  }
                  alt=""
                />
              )}
            </Box>
          </Stack>
          <Stack
            sx={{
              flexGrow: 1,
            }}
          >
            {loading ? (
              <Stack>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
              >
                <Stack gap={"9px"}>
                  <AtsTitleText
                    text={projectName}
                    fs={20}
                    fw={600}
                    textColor="#000"
                  />
                  <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                    <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                      <AtsIcon
                        icon="icon-document-text"
                        fs={16}
                        height="18px"
                        color={COLORS.LIGHT_GRAY}
                      />
                      <AtsTitleText
                        text={`P-${pNumber}`}
                        fs={14}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </Stack>
                    <Circle sx={{ fontSize: 5, color: "lightgray" }} />
                    <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                      <AtsIcon
                        icon="icon-location"
                        fs={16}
                        height="18px"
                        color={COLORS.LIGHT_GRAY}
                      />
                      <AtsTitleText
                        text={location}
                        fs={14}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  pr={!isEdit ? "10px" : "0px"}
                  direction={"row"}
                  alignItems={"flex-start"}
                  gap={"12px"}
                >
                  {!isImagesShow ? (
                    <Stack>
                      <UserImageForProject project_id={projectId} />
                    </Stack>
                  ) : (
                    <Skeleton variant="rectangular" width={100} height={50} />
                  )}
                  {isEdit && (
                    <Box ref={domNode} sx={{ position: "relative" }}>
                      <IconButton
                        color="inherit"
                        onClick={() => toggleCollapse()}
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                      <Box
                        sx={{
                          position: "absolute",
                          bgcolor: "white",
                          right: "0px",
                          top: "30px",
                          zIndex: 1,
                          width: "fit-content",
                          borderRadius: "6px",
                        }}
                      >
                        <AtsCollapse in={show}>
                          <Box
                            sx={{
                              border: BORDERS.GRAY,
                              borderRadius: "6px",
                              boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                              padding: "10px",
                              bgcolor: "white",
                            }}
                          >
                            <Stack gap={1}>
                              <AtsCustomButton
                                width={"auto"}
                                hoverColor={BG_COLORS.PRIMARY}
                                onClick={toggleDrawer}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={1}
                                >
                                  <Edit2 size={12} />
                                  <AtsTitleText text="Edit" fs={12} fw={600} />
                                </Stack>
                              </AtsCustomButton>
                            </Stack>
                          </Box>
                        </AtsCollapse>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </Stack>
            )}
            <Stack gap={"9px"} mt={"35px"}>
              {loading ? (
                <Stack>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="rectangular" width={"94%"} height={40} />
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  sx={{
                    width: "94%",
                  }}
                >
                  <Stack
                    sx={{
                      flex: 0.9,
                    }}
                    gap={"9px"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                      <AtsIcon
                        icon="icon-info-circle"
                        color={COLORS.PRIMARY}
                        height="18px"
                      />
                      <AtsTitleText
                        text={"Description"}
                        textColor={COLORS.PRIMARY}
                        fs={14}
                        fw={600}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        height: showMore ? "auto" : 63,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: font12,
                          fontWeight: 400,
                          lineHeight: "21px",
                        }}
                      >
                        {desc}
                        {"  "}
                        {description.length > 200 &&
                          (showMore ? (
                            <span
                              style={{
                                color: COLORS.PRIMARY,
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={handleToggle}
                            >
                              Read Less
                            </span>
                          ) : (
                            <span
                              style={{
                                color: COLORS.PRIMARY,
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={handleToggle}
                            >
                              Read More
                            </span>
                          ))}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack gap={"12px"}>
                    <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                      <AtsIcon
                        icon="icon-status-up"
                        color={COLORS.PRIMARY}
                        height="18px"
                      />
                      <AtsTitleText
                        text={"Progress"}
                        textColor={COLORS.PRIMARY}
                        fs={14}
                        fw={600}
                      />
                    </Stack>
                    <CustomCircularProgress progress={progress} />
                  </Stack>
                </Stack>
              )}
              <Stack
                mt={"20px"}
                mr={"10.6px"}
                direction={"row"}
                sx={{
                  padding: "15px 19px 9px",
                  bgcolor: "#f0f5ff",
                  borderRadius: "8px",
                }}
              >
                {isLoading
                  ? projectDetails.map((item, index) => (
                      <Stack key={index} gap={"6px"} sx={{ flex: 1 }}>
                        <AtsTitleText
                          text={item.title}
                          fw={400}
                          fs={12}
                          textColor={COLORS.LIGHT_GRAY}
                        />

                        <Skeleton width={50} height={20} />
                      </Stack>
                    ))
                  : projectStatsCount.map((item, index) => (
                      <Stack key={index} gap={"6px"} sx={{ flex: 1 }}>
                        <AtsTitleText
                          text={item.title}
                          fw={400}
                          fs={12}
                          textColor={COLORS.LIGHT_GRAY}
                        />

                        <AtsTitleText
                          text={item.value}
                          fw={400}
                          fs={14}
                          textColor={COLORS.BLACK}
                        />
                      </Stack>
                    ))}
              </Stack>
              {onClick && !loading && (
                <Stack
                  mt={"11px"}
                  mr={"10.6px"}
                  direction={"row"}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <AtsCustomButton hoverColor={"transparent"} onClick={onClick}>
                    <AtsTitleText
                      text={"View Details"}
                      fw={600}
                      fs={12}
                      textColor={COLORS.PRIMARY}
                    />
                  </AtsCustomButton>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Drawer
        PaperProps={{
          sx: {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            width: "500px",
            // overflow: "hidden",
          },
        }}
        anchor="right"
        open={showCreateProjectDrawer}
        onClose={toggleDrawer}
      >
        <CreateProjectDrawer
          toggleDrawer={toggleDrawer}
          fetchMyProject={getProjects}
          isUpdate
        />
      </Drawer>
    </>
  );
};

export default ProjectCard;

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: 40,
        height: 40,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#e0e0e0 !important",
          "& .MuiCircularProgress-circle": {
            stroke: "#e0e0e0", // Unfilled (background) color
          },
          "& .MuiCircularProgress-circleDeterminate": {
            stroke: "#3c854d", // Unfilled (background) color
          },
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const CustomCircularProgress = ({
  progress = 0,
  size = 42,
  strokeWidth = 4,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg width={size} height={size} className="circular-progress">
      <circle
        className="circular-progress-background"
        stroke="lightgray"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="circular-progress-bar"
        stroke="#3c854d"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
      <g
        dominantBaseline="middle"
        textAnchor="middle"
        transform={`rotate(90 ${size / 2} ${size / 2})`}
      >
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={12}
          fill="#231f20"
        >
          {Math.round(progress)}%
        </text>
      </g>
    </svg>
  );
};
