import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { RiInboxArchiveLine } from "react-icons/ri";
import { TiPinOutline } from "react-icons/ti";
import { ApproveIcon } from "../../../assets/icons/Icons";
import AtsCard from "../../../components/AtsCard";
import AtsIcon from "../../../components/AtsIcon";
import AtsLabel from "../../../components/AtsLabel";
import AtsText from "../../../components/AtsText";
import { BORDERS } from "../../../theme";
import { UpdateRemindersRequest } from "../../../types/reminderTypes";
import { useClickOutSide } from "../../../utils/common";
import FontUtil from "../../../utils/FontUtil";
import useResponsiveWidth from "../../../hooks/useResponsiveWidth";

interface ITaskProps {
  index: string;
  status: string;
  taskDes: string;
  dueTime: string;
  onClose: (index: string) => void;
  onClick: (keyVal: keyof UpdateRemindersRequest, nid: string) => void;
}

const TaskCard = ({
  index,
  status,
  taskDes,
  dueTime,
  onClose,
  onClick,
}: ITaskProps) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  const { font11, font12, font16, font10 } = FontUtil();
  const [show, setShow] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  const onSelectTask = () => {
    setShow(!show);
  };

  const cardWith = useResponsiveWidth({
    mobileWidth: 190,
    tabletWidth: 190,
    desktopWidth: 190,
    largeScreenWidth: 195,
  });

  return (
    <>
      <AtsCard
        key={index}
        sx={{
          minHeight: 104,
          width: cardWith,
        }}
      >
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <AtsLabel
              text={status}
              variant={status === "pending" ? "warninig" : "success"}
            />
            {status !== "closed" && (
              <Box
                ref={domNode}
                sx={{ position: "relative", cursor: "pointer" }}
              >
                <Box onClick={() => onSelectTask()}>
                  <MoreVert sx={{ ...font16 }} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bgcolor: "white",
                    right: "0px",
                    top: "20px",
                    zIndex: 9999,
                    width: "100px",
                  }}
                >
                  <Collapse
                    sx={{
                      boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                      borderRadius: "6px",
                    }}
                    in={show}
                  >
                    <Box
                      sx={{
                        border: BORDERS.GRAY,
                        borderRadius: "6px",
                        boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                        padding: "10px",
                        bgcolor: "white",
                      }}
                    >
                      <Stack gap={1}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={1}
                          onClick={() => onClick("pin_it", index)}
                        >
                          <TiPinOutline />
                          <AtsText
                            text="Pin it"
                            sx={{ ...font10, fontWeight: 600 }}
                          />
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={1}
                          onClick={() => onClick("assignees", index)}
                        >
                          <AtsIcon
                            icon="icon-profile-add"
                            width="14px"
                            height="14px"
                            color="black"
                          />
                          <AtsText
                            text="Assign to"
                            sx={{ ...font10, fontWeight: 600 }}
                          />
                        </Stack>
                        <Stack
                          pb={1}
                          direction={"row"}
                          alignItems={"center"}
                          gap={1}
                          sx={{ borderBottom: BORDERS.LIGHT_GRAY }}
                          onClick={() => onClick("archive", index)}
                        >
                          <Box mr={"2px"}>
                            <RiInboxArchiveLine fontSize={14} />
                          </Box>
                          <AtsText
                            text="Archive"
                            sx={{ ...font10, fontWeight: 600 }}
                          />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <AtsIcon icon="icon-trash" />

                          <AtsText
                            text="Delete"
                            sx={{ ...font10, fontWeight: 600 }}
                          />
                        </Stack>
                      </Stack>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            )}
          </Stack>
          <Stack
            pt={1}
            pb={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ borderBottom: BORDERS.LIGHT_GRAY }}
          >
            <Tooltip title={taskDes}>
              <Stack sx={{ minHeight: 32 }}>
                <AtsText
                  text={taskDes}
                  className="truncate_two_line"
                  sx={{
                    ...font12,
                    fontWeight: 600,
                    lineHeight: 1.33,
                    width: "153px",
                    textDecoration: status === "closed" ? "line-through" : "",
                    color: status === "closed" ? "var(--text-gray)" : "black",
                  }}
                />
              </Stack>
            </Tooltip>
            {status === "closed" ? (
              <ApproveIcon />
            ) : (
              <Tooltip title={"Close"}>
                <Stack
                  sx={{
                    height: "17px",
                    width: "17px",
                    flexGrow: 0,
                    border: BORDERS.GRAY,
                    borderRadius: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => onClose(index)}
                ></Stack>
              </Tooltip>
            )}
          </Stack>
          <Stack gap={0.5} pt={0.5} direction={"row"} alignItems={"center"}>
            <AtsText
              text="Due On:"
              sx={{ color: "var(--text-gray)", ...font11, fontWeight: 400 }}
            />
            <AtsText text={dueTime} sx={{ ...font11, fontWeight: 400 }} />
          </Stack>
        </Stack>
      </AtsCard>
    </>
  );
};

export default TaskCard;
