import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { JobStatisticsResponse } from "../../types/jobsTypes";

export const initialCountDetails = {
  nid: "",
  submissions_count: 0,
  interview_count: 0,
  screened_count: 0,
  shortlist_count: 0,
  offer_extented_count: 0,
  offer_accepted_count: 0,
};

interface JobState {
  statisticsCount: JobStatisticsResponse;
  selectedJobTab: string;
  selectedJob: string;
}

const initialState: JobState = {
  statisticsCount: initialCountDetails,
  selectedJobTab: "MY",
  selectedJob: "",
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setStatisticsCount: (state: JobState, action) => {
      const { payload } = action;
      state.statisticsCount = payload;
    },
    setSelectedJobTab: (state: JobState, action) => {
      const { payload } = action;
      state.selectedJobTab = payload;
    },
    setSelectedJob: (state: JobState, action) => {
      const { payload } = action;
      state.selectedJob = payload;
    },
  },
});

export const { setStatisticsCount, setSelectedJobTab, setSelectedJob } =
  jobSlice.actions;
export const getJobState = (state: RootState) => state.job;
export default jobSlice.reducer;
