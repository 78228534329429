import React from "react";
import AtsRow from "../../components/AtsRow";
import CreateAccountStepper from "./modules/CreateAccountStepper";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { BG_COLORS } from "../../theme";
import CreateAccountWrapper from "./modules/CreateAccountWrapper";

const CreateAccount = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          height: "100%",
        }}
      >
        <>
          <AtsRow
            sx={{
              height: "100%",
              minWidth: !isMobile ? "456px" : "0px",
              bgcolor: BG_COLORS.PRIMARY,
              display: isMobile ? "none" : "",
            }}
          >
            <Box
              sx={{
                pl: "80px",
                pr: "50px",
              }}
            >
              <CreateAccountStepper />
            </Box>
          </AtsRow>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <CreateAccountWrapper />
          </Box>
        </>
      </Stack>
    </Box>
  );
};

export default CreateAccount;
