import { Box } from "@mui/material";
import AtsPageContainer from "../../components/AtsPageContainer";
import { BillingTabsEnums } from "../../providers/BillingProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { BORDERS } from "../../theme";
import InvoiceDetails from "../Projects/modules/ProjectDetailsModules/Invoices/modules/InvoiceDetails";
import BillingPaymentDetails from "./modules/BillingPaymentDetails";
import BillingTabs from "./modules/BillingTabs";
import InvoicesList from "./modules/InvoicesList";

const Billing = () => {
  const { selectedBillingTab, isInvoiceView } = useInvoices();

  return (
    <AtsPageContainer>
      <>
        {isInvoiceView ? (
          <InvoiceDetails bottom={-70} />
        ) : (
          <>
            <BillingTabs />

            <Box
              sx={{
                height: "calc(100% - 100px)",

                bgcolor: "white",
                border: BORDERS.GRAY,
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  height: "90%",
                  overflow: "auto",
                  px: "38px",
                  mt: "30px",
                  mb: "46px",
                }}
              >
                <InvoicesList />
                {selectedBillingTab === BillingTabsEnums.BillingDetails && (
                  <Box
                    sx={{
                      pt: "60px",
                    }}
                  >
                    <BillingPaymentDetails />
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </>
    </AtsPageContainer>
  );
};

export default Billing;
