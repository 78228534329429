import { RadioGroup, Stack } from "@mui/material";
import React from "react";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";
import AtsRadioButton from "../../../components/AtsRadioButton";
import AtsTitleText from "../../../components/AtsTitleText";

interface Props {
  checked: boolean;
  title: string;
  sideTitle?: string;
  onSelect: () => void;
}

const RadioInput = ({ checked, title, sideTitle, onSelect }: Props) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"14px"}
        sx={{
          border: checked ? BORDERS_2X.PRIMARY : BORDERS.GRAY,
          borderRadius: 2,
          px: "15px",
          py: "13px",
          cursor: "pointer",
        }}
        onClick={onSelect}
      >
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          value={title}
        >
          <AtsRadioButton checked={checked} />
        </RadioGroup>

        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <AtsTitleText text={title} fs={16} fw={400} />
          {sideTitle && (
            <AtsTitleText
              text={sideTitle}
              fs={16}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default RadioInput;
