import { Dialog, Stack } from "@mui/material";
import { useMessages } from "../../providers/MessagesProvider";
import { isNotNullOrUndefined } from "../../utils/stringUtils";
import DetailsDrawer from "./DetailsDrawer";
import ChatRoom from "./modules/ChatRoom/ChatRoom";
import MessageLeftComponent from "./modules/MessageLeftside/MessageLeftComponent";
import { useMemo } from "react";
import NewMultipleChatRoom from "./modules/ChatRoom/NewMultipleChatRoom";
import { useCallingStore } from "../../providers/CallingProvider";
import VideoCallingBox from "./modules/ContractorDetails/components/VideoCallingBox";

const Messaging = () => {
  const {
    messenger,
    showNewMessage,
    showProjectDetails,
    showContractorDetails,
  } = useMessages();
  const { openCallingModal } = useCallingStore();

  const detailsMemo = useMemo(() => <DetailsDrawer />, []);

  return (
    <>
      <Stack direction={"row"} height={"100%"}>
        <MessageLeftComponent />
        {showNewMessage ? <NewMultipleChatRoom /> : <ChatRoom />}

        {!showNewMessage &&
        (showProjectDetails || showContractorDetails) &&
        isNotNullOrUndefined(messenger)
          ? detailsMemo
          : ""}
      </Stack>
      <Dialog open={openCallingModal} fullScreen>
        <VideoCallingBox />
      </Dialog>
    </>
  );
};

export default Messaging;
