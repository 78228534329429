import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { getProjectDetailsState } from "../../../../../../redux/slices/projectDetailsSlice";
import { useAppSelector } from "../../../../../../redux/store";
import DocsAccordion from "../components/DocsAccordion";
import { DocsProps } from "../../../../../../types/projectTypes";
import { ProjectService } from "../../../../../../Api/projectServices";

const HeathAndSafety = () => {
  const { projectDocuments, selectedProjectDetailsTab, projectDocsList } =
    useAppSelector(getProjectDetailsState);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [policyDocs, setPolicyDocs] = useState<DocsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setExpandedPanels([]);
  }, [selectedProjectDetailsTab]);

  const isDocs = (id: string) => {
    return projectDocsList?.some((docs) => docs.document_type_id === id);
  };

  useEffect(() => {
    const filteredProjectDocs = projectDocuments
      .filter((docs) => docs.parent_id === selectedProjectDetailsTab.id)
      .map((docs) => {
        if (isDocs(docs.id)) {
          const findList = projectDocsList.find(
            (document) => document.document_type_parent_id === docs.parent_id
          );
          const attachments = findList.attachments;
          const document_urls = findList.document_urls;
          const nid = findList.nid;
          return {
            ...docs,
            attachments,
            document_urls,
            nid,
          };
        } else {
          return {
            ...docs,
            attachments: [],
            document_urls: [],
            nid: "",
          };
        }
      });
    setPolicyDocs(filteredProjectDocs);
  }, []);

  const handleChange = (panelId) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedPanels((prevExpanded) => [...prevExpanded, panelId]);
    } else {
      setExpandedPanels((prevExpanded) =>
        prevExpanded.filter((id) => id !== panelId)
      );
    }
  };

  const handleBinaryUpload = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await ProjectService.uploadDocumentForProject(
            arrayBuffer,
            file?.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        } finally {
          setLoading(false);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles, index) => {
    setLoading(true);
    const fileArray = Array.from(selectedFiles);

    const uploadRequirmentPromises = fileArray.map(handleBinaryUpload);
    const resultsForDoc: any = await Promise.all(uploadRequirmentPromises);
    const file = resultsForDoc.map((item) => ({
      fid: item?.fid[0]?.value || null,
      url: item?.uri[0]?.value || "",
    }));
    const prePolicyDocs = [...policyDocs];
    const policy = { ...policyDocs[index] };

    const updatePolicy = {
      ...policy,
      attachments: [...policy.attachments, ...file],
    } as DocsProps;
    prePolicyDocs.splice(index, 1, updatePolicy);
    setPolicyDocs(prePolicyDocs);
    setLoading(false);
  };

  const handleFileChange = (e, index) => {
    handleFiles(e.target.files, index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files, index);
  };

  return (
    <>
      <Stack gap={"10px"} pb={"40px"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText
            text={selectedProjectDetailsTab.name}
            fs={20}
            fw={600}
          />
        </Stack>
        <div
          className="documents"
          dangerouslySetInnerHTML={{
            __html: selectedProjectDetailsTab.description,
          }}
        ></div>
        <Box>
          {/* {policyDocs.map((docs, index) => {
            return (
              <DocsAccordion
                key={docs.id}
                documents={docs}
                expanded={expandedPanels.includes(docs.id)}
                handleChange={handleChange(docs.id)}
                handleDrop={(e) => handleDrop(e, index)}
                handleFileChange={(e) => handleFileChange(e, index)}
                loading={loading}
              />
            );
          })} */}
        </Box>
      </Stack>
    </>
  );
};

export default HeathAndSafety;
