import { Avatar, Box } from "@mui/material";
import AtsCustomButton from "../../../../../components/AtsCustomButton";
import AtsRow from "../../../../../components/AtsRow";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { useMessages } from "../../../../../providers/MessagesProvider";
import { MultiUserIcon } from "../../../../../assets/icons/Icons";
import AtsRowBetween from "../../../../../components/AtsRowBetween";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";
import { BORDERS } from "../../../../../theme";

const ChatRoomTopSection = () => {
  const {
    handleShowContractorDetails,
    handleShowProjectDetails,
    messengerState,
    messenger,
  } = useMessages();

  const onClickMessenger = () => {
    if (messenger?.isGroupChat) {
      handleShowProjectDetails(true);
    } else {
      handleShowContractorDetails(true);
    }
  };
  return (
    <AtsRowBetween>
      <>
        <AtsRow gap={"18px"}>
          <>
            {messenger?.isGroupChat ? (
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  bgcolor: "#d9d9d9",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MultiUserIcon />
              </Box>
            ) : messengerState?.image ? (
              <Avatar src={messengerState?.image} />
            ) : (
              <Avatar />
            )}
            <AtsCustomButton px={0} py={0} onClick={onClickMessenger}>
              <AtsTitleText
                text={
                  messenger?.isGroupChat
                    ? messenger?.chatName
                    : messengerState?.name
                }
                fs={18}
                fw={600}
              />
            </AtsCustomButton>
          </>
        </AtsRow>
        {messenger?.isGroupChat && (
          <AtsRow
            sx={{
              border: BORDERS.GRAY,
              borderRadius: "60px",
            }}
          >
            <>
              <div className="avatar-group">
                {messenger?.participants.length > 0 &&
                  messenger?.participants.map((item, index) => {
                    const userData = isNotNullOrUndefined(item.user)
                      ? item.user
                      : null;
                    const image =
                      isNotNullOrUndefined(userData.picture) &&
                      userData?.picture?.length
                        ? userData?.picture
                        : "https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";
                    return (
                      <div key={item.id} className="avatar">
                        <img src={image} alt={`Image1${index}`} />
                      </div>
                    );
                  })}
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AtsTitleText text={messenger?.participants.length} />
                </Box>
              </div>
            </>
          </AtsRow>
        )}
      </>
    </AtsRowBetween>
  );
};

export default ChatRoomTopSection;
