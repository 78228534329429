import { Box, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { JobsService } from "../../Api/jobsListingServices";
import AtsIcon from "../../components/AtsIcon";
import AtsTitleText from "../../components/AtsTitleText";
import {
  getJobState,
  setSelectedJob,
  setSelectedJobTab,
} from "../../redux/slices/jobSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { BORDERS, COLORS } from "../../theme";
import { JobListingResponse } from "../../types/jobsTypes";
import JobsSwitch from "../Dashboard/PostedJobs/JobsSwitch";
import Job from "./Job";
import JobDetails from "./JobDetails";
import { useDebounce } from "../../hooks/useDebounce";
import JobDetailsLoadingPage from "./JobDetailsLoadingPage";
import JobCardLoading from "./JobCardLoading";
import AtsEmptyState from "../../components/AtsEmptyState";

const JOBS = [
  {
    title: "My Jobs",
    type: "MY",
  },
  {
    title: "All Jobs",
    type: "ALL",
  },
  {
    title: "Archived Jobs",
    type: "ARCHIVE",
  },
];

const Jobs = () => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("xxl"));
  const jobListRef = useRef(null);
  const dispatch = useAppDispatch();
  const { selectedJobTab, selectedJob } = useAppSelector(getJobState);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [jobList, setJobList] = useState<JobListingResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== jobList.length) {
        setPage(page + 1);
      }
    }
  };

  const fetchJobList = useMemo(() => {
    return (
      <div
        ref={jobListRef}
        onScroll={loading ? () => {} : loadMoreItems}
        className="scrollHide"
        style={{
          overflowX: "scroll",
          height: "100%",
          width: "100%",
        }}
      >
        <Stack
          gap={1}
          sx={{
            // height: "100%",
            pr: 2,
            pb: "10px",
          }}
        >
          {jobList.map((job, index) => (
            <Job
              loading={loading}
              key={job.nid}
              jobData={job}
              onSelect={() => dispatch(setSelectedJob(job.nid))}
              selectedJob={selectedJob}
            />
          ))}
        </Stack>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedJobTab, jobList, loading, selectedJob]);

  const jobDetailsComponent = useMemo(() => {
    return (
      <Box
        className="scrollHide"
        sx={{
          overflowX: "scroll",
          height: "100%",
          flex: 1,
        }}
      >
        <JobDetails selectedJob={selectedJob} />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  const getJobs = async () => {
    try {
      setLoading(true);
      const requestObj = {
        page,
        ...(search.length && { title: search }),
      };
      const response =
        selectedJobTab === "MY"
          ? await JobsService.getJobList(requestObj)
          : selectedJobTab === "ALL"
          ? await JobsService.getJobListForAllJobs(requestObj)
          : await JobsService.getJobListForArchived(requestObj);

      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setJobList(response.results);
          selectedJob === "" &&
            dispatch(setSelectedJob(response.results[0].nid));
        } else {
          setJobList((prevList) => [...prevList, ...response.results]);
        }
      } else {
        dispatch(setSelectedJob(""));
        setJobList([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const firstGetJobs = async () => {
    try {
      setFirstLoading(true);
      const requestObj = {
        page,
        ...(search.length && { title: search }),
      };
      const response =
        selectedJobTab === "MY"
          ? await JobsService.getJobList(requestObj)
          : selectedJobTab === "ALL"
          ? await JobsService.getJobListForAllJobs(requestObj)
          : await JobsService.getJobListForArchived(requestObj);

      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setJobList(response.results);
          selectedJob === "" &&
            dispatch(setSelectedJob(response.results[0].nid));
        } else {
          setJobList((prevList) => [...prevList, ...response.results]);
        }
      } else {
        dispatch(setSelectedJob(""));
        setJobList([]);
      }

      setFirstLoading(false);
    } catch (error) {
      setFirstLoading(false);
    }
  };
  useEffect(() => {
    page !== 0 && getJobs();
  }, [page, selectedJobTab, debouncedSearch]);
  useEffect(() => {
    page === 0 && firstGetJobs();
  }, [page, selectedJobTab, debouncedSearch]);

  useEffect(() => {
    if (jobListRef.current) {
      jobListRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [selectedJobTab]);

  return (
    <div
      className="main-container"
      style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          mb: 4,
        }}
      >
        <Stack sx={{ mb: 1.5 }}>
          <AtsTitleText
            text="All Jobs"
            fs={24}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Stack
            sx={{ pt: "22px", pb: "19px" }}
            direction={"row"}
            alignItems={"center"}
          >
            <JobsSwitch
              jobs={JOBS}
              active={selectedJobTab}
              onChange={(value) => {
                dispatch(setSelectedJobTab(value));
                setPage(0);
                dispatch(setSelectedJob(""));
              }}
            />
            <Stack
              gap={0.2}
              direction={"row"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
            ></Stack>
          </Stack>
          {jobList.length > 0 && (
            <Stack
              direction={"row"}
              gap={2}
              alignItems={"center"}
              sx={{
                maxWidth: 305,
                minHeight: 28,
              }}
            >
              <Stack sx={{ width: "40%" }}>
                <AtsTitleText
                  text={`${totalItem ?? 0} Jobs`}
                  fs={14}
                  fw={600}
                  textColor={COLORS.BLACK}
                />
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                width={"60%"}
              >
                <AnimatePresence>
                  {showInput ? (
                    <Stack
                      direction={"row"}
                      gap={1}
                      sx={{
                        width: "100%",
                        borderRadius: "20px",
                        border: BORDERS.GRAY,
                        bgcolor: "white",
                        pl: 1,
                        height: 25,
                      }}
                    >
                      <input
                        type="text"
                        className="customInput"
                        style={{
                          width: "80%",
                        }}
                        value={search}
                        onChange={(e) => {
                          setPage(0);
                          setSearch(e.target.value);
                        }}
                      />
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        mt={0.5}
                        pl={2}
                        sx={{ cursor: "pointer", width: "20%" }}
                        onClick={() => setShowInput(false)}
                      >
                        <AtsIcon icon="icon-search-normal-1" />
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      mt={0.5}
                      pl={2}
                      sx={{ cursor: "pointer", width: "15%" }}
                      onClick={() => setShowInput(true)}
                    >
                      <AtsIcon icon="icon-search-normal-1" />
                    </Stack>
                  )}
                </AnimatePresence>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack
          sx={{
            height: "calc(100% - 135px)",
            mb: 2,
            overflow: "hidden",
          }}
        >
          {firstLoading ? (
            <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
              <Box
                sx={{
                  width: isLarge ? 325 : 320,
                  height: "100%",
                }}
              >
                <JobCardLoading />
              </Box>
              <JobDetailsLoadingPage />
            </Stack>
          ) : jobList?.length ? (
            <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
              <Box
                sx={{
                  width: isLarge ? 325 : 320,
                  height: "100%",
                }}
              >
                {fetchJobList}
              </Box>
              {jobList?.length > 0 && jobDetailsComponent}
            </Stack>
          ) : (
            <AtsEmptyState
              sx={{
                mt: "100px",
              }}
              imgWidth={74}
              imgHeight={68}
              text="No jobs posted recently."
              subText={`Keep an eye here for new opportunities!`}
            />
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default Jobs;
