import { Stack } from "@mui/material";
import { ArrowCircleDown2, Setting3 } from "iconsax-react";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsIcon from "../../../components/AtsIcon";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useInsights } from "../../../providers/InsightsProvider";
import { BORDERS, COLORS } from "../../../theme";

const TimeTabFilterSection = () => {
  const { dateText } = useInsights();
  return (
    <>
      <AtsRow gap={"5px"}>
        <>
          <AtsRow
            sx={{
              border: BORDERS.GRAY,
              borderRadius: "40px",
              py: "5px",
              px: "10px",
            }}
          >
            <>
              <AtsCustomButton
                px={0}
                py={0}
                onClick={() => {
                  //   onPrevClick();
                  // getTimeEntriesOfContractor(selectedContractorTimesheet.email);
                }}
              >
                <AtsIcon icon="icon-arrow-left-2" height="16px" />
              </AtsCustomButton>
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                  <AtsCustomButton px={0} py={0}>
                    <AtsIcon
                      icon="icon-calendar-1"
                      height="16px"
                      color={COLORS.PRIMARY}
                    />
                  </AtsCustomButton>
                  <AtsTitleText
                    text={dateText}
                    fs={12}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                  />
                </Stack>
              </button>
              <AtsCustomButton px={0} py={0} onClick={() => {}}>
                <AtsIcon icon="icon-arrow-right-3" height="16px" />
              </AtsCustomButton>
            </>
          </AtsRow>
          <AtsCustomButton px={"3px"} py={"3px"}>
            <ArrowCircleDown2 fontWeight={700} size={20} color={COLORS.BLACK} />
          </AtsCustomButton>
          <AtsCustomButton px={"3px"} py={"3px"}>
            <Setting3 fontWeight={700} size={20} color={COLORS.BLACK} />
          </AtsCustomButton>
        </>
      </AtsRow>
    </>
  );
};

export default TimeTabFilterSection;
