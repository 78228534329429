import { Search } from "@mui/icons-material";
import { InputAdornment, Popover, Stack, TextField } from "@mui/material";
import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import "../../../../../../../../../../assets/styles/calender.css";
import AtsText from "../../../../../../../../../../components/AtsText";
import { BORDERS } from "../../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../../utils/FontUtil";

interface CustomHeaderProps {
  headerText: string;
}

export const OfferListHeader = ({ headerText }: CustomHeaderProps) => {
  const { font12 } = FontUtil();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="custom-header"
    >
      <Stack direction={"row"} gap={1}>
        <div
          style={{
            position: "relative",
          }}
        >
          <Stack direction={"row"} gap={1}>
            <AtsText
              text={headerText}
              sx={{
                ...font12,
                fontWeight: 600,
                color: "#4f4f4f",
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
          </Stack>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack
              sx={{
                p: 1,
                border: BORDERS.GRAY,
                borderRadius: 1,
              }}
            >
              {headerText === "Offer ID" ? (
                <TextField
                  placeholder="Search...."
                  fullWidth
                  size={"small"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : headerText === "Extended On" ? (
                <DateRangePicker
                  onChange={(item) => setState([item.selection])}
                  ranges={state}
                />
              ) : headerText === "Rate" ? (
                <Stack sx={{ px: 2 }} gap={1}>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsText
                      text="Over Budget"
                      sx={{ fontSize: 10, fontWeight: 600 }}
                    />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsText
                      text="Under Budget"
                      sx={{ fontSize: 10, fontWeight: 600 }}
                    />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsText
                      text="Matches Budget"
                      sx={{ fontSize: 10, fontWeight: 600 }}
                    />
                  </Stack>
                </Stack>
              ) : (
                headerText === "Status" && (
                  <Stack sx={{ px: 2 }} gap={1}>
                    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                      <AtsText
                        text="Good Fit"
                        sx={{ fontSize: 10, fontWeight: 600 }}
                      />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <AtsText
                        text="Not a Fit"
                        sx={{ fontSize: 10, fontWeight: 600 }}
                      />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                      <AtsText
                        text="Maybe"
                        sx={{ fontSize: 10, fontWeight: 600 }}
                      />
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>
          </Popover>
        </div>
      </Stack>
    </Stack>
  );
};
