import { Avatar, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BORDERS, COLORS } from "../../../../../../theme";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import { motion } from "framer-motion";
import { ProjectService } from "../../../../../../Api/projectServices";
import {
  ProjectListProps,
  ProjectStats,
  ProjectStatsParams,
} from "../../../../../../types/projectTypes";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/store";
import { getAuthState } from "../../../../../../redux/slices/authSlice";
import { getProjectsState } from "../../../../../../redux/slices/projectsSlice";
import { formattedAmount } from "../../../../../../utils/common";
import moment from "moment";
import { InvoiceServices } from "../../../../../../Api/invoiceServices";

const projectData = [
  {
    title: "Start date",
    value: "",
  },
  {
    title: "End date",
    value: "",
  },
  {
    title: "Budget",
    value: "",
  },
  {
    title: "Active Jobs",
    value: "",
  },
  {
    title: "New Submissions",
    value: "",
  },
  {
    title: "Invoices",
    value: "",
  },
  {
    title: "Contractors",
    value: "",
  },
  // {
  //   title: "Project Manager",
  //   value: "",
  // },
];

const AboutThisProject = () => {
  const [isCollapse, setIsCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectStatsCount, setProjectStatsCount] = useState<
    { title: string; value: string | number }[]
  >([]);
  const { userInfo } = useAppSelector(getAuthState);
  const { projectsDetails } = useAppSelector(getProjectsState);

  const { id } = useParams();
  const getProjectStatsCount = async (
    projectsDetails: Partial<ProjectListProps>
  ) => {
    setIsLoading(true);
    try {
      const projectStatsParams = {
        company_id: userInfo?.company?.id,
        project_id: id,
      } as ProjectStatsParams;
      const response = await ProjectService.getProjectState(projectStatsParams);
      const invoiceCountresponse = await InvoiceServices.getInvoiceStats({
        project_id: id,
      });
      const invoiceCount =
        invoiceCountresponse && invoiceCountresponse.length
          ? invoiceCountresponse[0].count
          : "0";
      if (response) {
        const projectDetails = [
          {
            title: "Start date",
            value: projectsDetails
              ? projectsDetails?.start_date.length
                ? moment(projectsDetails?.start_date).format("DD/MM/YYYY")
                : "--"
              : "--",
          },
          {
            title: "End date",
            value: projectsDetails
              ? projectsDetails?.end_date.length
                ? moment(projectsDetails?.end_date).format("DD/MM/YYYY")
                : "--"
              : "--",
          },
          {
            title: "Budget",
            value: projectsDetails
              ? `$${formattedAmount(projectsDetails?.budget_high)}`
              : "--",
          },
          {
            title: "Active Jobs",
            value: response?.jobs_count ?? 0,
          },
          {
            title: "New Submissions",
            value: response?.submission_count ?? 0,
          },
          {
            title: "Invoices",
            value: Number(invoiceCount) ?? 0,
          },
          {
            title: "Contractors",
            value: response?.contractors_count ?? 0,
          },
          // {
          //   title: "Project Manager",
          //   value: "Fredrick San...",
          // },
        ];
        console.log("response", projectDetails, projectsDetails);
        setProjectStatsCount(projectDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Object.keys(projectsDetails).length &&
      id &&
      getProjectStatsCount(projectsDetails);
  }, [projectsDetails]);
  console.log("projectStatsCount", projectStatsCount);
  return (
    <Box>
      <Stack
        sx={{
          ...(isCollapse && {
            borderBottom: BORDERS.GRAY,
            pb: "19px",
            gap: "20px",
          }),
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText text={"About this project"} fs={18} fw={600} />
          <AtsCustomButton
            px={0}
            py={0}
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <AtsIcon
              icon={`icon-arrow-circle-${!isCollapse ? "up" : "down"}`}
              height="16px"
            />
          </AtsCustomButton>
        </Stack>
        {/* <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: isCollapse ? "auto" : 0,
            opacity: isCollapse ? 1 : 0,
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ overflow: "hidden" }}
        >
          <Stack gap={"14px"} direction={"row"} alignItems={"center"}>
            <Avatar src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              You have{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                full project access
              </span>
            </Typography>
          </Stack>
        </motion.div> */}
      </Stack>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isCollapse ? "auto" : 0,
          opacity: isCollapse ? 1 : 0,
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ overflow: "hidden" }}
      >
        <Box mt={"30px"}>
          <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
            {isLoading
              ? projectData.map((data, index) => (
                  <Grid item xs={6} key={index}>
                    <Stack gap={1}>
                      <AtsTitleText
                        text={data.title}
                        fs={12}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                      <Skeleton variant="text" width={"100%"} />
                    </Stack>
                  </Grid>
                ))
              : projectStatsCount.map((details, index) => (
                  <Grid item xs={6} key={index}>
                    <Stack gap={1}>
                      <AtsTitleText
                        text={details.title}
                        fs={12}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                      <AtsTitleText text={details.value} fs={14} fw={400} />
                    </Stack>
                  </Grid>
                ))}
          </Grid>
        </Box>
      </motion.div>
    </Box>
  );
};

export default AboutThisProject;
