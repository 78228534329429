import { Box, IconButton, Stack } from "@mui/material";
import { useRef, useState } from "react";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsIcon from "../../../../components/AtsIcon";
import AtsRow from "../../../../components/AtsRow";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useMessages } from "../../../../providers/MessagesProvider";
import { BG_COLORS, BORDERS } from "../../../../theme";
import MemberSelectorForNewMessage from "./components/MemberSelectorForNewMessage";
import {
  CreateConversationRequest,
  GroupUsers,
} from "../../../../types/messagesTypes";
import { TeamMemberProps } from "../../../../types/projectTypes";
import { Send } from "@mui/icons-material";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";

const img = `https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60`;

const NewMultipleChatRoom = () => {
  const { messenger, conversations, createOnetoOneChat, createChatLoading } =
    useMessages();
  const messageListRef = useRef(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [users, setUsers] = useState<TeamMemberProps[]>([]);

  let previousDate = null;
  const messagesWithTimestamps = conversations.map((message) => {
    const messageDate = new Date(message.updatedAt).toISOString().split("T")[0];
    const showTime = previousDate !== messageDate;

    previousDate = messageDate;

    return {
      ...message,
      showTime,
    };
  });

  const createNewMessage = () => {
    if (users.length > 1) {
      // Group chat API
    } else {
      const oneOnOneChat = {
        isGroupChat: false,
        user: {
          id: users[0]?.uid,
          email: users[0]?.email,
          first_name: users[0]?.first_name,
          last_name: users[0]?.last_name,
        },
        isAtsUser: true,
      } as CreateConversationRequest;

      createOnetoOneChat(oneOnOneChat);
    }
  };

  // useEffect(() => {
  //   messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  // }, []);
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "white",
        height: "100%",
      }}
    >
      <>
        <Box
          sx={{
            px: "30px",
            py: "20px",
            borderBottom: BORDERS.GRAY,
          }}
        >
          <AtsRow gap={"18px"}>
            <>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  bgcolor: "#d9d9d9",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AtsIcon icon="icon-user" height="30px" fs={30} />
              </Box>

              <AtsTitleText text={"New Message"} fs={18} fw={600} />
            </>
          </AtsRow>
        </Box>
        <Box
          sx={{
            py: "10px",
            px: "30px",
            borderBottom: BORDERS.GRAY,
            position: "relative",
          }}
        >
          <AtsRow gap={"16px"}>
            <>
              <AtsTitleText text={"To:"} fs={14} fw={400} textColor="#000" />
              <MemberSelectorForNewMessage
                selected={selectedMembers}
                onSelect={(selected) => setSelectedMembers(selected)}
                selectedUsers={users}
                setSelectedUsers={setUsers}
              />
              <AtsRoundedButton
                buttonHeight={29}
                buttonWidth={79}
                text="Send"
                onClick={createNewMessage}
                disabled={users.length === 0 || createChatLoading}
                loading={createChatLoading}
              />
            </>
          </AtsRow>
        </Box>
        <Box
          sx={{
            height: "calc(100% - 230px)",
          }}
        >
          <Stack
            ref={messageListRef}
            gap={"10px"}
            sx={{
              overflow: "auto",
              height: "100%",
            }}
          >
            <Box px={"30px"} py={"5px"}>
              {/* {messagesWithTimestamps.map((conversation, index) => {
                  return (
                    <ConversationBox
                      key={conversation.id}
                      conversationInfo={conversation}
                    />
                  );
                })} */}
            </Box>
          </Stack>
        </Box>
        <Box m={"30px"}>
          <AtsRow
            sx={{
              border: BORDERS.GRAY,
              borderRadius: "82px",
              p: "16px",
              bgcolor: BG_COLORS.GRAY,
            }}
            gap={"16px"}
          >
            <>
              <input
                type="text"
                placeholder="Your message"
                className="customInput"
                style={{
                  flexGrow: 1,
                  backgroundColor: "transparent",
                }}
              />
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-gallery" height="20px" fs={20} />
              </AtsCustomButton>
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-emoji-normal" height="20px" fs={20} />
              </AtsCustomButton>
            </>
          </AtsRow>
        </Box>
      </>
    </Box>
  );
};

export default NewMultipleChatRoom;
