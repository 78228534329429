import { Skeleton, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { ProjectTimesheetTabsEnum } from "../../../../../../../enums/projectEnums";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import { COLORS } from "../../../../../../../theme";
import { formattedAmount } from "../../../../../../../utils/common";

interface IProps {
  email: string;
}

const TimeColumn = ({ email }: IProps) => {
  const { selectedStatus, timelogLoading, candidateTimelog } = useTimesheet();

  const getTimeLog = () => {
    const findLog = candidateTimelog.find((log) => log.mail === email);
    if (findLog) {
      return (
        <AtsTitleText
          fs={14}
          fw={selectedStatus === ProjectTimesheetTabsEnum.Approved ? 600 : 400}
          textColor={
            selectedStatus === ProjectTimesheetTabsEnum.Approved
              ? COLORS.GREEN
              : COLORS.BLACK
          }
          text={`${formattedAmount(
            selectedStatus === ProjectTimesheetTabsEnum.Approved
              ? findLog?.approved_time
              : selectedStatus === ProjectTimesheetTabsEnum.Disputed
              ? findLog?.total_time
              : findLog?.pending_time
          )} hrs`}
        />
      );
    } else {
      return (
        <AtsTitleText
          fs={14}
          fw={400}
          textColor={COLORS.BLACK}
          text={`0.00 hrs`}
        />
      );
    }
  };
  return (
    <>
      <Stack direction={"row"} gap={1}>
        {timelogLoading ? (
          <Skeleton variant="text" width={50} height={25} />
        ) : (
          getTimeLog()
        )}
      </Stack>
    </>
  );
};

export default TimeColumn;
