import { Avatar } from "@mui/material";
import React from "react";
import { BG_COLORS, COLORS } from "../theme";
import AtsTitleText from "./AtsTitleText";
import { getInitials } from "../utils/common";

interface IProps {
  image?: string;
  name: string;
  fs?: number;
}

const AtsUserNameTextAvatar = ({ name, fs = 14, image = "" }: IProps) => {
  return (
    <>
      <Avatar
        sx={{
          bgcolor: BG_COLORS.PRIMARY,
          width: "100%",
          height: "100%",
        }}
        src={image}
      >
        {name.length > 0 && (
          <AtsTitleText
            text={getInitials(name)}
            fs={fs}
            fw={600}
            textColor={COLORS.PRIMARY}
          />
        )}
      </Avatar>
    </>
  );
};

export default AtsUserNameTextAvatar;
