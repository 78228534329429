import { Box } from "@mui/material";

const TopPrioritiesEmptyState = () => {
  return (
    <Box display="flex" gap={2} justifyContent="center" alignItems="center">
      <Box
        sx={{
          position: "relative",
          //   width: "300px", // Match the image width
          height: "auto",
          overflow: "hidden",
        }}
      >
        <img
          src={require("../../../assets/emptyStateImages/emptyTopPriorities.png")}
          alt=""
        />
      </Box>
    </Box>
  );
};

export default TopPrioritiesEmptyState;
