import { Box, Modal, ModalProps } from "@mui/material";
import React from "react";

interface IModalProps extends ModalProps {
  children: JSX.Element;
  width?: string | number;
  maxWidth?: number;
  height?: number;
}

const AtsModal = ({
  children,
  width,
  height,
  maxWidth,
  ...rest
}: IModalProps) => {
  return (
    <Modal {...rest}>
      <Box
        sx={{
          ...style,
          ...(width ? { width } : { width: 400 }),
          ...(height && { height }),
          ":focus-visible": {
            outline: "none",
          },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default AtsModal;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};
