import { KeyboardArrowDown, Search } from "@mui/icons-material";
import { Box, Collapse, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { BG_COLORS, BOX_SHADOW, COLORS } from "../theme";
import { TitleIdOptionProps } from "../types/commonType";
import { useClickOutSide } from "../utils/common";
import AtsTextInput from "./AtsTextInput";
import AtsTitleText from "./AtsTitleText";

interface Props {
  searchValue: string;
  selected: string;
  placeHolder?: string;
  height?: string;
  options: TitleIdOptionProps[];
  onSelect: (selected: string) => void;
  handleChange?: (value: string) => void;
  onScroll?: (e: any) => void;
  isHideSearchIcon?: boolean;
  isShowDropdownIcon?: boolean;
}

const AtsAutoComplete = ({
  onSelect,
  searchValue,
  onScroll,
  options,
  selected,
  handleChange,
  placeHolder,
  height = "50px",
  isHideSearchIcon,
  isShowDropdownIcon,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const onChange = (selectValue: string | TitleIdOptionProps) => {
    if (typeof selectValue === "string") {
      onSelect(selectValue);
    } else {
      onSelect(selectValue.id);
    }
  };
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <div ref={domNode} style={{ position: "relative" }}>
      <AtsTextInput
        height={height}
        placeholder={placeHolder}
        startAdornment={isHideSearchIcon ? undefined : <Search />}
        endAdornment={
          !isShowDropdownIcon ? undefined : (
            <KeyboardArrowDown
              onClick={() => setShow(!show)}
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                fontSize: "25px !important",
                ...(show ? { rotate: "180deg" } : ""),
              }}
            />
          )
        }
        value={searchValue}
        onChange={(e) => {
          // onChange(e.target.value);
          handleChange(e.target.value);
        }}
        onClick={() => setShow(!show)}
      />
      <Box
        sx={{
          position: "absolute",
          bgcolor: "white",
          zIndex: 9999,
          width: "100%",
        }}
      >
        <Collapse
          sx={{
            boxShadow: BOX_SHADOW.GRAY,
            borderRadius: "6px",
          }}
          in={show}
        >
          <div
            onScroll={onScroll}
            style={{
              maxHeight: 200,
              margin: "10px",
              overflow: "auto",
            }}
            className=""
          >
            {options.length ? (
              options.map((option: TitleIdOptionProps, index) => (
                <MenuItem
                  key={index}
                  value={option.id}
                  onClick={() => {
                    onChange(option.id);
                    setShow(false);
                  }}
                  sx={{
                    bgcolor:
                      selected === option.id ? BG_COLORS.PRIMARY : "white",
                  }}
                >
                  <Stack>
                    <AtsTitleText
                      text={option.title}
                      fs={14}
                      fw={500}
                      textColor={
                        selected === option.id ? COLORS.PRIMARY : COLORS.BLACK
                      }
                    />
                  </Stack>
                </MenuItem>
              ))
            ) : (
              <AtsTitleText text={"No Result"} />
            )}
          </div>
        </Collapse>
      </Box>
    </div>
  );
};

export default AtsAutoComplete;
