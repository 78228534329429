import { Box, Stack } from "@mui/material";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";

interface Props {
  checked: boolean;
  title: string;
  icon: string;
  description: string | null;
  onSelect: () => void;
}

const LongCard = ({ checked, title, description, icon, onSelect }: Props) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          border: checked ? BORDERS_2X.PRIMARY : BORDERS.GRAY,
          borderRadius: 2,
          px: 2.5,
          py: "22px",
          cursor: "pointer",
        }}
        onClick={onSelect}
      >
        <Stack
          sx={{
            width: "50%",
          }}
          gap={0.5}
        >
          <AtsTitleText
            text={title}
            fs={16}
            fw={600}
            textColor={checked ? COLORS.PRIMARY : COLORS.BLACK}
          />
          <Stack
            sx={{
              minHeight: 38,
            }}
          >
            <AtsTitleText
              text={description ?? "-"}
              fs={14}
              fw={400}
              textColor={COLORS.BLACK}
              lineHeight={1.36}
            />
          </Stack>
        </Stack>
        <Box>
          <AtsIcon
            icon={icon}
            fs={35}
            color={checked ? COLORS.PRIMARY : COLORS.BLACK}
          />
        </Box>
      </Stack>
    </>
  );
};

export default LongCard;
