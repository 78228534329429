import { Box, Container, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import AtsCard from "../../components/AtsCard";
import AtsIcon from "../../components/AtsIcon";
import AtsCheckbox from "../../components/AtsCheckbox";

const HEADERS = [
  {
    header: "Action",
    width: "100%",
    minWidth: 400,
    textAlign: "flex-start",
    key: "role",
  },
  {
    header: "Team member",
    width: 120,
    minWidth: 200,
    textAlign: "center",
    key: "teamMember",
  },
  {
    header: "Manager",
    width: 120,
    minWidth: 200,
    textAlign: "center",
    key: "manager",
  },
  {
    header: "Admin",
    width: 120,
    minWidth: 200,
    textAlign: "center",
    key: "admin",
  },
];
const userManagement = [
  {
    role: "Add new users",
    teamMember: false,
    manager: false,
    admin: true,
  },
  {
    role: "Edit user details (name, email, role, department, etc.)",
    teamMember: false,
    manager: false,
    admin: true,
  },
  {
    role: "Active, deactivate, or delete users",
    teamMember: false,
    manager: false,
    admin: true,
  },
  {
    role: "View and manage active and pending users",
    teamMember: false,
    manager: false,
    admin: true,
  },
  {
    role: "Assign user roles",
    teamMember: false,
    manager: false,
    admin: true,
  },
];
const profileManagement = [
  {
    role: "View and update personal profile information",
    teamMember: true,
    manager: true,
    admin: true,
  },
];
const scheduling = [
  {
    role: "Create and manage personal events and meetings",
    teamMember: true,
    manager: true,
    admin: true,
  },
  {
    role: "Access and view shared events",
    teamMember: true,
    manager: true,
    admin: true,
  },
];

const UserRoles = () => {
  return (
    <div
      className="main-container"
      style={{
        height: "100%",
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 3, mb: 4 }}>
        <Stack
          px={2}
          sx={{
            height: "100%",
          }}
        >
          <AtsTitleText
            text="User Roles"
            fs={24}
            fw={600}
            textColor={COLORS.BLACK}
          />

          <Box
            sx={{
              mt: "22px",
              height: "calc(100% - 56px)",
            }}
          >
            <AtsCard
              sx={{
                height: "95%",
              }}
            >
              <Stack
                mx={2}
                sx={{
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  py={1.5}
                  sx={{
                    borderBottom: BORDERS.GRAY,
                    // width: "fit-content",
                  }}
                >
                  {HEADERS.map((header, index) => (
                    <Box
                      key={index}
                      sx={{
                        minWidth: header.minWidth,
                        width: header.width,
                        textAlign: header.textAlign,
                      }}
                    >
                      <AtsTitleText
                        text={header.header}
                        fs={14}
                        fw={600}
                        textColor={COLORS.BLACK}
                      />
                    </Box>
                  ))}
                </Stack>
                <Box
                  sx={
                    {
                      // width: "100%",
                    }
                  }
                >
                  <RoleSection
                    icon="icon-profile-2user"
                    title="User Management"
                    roles={userManagement}
                  />
                  <RoleSection
                    icon="icon-document-text-1"
                    title="Profile Management"
                    roles={profileManagement}
                  />
                  <RoleSection
                    icon="icon-clock"
                    title="Scheduling"
                    roles={scheduling}
                  />
                </Box>
              </Stack>
            </AtsCard>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default UserRoles;

const RoleSection = ({ icon, title, roles }) => (
  <>
    <Stack
      direction="row"
      alignItems="center"
      p={1.5}
      gap={1}
      sx={{ borderBottom: BORDERS.GRAY, bgcolor: BG_COLORS.GRAY }}
    >
      <AtsIcon icon={icon} fw={600} color={COLORS.PRIMARY} height="16px" />
      <AtsTitleText text={title} fs={16} fw={600} textColor={COLORS.BLACK} />
    </Stack>
    {roles.map((role, index) => (
      <Stack
        key={index}
        direction="row"
        alignItems="center"
        p={1.5}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        {HEADERS.map((header) => (
          <Box
            key={header.key}
            sx={{
              minWidth: header.minWidth,
              width: header.width,
              textAlign: header.textAlign,
            }}
          >
            {header.key === "role" ? (
              <AtsTitleText
                text={role[header.key]}
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
              />
            ) : (
              <Stack ml={2}>
                <AtsCheckbox checked={role[header.key]} disabled />
              </Stack>
            )}
          </Box>
        ))}
      </Stack>
    ))}
  </>
);
