import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { setIsOpenChatWindow } from "../../../../redux/slices/dashboardSlice";
import {
  getMessageState,
  setConversation,
} from "../../../../redux/slices/messageSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { connectSocket } from "../../../../socket";
import { ChatProps } from "../../../../types/messagesTypes";
import SupportCard from "../components/SupportCard";

export const token = `eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJTaC1yc3B0cnFEXzFaLW1rVktrNFRqLW1RYlZXaXdUMi1ZeGdLQXpaLXZVIn0.eyJleHAiOjE3MjQxNDAzMDUsImlhdCI6MTcyNDE0MDAwNSwiYXV0aF90aW1lIjoxNzI0MTQwMDAyLCJqdGkiOiI5NGFmNmJhOS0zY2IzLTQyNDMtYWUwNy02ODMzYTY5NWJmYTEiLCJpc3MiOiJodHRwczovL2NhdXRoLmVzdGVlbWVkLmlvL3JlYWxtcy9lc3RlZW1lZC1jb2xsZWFndWVzIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImRiYjhmMjJhLTQ5Y2ItNGU0Yy1iYTQxLWVlNDMxZWFmZjdkYSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImVzdGVlbWVkLXByb2QiLCJub25jZSI6IjBkMjhjOTI1LWQ3MTAtNDExYS04NzFmLTQxOWRjMDQxNzJkZSIsInNlc3Npb25fc3RhdGUiOiI0NDM0YmNmYS1iYTQ0LTRlZDAtYWE5Zi01YjNlZTJmYTE5Y2YiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9jZGV2LmVzdGVlbWVkLmlvIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsImRlZmF1bHQtcm9sZXMtZXN0ZWVtZWQtY29sbGVhZ3VlcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6IjQ0MzRiY2ZhLWJhNDQtNGVkMC1hYTlmLTViM2VlMmZhMTljZiIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IlNoYWlsZW5kcmEgQ2hvZGF2YWRpeWEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzaGFpc2hwYXRlbEBnbWFpbC5jb20iLCJnaXZlbl9uYW1lIjoiU2hhaWxlbmRyYSIsImZhbWlseV9uYW1lIjoiQ2hvZGF2YWRpeWEiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSVRsQTZncmVENll2TVBIZjlkTXBqT21VYlJMYkdVUU9JT2ZIYU90dnc1OTFGZVMwb1U9czk2LWMiLCJlbWFpbCI6InNoYWlzaHBhdGVsQGdtYWlsLmNvbSJ9.gncKlfpP8RXd4WXtGgOmbJuB_czbjAN7ZT0tqLlfvGoRPd_xofhrBpFufrbLcdJBaUVX25CiVdr0HK5yYzKZN-3PUt_yYgEPRO12lbIIfb1nHCysd6hohcb97AkTLpUwcZwe6Cj_TpqLllXUD23ml8mUF3ygmW9dIwWftWbPoJfE_yHrb3WIVVsH6MWQg0iU8U74RJEO-a3QaLtSjDjStxsFP-jZWm_ina000q2mAj-VjOJ7trxSpVg6bByPSbrDoyUx3dzCTxc8N5zal0QVoM7xjyDbl8w2rAfsAbkf-MUeJyEfypnrBlu4wen6P15SAmzcg0XSbEw4xX_KSfWGlQ`;

export const socket = connectSocket();

const ChatWithAccountManager = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { conversation } = useAppSelector(getMessageState);
  const onOpenChatWindow = () => {
    dispatch(setIsOpenChatWindow(true));
  };

  const getConnection = async () => {
    setLoading(true);
    try {
      const body = { userId: "7de1efab-1bea-4722-b041-6da06cf03cc6" };
      const response = await axios.post(
        "https://cdevapi.esteemed.io/v1/conversation",
        body,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response) {
        const conversation: ChatProps = response.data;
        dispatch(setConversation(conversation));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.message, error.response);
      } else {
        console.error("Error:", error);
      }
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   socket.on("connected", (e) => {
  //     console.log("connected", e);
  //     dispatch(SOCKET_CONNECT({ event: e, socket: socket }));
  //   });

  //   socket.on("onMessage", (e) => {
  //     console.log("🚀 ~ socket.on ~ e:", e);
  //     if (conversation.id === e.conversationId) {
  //       onSendMessageNotification.play();
  //       const preConversationList = [...conversationList, e];
  //       dispatch(setConversationList(preConversationList));
  //     } else {
  //       // dispatch(setUnReadCount(e.conversationId));
  //     }
  //   });
  //   return () => {
  //     socket.disconnect();
  //     dispatch(SOCKET_DISCONNECT());
  //   };
  // }, []);
  console.log("conversation", conversation.recipient);
  useEffect(() => {
    getConnection();
  }, []);

  return (
    <>
      <Box>
        <SupportCard
          header={`Chat with ${conversation?.recipient?.firstName}`}
          subText="Need some guidance? Contact your Account Manager for tips and info on finding the perfect match."
          onClick={onOpenChatWindow}
          image={conversation?.recipient?.picture}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default ChatWithAccountManager;
