import { Box, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setJobDetailsById,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS } from "../../../theme";
import {
  JobCategoriesResponse,
  JobDetailByIdResponse,
  JobDetailsResponse,
} from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import LongCard from "../components/LongCard";
import { htmlString } from "../../../utils/stringUtils";

const JobType = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById } = useAppSelector(
    getPostOpportunityState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<JobCategoriesResponse[]>([]);

  const handleChange = (value: JobCategoriesResponse) => {
    if (postOpportunity.job_type_id === value.id) {
      const opportunityObj = {
        ...postOpportunity,
        job_type_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          job_type: "",
        } as JobDetailByIdResponse)
      );
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_type_id: value.id,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          job_type: value.title,
        } as JobDetailByIdResponse)
      );
    }
  };

  const fetchJobTypes = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobTypes({});

      const iconMap = {
        "Direct hire": "icon-calendar-1",
        Contract: "icon-calendar",
        "Contract to hire": "icon-calendar",
      };
      const filterData = response.results.filter(
        (item) => item.title !== "Internship"
      );
      const resposeWithIcon = filterData.map((job) => {
        const icon = iconMap[job.title];
        return icon ? { ...job, icon } : job;
      });
      setTypes(resposeWithIcon);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobTypes();
  }, []);
  return (
    <>
      <JobCardWrapper header="What type of job is it?">
        <Stack pr={1} gap={2}>
          {loading
            ? Array.from(new Array(3)).map((_, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    pr: 1,
                    pl: 2,
                    py: 3,
                    cursor: "pointer",
                  }}
                >
                  <Stack>
                    <Skeleton
                      variant="text"
                      width={300}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={300}
                      sx={{ fontSize: "1rem" }}
                    />
                  </Stack>
                  <Box>
                    <Skeleton variant="circular" width={40} height={40} />
                  </Box>
                </Stack>
              ))
            : types.map((job, index) => (
                <LongCard
                  key={index}
                  title={getText(job.title)}
                  description={htmlString(job.description)}
                  icon={job.icon}
                  checked={postOpportunity.job_type_id === job.id}
                  onSelect={() => handleChange(job)}
                />
              ))}
          {}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobType;

const getText = (text: string) => {
  if (text === "Contract to hire") return "Contract-to-Hire";
  else return text;
};
