import { Skeleton, Stack } from "@mui/material";
import HomeIcon from "../../../../../../../../assets/images/homeIcon.svg";
import LocationIcon from "../../../../../../../../assets/images/location.svg";
import AtsText from "../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../theme";
import { useAppSelector } from "../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../redux/slices/submissionSlice";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";

const About = () => {
  const { candidateDetails, isCandidateDetailsLoading } =
    useAppSelector(getSubmissionState);
  return (
    <>
      <Stack gap={1}>
        {/* <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          className="stateIconList"
        >
          <img src={LocationIcon} alt="Nvidia Logo" style={{ width: 16 }} />
          {isCandidateDetailsLoading ? (
            <Skeleton width={150} height={20} />
          ) : (
            <AtsTitleText
              fs={14}
              fw={700}
              textColor={COLORS.LIGHT_GRAY}
              text={`From ${candidateDetails?.address_city ?? ""}${
                candidateDetails?.address_city ? "," : ""
              } ${candidateDetails?.address_state_code ?? ""}${
                candidateDetails?.address_state_code ? "," : ""
              } ${candidateDetails?.address_country_code ?? ""}`}
            />
          )}
        </Stack> */}
        <Stack
          direction={"row"}
          gap={1}
          alignItems="center"
          className="stateIconList"
        >
          {/* <HomeIcon /> */}
          <img src={HomeIcon} alt="Nvidia Logo" style={{ width: 16 }} />
          <AtsTitleText
            fs={14}
            fw={700}
            textColor={COLORS.LIGHT_GRAY}
            text={`Live in ${candidateDetails?.address_city ?? ""}${
              candidateDetails?.address_city ? "," : ""
            } ${candidateDetails?.address_state_code ?? ""}${
              candidateDetails?.address_state_code ? "," : ""
            } ${candidateDetails?.address_country_code ?? ""}`}
          />
        </Stack>
        <Stack
          sx={{
            marginTop: "8px",
          }}
        >
          <AtsTitleText
            text={
              isNotNullOrUndefined(candidateDetails?.field_ats_candidate_about)
                ? candidateDetails.field_ats_candidate_about
                : ""
            }
            fs={14}
            fw={400}
            lineHeight={"21px"}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default About;
