import { Box, Skeleton } from "@mui/material";
import React from "react";
import { BORDERS } from "../theme";

interface IProps {
  theadComponent: () => void;
  rowComponent?: () => React.ReactNode;
  scrollDiv?: () => React.ReactNode;
  loading: boolean;
  isTopBorder?: boolean;
  isHidBottomBorder?: boolean;
  isBoxShadow?: boolean;
  isNotStickyHeader?: boolean;
  data: any[];
}

const AtsDataTable = ({
  theadComponent,
  rowComponent,
  scrollDiv,
  loading,
  isTopBorder,
  isHidBottomBorder,
  isBoxShadow,
  isNotStickyHeader,
  data,
}: IProps) => {
  return (
    <>
      <Box component={"div"} sx={{ height: "100%" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            //   borderSpacing: '0 0px',
          }}
        >
          <thead
            style={{
              ...(!isNotStickyHeader && { position: "sticky", top: 0 }),

              background: "white",
              zIndex: 1,
            }}
          >
            <tr
              style={{
                ...(isBoxShadow && {
                  boxShadow:
                    "rgb(219 219 219) 0px 1px 1px, rgb(247 246 246) 0px -1px 0px",
                }),
                ...(isTopBorder && { borderTop: BORDERS.GRAY }),
                ...(!isHidBottomBorder && { borderBottom: BORDERS.GRAY }),
              }}
            >
              <>{theadComponent()}</>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from(new Array(3)).map((_, index) => (
                <tr>
                  <td key={index} colSpan={12} style={{ background: "white" }}>
                    <Skeleton variant="text" height={50} />
                  </td>
                </tr>
              ))
            ) : data.length ? (
              <>
                {rowComponent()}
                {scrollDiv && scrollDiv()}
              </>
            ) : (
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "white",
                      borderRadius: "12px",
                    }}
                  ></Box>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default AtsDataTable;
