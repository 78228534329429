import { Box, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AtsBackLink from "../../components/AtsBackLink";
import AtsPageContainer from "../../components/AtsPageContainer";
import AtsPdfViewer from "../../components/AtsPdfViewer";
import AtsRowBetween from "../../components/AtsRowBetween";
import AtsTitleText from "../../components/AtsTitleText";
import { ATS_ROUTES } from "../../router/routes";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import AtsRow from "../../components/AtsRow";
import { Calendar, PenAdd } from "iconsax-react";
import AtsModal from "../../components/AtsModal";
import AddSignature from "./AddSignature";
import {
  ContractsTabsEnums,
  useContracts,
} from "../../providers/ContractsProvider";

const EsignContractDetails = () => {
  const {
    showSignatureModal,
    toggleSignatureModal,
    setShowEsignForm,
    selectedContractTab,
    selectedMsaContracts,
  } = useContracts();
  console.log("selectedMsaContracts", selectedMsaContracts);
  return (
    <>
      <AtsPageContainer>
        <Box height={"100%"}>
          <AtsRowBetween
            sx={{
              pb: "12px",
            }}
          >
            <>
              <Stack
                gap={"6px"}
                direction={"row"}
                alignItems={"center"}
                sx={{ minHeight: 22 }}
              >
                <AtsTitleText
                  lineHeight={2.2}
                  text={
                    selectedContractTab === ContractsTabsEnums.MasterContracts
                      ? "Master Contracts"
                      : "Satement of Work"
                  }
                  fs={10}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />

                <AtsTitleText
                  lineHeight={2.2}
                  text={">"}
                  fs={10}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
                <AtsTitleText
                  lineHeight={2.2}
                  text={selectedMsaContracts?.title ?? ""}
                  fs={10}
                  fw={400}
                  textColor={"#000"}
                />
              </Stack>
              <Stack>
                <AtsBackLink
                  text="Back to contracts"
                  onClick={() => {
                    setShowEsignForm(false);
                  }}
                />
              </Stack>
            </>
          </AtsRowBetween>
          <Box
            sx={{
              height: "calc(100% - 34px)",

              bgcolor: "white",
              border: BORDERS.GRAY,
              borderRadius: "10px",
            }}
          >
            <Stack
              gap={"32px"}
              sx={{
                p: "36px",
                height: "90%",
              }}
            >
              <AtsTitleText
                text={`${selectedMsaContracts?.title ?? ""} Contract Details`}
                fs={22}
                fw={600}
              />
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                gap={"42px"}
                sx={{
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    px: "30px",
                    pt: "20px",
                    pb: "55px",
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    maxWidth: 216,
                  }}
                >
                  <Stack gap={"20px"}>
                    <AtsTitleText
                      text={
                        "Fill out the document by placing fields on the document:"
                      }
                      fs={14}
                      fw={400}
                      textColor={COLORS.DARK_GRAY}
                    />
                    <Stack gap={2}>
                      <ButtonWrapper
                        text="My Signature"
                        icon={<PenAdd size="16" color="#000" />}
                        onClick={() => {
                          toggleSignatureModal();
                        }}
                      />
                      <ButtonWrapper
                        text="Today’s Date"
                        icon={<Calendar size="16" color="#000" />}
                      />
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    flex: 1,
                    height: "95%",
                    //   minWidth: 247,
                  }}
                >
                  {selectedMsaContracts.contract_link &&
                    selectedMsaContracts.contract_link.length > 0 && (
                      <iframe
                        src={selectedMsaContracts.contract_link}
                        title="PDF Viewer"
                        width="100%"
                        height="100%"
                        style={{ border: "none", borderRadius: "10px" }}
                      />
                    )}
                  {/* <AtsPdfViewer
                    // pdfFile={require("../../assets/pdf/sample.pdf")}
                    pdfFile={
                      "https://ats-drupal.s3.amazonaws.com/s3fs-public/_37.pdf"
                    }
                  /> */}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </AtsPageContainer>
      <AtsModal
        width={814}
        open={showSignatureModal}
        onClose={toggleSignatureModal}
      >
        <AddSignature />
      </AtsModal>
    </>
  );
};

export default EsignContractDetails;

const ButtonWrapper = ({
  text,
  icon,
  onClick,
}: {
  text: string;
  icon: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <AtsRow
      gap={1}
      justifyContent={"center"}
      sx={{
        py: "14px",
        bgcolor: BG_COLORS.PRIMARY,
        cursor: "pointer",
        borderRadius: "8px",
      }}
      onClick={onClick ? onClick : () => {}}
    >
      <>
        <AtsTitleText text={text} fs={14} fw={400} textColor={"#000"} />
        {icon}
      </>
    </AtsRow>
  );
};
