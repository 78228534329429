import { Box, Skeleton, Stack } from "@mui/material";
import { useState } from "react";
import { OnboardingServices } from "../../../../../../../../../Api/onboardingServices";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { FileItem } from "../../../../../../../../../types/commonType";
import UploadInput from "./UploadInput";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";

const OfficeTours = () => {
  const {
    setOfficeToursFiles,
    officeToursFiles,
    setCreateOnboardingState,
    createOnboardingState,
    handleCreateOnboardingState,
    editState,
  } = useOnboarding();
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileLength, setFileLength] = useState<number>(0);

  const handleBinaryUploadForLogo = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await OnboardingServices.uploadOfficeToursFiles(
            arrayBuffer,
            file.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles) => {
    setFileLoading(true);
    try {
      const fileArray = Array.from(selectedFiles) as File[];
      setFileLength(fileArray?.length);
      const uploadChooseProjectImgPromises = fileArray.map(
        handleBinaryUploadForLogo
      );
      const resultsForProjectImg = await Promise.all(
        uploadChooseProjectImgPromises
      );

      const result = resultsForProjectImg.map((item: FileItem) => {
        const fidValue = item?.fid[0]?.value;
        const fileNameValue = item?.uri[0]?.url;
        const fileName = item?.filename[0]?.value;
        return fidValue && fileNameValue && fileName
          ? { fid: fidValue, url: fileNameValue, fileName }
          : null;
      });
      setOfficeToursFiles([...officeToursFiles, ...result]);
      setCreateOnboardingState({
        ...createOnboardingState,
        office_tours_activities_files: result.map((value) =>
          value.fid.toString()
        ),
      });
    } catch (error) {
      setFileLoading(false);
    } finally {
      setFileLoading(false);
    }
  };
  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDelete = (index: number) => {
    const preFiles = [...officeToursFiles];
    preFiles.splice(index, 1);
    setOfficeToursFiles(preFiles);
  };

  const renderFIleComponent = (file, index) => {
    let fileName = file.fileName.substring(0, 20) + "...";

    return (
      <Stack
        direction={"row"}
        sx={{
          pl: "20px",
          py: "10px",
          pr: !editState.isEditEquipment ? "20px" : "0px",
          bgcolor: "white",
          borderRadius: "8px",
          border: BORDERS.GRAY,
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"15px"}>
          <img
            src={require("../../../../../../../../../assets/images/pdfIcon.png")}
            alt="Nvidia Logo"
            style={{ width: 21 }}
          />
          <Stack gap={"10px"}>
            <AtsTitleText
              text={fileName}
              fs={14}
              fw={500}
              textColor={COLORS.BLACK}
            />
          </Stack>
          {!isContractorOnboarded ||
            (editState.isEditOfficceTours && (
              <Box
                sx={{
                  borderLeft: BORDERS.GRAY,
                }}
              >
                <Box
                  sx={{
                    px: "10px",

                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AtsCustomButton
                    px={0.5}
                    py={0.5}
                    onClick={() => handleDelete(index)}
                  >
                    <AtsIcon icon="icon-trash" height="16px" />
                  </AtsCustomButton>
                </Box>
              </Box>
            ))}
        </Stack>
      </Stack>
    );
  };
  return (
    <>
      <Stack gap={"30px"}>
        <Stack gap={"5px"}>
          <AtsTitleText text={"Describe activities"} fs={14} fw={600} />
          {isContractorOnboarded && !editState.isEditOfficceTours ? (
            <AtsTitleText
              text={createOnboardingState?.office_tours_activities_description}
              fs={14}
              fw={500}
            />
          ) : (
            <UploadInput
              keyValue="office_tours_activities_description"
              placeholder="Enter details here"
              handleFileChange={handleFileChange}
              value={createOnboardingState?.office_tours_activities_description}
              handleChange={(e) => {
                handleCreateOnboardingState(
                  "office_tours_activities_description",
                  e.target.value
                );
              }}
            />
          )}
          <Stack direction={"row"} flexWrap={"wrap"} gap={"10px"} sx={{}}>
            {fileLoading ? (
              Array.from(new Array(fileLength)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  height={50}
                  width={240}
                />
              ))
            ) : !officeToursFiles.length ? (
              <AtsTitleText text={"No files chosen"} fs={12} />
            ) : (
              officeToursFiles.map((file, index) =>
                renderFIleComponent(file, index)
              )
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default OfficeTours;
