import { Box, Stack } from "@mui/material";
import AtsPageContainer from "../../components/AtsPageContainer";
import AtsTitleText from "../../components/AtsTitleText";
import { InsightTypeTabs } from "../../enums/insightsEnums";
import { useInsights } from "../../providers/InsightsProvider";
import { COLORS } from "../../theme";
import JobsSwitch from "../Dashboard/PostedJobs/JobsSwitch";

import FinancesTab from "./Tabs/FinancesTab";
import TalentTab from "./Tabs/TalentTab";
import TimeTab from "./Tabs/TimeTab/TimeTab";

const Insights = () => {
  const { selectedInsightsTab, handleChangeTab } = useInsights();

  const insightsTabs = Object.keys(InsightTypeTabs).map((tab) => ({
    title: tab,
    type: tab,
  }));

  return (
    <AtsPageContainer isEqualPading>
      <>
        <Stack sx={{ mb: 1.5 }}>
          <AtsTitleText
            text="Insights"
            fs={24}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Stack
            sx={{ pt: "22px", pb: "19px" }}
            direction={"row"}
            alignItems={"center"}
          >
            <JobsSwitch
              jobs={insightsTabs}
              active={selectedInsightsTab}
              onChange={(value) => {
                handleChangeTab(value as InsightTypeTabs);
              }}
            />
          </Stack>
        </Stack>
        <Box
          className="scrollHide"
          sx={{
            height: "calc(100% - 100px)",
            overflow: "auto",
          }}
        >
          {selectedInsightsTab === InsightTypeTabs.Time ? (
            <TimeTab />
          ) : selectedInsightsTab === InsightTypeTabs.Finances ? (
            <FinancesTab />
          ) : (
            <TalentTab />
          )}
        </Box>
      </>
    </AtsPageContainer>
  );
};

export default Insights;
