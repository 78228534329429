import { Circle, MoreVert } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../components/AtsTitleText";
import AtsIcon from "../../../../components/AtsIcon";
import { COLORS } from "../../../../theme";
import { ProjectListProps } from "../../../../types/projectTypes";
import UserImageForProject from "../../modules/ProjectModules/UserImageForProject";
import { useParams } from "react-router-dom";

interface IProps {
  projectsDetails: Partial<ProjectListProps>;
  loading: boolean;
}

const ProjectDetailsHeaderComponent = ({
  projectsDetails,
  loading,
}: IProps) => {
  const { id } = useParams();
  return (
    <>
      <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Box
            sx={{
              width: 50,
              height: 50,
            }}
          >
            {loading ? (
              <Skeleton variant="circular" width={50} height={50} />
            ) : projectsDetails?.project_image ? (
              <img
                width={"100%"}
                src={projectsDetails?.project_image}
                alt=""
                style={{
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                width={"100%"}
                src={require("../../../../assets/images/projectImg.png")}
                alt=""
              />
            )}
          </Box>
        </Stack>
        <Stack
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack gap={"9px"}>
              {loading ? (
                <Skeleton height={20} width={200} />
              ) : (
                <AtsTitleText
                  text={projectsDetails?.title ?? ""}
                  fs={20}
                  fw={600}
                  textColor="#000"
                />
              )}
              <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                  <AtsIcon
                    icon="icon-document-text"
                    fs={16}
                    height="18px"
                    color={COLORS.LIGHT_GRAY}
                  />
                  {loading ? (
                    <Skeleton height={20} width={100} />
                  ) : (
                    <AtsTitleText
                      text={`P-${projectsDetails?.nid ?? ""}`}
                      fs={14}
                      fw={400}
                      textColor={COLORS.LIGHT_GRAY}
                    />
                  )}
                </Stack>
                <Circle sx={{ fontSize: 5, color: "lightgray" }} />
                <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                  <AtsIcon
                    icon="icon-location"
                    fs={16}
                    height="18px"
                    color={COLORS.LIGHT_GRAY}
                  />
                  {loading ? (
                    <Skeleton height={20} width={100} />
                  ) : (
                    <AtsTitleText
                      text={
                        projectsDetails?.location
                          ? projectsDetails?.location?.country
                          : ""
                      }
                      fs={14}
                      fw={400}
                      textColor={COLORS.LIGHT_GRAY}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              gap={"12px"}
              pr={"10px"}
            >
              <Stack>
                <UserImageForProject project_id={id} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectDetailsHeaderComponent;
