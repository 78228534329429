import { RadioGroup, Skeleton, Stack } from "@mui/material";
import AtsRadioButton from "../../../components/AtsRadioButton";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setJobDetailsById,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, BORDERS_2X } from "../../../theme";
import JobCardWrapper from "../components/JobCardWrapper";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  JobCategoriesResponse,
  JobDetailByIdResponse,
  JobDetailsResponse,
} from "../../../types/jobsTypes";
import RadioInput from "../components/RadioInput";

const JobPriority = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById } = useAppSelector(
    getPostOpportunityState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [jobPriorities, setJobPriorities] = useState<JobCategoriesResponse[]>(
    []
  );

  const handleChange = (value: JobCategoriesResponse) => {
    if (postOpportunity.job_priority_id === value.id) {
      const opportunityObj = {
        ...postOpportunity,
        job_priority_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          job_priority: "",
        } as JobDetailByIdResponse)
      );
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_priority_id: value.id,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          job_priority: value.title,
        } as JobDetailByIdResponse)
      );
    }
  };

  const fetchPeriodOfTime = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobPriorities({
        items_per_page: 50,
      });
      setJobPriorities(response.results);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPeriodOfTime();
  }, []);
  return (
    <>
      <JobCardWrapper header="Set the Job Priority">
        <Stack pr={1} gap={2}>
          {loading
            ? Array.from(new Array(3)).map((_, index) => (
                <Stack
                  gap={2}
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    px: 2,
                    py: 1.5,
                    cursor: "pointer",
                  }}
                >
                  <Skeleton variant="circular" width={10} height={10} />

                  <Skeleton
                    variant="text"
                    width={300}
                    sx={{ fontSize: "1rem" }}
                  />
                </Stack>
              ))
            : jobPriorities.map((item, index) => (
                <RadioInput
                  key={index}
                  checked={postOpportunity.job_priority_id === item.id}
                  onSelect={() => handleChange(item)}
                  title={`${item.title} Priority`}
                />
              ))}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobPriority;
