import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../theme";
import Chart from "react-apexcharts";
import { ProjectOverviewServices } from "../../../../../../Api/projectOverviewServices";
import { useParams } from "react-router-dom";
import { ProjectDateRangeEnums } from "../../../../../../enums/projectEnums";
import { RecentActivitiesResponse } from "../../../../../../types/projectOverviewTypes";
import { isNotNullOrUndefined } from "../../../../../../utils/stringUtils";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";
import { Skeleton } from "@mui/material";

const timeDataMap = {
  0: {
    data: [23, 34, 45, 56, 67],
    categories: ["Mon", "Tue", "Wed", "Thu", "Fri"],
  },
  1: { data: [67], categories: ["Jan"] },
  2: { data: [65, 54, 43], categories: ["Jan", "Feb", "Mar"] },
  3: {
    data: [44, 55, 41, 55, 22, 43],
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  },
};

const dateRangeTab = {
  week: "1W",
  month: "1M",
  quarter: "3M",
  "half-year": "6M",
};

const RecentActivity = () => {
  const { id } = useParams();
  const [recentActivities, setRecentActivities] =
    useState<RecentActivitiesResponse | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    ProjectDateRangeEnums.week
  );
  const [loading, setLoading] = useState<boolean>(false);

  const getRecentActivities = async () => {
    setLoading(true);
    try {
      const response = await ProjectOverviewServices.getRecentActivities({
        project_id: id,
        date_range: selectedDateRange,
      });
      if (response.length) {
        setRecentActivities(response[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRecentActivities();
  }, [selectedDateRange]);

  const categories = isNotNullOrUndefined(recentActivities)
    ? Object.keys(recentActivities.hired).map((value) => value)
    : [];

  const jobsCreatedData = isNotNullOrUndefined(recentActivities)
    ? Object.values(recentActivities.jobs_created)
    : []; // [0, 0, 0, 0, 4, 0]
  const hiredData = isNotNullOrUndefined(recentActivities)
    ? Object.values(recentActivities.hired)
    : [];
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
      fontFamily: "Inter",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 2,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ["transparent"] },
    xaxis: {
      categories,
    },
    yaxis: { title: { text: "" } },
    fill: { opacity: 1, colors: ["#2ba899", "#3366cc", "#63abfd"] },
    colors: ["#2ba899", "#3366cc", "#63abfd"],
    legend: {
      markers: { shape: "circle", size: 5, offsetX: -2, offsetY: 0 },
      offsetX: 20,
      offsetY: 10,
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
  };

  return (
    <>
      <Box pb={"16px"}>
        <AtsTitleText text={"Recent activity"} fs={18} fw={600} />
      </Box>

      <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 6 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, md: 0 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsTitleText
              text={"Activity"}
              fs={14}
              fw={600}
              textColor={COLORS.DARK_GRAY}
            />
            <Stack direction={"row"} alignItems={"center"}>
              {Object.values(ProjectDateRangeEnums).map((tab, index) => (
                <Stack
                  key={index}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"8px"}
                    px={"5px"}
                    mx={"5px"}
                    pb={"3px"}
                    sx={{
                      cursor: "pointer",
                      height: 13,
                      borderBottom:
                        tab === selectedDateRange
                          ? "1px solid var(--primary)"
                          : "1px solid transparant",
                    }}
                    onClick={() => setSelectedDateRange(tab)}
                  >
                    <AtsTitleText
                      text={dateRangeTab[tab]}
                      fs={11}
                      fw={600}
                      textColor={
                        tab === selectedDateRange ? COLORS.PRIMARY : "#c4c4c4"
                      }
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {loading ? (
            <Skeleton width={"100"} height={190} variant="rounded" />
          ) : isNotNullOrUndefined(recentActivities) ? (
            <Chart
              options={options}
              series={[
                {
                  data: jobsCreatedData,
                  name: "Jobs Created",
                },
                {
                  data: hiredData,
                  name: "Hired",
                },
              ]}
              type="bar"
              height={192}
            />
          ) : (
            <AtsEmptyState text="No Recent activities" subText="" />
          )}
        </Box>
        {/* <Stack
          sx={{
            flex: { xs: "1 1 auto", md: "0 0 45%" },
          }}
          gap={2}
        >
          <AtsTitleText
            text={"Collaborator activity"}
            fs={14}
            fw={600}
            textColor={COLORS.DARK_GRAY}
          />
          <Stack gap={"20px"}>
            {renderCollaborator()}
            {renderCollaborator()}
            {renderCollaborator()}
          </Stack>
        </Stack> */}
      </Stack>
    </>
  );
};

export default RecentActivity;

// const renderCollaborator = () => {
//   return (
//     <Stack gap={"14px"} direction={"row"} alignItems={"center"}>
//       <Avatar src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" />
//       <Stack>
//         <Typography
//           sx={{
//             fontSize: 14,
//             fontWeight: 400,
//           }}
//         >
//           <span
//             style={{
//               fontWeight: 600,
//             }}
//           >
//             Daniel Anderson
//           </span>{" "}
//           created a new job
//         </Typography>
//         <AtsTitleText
//           text={"49m ago"}
//           fw={400}
//           fs={11}
//           textColor={COLORS.LIGHT_GRAY}
//         />
//       </Stack>
//     </Stack>
//   );
// };
