import React from "react";
import OffersList from "../components/Offers/OffersList";

const Offers = () => {
  return (
    <>
      <OffersList />
    </>
  );
};

export default Offers;
