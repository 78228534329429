import io from "socket.io-client"; // Add this

let socket;

export const connectSocket = () => {
  // socket = io("https://cdevws.esteemed.io", {
  //   withCredentials: true,
  //   extraHeaders: {
  //     Authorization:
  //       "Bearer " +
  //       `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjdkZTFlZmFiLTFiZWEtNDcyMi1iMDQxLTZkYTA2Y2YwM2NjNiIsImVtYWlsIjoic2hhaWxlbmRyYS5jQGlrc3VsYS5jb20iLCJmaXJzdE5hbWUiOiJTaGFpbGVuZHJhIiwibGFzdE5hbWUiOiJDaG9kYXZhZGl5YSIsImlhdCI6MTcyNDE0MDIyMSwiZXhwIjoxNzI0MjI2NjIxfQ.Q2Eo1p_Z52UTvVN05VE88Xz0fYTAizvkVld9IWSN7E4`,
  //   },
  // });
  return socket;
};
