import React, { createContext, useContext, useState } from "react";

export enum BillingTabsEnums {
  BillingDetails = "Billing Details",
  BillingHistory = "Billing History",
}

type BillingModuleContextType = {
  selectedBillingTab: BillingTabsEnums;
  setSelectedBillingTab: React.Dispatch<React.SetStateAction<BillingTabsEnums>>;
};

const BillingModule = createContext<BillingModuleContextType | null>(null);

export const useBilling = () =>
  useContext(BillingModule) as BillingModuleContextType;

export const BillingProvider = ({ children }: any) => {
  const [selectedBillingTab, setSelectedBillingTab] =
    useState<BillingTabsEnums>(BillingTabsEnums.BillingDetails);

  return (
    <BillingModule.Provider
      value={{
        selectedBillingTab,
        setSelectedBillingTab,
      }}
    >
      {children}
    </BillingModule.Provider>
  );
};
