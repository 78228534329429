import { Stack, Typography } from "@mui/material";
import { Add } from "iconsax-react";
import { useState } from "react";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useMyProfile } from "../../../providers/MyProfileProvider";
import { BORDERS, COLORS } from "../../../theme";
import { GetInterestResponse } from "../../../types/userManagementTypes";
import InterestList from "./InterestList";
import ProfileModal from "./ProfileModal";

const Interestes = () => {
  const { updateProfileState } = useMyProfile();
  const [interestes, setInterestes] = useState<GetInterestResponse[]>([]);
  const [open, setOpen] = useState(false);
  const toggelOpenClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Stack gap={"24px"}>
        <Stack gap={"13px"}>
          <AtsTitleText
            text={"What you're into"}
            fs={18}
            fw={600}
            textColor="#000"
          />
          <AtsTitleText
            text={
              "Find common ground with other guests and Hosts by adding interests to your profile."
            }
            fs={16}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"20px"}
          flexWrap={"wrap"}
        >
          {updateProfileState.you_are_into.length > 0 &&
            updateProfileState.you_are_into.map((item, index) => {
              return (
                <AtsRow
                  key={index}
                  gap={"8px"}
                  sx={{
                    borderRadius: "50px",
                    border: BORDERS.GRAY,
                    px: "16px",

                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <>
                    {" "}
                    {item.icon && (
                      <img src={item.icon} height={20} width={20} alt="" />
                    )}
                    <AtsTitleText
                      text={item?.title}
                      fs={12}
                      fw={400}
                      textColor={"#000"}
                    />
                  </>
                </AtsRow>
              );
            })}
          <AtsRow
            sx={{
              width: 90,
              height: 39,
              borderRadius: "40px",
              border: "1px dashed #3366cc",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={toggelOpenClose}
          >
            <Add color={COLORS.PRIMARY} />
          </AtsRow>
        </Stack>
        <Typography
          sx={{
            textDecoration: "underline",
            color: "black",
            fontSize: 14,
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          Add interests
        </Typography>
      </Stack>
      <ProfileModal
        width={536}
        open={open}
        header={"What are you into?"}
        subText={
          "Pick some interests that you enjoy and that you want to show on your profile."
        }
        toggelOpenClose={toggelOpenClose}
        renderBottomText={
          <Typography
            sx={{
              textDecoration: "underline",
              fontSize: 14,
              fontWeight: 600,
              color: "#000",
            }}
          >
            {`${updateProfileState.you_are_into.length}/${interestes.length} selected`}
          </Typography>
        }
        renderConponents={
          <InterestList setInterestes={setInterestes} interestes={interestes} />
        }
        onSave={toggelOpenClose}
      />
    </>
  );
};

export default Interestes;
