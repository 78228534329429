import { createContext, useContext, useMemo } from "react";
import { io, Socket } from "socket.io-client";
import { WEB_SOCKET } from "../utils/url";

type SocketModuleContextType = {
  socket: Socket | null;
};
export const dummyToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFjNWRjZmE3LTcyOGYtNDQ4Yi1iYzU1LTUwNGM5NjEzZTQxYSIsImVtYWlsIjoic2hhaXNocGF0ZWxAZ21haWwuY29tIiwiZmlyc3ROYW1lIjoiU2hhaWxlbmRyYSIsImxhc3ROYW1lIjoiQ2hvZGF2YWRpeWEiLCJpYXQiOjE3MjgwNDcyMDcsImV4cCI6MTcyODEzMzYwN30.hy2PvZ8rPjh9vXWD_5DJBa8yDTedMYtAP4Z189-qoJE`;

const SocketModule = createContext<SocketModuleContextType | null>(null);

export const useSocket = () =>
  useContext(SocketModule) as SocketModuleContextType;

export const SocketProvider = ({ children }: any) => {
  const socket = useMemo(
    () =>
      io(WEB_SOCKET, {
        withCredentials: true,
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          //   Authorization: `Bearer ${dummyToken}`,
        },
      }),
    []
  );

  return (
    <SocketModule.Provider
      value={{
        socket,
      }}
    >
      {children}
    </SocketModule.Provider>
  );
};
