import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Divider, Stack, useMediaQuery, useTheme } from "@mui/material";
import { FileIcon, MarkerIcon, TimeIcon } from "../../../assets/icons/Icons";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import { JobDetailsResponse } from "../../../types/jobsTypes";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";
import AtsIcon from "../../../components/AtsIcon";
import { commaSeparatedAddress } from "../../../utils/stringUtils";
import {
  renderDateComponent,
  renderJobDataComponent,
  renderStatusComponent,
} from "../Job";

interface SubmissionHeaderProps {
  jobDetails: JobDetailsResponse;
  loading: boolean;
}

const SubmissionHeader = ({ jobDetails, loading }: SubmissionHeaderProps) => {
  const navigate = useNavigate();
  return (
    <>
      {/*----- Active & Back to Job -----*/}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={3}
        justifyContent={"space-between"}
      >
        {renderStatusComponent(loading, jobDetails?.job_status)}
        <Stack
          gap={0.2}
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(ATS_ROUTES.JOBS)}
        >
          <ChevronLeft sx={{ fontSize: 22, color: "#3366cc" }} />

          <AtsTitleText fs={12} textColor={"#3366cc"} text="Back to Job" />
        </Stack>
      </Stack>

      {/*----- Vice President - France > Submissions -----*/}
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <AtsTitleText
          loading={loading}
          fs={20}
          fw={600}
          textColor={COLORS.BLACK}
          text={jobDetails?.title ?? ""}
        />
        {/* <ChevronRight sx={{ fontSize: 25, color: "#231f20" }} /> */}
        <AtsIcon icon="icon-arrow-right-3" fs={20} height="20px" />
        <AtsTitleText
          fs={20}
          fw={400}
          textColor={COLORS.BLACK}
          text="Submissions"
        />
      </Stack>

      {/*----- Location & Time & File -----*/}
      <Stack direction={"row"} alignItems={"center"} gap={"15px"}>
        <Box minWidth={130}>
          {renderDateComponent(
            loading,
            "Starts",
            jobDetails?.job_start_date,
            12,
            true
          )}
        </Box>
        <Divider orientation="vertical" variant="middle" />
        <Stack
          direction={"row"}
          gap={5}
          sx={{ width: "100%", flexWrap: "wrap" }}
          alignItems={"center"}
        >
          {renderJobDataComponent(
            loading,
            "location",
            commaSeparatedAddress({
              location: jobDetails?.job_address_locality,
              country: jobDetails?.job_address_country,
            })
          )}
          {renderJobDataComponent(loading, "clock", "Full-time")}
          {jobDetails?.job_type &&
            renderJobDataComponent(
              loading,
              "document-text",
              jobDetails?.job_type
            )}
          {jobDetails?.work_mode &&
            renderJobDataComponent(loading, "monitor", jobDetails?.work_mode)}
          {renderJobDataComponent(loading, "info-circle", jobDetails?.nid)}
        </Stack>
      </Stack>
    </>
  );
};

export default SubmissionHeader;
