import { Close } from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsIcon from "../../../components/AtsIcon";
import AtsRow from "../../../components/AtsRow";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import { useProjectFilter } from "../../../providers/ProjectFilterProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import { ProjectFilterState } from "../../../types/projectTypes";
import { useClickOutSide } from "../../../utils/common";
import { countryAbbreviationsTwoDigit } from "../../../utils/countryAbbreviations";
import { IProjectFilterProps } from "../modules/ProjectModules/ProjectFilterSection";

const location = [
  { country: "United States", abbrevation: "US" },
  { country: "United Kingdom", abbrevation: "GB" },
  { country: "South Africa", abbrevation: "ZA" },
  { country: "Australia", abbrevation: "AU" },
  { country: "Germany", abbrevation: "DE" },
];

const FilterLoacation = ({ setPage }: IProjectFilterProps) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const { search, setSearch, projectFilter, setProjectFilter } =
    useProjectFilter();

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const filteredCountries = Object.entries(countryAbbreviationsTwoDigit).filter(
    ([country]) => {
      const searchValue = search as string;
      return country.toLowerCase().includes(searchValue.toLowerCase());
    }
  );

  const handleSelect = (country: string) => {
    const selectedCountry = countryAbbreviationsTwoDigit[country];
    setPage(0);
    if (projectFilter?.country_code.includes(selectedCountry)) {
      const filterCountries = projectFilter?.country_code.filter(
        (country) => country !== selectedCountry
      );
      // setSearch("");
      const projectPreState = {
        ...projectFilter,
        country_code: filterCountries,
      } as ProjectFilterState;
      setProjectFilter(projectPreState);
    } else {
      // setSearch("");
      // setSearch(country);
      const projectPreState = {
        ...projectFilter,
        country_code: [...projectFilter?.country_code, selectedCountry],
      } as ProjectFilterState;
      setProjectFilter(projectPreState);
    }
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <AtsCustomButton px={0} onClick={() => setIsCollapse(!isCollapse)}>
          <AtsIcon
            icon={isCollapse ? "icon-arrow-up-2" : "icon-arrow-down-1"}
            height="16px"
            fw={600}
          />
        </AtsCustomButton>
        <AtsTitleText
          text={"Location"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Collapse in={isCollapse}>
        <Stack>
          <Box
            ref={domNode}
            sx={{
              position: "relative",
            }}
            onClick={() => setShow(true)}
          >
            <AtsRow gap={"5px"} flexWrap={"wrap"}>
              <>
                <AtsTextInput
                  height="42px"
                  placeholder="Search location"
                  value={search}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length) {
                      setShow(true);
                    } else {
                      setShow(false);
                    }
                    setSearch(value);
                  }}
                  endAdornment={
                    <AtsIcon
                      icon="icon-search-normal-1"
                      height="18px"
                      color={COLORS.PRIMARY}
                    />
                  }
                />

                <AtsRow gap={"5px"} flexWrap={"wrap"}>
                  <>
                    {projectFilter.country_code.length > 0 &&
                      projectFilter.country_code.map((country, index) => {
                        const getCountryName = Object.entries(
                          countryAbbreviationsTwoDigit
                        ).find(([_, value]) => value === country)?.[0];
                        return (
                          <AtsRow
                            key={index}
                            gap={"5px"}
                            sx={{
                              bgcolor: BG_COLORS.PRIMARY,
                              py: "2px",
                              pl: "8px",
                              borderRadius: "5px",
                            }}
                          >
                            <>
                              <AtsTitleText
                                text={getCountryName}
                                fs={12}
                                fw={500}
                                textColor={COLORS.PRIMARY}
                              />
                              <IconButton
                                sx={{
                                  p: "3px",
                                }}
                                onClick={() => handleSelect(getCountryName)}
                              >
                                <Close
                                  sx={{
                                    fontSize: 14,
                                    color: COLORS.PRIMARY,
                                  }}
                                />
                              </IconButton>
                            </>
                          </AtsRow>
                        );
                      })}
                  </>
                </AtsRow>
              </>
            </AtsRow>

            <Box mt={1}>
              <Collapse in={show}>
                <Stack
                  gap={1}
                  sx={{
                    p: 2,
                    border: BORDERS.GRAY,
                    borderRadius: "6px",
                    maxHeight: "200px",
                    overflow: "auto",
                  }}
                >
                  {filteredCountries.map(([country, abbreviation]) => (
                    <Stack
                      key={country}
                      direction={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                    >
                      <AtsCheckbox
                        p={0}
                        checked={projectFilter?.country_code.includes(
                          abbreviation
                        )}
                        onChange={() => handleSelect(country)}
                      />
                      <AtsTitleText
                        text={country}
                        fs={12}
                        fw={400}
                        textColor={"#000"}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Collapse>
            </Box>
          </Box>
          <Stack gap={"10px"} mt={"10px"}>
            {location.map((country) => (
              <Stack
                key={country.abbrevation}
                direction={"row"}
                alignItems={"center"}
                gap={"8px"}
              >
                <AtsCheckbox
                  p={0}
                  checked={projectFilter?.country_code.includes(
                    country.abbrevation
                  )}
                  onChange={() => handleSelect(country.country)}
                />
                <AtsTitleText
                  text={country.country}
                  fs={12}
                  fw={400}
                  textColor={"#000"}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default FilterLoacation;
