import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../../../providers/TimesheetProvider";
import { setSelectedDisputeFlowTab } from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import DisputedEntriesCard from "./DisputedEntriesCard";
import { formattedAmount } from "../../../../../../../../../utils/common";

const DisputeEntries = () => {
  const dispatch = useAppDispatch();
  const { selectedTimelogOfContractorDetails } = useTimesheet();
  const { font14 } = FontUtil();
  const [errorState, setErrorState] = useState("");

  const hasEmptyDisputedTime = selectedTimelogOfContractorDetails.some(
    (item) =>
      !item.disputed_hrs ||
      !item.disputed_mins ||
      !item.dispute_description ||
      !item.dispute_description?.length
  );

  const nextStep = () => {
    if (!hasEmptyDisputedTime) {
      setErrorState(``);
      dispatch(setSelectedDisputeFlowTab(DisputeTabsEnum.EvidenceDetails));
    } else {
      const hasEmptyState = selectedTimelogOfContractorDetails.some(
        (item) =>
          !item.disputed_hrs &&
          !item.disputed_mins &&
          !item.dispute_description &&
          !item.dispute_description?.length
      );
      const hasEmptyDesState = selectedTimelogOfContractorDetails.some(
        (item) => !item.dispute_description && !item.dispute_description?.length
      );
      if (hasEmptyState) {
        setErrorState(`Please enter dispute hours, minits and description`);
      } else if (hasEmptyDesState) {
        setErrorState(`Please enter description`);
      } else {
        setErrorState(`Please enter dispute hours or dispute minits`);
      }
    }
  };

  const disputed_hrs = selectedTimelogOfContractorDetails
    .filter((log) => log.disputed_hrs.length)
    .reduce((total, log) => total + parseFloat(log.disputed_hrs), 0);
  const disputed_mins = selectedTimelogOfContractorDetails
    .filter((log) => log.disputed_mins.length)
    .reduce((total, log) => total + parseFloat(log.disputed_mins), 0);
  return (
    <>
      <Box
        px={"20px"}
        mt={"30px"}
        sx={{
          height: "calc(100% - 265px)",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            overflow: "auto",
          }}
          gap={"20px"}
        >
          <Stack textAlign={"center"}>
            <Typography
              sx={{
                ...font14,
                fontWeight: 400,
                color: "#000",
              }}
            >
              You have selected{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedTimelogOfContractorDetails.length} entries{" "}
              </span>{" "}
              to dispute
            </Typography>
          </Stack>

          <DisputedEntriesCard error={errorState} />

          <AtsRowBetween
            sx={{
              minHeight: 43,
              px: "14px",
              mx: "30px",
              bgcolor: "#fff3f3",
              borderRadius: "6px",
            }}
          >
            <>
              <AtsTitleText
                text={"Total time disputed"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
              <AtsTitleText
                text={`${disputed_hrs ? disputed_hrs : 0}.${
                  disputed_mins ? disputed_mins : 0
                } hrs`}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
            </>
          </AtsRowBetween>
        </Stack>
      </Box>
      <AtsRow
        px={"50px"}
        pt={"30px"}
        pb={"50px"}
        gap={"50px"}
        justifyContent={"flex-end"}
      >
        <>
          <AtsCustomButton px={0} py={0}>
            <AtsTitleText text={"Cancel"} fw={400} textColor="#050505" />
          </AtsCustomButton>
          <AtsRoundedButton
            text="Next attach your evidence"
            fw={400}
            isInitial
            buttonWidth={220}
            buttonHeight={37}
            onClick={nextStep}
          />
        </>
      </AtsRow>
    </>
  );
};

export default DisputeEntries;
