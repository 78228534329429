import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";
import { COLORS } from "../theme";
import { CheckedIcon, MinusIcon, UnCheckBoxIcon } from "../assets/icons/Icons";

interface Props extends CheckboxProps {
  p?: number;
}

const AtsCheckbox = ({ p = 0.6, ...rest }: Props) => {
  return (
    <>
      <Checkbox
        {...rest}
        sx={{
          padding: p,
          "& .MuiSvgIcon-root": {
            width: "20px !important",
            height: "20px !important",
          },
          "&.Mui-checked": { color: COLORS.PRIMARY },
          "&.MuiCheckbox-indeterminate": { color: COLORS.PRIMARY },
        }}
        checkedIcon={<CheckedIcon strokeColor={COLORS.PRIMARY} />}
        icon={<UnCheckBoxIcon strokeColor={COLORS.LIGHT_GRAY} />}
        indeterminateIcon={<MinusIcon strokeColor={COLORS.PRIMARY} />}
      />
    </>
  );
};

export default AtsCheckbox;
