import { Avatar, Box, Stack } from "@mui/material";
import { SubmissionService } from "../../../Api/submissionServices";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  setCondidateTabValue,
  setJobId,
  setSubmissionId,
} from "../../../redux/slices/candidateSlice";
import {
  setCandidateDetails,
  setCandidateId,
  setIsCandidateDetailsDrawerOpen,
  setIsCandidateDetailsLoading,
} from "../../../redux/slices/submissionSlice";
import { useAppDispatch } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import { InterviewEventsResponse } from "../../../types/calendarTypes";
import { isNotNullOrUndefined } from "../../../utils/stringUtils";

interface IProps {
  cadidateData: InterviewEventsResponse;
}

const CtoInterview = ({ cadidateData }: IProps) => {
  const dispatch = useAppDispatch();
  const name = isNotNullOrUndefined(cadidateData)
    ? `${cadidateData[0]?.interview_candidate_data?.first_name ?? ""} ${
        cadidateData[0]?.interview_candidate_data?.last_name ?? ""
      }`
    : "";

  const getCandidateDetials = async (id: string) => {
    dispatch(setIsCandidateDetailsLoading(true));
    try {
      const response = await SubmissionService.getCandidateDetailsById({ id });
      if (response.results.length) {
        dispatch(setCandidateDetails(response.results[0]));

        dispatch(setCandidateDetails(response.results[0]));
      } else {
        dispatch(setCandidateDetails(null));
      }
    } catch (error) {
    } finally {
      dispatch(setIsCandidateDetailsLoading(false));
    }
  };

  const onViewInterview = (data: InterviewEventsResponse) => {
    dispatch(setSubmissionId(data.nid));
    dispatch(setJobId(data.job_id));
    dispatch(setCandidateId(data.interview_candidate_data.candidate_id));
    dispatch(setIsCandidateDetailsDrawerOpen(true));
    dispatch(setCondidateTabValue(4));
    getCandidateDetials(data.interview_candidate_data.candidate_id);
  };
  return (
    <Box>
      <Stack
        gap={"15px"}
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack gap={"10px"}>
          <AtsTitleText text={"Guests"} fs={16} fw={600} textColor="#000" />
          <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              src={
                isNotNullOrUndefined(cadidateData)
                  ? cadidateData[0]?.interview_candidate_data?.image
                  : ""
              }
            />
            <AtsTitleText
              text={name}
              fs={14}
              fw={400}
              textColor={COLORS.BLACK}
            />
          </Stack>
        </Stack>
        <Stack gap={"10px"}>
          <AtsTitleText
            text={"Description"}
            fs={16}
            fw={600}
            textColor="#000"
          />
          <AtsTitleText
            text={
              isNotNullOrUndefined(cadidateData)
                ? cadidateData[0]?.interview_description
                : ""
            }
            fs={14}
            fw={400}
            textColor={COLORS.BLACK}
          />
        </Stack>
      </Stack>
      <Stack
        pt={"15px"}
        pb={"5px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack gap={"4px"} direction={"row"} alignItems={"center"}>
          <AtsIcon icon="icon-edit-2" color={COLORS.PRIMARY} height="16px" />
          <AtsTitleText
            text={"Edit"}
            fs={12}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
        </Stack>
        <AtsTitleText
          text={"View interview details"}
          fs={12}
          fw={500}
          textColor={COLORS.PRIMARY}
          isCursor
          onClick={
            cadidateData ? () => onViewInterview(cadidateData[0]) : () => {}
          }
        />
      </Stack>
    </Box>
  );
};

export default CtoInterview;
