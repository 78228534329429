import axios from "axios";
import {
  CandidateEffortsParams,
  CandidateEffortsResonse,
  CandidateTimelogParams,
  CandidateTimelogResponse,
  DisputedData,
  DisputedTimesheetResponse,
  DisputeTimesheetRequest,
  TimeEntriesOfCandidateParams,
  TimelogEntriesResponse,
} from "../types/timesheetTypes";
import { PROJECT_BASE_URL } from "../utils/url";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  CANDIDATE_TIMELOG: "candidate/timelog",
  TIME_ENTRIES: "candidate/project/timesheet",
  DISPUTE_TIMESHEET: "candidate/timesheet/dispute/create",
  APPROVE_TIMESHEET: "candidate/timesheet/approve",
  RESOLVE_DISPUTE: "candidate/timesheet/dispute/resolve",
  GET_DISPUTED_DETAILS: "candidate/timesheet/dispute/get",
  CANDIDATE_EFFORTS_DETAILS: "timelog/get",
  UPLOAD_EVIDANCES:
    "/file/upload/node/disputed_time_log/field_dispute_evidences?_format=json",
};
const PROJECT_ENDPOINT = `${PROJECT_BASE_URL}${Endpoint.BASE}`;
const CANDIDATE_TIMELOG_URL = PROJECT_ENDPOINT + Endpoint.CANDIDATE_TIMELOG;
const TIME_ENTRIES_URL = PROJECT_ENDPOINT + Endpoint.TIME_ENTRIES;
const DISPUTE_TIMESHEET_URL = PROJECT_ENDPOINT + Endpoint.DISPUTE_TIMESHEET;
const APPROVE_TIMESHEET_URL = PROJECT_ENDPOINT + Endpoint.APPROVE_TIMESHEET;
const RESOLVE_DISPUTE_URL = PROJECT_ENDPOINT + Endpoint.RESOLVE_DISPUTE;
const GET_DISPUTED_DETAILS_URL =
  PROJECT_ENDPOINT + Endpoint.GET_DISPUTED_DETAILS;
const CANDIDATE_EFFORTS_DETAILS_URL =
  PROJECT_ENDPOINT + Endpoint.CANDIDATE_EFFORTS_DETAILS;

const UPLOAD_EVIDANCES_URL = PROJECT_BASE_URL + Endpoint.UPLOAD_EVIDANCES;

export class TimesheetServices {
  static async getCandidateTimelog(
    params: Partial<CandidateTimelogParams>
  ): Promise<CandidateTimelogResponse[]> {
    const endpoint = CANDIDATE_TIMELOG_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getTimeEntriesOfCandidate(
    params: Partial<TimeEntriesOfCandidateParams>
  ): Promise<TimelogEntriesResponse[]> {
    const endpoint = TIME_ENTRIES_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getDisputedDetails(params: {
    nid: string;
  }): Promise<DisputedData> {
    const endpoint = GET_DISPUTED_DETAILS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateEfforts(
    params: Partial<CandidateEffortsParams>
  ): Promise<Partial<CandidateEffortsResonse>> {
    const endpoint = CANDIDATE_EFFORTS_DETAILS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async disputeTimesheet(
    request: DisputeTimesheetRequest
  ): Promise<DisputedTimesheetResponse> {
    const endpoint = DISPUTE_TIMESHEET_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async approveTimelog(request: {
    nids: number[];
  }): Promise<CandidateTimelogResponse[]> {
    const endpoint = APPROVE_TIMESHEET_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async resolveDispute(request: {
    nid: number;
  }): Promise<CandidateTimelogResponse[]> {
    const endpoint = RESOLVE_DISPUTE_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async uploadEvidancesForTimesheet(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<any> {
    const response = await axios.post(UPLOAD_EVIDANCES_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }
}
