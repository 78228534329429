import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";

const useResponsiveWidth = ({
  mobileWidth,
  tabletWidth,
  desktopWidth,
  largeScreenWidth,
}: {
  mobileWidth: number;
  tabletWidth: number;
  desktopWidth: number;
  largeScreenWidth: number;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const [width, setWidth] = useState(desktopWidth);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        setWidth(mobileWidth);
      } else if (isTablet) {
        setWidth(tabletWidth);
      } else if (isDesktop) {
        setWidth(desktopWidth);
      } else {
        setWidth(largeScreenWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial width

    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile, isTablet, isDesktop, isLarge]);

  return width;
};

export default useResponsiveWidth;
