import { createContext, useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { CalendarServices } from "../Api/calendarServices";
import { InterviewsService } from "../Api/interviewServices";
import { getSubmissionState } from "../redux/slices/submissionSlice";
import { useAppSelector } from "../redux/store";
import { GetCandidateInterviewListByJobResponse } from "../types/calendarTypes";
import {
  CreateScoreCardOfCandidateParams,
  InterviewData,
  ScoreCardOfInterviewResponse,
  ScoreCardRatingResponse,
  ScoreCardTypeResponse,
} from "../types/interviewTypes";
import { getInterviewState } from "../redux/slices/interviewSlice";
import { getCandidateState } from "../redux/slices/candidateSlice";

type InterviewsModuleContextType = {
  interviewListLoading: boolean;
  createInterviewListLoading: boolean;
  interviewDetailsLoading: boolean;
  scoreCardOfInterviewLoading: boolean;
  scorecardRatingLoading: boolean;
  scorecardTypeLoading: boolean;
  createScorecardLoading: boolean;

  interviewList: GetCandidateInterviewListByJobResponse[];
  scoreCardRating: ScoreCardRatingResponse[];
  scoreCardType: ScoreCardTypeResponse[];
  interviewInfo: GetCandidateInterviewListByJobResponse | null;
  interviewDetails: InterviewData | null;
  scroreCardOfInterviewDetails: ScoreCardOfInterviewResponse | null;

  setInterviewInfo: React.Dispatch<
    React.SetStateAction<GetCandidateInterviewListByJobResponse | null>
  >;
  setScoreCardType: React.Dispatch<
    React.SetStateAction<ScoreCardTypeResponse[]>
  >;

  getInterviewList: (candidateId: string, job_id: string) => void;
  getScrorecardRatting: () => void;
  getScrorecardType: () => void;
  getInterviewDetails: (interview_id: string) => void;
  getScorecardOfInterview: (interview_id: string, candidate_id: string) => void;
  createScorecardForCandidate: (
    request: Partial<CreateScoreCardOfCandidateParams>
  ) => void;
  updateScorecardForCandidate: (
    request: Partial<CreateScoreCardOfCandidateParams>
  ) => void;
};

const InterviewsModule = createContext<InterviewsModuleContextType | null>(
  null
);

export const useInterviews = () =>
  useContext(InterviewsModule) as InterviewsModuleContextType;

export const InterviewsProvider = ({ children }: any) => {
  const { nid } = useParams();
  const { candidateDetails, candidateId } = useAppSelector(getSubmissionState);
  const { interviewTab } = useAppSelector(getInterviewState);
  const { jobId } = useAppSelector(getCandidateState);

  const [interviewList, setInterviewList] = useState<
    GetCandidateInterviewListByJobResponse[]
  >([]);
  const [scoreCardRating, setScoreCardRating] = useState<
    ScoreCardRatingResponse[]
  >([]);
  const [scoreCardType, setScoreCardType] = useState<ScoreCardTypeResponse[]>(
    []
  );
  const [interviewInfo, setInterviewInfo] =
    useState<GetCandidateInterviewListByJobResponse | null>(null);
  const [interviewDetails, setInterviewDetails] =
    useState<InterviewData | null>(null);
  const [scroreCardOfInterviewDetails, setScroreCardOfInterviewDetails] =
    useState<ScoreCardOfInterviewResponse | null>(null);

  const [interviewListLoading, setInterviewListLoading] =
    useState<boolean>(false);
  const [createInterviewListLoading, setCreateInterviewListLoading] =
    useState<boolean>(false);
  const [createScorecardLoading, setCreateScorecardLoading] =
    useState<boolean>(false);
  const [interviewDetailsLoading, setInterviewDetailsLoading] =
    useState<boolean>(false);
  const [scoreCardOfInterviewLoading, setScoreCardOfInterviewLoading] =
    useState<boolean>(false);
  const [scorecardRatingLoading, setScorecardRatingLoading] =
    useState<boolean>(false);
  const [scorecardTypeLoading, setScorecardTypeLoading] =
    useState<boolean>(false);

  /////////////  Interview List \\\\\\\\\\

  const getInterviewList = async (candidateId: string, job_id: string) => {
    setInterviewListLoading(true);
    try {
      if (interviewTab === 0) {
        const response = await CalendarServices.getCandidateInterviewByJob({
          job_id,
          candidate_id: candidateId,
        });
        setInterviewList(response.results);
      } else {
        const response = await InterviewsService.getAllInterviews({
          candidate_id: candidateId,
        });
        setInterviewList(response.results);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInterviewListLoading(false);
    }
  };
  /////////////  Interview List \\\\\\\\\\
  ///////////// get Interview details \\\\\\\\\\
  const getInterviewDetails = useCallback(async (interview_id: string) => {
    setInterviewDetailsLoading(true);
    try {
      const response = await InterviewsService.getInterviewDetails({
        nid: interview_id,
      });
      setInterviewDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setInterviewDetailsLoading(false);
    }
  }, []);
  ///////////// get Interview details \\\\\\\\\\
  ///////////// get score card of interview \\\\\\\\\\
  const getScorecardOfInterview = async (
    interview_id: string,
    candidate_id: string
  ) => {
    setScoreCardOfInterviewLoading(true);
    try {
      const response = await InterviewsService.getScroreCardOfInterview({
        interview_id,
        candidate_id,
      });
      setScroreCardOfInterviewDetails(response);
    } catch (error) {
      console.error(error);
      setScroreCardOfInterviewDetails(null);
    } finally {
      setScoreCardOfInterviewLoading(false);
    }
  };
  ///////////// get score card of interview \\\\\\\\\\
  ///////////// get score card rating \\\\\\\\\\
  const getScrorecardRatting = async () => {
    setScorecardRatingLoading(true);
    try {
      const response = await InterviewsService.getScroreCardRating();
      setScoreCardRating(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setScorecardRatingLoading(false);
    }
  };
  ///////////// get score card rating \\\\\\\\\\
  ///////////// get score card type \\\\\\\\\\
  const getScrorecardType = async () => {
    setScorecardTypeLoading(true);
    try {
      const response = await InterviewsService.getScroreCardType();
      const addResponse = response.results.map((item) => ({
        ...item,
        rating: 0,
        comments: "",
      }));
      setScoreCardType(addResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setScorecardTypeLoading(false);
    }
  };
  ///////////// get score card type \\\\\\\\\\
  ///////////// create score card for candidate \\\\\\\\\\
  const createScorecardForCandidate = async (
    request: Partial<CreateScoreCardOfCandidateParams>
  ) => {
    setCreateScorecardLoading(true);
    try {
      const response = await InterviewsService.createScoreCardForCandidate(
        request
      );
      //   setScroreCardOfInterviewDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setCreateScorecardLoading(false);
    }
  };
  ///////////// create score card for candidate \\\\\\\\\\
  ///////////// update score card for candidate \\\\\\\\\\
  const updateScorecardForCandidate = async (
    request: Partial<CreateScoreCardOfCandidateParams>
  ) => {
    setCreateScorecardLoading(true);
    try {
      const response = await InterviewsService.updateScoreCardForCandidate(
        request
      );
      //   setScroreCardOfInterviewDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setCreateScorecardLoading(false);
    }
  };
  ///////////// update score card for candidate \\\\\\\\\\

  return (
    <InterviewsModule.Provider
      value={{
        interviewList,
        scoreCardRating,
        scoreCardType,
        interviewInfo,
        interviewDetails,
        scroreCardOfInterviewDetails,
        interviewListLoading,
        createInterviewListLoading,
        interviewDetailsLoading,
        scoreCardOfInterviewLoading,
        scorecardRatingLoading,
        scorecardTypeLoading,
        createScorecardLoading,
        getInterviewList,
        getInterviewDetails,
        getScrorecardRatting,
        getScrorecardType,
        getScorecardOfInterview,
        createScorecardForCandidate,
        updateScorecardForCandidate,

        setInterviewInfo,
        setScoreCardType,
      }}
    >
      {children}
    </InterviewsModule.Provider>
  );
};
