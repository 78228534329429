import { Box, Dialog, Grid, Stack } from "@mui/material";
import AtsBackLink from "../../../../../../../../../components/AtsBackLink";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import UserInfo from "../../../../../../../../../layout/Header/UserInfo";
// import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import { BORDERS } from "../../../../../../../../../theme";
import { WarningIcon } from "../../../../../../../assets/Icons";
import JobDetailsAndTerms from "../components/JobDetailsAndTerms";
import OfferSent from "../components/OfferSent";
import PriceSummary from "../components/PriceSummary";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import {
  getMakeAnOfferState,
  setIsOpenMakeAnOfferDialog,
} from "../../../../../../../../../redux/slices/makeAnOfferSlice";
import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";

const MakeAnOfferDialog = () => {
  const dispatch = useAppDispatch();
  const { isOpenMakeAnOfferDialog } = useAppSelector(getMakeAnOfferState);
  const { isCompleteRequest } = useMakeAnOffer();
  return (
    <>
      <Dialog open={isOpenMakeAnOfferDialog} fullScreen>
        <Stack
          direction={"row"}
          className="header"
          sx={{ justifyContent: "space-between" }}
        >
          <Box
            sx={{
              height: "50px",
            }}
          >
            <img
              height={"100%"}
              src={require("../../../../../../../../../assets/images/logo.png")}
              alt="esteemed"
            />
          </Box>
          <Stack direction={"row"} alignItems={"center"} gap={2} mx={3}>
            <UserInfo />
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 83px)",
          }}
        >
          <Box
            sx={{
              height: "97%",
              // overflow: "scroll",
              p: 2,
            }}
          >
            <Stack
              // m={2}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                border: BORDERS.GRAY,
                borderRadius: 1,
                height: "100%",
              }}
            >
              {isCompleteRequest ? (
                <OfferSent />
              ) : (
                <>
                  <Stack
                    direction={"row"}
                    px={4}
                    py={2}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <AtsTitleText text={"Make an offer"} fs={24} fw={600} />
                    <AtsBackLink
                      text="Back to Candidate’s Details"
                      onClick={() =>
                        dispatch(setIsOpenMakeAnOfferDialog(false))
                      }
                    />
                  </Stack>
                  <Stack
                    sx={{
                      height: "calc(100% - 63px)",
                    }}
                    px={4}
                    py={2}
                  >
                    <Grid container columnSpacing={"50px"} height={"97%"}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        lg={8.5}
                        xl={9}
                        xxl={9}
                        height={"100%"}
                      >
                        <Stack height={"100%"}>
                          <JobDetailsAndTerms />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                            sx={{
                              pt: "10px",
                            }}
                          >
                            <WarningIcon />
                            <AtsTitleText
                              text={
                                "Your project won’t be confirmed until your service provider accepts your request (within 24 hours)."
                              }
                              fs={14}
                              fw={400}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={4} md={5} lg={3.5} xl={3} xxl={3}>
                        <PriceSummary />
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default MakeAnOfferDialog;
