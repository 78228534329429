import axios from "axios";
import {
  MSAcontractResponse,
  SOWcontractReposnse,
  SOWcontractsParams,
  UpdatecontractRequest,
} from "../types/contractTypes";

const Endpoint = {
  BASE: "ats/api/v1/",
  MSA_CONTRACT: "master-contracts?_format=json",
  SOW_CONTRACT: "sow?_format=json",
  SIGN_UPDATE_CONTRACT: "sign-client-contract/update",
};

const MSA_CONTRACT_URL = Endpoint.BASE + Endpoint.MSA_CONTRACT;
const SOW_CONTRACT_URL = Endpoint.BASE + Endpoint.SOW_CONTRACT;
const SIGN_UPDATE_CONTRACT_URL = Endpoint.BASE + Endpoint.SIGN_UPDATE_CONTRACT;

export class ContractServices {
  static async getMsaContracts(): Promise<MSAcontractResponse[]> {
    const endpoint = MSA_CONTRACT_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getSowContracts(
    params: Partial<SOWcontractsParams>
  ): Promise<SOWcontractReposnse[]> {
    const endpoint = SOW_CONTRACT_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async updateContract(
    request: UpdatecontractRequest
  ): Promise<SOWcontractReposnse[]> {
    const endpoint = SIGN_UPDATE_CONTRACT_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
