import { Box, Stack } from "@mui/material";
import React from "react";
import { BG_COLORS } from "../../theme";
import AtsRoundedButton from "../AtsRoundedButton";
import AtsTitleText from "../AtsTitleText";

interface IStepProps {
  isActiveStep?: boolean;
  isShowSubmitBtn?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isUpdate?: boolean;
  steps: number[];
  handleBack: () => void;
  handleNext: () => void;
  handleSubmit: () => void;
}

const StepComponent = ({
  isActiveStep,
  isShowSubmitBtn,
  isDisabled,
  isLoading,
  isUpdate = false,
  steps,
  handleBack,
  handleNext,
  handleSubmit,
}: IStepProps) => {
  return (
    <>
      <Stack direction="row" gap={1}>
        {steps.map((prog, index) => (
          <Box
            key={index}
            sx={{
              height: "8px",
              width: "100%",
              bgcolor: "#dadada",
              borderRadius: 0,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: `${prog}%`,
                bgcolor: "#3c854d",
              }}
            />
          </Box>
        ))}
      </Stack>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", py: 2, px: 6 }}
      >
        <Stack
          sx={{
            bgcolor: "transparent",
            borderRadius: "20px",
            pt: "5px",
            pb: "1px",
            px: "17px",
            cursor: isActiveStep || isLoading ? "not-allowed" : "pointer",
            ":hover": { bgcolor: BG_COLORS.GRAY },
          }}
        >
          <AtsTitleText
            text="Back"
            fs={16}
            fw={600}
            isUnderline
            textColor="#1e1e1e"
            onClick={isActiveStep ? () => {} : handleBack}
          />
        </Stack>
        {isShowSubmitBtn ? (
          <Box>
            <AtsRoundedButton
              disabled={isDisabled || isLoading}
              text={isUpdate ? "Update" : "Submit"}
              onClick={handleSubmit}
              loading={isLoading}
              buttonHeight={30}
            />
          </Box>
        ) : (
          <AtsRoundedButton
            buttonWidth={68}
            buttonHeight={30}
            disabled={isDisabled || isLoading}
            text="Next"
            onClick={handleNext}
            loading={isLoading}
          />
        )}
      </Stack>
    </>
  );
};

export default StepComponent;
