import { Skeleton, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface ITextProps extends TypographyProps {
  text: string | number;
  loading?: boolean;
}

const AtsText = ({ text, loading, ...rest }: ITextProps) => {
  return (
    <Typography {...rest}>
      {loading ? <Skeleton variant="text" /> : text}
    </Typography>
  );
};

export default AtsText;
