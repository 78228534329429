import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MessageServices } from "../../Api/messageServices";
import { ChatProps, MessageList } from "../../types/messagesTypes";

interface Message {
  id: string;
  content: string;
  conversationId: string;
  // Add other message fields as needed
}

interface TypingIndicator {
  conversationId: string;
  typing: boolean;
}

interface MessageState {
  messages: Message[];
  typingIndicators: { [conversationId: string]: boolean };
  conversation: Partial<ChatProps>;
  conversationList: MessageList[];
  typing: boolean;
}

const initialState: MessageState = {
  messages: [],
  typingIndicators: {},
  conversation: {},
  conversationList: [],
  typing: false,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    addNewMessage(state: MessageState, action: PayloadAction<Message>) {
      state.messages.push(action.payload);
    },
    setTypingIndicator(state: MessageState, action) {
      state.typingIndicators[action.payload.conversationId] =
        action.payload.typing;
    },
    setTyping(state: MessageState, action) {
      const { payload } = action;
      state.typing = payload;
    },
    setConversation(state: MessageState, action) {
      const { payload } = action;
      state.conversation = payload;
    },
    setConversationList(state: MessageState, action) {
      const { payload } = action;
      state.conversationList = payload;
    },
  },
});

export const {
  addNewMessage,
  setTypingIndicator,
  setTyping,
  setConversation,
  setConversationList,
} = messageSlice.actions;
export const getMessageState = (state: RootState) => state.message;
export default messageSlice.reducer;
