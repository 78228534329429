import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserInformation } from "../../types/userManagementTypes";

interface DashboardState {
  isOpenChatWindow: boolean;
  isOpenChatbotWindow: boolean;
  isOpenSubMenu: boolean;
  userInfoLoading: boolean;
  userInformation: Partial<UserInformation>;
}

const initialState: DashboardState = {
  isOpenChatWindow: false,
  isOpenChatbotWindow: false,
  isOpenSubMenu: false,
  userInfoLoading: false,
  userInformation: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIsOpenChatWindow: (state: DashboardState, action) => {
      const { payload } = action;
      state.isOpenChatWindow = payload;
    },
    setIsOpenChatBotWindow: (state: DashboardState, action) => {
      const { payload } = action;
      state.isOpenChatbotWindow = payload;
    },
    setIsOpenSubMenu: (state: DashboardState, action) => {
      const { payload } = action;
      state.isOpenSubMenu = payload;
    },
    setUserInformation: (state: DashboardState, action) => {
      const { payload } = action;
      state.userInformation = payload;
    },
    setUserInfoLoading: (state: DashboardState, action) => {
      const { payload } = action;
      state.userInfoLoading = payload;
    },
  },
});

export const {
  setIsOpenChatWindow,
  setIsOpenSubMenu,
  setIsOpenChatBotWindow,
  setUserInformation,
  setUserInfoLoading,
} = dashboardSlice.actions;
export const getDashboardState = (state: RootState) => state.dashboard;
export default dashboardSlice.reducer;
