import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsText from "../../../../../../../../../components/AtsText";
import {
  BG_COLORS,
  BOX_SHADOW,
  COLORS,
} from "../../../../../../../../../theme";
import { useInterviews } from "../../../../../../../../../providers/InterviewsProvider";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { Collapse } from "@mui/material";
import { useClickOutSide } from "../../../../../../../../../utils/common";

const InterviewDetails = () => {
  const {
    interviewInfo,
    getInterviewDetails,
    interviewDetails,
    interviewDetailsLoading,
  } = useInterviews();

  useEffect(() => {
    isNotNullOrUndefined(interviewInfo) &&
      getInterviewDetails(interviewInfo.nid);
  }, []);

  const meetingName = isNotNullOrUndefined(interviewDetails)
    ? interviewDetails?.subject
    : "--";
  const description = isNotNullOrUndefined(interviewDetails)
    ? interviewDetails?.description
    : "--";
  const interviewType = isNotNullOrUndefined(interviewDetails)
    ? interviewDetails.type.title
    : "--";
  const interviewLink = isNotNullOrUndefined(interviewDetails)
    ? isNotNullOrUndefined(interviewDetails.link)
      ? interviewDetails.link
      : ""
    : "";
  const nameOfInterviewers = isNotNullOrUndefined(interviewDetails)
    ? interviewDetails.interviewer.map((interviewer) => {
        const name = `${interviewer?.first_name ?? "-"} ${
          interviewer?.last_name ?? "-"
        }`;
        return name;
      })
    : [];
  const emailOfInterviewers = isNotNullOrUndefined(interviewDetails)
    ? interviewDetails.interviewer.map((interviewer) => {
        const email = `${interviewer?.email ?? "-"} `;
        return email;
      })
    : [];

  const TextWrapper = ({ text, value }: { text: string; value?: string }) => {
    return (
      <Stack
        direction={"row"}
        sx={{
          mb: "20px",
        }}
      >
        <Stack sx={{ width: "50%" }}>
          <AtsTitleText
            text={text}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Stack>
        <Stack sx={{ width: "50%" }}>
          {interviewDetailsLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <AtsTitleText text={value} fs={14} fw={400} />
          )}
        </Stack>
      </Stack>
    );
  };
  const LinkTextWrapper = ({
    text,
    value,
  }: {
    text: string;
    value: string;
  }) => {
    return (
      <Stack
        direction={"row"}
        sx={{
          mb: "20px",
        }}
      >
        <Stack sx={{ width: "50%" }}>
          <AtsTitleText
            text={text}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Stack>
        <Stack sx={{ width: "50%" }}>
          {interviewDetailsLoading ? (
            <Skeleton variant="text" width={100} />
          ) : value.length ? (
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: COLORS.PRIMARY,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(value, "_blank");
              }}
            >
              {value}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: COLORS.PRIMARY,
                cursor: "pointer",
              }}
            >
              {"--"}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };
  const MoreTextWrapper = ({
    text,
    value,
  }: {
    text: string;
    value?: string[];
  }) => {
    const [show, setShow] = useState<boolean>(false);

    const domNode = useClickOutSide(() => {
      setShow(false);
    });
    return (
      <Stack
        direction={"row"}
        sx={{
          mb: "20px",
        }}
      >
        <Stack sx={{ width: "50%" }}>
          <AtsTitleText
            text={text}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Stack>
        <Stack sx={{ width: "50%" }}>
          {interviewDetailsLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                display: "flex",
              }}
            >
              {value[0]}
              {"  "}
              {value.length > 1 && (
                <div
                  className=""
                  ref={domNode}
                  style={{
                    position: "relative",
                    marginLeft: "3px",
                  }}
                >
                  <span
                    style={{
                      color: COLORS.PRIMARY,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShow(!show)}
                  >{` +${value.length - 1} More`}</span>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      width: "max-content",
                    }}
                  >
                    <Collapse
                      in={show}
                      sx={{
                        boxShadow: BOX_SHADOW.GRAY,
                        borderRadius: "5px",
                        bgcolor: BG_COLORS.PRIMARY,
                      }}
                    >
                      <Stack
                        gap={"5px"}
                        sx={{
                          py: "10px",
                          px: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        {value.slice(1).map((atten) => (
                          <AtsTitleText
                            key={atten}
                            fs={12}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                            text={atten}
                          />
                        ))}
                      </Stack>
                    </Collapse>
                  </Box>
                </div>
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };
  return (
    <Box
      sx={{
        py: 3,
      }}
    >
      <TextWrapper text="Interview Type" value={interviewType} />
      <LinkTextWrapper text="Web Conferencing Details" value={interviewLink} />
      <TextWrapper text="Meeting Name" value={meetingName} />
      <MoreTextWrapper text="Name Of Interviewer" value={nameOfInterviewers} />
      <MoreTextWrapper
        text="Email Of Interviewer"
        value={emailOfInterviewers}
      />
      <TextWrapper text="Details Of Meeting" value={description} />
    </Box>
  );
};

export default InterviewDetails;
