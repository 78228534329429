import { Box, Collapse, Skeleton, Stack } from "@mui/material";
import { useState } from "react";
import AtsCustomButton from "../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import AtsRow from "../../../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../../../../theme";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";
import DisputedEntriesCard from "../../components/DisputedEntriescard";
import { DisputeResolutionProps } from "../DisputeResolutionDrawer";
import { TimeEntry } from "../../../../../../../../types/timesheetTypes";

const Summary = ({
  status,
  loading,
  disputedTimesheet,
}: DisputeResolutionProps) => {
  const [show, setShow] = useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState<number>(0);
  const date = (date) => {
    const formatted = new Date(Number(date) * 1000);
    const formattedDate = formatted.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      <AtsTitleText text={"Summary"} fs={18} fw={600} textColor={"#000"} />
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Timesheet Date:"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <Box sx={{}}>
            {loading ? (
              <Skeleton variant="text" width={200} />
            ) : (
              <AtsTitleText
                text={
                  disputedTimesheet
                    ? isNotNullOrUndefined(
                        disputedTimesheet.disputed_time_logs[selectedTimesheet]
                      )
                      ? date(
                          disputedTimesheet.disputed_time_logs[
                            selectedTimesheet
                          ].date
                        )
                      : "--"
                    : "--"
                }
                fs={14}
                fw={400}
                textColor={"#1c1c1c"}
              />
            )}
          </Box>
        </>
      </AtsRow>
      <Stack>
        <AtsRow gap={"89px"}>
          <>
            <Box
              sx={{
                width: 200,
              }}
            >
              <AtsTitleText
                text={"Disputed entries:"}
                fs={14}
                fw={500}
                textColor={COLORS.DARK_GRAY}
              />
            </Box>
            <AtsRow gap={"7.2px"} sx={{}}>
              {loading ? (
                <Skeleton variant="text" width={80} />
              ) : (
                <>
                  <AtsTitleText
                    text={
                      disputedTimesheet
                        ? disputedTimesheet.disputed_time_logs.length
                        : 0
                    }
                    fs={14}
                    fw={400}
                    textColor={"#1c1c1c"}
                  />
                  <AtsCustomButton px={0} py={0} onClick={() => setShow(!show)}>
                    <AtsRow gap={"4px"}>
                      <>
                        <AtsTitleText
                          text={show ? "hide" : "show"}
                          fs={12}
                          fw={500}
                          textColor={COLORS.PRIMARY}
                        />
                        <AtsIcon
                          icon={`icon-arrow-circle-${show ? "up" : "down"}`}
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      </>
                    </AtsRow>
                  </AtsCustomButton>
                </>
              )}
            </AtsRow>
          </>
        </AtsRow>
        <Collapse in={show}>
          <Box
            sx={{
              py: "16px",
              px: "14px",
            }}
          >
            {disputedTimesheet && (
              <DisputedEntriesCard
                disputedEntries={disputedTimesheet}
                setSelectedTimesheet={setSelectedTimesheet}
                selectedTimesheet={selectedTimesheet}
              />
            )}
          </Box>
        </Collapse>
      </Stack>
      <Stack direction={"row"} alignItems={"flex-start"} gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <Stack gap={"7px"}>
              <AtsTitleText
                text={"Your note:"}
                fs={14}
                fw={500}
                textColor={COLORS.DARK_GRAY}
              />
              <AtsTitleText
                text={
                  "This is forwarded to the contractor and the accounting manager"
                }
                fs={11}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
            </Stack>
          </Box>
          <Box sx={{}}>
            {loading ? (
              <Skeleton variant="text" width={200} />
            ) : (
              <AtsTitleText
                text={disputedTimesheet ? disputedTimesheet?.note : "--"}
                fs={14}
                fw={400}
                textColor={"#1c1c1c"}
              />
            )}
          </Box>
        </>
      </Stack>
    </>
  );
};

export default Summary;
