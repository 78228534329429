import { Skeleton, Stack } from "@mui/material";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../../../../../theme";

interface Props {
  title: string;
  icon: string;
  rate: string | number;
  loading: boolean;
}

const RatingView = ({ title, icon, rate, loading }: Props) => {
  return (
    <>
      <Stack gap={1}>
        <AtsIcon icon={icon} fs={30} />
        <Stack
          sx={{
            minHeight: 42,
          }}
        >
          <AtsTitleText
            text={title}
            fs={13}
            fw={500}
            textColor={COLORS.BLACK}
          />
        </Stack>
        {loading ? (
          <Skeleton variant="rectangular" width={50} height={28} />
        ) : (
          <AtsTitleText text={rate} fs={24} fw={500} textColor={COLORS.BLACK} />
        )}
      </Stack>
    </>
  );
};

export default RatingView;
