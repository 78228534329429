import React from "react";
import { GetModifyRequisitionResponse } from "../../../types/insightsTypes";
import { Map, CircleMarker, TileLayer, Tooltip } from "react-leaflet";

interface IProps {
  requisitionsByLocation: GetModifyRequisitionResponse[];
}

const LocationMapContainer = ({ requisitionsByLocation }: IProps) => {
  const getLocationData = () => {
    const data = {
      city: requisitionsByLocation,
      minLat: -6.1751,
      maxLat: 55.7558,
      minLong: 37.6173,
      maxLong: 139.6917,
    };
    return data;
  };

  const centerLat = (getLocationData().minLat + getLocationData().maxLat) / 2;
  const distanceLat = getLocationData().maxLat - getLocationData().minLat;
  const bufferLat = distanceLat * 0.05;
  const centerLong =
    (getLocationData().minLong + getLocationData().maxLong) / 2;
  const distanceLong = getLocationData().maxLong - getLocationData().minLong;
  const bufferLong = distanceLong * 0.05;
  return (
    <Map
      style={{ height: "100%", width: "100%", borderRadius: "10px" }}
      zoom={1}
      center={[centerLat, centerLong]}
      bounds={[
        [
          getLocationData().minLat - bufferLat,
          getLocationData().minLong - bufferLong,
        ],
        [
          getLocationData().maxLat + bufferLat,
          getLocationData().maxLong + bufferLong,
        ],
      ]}
    >
      <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {getLocationData().city.map((city, k) => {
        const radius = 5 * city["count"];
        console.log("radius", radius, city["count"]);
        return (
          <CircleMarker
            key={k}
            center={[city["coordinates"][1], city["coordinates"][0]]}
            radius={radius}
            fillOpacity={0.5}
            stroke={false}
          >
            <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
              <span>
                {city["job_location"] + ": " + "Count" + " " + city["count"]}
              </span>
            </Tooltip>
          </CircleMarker>
        );
      })}
    </Map>
  );
};

export default LocationMapContainer;
