import React, { useEffect, useRef, useState } from "react";
import {
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-sdk-ng/esm";
import { useCallingStore } from "../../../../../providers/CallingProvider";
import { Avatar, Box, Grid, IconButton, Stack } from "@mui/material";
import AtsCard from "../../../../../components/AtsCard";
import { BG_COLORS, BORDERS, BOX_SHADOW, COLORS } from "../../../../../theme";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { useMessages } from "../../../../../providers/MessagesProvider";
import AtsRow from "../../../../../components/AtsRow";
import AtsIcon from "../../../../../components/AtsIcon";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";

const VideoCallingBox = () => {
  const {
    callStatus,
    isVoiceCalling,
    storeCallInfo,
    callStarted,
    agoraOption,
    setCallingProfile,
  } = useCallingStore();
  const {
    messengerState,
    messengersList,
    onAcceptCall,
    onOutgoingCallEnd,
    messenger,
  } = useMessages();
  const [localTracks, setLocalTracks] = useState([]);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [state, setState] = useState({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
    isConnecting: false,
  });
  const client = useRef(null);
  let cron: NodeJS.Timeout;

  const getAgoraOption = () => {
    if (agoraOption) {
      return agoraOption;
    } else {
      return {
        appId: "ee60fe4066a64c009c132ff04475833a",
        channel: "test",
        token: null,
        uid: 0,
      };
    }
  };

  const initAgora = async () => {
    const { appId, channel, token, uid } = getAgoraOption();
    const channelName = channel;
    try {
      client.current = createClient({ mode: "rtc", codec: "vp8" });
      await client.current.join(appId, channelName, token, uid);
      console.log("SHAILENDRA____Joined channel successfully!");

      const [microphoneTrack, cameraTrack] =
        await createMicrophoneAndCameraTracks();
      setLocalTracks([microphoneTrack, cameraTrack]);

      await client.current.publish([microphoneTrack, cameraTrack]);
      console.log("SHAILENDRA____Publish success!");
    } catch (error) {
      console.error("Failed to join or publish:", error);
      alert(
        "Failed to join the channel or publish local tracks. Please try again."
      );
    }
  };

  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      console.log("SHAILENDRA____Permissions granted!");
    } catch (err) {
      console.error("Permission denied", err);
      alert("Please allow camera and microphone access for the call.");
    }
  };

  useEffect(() => {
    initAgora();
    requestPermissions();
    client.current.on("user-published", async (user, mediaType) => {
      console.log("SHAILENDRA____🚀 ~ client.current.on ~ user:", user);
      try {
        if (user) {
          await client.current.subscribe(user, mediaType);
          console.log("SHAILENDRA____Subscribe success");

          if (mediaType === "video") {
            const remoteVideoTrack = user.videoTrack;
            setRemoteUsers((prev) => [...prev, user]);
            remoteVideoTrack.play(`user-${user.uid}`);
          }

          if (mediaType === "audio") {
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack.play();
          }
        } else {
          console.warn("Attempted to subscribe to a non-existent user.");
        }
      } catch (error) {
        console.error("Error subscribing to user:", error);
      }
    });

    client.current.on("user-unpublished", (user) => {
      setRemoteUsers((prev) => prev.filter((u) => u.uid !== user.uid));
    });

    return () => {
      pauseTimer();
      resetTimer();
      localTracks.forEach((track) => {
        track.stop();
        track.close();
      });
      if (client.current) {
        client.current
          .leave()
          .then(() => {
            console.log("SHAILENDRA____Left the channel successfully.");
          })
          .catch((error) => {
            console.error("Failed to leave the channel:", error);
          });
      }
    };
  }, []);

  useEffect(() => {
    onStartVoiceCalling();
  }, [callStarted]);

  const startTimer = () => {
    pauseTimer();
    cron = setInterval(timer, 1000);
  };

  const pauseTimer = () => {
    clearInterval(cron);
  };

  const resetTimer = () => {
    setState((prev) => ({
      ...prev,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }));
  };

  const timer = () => {
    setState((prev) => {
      let second = prev.second + 1;
      let minute = prev.minute;
      let hour = prev.hour;

      if (second === 60) {
        second = 0;
        minute++;
      }
      if (minute === 60) {
        minute = 0;
        hour++;
      }

      return { ...prev, second, minute, hour };
    });
  };

  const onStartVoiceCalling = async () => {
    try {
      if (isVoiceCalling) await client.current.publish(localTracks[0]);
      else await client.current.publish(localTracks);
      setTimeout(() => {
        startTimer();
      }, 1000);
      console.log("SHAILENDRA____Publish success!");
    } catch (error) {
      console.error("Got an error", error);
    }
  };

  const onAcceptCalling = async () => {
    setState((prev) => ({ ...prev, isConnecting: true }));
    await initAgora();
    onAcceptCall();
    setState((prev) => ({ ...prev, isConnecting: false }));
  };

  // Mute/Unmute Functionality
  const toggleMute = () => {
    if (isMuted) {
      localTracks[0].setEnabled(true); // Unmute the microphone
      setIsMuted(false);
    } else {
      localTracks[0].setEnabled(false); // Mute the microphone
      setIsMuted(true);
    }
  };

  // Video On/Off Functionality
  const toggleVideo = () => {
    if (isVideoOff) {
      localTracks[1].setEnabled(true); // Turn video on
      setIsVideoOff(false);
    } else {
      localTracks[1].setEnabled(false); // Turn video off
      setIsVideoOff(true);
    }
  };

  // End Call Functionality
  const endCall = () => {
    localTracks.forEach((track) => {
      track.stop();
      track.close();
    });
    client.current.leave();
    setLocalTracks([]);
    setRemoteUsers([]);
    onOutgoingCallEnd();
  };

  const findImage = isNotNullOrUndefined(storeCallInfo)
    ? messengersList
        .filter((messenger) => !messenger.isGroupChat)
        .find(
          (messenger) => messenger?.recipient?.id === storeCallInfo?.callBy?.id
        )
    : null;

  return (
    <Box
      sx={{
        m: "10px",
        border: BORDERS.GRAY,
        borderRadius: "6px",
        p: "10px",
        height: "100%",
      }}
    >
      <Stack gap={"20px"} sx={{ height: "100%" }}>
        <AtsRow
          gap={"10px"}
          sx={{
            pl: "10px",
            pr: "10px",
            py: "15px",
            boxShadow: BOX_SHADOW.GRAY,
            borderRadius: "6px",
          }}
        >
          <>
            {isNotNullOrUndefined(findImage) ? (
              findImage?.recipient ? (
                <Avatar src={findImage?.recipient?.picture} />
              ) : (
                <Avatar />
              )
            ) : messengerState?.image ? (
              <Avatar src={messengerState.image} />
            ) : (
              <Avatar />
            )}
            <Stack gap={"8px"} flex={1}>
              <AtsTitleText
                text={
                  isNotNullOrUndefined(storeCallInfo)
                    ? storeCallInfo.callBy?.fullName
                    : messengerState?.name
                }
                fw={400}
              />
              <AtsTitleText
                text={""}
                fs={12}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
              />
            </Stack>
          </>
        </AtsRow>
        <Box
          sx={{
            height: "calc(100% - 140px)",
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              my: "10px",
              px: "10px",
              height: "100%",
            }}
          >
            <Grid
              container
              columnSpacing={1}
              sx={{
                height: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  borderRadius: "10px",
                }}
              >
                <Box
                  className="video_player"
                  sx={{
                    backgroundColor: BG_COLORS.GRAY,
                    borderRadius: "10px",
                    height: "100%",
                  }}
                  id="local-player"
                >
                  {/* Render the local video */}
                </Box>
              </Grid>
              {remoteUsers.map((user) => (
                <Grid
                  item
                  key={user.uid}
                  xs={12}
                  sm={6}
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    className="video_player"
                    sx={{
                      backgroundColor: BG_COLORS.GRAY,
                      borderRadius: "10px",
                      height: "100%",
                    }}
                    id={`user-${user.uid}`}
                  >
                    {/* Remote user's video will be rendered in this box */}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <AtsRow
          gap={"10px"}
          justifyContent={"center"}
          sx={{
            pl: "10px",
            pr: "10px",
            py: "15px",
            borderRadius: "50px",
            bgcolor: COLORS.GRAY,

            mx: "50px",
          }}
        >
          <>
            <IconButton
              sx={{
                bgcolor: BG_COLORS.PRIMARY,
              }}
              onClick={toggleMute}
            >
              <AtsIcon
                height="20px"
                fs={20}
                color={COLORS.PRIMARY}
                icon={`icon-${isMuted ? "microphone-slash-1" : "microphone-2"}`}
              />
            </IconButton>
            <IconButton
              sx={{
                bgcolor: BG_COLORS.PRIMARY,
              }}
              onClick={toggleVideo}
            >
              <AtsIcon
                height="20px"
                fs={20}
                color={COLORS.PRIMARY}
                icon={`icon-${isVideoOff ? "video-slash" : "video"}`}
              />
            </IconButton>
            {!callStarted && callStatus === "INCOMING" && (
              <IconButton
                sx={{
                  bgcolor: BG_COLORS.GREEN,
                }}
                onClick={onAcceptCalling}
              >
                <AtsIcon
                  height="20px"
                  fs={20}
                  icon={`icon-call`}
                  color={COLORS.GREEN}
                />
              </IconButton>
            )}
            <IconButton
              sx={{
                bgcolor: BG_COLORS.RED,
              }}
              onClick={endCall}
            >
              <AtsIcon
                height="20px"
                fs={20}
                icon={`icon-call-slash`}
                color={COLORS.RED}
              />
            </IconButton>
          </>
        </AtsRow>
      </Stack>
    </Box>
  );
};

export default VideoCallingBox;
