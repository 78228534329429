import { createSlice } from "@reduxjs/toolkit";
import {
  CandidateListResponse,
  ContractorByProjectResponse,
  ContractorRoleResponse,
} from "../../types/projectTypes";
import { RootState } from "../store";
import {
  ContractorPlacementResponse,
  GetContractorOnboardingResponse,
} from "../../types/onboardingTypes";
import { CandidateEffortsResonse } from "../../types/timesheetTypes";

const initialContractorPlacement = {
  title: "",
  job_title: "",
  start_date: "",
  end_date: "",
  bill_rate: "00.00",
  bill_salary: "00.00",
  onboarding_date: "",
  onboarding_status: "",
  employment_type: "",
  employment_type_id: "",
  hours_per_week: "0",
  category: "",
  can_title: "",
  industry: "",
  user_picture: "",
};

interface ContractorSliceState {
  selectedContractor: Partial<ContractorRoleResponse>;
  contractors: CandidateListResponse[];
  contractorPlacmentDetails: ContractorPlacementResponse;
  isContractorDetailsDrawerOpen: boolean;
  isContractorOnboarded: boolean;
  loading: boolean;
  error: string;
  selectedContractorDrawerTab: number;
  selectedContractorTimesheet: ContractorByProjectResponse | null;
  contractorOnboardingDetails: GetContractorOnboardingResponse | null;
  contractorEffortsDetails: Partial<CandidateEffortsResonse> | null;
}

const initialState: ContractorSliceState = {
  selectedContractor: {},
  contractors: [],
  contractorPlacmentDetails: initialContractorPlacement,
  isContractorDetailsDrawerOpen: false,
  isContractorOnboarded: false,
  loading: false,
  error: "",
  selectedContractorDrawerTab: 0,
  selectedContractorTimesheet: null,
  contractorOnboardingDetails: null,
  contractorEffortsDetails: null,
};

const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setSelectedContractor: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.selectedContractor = payload;
    },
    setContractors: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.contractors = payload;
    },
    setIsContractorDetailsDrawerOpen: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.isContractorDetailsDrawerOpen = payload;
    },
    setSelectedContractorDrawerTab: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.selectedContractorDrawerTab = payload;
    },
    setSelectedContractorTimesheet: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.selectedContractorTimesheet = payload;
    },
    setContractorPlacmentDetails: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.contractorPlacmentDetails = payload;
    },
    setContractorEffortsDetails: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.contractorEffortsDetails = payload;
    },
    setIsContractorOnboarded: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.isContractorOnboarded = payload;
    },
    setContractorOnboardingDetails: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.contractorOnboardingDetails = payload;
    },

    setInitialContractorDetailsState: () => initialState,
  },
});

export const {
  setSelectedContractor,
  setContractors,
  setIsContractorDetailsDrawerOpen,
  setSelectedContractorDrawerTab,
  setInitialContractorDetailsState,
  setSelectedContractorTimesheet,
  setContractorPlacmentDetails,
  setIsContractorOnboarded,
  setContractorOnboardingDetails,
  setContractorEffortsDetails,
} = contractorSlice.actions;
export const getContractorDetailsState = (state: RootState) => state.contractor;
export default contractorSlice.reducer;
