import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { BG_COLORS } from "../../../../../../../../../../theme";
import OfferDetailsInfo from "./OfferDetailsInfo";
import StatusDetails from "./StatusDetails";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import { isNotNullOrUndefined } from "../../../../../../../../../../utils/stringUtils";
import UnderNegotiation from "./UnderNegotiation";

const OfferDetails = () => {
  const { selectedOffer, getOfferDetails } = useMakeAnOffer();

  useEffect(() => {
    selectedOffer && getOfferDetails();
  }, []);
  return (
    <>
      <Stack direction={"row"} sx={{ width: "100%", minHeight: "440px" }}>
        <Box
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          {/* {isNotNullOrUndefined(selectedOffer.status) &&
          selectedOffer.status === "Under Negotiation" ? (
          ) : (
            <OfferDetailsInfo />
          )} */}
          <UnderNegotiation />
        </Box>
        <Box
          sx={{
            px: "46px",
            py: "36px",
            bgcolor: BG_COLORS.GRAY,
          }}
        >
          <StatusDetails />
        </Box>
      </Stack>
    </>
  );
};

export default OfferDetails;
