import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { UserManagementServices } from "../../../Api/userManagementServices";
import AtsSelect from "../../../components/AtsSelect";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import { OptionProps } from "../../../types/commonType";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getInviteMembersState,
  InviteMemberProps,
  setInviteMember,
} from "../../../redux/slices/inviteMembersSlice";

const InviteAs = () => {
  const dispatch = useAppDispatch();
  const { inviteMember, inviteError } = useAppSelector(getInviteMembersState);
  const [roles, setRoles] = useState<OptionProps[]>([]);

  const handleSelect = (value: string) => {
    const updateInviteMember = {
      ...inviteMember,
      role_id: value,
    } as InviteMemberProps;
    dispatch(setInviteMember(updateInviteMember));
  };

  const getClientRole = async () => {
    try {
      const response = await UserManagementServices.getClientRoles();
      const rolesData = response.results.map(
        (item) => ({ label: item.title, value: item.id } as OptionProps)
      );
      setRoles(rolesData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getClientRole();
  }, []);

  return (
    <Stack gap={"6px"} sx={{ mt: "12px" }}>
      <AtsTitleText
        text={"Invite as"}
        fs={14}
        fw={600}
        textColor={COLORS.BLACK}
      >
        <span
          style={{
            color: "red",
          }}
        >
          *
        </span>
      </AtsTitleText>
      <AtsSelect
        placeholder="Select"
        selected={inviteMember.role_id}
        onSelect={(select: string) => handleSelect(select)}
        options={roles}
      />
      <Stack textAlign={"end"}>
        <AtsTitleText
          text={inviteError["role_id"]}
          fs={12}
          fw={500}
          textColor={COLORS.RED_LIGHT}
        />
      </Stack>
    </Stack>
  );
};

export default InviteAs;
