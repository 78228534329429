import { Button, Collapse, Stack } from "@mui/material";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useState } from "react";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsDateRangePicker from "../../../components/AtsDateRangePicker";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { useProjectFilter } from "../../../providers/ProjectFilterProvider";
import { BORDERS, COLORS } from "../../../theme";
import { ProjectFilterState } from "../../../types/projectTypes";
import { IProjectFilterProps } from "../modules/ProjectModules/ProjectFilterSection";

const dateButtons = [
  {
    title: "Last 7 days",
    dateFrom: 6,
  },
  {
    title: "Last 15 days",
    dateFrom: 14,
  },
];

const FilterDateRange = ({ setPage }: IProjectFilterProps) => {
  const {
    handleEvent,
    handleDayButtonSelect,
    selectedDate,
    selected,
    projectFilter,
    setProjectFilter,
  } = useProjectFilter();
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  const renderButtonComponent = (text: string, dateFrom: number) => {
    return (
      <Button
        variant="outlined"
        size="small"
        sx={{
          textTransform: "initial",
          height: 27,
          borderRadius: "6px",
          bgcolor: selected === text ? COLORS.PRIMARY : "transparent",
          border: selected === text ? BORDERS.PRIMARY : BORDERS.GRAY,
          color: selected === text ? "white" : COLORS.BLACK,
          fontSize: 12,
          fontWeight: 400,
          ":hover": {
            border: selected === text ? BORDERS.PRIMARY : BORDERS.GRAY,
            color: selected === text ? "white" : COLORS.BLACK,
            bgcolor: selected === text ? COLORS.PRIMARY : "transparent",
          },
        }}
        onClick={() => {
          setPage(0);
          handleDayButtonSelect(text, dateFrom);
        }}
      >
        {text}
      </Button>
    );
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <AtsCustomButton px={0} onClick={() => setIsCollapse(!isCollapse)}>
          <AtsIcon
            icon={isCollapse ? "icon-arrow-up-2" : "icon-arrow-down-1"}
            height="16px"
            fw={600}
          />
        </AtsCustomButton>
        <AtsTitleText
          text={"Date range"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Collapse in={isCollapse}>
        <Stack gap={"10px"}>
          <Stack direction={"row"} gap={"10px"}>
            {dateButtons.map((button) =>
              renderButtonComponent(button.title, button.dateFrom)
            )}
          </Stack>
          <Stack>
            <AtsDateRangePicker
              handleEvent={handleEvent}
              handleClear={() => {
                const dateSelection = {
                  ...projectFilter,
                  start_date: "",
                  end_date: "",
                } as ProjectFilterState;
                setProjectFilter(dateSelection);
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "initial",
                  height: 39,
                  borderRadius: "6px",
                  border: BORDERS.GRAY,
                  color: COLORS.BLACK,
                  fontSize: 14,
                  fontWeight: 400,
                  justifyContent: "flex-start",
                  ":hover": {
                    border: BORDERS.GRAY,
                    color: COLORS.BLACK,
                  },
                }}
                startIcon={
                  <AtsIcon
                    icon="icon-calendar-1"
                    color={COLORS.PRIMARY}
                    height="30px"
                  />
                }
              >
                {selectedDate}
              </Button>
            </AtsDateRangePicker>
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default FilterDateRange;
