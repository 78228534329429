import React from "react";
import AtsRoundedButton from "../../../components/AtsRoundedButton";

interface Props {
  onSelect: () => void;
  isSelected?: boolean;
  loading?: boolean;
  text: string;
}

const ButtonSelection = ({ onSelect, isSelected, text, loading }: Props) => {
  return (
    <>
      <AtsRoundedButton
        onClick={onSelect}
        text={text}
        loading={loading}
        buttonVariant={isSelected ? "contained" : "whiteContained"}
        //   endIcon={
        //     isSelected && <Close />
        //   }
      />
    </>
  );
};

export default ButtonSelection;
