import React from "react";
import AtsRow from "../../../../components/AtsRow";
import { BORDERS, COLORS } from "../../../../theme";
import { Stack } from "@mui/material";
import AtsTitleText from "../../../../components/AtsTitleText";

const TopOverviewSection = () => {
  const topData = [
    {
      title: "Total Time",
      value: "40:00:00",
    },
    {
      title: "Total Project",
      value: "Cisco Tech",
    },
    {
      title: "Total Contractor",
      value: "Trevor Cunningham",
    },
  ];
  return (
    <>
      <AtsRow
        sx={{
          borderTop: BORDERS.GRAY,
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>
          {topData.map((data, index) => (
            <AtsRow
              key={index}
              justifyContent={"center"}
              sx={{
                width: "33.33%",
                borderRight: topData.length === index + 1 ? "" : BORDERS.GRAY,
                py: "25px",
              }}
            >
              <>
                <Stack gap={"5px"} textAlign={"center"}>
                  <AtsTitleText text={data.title} fs={12} fw={600} />
                  <AtsTitleText
                    text={data.value}
                    fs={18}
                    fw={500}
                    textColor={COLORS.PRIMARY}
                  />
                </Stack>
              </>
            </AtsRow>
          ))}
        </>
      </AtsRow>
    </>
  );
};

export default TopOverviewSection;
