import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import FontUtil from "../../utils/FontUtil";
import AtsCard from "../AtsCard";
import AtsText from "../AtsText";
import { Grid } from "@mui/material";
import AtsEmptyState from "../AtsEmptyState";
import TopPrioritiesEmptyState from "../../pages/Dashboard/TopPriorities/TopPrioritiesEmptyState";
import AtsRow from "../AtsRow";
import { TopPrioritiesServices } from "../../Api/topPrioritiesServices";
import { useAppSelector } from "../../redux/store";
import { getAuthState } from "../../redux/slices/authSlice";
import { CommonPrioritiesType } from "../../types/topPrioritiesTypes";
import { WarningIcon } from "../../assets/icons/Icons";
import moment from "moment";
import { CalendarServices } from "../../Api/calendarServices";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ATS_ROUTES } from "../../router/routes";
import AtsTitleText from "../AtsTitleText";
import { useNavigate } from "react-router-dom";
import LoadingReminderCard from "./LoadingReminderCard";

interface Priority {
  icon: any;
  label: string;
  text: string;
}

enum PriorityType {
  Submission = "Submission",
  ContractotJoin = "ContractotJoin",
  Invoice = "Invoice",
}

interface IPrioritiesProps {
  prioritiesData: Priority[];
  headerText: string;
}

const TopPrioritiesComponnet = ({
  prioritiesData,
  headerText,
}: IPrioritiesProps) => {
  const navigate = useNavigate();
  const [priorities, setPriorities] = useState<CommonPrioritiesType[]>([]);
  const { userInfo } = useAppSelector(getAuthState);
  const [loading, setLoading] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<number | null>(null);
  const { font11, font14, font16, font17 } = FontUtil();

  const getTopPriorities = async () => {
    setLoading(true);
    try {
      const date = moment(new Date()).toDate();
      const from = moment(date)?.startOf("month");
      const to = moment(date)?.endOf("month");
      const start_date = moment(from.format()).unix();
      const end_date = moment(to.format()).unix();
      const submissionPriorities =
        await TopPrioritiesServices.getSubmissionPriorities();
      const contractorJoiningPriorities =
        await TopPrioritiesServices.getContractorJoiningPriorities();
      const activeContractorPriorities =
        await TopPrioritiesServices.getActiveContractorsPriorities({
          company_id: userInfo?.company?.id,
        });
      if (activeContractorPriorities.length) {
        const emailString = activeContractorPriorities
          .map((value) => value.email)
          .join(", ");
        // const getAssignTask =
        //   await TopPrioritiesServices.getAssignedTasksCountPriorities({
        //     emails: emailString,
        //   });
      }
      const invoiceDueResponse = await CalendarServices.getInvoiceDueEvents({
        start_date,
        end_date,
      });

      const uniqueJobIds = Array.from(
        new Set(submissionPriorities.map((item) => item.job_id))
      );

      const commonArray = [
        ...uniqueJobIds.map((job_id) => {
          const jobLength = submissionPriorities.filter(
            (item) => item.job_id === job_id
          ).length;
          const jobName = submissionPriorities.find(
            (item) => item.job_id === job_id
          );
          return {
            sub_id: job_id,
            title: `You have ${jobLength} new candidate submissions for ${jobName?.job_name} waiting for your review. Timely evaluation can help keep the hiring process on track.`,
            id: job_id,
            priorityType: PriorityType.Submission,
            route: `${ATS_ROUTES.SUBMISSIONS.replace(":nid", job_id)}`,
            ctaText: "Job submission",
          };
        }),
        ...contractorJoiningPriorities.map((contractor) => {
          const text = `${contractor.contractor_fname} ${contractor.contractor_lname} starts today. Make sure they're prepared to hit the ground running`;
          return {
            sub_id: contractor.contractor_id,
            title: text,
            id: contractor.job_id,
            priorityType: PriorityType.ContractotJoin,
            route: `${ATS_ROUTES.SUBMISSIONS.replace(
              ":nid",
              contractor.job_id
            )}`,
            ctaText: "Jobs",
          };
        }),
        invoiceDueResponse.length > 0 && {
          sub_id: "",
          title: `You have ${invoiceDueResponse.length} upcoming invoice – Add your payment information to prevent any overdue billing. `,
          id: "",
          priorityType: PriorityType.Invoice,
          route: `${ATS_ROUTES.BILLING}`,
          ctaText: "Invoices",
        },
      ];
      setPriorities(commonArray);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTopPriorities();
  }, []);

  const NextArrowComponent = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        sx={{
          position: "absolute",
          top: "-30px",
          right: "10px",
          cursor: "pointer",
          border: BORDERS.GRAY,
          bgcolor: "white",
          p: "3px",
          borderRadius: "50%",
          ":hover": {
            bgcolor: "white",
          },
          ":disabled": {
            bgcolor: "white",
          },
        }}
        onClick={onClick}
        disabled={onClick === null}
      >
        <ChevronRight
          sx={{
            fontSize: "1.063rem",
            color: onClick === null ? COLORS.GRAY : COLORS.PRIMARY,
          }}
        />
        {/* {icon} */}
      </IconButton>
    );
  };
  const PrevArrowComponent = (props) => {
    const { onClick, currentSlide } = props;
    return (
      <IconButton
        sx={{
          position: "absolute",
          top: "-30px",
          right: "40px",
          cursor: "pointer",
          border: BORDERS.GRAY,
          bgcolor: "white",
          p: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          ":hover": {
            bgcolor: "white",
          },
          ":disabled": {
            bgcolor: "white",
          },
        }}
        disabled={currentSlide === 0}
        onClick={onClick}
      >
        <ChevronLeft
          sx={{
            fontSize: "1.063rem",
            color: currentSlide === 0 ? COLORS.GRAY : COLORS.PRIMARY,
          }}
        />
        {/* {icon} */}
      </IconButton>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrowComponent />,
    prevArrow: <PrevArrowComponent />,
    responsive: [
      {
        breakpoint: 1024, // Tablet and smaller
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Mobile and smaller
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Stack px={1} direction={"row"} justifyContent={"space-between"}>
        <AtsText
          text={headerText}
          sx={{
            ...font16,
            fontWeight: 600,
            color: "#231f20",
          }}
        />
      </Stack>

      {loading ? (
        <Stack direction={"row"} mt={"10px"} gap={"10px"}>
          {Array.from(new Array(2)).map((_, index) => (
            <LoadingReminderCard key={index} />
          ))}
        </Stack>
      ) : priorities.length ? (
        <div
          style={{
            minHeight: "130px",
            paddingTop: "10px",
          }}
        >
          <Slider
            {...settings}
            style={{
              height: 130,
            }}
          >
            {priorities.map((priority, index) => {
              return (
                <motion.div
                  whileHover={{ scale: 1.02, translateY: -5 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "10px",
                    cursor: "pointer",
                    height: "100%",
                  }}
                  onHoverStart={() => setIsHovered(index)}
                  onHoverEnd={() => setIsHovered(null)}
                >
                  <Box
                    px={1}
                    sx={{
                      // maxWidth: isLargeXl ? "500px" : "300px",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <AtsCard
                      sx={{
                        height: "86px",
                      }}
                    >
                      <Stack direction={"row"} gap={1}>
                        <Box
                          sx={{
                            mt: 0.3,
                          }}
                        >
                          <WarningIcon />
                        </Box>
                        <Stack gap={1}>
                          <Tooltip title={priority.title} placement="top-start">
                            <Stack>
                              <AtsText
                                className="truncate_two_line"
                                text={priority.title}
                                sx={{
                                  ...font14,
                                  fontWeight: 600,
                                  color: "#4f4f4f",
                                }}
                              />
                            </Stack>
                          </Tooltip>
                          <Box
                            sx={{
                              px: "10px",
                              py: "3px",
                              bgcolor: BG_COLORS.PRIMARY,
                              borderRadius: "4px",
                              width: "fit-content",
                            }}
                          >
                            <AtsTitleText
                              text={priority.ctaText}
                              fs={10}
                              fw={500}
                              textColor={COLORS.PRIMARY}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    </AtsCard>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 9,
                        width: "24px",
                        height: "20px",
                        textAlign: "center",
                        borderBottomRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                        cursor: "pointer",
                        bgcolor:
                          isHovered === index
                            ? COLORS.PRIMARY
                            : BG_COLORS.PRIMARY,
                      }}
                      onClick={() => navigate(priority.route)}
                    >
                      <IoIosArrowForward
                        style={{
                          fontSize: 12,
                          color:
                            isHovered !== index
                              ? COLORS.PRIMARY
                              : BG_COLORS.PRIMARY,
                        }}
                      />
                    </Box>
                  </Box>
                </motion.div>
              );
            })}
          </Slider>
        </div>
      ) : (
        <>
          <AtsRow>
            <>
              <AtsEmptyState
                textFs={14}
                isStartAlign
                imgWidth={25}
                imgHeight={32}
                image={require("../../assets/emptyStateImages/emptyList.png")}
                text="No priorities assigned yet."
                subText="Stay tuned for upcoming tasks and updates."
              />
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <TopPrioritiesEmptyState />
              </Box>
            </>
          </AtsRow>
        </>
      )}
    </>
  );
};

export default TopPrioritiesComponnet;
