import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Skeleton,
  Stack,
} from "@mui/material";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useCreateAccount } from "../../../../providers/CreateAccountProvider";
import { BORDERS, COLORS } from "../../../../theme";

const AboutCompany = () => {
  const { companyInfoState, companyInfoLoading, companyLogo, companySizeText } =
    useCreateAccount();

  return (
    <>
      {Object.keys(companyInfoState).length > 0 && (
        <Box component={"div"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pt={4}
            sx={{ borderTop: BORDERS.GRAY }}
          >
            <AtsTitleText fs={18} fw={600} text="About Company" />
          </Stack>
          <List>
            <ListItem alignItems="center" sx={{ pb: 0, pt: 0, pl: 0, pr: 0 }}>
              {companyLogo.image && (
                <ListItemIcon sx={{ minWidth: "20px", mt: 0 }}>
                  {companyInfoLoading ? (
                    <Skeleton width={72} height={50} />
                  ) : (
                    <img
                      src={companyLogo.image}
                      alt="Nvidia Logo"
                      style={{
                        width: 72,
                        height: 50,
                        marginRight: 10,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                </ListItemIcon>
              )}
              <Stack className="innerHTML">
                {renderLoadingComponent(
                  companyInfoLoading,
                  <AtsTitleText
                    text={companyInfoState.title}
                    fs={12}
                    fw={400}
                    textColor={COLORS.BLACK}
                  />
                )}
              </Stack>
            </ListItem>
          </List>
          <Stack gap={1} sx={{ pb: 0, pt: 2 }}>
            <AtsTitleText
              text="Company Overview"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              companyInfoLoading,
              <AtsTitleText
                isCapitalize
                text={companyInfoState?.description}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
          <Stack gap={1} sx={{ pb: 0, pt: 2 }}>
            <AtsTitleText
              text="Website"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              companyInfoLoading,
              <AtsTitleText
                text={companyInfoState.website}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
          <Stack gap={1} sx={{ pb: 0, pt: 2 }}>
            <AtsTitleText
              text="Company Size"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              companyInfoLoading,
              <AtsTitleText
                text={companySizeText}
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#231f20",
                }}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
          <Stack gap={1} sx={{ pb: 4, pt: 2 }}>
            <AtsTitleText
              text="Founded In"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              companyInfoLoading,
              <AtsTitleText
                text={companyInfoState.founded}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default AboutCompany;

const renderLoadingComponent = (
  companyInfoLoading: boolean,
  component: JSX.Element
) => {
  if (companyInfoLoading) {
    return <Skeleton height={80} sx={{ fontSize: "1rem" }} />;
  } else {
    return component;
  }
};
