import { Search } from "@mui/icons-material";
import { InputAdornment, Popover, Stack, TextField } from "@mui/material";
import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import "../../../../../../../../../assets/styles/calender.css";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BORDERS } from "../../../../../../../../../theme";
import "../../../../../../../assets/styles/dateRange.css";

interface CustomHeaderProps {
  headerText: string;
}

export const InterviewListHeader = ({ headerText }: CustomHeaderProps) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [dates, setDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleApply = () => {
    if (startDate && endDate) {
      const newDateRange = {
        key: dates.length + 1,
        start: startDate.format("MM/DD/YYYY"),
        end: endDate.format("MM/DD/YYYY"),
      };
      setDates([...dates, newDateRange]);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const removeDate = (key) => {
    setDates(dates.filter((date) => date.key !== key));
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="custom-header"
    >
      <Stack direction={"row"} gap={1}>
        <div
          style={{
            position: "relative",
          }}
        >
          <Stack direction={"row"} gap={1}>
            <AtsTitleText
              text={headerText}
              fs={12}
              fw={600}
              textColor="#4f4f4f"
              isCursor
              onClick={handleClick}
            />
          </Stack>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack
              sx={{
                p: 1,
                border: BORDERS.GRAY,
                borderRadius: 1,
              }}
            >
              {headerText === "Interview Id" ? (
                <TextField
                  placeholder="Search...."
                  fullWidth
                  size={"small"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : headerText === "Scheduled On" ? (
                <DateRangePicker
                  onChange={(item) => setState([item.selection])}
                  ranges={state}
                />
              ) : headerText === "Duration" ? (
                <Stack sx={{ px: 2 }} gap={1}>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Over Budget" fs={10} fw={600} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Under Budget" fs={10} fw={600} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Matches Budget" fs={10} fw={600} />
                  </Stack>
                </Stack>
              ) : (
                headerText === "Status" && (
                  <Stack sx={{ px: 2 }} gap={1}>
                    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                      <AtsTitleText text="Good Fit" fs={10} fw={600} />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <AtsTitleText text="Not a Fit" fs={10} fw={600} />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                      <AtsTitleText text="Maybe" fs={10} fw={600} />
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>
          </Popover>
        </div>
      </Stack>
    </Stack>
  );
};
