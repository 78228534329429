import { Box, Skeleton, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useInvoices } from "../../../../../../../providers/InvoicesProvider";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { InvoiceInfoResponse } from "../../../../../../../types/invoiceTypes";

const InvoiceInfo = () => {
  const {
    detailsLoading,
    invoiceFrom,
    invoiceTo,
    invoiceDate,
    selectedInvoice,
  } = useInvoices();

  const renderInvoiceComponent = (key: string, value: string) => {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <AtsTitleText
            text={key}
            fs={16}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Box>
        <Box>
          <AtsTitleText
            text={value}
            fs={16}
            fw={400}
            loading={detailsLoading}
          />
        </Box>
      </Stack>
    );
  };

  const renderText = (
    line1: string,
    line2: string,
    line3: string,
    isLink?: boolean
  ) => {
    return (
      <Stack gap={1} sx={{ minWidth: { xs: "100%", sm: 150, md: 200 } }}>
        {line1.length > 0 && (
          <AtsTitleText
            text={line1}
            fs={14}
            fw={500}
            textColor="#5d5d5d"
            loading={detailsLoading}
          />
        )}
        {line2.length > 0 && (
          <AtsTitleText
            text={line2}
            fs={14}
            fw={500}
            textColor="#5d5d5d"
            loading={detailsLoading}
          />
        )}
        {detailsLoading ? (
          <Skeleton variant="rounded" width={100} />
        ) : isLink ? (
          <a
            target="_blank"
            href={`${line3}`}
            style={{
              color: COLORS.PRIMARY,
              fontSize: 14,
              textDecoration: "none",
            }}
            rel="noreferrer"
          >
            {line3}
          </a>
        ) : (
          <AtsTitleText
            text={line3}
            fs={14}
            fw={500}
            textColor="#5d5d5d"
            loading={detailsLoading}
          />
        )}
      </Stack>
    );
  };

  const renderCompanyInfo = (info: InvoiceInfoResponse, title: string) => {
    console.log("info", info);
    return (
      <>
        <AtsTitleText text={title} fs={16} fw={600} />
        <Stack direction={"row"} alignItems={"center"} gap={"60px"}>
          {renderText(info.companyName, info.addressLine, info.cityStateZip)}
          {renderText(info.phone, info.email, info.website, true)}
        </Stack>
      </>
    );
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        flexWrap={"wrap"}
      >
        <Stack>
          <Stack
            mt={{ xs: "10px", md: "24px" }}
            direction={"row"}
            alignItems={"center"}
            gap={"20px"}
          >
            <Stack gap={"12px"} pb={"28px"}>
              {renderCompanyInfo(invoiceFrom, "Bill from")}
            </Stack>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
            <Stack gap={"12px"}>
              {renderCompanyInfo(invoiceTo, "Billed to")}
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            py: { xs: 2, md: "28px" },
            px: { xs: 2, md: "24px" },
            border: BORDERS.GRAY,
            bgcolor: "#f9f9f9",
            borderRadius: "6px",
            minWidth: 285,
          }}
        >
          <Stack gap={"25px"}>
            <AtsTitleText
              text={`Invoice #${selectedInvoice.invoice_no}`}
              fs={18}
              fw={600}
            />
            <Stack gap={"10px"}>
              {renderInvoiceComponent("Invoice Date", invoiceDate.invoiceDate)}
              {renderInvoiceComponent("Due Date", invoiceDate.dueDate)}
              {renderInvoiceComponent("Terms", "Net 15")}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default InvoiceInfo;
