import { Box, Stack } from "@mui/material";
import AtsTabs from "../../../../../../../../components/AtsTabs";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  OfferTypeEnum,
  useMakeAnOffer,
} from "../../../../../../../../providers/MakeAnOfferProvider";
import Offers from "./module/Offers";

const MakeAnOfferTab = () => {
  const { offerType, setOfferType } = useMakeAnOffer();
  const enumValues = Object.values(OfferTypeEnum);
  return (
    <>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <AtsTitleText text="Offers" fs={20} fw={600} />
          </Stack>
        </Stack>
        <AtsTabs
          value={enumValues.indexOf(offerType)}
          onChange={(event, newValue) => {
            const findeValue = enumValues.find(
              (_, index) => index === newValue
            );
            setOfferType(findeValue);
          }}
          tabData={enumValues.map((offer) => offer)}
        />
        <Box
          sx={{
            height: "calc(100% - 80px)",
            overflow: "scroll",
          }}
        >
          <Offers />
        </Box>
      </Stack>
    </>
  );
};

export default MakeAnOfferTab;
