import { Box, Stack, SxProps } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { COLORS } from "../theme";
import AtsTitleText from "./AtsTitleText";

export interface ISwitch {
  title: string;
  type: string;
}
interface SwitchProps {
  switchData: ISwitch[];
  active: string;
  switchWidth?: number;
  onChange: (valueObj: ISwitch) => void;
  sx?: SxProps;
}

const AtsSwitch = ({
  switchData,
  switchWidth,
  active,
  onChange,
  sx,
}: SwitchProps) => {
  return (
    <>
      <Box
        sx={{
          border: "2px solid #dcdfe7",
          borderRadius: "40px",
          width: switchWidth ? switchWidth * switchData.length : "auto",
          minHeight: 44,
        }}
      >
        <AnimatePresence>
          <Stack
            sx={{
              bgcolor: "#dcdfe7",
              borderRadius: "40px",
              height: "100%",
            }}
            direction={"row"}
          >
            {switchData.map((item, index) => (
              <Stack
                key={index}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  bgcolor: active === item.title ? "white" : "#dcdfe7",
                  borderRadius: "40px",
                  width: switchWidth ? switchWidth : "auto",
                  height: "100%",
                  cursor: "pointer",
                  ...sx,
                }}
                onClick={() => onChange(item)}
              >
                <AtsTitleText
                  text={item.title}
                  fs={16}
                  fw={600}
                  textColor={
                    active === item.title ? COLORS.PRIMARY : COLORS.DARK_GRAY
                  }
                />
              </Stack>
            ))}
          </Stack>
        </AnimatePresence>
      </Box>
    </>
  );
};

export default AtsSwitch;
