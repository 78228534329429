import { createContext, useContext, useEffect, useState } from "react";
import {
  CreateUpdateOnboardingRequest,
  EditStateProps,
  ResponsibilitiesResponse,
} from "../types/onboardingTypes";
import { FileFidUrl, ValidationResult } from "../types/commonType";
import dayjs, { Dayjs } from "dayjs";
import { ProjectService } from "../Api/projectServices";
import { useParams } from "react-router-dom";
import { ProjectDocsListResponse } from "../types/projectTypes";
import { OnboardingServices } from "../Api/onboardingServices";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { getSubmissionState } from "../redux/slices/submissionSlice";
import {
  getContractorDetailsState,
  setContractorOnboardingDetails,
  setIsContractorOnboarded,
} from "../redux/slices/contractorSlice";
import moment from "moment";
import toast from "react-hot-toast";

export enum OnboardingStatusEnum {
  ConfirmPositionDetails = "Confirm Position Details",
  SetupITEquipment = "Set up IT & Equipment",
  SelectPolicyDocuments = "Select Policy Documents",
  Responsibilites = "Responsibilites",
  OnboardingSchedule = "Onboarding Schedule",
  OnboardingSuccess = "Onboarding Success",
}
export enum OnboardingStepEnum {
  SetupITEquipment = "Set up IT & Equipment",
  SelectPolicyDocuments = "Select Policy Documents",
  Responsibilites = "Responsibilites",
  OnboardingSchedule = "Onboarding Schedule",
}

export const initialCreateOnboardingState = {
  title: "",
  project_id: "",
  candidate: "",
  equipment_needed: "",
  device_setup_instruction: "",
  device_setup_instruction_files: [],
  it_support_contact_name: "",
  it_support_contact_email: "",
  it_support_contact_phone: "",
  // meeting_name: "kickoff";
  // key_team_members: ["rudra@gmail.com", "sandy@gmail.com"];
  policy_documents: [],
  meeting_preparation_notes: "",
  orientation_session_date: "",
  company_culture_values_description: "",
  company_culture_values_files: [],
  office_tours_activities_description: "",
  office_tours_activities_files: [],
  job_responsibilities: [],
} as CreateUpdateOnboardingRequest;

export const initailIsEditState = {
  isEditEquipment: false,
  isEditItSupport: false,
  isEditPolicyDocuments: false,
  isEditOrientationSession: false,
  isEditCompanyCulture: false,
  isEditOfficceTours: false,
  isEditResponsibilities: false,
};

type OnboardingModuleContextType = {
  selectedOnboardingStep: OnboardingStatusEnum;
  policyLoading: boolean;
  createOnboardingLoading: boolean;
  responsibilitiesLoading: boolean;
  selectedMembers: string[];
  selectedPolicyDocs: string[];
  policyDocuments: ProjectDocsListResponse[];
  deviceSetupFiles: FileFidUrl[];
  officeToursFiles: FileFidUrl[];
  companyCultureFiles: FileFidUrl[];
  selectedDate: Dayjs;
  selectedTime: Dayjs;
  error: Partial<ValidationResult>;
  createOnboardingState: CreateUpdateOnboardingRequest;
  editState: EditStateProps;

  onNext: (step: OnboardingStatusEnum) => void;
  onBack: (step: OnboardingStatusEnum) => void;
  setSelectedOnboardingStep: React.Dispatch<
    React.SetStateAction<OnboardingStatusEnum>
  >;
  setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPolicyDocs: React.Dispatch<React.SetStateAction<string[]>>;
  setDeviceSetupFiles: React.Dispatch<React.SetStateAction<FileFidUrl[]>>;
  setOfficeToursFiles: React.Dispatch<React.SetStateAction<FileFidUrl[]>>;
  setCompanyCultureFiles: React.Dispatch<React.SetStateAction<FileFidUrl[]>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setSelectedTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setResponsibilitiesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<Partial<ValidationResult>>;
  setCreateOnboardingState: React.Dispatch<
    React.SetStateAction<CreateUpdateOnboardingRequest>
  >;

  handleCreateOnboardingState: (
    key: keyof CreateUpdateOnboardingRequest,
    value: string | ResponsibilitiesResponse[]
  ) => void;
  handleEditState: (key: keyof EditStateProps, value: boolean) => void;
  getDocumentList: () => void;
  onCreateOnboarding: (value: string) => void;
  getResponsibilities: () => void;
};

const OnboardingModule = createContext<OnboardingModuleContextType | null>(
  null
);

export const useOnboarding = () =>
  useContext(OnboardingModule) as OnboardingModuleContextType;

export const OnboardingProvider = ({ children }: any) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { candidateDetails } = useAppSelector(getSubmissionState);
  const { contractorOnboardingDetails } = useAppSelector(
    getContractorDetailsState
  );
  const [selectedOnboardingStep, setSelectedOnboardingStep] =
    useState<OnboardingStatusEnum>(OnboardingStatusEnum.ConfirmPositionDetails);
  const [policyLoading, setPolicyLoading] = useState<boolean>(false);
  const [responsibilitiesLoading, setResponsibilitiesLoading] =
    useState<boolean>(false);
  const [createOnboardingLoading, setCreateOnboardingLoading] =
    useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [selectedPolicyDocs, setSelectedPolicyDocs] = useState<string[]>([]);
  const [deviceSetupFiles, setDeviceSetupFiles] = useState<FileFidUrl[]>([]);
  const [companyCultureFiles, setCompanyCultureFiles] = useState<FileFidUrl[]>(
    []
  );
  const [officeToursFiles, setOfficeToursFiles] = useState<FileFidUrl[]>([]);
  const [policyDocuments, setPolicyDocuments] = useState<
    ProjectDocsListResponse[]
  >([]);

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(dayjs());
  const [error, setError] = useState<Partial<ValidationResult>>({});
  const [createOnboardingState, setCreateOnboardingState] =
    useState<CreateUpdateOnboardingRequest>(initialCreateOnboardingState);

  const [editState, setEditState] =
    useState<EditStateProps>(initailIsEditState);

  const onNext = (step: OnboardingStatusEnum) => {
    setSelectedOnboardingStep(step);
  };
  const onBack = (step: OnboardingStatusEnum) => {
    setSelectedOnboardingStep(step);
  };

  const handleCreateOnboardingState = (
    key: keyof CreateUpdateOnboardingRequest,
    value: string | ResponsibilitiesResponse[]
  ) => {
    setCreateOnboardingState({ ...createOnboardingState, [key]: value });
  };
  const handleEditState = (key: keyof EditStateProps, value: boolean) => {
    setEditState({ ...editState, [key]: value });
  };

  const getDocumentList = async () => {
    setPolicyLoading(true);
    try {
      const response = await ProjectService.getDocumentByProjectId({
        project_id: id,
      });
      const documents = response.results;

      const filterPolicyDocs = documents
        .filter((docs) => docs.document_type_parent_id === "2")
        .filter((filerDocs) => filerDocs.attachments.length);
      setPolicyDocuments(filterPolicyDocs);
    } catch (error) {
      console.error(error);
    } finally {
      setPolicyLoading(false);
    }
  };

  const getResponsibilities = async () => {
    if (!candidateDetails && !candidateDetails.job_title.length) return;
    if (!createOnboardingState.job_responsibilities.length) {
      setResponsibilitiesLoading(true);

      try {
        const response = await OnboardingServices.getResponsibilitiesList({
          job_title: candidateDetails.job_title.replace(" ", "_"),
        });
        if (response.length) {
          setCreateOnboardingState({
            ...createOnboardingState,
            job_responsibilities: response,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setResponsibilitiesLoading(false);
      }
    }
  };
  const onCreateOnboarding = async (value: string) => {
    const candidateName = candidateDetails
      ? candidateDetails.first_name ??
        "" + " " + candidateDetails.last_name ??
        ""
      : "";

    setCreateOnboardingLoading(true);
    try {
      const combinedTimestamp =
        selectedDate && selectedTime
          ? selectedDate
              .hour(selectedTime.hour())
              .minute(selectedTime.minute())
              .unix()
          : null;
      const body = {
        ...createOnboardingState,
        title: `${candidateName}-${id}-Onboarding`,
        ...(value === "Update" && { nid: createOnboardingState.nid }),
        ...(candidateDetails &&
          candidateDetails.email_address && {
            candidate: candidateDetails.email_address,
          }),
        project_id: id,
        device_setup_instruction_files: deviceSetupFiles.map((file) =>
          file.fid.toString()
        ),
        company_culture_values_files: companyCultureFiles.map((file) =>
          file.fid.toString()
        ),
        office_tours_activities_files: officeToursFiles.map((file) =>
          file.fid.toString()
        ),
        orientation_session_date: combinedTimestamp,
        policy_documents: selectedPolicyDocs,
      } as CreateUpdateOnboardingRequest;
      if (value === "Update") {
        const response = await OnboardingServices.updateOnboarding(body);
        dispatch(setContractorOnboardingDetails(response.data));
        dispatch(setIsContractorOnboarded(true));
        console.log("response", response);
        toast.success(response.messages);
      } else {
        const response = await OnboardingServices.createOnboarding(body);
        console.log("response", response);
        dispatch(setContractorOnboardingDetails(response.data));
        dispatch(setIsContractorOnboarded(true));
        toast.success(response.messages);
      }
      await OnboardingServices.confirmOnboarding({
        email: candidateDetails?.email_address,
        project_id: Number(id),
      });
      onNext(OnboardingStatusEnum.OnboardingSuccess);
    } catch (error) {
      console.error(error);
    } finally {
      setCreateOnboardingLoading(false);
    }
  };

  useEffect(() => {
    if (contractorOnboardingDetails) {
      const updateOnboardingState = {
        ...contractorOnboardingDetails,
        device_setup_instruction_files:
          contractorOnboardingDetails.device_setup_instruction_files.map(
            (file) => file.fid
          ),
        company_culture_values_files:
          contractorOnboardingDetails.company_culture_values_files.map(
            (file) => file.fid
          ),
        office_tours_activities_files:
          contractorOnboardingDetails.office_tours_activities_files.map(
            (file) => file.fid
          ),
      } as CreateUpdateOnboardingRequest;
      setCreateOnboardingState(updateOnboardingState);
      const deviceFiles =
        contractorOnboardingDetails.device_setup_instruction_files
          .map((file) => {
            const fileName =
              file.path.length > 10
                ? `${file.path.substring(0, 10)}...pdf`
                : file.path;
            return { fid: Number(file.fid), fileName, url: file.path };
          })
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.fid === item.fid)
          );
      const companyFiles =
        contractorOnboardingDetails.company_culture_values_files
          .map((file) => {
            const fileName =
              file.path.length > 10
                ? `${file.path.substring(0, 10)}...pdf`
                : file.path;
            return { fid: Number(file.fid), fileName, url: file.path };
          })
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.fid === item.fid)
          );
      const officeFiles =
        contractorOnboardingDetails.office_tours_activities_files
          .map((file) => {
            const fileName =
              file.path.length > 10
                ? `${file.path.substring(0, 10)}...pdf`
                : file.path;
            return { fid: Number(file.fid), fileName, url: file.path };
          })
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.fid === item.fid)
          );
      setDeviceSetupFiles(deviceFiles);
      setCompanyCultureFiles(companyFiles);
      setOfficeToursFiles(officeFiles);
    }
  }, [contractorOnboardingDetails]);

  return (
    <OnboardingModule.Provider
      value={{
        error,
        editState,
        policyLoading,
        responsibilitiesLoading,
        createOnboardingLoading,
        selectedOnboardingStep,
        selectedMembers,
        createOnboardingState,
        deviceSetupFiles,
        companyCultureFiles,
        officeToursFiles,
        selectedDate,
        selectedTime,
        policyDocuments,
        selectedPolicyDocs,
        onBack,
        onNext,
        setSelectedOnboardingStep,
        setSelectedDate,
        setSelectedTime,
        setSelectedMembers,
        setCreateOnboardingState,
        setDeviceSetupFiles,
        setCompanyCultureFiles,
        setOfficeToursFiles,
        setError,
        setSelectedPolicyDocs,
        setResponsibilitiesLoading,
        handleCreateOnboardingState,
        handleEditState,
        getDocumentList,
        onCreateOnboarding,
        getResponsibilities,
      }}
    >
      {children}
    </OnboardingModule.Provider>
  );
};
