import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ResponsibilitiesResponse } from "../types/onboardingTypes";
import { API_GATEWAY } from "../utils/url";

const Endpoint = {
  BASE: "ats/api/v1/",
  RESPONSIBILITIES_LIST: "job/responsibilities/get",
};

const RESPONSIBILITIES_LIST_URL =
  Endpoint.BASE + Endpoint.RESPONSIBILITIES_LIST;

export const responsibilitiesServices = createApi({
  reducerPath: "responsibilitiesServices",
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getResponsibilities: builder.query<
      ResponsibilitiesResponse[],
      { job_title: string }
    >({
      query: (params) => {
        return {
          url: RESPONSIBILITIES_LIST_URL,
          params,
        };
      },
    }),
  }),
});

export const { useGetResponsibilitiesQuery } = responsibilitiesServices;
