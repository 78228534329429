import {
  Collapse,
  Slider,
  SliderThumb,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsIcon from "../../../components/AtsIcon";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useProjectFilter } from "../../../providers/ProjectFilterProvider";
import { getProjectsState } from "../../../redux/slices/projectsSlice";
import { useAppSelector } from "../../../redux/store";
import { COLORS } from "../../../theme";
import { formattedAmount } from "../../../utils/common";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: COLORS.PRIMARY,
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 1,
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "var(--primary)",
  },
  "& .MuiSlider-rail": {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
    ...theme.applyStyles("dark", {
      color: COLORS.PRIMARY,
      opacity: undefined,
    }),
  },
}));
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return <SliderThumb {...other}>{children}</SliderThumb>;
}

const FilterBudgetRange = () => {
  const { range, handlePriceChange, projectFilter } = useProjectFilter();
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <AtsCustomButton px={0} onClick={() => setIsCollapse(!isCollapse)}>
          <AtsIcon icon="icon-arrow-down-1" height="16px" fw={600} />
        </AtsCustomButton>
        <AtsTitleText
          text={"Budget Range"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Collapse in={isCollapse}>
        <Stack mt={"20px"} gap={"10px"}>
          <AirbnbSlider
            slots={{ thumb: AirbnbThumbComponent }}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => {
              return `$${formattedAmount(value)}`;
            }}
            getAriaLabel={(index) =>
              index === 0 ? "Minimum price" : "Maximum price"
            }
            onChange={handlePriceChange}
            value={range}
          />

          <AtsRow>
            <>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                <span
                  style={{
                    marginRight: "8px",
                  }}
                >
                  {`$${formattedAmount(projectFilter.budget_low)}`}
                </span>
                to
                <span
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  {`$${formattedAmount(projectFilter.budget_high)}`}
                </span>
              </Typography>
            </>
          </AtsRow>
        </Stack>
      </Collapse>
    </>
  );
};

export default FilterBudgetRange;
