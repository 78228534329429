import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import linkdinImg from "../../../assets/images/linkdin.jpg";
import AtsRow from "../../../components/AtsRow";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import JobTitleSelector from "../../../pages/PostOpportunity/components/JobTitleSelector";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { COLORS } from "../../../theme";
import { UserUpdateRequest } from "../../../types/userManagementTypes";

const AccountDetails = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {
    accountDetailsState,
    handleAccountDetailsChange,
    updateUserLoading,
    accountDetailsError,
  } = useCreateAccount();

  const renderTextInputComponent = (
    isEdit: boolean,
    title: string,
    value: string | number,
    key: keyof UserUpdateRequest,
    handleChange: (key: string, value: string) => void,
    updateUserLoading?: boolean
  ) => {
    return (
      <Stack gap={"5px"}>
        <AtsRow gap={"5px"}>
          <>
            {key === "linkedin_profile_url" && (
              <img width={16} height={16} src={linkdinImg} alt="images" />
            )}
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
            >
              {title}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>
          </>
        </AtsRow>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {key === "job_title" ? (
            <JobTitleSelector
              height="41px"
              isHideSearchIcon
              isShowDropdownIcon
              selected={value as string}
              onSelected={(select) => handleChange(key, select)}
            />
          ) : (
            <AtsTextInput
              placeholder={`Enter ${title}`}
              height="41px"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          )}
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={accountDetailsError[key]}
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Stack gap={"20px"}>
        <AtsRow gap={"10px"}>
          <>
            <AtsTitleText
              text={"Account details"}
              textColor={"#1c1c1c"}
              fs={24}
              fw={600}
            />
            {/* {!isEdit && (
              <AtsRoundedButton
                text="Edit"
                startIcon={<EditIcon />}
                buttonVariant="whiteContained"
                buttonHeight={29}
                buttonWidth={65}
                onClick={() => setIsEdit(!isEdit)}
              />
            )} */}
          </>
        </AtsRow>
        <AtsTitleText
          text={
            "Please review and confirm that the account details entered below are correct before proceeding."
          }
          textColor={"#000"}
          fs={12}
          fw={400}
        />
      </Stack>
      <Box mt={"28px"}>
        <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "First name",
              accountDetailsState?.first_name ?? "",
              "first_name",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Last name",
              accountDetailsState?.last_name ?? "",
              "last_name",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Title",
              accountDetailsState?.job_title ?? "",
              "job_title",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "LinkedIn profile",
              accountDetailsState?.linkedin_profile_url ?? "",
              "linkedin_profile_url",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              isEdit,
              "Phone no.",
              accountDetailsState?.phone_number ?? "",
              "phone_number",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountDetails;
