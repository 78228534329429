import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BG_COLORS, COLORS } from "../../../../../../../theme";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { ProjectService } from "../../../../../../../Api/projectServices";

interface IProps {
  projectId: string;
}

const ProjectNameForContract = ({ projectId }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const getProjectData = async (nid: string) => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({ nid });
      if (response) {
        setProjectName(response.title);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    projectId && getProjectData(projectId);
  }, []);
  return (
    <Box
      sx={{
        bgcolor: BG_COLORS.PRIMARY,
        borderRadius: "40px",
        py: "4px",
        px: "10px",
        width: "fit-content",
      }}
    >
      {loading ? (
        <Skeleton variant="rounded" height={22} width={120} />
      ) : (
        <AtsTitleText
          text={projectName}
          textColor={COLORS.PRIMARY}
          fs={12}
          fw={500}
        />
      )}
    </Box>
  );
};

export default ProjectNameForContract;
