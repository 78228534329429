import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BG_COLORS, COLORS } from "../../../../../../../../../theme";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import {
  OnboardingStatusEnum,
  useOnboarding,
} from "../../../../../../../../../providers/OnboardingProvider";

const OnboardingSuccess = () => {
  const { candidateDetails } = useAppSelector(getSubmissionState);
  const { setSelectedOnboardingStep } = useOnboarding();
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Stack pb={"16px"}>
        <AtsTitleText
          text={"You have started the onboarding process."}
          fs={16}
          fw={500}
          textColor="#000"
        />
      </Stack>
      <Stack
        sx={{
          borderRadius: 2,
          bgcolor: BG_COLORS.GREEN,
          px: 3,
          py: 1,
          mb: "24px",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            color: COLORS.GREEN,
          }}
        >
          Onboarding details are sent to{" "}
          <span
            style={{
              fontWeight: 700,
            }}
          >
            {`${candidateDetails?.first_name ?? ""} ${
              candidateDetails?.last_name ?? ""
            }`}
          </span>
        </Typography>
      </Stack>
      <Stack pb={"30px"}>
        <AtsTitleText
          text={"You can track the onboarding status."}
          fs={14}
          fw={500}
          textColor="#000"
        />
      </Stack>
      <AtsRow justifyContent={"center"}>
        <AtsRoundedButton
          text="View Onboarding Details"
          buttonWidth={220}
          buttonHeight={38}
          fs={14}
          fw={500}
          endIcon={<KeyboardArrowRight />}
          onClick={() =>
            setSelectedOnboardingStep(
              OnboardingStatusEnum.ConfirmPositionDetails
            )
          }
        />
      </AtsRow>
    </Box>
  );
};

export default OnboardingSuccess;
