import { Stack } from "@mui/material";
import React from "react";
import AtsEmptyState from "../../../../components/AtsEmptyState";

const EmptyTaskState = () => {
  return (
    <>
      <Stack gap={"40px"}>
        <AtsEmptyState
          imgWidth={26}
          imgHeight={26}
          image={require("../../../../assets/images/clock.png")}
          text="No reminders."
          subText={`You're all caught up!`}
        />
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            width={220}
            src={require("../../../../assets/emptyStateImages/emptyReminderState.png")}
            alt=""
          />
        </Stack>
      </Stack>
    </>
  );
};

export default EmptyTaskState;
