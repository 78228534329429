import { Box, Stack } from "@mui/material";
import { MultiUserIcon, UsersIcon } from "../../../../../assets/icons/Icons";
import AtsIcon from "../../../../../components/AtsIcon";
import AtsRow from "../../../../../components/AtsRow";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { useMessages } from "../../../../../providers/MessagesProvider";
import { BORDERS, COLORS } from "../../../../../theme";
import AddMembersModal from "../../AddMembers/AddMembersModal";
import AtsRoundedButton from "../../../../../components/AtsRoundedButton";

const AddMembersInGroup = () => {
  const { messenger, handleOpenAddMembersModal } = useMessages();
  return (
    <>
      <AtsRow gap={"18px"}>
        <>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              bgcolor: "#d9d9d9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MultiUserIcon />
          </Box>

          <AtsTitleText text={messenger?.chatName} fs={18} fw={600} />
        </>
      </AtsRow>
      <Stack pt={"10px"} pb={"15px"}>
        <AtsTitleText
          text={`You created this group today, this is the very beginning of ${messenger?.chatName} group`}
          fs={12}
          fw={400}
          textColor="#000"
        />
      </Stack>
      <AtsRow gap={"14px"}>
        <>
          <AtsRoundedButton
            fs={12}
            fw={600}
            buttonWidth={147}
            buttonHeight={32}
            startIcon={<UsersIcon />}
            text="Add members"
            isInitial
            buttonVariant="outline"
            onClick={() => handleOpenAddMembersModal(true)}
          />

          <RenderButton
            text="Add description"
            icon="document"
            onClick={() => {}}
          />
        </>
      </AtsRow>

      <AddMembersModal />
    </>
  );
};

export default AddMembersInGroup;

const RenderButton = ({
  text,
  icon,
  onClick,
}: {
  text: string;
  icon: string;
  onClick: () => void;
}) => {
  return (
    <AtsRow
      gap={"6px"}
      sx={{
        justifyContent: "center",
        height: 30,
        width: 147,
        border: BORDERS.PRIMARY,
        borderRadius: "20px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <>
        <AtsIcon
          icon={`icon-${icon}`}
          height="16px"
          fs={16}
          color={COLORS.PRIMARY}
        />
        <AtsTitleText
          text={text}
          fs={12}
          fw={600}
          textColor={COLORS.PRIMARY}
          noTextWrap
        />
      </>
    </AtsRow>
  );
};
