import axios from "axios";
import {
  ContractorJoiningResponse,
  SubmissionPrioritiesResponse,
} from "../types/topPrioritiesTypes";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  SUBMISSION_PRIORITIES: "priorities/submissions",
  CONTRACTORS_JOINING_PRIORITIES: "priorities/contractors-joining",
  ACTIVE_CONTRACTORS_PRIORITIES: "get-company-contractors",
  ASSIGNED_TASKS_COUNT_PRIORITIES: "get-assigned-tasks-count",
};

const SUBMISSION_PRIORITIES_URL =
  Endpoint.BASE + Endpoint.SUBMISSION_PRIORITIES;
const CONTRACTORS_JOINING_PRIORITIES_URL =
  Endpoint.BASE + Endpoint.CONTRACTORS_JOINING_PRIORITIES;
const ACTIVE_CONTRACTORS_PRIORITIES_URL =
  Endpoint.BASE + Endpoint.ACTIVE_CONTRACTORS_PRIORITIES;
const ASSIGNED_TASKS_COUNT_PRIORITIES_URL =
  Endpoint.BASE + Endpoint.ASSIGNED_TASKS_COUNT_PRIORITIES;

export class TopPrioritiesServices {
  static async getSubmissionPriorities(): Promise<
    SubmissionPrioritiesResponse[]
  > {
    const response = await axios.get(SUBMISSION_PRIORITIES_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getContractorJoiningPriorities(): Promise<
    ContractorJoiningResponse[]
  > {
    const response = await axios.get(CONTRACTORS_JOINING_PRIORITIES_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getActiveContractorsPriorities(params: {
    company_id: string;
  }): Promise<{ email: string }[]> {
    const response = await axios.get(ACTIVE_CONTRACTORS_PRIORITIES_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getAssignedTasksCountPriorities(params: { emails: string }) {
    const response = await axios.get(ASSIGNED_TASKS_COUNT_PRIORITIES_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
