import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import ContractorComponent from "./ContractorComponent";
import { useEffect, useState } from "react";
import {
  AppointmentStatusCode,
  CandidateDataType,
  ContractEndEvent,
  ContractStartEvent,
} from "../../../types/calendarTypes";
import { useAppDispatch } from "../../../redux/store";
import { ContractsTabsEnums } from "../../../providers/ContractsProvider";
import { setContractType } from "../../../redux/slices/candidateSlice";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";

interface IContractProps {
  contractStatus: string;
  cadidateData: ContractEndEvent[] | ContractStartEvent[];
}

const Contract = ({ contractStatus, cadidateData }: IContractProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onViewContract = () => {
    dispatch(setContractType(ContractsTabsEnums.StatementsOfWork));
    navigate(ATS_ROUTES.CONTRACTS);
  };

  return (
    <Stack
      gap={"20px"}
      sx={{
        maxHeight:
          contractStatus === AppointmentStatusCode.contractStart ? 335 : "auto",
        overflow: "auto",
      }}
    >
      {cadidateData.map((item, index) => {
        return (
          <ContractorComponent
            contractorImg={item.candidate_data?.image}
            contractorName={`${item?.candidate_data?.first_name} ${item?.candidate_data?.last_name}`}
            projectName={"--"}
            role={item?.candidate_data?.role}
            key={index}
            renderBottomView={
              <Stack
                pt={"15px"}
                pb={"5px"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={
                  contractStatus === AppointmentStatusCode.contractStart
                    ? "flex-end"
                    : "space-between"
                }
              >
                {contractStatus !== AppointmentStatusCode.contractStart && (
                  <AtsTitleText
                    text={"Renew"}
                    isLink
                    isUnderline
                    fs={12}
                    fw={500}
                    textColor={COLORS.PRIMARY}
                  />
                )}
                <AtsTitleText
                  text={"View"}
                  fs={12}
                  fw={500}
                  isCursor
                  textColor={COLORS.PRIMARY}
                  onClick={onViewContract}
                />
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default Contract;
