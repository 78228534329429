import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { getAuthState } from "../redux/slices/authSlice";

interface IProps {
  children: ReactNode;
}

const PublicRoutes = ({ children }: IProps) => {
  const { isAuth } = useAppSelector(getAuthState);
  if (!isAuth) return <>{children}</>;
  return <Outlet />;
};

export default PublicRoutes;
