import React from "react";
import AtsModal from "../../../components/AtsModal";
import AtsRowBetween from "../../../components/AtsRowBetween";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import AtsRow from "../../../components/AtsRow";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";

interface IProps {
  open: boolean;
  header: string;
  width?: number;
  subText: string;
  toggelOpenClose: () => void;
  onSave: () => void;
  renderConponents: JSX.Element;
  renderBottomText?: JSX.Element;
}

const ProfileModal = ({
  open,
  toggelOpenClose,
  renderConponents,
  renderBottomText,
  onSave,
  header,
  subText,
  width = 460,
}: IProps) => {
  return (
    <>
      <AtsModal width={width} open={open} onClose={toggelOpenClose}>
        <>
          <Box
            sx={{
              px: "30px",
              pt: "23px",
              pb: "29px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsRowBetween>
              <>
                <Box></Box>
                <IconButton
                  sx={{
                    p: 0,
                  }}
                  onClick={toggelOpenClose}
                >
                  <Close />
                </IconButton>
              </>
            </AtsRowBetween>
            <Stack gap={"6px"}>
              <AtsTitleText text={header} textColor="#000" fs={18} fw={600} />
              <AtsTitleText
                text={subText}
                textColor={COLORS.DARK_GRAY}
                fs={12}
                fw={400}
              />
            </Stack>
            <Box
              sx={{
                mt: "30px",
              }}
            >
              {renderConponents}
            </Box>
          </Box>

          <AtsRowBetween
            sx={{
              px: "30px",
              py: "22px",
            }}
          >
            <>
              {renderBottomText ? <>{renderBottomText}</> : <Box></Box>}
              <AtsRoundedButton
                text="Save"
                buttonHeight={30}
                buttonWidth={94}
                onClick={onSave}
              />
            </>
          </AtsRowBetween>
        </>
      </AtsModal>
    </>
  );
};

export default ProfileModal;
