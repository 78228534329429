import axios from "axios";
import { GetCandidateInterviewByJobParams } from "../types/calendarTypes";
import { CompanyInfoResponse } from "../types/companyTypes";
import {
  ConvertOfferRequest,
  GetOfferDetailsParams,
  GetOfferDetailsResponse,
  GetOffersParams,
  GetOffersResponse,
  TermContentResponse,
  UpdateOfferRequest,
} from "../types/makeAnOfferTypes";
import { PaginatedResponse } from "../types/commonType";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  CANDIDATE_OFFERS_BY_JOB_ID_LIST: "candidate-offers-by-job",
  CANDIDATE_OFFERS_LIST: "offers-by-candidate-id",
  MAKE_AN_OFFER: "make-offer/create",
  CONVERT_OFFER: "offer-convert-to-direct-hire/create",
  UPDATE_AN_OFFER: "make-offer/update",
  OFFER_STATUS: "offer-status-list",
  GET_OFFER_DETAILS_BY_ID: "make-offer/get",
  TERM_CONTENT: "static-content-get?title=terms&_format=json",
};

const CANDIDATE_OFFERS_BY_JOB_ID_LIST_URL =
  Endpoint.BASE + Endpoint.CANDIDATE_OFFERS_BY_JOB_ID_LIST;
const CANDIDATE_OFFERS_LIST_URL =
  Endpoint.BASE + Endpoint.CANDIDATE_OFFERS_LIST;
const MAKE_AN_OFFER_URL = Endpoint.BASE + Endpoint.MAKE_AN_OFFER;
const CONVERT_OFFER_URL = Endpoint.BASE + Endpoint.CONVERT_OFFER;
const UPDATE_AN_OFFER_URL = Endpoint.BASE + Endpoint.UPDATE_AN_OFFER;
const TERM_CONTENT_URL = Endpoint.BASE + Endpoint.TERM_CONTENT;
const OFFER_STATUS_URL = Endpoint.BASE + Endpoint.OFFER_STATUS;
const GET_OFFER_DETAILS_BY_ID_URL =
  Endpoint.BASE + Endpoint.GET_OFFER_DETAILS_BY_ID;

export class MakeAnOfferServices {
  static async getOfferStatus(): Promise<{
    status_options: { [key: string]: string };
  }> {
    const response = await axios.get(OFFER_STATUS_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateOfferListByJobId(
    params: Partial<GetOffersParams>
  ): Promise<PaginatedResponse<GetOffersResponse>> {
    const response = await axios.get(CANDIDATE_OFFERS_BY_JOB_ID_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateOfferList(
    params: Partial<GetOffersParams>
  ): Promise<PaginatedResponse<GetOffersResponse>> {
    const response = await axios.get(CANDIDATE_OFFERS_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getOfferDetailsById(
    params: Partial<GetOfferDetailsParams>
  ): Promise<GetOfferDetailsResponse> {
    const response = await axios.get(GET_OFFER_DETAILS_BY_ID_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getTermContent(): Promise<TermContentResponse[]> {
    const response = await axios.get(TERM_CONTENT_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createOffer(request: { submission_id: number }) {
    const response = await axios.post(MAKE_AN_OFFER_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async convertOffer(request: ConvertOfferRequest) {
    const response = await axios.post(CONVERT_OFFER_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateOffer(
    request: Partial<UpdateOfferRequest>
  ): Promise<{ message: string }> {
    const response = await axios.patch(UPDATE_AN_OFFER_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
