import { Stack } from "@mui/material";
import AtsIcon from "../../../../components/AtsIcon";
import AtsTextInput from "../../../../components/AtsTextInput";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useProjectFilter } from "../../../../providers/ProjectFilterProvider";
import { COLORS } from "../../../../theme";
import { ProjectFilterState } from "../../../../types/projectTypes";
import FilterBudgetRange from "../../components/FilterBudgetRange";
import FilterDateRange from "../../components/FilterDateRange";
import FilterLoacation from "../../components/FilterLoacation";

export interface IProjectFilterProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ProjectFilterSection = ({ setPage }: IProjectFilterProps) => {
  const { projectFilter, setProjectFilter } = useProjectFilter();
  return (
    <Stack
      px={"20px"}
      my={"26px"}
      gap={"30px"}
      sx={{ height: "90%", overflow: "auto" }}
    >
      <Stack gap={"10px"}>
        <AtsTitleText
          text={"Search project"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <AtsTextInput
          height="42px"
          placeholder="Search project name,project..."
          endAdornment={<AtsIcon icon="icon-search-normal-1" height="18px" />}
          value={projectFilter?.title}
          onChange={(e) => {
            const updateProjectFilter = {
              ...projectFilter,
              title: e.target.value,
            } as ProjectFilterState;
            setProjectFilter(updateProjectFilter);
            setPage(0);
          }}
        />
      </Stack>
      <Stack gap={"10px"}>
        <FilterDateRange setPage={setPage} />
      </Stack>
      <Stack gap={"10px"}>
        <FilterLoacation setPage={setPage} />
      </Stack>
      <Stack gap={"10px"}>
        <FilterBudgetRange />
      </Stack>
    </Stack>
  );
};

export default ProjectFilterSection;
