import { Box, Stack, Typography } from "@mui/material";
import { Eye, InfoCircle } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { ATS_ROUTES } from "../../../router/routes";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import { MSAcontractResponse } from "../../../types/contractTypes";
import { useContracts } from "../../../providers/ContractsProvider";
import AtsCustomButton from "../../../components/AtsCustomButton";
import { Close } from "@mui/icons-material";
import AtsModal from "../../../components/AtsModal";
import { useState } from "react";
import PdfIcon from "../../../assets/images/pdfIcon.png";

const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;
interface IProps {
  contract: MSAcontractResponse;
}
const ActiveContracts = ({ contract }: IProps) => {
  const { setShowEsignForm, setSelectedMsaContracts } = useContracts();
  const [contractLink, setContractLink] = useState("");
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  const onView = (contract: MSAcontractResponse) => {
    if (contract && contract.contract_link && contract.contract_link.length) {
      toggleModal();
      setContractLink(contract.contract_link);
    }
  };

  const EsignComponent = ({ contract }) => {
    return (
      <Stack direction={"row"} alignItems={"flex-start"} gap={"7px"}>
        <Box>
          <InfoCircle size={16} variant="Bold" color="#e63030" />
        </Box>
        <Stack gap={"18px"}>
          <AtsTitleText
            text={`Your Master Services Agreement (MSA) is ready for e-signature. 
  Please e-sign it now to access the Dashboard.`}
            fs={12}
            fw={400}
          />
          <AtsRoundedButton
            text="E-Signature"
            isInitial
            buttonWidth={119}
            buttonHeight={26}
            fs={12}
            onClick={() => {
              setShowEsignForm(true);
              setSelectedMsaContracts(contract);
            }}
          />
        </Stack>
      </Stack>
    );
  };
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 188,
          mx: "9px",
          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 252,
                minHeight: 188,
                bgcolor: BG_COLORS.PRIMARY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: -4,
                  left: 0,
                }}
              >
                <img
                  src={require("../assets/images/documentEdit.png")}
                  alt=""
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <img src={require("../assets/images/talentMSA.png")} alt="" />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 188,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      gap={"55px"}
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      {/* <Stack
                        gap={"15px"}
                        sx={{
                          minWidth: 100,
                        }}
                      >
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={"Details"}
                          textColor={"#000"}
                        />
                        <Stack gap={"20px"}>
                          <DateText
                            text={"Start Date"}
                            value={"Sep 12, 2024"}
                          />
                          <DateText text={"End Date"} value={"Sep 12, 2025"} />
                        </Stack>
                      </Stack> */}
                      <Stack gap={"15px"}>
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={"Included in your contract"}
                          textColor={"#000"}
                        />
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: 1.43,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {dummyText}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      ...(contract.signed === "0"
                        ? { minWidth: 190, maxWidth: 210 }
                        : { minWidth: 202 }),

                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pr: "19px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {contract.signed === "0" ? (
                      <EsignComponent contract={contract} />
                    ) : (
                      <ViewContractComponent
                        contract={contract}
                        onView={() => onView(contract)}
                      />
                    )}
                  </Box>
                  {/* <Stack gap={"18px"}>
                        <AtsTitleText
                          text={`Your Master Services Agreement (MSA) is ready for e-signature. 
Please e-sign it now to access the Dashboard.`}
                          fs={12}
                          fw={400}
                        />
                        {contract.signed !== "0" ? (
                          ""
                        ) : (
                          <AtsRoundedButton
                            text="E-Signature"
                            isInitial
                            buttonWidth={119}
                            buttonHeight={26}
                            fs={12}
                            onClick={() => {
                              setShowEsignForm(true);
                              setSelectedMsaContracts(contract);
                            }}
                          />
                        )}
                      </Stack> */}
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
      <AtsModal width={800} height={550} open={open} onClose={toggleModal}>
        <Box height={"100%"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              text={"Contract Details"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsCustomButton px={0} py={0} onClick={toggleModal}>
              <Close />
            </AtsCustomButton>
          </Stack>
          <Stack
            px={"30px"}
            pt={"40px"}
            gap={"10px"}
            sx={{
              height: "calc(100% - 120px)",
            }}
          >
            <iframe
              src={contractLink}
              title="PDF Viewer"
              width="100%"
              height="100%"
              style={{ border: "none", borderRadius: "10px" }}
            />
          </Stack>
        </Box>
      </AtsModal>
    </>
  );
};

export default ActiveContracts;

const ViewContractComponent = ({
  contract,
  onView,
}: {
  contract: MSAcontractResponse;
  onView: () => void;
}) => {
  return (
    <Stack gap={"18px"} sx={{}}>
      <AtsRoundedButton
        text="View contract"
        isInitial
        buttonVariant="outline"
        buttonWidth={129}
        buttonHeight={26}
        fs={12}
        fw={400}
        startIcon={<Eye color={COLORS.PRIMARY} size={16} />}
        onClick={onView}
      />
      <AtsRoundedButton
        text="Download contract"
        isInitial
        buttonVariant="outline"
        buttonWidth={158}
        buttonHeight={26}
        fs={12}
        fw={400}
        startIcon={<img src={PdfIcon} alt="pdf" height={16} />}
        onClick={() => window.open(contract.contract_link, "_blank")}
      />
    </Stack>
  );
};
