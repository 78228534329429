import React from "react";
import EvidenceDetails from "../modules/EvidenceDetails";
import DisputeEntries from "../modules/DisputeEntries";
import AtsModal from "../../../../../../../../../components/AtsModal";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import {
  getContractorTimesheetState,
  setSelectedDisputeFlowTab,
  setShowDisputeModal,
} from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import { useTimesheet } from "../../../../../../../../../providers/TimesheetProvider";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import { Check, Close } from "@mui/icons-material";
import DisputedSuccessfully from "../modules/DisputedSuccessfully";

const DisputeModal = () => {
  const theme = useTheme();
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  const dispatch = useAppDispatch();
  const { isDisputed, selectedDisputeFlowTab, showDisputeModal } =
    useAppSelector(getContractorTimesheetState);
  const { onClearDispute } = useTimesheet();

  const onOpenCloseModal = () => {
    onClearDispute();
    dispatch(setShowDisputeModal(!showDisputeModal));
    dispatch(setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries));
    // dispatch()
  };
  return (
    <>
      <AtsModal
        width={isLargeXl ? 690 : 633}
        height={655}
        open={showDisputeModal}
        onClose={onOpenCloseModal}
      >
        <Box height={"100%"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              text={"Dispute Time"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsCustomButton px={0} py={0} onClick={onOpenCloseModal}>
              <Close />
            </AtsCustomButton>
          </Stack>
          {isDisputed ? (
            <DisputedSuccessfully />
          ) : (
            <>
              <Stack direction={"row"} gap={"10px"} width={"100%"}>
                {Object.values(DisputeTabsEnum).map((tab, index) => (
                  <Stack width={"50%"} key={tab}>
                    <Stack
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"10px"}
                        py={"16px"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            bgcolor:
                              selectedDisputeFlowTab ===
                                DisputeTabsEnum.EvidenceDetails && index === 0
                                ? COLORS.PRIMARY
                                : selectedDisputeFlowTab === tab
                                ? COLORS.PRIMARY
                                : "white",
                            border: BORDERS.PRIMARY,
                            color: "white",
                            height: 24,
                            width: 24,
                            borderRadius: "50px",
                          }}
                        >
                          {selectedDisputeFlowTab ===
                            DisputeTabsEnum.EvidenceDetails && index === 0 ? (
                            <Check
                              sx={{
                                fontSize: 18,
                              }}
                            />
                          ) : (
                            <AtsTitleText
                              text={index + 1}
                              fs={14}
                              fw={selectedDisputeFlowTab === tab ? 600 : 400}
                              textColor={
                                selectedDisputeFlowTab === tab
                                  ? "white"
                                  : COLORS.PRIMARY
                              }
                            />
                          )}
                        </Stack>
                        <AtsTitleText
                          text={tab}
                          fs={14}
                          fw={
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? 400
                              : selectedDisputeFlowTab === tab
                              ? 600
                              : 400
                          }
                          textColor={
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? COLORS.PRIMARY
                              : selectedDisputeFlowTab === tab
                              ? COLORS.PRIMARY
                              : COLORS.LIGHT_GRAY
                          }
                        />
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        height: "4px",
                        width: "100%",
                        bgcolor: "var(--border-gray)",
                        borderRadius: 0,
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          width:
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? "100%"
                              : selectedDisputeFlowTab ===
                                DisputeTabsEnum.EvidenceDetails
                              ? "50%"
                              : "50%",
                          bgcolor:
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? "#3c854d"
                              : selectedDisputeFlowTab === tab
                              ? "#3c854d"
                              : "var(--border-gray)",
                        }}
                      />
                    </Box>
                  </Stack>
                ))}
              </Stack>
              {selectedDisputeFlowTab === DisputeTabsEnum.EvidenceDetails ? (
                <EvidenceDetails />
              ) : (
                <DisputeEntries />
              )}
            </>
          )}
        </Box>
      </AtsModal>
    </>
  );
};

export default DisputeModal;
