import { Box, Skeleton, Stack } from "@mui/material";
import AtsRow from "../../../components/AtsRow";
import { BORDERS, COLORS } from "../../../theme";

const HiringFunnelLoadingState = () => {
  return (
    <>
      <Box
        sx={{
          py: "10px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <AtsRow>
          <>
            <Stack
              gap={"10px"}
              sx={{
                width: "30%",
              }}
            >
              <Skeleton
                variant="text"
                width="80%"
                height={24}
                sx={{ bgcolor: COLORS.PRIMARY }}
              />

              <AtsRow gap={"10px"}>
                <>
                  <Skeleton variant="text" width="60%" height={16} />
                  <Skeleton variant="circular" width={16} height={16} />
                </>
              </AtsRow>
            </Stack>

            <Stack
              sx={{
                width: "70%",
              }}
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                height={30}
                sx={{
                  bgcolor: COLORS.PRIMARY,
                  borderRadius: "6px",
                }}
              />
            </Stack>
          </>
        </AtsRow>
      </Box>
    </>
  );
};

export default HiringFunnelLoadingState;
