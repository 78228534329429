import { Circle } from "@mui/icons-material";
import { Box, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectOverviewServices } from "../../../../../../Api/projectOverviewServices";
import AtsCard from "../../../../../../components/AtsCard";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../theme";
import {
  JobSubmissionCountResponse,
  RecentlyPostedJobsResponse,
} from "../../../../../../types/projectOverviewTypes";
import { convertTimestampToDate } from "../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../utils/stringUtils";
import { setSelectedJob } from "../../../../../../redux/slices/jobSlice";
import { useAppDispatch } from "../../../../../../redux/store";
import { ATS_ROUTES } from "../../../../../../router/routes";

const RecentlyPostedJobs = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [recentlyPostedJobs, setRecentlyPostedJobs] = useState<
    RecentlyPostedJobsResponse[]
  >([]);

  // const { data:recentlyPostedJobs, loading } = useGetRecentJobsQuery(
  //   { project_id: id },
  //   { skip: !id }
  // );

  const getRecentlyPostedJobs = async () => {
    setLoading(true);
    try {
      const response = await ProjectOverviewServices.getRecentJobs({
        project_id: id,
      });
      setRecentlyPostedJobs(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getRecentlyPostedJobs();
  }, []);

  return (
    <>
      <Box pb={"29px"} mx={"15px"}>
        <AtsTitleText text={"Recently posted jobs"} fs={18} fw={600} />
      </Box>
      <Box pb={"14px"} mx={"15px"}>
        {loading ? (
          <Skeleton variant="text" width={80} />
        ) : (
          recentlyPostedJobs.length > 0 && (
            <AtsTitleText
              text={`${recentlyPostedJobs.length} new jobs`}
              fs={16}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          )
        )}
      </Box>
      <Stack
        px={"15px"}
        className="scrollHide"
        gap={"20px"}
        sx={{
          maxHeight: 373,
          overflowY: "auto",
        }}
      >
        {loading
          ? Array.from(new Array(2)).map((_, index) => (
              <AtsCard key={index}>
                <Stack px={1}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"20px"}
                    sx={{ minHeight: 17 }}
                    pt={1}
                    pb={"19px"}
                  >
                    <Skeleton variant="text" width={120} />
                  </Stack>
                  <Stack
                    sx={
                      {
                        // height: "44px",
                      }
                    }
                  >
                    <Skeleton variant="text" width={"100%"} />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"8px"}
                    rowGap={"3px"}
                    flexWrap={"wrap"}
                    pt={"9px"}
                    pb={"10px"}
                    sx={{
                      borderBottom: BORDERS.LIGHT_GRAY,
                    }}
                  >
                    <Skeleton variant="text" width={80} />
                    <Circle sx={{ fontSize: 3, color: "lightgray" }} />
                    <Skeleton variant="text" width={80} />
                    <Circle sx={{ fontSize: 3, color: "lightgray" }} />
                    <Skeleton variant="text" width={80} />
                    <Circle sx={{ fontSize: 3, color: "lightgray" }} />
                    <Skeleton variant="text" width={80} />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    pt={"16px"}
                    pb={"6px"}
                  >
                    <Skeleton variant="text" width={80} />
                    <Skeleton variant="text" width={80} />
                  </Stack>
                </Stack>
              </AtsCard>
            ))
          : recentlyPostedJobs.map((item) => (
              <Box key={item.id}>{<RenderCard item={item} />}</Box>
            ))}
      </Stack>
    </>
  );
};

export default RecentlyPostedJobs;

const renderText = (text: string) => {
  return (
    <AtsTitleText
      text={text}
      fs={11}
      fw={400}
      textColor={COLORS.LIGHT_GRAY}
      noTextWrap
    />
  );
};

const RenderCard = ({ item }: { item: RecentlyPostedJobsResponse }) => {
  const navidate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <AtsCard>
      <Stack px={1}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"20px"}
          sx={{ minHeight: 17 }}
          pt={1}
          pb={"19px"}
        >
          {item.active === "Yes" && (
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  bgcolor: COLORS.GREEN,
                  borderRadius: "20px",
                }}
              ></Box>
              <AtsTitleText
                fs={11}
                fw={700}
                textColor={COLORS.GREEN}
                text={"ACTIVE"}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          sx={
            {
              // height: "44px",
            }
          }
        >
          <AtsTitleText
            className="truncate_two_line"
            fs={16}
            fw={600}
            text={item?.title ?? "--"}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"8px"}
          rowGap={"3px"}
          flexWrap={"wrap"}
          pt={"9px"}
          pb={"10px"}
          sx={{
            borderBottom: BORDERS.LIGHT_GRAY,
          }}
        >
          {renderText(item?.country ?? "--")}
          <Circle sx={{ fontSize: 3, color: "lightgray" }} />
          {renderText("Full-time")}
          <Circle sx={{ fontSize: 3, color: "lightgray" }} />
          {renderText(`R-${item?.id ?? "--"}`)}
          <Circle sx={{ fontSize: 3, color: "lightgray" }} />
          {renderText(
            `Posted on ${
              item?.posted_date.length
                ? convertTimestampToDate(item?.posted_date)
                : "--"
            }`
          )}
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pt={"16px"}
          pb={"6px"}
        >
          {item.id.length > 0 && <GetSubmissionCount id={item.id} />}

          <AtsTitleText
            fs={12}
            fw={600}
            textColor={COLORS.PRIMARY}
            isCursor
            text={`View Job`}
            onClick={() => {
              dispatch(setSelectedJob(item.id));
              navidate(ATS_ROUTES.JOBS);
            }}
          />
        </Stack>
      </Stack>
    </AtsCard>
  );
};

const GetSubmissionCount = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [countInfo, setCountInfo] = useState<JobSubmissionCountResponse | null>(
    null
  );
  const getJobSubmission = async () => {
    setLoading(true);
    try {
      const response = await ProjectOverviewServices.getSubmissionCountByJobIds(
        { job_ids: id }
      );
      if (response.length) {
        setCountInfo(response[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getJobSubmission();
  }, []);

  return loading ? (
    <Skeleton variant="text" width={100} />
  ) : (
    <AtsTitleText
      fs={12}
      fw={500}
      textColor={COLORS.DARK_GRAY}
      text={
        isNotNullOrUndefined(countInfo)
          ? `${countInfo.count} Submissions`
          : "0 Submissions"
      }
    />
  );
};
