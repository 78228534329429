import { Avatar, Box, IconButton, Skeleton, Stack } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AtsTextInput from "../../../../components/AtsTextInput";
import { Close, Send } from "@mui/icons-material";
import { BG_COLORS, BORDERS, COLORS } from "../../../../theme";
import AtsTitleText from "../../../../components/AtsTitleText";
import { dayAndTimeFormatter } from "../../../../utils/dateUtils";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getMessageState,
  setConversationList,
  setTyping,
  setTypingIndicator,
} from "../../../../redux/slices/messageSlice";
import axios from "axios";
import incomingCallNotification from "../../../../assets/notification/imcoing-call.mp3";
import sendMessageNotification from "../../../../assets/notification/sent-sound.mp3";
import { token } from "./ChatWithAccountManager";
import { setIsOpenChatWindow } from "../../../../redux/slices/dashboardSlice";
import {
  ChatMessagesListProps,
  MessageList,
} from "../../../../types/messagesTypes";
import {
  getWebSocketState,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
} from "../../../../redux/slices/webSocketSlice";
import { connectSocket } from "../../../../socket";
import { io } from "socket.io-client";

const Conversations = () => {
  const dispatch = useAppDispatch();
  const { conversationList, conversation, typing } =
    useAppSelector(getMessageState);
  const [textArea, setTextArea] = useState<string>("");
  const [loading, setLoading] = useState(false);
  // const socket = connectSocket();
  const conversationListRef = useRef(conversationList);

  const onSendMessageNotification = new Audio(sendMessageNotification);
  const onIncomingCallNotification = new Audio(incomingCallNotification);

  // const socket = useMemo(
  //   () =>
  //     io("https://cdevws.esteemed.io", {
  //       withCredentials: true,
  //       extraHeaders: {
  //         Authorization:
  //           "Bearer " +
  //           `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFjNWRjZmE3LTcyOGYtNDQ4Yi1iYzU1LTUwNGM5NjEzZTQxYSIsImVtYWlsIjoic2hhaXNocGF0ZWxAZ21haWwuY29tIiwiZmlyc3ROYW1lIjoiU2hhaWxlbmRyYSIsImxhc3ROYW1lIjoiQ2hvZGF2YWRpeWEiLCJpYXQiOjE3MjQyNTk0NjEsImV4cCI6MTcyNDM0NTg2MX0.QcWJMTGQ7w5CI0Rr5-fB-ITcTj74tqkAumaklTN1uT4`,
  //       },
  //     }),
  //   []
  // );

  // const onStartTyping = async () => {
  //   socket.emit("onTypingStart", {
  //     recipientId: conversation?.recipient?.id,
  //     conversationId: conversation?.id,
  //   });
  // };

  // const onStopTyping = async () => {
  //   socket.emit("onTypingStop", {
  //     recipientId: conversation?.recipient?.id,
  //     conversationId: conversation?.id,
  //   });
  // };

  // const handleInputChange = (e) => {
  //   setTextArea(e.target.value);
  //   if (!typing) {
  //     setTyping(true);
  //     onStartTyping();
  //   }

  //   setTimeout(() => {
  //     setTyping(false);
  //     onStopTyping();
  //   }, 2000);
  // };

  // const handleSendMessage = async (e) => {
  //   e.preventDefault();
  //   try {
  //     onSendMessageNotification.pause();
  //     onSendMessageNotification.currentTime = 0;
  //     const payload = {
  //       content: textArea,
  //       conversationId: conversation?.id,
  //     };
  //     const response = await axios.post(
  //       "https://cdevapi.esteemed.io/v1/message/",
  //       payload,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     const preConversation = [...conversationList, response.data];
  //     dispatch(setConversationList(preConversation));
  //     socket.emit("createMessage", {
  //       message: textArea,
  //       recipientId: conversation.recipient.id,
  //       conversationDto: response.data,
  //     });
  //     setTextArea("");
  //     onSendMessageNotification.play();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getConversationsList = async (conversationId: string) => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `https://cdevapi.esteemed.io/v1/message/by/conversation/${conversationId}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     const conversationList = response.data as ChatMessagesListProps;
  //     if (conversationList) {
  //       const messages = conversationList.messages as MessageList[];
  //       if (messages.length) {
  //         const sortedData = messages.sort(
  //           (a, b) =>
  //             new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
  //         );
  //         dispatch(setConversationList(sortedData));
  //       }
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   conversation?.id && getConversationsList(conversation?.id);
  // }, [conversation]);

  // useEffect(() => {
  //   conversationListRef.current = conversationList;
  // }, [conversationList]);

  // useEffect(() => {
  //   socket.on("connected", (e) => {
  //     console.log("connected", e);
  //     dispatch(SOCKET_CONNECT({ event: e, socket: socket }));
  //   });

  //   console.log("conversationList", conversationList);
  //   socket.on("onMessage", (e) => {
  //     console.log("🚀 ~ socket.on ~ e:", e);
  //     if (conversation.id === e.conversationId) {
  //       onSendMessageNotification.play();
  //       onSendMessageNotification.play();
  //       const updatedList = [...conversationListRef.current, e];
  //       dispatch(setConversationList(updatedList));
  //       conversationListRef.current = updatedList;
  //     } else {
  //       // dispatch(setUnReadCount(e.conversationId));
  //     }
  //   });
  //   socket.on("onTypingStart", (e) => {
  //     dispatch(setTyping(true));
  //   });

  //   socket.on("onTypingStop", (e) => {
  //     dispatch(setTyping(false));
  //   });
  //   return () => {
  //     // socket.disconnect();
  //     // dispatch(SOCKET_DISCONNECT());
  //     socket?.off("onMessage");
  //     socket?.off("onTypingStart");
  //     socket?.off("onTypingStop");
  //   };
  // }, [socket]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            bgcolor: BG_COLORS.PRIMARY,
            px: 2,
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <AtsTitleText
            text={"Chat with Matt"}
            fs={14}
            fw={600}
            textColor={COLORS.PRIMARY}
          />
          <IconButton onClick={() => dispatch(setIsOpenChatWindow(false))}>
            <Close />
          </IconButton>
        </Stack>
        <Box
          component={"div"}
          sx={{
            position: "relative",

            height: "100%",
          }}
        >
          <Box
            className="inner"
            sx={{
              height: "calc(100% - 190px)",
              overflowY: "auto",
              padding: "15px",
              my: 1,
            }}
          >
            {loading
              ? Array.from(new Array(5)).map((_, index) => (
                  <Stack key={index} direction={"row"} gap={2} pb={1}>
                    <Box>
                      <Skeleton variant="circular" width={40} height={40} />
                    </Box>
                    <Stack gap={0.5} width="100%">
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Skeleton variant="text" width="50%" height={16} />
                        <Skeleton variant="text" width="30%" height={12} />
                      </Stack>
                      <Skeleton variant="text" width="80%" height={12} />
                    </Stack>
                  </Stack>
                ))
              : conversationListRef.current.map((conversation, index) => (
                  <Stack key={index} direction={"row"} gap={2} pb={1}>
                    <Box>
                      {conversation?.sender?.picture ? (
                        <img
                          width={40}
                          height={40}
                          src={conversation?.sender?.picture}
                          alt=""
                          style={{
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Avatar src={conversation?.sender?.picture} />
                      )}
                    </Box>
                    <Stack gap={0.5}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <AtsTitleText
                          text={conversation?.sender?.firstName}
                          fs={16}
                          fw={700}
                        />
                        <AtsTitleText
                          text={dayAndTimeFormatter(conversation?.updatedAt)}
                          fs={12}
                          fw={400}
                        />
                      </Stack>
                      <Stack>
                        <AtsTitleText
                          text={conversation?.content}
                          fs={12}
                          fw={400}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
          </Box>

          <form
            style={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                pb: 2,
                borderBottom: BORDERS.LIGHT_GRAY,
              }}
            >
              <Stack sx={{ minHeight: 10, px: 2 }}>
                {typing && <AtsTitleText text={"typing..."} fs={12} fw={600} />}
              </Stack>
              <Stack
                direction={"row"}
                sx={{
                  // minHeight: isExpand ? "495px" : "88px",
                  pl: 2,
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <AtsTextInput
                    value={textArea}

                    // onChange={handleInputChange}
                  />
                </Stack>
                <Stack direction={"column"} justifyContent={"space-between"}>
                  <IconButton
                    type="submit"
                    // onClick={handleSendMessage}
                  >
                    <Send />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default Conversations;
