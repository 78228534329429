import { Add } from "@mui/icons-material";
import { Box, Drawer, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectService } from "../../../Api/projectServices";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsSelect from "../../../components/AtsSelect";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import JobCardWrapper from "../components/JobCardWrapper";
import { OptionProps } from "../../../types/commonType";
import { ProjectListStatusEnum } from "../../../enums/projectEnums";
import { ProjectListProps } from "../../../types/projectTypes";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";
import CreateProjectDrawer from "./CreateProjectDrawer";
import {
  initailCreateProjectState,
  setCreateProjectState,
} from "../../../redux/slices/projectsSlice";

const projects = [
  "One",
  "Two",
  "Three",
  "Sixty two and One",
  "Hundred and Five",
];

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCreateProjectDrawer, setShowCreateProjectDrawer] =
    useState<boolean>(false);
  const [reletedProject, setReletedProject] = useState<OptionProps[]>([]);
  const [recentProject, setRecentProject] = useState<ProjectListProps[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== reletedProject.length) {
        setPage(page + 1);
      }
    }
  };

  const handleChange = (value: string) => {
    if (postOpportunity.job_project_id === value) {
      const opportunityObj = {
        ...postOpportunity,
        job_project_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_project_id: value,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const recentCreateProject = async () => {
    try {
      const response = await ProjectService.getProjectList({
        status: ProjectListStatusEnum.IN_PROGRESS,
      });
      if (response.results.length) {
        console.log("response.results", response.results);
        const firstFiveData = response.results.slice(0, 5);
        setRecentProject(firstFiveData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMyProject = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getMyProject({ page });
      if (response.results.length) {
        const options = response.results.map(
          (option) =>
            ({ label: option.title, value: option.nid } as OptionProps)
        );
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setReletedProject(options);
        } else {
          setReletedProject((prevList) => [...prevList, ...options]);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchFunction = () => {
    recentCreateProject();
    fetchMyProject();
  };
  useEffect(() => {
    fetchFunction();
  }, [page]);

  const toggleDrawer = () => {
    setShowCreateProjectDrawer(!showCreateProjectDrawer);
    dispatch(setCreateProjectState(initailCreateProjectState));
  };
  return (
    <>
      <JobCardWrapper header="Add this job to a Project">
        <Stack pr={1}>
          <Stack gap={1} pt={1}>
            <AtsTitleText
              text={"Related Projects"}
              fs={"0.875rem"}
              fw={600}
              textColor={COLORS.LIGHT_GRAY}
            />
            <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
              {loading
                ? Array.from(new Array(5)).map((_, index) => (
                    <Skeleton width={150} height={36} variant="rounded" />
                  ))
                : recentProject.map((project, index) => (
                    <AtsRoundedButton
                      onClick={() => handleChange(project.nid)}
                      key={index}
                      text={`Project ${project.title}`}
                      buttonVariant={
                        postOpportunity.job_project_id === project.nid
                          ? "contained"
                          : "whiteContained"
                      }
                      // endIcon={
                      //   postOpportunity.job_projects.includes(project) && <Close />
                      // }
                    />
                  ))}
            </Stack>
          </Stack>
          <Stack gap={1} py={5} sx={{ borderBottom: BORDERS.GRAY }}>
            <AtsTitleText
              text={"All Project"}
              textColor={COLORS.LIGHT_GRAY}
              fs={"0.875rem"}
              fw={600}
            />

            <AtsSelect
              placeholder="Select Project"
              selected={postOpportunity?.job_project_id}
              onSelect={(select: string) => handleChange(select)}
              options={reletedProject}
              onScroll={loadMoreItems}
            />
          </Stack>
          <Stack gap={1} py={2}>
            <AtsTitleText
              text={"What to create a new project?"}
              textColor={COLORS.BLACK}
              fs={"0.75rem"}
              fw={600}
            />
            <AtsTitleText
              text={
                "Don't worry create a new project to complete your job posting!"
              }
              textColor={COLORS.BLACK}
              fs={"0.625rem"}
              fw={400}
            />
            <Box>
              <AtsRoundedButton
                text={`Create Project`}
                buttonVariant={"outline"}
                startIcon={<Add />}
                onClick={toggleDrawer}
              />
            </Box>
          </Stack>
        </Stack>
      </JobCardWrapper>
      <Drawer
        PaperProps={{
          sx: {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            width: "500px",
            // overflow: "hidden",
          },
        }}
        anchor="right"
        open={showCreateProjectDrawer}
        onClose={toggleDrawer}
      >
        <CreateProjectDrawer
          toggleDrawer={toggleDrawer}
          fetchMyProject={fetchFunction}
        />
      </Drawer>
    </>
  );
};

export default Projects;
