import { Stack } from "@mui/material";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, BORDERS_2X } from "../../../theme";

interface Props {
  checked: boolean;
  title: string;
  onSelect: () => void;
}

const CheckboxInput = ({ title, checked, onSelect }: Props) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          border: checked ? BORDERS_2X.PRIMARY : BORDERS.GRAY,
          borderRadius: 2,
          pr: 1,
          pl: 2,
          py: 0.5,
          cursor: "pointer",
          minHeight: 38,
        }}
        onClick={onSelect}
      >
        <AtsTitleText text={title} fs={16} />
        <AtsCheckbox checked={checked} />
      </Stack>
    </>
  );
};

export default CheckboxInput;
