import { Button, Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import AtsDateRangePicker from "../../../components/AtsDateRangePicker";
import AtsIcon from "../../../components/AtsIcon";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import JobCardWrapper from "../components/JobCardWrapper";

const JobStartEndDate = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [selectedDate, setSelectedDate] = useState<string>(
    "Start Date - End Date"
  );

  const handleEvent = (event, picker) => {
    const startDate = moment(picker.startDate).format("YYYY-MM-DD");
    const endDate = moment(picker.endDate).format("YYYY-MM-DD");
    setSelectedDate(`${startDate} - ${endDate}`);
    const updateProjectState = {
      ...postOpportunity,
      job_start_date: startDate,
      job_end_date: endDate,
    } as PostOpportunityProps;
    dispatch(setPostOpportunity(updateProjectState));
  };
  const handleClear = () => {
    setSelectedDate(`Start Date - End Date`);
  };

  useEffect(() => {
    if (
      postOpportunity.job_start_date.length &&
      postOpportunity.job_end_date.length
    ) {
      setSelectedDate(
        `${postOpportunity.job_start_date} - ${postOpportunity.job_end_date}`
      );
    }
  }, [postOpportunity.job_start_date, postOpportunity.job_end_date]);

  return (
    <JobCardWrapper header="Select start date and end date">
      <Stack pr={1} gap={2}>
        <AtsDateRangePicker
          handleEvent={handleEvent}
          handleClear={handleClear}
          minDate={new Date()}
          // startDate={postOpportunity.job_start_date}
          // endDate={postOpportunity.job_end_date}
        >
          <Button
            variant="outlined"
            sx={{
              textTransform: "initial",
              height: 42,
              borderRadius: "6px",
              border: BORDERS.GRAY,
              color: COLORS.BLACK,
              fontSize: 14,
              fontWeight: 400,
              justifyContent: "flex-start",
              ":hover": {
                border: BORDERS.GRAY,
                color: COLORS.BLACK,
              },
            }}
            startIcon={
              <AtsIcon
                icon="icon-calendar-1"
                color={COLORS.BLACK}
                height="30px"
              />
            }
          >
            {selectedDate}
          </Button>
        </AtsDateRangePicker>
      </Stack>
    </JobCardWrapper>
  );
};

export default JobStartEndDate;
