import { createContext, useContext, useState } from "react";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
} from "../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";

type ExpenseModuleContextType = {
  openDrawer: boolean;
  show: boolean;
  openCloseDisputedResolutionDrawer: () => void;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DisputeResolutionModule = createContext<ExpenseModuleContextType | null>(
  null
);

export const useDisputeResolution = () =>
  useContext(DisputeResolutionModule) as ExpenseModuleContextType;

export const DisputeResolutionProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const openCloseDisputedResolutionDrawer = () => {
    dispatch(setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen));
    setOpenDrawer(!openDrawer);
  };

  return (
    <DisputeResolutionModule.Provider
      value={{
        openDrawer,
        show,
        setShow,
        setOpenDrawer,
        openCloseDisputedResolutionDrawer,
      }}
    >
      {children}
    </DisputeResolutionModule.Provider>
  );
};
