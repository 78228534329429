import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initailInviteMemberState = {
  emails: [],
  role_id: "",
};

interface ErrorProps {
  emails: string[];
  role_id: string;
}
export interface InviteMemberProps {
  emails: string[];
  role_id: string;
}

interface InviteMembersSliceState {
  isSend: number;
  inviteMember: InviteMemberProps;
  inviteError: Partial<ErrorProps>;
}

const initialState: InviteMembersSliceState = {
  isSend: 0,
  inviteMember: initailInviteMemberState,
  inviteError: {},
};

const inviteMembersSlice = createSlice({
  name: "inviteMembers",
  initialState,
  reducers: {
    setIsSend: (state: InviteMembersSliceState, action) => {
      const { payload } = action;
      state.isSend = payload;
    },
    setInviteMember: (state: InviteMembersSliceState, action) => {
      const { payload } = action;
      state.inviteMember = payload;
    },
    setInviteError: (state: InviteMembersSliceState, action) => {
      const { payload } = action;
      state.inviteError = payload;
    },
    setInitialInviteMemeberState: () => initialState,
  },
});

export const {
  setIsSend,
  setInitialInviteMemeberState,
  setInviteMember,
  setInviteError,
} = inviteMembersSlice.actions;
export const getInviteMembersState = (state: RootState) => state.inviteMembers;
export default inviteMembersSlice.reducer;
