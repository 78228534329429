import { Stack } from "@mui/material";
import { useEffect } from "react";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  ProfileToggleState,
  useMyProfile,
} from "../../../providers/MyProfileProvider";
import { COLORS } from "../../../theme";
import ExistingProfile from "./ExistingProfile";
import LoadingProfileState from "./LoadingProfileState";
import YourProfile from "./YourProfile";

const text = `Your Esteemed profile is an important part.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation`;

const CreateProfileTab = () => {
  const { profileToggleState, setProfileToggleState, loading } = useMyProfile();
  const { getUserProfile } = useMyProfile();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <Stack gap={"14px"} px={"10px"}>
        {loading ? (
          <LoadingProfileState />
        ) : profileToggleState === ProfileToggleState.Create ? (
          <YourProfile />
        ) : profileToggleState === ProfileToggleState.Edit ? (
          <ExistingProfile />
        ) : (
          <>
            <AtsTitleText
              text={"It's time to create your profile"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <Stack gap={"18px"}>
              <AtsTitleText
                text={text}
                fs={14}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
              <AtsRoundedButton
                text="Create profile"
                buttonVariant="outline"
                buttonWidth={127}
                buttonHeight={30}
                onClick={() => setProfileToggleState(ProfileToggleState.Create)}
              />
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default CreateProfileTab;
