import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InvoicesState {
  isInvoiceView: boolean;
}

const initialState: InvoicesState = {
  isInvoiceView: false,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setIsInvoiceView: (state: InvoicesState, action) => {
      const { payload } = action;
      state.isInvoiceView = payload;
    },
  },
});

export const { setIsInvoiceView } = invoicesSlice.actions;
export const getInvoicesState = (state: RootState) => state.invoices;
export default invoicesSlice.reducer;
