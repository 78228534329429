import React from "react";
import Empty from "../assets/images/Empty.svg";
import { BoxProps, Stack } from "@mui/material";
import { Box } from "@mui/material";
import AtsTitleText from "./AtsTitleText";
import { COLORS } from "../theme";

interface IProps extends BoxProps {
  text: string;
  subText: string;
  image?: string;
  imgWidth?: string | number;
  imgHeight?: string | number;
  textFs?: number;
  isStartAlign?: boolean;
}
const AtsEmptyState = ({
  text,
  subText,
  image = Empty,
  imgHeight = "100%",
  imgWidth = "100%",
  textFs = 16,
  isStartAlign,
  ...rest
}: IProps) => {
  const { sx } = rest;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: isStartAlign ? "flex-start" : "center",
        ...sx,
      }}
    >
      <Stack gap={"10px"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isStartAlign ? "flex-start" : "center",
          }}
        >
          <img src={image} alt="img" width={imgWidth} height={imgHeight} />
        </Box>

        <AtsTitleText
          text={text}
          fs={textFs}
          fw={400}
          textColor={COLORS.DARK_GRAY}
          textAlign={isStartAlign ? "start" : "center"}
        />
        <AtsTitleText
          text={subText}
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          textAlign={isStartAlign ? "start" : "center"}
        />
      </Stack>
    </Box>
  );
};

export default AtsEmptyState;
