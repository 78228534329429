import { Box, Skeleton, Stack } from "@mui/material";
import {
  HybridIcon,
  InPersonIcon,
  RemoteIcon,
} from "../../../assets/icons/Icons";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setJobDetailsById,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";
import JobCardWrapper from "../components/JobCardWrapper";
import { useEffect, useState } from "react";
import {
  JobCategoriesResponse,
  JobDetailByIdResponse,
  JobDetailsResponse,
} from "../../../types/jobsTypes";
import { JobsService } from "../../../Api/jobsListingServices";
import LongCard from "../components/LongCard";
import { htmlString } from "../../../utils/stringUtils";

const WorkMode = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById } = useAppSelector(
    getPostOpportunityState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [workMode, setWorkMode] = useState<JobCategoriesResponse[]>([]);

  const handleChange = (value: JobCategoriesResponse) => {
    if (postOpportunity.job_work_mode_id === value.id) {
      const opportunityObj = {
        ...postOpportunity,
        job_work_mode_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          work_mode: "",
        } as JobDetailByIdResponse)
      );
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_work_mode_id: value.id,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          work_mode: value.title,
        } as JobDetailByIdResponse)
      );
    }
  };

  const fetchJobTypes = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getWorkMode({ items_per_page: 50 });

      const iconMap = {
        Remote: "icon-monitor-recorder",
        "Remote location": "icon-monitor-recorder",
        Hybrid: "icon-routing-2",
        "In-person": "icon-buildings",
        inPerson: "icon-buildings",
        "In person": "icon-buildings",
      };

      const resposeWithIcon = response.results.map((job) => {
        const icon = iconMap[job.title];
        return icon ? { ...job, icon } : job;
      });
      setWorkMode(resposeWithIcon);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobTypes();
  }, []);
  return (
    <>
      <JobCardWrapper header="What’s your work mode?">
        <Stack pr={1} gap={2}>
          {loading
            ? Array.from(new Array(4)).map((_, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    pr: 1,
                    pl: 2,
                    py: 3,
                    cursor: "pointer",
                  }}
                >
                  <Stack>
                    <Skeleton
                      variant="text"
                      width={300}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={300}
                      sx={{ fontSize: "1rem" }}
                    />
                  </Stack>
                  <Box>
                    <Skeleton variant="circular" width={40} height={40} />
                  </Box>
                </Stack>
              ))
            : workMode.map((mode, index) => (
                <LongCard
                  key={index}
                  title={mode.title}
                  description={htmlString(mode.description)}
                  icon={mode.icon}
                  onSelect={() => handleChange(mode)}
                  checked={postOpportunity.job_work_mode_id === mode.id}
                />
              ))}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default WorkMode;
