import { Stack, Typography } from "@mui/material";
import { TickCircle } from "iconsax-react";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { BG_COLORS, COLORS } from "../../../theme";

interface IProps {
  text: string;
  renderButton?: () => React.ReactNode;
}

const Submit = ({ text, renderButton }: IProps) => {
  return (
    <AtsRow
      justifyContent={"center"}
      sx={{
        height: "100%",
      }}
    >
      <Stack
        gap={"20px"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TickCircle variant="Bold" size={35} color={COLORS.GREEN} />
        <AtsTitleText text={text} fs={24} fw={400} textColor="#000" />
        <Stack
          sx={{
            borderRadius: 2,
            bgcolor: BG_COLORS.GREEN,
            minWidth: 450,
            py: 1.2,
            px: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              color: COLORS.GREEN,
            }}
          >
            Check your{" "}
            <span
              style={{
                fontWeight: 700,
              }}
            >
              registered email
            </span>{" "}
            to receive a verification link.
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              color: COLORS.GREEN,
            }}
          >
            Click the{" "}
            <span
              style={{
                fontWeight: 700,
              }}
            >
              verification link
            </span>{" "}
            to confirm your email address.
          </Typography>
        </Stack>
        {renderButton && renderButton()}
      </Stack>
    </AtsRow>
  );
};

export default Submit;
