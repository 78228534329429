import React, { useEffect, useState } from "react";

import { Skeleton, Stack } from "@mui/material";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FilterIcon } from "../../../../../../../assets/icons/Icons";
import AtsCheckbox from "../../../../../../../components/AtsCheckbox";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsIcon from "../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import {
  setContractorEffortsDetails,
  setContractorOnboardingDetails,
  setContractorPlacmentDetails,
  setIsContractorDetailsDrawerOpen,
  setIsContractorOnboarded,
  setSelectedContractorDrawerTab,
} from "../../../../../../../redux/slices/contractorSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { ContractorByProjectResponse } from "../../../../../../../types/projectTypes";
import TimeColumn from "./TimeColumn";
import { SubmissionService } from "../../../../../../../Api/submissionServices";
import {
  setCandidateDetails,
  setIsCandidateDetailsLoading,
} from "../../../../../../../redux/slices/submissionSlice";
import { OnboardingServices } from "../../../../../../../Api/onboardingServices";
import { useParams } from "react-router-dom";
import { TimesheetServices } from "../../../../../../../Api/timesheetServices";
import { getAuthState } from "../../../../../../../redux/slices/authSlice";
import AtsEmptyState from "../../../../../../../components/AtsEmptyState";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}
interface UserProps {
  id: string;
  teamMember: string;
  teamManager: string;
  time: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "uid",
    header: "User",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "teamMember",
    header: "Team Memeber",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "account_manager_id",
    header: "Team Manager",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "time",
    header: "Time",
    width: "100px",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "80px",
    minWidth: "80px",
  },
];

const cellStyles = {
  padding: "12px 10px",
};
const firstCellStyles = {
  padding: "9px 0px",
};

const PendingTimeSheet = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const {
    contractorsTimesheet,
    loading,
    candidateTimelog,
    selectedUsers,
    setSelectedContractorTimesheet,
    setSelectedUsers,
  } = useTimesheet();

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    if (selectedUsers.length && contractorsTimesheet.length) {
      const filtercontractorTimesheet = contractorsTimesheet
        .map((value) => {
          const findIsHours = candidateTimelog.find(
            (log) => log.mail === value.email
          );
          if (findIsHours) {
            if (findIsHours.mail === value.email) {
              return value;
            }
          }
          return null;
        })
        .filter((value) => value);

      setSelectAll(selectedUsers.length === filtercontractorTimesheet.length);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers]);

  const handleUserSelect = (id: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllUser = () => {
    const newSelectAll = !selectAll;
    const filtercontractorTimesheet = contractorsTimesheet
      .map((value) => {
        const findIsHours = candidateTimelog.find(
          (log) => log.mail === value.email
        );
        if (findIsHours) {
          if (findIsHours.mail === value.email) {
            return value;
          }
        }
        return null;
      })
      .filter((value) => value);
    setSelectAll(newSelectAll);
    setSelectedUsers(
      newSelectAll ? filtercontractorTimesheet.map((user) => user.uid) : []
    );
  };
  const openContractDetailsDrawer = (
    contractor: ContractorByProjectResponse
  ) => {
    setSelectedContractorTimesheet(contractor);
    getCandidateDetials(contractor.uid);
    dispatch(setIsContractorDetailsDrawerOpen(true));
    dispatch(setSelectedContractorDrawerTab(1));
  };

  const getPlacement = async (email: string) => {
    try {
      const response = await OnboardingServices.getCandidatePlacementDetails({
        project_id: id,
        email,
      });
      if (response && response.length) {
        dispatch(setContractorPlacmentDetails(response[0]));
        return response;
      }
    } catch (error) {}
  };
  const getEfforts = async (email: string) => {
    try {
      const response = await TimesheetServices.getCandidateEfforts({
        company_id: userInfo?.company?.id,
        project_id: id,
        email,
      });
      if (response) {
        dispatch(setContractorEffortsDetails(response));
      }
    } catch (error) {}
  };
  const getContractOnboardingDetails = async (email: string) => {
    try {
      const response = await OnboardingServices.getOnboardingDetails({
        project_id: id,
        email,
      });
      if (response) {
        dispatch(setContractorOnboardingDetails(response));
        dispatch(setIsContractorOnboarded(true));
      }
    } catch (error) {
      dispatch(setContractorOnboardingDetails(null));
      dispatch(setIsContractorOnboarded(false));
    }
  };

  const getCandidateDetials = async (id: string) => {
    dispatch(setIsCandidateDetailsLoading(true));
    try {
      const response = await SubmissionService.getCandidateDetailsById({ id });
      if (response.results.length) {
        dispatch(setCandidateDetails(response.results[0]));
        const placementResponse = await getPlacement(
          response.results[0].email_address
        );
        await getEfforts(response.results[0].email_address);
        if (placementResponse) {
          placementResponse[0]?.onboarding_status !== "Pending" &&
            getContractOnboardingDetails(response.results[0].email_address);
        }
      } else {
        dispatch(setCandidateDetails(null));
      }
    } catch (error) {
    } finally {
      dispatch(setIsCandidateDetailsLoading(false));
    }
  };
  const customizeRow = (
    column: IColumnProps,
    contractor: ContractorByProjectResponse
  ) => {
    switch (column.key) {
      case "uid":
        const findIsHours = candidateTimelog.find(
          (log) => log.mail === contractor.email
        );
        return (
          <td style={firstCellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              {findIsHours ? (
                <AtsCheckbox
                  checked={selectedUsers.includes(contractor.uid)}
                  onChange={() => handleUserSelect(contractor.uid)}
                  p={0}
                />
              ) : (
                ""
              )}
            </Stack>
          </td>
        );
      case "teamMember":
        const teamMemberName = `${contractor?.first_name ?? ""} ${
          contractor?.last_name ?? ""
        }`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text={teamMemberName}
              />
            </Stack>
          </td>
        );
      case "account_manager_id":
        const teamManager = `${contractor?.account_manager_fname ?? ""} ${
          contractor?.account_manager_lname ?? ""
        }`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text={teamManager}
              />
            </Stack>
          </td>
        );
      case "time":
        return (
          <td style={cellStyles} key={column.key}>
            {loading ? (
              <Skeleton width={50} height={15} />
            ) : (
              <TimeColumn email={contractor.email} />
            )}
          </td>
        );

      case "action":
        const findLog = candidateTimelog.find(
          (log) => log.mail === contractor.email
        );
        return (
          <td key={column.key}>
            {findLog ? (
              <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <AtsCustomButton
                  px={0.6}
                  onClick={() => openContractDetailsDrawer(contractor)}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <AtsIcon
                      icon="icon-eye"
                      height="16px"
                      color={COLORS.BLACK}
                    />
                  </Stack>
                </AtsCustomButton>
              </Stack>
            ) : (
              ""
            )}
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = contractorsTimesheet.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              minHeight: "44px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const filtercontractorTimesheet = contractorsTimesheet
      .map((value) => {
        const findIsHours = candidateTimelog.find(
          (log) => log.mail === value.email
        );
        if (findIsHours) {
          if (findIsHours.mail === value.email) {
            return value;
          }
        }
        return null;
      })
      .filter((value) => value);
    const isIndeterminate =
      selectedUsers.length > 0 &&
      selectedUsers.length < filtercontractorTimesheet.length;

    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width:
              index === 0
                ? candidateTimelog.length > 0
                  ? "25px"
                  : "0px"
                : column.width,
            minWidth:
              index === 0
                ? candidateTimelog.length > 0
                  ? "25px"
                  : "0px"
                : column.minWidth,
            padding:
              index === 0
                ? candidateTimelog.length > 0
                  ? "15px 0px"
                  : "0px"
                : "15px 10px",
            textAlign: "start",
          }}
        >
          {index === 0 ? (
            candidateTimelog.length > 0 ? (
              <AtsCheckbox
                checked={selectAll}
                indeterminate={isIndeterminate}
                onChange={handleSelectAllUser}
                p={0}
              />
            ) : (
              <></>
            )
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <AtsCustomButton px={0.5}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsTitleText
                    text={column.header}
                    fs={14}
                    fw={600}
                    textColor="#4f4f4f"
                    isCursor
                  />
                  {column.header.length > 0 && <FilterIcon />}
                </Stack>
              </AtsCustomButton>
              {column.header.length > 0 && (
                <Stack>
                  <KeyboardArrowUp
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mb: -0.4,
                      cursor: "pointer",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mt: -0.5,
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      {loading ? (
        <AtsDataTable
          data={[]}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={loading}
        />
      ) : contractorsTimesheet.length ? (
        <AtsDataTable
          data={contractorsTimesheet}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={loading}
        />
      ) : (
        <AtsEmptyState
          sx={{
            height: "100%",
          }}
          imgHeight={68}
          imgWidth={74}
          text="No timesheet recently."
          subText=""
        />
      )}
    </>
  );
};

export default PendingTimeSheet;
