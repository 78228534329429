import axios from "axios";
import { FileItem, PaginatedResponse } from "../types/commonType";
import {
  CandidatePlacementParams,
  ContractorPlacementResponse,
  CreateUpdateOnboardingRequest,
  GetContractorOnboardingResponse,
  OnboardingCandidatesListParams,
  OnboardingCandidatesListResponse,
  ResponsibilitiesResponse,
} from "../types/onboardingTypes";
import { PROJECT_BASE_URL } from "../utils/url";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  ONBOARDING_CANDIDATES_LIST: "get/onboarding/candidates",
  ONBOARDING_CANDIDATE_PLACEMENT: "candidate/placement",
  ONBOARDING_DETAILS: "candidate/onboarding/get",
  CREATE_ONBOARDING: "candidate/onboarding/create/",
  UPDATE_ONBOARDING: "candidate/onboarding/edit",
  CONFIRM_ONBOARDING: "candidate/onboarding/confirm",
  RESPONSIBILITIES_LIST: "job/responsibilities/get",
  UPLOAD_DEVICE_FILES:
    "/file/upload/node/candidate_onboarding/field_device_setup_instruction_f?_format=json",
  UPLOAD_COMAPNY_VALUES_FILES:
    "/file/upload/node/candidate_onboarding/field_company_culture_values_fil?_format=json",
  UPLOAD_OFFICE_TOURS_FILES:
    "/file/upload/node/candidate_onboarding/field_office_tours_activities_fi?_format=json",
};

const ONBOARDING_CANDIDATES_LIST_URL =
  Endpoint.BASE + Endpoint.ONBOARDING_CANDIDATES_LIST;
const ONBOARDING_CANDIDATE_PLACEMENT_URL =
  Endpoint.BASE + Endpoint.ONBOARDING_CANDIDATE_PLACEMENT;
const CREATE_ONBOARDING_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.CREATE_ONBOARDING;
const UPDATE_ONBOARDING_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.UPDATE_ONBOARDING;
const ONBOARDING_DETAILS_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.ONBOARDING_DETAILS;
const RESPONSIBILITIES_LIST_URL =
  Endpoint.BASE + Endpoint.RESPONSIBILITIES_LIST;

const UPLOAD_DEVICE_FILES_URL = PROJECT_BASE_URL + Endpoint.UPLOAD_DEVICE_FILES;
const UPLOAD_COMAPNY_VALUES_FILES_URL =
  PROJECT_BASE_URL + Endpoint.UPLOAD_COMAPNY_VALUES_FILES;
const UPLOAD_OFFICE_TOURS_FILES_URL =
  PROJECT_BASE_URL + Endpoint.UPLOAD_OFFICE_TOURS_FILES;

const CONFIRM_ONBOARDING_URL = Endpoint.BASE + Endpoint.CONFIRM_ONBOARDING;

export class OnboardingServices {
  static async getOnboardingCandidatesList(
    params: Partial<OnboardingCandidatesListParams>
  ): Promise<PaginatedResponse<OnboardingCandidatesListResponse>> {
    const response = await axios.get(ONBOARDING_CANDIDATES_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getOnboardingDetails(
    params: Partial<CandidatePlacementParams>
  ): Promise<GetContractorOnboardingResponse> {
    const response = await axios.get(ONBOARDING_DETAILS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidatePlacementDetails(
    params: Partial<CandidatePlacementParams>
  ): Promise<ContractorPlacementResponse[]> {
    const response = await axios.get(ONBOARDING_CANDIDATE_PLACEMENT_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getResponsibilitiesList(params: {
    job_title: string;
  }): Promise<ResponsibilitiesResponse[]> {
    const response = await axios.get(RESPONSIBILITIES_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createOnboarding(
    request: Partial<CreateUpdateOnboardingRequest>
  ): Promise<{ data: GetContractorOnboardingResponse; messages: string }> {
    const response = await axios.post(CREATE_ONBOARDING_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateOnboarding(
    request: Partial<CreateUpdateOnboardingRequest>
  ): Promise<{ data: GetContractorOnboardingResponse; messages: string }> {
    const response = await axios.patch(UPDATE_ONBOARDING_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async confirmOnboarding(
    request: Partial<{ email: string; project_id: number }>
  ) {
    const response = await axios.patch(CONFIRM_ONBOARDING_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async uploadDeviceSetupFiles(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<FileItem> {
    const response = await axios.post(UPLOAD_DEVICE_FILES_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }

  static async uploadCompanyValuesFiles(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<FileItem> {
    const response = await axios.post(UPLOAD_COMAPNY_VALUES_FILES_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }

  static async uploadOfficeToursFiles(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<FileItem> {
    const response = await axios.post(UPLOAD_OFFICE_TOURS_FILES_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }
}
