interface IProps {
  strokeColor?: string;
}

export const FinanceIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.11 11.15H7.46c-.63 0-1.14.51-1.14 1.14v5.12h3.79v-6.26 0z"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.761 6.6h-1.52c-.63 0-1.14.51-1.14 1.14v9.66h3.79V7.74c0-.63-.5-1.14-1.13-1.14zM16.548 12.85h-2.65v4.55h3.79v-3.41c-.01-.63-.52-1.14-1.14-1.14z"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BusinessIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9 17h12.19c1.9 0 2.9-1 2.9-2.9V2h-18v12.1C3 16 4 17 5.9 17zM2 2h20M8 22l4-2v-3M16 22l-4-2"
        stroke={strokeColor}
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m7.5 11 3.15-2.63c.25-.21.58-.15.75.13l1.2 2c.17.28.5.33.75.13L16.5 8"
        stroke={strokeColor}
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TechnologyIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 13c-.66.33-1.21.82-1.62 1.43-.23.35-.23.79 0 1.14.41.61.96 1.1 1.62 1.43M15.21 13c.66.33 1.21.82 1.62 1.43.23.35.23.79 0 1.14-.41.61-.96 1.1-1.62 1.43"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7zM2.23 8.01 21.45 8"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SupportIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.97 18.33c0 .36-.08.73-.25 1.09-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.6.25-1.25.38-1.95.38-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98a28.75 28.75 0 0 1-3.28-2.8 28.414 28.414 0 0 1-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.54.53 1.06 1.02 1.59 1.47.52.44.95.74 1.29.92.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M18.5 9c0-.6-.47-1.52-1.17-2.27-.64-.69-1.49-1.23-2.33-1.23M22 9c0-3.87-3.13-7-7-7"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LegalIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22h20M12 2c1.6.64 3.4.64 5 0v3c-1.6.64-3.4.64-5 0V2zM12 5v3M17 8H7c-2 0-3 1-3 3v11h16V11c0-2-1-3-3-3zM4.58 12h14.84"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99 12v10M11.99 12v10M15.99 12v10"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const MarketingIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.32 12c2.6 0 3.68-1 2.72-4.28-.65-2.21-2.55-4.11-4.76-4.76C13 2 12 3.08 12 5.68v2.88C12 11 13 12 15 12h3.32z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 14.7a9.091 9.091 0 0 1-10.42 7.17c-3.79-.61-6.84-3.66-7.46-7.45A9.1 9.1 0 0 1 9.26 4.01"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SalesIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.88 18.15v-2.07M12 18.15v-4.14M17.12 18.15v-6.22M17.12 5.85l-.46.54a18.882 18.882 0 0 1-9.78 6.04"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.19 5.85h2.93v2.92"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AdvertisingIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67 14H4c-1.1 0-2 .9-2 2v6h6.67v-8z"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.33 10h-2.67c-1.1 0-2 .9-2 2v10h6.67V12a2 2 0 0 0-2-2zM20 17h-4.67v5H22v-3c0-1.1-.9-2-2-2z"
        stroke={strokeColor}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.52 2.07.53 1.06c.07.15.26.29.42.31l.96.16c.61.1.76.55.32.98l-.75.75c-.13.13-.2.37-.16.54l.21.92c.17.73-.22 1.01-.86.63l-.9-.53c-.16-.1-.43-.1-.59 0l-.9.53c-.64.38-1.03.1-.86-.63l.21-.92a.63.63 0 0 0-.16-.54l-.74-.74c-.44-.44-.3-.88.32-.98l.96-.16c.16-.03.35-.17.42-.31l.53-1.06c.29-.58.75-.58 1.04-.01z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const WritingIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 13.4v3c0 4-1.6 5.6-5.6 5.6H7.6c-4 0-5.6-1.6-5.6-5.6v-3.8C2 8.6 3.6 7 7.6 7h3"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 13.4h-3.2c-2.4 0-3.2-.8-3.2-3.2V7l6.4 6.4zM11.6 2h4M7 5c0-1.66 1.34-3 3-3h2.62M22 8v6.19c0 1.55-1.26 2.81-2.81 2.81M22 8h-3c-2.25 0-3-.75-3-3V2l6 6z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CreativeIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.76 22h10.48c2.76 0 3.86-1.69 3.99-3.75l.52-8.26A3.753 3.753 0 0 0 18 6c-.61 0-1.17-.35-1.45-.89l-.72-1.45C15.37 2.75 14.17 2 13.15 2h-2.29c-1.03 0-2.23.75-2.69 1.66l-.72 1.45C7.17 5.65 6.61 6 6 6 3.83 6 2.11 7.83 2.25 9.99l.52 8.26C2.89 20.31 4 22 6.76 22zM10.5 8h3"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18c1.79 0 3.25-1.46 3.25-3.25S13.79 11.5 12 11.5s-3.25 1.46-3.25 3.25S10.21 18 12 18z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DesignIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.07 4.93 4.93 19.07A9.969 9.969 0 0 1 2 12C2 6.48 6.48 2 12 2c2.76 0 5.26 1.12 7.07 2.93z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12c0 5.52-4.48 10-10 10-2.76 0-5.26-1.12-7.07-2.93L19.07 4.93A9.969 9.969 0 0 1 22 12zM7.76 16.24l5.65 5.66M11.29 12.71l6.58 6.57M14.83 9.17l6.29 6.3"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PeopleOpsIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 0 1-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 0 1 .16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 0 0-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const EngineeringIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 0 1-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 0 1 .16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 0 0-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const EducationIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 0 1-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 0 1 .16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 0 0-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const WorkplaceIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 0 1-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 0 1 .16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 0 0-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LifeStyleIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 0 1-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 0 1 .16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 0 0-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const UploadIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.63 20.217a3.261 3.261 0 1 0 0-6.522 3.261 3.261 0 0 0 0 6.522z"
        stroke="#4F4F4F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.152 7.17H16.63c-8.152 0-11.413 3.26-11.413 11.413v9.783c0 8.152 3.261 11.413 11.413 11.413h9.783c8.152 0 11.413-3.261 11.413-11.413v-8.153"
        stroke="#4F4F4F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.304 16.953V7.17l3.261 3.26M31.304 7.17l-3.26 3.26M6.31 34.81l8.038-5.397c1.288-.864 3.147-.766 4.304.229l.538.473c1.272 1.092 3.326 1.092 4.598 0l6.783-5.821c1.271-1.093 3.326-1.093 4.598 0l2.657 2.282"
        stroke="#4F4F4F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BulbIcon = ({ strokeColor = "#231F20" }: IProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0624 13.7625C11.2874 13.0126 11.7374 12.3376 12.3374 11.8125C13.4624 10.875 14.1749 9.45003 14.1749 7.87503C14.1749 4.95003 11.7374 2.55002 8.73742 2.70003C6.0749 2.81253 3.89989 5.06253 3.8249 7.72502C3.78739 9.37504 4.4999 10.8375 5.66241 11.8125C6.2999 12.3375 6.7499 13.0125 6.9749 13.7625H11.0624Z"
        fill="#FFD15C"
      />
      <path
        d="M8.5125 13.8383H8.85L7.34998 8.25077C7.3875 8.25077 7.42497 8.25077 7.46248 8.25077C7.64997 8.25077 7.8375 8.17578 7.98747 8.02577C8.06246 7.95078 8.13748 7.91327 8.24998 7.91327C8.36248 7.91327 8.43747 7.95078 8.5125 8.02577C8.77501 8.32576 9.22501 8.32576 9.48748 8.02577C9.56247 7.95078 9.6375 7.91327 9.75 7.91327C9.82498 7.91327 9.93748 7.95078 10.0125 8.02577C10.1625 8.17578 10.3125 8.25077 10.5375 8.25077C10.575 8.25077 10.6125 8.25077 10.65 8.25077L9.1875 13.8383H9.525L11.0625 7.98826C11.0625 7.91327 11.0625 7.83825 10.9875 7.80077C10.9125 7.76326 10.8375 7.80077 10.8 7.83828C10.725 7.91327 10.65 7.95078 10.575 7.95078C10.4625 7.95078 10.3875 7.91327 10.275 7.83828C10.125 7.68827 9.97503 7.61328 9.78751 7.61328C9.59998 7.61328 9.45001 7.68827 9.3 7.83828C9.14998 7.98829 8.92498 7.98829 8.77501 7.83828C8.66251 7.68827 8.47502 7.61328 8.2875 7.61328C8.10001 7.61328 7.91248 7.68827 7.79998 7.83828C7.725 7.91327 7.6125 7.95078 7.5 7.95078C7.42501 7.95078 7.31251 7.91327 7.275 7.83828C7.23748 7.80077 7.1625 7.76329 7.08751 7.80077C7.01252 7.83828 6.97501 7.91327 7.01252 7.98826L8.5125 13.8383Z"
        fill="white"
      />
      <path
        d="M7.83765 17.2129C8.02517 17.6629 8.47517 18.0004 9.00016 18.0004C9.52515 18.0004 9.97515 17.6629 10.1627 17.2129H7.83765Z"
        fill="#344A5E"
      />
      <path
        d="M10.3876 17.2496H7.6501C7.27509 17.2496 6.9751 16.9496 6.9751 16.5746V13.7246H11.0626V16.5746C11.0626 16.9496 10.7626 17.2496 10.3876 17.2496Z"
        fill="#344A5E"
      />
      <path
        d="M11.025 15.1492H6.97499C6.74999 15.1492 6.5625 14.9617 6.5625 14.7367C6.5625 14.5117 6.74999 14.3242 6.97499 14.3242H11.025C11.25 14.3242 11.4375 14.5117 11.4375 14.7367C11.4375 14.9617 11.25 15.1492 11.025 15.1492Z"
        fill="#415A6B"
      />
      <path
        d="M11.025 16.4617H6.97499C6.74999 16.4617 6.5625 16.2742 6.5625 16.0492C6.5625 15.8242 6.74999 15.6367 6.97499 15.6367H11.025C11.25 15.6367 11.4375 15.8242 11.4375 16.0492C11.4375 16.2742 11.25 16.4617 11.025 16.4617Z"
        fill="#415A6B"
      />
      <path
        d="M9.00001 0C8.81252 0 8.625 0.150012 8.625 0.375012V1.65002C8.625 1.83751 8.77501 2.02504 9.00001 2.02504C9.22501 2.02504 9.37502 1.87502 9.37502 1.65002V0.375012C9.37502 0.150012 9.1875 0 9.00001 0Z"
        fill="#FFD15C"
      />
      <path
        d="M3.97513 2.28829C3.82512 2.13828 3.60012 2.13828 3.45014 2.28829C3.30017 2.4383 3.30013 2.6633 3.45014 2.81328L4.35014 3.71328C4.50015 3.86329 4.72515 3.86329 4.87513 3.71328C5.02511 3.56327 5.02514 3.33827 4.87513 3.18829L3.97513 2.28829Z"
        fill="#FFD15C"
      />
      <path
        d="M2.81262 7.46289H1.53761C1.35012 7.46289 1.1626 7.6129 1.1626 7.8379C1.1626 8.02539 1.31261 8.21291 1.53761 8.21291H2.81262C3.00011 8.21291 3.18763 8.0629 3.18763 7.8379C3.18763 7.65038 3.00011 7.46289 2.81262 7.46289Z"
        fill="#FFD15C"
      />
      <path
        d="M4.35016 11.9621L3.45016 12.8621C3.30014 13.0121 3.30014 13.2371 3.45016 13.3871C3.60017 13.5371 3.82517 13.5371 3.97514 13.3871L4.87514 12.4871C5.02515 12.3371 5.02515 12.1121 4.87514 11.9621C4.72513 11.8121 4.50017 11.8121 4.35016 11.9621Z"
        fill="#FFD15C"
      />
      <path
        d="M13.6502 11.9621C13.5002 11.8121 13.2752 11.8121 13.1252 11.9621C12.9752 12.1121 12.9752 12.3371 13.1252 12.4871L14.0252 13.3871C14.1752 13.5371 14.4002 13.5371 14.5502 13.3871C14.7002 13.2371 14.7002 13.0121 14.5502 12.8621L13.6502 11.9621Z"
        fill="#FFD15C"
      />
      <path
        d="M16.4625 7.46289H15.1875C15 7.46289 14.8125 7.6129 14.8125 7.8379C14.8125 8.02539 14.9625 8.21291 15.1875 8.21291H16.4625C16.65 8.21291 16.8375 8.0629 16.8375 7.8379C16.8375 7.65038 16.6875 7.46289 16.4625 7.46289Z"
        fill="#FFD15C"
      />
      <path
        d="M14.0252 2.28828L13.1252 3.18828C12.9752 3.33829 12.9752 3.56329 13.1252 3.71327C13.2752 3.86328 13.5002 3.86328 13.6502 3.71327L14.5502 2.81327C14.7002 2.66325 14.7002 2.43825 14.5502 2.28828C14.4002 2.1383 14.1752 2.13827 14.0252 2.28828Z"
        fill="#FFD15C"
      />
    </svg>
  );
};
