import React from "react";
import Calendar, { CalendarProps } from "react-calendar";
import { tileDisabled } from "../utils/dateUtils";
import "react-calendar/dist/Calendar.css";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { COLORS } from "../theme";

export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Props extends CalendarProps {}

const AtsDateCalenderPicker = ({ ...rest }: Props) => {
  return (
    <>
      <Calendar
        {...rest}
        tileDisabled={tileDisabled}
        prevLabel={<NavigateBefore sx={{ color: COLORS.LIGHT_GRAY }} />}
        nextLabel={<NavigateNext sx={{ color: COLORS.LIGHT_GRAY }} />}
        className={"calendar"}
      />
    </>
  );
};

export default AtsDateCalenderPicker;
