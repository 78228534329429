import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getSubmissionState } from "../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { MessageTypeEnum } from "../enums/messagingEnums";
import {
  ChatProps,
  CreateConversationRequest,
  CreateGroupRequest,
  GroupUsers,
  Messages,
  SendMessageReaponse,
} from "../types/messagesTypes";
import { MessageServices } from "../Api/messageServices";
import { io, Socket } from "socket.io-client";
import { WEB_SOCKET } from "../utils/url";
import { isNotNullOrUndefined } from "../utils/stringUtils";
import sendMessageNotification from "../assets/notification/sent-sound.mp3";
import incomingCallNotification from "../assets/notification/incoming-notification.mp3";
import { CreateUpdateEnum } from "../enums/commonEnums";
import { useCallingStore } from "./CallingProvider";
import { useSocket } from "./SocketProvider";

interface DefinePreDetailsProps {
  name: string;
  email: string;
  phoneNumber: string;
  image: string | null;
}

type MessagesModuleContextType = {
  socket: Socket | null;
  selectedMessageType: string;
  projectId: string;
  unReadMessageId: string;
  messenger: ChatProps | null;
  messengerState: DefinePreDetailsProps | null;
  conversations: Messages[];
  unReadConversation: Messages[];
  messengersList: ChatProps[];
  isMessengerListLoading: boolean;
  isConversationsLoading: boolean;
  showContractorDetails: boolean;
  showProjectDetails: boolean;
  openCreateGroupModal: boolean;
  openAddMembersModal: boolean;
  showNewMessage: boolean;
  createChatLoading: boolean;
  typing: boolean;
  createGroupLoading: boolean;
  storeTyping: {
    [conversationId: string]: boolean;
  };
  setSelectedMessageType: React.Dispatch<React.SetStateAction<string>>;
  setIsMessengerListLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowContractorDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
  getConversationsById: (messenger: ChatProps) => void;
  handleShowContractorDetails: (value: boolean) => void;
  handleShowProjectDetails: (value: boolean) => void;
  handleOpenCreateGroupModal: (value: boolean) => void;
  handleOpenAddMembersModal: (value: boolean) => void;
  handleShowNewMessage: (value: boolean) => void;
  handleSendMessage: (e: any, content: string, conversation: ChatProps) => void;
  onStartTyping: () => void;
  onStopTyping: () => void;
  onAcceptCall: () => void;
  onOutgoingCallEnd: () => void;
  createOnetoOneChat: (request: CreateConversationRequest) => void;

  createOrUpdateGroup: (
    request: Partial<CreateGroupRequest>,
    state: string
  ) => void;
};
export const dummyToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFjNWRjZmE3LTcyOGYtNDQ4Yi1iYzU1LTUwNGM5NjEzZTQxYSIsImVtYWlsIjoic2hhaXNocGF0ZWxAZ21haWwuY29tIiwiZmlyc3ROYW1lIjoiU2hhaWxlbmRyYSIsImxhc3ROYW1lIjoiQ2hvZGF2YWRpeWEiLCJpYXQiOjE3MjgwNDcyMDcsImV4cCI6MTcyODEzMzYwN30.hy2PvZ8rPjh9vXWD_5DJBa8yDTedMYtAP4Z189-qoJE`;
const MessagesModule = createContext<MessagesModuleContextType | null>(null);
const dummyToken2 = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQwNTk1M2EwLTlmZDUtNDQ5NS04MTAzLThkODg1M2I2NWRjMSIsImVtYWlsIjoiY2hvZGF2YWRpeWFzaGFpbGVuZHJhQGdtYWlsLmNvbSIsImZpcnN0TmFtZSI6IlNoYWlsZW5kcmEiLCJsYXN0TmFtZSI6IkNob2RhdmFkaXlhIiwiaWF0IjoxNzI3OTUyMjEzLCJleHAiOjE3MjgwMzg2MTN9.J_n5fTL4g4JAsZKhdiQlV-QUPUxt1IUNW4kb0UdQOyA`;

export const useMessages = () =>
  useContext(MessagesModule) as MessagesModuleContextType;

export const MessagesProvider = ({ children }: any) => {
  const getUnReadMesseages = localStorage.getItem("unReadMessages")
    ? JSON.parse(localStorage.getItem("unReadMessages"))
    : [];
  const { socket } = useSocket();
  const onSendMessageNotification = new Audio(sendMessageNotification);
  const onIncomingCallNotification = new Audio(incomingCallNotification);

  // const [socket, setSocket] = useState<Socket | null>(null);
  const [selectedMessageType, setSelectedMessageType] = useState<string>(
    MessageTypeEnum.All
  );
  const [messengersList, setMessengersList] = useState<ChatProps[]>([]);
  const [conversations, setConversations] = useState<Messages[]>([]);
  const [unReadConversation, setUnReadConversation] =
    useState<Messages[]>(getUnReadMesseages);
  const [messenger, setMessenger] = useState<ChatProps | null>(null);
  const [messengerState, setMessengerState] =
    useState<DefinePreDetailsProps | null>(null);

  const [isMessengerListLoading, setIsMessengerListLoading] =
    useState<boolean>(false);
  const [isConversationsLoading, setIsConversationsLoading] =
    useState<boolean>(false);
  const [showContractorDetails, setShowContractorDetails] =
    useState<boolean>(false);
  const [showProjectDetails, setShowProjectDetails] = useState<boolean>(false);
  const [createChatLoading, setCreateChatLoading] = useState<boolean>(false);
  const [openCreateGroupModal, setOpenCreateGroupModal] =
    useState<boolean>(false);
  const [openAddMembersModal, setOpenAddMembersModal] =
    useState<boolean>(false);
  const [typing, setTyping] = useState<boolean>(false);
  const [showNewMessage, setShowNewMessage] = useState<boolean>(false);
  const [createGroupLoading, setCreateGroupLoading] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>("");
  const [storeTyping, setStoreTyping] = useState<{
    [conversationId: string]: boolean;
  }>({});
  const [unReadMessageId, setUnReadMessageId] = useState<string>("");

  const {
    storeCallInfo,
    setIncomingOrOutGoingCall,
    setAcceptCall,
    setCallIsDisconnected,
    setOpenCallingModal,
  } = useCallingStore();

  // useEffect(() => {
  //   if (!socket) {
  //     const newSocket = io(WEB_SOCKET);
  //     setSocket(newSocket);
  //     return () => {
  //       newSocket.disconnect();
  //     };
  //   }
  // }, []);
  // const socket = useMemo(() => io("https://api.propertise.com"), []);

  const onStartTyping = async () => {
    socket.emit("onTypingStart", {
      recipientId: messenger?.recipient?.id,
      conversationId: messenger?.id,
    });
  };

  const onStopTyping = async () => {
    socket.emit("onTypingStop", {
      recipientId: messenger?.recipient?.id,
      conversationId: messenger?.id,
    });
  };
  const addUnReadMessageCount = (
    messengersList: ChatProps[],
    unReadMesengerList: Messages[]
  ) => {
    const updatedMessengerList = messengersList.map((messenger) => {
      const matchingUnreadConversations = unReadMesengerList.filter(
        (unread) => unread.conversation.id === messenger.id && !unread.isRead
      );

      return {
        ...messenger,
        unReadMessageCount: matchingUnreadConversations.length,
      };
    });
    return updatedMessengerList;
  };

  const updateMessengerList = (
    conversationId: string,
    response: SendMessageReaponse,
    unReadMesengerList: Messages[]
  ) => {
    const updateMessengerListArr = addUnReadMessageCount(
      messengersList,
      unReadMesengerList
    );
    const findIndexOfConversationList = updateMessengerListArr.findIndex(
      (list) => list.id === conversationId
    );

    const preList = [...updateMessengerListArr];
    const findLastConversationList = {
      ...preList[findIndexOfConversationList],
    };
    const updateList = {
      ...findLastConversationList,
      latestMessage: {
        ...findLastConversationList.latestMessage,
        content: response?.content,
        updatedAt: response?.updatedAt,
      },
    } as ChatProps;
    preList.splice(findIndexOfConversationList, 1, updateList);
    const sortedData = preList.sort(
      (a, b) =>
        new Date(b?.latestMessage?.updatedAt).getTime() -
        new Date(a?.latestMessage?.updatedAt).getTime()
    );

    setMessengersList(sortedData);
  };

  const setTypingIndicator = (conversationId: string, typing: boolean) => {
    setStoreTyping((prev) => ({
      ...prev,
      [conversationId]: typing,
    }));
  };

  useEffect(() => {}, [conversations]);

  useEffect(() => {
    socket.on("connected", (e) => {
      console.log("connected", conversations);
    });
    socket.on("onConversationJoin", (e) => {
      console.log("onConversationJoin", e);
    });

    socket.on("onMessage", async (e) => {
      console.log("🚀 ~ socket.on ~ e:", e);
      if (
        isNotNullOrUndefined(messenger) &&
        messenger.id === e.conversationId
      ) {
        onSendMessageNotification.play();
        const unreadMsg = { ...e, isRead: true };
        const updatedList = [...conversations, unreadMsg];

        setConversations(updatedList);
      } else {
        const unreadMsg = { ...e, isRead: false };
        localStorage.setItem(
          "unReadMessages",
          JSON.stringify([...unReadConversation, unreadMsg])
        );
        setUnReadConversation([...unReadConversation, unreadMsg]);

        setUnReadMessageId(e.conversationId);
        updateMessengerList(e.conversationId, e, [
          ...unReadConversation,
          unreadMsg,
        ]);
      }
    });
    socket.on("onTypingStart", (e) => {
      setTypingIndicator(e.conversationId, true);
    });

    socket.on("onTypingStop", (e) => {
      setTypingIndicator(e.conversationId, false);
    });

    socket.on("onIncomingCall", (e) => {
      const userInfoId = "405953a0-9fd5-4495-8103-8d8853b65dc1";

      let mode = userInfoId === e.callBy.id ? "OUTGOING" : "INCOMING";
      setOpenCallingModal(true);
      setIncomingOrOutGoingCall({
        ...e,
        mode,
      });

      if (mode === "INCOMING") {
        onIncomingCallNotification.play();
      }
    });

    socket.on("onOutgoingCallEnd", () => {
      setCallIsDisconnected();
    });

    socket.on("onAnotherCall", (e) => {});

    socket.on("onAcceptCall", (e) => {
      setAcceptCall();
    });
    return () => {
      // socket.disconnect();
      // dispatch(SOCKET_DISCONNECT());
      socket?.off("connected");
      socket?.off("onMessage");
      socket?.off("onTypingStart");
      socket?.off("onTypingStop");
      socket?.off("onIncomingCall");
      socket?.off("onOutgoingCallEnd");
      socket?.off("onAnotherCall");
      socket?.off("onAcceptCall");
    };
  }, [socket, messengersList, conversations]);

  ////// get messengers list \\\\\\\\
  const getMessengerList = async () => {
    setIsMessengerListLoading(true);
    try {
      const response = await MessageServices.getMessengersList();
      const updateMessengerListArr = addUnReadMessageCount(
        response,
        unReadConversation
      );
      setMessengersList(updateMessengerListArr);
    } catch (error) {
      console.error(error);
    } finally {
      setIsMessengerListLoading(false);
    }
  };

  useEffect(() => {
    getMessengerList();
  }, []);
  ////// get messengers list \\\\\\\\

  ////// selected messenger conversation \\\\\\\\
  const getMessengerState = (messenger: ChatProps) => {
    const firstName = isNotNullOrUndefined(messenger.recipient)
      ? messenger.recipient.firstName
      : "--";
    const lastName = isNotNullOrUndefined(messenger.recipient)
      ? messenger.recipient.lastName
      : "--";
    const email = isNotNullOrUndefined(messenger.recipient)
      ? messenger.recipient.email
      : "--";
    const name = `${firstName} ${lastName}`;
    const phoneNumber = `--`;
    const image =
      isNotNullOrUndefined(messenger.recipient) &&
      isNotNullOrUndefined(messenger.recipient.picture)
        ? messenger.recipient.picture
        : null;

    setMessengerState({ name, image, email, phoneNumber });
  };
  const getConversationsById = async (messenger: ChatProps) => {
    const filterUnreadMessage = unReadConversation.filter(
      (unReadMessage) => unReadMessage.conversation.id !== messenger.id
    );
    setUnReadConversation(filterUnreadMessage);
    localStorage.setItem("unReadMessages", JSON.stringify(filterUnreadMessage));
    const readCountMessageList = messengersList.map((unReadmessage) => {
      if (unReadmessage.id === messenger.id) {
        return {
          ...unReadmessage,
          unReadMessageCount: 0,
        };
      } else {
        return unReadmessage;
      }
    });
    setMessengersList(readCountMessageList);
    const getGroupName = () => {
      const inputString = messenger?.chatName;
      const index = inputString.indexOf("?");

      if (index !== -1) {
        const result = inputString.split("?");
        const projectId = result[1].split("=");
        setProjectId(projectId[1]);
        return result[0];
      } else {
        setProjectId("");
        return inputString;
      }
    };
    const groupName = getGroupName();
    setMessenger({ ...messenger, chatName: groupName });
    getMessengerState(messenger);
    // await MessageServices.createConversation({ userId: id });
    setIsConversationsLoading(true);
    try {
      const response = await MessageServices.getConversationsByMessengerId(
        messenger.id
      );
      if (response) {
        const conversation: Messages[] = response.messages;
        const readConversationList = conversation.map((conversation) => ({
          ...conversation,
          isRead: true,
        }));
        const sortedData = readConversationList.sort(
          (a, b) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
        setConversations(sortedData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsConversationsLoading(false);
    }
  };
  ////// selected messenger conversation \\\\\\\\

  ///////////// Create one to one Chat \\\\\\\\\\\\

  const createOnetoOneChat = async (request: CreateConversationRequest) => {
    setCreateChatLoading(true);
    try {
      const response = await MessageServices.createConversation(request);
      getMessengerList();
      getConversationsById(response);
    } catch (error) {
      console.error(error);
    } finally {
      setCreateChatLoading(false);
    }
  };

  ///////////// Create one to one Chat \\\\\\\\\\\\
  // Function to convert Blob URL to image
  const loadImage = async (blobUrl) => {
    try {
      const response = await fetch(blobUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      return objectUrl;
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  // Use the function with your Blob URL

  ///////////// send message \\\\\\\\\\\\
  const handleSendMessage = async (
    e,
    content: string,
    conversation: ChatProps
  ) => {
    e.preventDefault();
    try {
      onSendMessageNotification.pause();
      onSendMessageNotification.currentTime = 0;
      const payload = {
        content,
        conversationId: conversation.id,
      };
      const response = await MessageServices.sendMessage(payload);

      if (response) {
        updateMessengerList(conversation.id, response, unReadConversation);
        // const picture = await loadImage(conversation?.recipient?.picture);
        const updatedResponse = conversation?.isGroupChat
          ? response
          : ({
              ...response,
              sender: {
                ...response?.sender,
                picture: conversation?.recipient?.picture,
              } as GroupUsers,
            } as SendMessageReaponse);
        // console.log("updatedResponse", updatedResponse, conversation);
        const preConversation = [...conversations, updatedResponse];
        setConversations(
          preConversation.map((conversation) => ({
            ...conversation,
            isRead: true,
          }))
        );

        const createMessageSocketPayload = {
          message: content,
          recipientId: conversation?.isGroupChat
            ? conversation?.id
            : conversation.recipient.id,
          conversationDto: updatedResponse,
        };
        if (conversation?.isGroupChat) {
          socket.emit("onConversationJoin", {
            conversationId: conversation?.id,
          });
        }
        socket.emit("createMessage", createMessageSocketPayload);
      }
      onSendMessageNotification.play();
    } catch (error) {
      console.log(error);
    }
  };
  ///////////// send message \\\\\\\\\\\\

  /////////////// create and update group \\\\\\\\\\\\\
  const createOrUpdateGroup = async (
    request: Partial<CreateGroupRequest>,
    state: string
  ) => {
    setCreateGroupLoading(true);

    try {
      if (state === CreateUpdateEnum.Create) {
        const createGroupResponse = await MessageServices.createGroup(request);
        getConversationsById(createGroupResponse);
      } else {
        const updateGroupResponse = await MessageServices.updateGroup(request);
        getConversationsById(updateGroupResponse);
      }
      getMessengerList();
    } catch (error) {
      console.error(error);
    } finally {
      setCreateGroupLoading(false);
    }
  };
  /////////////// create and update group \\\\\\\\\\\\\

  /////////////// accept and reject call \\\\\\\\\\\\\
  const onAcceptCall = async () => {
    if (storeCallInfo) {
      socket.emit("onAcceptCall", {
        acceptBy: storeCallInfo.acceptBy,
      });
    }
    setAcceptCall();
  };

  const onOutgoingCallEnd = () => {
    storeCallInfo && socket.emit("endCallingTo", storeCallInfo);
    setCallIsDisconnected();
    setOpenCallingModal(false);
  };
  /////////////// accept and reject call \\\\\\\\\\\\\

  const handleShowContractorDetails = (value: boolean) => {
    setShowContractorDetails(value);
  };
  const handleShowProjectDetails = (value: boolean) => {
    setShowProjectDetails(value);
  };

  const handleOpenCreateGroupModal = (value: boolean) => {
    setOpenCreateGroupModal(value);
  };
  const handleOpenAddMembersModal = (value: boolean) => {
    setOpenAddMembersModal(value);
  };
  const handleShowNewMessage = (value: boolean) => {
    setShowNewMessage(value);
  };

  return (
    <MessagesModule.Provider
      value={{
        socket,
        messengersList,
        messenger,
        unReadMessageId,
        messengerState,
        conversations,
        unReadConversation,
        selectedMessageType,
        isConversationsLoading,
        isMessengerListLoading,
        showContractorDetails,
        showProjectDetails,
        showNewMessage,
        openCreateGroupModal,
        openAddMembersModal,
        typing,
        createGroupLoading,
        createChatLoading,
        projectId,
        storeTyping,
        setTyping,
        onStartTyping,
        onStopTyping,
        onAcceptCall,
        onOutgoingCallEnd,
        setShowContractorDetails,
        setIsMessengerListLoading,
        setSelectedMessageType,
        getConversationsById,
        handleShowContractorDetails,
        handleShowProjectDetails,
        handleOpenCreateGroupModal,
        handleOpenAddMembersModal,
        handleShowNewMessage,
        handleSendMessage,
        createOrUpdateGroup,
        createOnetoOneChat,
      }}
    >
      {children}
    </MessagesModule.Provider>
  );
};
