import axios from "axios";
import { PaginatedResponse, TitleIdOptionProps } from "../types/commonType";
import {
  CompanyInfoByIdResponse,
  CompanyInfoParams,
  CompanyInfoResponse,
  UpdateCompanyInfoRequest,
} from "../types/companyTypes";
import { RolesForProjectParams } from "../types/projectTypes";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  COMPANY_INFO: "company-info?_format=json",
  COMPANY_INFO_BY_COMPANY_ID: "company-details/get?_format=json",
  UPLOAD_COMPANY_LOGO:
    "file/upload/node/company/field_ats_company_logo?_format=json",
  UPDATE_COMPANY_INFO: "company/update",
  COMPANY_SIZE: "company-size",
};

const COMPANY_INFO_URL = Endpoint.BASE + Endpoint.COMPANY_INFO;
const COMPANY_INFO_BY_COMPANY_ID_URL =
  Endpoint.BASE + Endpoint.COMPANY_INFO_BY_COMPANY_ID;
const UPDATE_COMPANY_INFO_URL = Endpoint.BASE + Endpoint.UPDATE_COMPANY_INFO;
const COMPANY_SIZE_URL = Endpoint.BASE + Endpoint.COMPANY_SIZE;

export class CompanyServices {
  static async getCompanyInfo(
    params: Partial<CompanyInfoParams>
  ): Promise<CompanyInfoResponse> {
    const response = await axios.get(COMPANY_INFO_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCompanySize(): Promise<
    PaginatedResponse<TitleIdOptionProps>
  > {
    const response = await axios.get(COMPANY_SIZE_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCompanyInfoById(params: {
    nid: string;
  }): Promise<PaginatedResponse<CompanyInfoByIdResponse>> {
    const response = await axios.get(COMPANY_INFO_BY_COMPANY_ID_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateCompanyInfo(
    request: Partial<UpdateCompanyInfoRequest>
  ): Promise<CompanyInfoResponse> {
    const response = await axios.patch(UPDATE_COMPANY_INFO_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async uploadLogoForCompany(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<any> {
    const response = await axios.post(Endpoint.UPLOAD_COMPANY_LOGO, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }
}
