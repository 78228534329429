import React, { useEffect, useState } from "react";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import {
  getProjectsState,
  setActiveProjectStep,
  setActiveProjectStep2,
  setActiveProjectStep3,
  setIsEditProjectStep,
  setSelectedProjectDetailsConfirm,
} from "../../../../redux/slices/projectsSlice";
import { formattedAmount } from "../../../../utils/common";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { Box, Stack } from "@mui/material";
import { BORDERS, BORDERS_2X, COLORS } from "../../../../theme";
import AtsTitleText from "../../../../components/AtsTitleText";
import {
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from "@mui/icons-material";

const ReviewProjectDetails = () => {
  const dispatch = useAppDispatch();
  const { selectedProjectDetailsConfirm, createProjectState } =
    useAppSelector(getProjectsState);

  const reviewProjectList = [
    {
      title: createProjectState.title,
      subTitle: "Confirm your project name.",
      route: () => {
        dispatch(setActiveProjectStep(1));
        dispatch(setActiveProjectStep2(0));
        dispatch(setActiveProjectStep3(0));
        dispatch(setIsEditProjectStep(true));
      },
      key: "title",
    },

    {
      title: `${createProjectState.location.address_line1} ${createProjectState.location.locality} ${createProjectState.location.country}`,
      subTitle: `Confirm project location.`,
      route: () => {
        dispatch(setActiveProjectStep(3));
        dispatch(setActiveProjectStep2(1));
        dispatch(setActiveProjectStep3(0));
        dispatch(setIsEditProjectStep(true));
      },
      key: "location",
    },
    {
      title: `$${formattedAmount(
        createProjectState.budget_low
      )}-$${formattedAmount(createProjectState.budget_high)}`,
      subTitle: `Confirm the budget of the project.`,
      route: () => {
        dispatch(setActiveProjectStep(3));
        dispatch(setActiveProjectStep2(2));
        dispatch(setActiveProjectStep3(0));
        dispatch(setIsEditProjectStep(true));
      },
      key: "salary",
    },
  ];

  const handleChange = (value: string) => {
    if (selectedProjectDetailsConfirm?.includes(value)) {
      const updatedCheck = selectedProjectDetailsConfirm.filter(
        (day) => day !== value
      );
      dispatch(setSelectedProjectDetailsConfirm(updatedCheck));
    } else {
      const updatedCheck = [...selectedProjectDetailsConfirm, value];
      dispatch(setSelectedProjectDetailsConfirm(updatedCheck));
    }
  };

  useEffect(() => {
    dispatch(setIsEditProjectStep(false));
  }, [dispatch]);

  return (
    <JobCardWrapper
      header="Review project details"
      subText={`Confirm the important details of your listing. Then publish and prepare.`}
    >
      <Stack mb={1} pr={1} gap={2}>
        {reviewProjectList.map((item, index) => (
          <Stack
            key={index}
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            sx={{
              border:
                selectedProjectDetailsConfirm.length &&
                selectedProjectDetailsConfirm?.includes(item.key)
                  ? BORDERS_2X.GREEN
                  : BORDERS.GRAY,
              borderRadius: 2,
              pr: 1,
              pl: 2,
              py: 3,
              cursor: "pointer",
            }}
            onClick={() => handleChange(item.key)}
          >
            <Stack gap={0.5}>
              <AtsTitleText
                text={item.title}
                fs={16}
                fw={600}
                textColor={
                  selectedProjectDetailsConfirm.length &&
                  selectedProjectDetailsConfirm?.includes(item.key)
                    ? COLORS.GREEN
                    : COLORS.BLACK
                }
              />
              <AtsTitleText
                text={item.subTitle}
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
              />
              <Stack width={"fit-content"}>
                <AtsTitleText
                  text={`Edit`}
                  isCursor
                  isUnderline
                  fs={12}
                  fw={600}
                  textColor={COLORS.BLACK}
                  onClick={item.route}
                />
              </Stack>
            </Stack>
            <Box>
              {selectedProjectDetailsConfirm.length &&
              selectedProjectDetailsConfirm?.includes(item.key) ? (
                <CheckCircleRounded
                  sx={{ color: COLORS.GREEN, fontSize: 30 }}
                />
              ) : (
                <CheckCircleOutlineRounded
                  sx={{ color: COLORS.LIGHT_GRAY, fontSize: 30 }}
                />
              )}
            </Box>
          </Stack>
        ))}
      </Stack>
    </JobCardWrapper>
  );
};

export default ReviewProjectDetails;
