import { Box, Stack } from "@mui/material";
import React from "react";
import { useMessages } from "../../../../../providers/MessagesProvider";
import AtsIcon from "../../../../../components/AtsIcon";
import { BG_COLORS, COLORS } from "../../../../../theme";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { Divider } from "@mui/material";
import StarIconRating from "../../../../../assets/images/startIcon.svg";
import {
  getDateFormatMMMDYYYYY,
  getDateFormatMMMMDYYYYY,
} from "../../../../../utils/dateUtils";
import { StarIcon } from "../../../../Jobs/assets/Icons";

const ContractorInfo = () => {
  const { messengerState } = useMessages();
  return (
    <Stack gap={"18px"}>
      <Box
        width={230}
        height={230}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          bgcolor: BG_COLORS.GRAY,
        }}
      >
        {messengerState?.image ? (
          <img
            src={messengerState.image}
            alt="images"
            width={"100%"}
            height={"100%"}
            style={{
              borderRadius: "10px",
            }}
          />
        ) : (
          <AtsIcon icon="icon-profile-2user" height="32px" fs={32} />
        )}
      </Box>
      <Stack gap={"8px"}>
        <AtsTitleText text={messengerState?.name} fs={16} fw={600} noTextWrap />
        <AtsTitleText
          text={"Sr. Java developer"}
          fs={14}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
        />
        <AtsTitleText
          text={"Java Team"}
          fs={12}
          fw={400}
          textColor={"#000"}
          noTextWrap
        />
        <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text={"4.8"}
              noTextWrap
            />
            <StarIcon />
          </Stack>
          <Divider variant="fullWidth" orientation="vertical" flexItem />
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Onboarded:"
              noTextWrap
            />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text={getDateFormatMMMDYYYYY(new Date().toString())}
              noTextWrap
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ContractorInfo;
