import { Box, Skeleton, Stack } from "@mui/material";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import {
  getProjectDetailsState,
  setCurrentIndex,
  setSelectedProjectDetailsTab,
} from "../../../../../../redux/slices/projectDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BG_COLORS, COLORS } from "../../../../../../theme";
import { ProjectDocumentResponse } from "../../../../../../types/projectTypes";

interface IProps {
  loading: boolean;
  projectTab: ProjectDocumentResponse[];
}

const ProjectDetailsTabs = ({ loading, projectTab }: IProps) => {
  const dispatch = useAppDispatch();
  const { selectedProjectDetailsTab } = useAppSelector(getProjectDetailsState);
  return (
    <>
      <Box
        sx={{
          bgcolor: BG_COLORS.PRIMARY,
          borderRadius: "10px",
          padding: "40px 24px 40px 30px",
          minWidth: 236,
          maxHeight: 350,
        }}
      >
        <AtsTitleText text={"Project Details"} fs={30} fw={300} />
        <Stack
          className="scrollHide"
          gap={"40px"}
          mt={"30px"}
          mb={"20px"}
          sx={{
            height: "calc(100% - 35px)",
            overflow: "auto",
          }}
        >
          {loading
            ? Array.from(new Array(5)).map((_, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Skeleton variant="rounded" height={21} width={200} />
                </Stack>
              ))
            : projectTab.map((tab, index) => (
                <Stack
                  key={tab.id}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setCurrentIndex(index));
                    dispatch(setSelectedProjectDetailsTab(tab));
                  }}
                >
                  <AtsTitleText
                    text={tab.name}
                    fs={18}
                    fw={500}
                    textColor={
                      selectedProjectDetailsTab.id === tab.id
                        ? COLORS.PRIMARY
                        : "#93b1e6"
                    }
                  />
                  {selectedProjectDetailsTab.id === tab.id && (
                    <AtsIcon
                      icon="icon-arrow-right-3"
                      height="16px"
                      fw={600}
                      color={COLORS.PRIMARY}
                    />
                  )}
                </Stack>
              ))}
        </Stack>
      </Box>
    </>
  );
};

export default ProjectDetailsTabs;
