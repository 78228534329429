import { Box, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useInterviews } from "../../../../../../../../../providers/InterviewsProvider";
import { COLORS } from "../../../../../../../../../theme";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import SkillsComponent from "./SkillsComponent";
import AtsRow from "../../../../../../../../../components/AtsRow";
import { ScoreCardTypeResponse } from "../../../../../../../../../types/interviewTypes";
import { NumberFilterModel } from "ag-grid-community";
import { InterviewStatusEnum } from "../../../../../../../../../enums/commonEnums";

interface SkillsCommentsProps {
  education: string;
  priorWorkExp: string;
  technicalExp: string;
  verbalCommunication: string;
  candidateEnthusiasm: string;
  companyKnowledge: string;
}

const Scorecard = () => {
  const {
    interviewInfo,
    getScorecardOfInterview,
    scroreCardOfInterviewDetails,
    scoreCardOfInterviewLoading,
    getScrorecardType,
    scoreCardType,
    setScoreCardType,
  } = useInterviews();

  const [comments, setComments] = useState<SkillsCommentsProps>({
    education: "",
    priorWorkExp: "",
    technicalExp: "",
    verbalCommunication: "",
    candidateEnthusiasm: "",
    companyKnowledge: "",
  });

  useEffect(() => {
    if (
      interviewInfo &&
      interviewInfo.status === InterviewStatusEnum.Completed
    ) {
      getScorecardOfInterview(interviewInfo.nid, interviewInfo.candidate_id);
    }
  }, []);

  const scorecardData = isNotNullOrUndefined(scroreCardOfInterviewDetails)
    ? scroreCardOfInterviewDetails.scorecard
    : [];

  useEffect(() => {
    if (
      interviewInfo.status === InterviewStatusEnum.Completed &&
      !isNotNullOrUndefined(scroreCardOfInterviewDetails)
    ) {
      getScrorecardType();
    }
  }, [scroreCardOfInterviewDetails]);

  const handleChage = (
    key: keyof ScoreCardTypeResponse,
    value: string | number,
    index: number
  ) => {
    const preScrorecardType = [...scoreCardType];
    const getRattingObj = { ...scoreCardType[index] };
    if (key === "rating") {
      if (getRattingObj.rating === value) {
        const updateRattingObj = { ...getRattingObj, [key]: 0 };
        preScrorecardType.splice(index, 1, updateRattingObj);
        setScoreCardType(preScrorecardType);
      } else {
        const updateRattingObj = { ...getRattingObj, [key]: value as number };
        preScrorecardType.splice(index, 1, updateRattingObj);
        setScoreCardType(preScrorecardType);
      }
    } else {
      const updateRattingObj = { ...getRattingObj, [key]: value };
      preScrorecardType.splice(index, 1, updateRattingObj);
      setScoreCardType(preScrorecardType);
    }
  };

  return (
    <Box
      sx={{
        my: 3,
        height: 400,
        width: "100%",
        pr: 2,
        overflow: "scroll",
      }}
    >
      {interviewInfo.status === InterviewStatusEnum.Completed ? (
        <Stack gap={2}>
          <AtsTitleText
            text={"How does the scorecard work ?"}
            fs={12}
            fw={600}
            textColor={COLORS.PRIMARY}
          />
          <Stack gap={2}>
            {scoreCardOfInterviewLoading ? (
              <Stack sx={{ mt: 3 }}>
                <Stack gap={1}>
                  <Skeleton variant="text" width={50} height={25} />
                  <Skeleton variant="text" width={50} height={25} />
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <Stack direction={"row"} gap={0.5}>
                      {Array.from(new Array(5)).map((_, index) => (
                        <Skeleton variant="rounded" width={35} height={35} />
                      ))}
                    </Stack>
                    <Stack direction={"row"} gap={0.5}>
                      <AtsTitleText
                        text={"Your Rating:"}
                        fs={12}
                        fw={400}
                        textColor={"#000"}
                      />
                      <Skeleton variant="text" width={50} height={25} />
                    </Stack>
                  </Stack>
                  <Stack sx={{ mt: 1 }}>
                    <Skeleton variant="rounded" width={"100%"} height={55} />
                  </Stack>
                </Stack>
              </Stack>
            ) : scorecardData.length > 0 ? (
              scorecardData.map((data, index) => (
                <Stack key={data.type_id} sx={{ mt: 3 }}>
                  <SkillsComponent
                    title={data.type_name}
                    des="Final comments and recommendations for proceeding with the candidate."
                    rating={Number(data.rating)}
                    comments={data.comments}
                    onChange={(comment) =>
                      setComments({ ...comments, technicalExp: comment })
                    }
                  />
                </Stack>
              ))
            ) : (
              scoreCardType.map((type, index) => (
                <Stack key={type.id} sx={{ mt: 3 }}>
                  <SkillsComponent
                    title={type.title}
                    des={type.description}
                    rating={type.rating}
                    comments={type.comments}
                    onChange={(comment) =>
                      handleChage("comments", comment, index)
                    }
                    onSelecteRate={(rate) => handleChage("rating", rate, index)}
                  />
                </Stack>
              ))
            )}
          </Stack>
        </Stack>
      ) : (
        <AtsRow justifyContent={"center"} height={"100%"}>
          <>
            <AtsTitleText text={"Interview not completed"} fs={18} fw={600} />
          </>
        </AtsRow>
      )}
    </Box>
  );
};

export default Scorecard;
