import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import AtsPageContainer from "../../components/AtsPageContainer";
import AtsTitleText from "../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import { AnimatePresence } from "framer-motion";
import AtsAnimation from "../../components/AtsAnimation";
import { useNotifications } from "../../providers/NotificationsProvider";
import AtsRowBetween from "../../components/AtsRowBetween";
import AtsRoundedButton from "../../components/AtsRoundedButton";
import userImg from "../../assets/images/candidate.png";
import AtsRow from "../../components/AtsRow";
import { DocumentText, InfoCircle, Star, Star1 } from "iconsax-react";
import { Close } from "@mui/icons-material";

const notificationsTab = [
  {
    title: "All Notifications",
    type: "ALL",
  },
  {
    title: "Unread",
    type: "UNREAD",
  },
];
// #364359

const notificationsData = [
  {
    type: "job",
    text: "Chief Technology Officer",
    date: "June 1, 2024",
    id: 1,
  },
  {
    type: "invoice_overdue",
    text: "#00800002",
    date: "June 1, 2024",
    id: 2,
  },
  {
    type: "contract",
    text: "Trevor Cunningham",
    date: "June 1, 2024",
    id: 3,
  },
  {
    type: "invoice_due",
    text: "#00800002",
    date: "June 1, 2024",
    id: 3,
  },
];

const Notifications = () => {
  const { selectedNotificationTab, setSelectedNotificationTab } =
    useNotifications();
  return (
    <AtsPageContainer>
      <Box
        sx={{
          height: "100%",
        }}
      >
        <AtsTitleText
          text="Notifications"
          fs={24}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Box
          sx={{
            height: "calc(100% - 95px)",
            bgcolor: "white",
            border: BORDERS.GRAY,
            borderRadius: "10px",
            mt: "22px",
            p: "30px",
          }}
        >
          <div
            style={{
              maxWidth: 727,
              marginTop: "25px",
              marginRight: "auto",
              marginLeft: "auto",
              height: "96%",
            }}
          >
            <Stack gap={"30px"} height={"100%"}>
              <AtsRowBetween>
                <>
                  <Stack
                    sx={{
                      border: "2px solid #dcdfe7",
                      borderRadius: "20px",
                    }}
                  >
                    <AnimatePresence>
                      <Stack
                        sx={{
                          bgcolor: "#dcdfe7",
                          borderRadius: "20px",
                        }}
                        direction={"row"}
                      >
                        {notificationsTab.map((tab, index) => (
                          <AtsAnimation key={index}>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                bgcolor:
                                  selectedNotificationTab === tab.type
                                    ? "white"
                                    : "#dcdfe7",
                                borderRadius: "20px",
                                width: "90px",
                                height: "26px",
                                cursor: "pointer",
                                px: "20px",
                              }}
                              onClick={() =>
                                setSelectedNotificationTab(tab.type)
                              }
                            >
                              <AtsTitleText
                                noTextWrap
                                fs={12}
                                fw={600}
                                textColor={
                                  selectedNotificationTab === tab.type
                                    ? COLORS.PRIMARY
                                    : COLORS.DARK_GRAY
                                }
                                text={tab.title}
                              />
                            </Stack>
                          </AtsAnimation>
                        ))}
                      </Stack>
                    </AnimatePresence>
                  </Stack>
                  <Box
                    sx={{
                      px: "16px",
                      py: "9px",
                      bgcolor: BG_COLORS.GRAY,
                      borderRadius: "44px",
                      cursor: "pointer",
                    }}
                  >
                    <AtsTitleText
                      text="Mark all as read"
                      fs={12}
                      fw={500}
                      textColor={"#1a1918"}
                    />
                  </Box>
                </>
              </AtsRowBetween>
              <Box
                className="scrollHide"
                sx={{
                  height: "calc(100% - 55px)",
                  overflow: "auto",
                }}
              >
                {notificationsData.map((notification, index) => {
                  const backgroundColor =
                    notification.type === "invoice_due"
                      ? "#3c854d"
                      : notification.type === "invoice_overdue"
                      ? "#af1f1f"
                      : "#364359";

                  const Icon = () => {
                    if (notification.type === "contract") {
                      return (
                        <img
                          src={userImg}
                          alt="cc"
                          width="100%"
                          style={{ borderRadius: "50%" }}
                        />
                      );
                    } else if (notification.type === "invoice_due") {
                      return <DocumentText color="white" size={24} />;
                    } else if (notification.type === "invoice_overdue") {
                      return <InfoCircle color="white" size={24} />;
                    } else {
                      return <Star1 color="#ffcd34" size={24} variant="Bold" />;
                    }
                  };

                  const message = () => {
                    const typeText =
                      notification.type === "invoice_due"
                        ? "Invoice Due"
                        : notification.type === "invoice_overdue"
                        ? "Invoice Overdue"
                        : notification.type === "contract"
                        ? "Contract Started"
                        : "Your Job";
                    return `${typeText} for `;
                  };
                  return (
                    <Box
                      sx={{
                        px: "10px",
                        py: "20px",
                        ":hover": {
                          bgcolor: BG_COLORS.PRIMARY,
                        },
                      }}
                    >
                      <AtsRow key={index}>
                        <>
                          <Box
                            sx={{
                              width: 8,
                              minWidth: 8,
                              height: 8,
                              minHeight: 8,
                              borderRadius: "50%",
                              bgcolor: COLORS.PRIMARY,
                              mr: "15px",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              width: { xs: 32, sm: 48 },
                              minWidth: { xs: 32, sm: 48 },
                              height: { xs: 32, sm: 48 },
                              minHeight: { xs: 32, sm: 48 },
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: backgroundColor,
                              mr: "12px",
                            }}
                          >
                            <Icon />
                          </Box>
                          <Box
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <Stack gap={"4px"}>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: COLORS.BLACK,
                                  fontWeight: 400,
                                }}
                              >
                                {message()}
                                <span style={{ fontWeight: 600 }}>
                                  {notification.text}
                                </span>
                                {notification.type === "job" &&
                                  " is successfully created"}
                              </Typography>
                              <AtsTitleText
                                text={notification.date}
                                fs={12}
                                fw={400}
                                textColor={COLORS.LIGHT_GRAY}
                              />
                            </Stack>
                          </Box>
                          <AtsRow>
                            <>
                              <AtsRoundedButton
                                fs={12}
                                fw={600}
                                isInitial
                                text={
                                  notification.type === "job" ||
                                  notification.type === "contract"
                                    ? "View"
                                    : "Pay now"
                                }
                                buttonVariant={
                                  notification.type === "job" ||
                                  notification.type === "contract"
                                    ? "outline"
                                    : "darkGreenContained"
                                }
                                buttonHeight={26}
                                buttonWidth={104}
                              />
                              <IconButton>
                                <Close />
                              </IconButton>
                            </>
                          </AtsRow>
                        </>
                      </AtsRow>
                    </Box>
                  );
                })}
              </Box>
            </Stack>
          </div>
        </Box>
      </Box>
    </AtsPageContainer>
  );
};

export default Notifications;
