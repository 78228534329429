import { useMediaQuery } from "@mui/material";
import { Box, Container, Stack, useTheme } from "@mui/system";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";

interface Props {
  title: string;
  subText: string;
  step: string;
}

const StepCard = ({ title, subText, step }: Props) => {
  const theme = useTheme();
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  return (
    <>
      <Container maxWidth={isLargeXl ? "sm" : "xs"}>
        <Stack gap={1}>
          <Box mt={8} mb={2}>
            <img
              width={"100%"}
              src={require("../../../assets/images/opportunity.png")}
              alt="oppo"
            />
          </Box>
        </Stack>
        <Stack gap={1}>
          <AtsTitleText
            text={`Step ${step}`}
            fs={16}
            fw={600}
            textColor={COLORS.LIGHT_GRAY}
          />
          <AtsTitleText
            text={title}
            fs={22}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <AtsTitleText
            text={subText}
            fs={14}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
      </Container>
    </>
  );
};

export default StepCard;
