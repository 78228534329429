import { Close } from "@mui/icons-material";
import { Box, Collapse, IconButton, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProjectService } from "../../../../../Api/projectServices";
import AtsRow from "../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../theme";
import { TeamMemberProps } from "../../../../../types/projectTypes";
import { useClickOutSide } from "../../../../../utils/common";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";

interface IProps {
  onSelect?: (selected: string[]) => void;
  selected: string[];
  selectedUsers?: TeamMemberProps[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<TeamMemberProps[]>>;
}

const MemberSelectorForNewMessage = ({
  onSelect,
  selected,
  setSelectedUsers,
  selectedUsers,
}: IProps) => {
  const [users, setUsers] = useState<TeamMemberProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const debouncedSearch = useDebounce(searchValue);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const fetchMoreData = () => {
    if (totalItem !== users.length) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelect = (selectedId: string) => {
    let updatedSelected = [...selected];
    if (updatedSelected.includes(selectedId)) {
      updatedSelected = updatedSelected.filter((id) => id !== selectedId);
    } else {
      updatedSelected.push(selectedId);
    }
    const findUser = users.find((user) => user.uid === selectedId);
    setSelectedUsers([...selectedUsers, findUser]);
    setSearchValue("");
    if (onSelect) {
      onSelect(updatedSelected);
    }
  };

  const handleDelete = (selectedId: string) => {
    const updatedSelected = selected.filter((id) => id !== selectedId);
    if (onSelect) {
      onSelect(updatedSelected);
    }
  };

  const getProjectMembers = async () => {
    setIsLoading(true);
    try {
      const response = await ProjectService.getProjectMember({ page });
      if (response && response.results?.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setUsers([...response.results]);
        } else {
          setUsers([...users, ...response.results]);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProjectMembers();
  }, [page, debouncedSearch]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (users.length === 0) return;

      switch (event.key) {
        case "ArrowUp":
          setFocusedIndex((prevIndex) =>
            prevIndex === null || prevIndex === 0
              ? users.length - 1
              : prevIndex - 1
          );
          break;
        case "ArrowDown":
          setFocusedIndex((prevIndex) =>
            prevIndex === null || prevIndex === users.length - 1
              ? 0
              : prevIndex + 1
          );
          break;
        case "Enter":
          if (focusedIndex !== null || users.length) {
            handleSelect(users[focusedIndex].uid);
            setShow(false);
          }
          break;
        default:
          break;
      }
    };

    if (show) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedIndex, show]);

  return (
    <>
      <div ref={domNode} style={{ flex: 1 }}>
        <AtsRowBetween>
          <>
            <AtsRow
              sx={{
                flex: 1,
              }}
              columnGap={"5px"}
              rowGap={"3px"}
              flexWrap={"wrap"}
            >
              <>
                {selected.map((id) => {
                  const selectedUser = selectedUsers?.find(
                    (user) => user.uid === id
                  );
                  return (
                    selectedUser && (
                      <AtsRow
                        key={id}
                        gap={"5px"}
                        sx={{
                          bgcolor: BG_COLORS.PRIMARY,
                          py: "2px",
                          pl: "8px",
                          borderRadius: "5px",
                        }}
                      >
                        <>
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              borderRadius: "50%",
                              border: BORDERS.GRAY,
                            }}
                          >
                            <img
                              width={"100%"}
                              height={"100%"}
                              style={{ borderRadius: "50%" }}
                              src={
                                isNotNullOrUndefined(selectedUser.image)
                                  ? selectedUser.image
                                  : require("../../../../../assets/images/projectImg.png")
                              }
                              alt=""
                            />
                          </Box>
                          <AtsTitleText
                            text={`${selectedUser?.first_name ?? ""} ${
                              selectedUser?.last_name ?? ""
                            }`}
                            fs={12}
                            fw={500}
                            textColor={COLORS.PRIMARY}
                          />
                          <IconButton
                            sx={{ p: "3px" }}
                            onClick={() => handleDelete(id)}
                          >
                            <Close
                              sx={{ fontSize: 14, color: COLORS.PRIMARY }}
                            />
                          </IconButton>
                        </>
                      </AtsRow>
                    )
                  );
                })}
                <input
                  className="customInput"
                  placeholder={"Search user name"}
                  value={searchValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setShow(true);
                    } else {
                      setShow(false);
                    }
                    setSearchValue(value);
                  }}
                  style={{ flex: 1, height: "25px" }}
                />
              </>
            </AtsRow>
          </>
        </AtsRowBetween>
      </div>
      <Box
        sx={{
          position: "absolute",
          top: 44,
          left: 25,
          right: 25,
          bgcolor: "white",
        }}
      >
        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: "6px",
            border: BORDERS.GRAY,
          }}
          in={show}
        >
          <Stack
            id="projectDiv"
            sx={{ maxHeight: 200, px: "10px", my: "10px", overflow: "auto" }}
          >
            <InfiniteScroll
              dataLength={users.length}
              next={fetchMoreData}
              hasMore={users.length < totalItem}
              loader={<h4>Loading...</h4>}
              scrollableTarget="projectDiv"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Stack gap={"5px"}>
                {users.length ? (
                  users.map((option: TeamMemberProps, index) => (
                    <MenuItem
                      key={index}
                      value={option.uid}
                      onClick={() => handleSelect(option.uid)}
                      sx={{
                        borderRadius: "6px",
                        bgcolor: selected.includes(option.uid)
                          ? BG_COLORS.PRIMARY
                          : focusedIndex === index
                          ? "rgba(0, 0, 0, 0.1)"
                          : BG_COLORS.GRAY,
                      }}
                    >
                      <AtsRowBetween>
                        <>
                          <AtsRow>
                            <>
                              <Box
                                sx={{
                                  width: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  border: BORDERS.GRAY,
                                }}
                              >
                                <img
                                  width={"100%"}
                                  height={"100%"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    isNotNullOrUndefined(option.image)
                                      ? option.image
                                      : require("../../../../../assets/images/projectImg.png")
                                  }
                                  alt=""
                                />
                              </Box>
                              <AtsTitleText
                                text={`${option?.first_name ?? ""} ${
                                  option?.last_name ?? ""
                                }`}
                                fs={12}
                                fw={500}
                                textColor={COLORS.PRIMARY}
                              />
                            </>
                          </AtsRow>
                        </>
                      </AtsRowBetween>
                    </MenuItem>
                  ))
                ) : (
                  <Box>No users found</Box>
                )}
              </Stack>
            </InfiniteScroll>
          </Stack>
        </Collapse>
      </Box>
    </>
  );
};

export default MemberSelectorForNewMessage;
