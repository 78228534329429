import axios from "axios";
import {
  GetRemindersParams,
  RemindersResponse,
  UpdateRemindersRequest,
} from "../types/reminderTypes";
import { SubmissionPrioritiesResponse } from "../types/topPrioritiesTypes";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  REMINDER_GET: "company-reminders/get",
  UPDATE_REMINDER: "company-reminders/update",
  DELETE_REMINDER: "company-reminders/delete",
};

const REMINDER_GET_URL = Endpoint.BASE + Endpoint.REMINDER_GET;
const UPDATE_REMINDER_URL = Endpoint.BASE + Endpoint.UPDATE_REMINDER;
const DELETE_REMINDER_URL = Endpoint.BASE + Endpoint.DELETE_REMINDER;

export class ReminderServices {
  static async getReminders(
    params: GetRemindersParams
  ): Promise<RemindersResponse[]> {
    const response = await axios.get(REMINDER_GET_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateReminders(
    request: Partial<UpdateRemindersRequest>
  ): Promise<SubmissionPrioritiesResponse[]> {
    const response = await axios.patch(UPDATE_REMINDER_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async deleteReminders(params: {
    nid: string;
  }): Promise<SubmissionPrioritiesResponse[]> {
    const response = await axios.delete(DELETE_REMINDER_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
