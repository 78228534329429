import { Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import AtsCard from "../../../components/AtsCard";
import AtsLabel from "../../../components/AtsLabel";
import { BORDERS } from "../../../theme";

const LoadingTaskCard = () => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <AtsCard
        sx={{
          minHeight: 104,
          width: !isLargeXl && isLarge ? 190 : 184,
        }}
      >
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <AtsLabel text={""} loading={true} variant={"warninig"} />
            <Skeleton variant="circular" width={24} height={24} />
          </Stack>
          <Stack
            pt={1}
            pb={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ borderBottom: BORDERS.LIGHT_GRAY }}
          >
            <Skeleton variant="text" width={153} height={18} />
            <Skeleton variant="circular" width={17} height={17} />
          </Stack>
          <Stack gap={0.5} pt={0.5} direction={"row"} alignItems={"center"}>
            <Skeleton variant="text" width={50} height={14} />
            <Skeleton variant="text" width={40} height={14} />
          </Stack>
        </Stack>
      </AtsCard>
    </>
  );
};

export default LoadingTaskCard;
