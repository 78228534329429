import { Box, Container, Stack } from "@mui/material";
import { useEffect } from "react";
import { useCalendarAvailability } from "../../providers/CalendarAvailabilityProvider";
import { BORDERS } from "../../theme";
import { CalendarEventTypes } from "../../types/calendarTypes";
import CalendarFilterSection from "./modules/CalendarFilterSection";
import CalendarView from "./modules/CalendarView";
import CalenderDateFilterSection from "./modules/CalenderDateFilterSection";

const Calendar = () => {
  const {
    eventType,
    date,
    calendarView,
    getInterviewEventData,
    getInvoiceEventData,
    getContractsEventData,
    setContractsEvents,
    setInterviewEvents,
    setInvoiceEvents,
  } = useCalendarAvailability();

  const getEventsApiCalling = () => {
    if (eventType === CalendarEventTypes.Interviews) {
      getInterviewEventData();
      setInvoiceEvents([]);
      setContractsEvents([]);
    } else if (eventType === CalendarEventTypes.Invoices) {
      getInvoiceEventData();
      setInterviewEvents([]);
      setContractsEvents([]);
    } else if (eventType === CalendarEventTypes.Contracts) {
      getContractsEventData();
      setInterviewEvents([]);
      setInvoiceEvents([]);
    } else {
      getInterviewEventData();
      getInvoiceEventData();
      getContractsEventData();
    }
  };

  useEffect(() => {
    getEventsApiCalling();
  }, [eventType, date, calendarView]);

  return (
    <div
      className="main-container"
      style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          mb: 4,
          pr: "14px !important",
        }}
      >
        <Stack sx={{ pr: "10px" }}>
          <CalenderDateFilterSection />
        </Stack>

        <Box
          sx={{
            height: "calc(100% - 95px)",
            bgcolor: "white",
            border: BORDERS.GRAY,
            borderRadius: "10px",
          }}
        >
          <CalendarFilterSection />
          <CalendarView />
        </Box>
      </Container>
    </div>
  );
};

export default Calendar;
