import { Radio, RadioProps, styled } from "@mui/material";
import React from "react";
import { COLORS } from "../theme";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 19,
  height: 19,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 2px #4f4f4f, inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#fff",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: COLORS.PRIMARY,
  boxShadow: `inset 0 0 0 2px ${COLORS.PRIMARY}, inset 0 -1px 0 rgba(16,22,26,.1)`,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 19,
    height: 19,
    backgroundImage: "radial-gradient(#fff,#fff 22%,transparent 26%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: COLORS.PRIMARY,
  },
});

interface IProps extends RadioProps {}

const AtsRadioButton = ({ ...rest }: IProps) => {
  return (
    <>
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        sx={{ p: 0 }}
        {...rest}
      />
    </>
  );
};

export default AtsRadioButton;
