import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../components/AtsTitleText";
import {
  getProjectsState,
  setInitialProjectState,
} from "../../../../redux/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ATS_ROUTES } from "../../../../router/routes";
import { getPercentageFromStartToEnd } from "../../../../utils/dateUtils";
import ProjectCard from "../ProjectModules/ProjectCard";

const ProjectCreated = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { createdProject } = useAppSelector(getProjectsState);

  return (
    <Box
      className="scrollHide"
      sx={{
        maxWidth: "950px",
        mx: "auto",
        // pt: isLargeXl ? "100px" : "40px",
        // height: isLargeXl ? "calc(100% - 115px)" : "calc(100% - 40px)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        px: 1,
        overflow: "auto",
      }}
    >
      <Stack gap={"30px"} px={2}>
        <Stack gap={2} textAlign={"center"}>
          <Box>
            <img
              width={45}
              src={require("../../../../assets/images/check.gif")}
              alt="check"
            />
          </Box>
          <AtsTitleText
            text={"Success! Your project is created."}
            fw={600}
            fs={20}
          />
        </Stack>
        <ProjectCard
          projectId={createdProject?.nid}
          minHeight={275}
          image={createdProject?.project_image ?? ""}
          location={createdProject?.location?.country}
          progress={
            createdProject?.start_date && createdProject?.end_date
              ? getPercentageFromStartToEnd(
                  createdProject?.start_date,
                  createdProject?.end_date
                )
              : 0
          }
          projectName={createdProject?.title}
          pNumber={`${createdProject?.nid}`}
          description={createdProject?.description}
        />

        <Stack
          direction={"row"}
          gap={"20px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <AtsRoundedButton
            text="View details"
            buttonVariant="outline"
            buttonWidth={145}
            onClick={() => {
              navigate(ATS_ROUTES.PROJECTS);
              dispatch(setInitialProjectState());
            }}
          />
          <AtsRoundedButton
            text="Post opportunity"
            buttonWidth={181}
            onClick={() => navigate(ATS_ROUTES.POST_OPPORTUNITY)}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProjectCreated;
