import { Box, Grid, Skeleton, Stack } from "@mui/material";

import AtsCard from "../../components/AtsCard";

interface IJobProps {
  isLoading?: boolean;
}

const JobDetailsLoadingPage = ({ isLoading }: IJobProps) => {
  return (
    <>
      <Stack gap={1} sx={{ pb: "3px", flexGrow: 1 }}>
        <AtsCard isOnlyBorder>
          <Box sx={{ px: 3, py: 2 }}>
            <Stack gap={2}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={3}
                py={0}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                  <Box sx={{ width: "100%" }}>
                    <Skeleton width={100} />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Skeleton width={100} />
                  </Box>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton width={50} />
                  <Skeleton variant="rectangular" width={20} height={20} />
                </Stack>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box sx={{ width: "100%" }}>
                  <Skeleton width={150} />
                </Box>
              </Stack>

              <Stack
                direction={"row"}
                sx={{ width: "100%", minHeight: "36px" }}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} gap={5}>
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                </Stack>
              </Stack>

              <Stack>
                <Skeleton height={80} />
              </Stack>

              <Stack>
                <Skeleton height={80} />
              </Stack>

              <Stack sx={{ pb: 0, pt: 0 }}>
                <Skeleton width={100} />
              </Stack>
              <Stack direction={"row"} flexWrap={"wrap"}>
                <Skeleton width={25} />
                <Skeleton width={25} />
                <Skeleton width={25} />
              </Stack>

              <Stack sx={{ pb: 0, pt: 0 }}>
                <Skeleton width={100} />
              </Stack>
              <Stack>
                <Grid container spacing={3} sx={{ mb: 2 }}>
                  <Grid item xs={4}>
                    <Skeleton width="100%" height={20} />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton width="100%" height={20} />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton width="100%" height={20} />
                  </Grid>
                </Grid>
              </Stack>

              <Box mt={2}>
                <Skeleton width="100%" height={40} />
              </Box>
              <Box my={2}>
                <Skeleton width="100%" height={40} />
              </Box>
            </Stack>
          </Box>
        </AtsCard>
      </Stack>
    </>
  );
};

export default JobDetailsLoadingPage;
