import { Avatar, Badge, Box, Stack } from "@mui/material";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { BG_COLORS, COLORS } from "../../../../../theme";
import { styled } from "@mui/material";
import { ChatProps } from "../../../../../types/messagesTypes";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";
import moment from "moment";
import { useMessages } from "../../../../../providers/MessagesProvider";
import { MultiUserIcon } from "../../../../../assets/icons/Icons";
import AtsRow from "../../../../../components/AtsRow";

interface IMessengerInfoProps {
  messengerInfo: ChatProps;
}

const MessengerInfo = ({ messengerInfo }: IMessengerInfoProps) => {
  const {
    messenger,
    unReadConversation,
    unReadMessageId,
    messengersList,
    getConversationsById,
    handleShowNewMessage,
    handleShowContractorDetails,
    handleShowProjectDetails,
  } = useMessages();

  const online = false;

  const firstName = isNotNullOrUndefined(messengerInfo.recipient)
    ? messengerInfo.recipient.firstName
    : "--";
  const lastName = isNotNullOrUndefined(messengerInfo.recipient)
    ? messengerInfo.recipient.lastName
    : "--";
  const name = `${firstName} ${lastName}`;
  const image =
    isNotNullOrUndefined(messengerInfo.recipient) &&
    isNotNullOrUndefined(messengerInfo.recipient.picture)
      ? messengerInfo.recipient.picture
      : null;

  const lastMessageDate =
    isNotNullOrUndefined(messengerInfo.latestMessage) &&
    isNotNullOrUndefined(messengerInfo.latestMessage.updatedAt)
      ? moment(messengerInfo.latestMessage.updatedAt).format("MMM D")
      : "";
  const lastMessage =
    isNotNullOrUndefined(messengerInfo.latestMessage) &&
    isNotNullOrUndefined(messengerInfo.latestMessage.content)
      ? messengerInfo.latestMessage.content.length > 20
        ? `${messengerInfo.latestMessage.content.substring(0, 20)} ...`
        : messengerInfo.latestMessage.content
      : "--";

  const onSelect = () => {
    handleShowNewMessage(false);
    handleShowContractorDetails(false);
    handleShowProjectDetails(false);
    getConversationsById(messengerInfo);
  };
  const getGroupName = () => {
    console.log("messengerInfo", messengerInfo);
    const inputString = messengerInfo?.chatName;
    const index = inputString?.indexOf("?");

    if (index !== -1) {
      const result = inputString?.split("?");
      return result[0];
    } else {
      return inputString;
    }
  };
  const groupName = getGroupName();
  console.log("messengerInfo.unReadMessageCount", messengerInfo);
  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      gap={"10px"}
      sx={{
        pl: "10px",
        pr: "10px",
        py: "15px",
        bgcolor: messenger?.id === messengerInfo.id ? BG_COLORS.GRAY : "white",
        borderRadius: "6px",
        cursor: "pointer",
      }}
      onClick={onSelect}
    >
      {messengerInfo.isGroupChat ? (
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            bgcolor: "#d9d9d9",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MultiUserIcon />
        </Box>
      ) : image ? (
        <Avatar src={image} />
      ) : (
        <Avatar />
      )}

      <Stack gap={"8px"} flex={1}>
        <AtsTitleText
          text={messengerInfo.isGroupChat ? groupName : name}
          fw={400}
        />
        <AtsTitleText
          text={lastMessage}
          fs={12}
          fw={messengerInfo.unReadMessageCount !== 0 ? 600 : 400}
          textColor={
            messengerInfo.unReadMessageCount !== 0
              ? COLORS.PRIMARY
              : COLORS.LIGHT_GRAY
          }
        />
      </Stack>
      <Stack gap={"5px"}>
        <Box>
          <AtsTitleText
            text={lastMessageDate}
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Box>
        {messengerInfo.unReadMessageCount !== 0 && (
          <AtsRow
            justifyContent={"center"}
            sx={{
              mt: 1,
            }}
          >
            <Badge
              className="unread-count"
              badgeContent={messengerInfo.unReadMessageCount}
              sx={{
                ".MuiBadge-badge": {
                  bgcolor: BG_COLORS.PRIMARY,
                  color: COLORS.PRIMARY,
                  fontSize: 10,
                  fontWeight: 600,
                  minWidth: "18px",
                  height: "18px",
                },
              }}
            />
          </AtsRow>
        )}
      </Stack>
    </Stack>
  );
};

export default MessengerInfo;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
