import { Stack } from "@mui/material";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CheckboxInput from "../components/CheckboxInput";
import JobCardWrapper from "../components/JobCardWrapper";
import ImportantThings from "../components/ImportantThings";

const jobCheck = [
  "Security clearance",
  "Background check",
  "Drug testing",
  "None",
];

const AlmostThere = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);

  const handleChange = (value: string) => {
    let updatedJobCheck = [...postOpportunity.job_check];

    if (value === "None") {
      if (updatedJobCheck.includes("None")) {
        updatedJobCheck = updatedJobCheck.filter((item) => item !== "None");
      } else {
        updatedJobCheck = ["None"];
      }
    } else {
      if (updatedJobCheck.includes(value)) {
        updatedJobCheck = updatedJobCheck.filter((item) => item !== value);
      } else {
        updatedJobCheck = updatedJobCheck.filter((item) => item !== "None");
        updatedJobCheck.push(value);
      }
    }

    const opportunityObj = {
      ...postOpportunity,
      job_check: updatedJobCheck,
    } as PostOpportunityProps;

    dispatch(setPostOpportunity(opportunityObj));
  };
  return (
    <>
      <JobCardWrapper
        header="Almost there!"
        subText="Does your opportunuty require any of these?"
      >
        <Stack pr={1} gap={2}>
          {jobCheck.map((item, index) => (
            <CheckboxInput
              key={index}
              title={item}
              checked={postOpportunity.job_check.includes(item)}
              onSelect={() => handleChange(item)}
            />
          ))}
          <Stack mt={3}>
            <ImportantThings
              header="Important things to remember"
              subText="Be sure to comply with all local workplace regulations, and the "
              linkText="Esteemed nondiscrimination policy."
            />
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default AlmostThere;
