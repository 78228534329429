import { Box, Skeleton, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectOverviewServices } from "../../../../../../Api/projectOverviewServices";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, BOX_SHADOW, COLORS } from "../../../../../../theme";
import { ProjectPipelineCount } from "../../../../../../types/projectOverviewTypes";
import CandidatesDemographics from "./CandidatesDemographics";
export const initialCountDetails = [
  {
    title: "New Submission",
    value: 0,
    color: "#b2edff",
  },
  {
    title: "Interviewing",
    value: 0,
    color: "#ddb2ff",
  },

  {
    title: "Offers Extended",
    value: 0,
    color: "#f8ea6c",
  },
  {
    title: "Offer Accepted",
    value: 0,
    color: "#b2a6fa",
  },
  {
    title: "Hired",
    value: 0,
    color: "#81ea8c",
  },
];

const PipelineProject = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [pipelineStages, setPipelineStages] = useState<ProjectPipelineCount[]>(
    []
  );
  const getProjectPipeline = async () => {
    setLoading(true);
    try {
      const response = await ProjectOverviewServices.getHiringPipelineStages({
        project_id: id,
      });

      if (response.results.length) {
        const countDetails = [
          {
            title: "New Submission",
            value: response.results[0]?.submissions_count,
            color: "#b2edff",
          },
          {
            title: "Interviewing",
            value: response.results[0]?.interview_count,
            color: "#ddb2ff",
          },

          {
            title: "Offers Extended",
            value: response.results[0]?.offer_extented_count,
            color: "#f8ea6c",
          },
          {
            title: "Offer Accepted",
            value: response.results[0]?.offer_accepted_count,
            color: "#b2a6fa",
          },
          {
            title: "Hired",
            value: response.results[0]?.hired_count,
            color: "#81ea8c",
          },
        ];
        setPipelineStages(countDetails);
      } else {
        setPipelineStages(initialCountDetails);
      }
    } catch (error) {
      console.error(error);
      setPipelineStages(initialCountDetails);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getProjectPipeline();
  }, []);

  const candidatesDemographicsMemo = useMemo(
    () => <CandidatesDemographics />,
    []
  );

  return (
    <>
      <Box pb={"22px"}>
        <AtsTitleText text={"Pipeline"} fs={18} fw={600} />
      </Box>
      <Box pb={"13px"}>
        <AtsTitleText
          text={"Hiring pipeline stages"}
          fs={16}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Box>
      <Stack direction={"row"} flexWrap={"wrap"} gap={"15px"}>
        {loading ? (
          <StagesList isLoading={true} stages={initialCountDetails} />
        ) : (
          <StagesList isLoading={false} stages={pipelineStages} />
        )}
      </Stack>
      {candidatesDemographicsMemo}
    </>
  );
};

export default PipelineProject;

const StageCard = ({
  stage,
  isLoading,
}: {
  stage: ProjectPipelineCount;
  isLoading: boolean;
}) => (
  <Stack
    sx={{
      px: "16px",
      py: "14px",
      borderRadius: "6px",
      boxShadow: BOX_SHADOW.GRAY,
      gap: "10px",
      width: 97,
      cursor: "pointer",
      border: BORDERS.GRAY,
    }}
  >
    <Stack direction="row" alignItems="center" gap="6px">
      <Box
        sx={{
          width: "15px",
          height: "15px",
          bgcolor: stage.color,
          borderRadius: "30px",
        }}
      ></Box>
      {isLoading ? (
        <Skeleton width={50} />
      ) : (
        <AtsTitleText
          fs={18}
          fw={400}
          textColor={COLORS.BLACK}
          text={stage.value}
        />
      )}
    </Stack>
    <AtsTitleText
      fs={12}
      fw={600}
      textColor={COLORS.BLACK}
      text={stage.title}
    />
  </Stack>
);

const StagesList = ({
  isLoading,
  stages,
}: {
  isLoading: boolean;
  stages: ProjectPipelineCount[];
}) => (
  <>
    {stages.map((stage, index) => (
      <StageCard key={index} stage={stage} isLoading={isLoading} />
    ))}
  </>
);
