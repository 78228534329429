import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface CandidateState {
  condidateTabValue: number;
  jobId: string | null;
  submissionId: string | null;
  contractType: string | null;
}

const initialState: CandidateState = {
  condidateTabValue: 0,
  jobId: null,
  submissionId: null,
  contractType: null,
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setCondidateTabValue: (state: CandidateState, action) => {
      const { payload } = action;
      state.condidateTabValue = payload;
    },
    setJobId: (state: CandidateState, action) => {
      const { payload } = action;
      state.jobId = payload;
    },
    setSubmissionId: (state: CandidateState, action) => {
      const { payload } = action;
      state.submissionId = payload;
    },
    setContractType: (state: CandidateState, action) => {
      const { payload } = action;
      state.contractType = payload;
    },
  },
});

export const {
  setCondidateTabValue,
  setJobId,
  setSubmissionId,
  setContractType,
} = candidateSlice.actions;
export const getCandidateState = (state: RootState) => state.candidate;
export default candidateSlice.reducer;
