import React, { useEffect } from "react";
import OnboardingWrapper from "./OnboardingWrapper";
import { Box, Skeleton, Stack } from "@mui/material";
import FormWrapper from "./FormWrapper";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsCheckbox from "../../../../../../../../../components/AtsCheckbox";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { Edit2 } from "iconsax-react";
import AtsLabel from "../../../../../../../../../components/AtsLabel";

const SelectPolicyDocuments = () => {
  const {
    policyLoading,
    policyDocuments,
    selectedPolicyDocs,
    setSelectedPolicyDocs,
    getDocumentList,
    editState,
    handleEditState,
  } = useOnboarding();
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);

  useEffect(() => {
    getDocumentList();
  }, []);

  return (
    <OnboardingWrapper heading="Please check the policy document that need to be consented by the contractor">
      <Stack gap={"40px"}>
        <FormWrapper
          heading="Select Policy Documnets"
          renderRightComponent={
            isContractorOnboarded ? (
              <AtsRow gap={"10px"}>
                <>
                  {editState.isEditPolicyDocuments ? (
                    <AtsRoundedButton
                      text="Save"
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="darkGreenContained"
                      onClick={() =>
                        handleEditState("isEditPolicyDocuments", false)
                      }
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Edit"
                      startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="whiteContained"
                      onClick={() =>
                        handleEditState("isEditPolicyDocuments", true)
                      }
                    />
                  )}
                </>
              </AtsRow>
            ) : (
              <></>
            )
          }
        >
          <Box pt={"20px"}>
            {policyLoading ? (
              <Stack gap={"10px"}>
                {Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width={"100%"}
                    height={50}
                  />
                ))}
              </Stack>
            ) : (
              policyDocuments.map((document, index) => (
                <AtsRow
                  key={index}
                  gap={"10px"}
                  sx={{
                    py: "15px",
                    borderBottom:
                      policyDocuments.length - 1 !== index
                        ? BORDERS.GRAY
                        : "none",
                  }}
                >
                  <>
                    {!isContractorOnboarded && (
                      <AtsCheckbox
                        p={0}
                        checked={selectedPolicyDocs.includes(document.nid)}
                        onChange={(e) => {
                          if (selectedPolicyDocs.includes(document.nid)) {
                            const filterPoliyDocs = selectedPolicyDocs.filter(
                              (doc) => doc !== document.nid
                            );
                            setSelectedPolicyDocs(filterPoliyDocs);
                          } else {
                            setSelectedPolicyDocs([
                              ...selectedPolicyDocs,
                              document.nid,
                            ]);
                          }
                        }}
                      />
                    )}
                    {isContractorOnboarded &&
                      editState.isEditPolicyDocuments && (
                        <AtsCheckbox
                          p={0}
                          checked={selectedPolicyDocs.includes(document.nid)}
                          onChange={(e) => {
                            if (selectedPolicyDocs.includes(document.nid)) {
                              const filterPoliyDocs = selectedPolicyDocs.filter(
                                (doc) => doc !== document.nid
                              );
                              setSelectedPolicyDocs(filterPoliyDocs);
                            } else {
                              setSelectedPolicyDocs([
                                ...selectedPolicyDocs,
                                document.nid,
                              ]);
                            }
                          }}
                        />
                      )}
                    <AtsRow gap={"10px"}>
                      <>
                        <AtsTitleText text={document.title} fs={14} fw={500} />
                        {isContractorOnboarded &&
                        !editState.isEditPolicyDocuments &&
                        selectedPolicyDocs.includes(document.nid) ? (
                          <AtsLabel
                            fs={12}
                            px={2}
                            text={"Confirmed"}
                            variant="success"
                            isCapital
                            height="25px"
                          />
                        ) : (
                          ""
                        )}
                      </>
                    </AtsRow>
                  </>
                </AtsRow>
              ))
            )}
          </Box>
        </FormWrapper>
      </Stack>
    </OnboardingWrapper>
  );
};

export default SelectPolicyDocuments;
