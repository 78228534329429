import React from "react";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { Call, Home3, Location } from "iconsax-react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { COLORS } from "../../../../../../../../../theme";
import AtsRow from "../../../../../../../../../components/AtsRow";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";

const AboutModule = () => {
  const { candidateDetails, isCandidateDetailsLoading } =
    useAppSelector(getSubmissionState);

  const TextIconWrapper = ({
    icon,
    text,
    boldText,
  }: {
    icon: JSX.Element;
    text: string;
    boldText: string;
  }) => {
    return (
      <AtsRow gap={"10px"}>
        <>
          {icon}
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: COLORS.LIGHT_GRAY,
            }}
          >
            {text}
            {"  "}
            {isCandidateDetailsLoading ? (
              <Skeleton variant="rounded" width={100} />
            ) : (
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {boldText}
              </span>
            )}
          </Typography>
        </>
      </AtsRow>
    );
  };
  return (
    <Stack gap={1}>
      <TextIconWrapper
        icon={<Location size={16} />}
        text="From"
        boldText={candidateDetails?.address_state_code ?? ""}
      />
      <TextIconWrapper
        icon={<Home3 size={16} />}
        text="Live in"
        boldText={`${candidateDetails?.address_city ?? ""}${
          candidateDetails?.address_city ? "," : ""
        }  ${candidateDetails?.address_country_code ?? ""}`}
      />
      <TextIconWrapper
        icon={<Call size={16} />}
        text="Contact"
        boldText={`${candidateDetails?.mobile ?? ""}${
          candidateDetails?.mobile ? " |" : ""
        }  ${candidateDetails?.email_address ?? ""}`}
      />
      <Stack
        sx={{
          marginTop: "8px",
        }}
      >
        <AtsTitleText
          text={candidateDetails?.field_ats_candidate_about ?? ""}
          fs={14}
          fw={400}
          lineHeight={"21px"}
        />
      </Stack>
    </Stack>
  );
};

export default AboutModule;
