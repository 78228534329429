import { Box, Skeleton, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { AtsAvatarBadge } from "../../../../components/AtsAvatarBadge";
import AtsCard from "../../../../components/AtsCard";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../theme";
import AtsIcon from "../../../../components/AtsIcon";

interface Props {
  onClick: () => void;
  header: string;
  subText: string;
  image?: string;
  loading?: boolean;
}

const SupportCard = ({ header, subText, onClick, image, loading }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <motion.div
        whileHover={{ scale: 1.02, translateY: -5 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{
          position: "relative",
          overflow: "hidden",
          borderRadius: "10px",
          cursor: "pointer",
          boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
        onClick={onClick}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <AtsCard
          sx={{
            minHeight: 88,
          }}
        >
          <>
            {loading ? (
              <Stack
                pt={1}
                pb={1}
                px={1}
                direction={"row"}
                sx={{
                  minHeight: "67px",
                }}
                gap={1.5}
              >
                <Skeleton
                  variant="circular"
                  width={50}
                  height={50}
                  sx={{
                    borderRadius: "50%",
                  }}
                />
                <Stack gap={0.5} width="100%">
                  <Skeleton variant="text" width="60%" height={14} />
                  <Skeleton variant="text" width="80%" height={12} />
                </Stack>
              </Stack>
            ) : (
              <Stack
                pt={1}
                pb={1}
                px={1}
                direction={"row"}
                sx={{
                  minHeight: "67px",
                }}
                gap={1.5}
              >
                <AtsAvatarBadge
                  sx={{
                    width: 50,
                    height: 50,
                    border: BORDERS.DARK_GRAY,
                    bgcolor: "white",
                  }}
                  src={image ? image : ""}
                  icon={
                    <AtsIcon icon="icon-profile-2user" height="32px" fs={32} />
                  }
                  // src={
                  //   "https://images.pexels.com/photos/13514352/pexels-photo-13514352.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  // }
                />
                <Stack gap={0.5}>
                  <AtsTitleText text={header} fs={14} fw={700} />
                  <AtsTitleText
                    text={subText}
                    fs={12}
                    fw={400}
                    textColor={COLORS.DARK_GRAY}
                    lineHeight={1.5}
                  />
                </Stack>
              </Stack>
            )}

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "24px",
                height: "20px",
                textAlign: "center",
                borderBottomRightRadius: "10px",
                borderTopLeftRadius: "10px",
                bgcolor: isHovered ? COLORS.PRIMARY : BG_COLORS.PRIMARY,
              }}
            >
              <IoIosArrowForward
                style={{
                  fontSize: 12,
                  color: !isHovered ? COLORS.PRIMARY : BG_COLORS.PRIMARY,
                }}
              />
            </Box>
          </>
        </AtsCard>
      </motion.div>
    </>
  );
};

export default SupportCard;
