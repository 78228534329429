import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { ComponentType, ReactNode } from "react";
import AtsRoundedButton from "../AtsRoundedButton";
import AtsBackLink from "../AtsBackLink";
import AtsTitleText from "../AtsTitleText";

interface IStepWrapperProps {
  headerText: string;
  backText: string;
  isQuestionRoute: boolean;
  isFinalStep: boolean;
  isImgShow: boolean;
  QuestionComponent: ComponentType<any>;
  FinalStepComponent: ComponentType<any>;
  StepperComponent: ComponentType<any>;
  backRoute: () => void;
  questionAction: () => void;
  renderStepComponent: () => ReactNode;
}

const StepWrapper = ({
  headerText,
  backText,
  isQuestionRoute,
  isFinalStep,
  isImgShow,
  QuestionComponent,
  StepperComponent,
  FinalStepComponent,
  backRoute,
  questionAction,
  renderStepComponent,
}: IStepWrapperProps) => {
  const theme = useTheme();
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <AtsTitleText fs={24} fw={600} text={headerText} />
        <AtsBackLink text={backText} onClick={backRoute} />
      </Stack>
      <Box
        sx={{
          mt: 3,
          bgcolor: "white",
          height: "calc(100% - 110px)",
          borderRadius: 2,
        }}
      >
        {isQuestionRoute ? (
          <QuestionComponent />
        ) : isFinalStep ? (
          <FinalStepComponent />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box sx={{ height: "calc(100% - 80px)", position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  right: 25,
                  top: 25,
                }}
              >
                <AtsRoundedButton
                  text="Questions?"
                  buttonVariant="whiteContained"
                  onClick={questionAction}
                  fw={600}
                  buttonHeight={29}
                />
              </Box>
              <Box
                sx={{
                  maxWidth: isLargeXl ? "750px" : "660px",
                  mx: "auto",
                  mt: isLargeXl ? "100px" : "40px",
                  height: isLargeXl
                    ? "calc(100% - 115px)"
                    : "calc(100% - 40px)",
                  px: 1,
                }}
              >
                {renderStepComponent()}
              </Box>
              {isImgShow && (
                <Box
                  sx={{
                    position: "absolute",
                    left: 5,
                    bottom: -8,
                    width: "12%",
                  }}
                >
                  <img
                    width={"50%"}
                    src={require("../../assets/images/flower.png")}
                    alt="flower"
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ height: "80px", zIndex: 1 }}>
              <StepperComponent />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StepWrapper;
