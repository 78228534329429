import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import BudgetRange from "../../components/BudgetRange";

const ProjectBudget = () => {
  return (
    <JobCardWrapper
      header="Now set your budget"
      subText="Set a rate of budget for your project"
    >
      <>
        <BudgetRange />
      </>
    </JobCardWrapper>
  );
};

export default ProjectBudget;
