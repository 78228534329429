import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import AtsRow from "../../../components/AtsRow";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import JobTitleSelector from "../../../pages/PostOpportunity/components/JobTitleSelector";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { COLORS } from "../../../theme";
import { CreateUpdateBillingInfoRequest } from "../../../types/billingTypes";
import { CompanyAddressProps } from "../../../types/companyTypes";

const BillingDetails = () => {
  const {
    billingInfoState,
    handleBillingDetailsChange,
    searchQueryForBilling,
    bilingDetailsError,
    setSearchQueryForBilling,
  } = useCreateAccount();

  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSearchQueryForBilling(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";
      let country_code = "";

      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
          country_code = component.short_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        }
      }
      const address = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
        country_code,
      } as CompanyAddressProps;
      handleBillingDetailsChange("address", address);
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const renderTextInputComponent = (
    title: string,
    value: string | number,
    key: keyof CreateUpdateBillingInfoRequest,
    handleChange: (key: string, value: string) => void
  ) => {
    return (
      <Stack gap={"5px"}>
        <AtsRow gap={"5px"}>
          <>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
            >
              {title}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>
          </>
        </AtsRow>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {key === "address" ? (
            <Autocomplete
              onLoad={handleLoad}
              onPlaceChanged={handlePlaceSelect}
            >
              <AtsTextInput
                height="41px"
                value={searchQueryForBilling}
                onChange={(e) => setSearchQueryForBilling(e.target.value)}
              />
            </Autocomplete>
          ) : key === "job_title" ? (
            <JobTitleSelector
              height="41px"
              isHideSearchIcon
              isShowDropdownIcon
              selected={value as string}
              onSelected={(select) => handleChange(key, select)}
            />
          ) : (
            <AtsTextInput
              placeholder={`Enter ${title}`}
              height="41px"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          )}
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={bilingDetailsError[key]}
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
    );
  };

  if (!isLoaded) {
    return <Skeleton />;
  }

  return (
    <>
      <Stack gap={"20px"}>
        <AtsRow gap={"10px"}>
          <>
            <AtsTitleText
              text={"Billing details"}
              textColor={"#1c1c1c"}
              fs={24}
              fw={600}
            />
            {/* {!isEdit && (
              <AtsRoundedButton
                text="Edit"
                startIcon={<EditIcon />}
                buttonVariant="whiteContained"
                buttonHeight={29}
                buttonWidth={65}
                onClick={() => setIsEdit(!isEdit)}
              />
            )} */}
          </>
        </AtsRow>
        <AtsTitleText
          text={
            "Please review and confirm that the billing details entered below are correct before proceeding."
          }
          textColor={"#000"}
          fs={12}
          fw={400}
        />
      </Stack>
      <Box mt={"28px"}>
        <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Email",
              billingInfoState?.email_1 ?? "",
              "email_1",
              handleBillingDetailsChange
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Alternate email",
              billingInfoState?.email_2 ?? "",
              "email_2",
              handleBillingDetailsChange
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "First name",
              billingInfoState?.first_name ?? "",
              "first_name",
              handleBillingDetailsChange
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Last name",
              billingInfoState?.last_name ?? "",
              "last_name",
              handleBillingDetailsChange
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Title",
              billingInfoState?.job_title ?? "",
              "job_title",
              handleBillingDetailsChange
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            {renderTextInputComponent(
              "Billing address",
              searchQueryForBilling,
              "address",
              handleBillingDetailsChange
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BillingDetails;
