import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRow from "../../../../../../../../components/AtsRow";
import { DisputeResolutionTabsEnum } from "../../../../../../../../types/contractorTypes";
import { BG_COLORS, COLORS } from "../../../../../../../../theme";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import { DisputedData } from "../../../../../../../../types/timesheetTypes";
import { DisputeResolutionProps } from "../DisputeResolutionDrawer";

const tabs = [
  {
    title: DisputeResolutionTabsEnum.Disputed,
    status: "disputed",
    bgColor: BG_COLORS.PRIMARY,
    color: COLORS.PRIMARY,
    width: 108,
  },

  {
    title: DisputeResolutionTabsEnum.Resolved,
    status: "Resolved",
    bgColor: "#caffd6",
    color: "#087f23",
    width: 108,
  },
];

const TimelineTabs = ({
  loading,
  disputedTimesheet,
  status,
}: DisputeResolutionProps) => {
  return (
    <Stack
      sx={{
        bgcolor: "#f5f8ff",
        px: "30px",
        py: "18px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.06)",
      }}
      gap={"8px"}
    >
      <AtsTitleText text="Timeline" textColor="#000" fs={16} fw={400} />
      <AtsRow>
        <>
          {loading ? (
            Array.from(new Array(2)).map((_, index) => (
              <AtsRow key={index}>
                <>
                  <Skeleton variant="rounded" height={27} width={108} />
                  {2 !== index + 1 && (
                    <Box
                      sx={{
                        width: 46,
                        borderBottom: "1px dashed #3366cc",
                      }}
                    ></Box>
                  )}
                </>
              </AtsRow>
            ))
          ) : disputedTimesheet && status === "disputed" ? (
            <AtsRow
              gap={"5px"}
              sx={{
                bgcolor: BG_COLORS.PRIMARY,
                borderRadius: "30px",
                justifyContent: "center",
                width: 108,
                height: 27,
                boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.15)",
              }}
            >
              <>
                <AtsTitleText
                  text={"Disputed"}
                  fs={12}
                  fw={600}
                  textColor={COLORS.PRIMARY}
                />
              </>
            </AtsRow>
          ) : (
            tabs.map((tab, index) => {
              return (
                <AtsRow key={index}>
                  <>
                    <AtsRow
                      gap={"5px"}
                      sx={{
                        bgcolor: tab.bgColor,
                        borderRadius: "30px",
                        justifyContent: "center",
                        width: tab.width,
                        height: 27,
                        ...(status === tab.status && {
                          boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.15)",
                        }),
                      }}
                    >
                      <>
                        <AtsTitleText
                          text={tab.title}
                          fs={12}
                          fw={600}
                          textColor={tab.color}
                        />
                      </>
                    </AtsRow>
                    {tabs.length !== index + 1 && (
                      <Box
                        sx={{
                          width: 46,
                          borderBottom: "1px dashed #3366cc",
                        }}
                      ></Box>
                    )}
                  </>
                </AtsRow>
              );
            })
          )}
        </>
      </AtsRow>
    </Stack>
  );
};

export default TimelineTabs;
