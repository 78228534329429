import { createContext, useContext, useEffect, useState } from "react";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
} from "../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Views } from "react-big-calendar";
import moment from "moment";
import {
  CalendarEventTypes,
  ContractsEventsResponse,
  DayAvailability,
  InterviewEventsResponse,
  InvoiceDueEventsResponse,
} from "../types/calendarTypes";
import { CalendarServices } from "../Api/calendarServices";

export type Keys = keyof typeof Views;

export const initialAvailability = [
  {
    day: "Monday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Tuesday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Wednesday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Thursday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Friday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Saturday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Sunday",
    is_unavailable: 1,
    slots: [],
  },
];

type CalendarAvailabilityModuleContextType = {
  isAvailability: boolean;
  loading: boolean;
  invoiceEventLoading: boolean;
  contractsEventLoading: boolean;
  interviewEventLoading: boolean;
  interviewEvents: InterviewEventsResponse[];
  contractsEvents: ContractsEventsResponse[];
  invoiceEvents: InvoiceDueEventsResponse[];
  selectedTimezone: string;
  availability: DayAvailability[];
  calendarView: (typeof Views)[Keys];
  date: Date;
  eventType: keyof typeof CalendarEventTypes;
  setCalendarView: React.Dispatch<React.SetStateAction<(typeof Views)[Keys]>>;
  setAvailability: React.Dispatch<React.SetStateAction<DayAvailability[]>>;
  setIsAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>;
  setInterviewEvents: React.Dispatch<
    React.SetStateAction<InterviewEventsResponse[]>
  >;
  setContractsEvents: React.Dispatch<
    React.SetStateAction<ContractsEventsResponse[]>
  >;
  setInvoiceEvents: React.Dispatch<
    React.SetStateAction<InvoiceDueEventsResponse[]>
  >;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  setEventType: React.Dispatch<
    React.SetStateAction<keyof typeof CalendarEventTypes>
  >;
  openCloseDisputedResolutionDrawer: () => void;
  getInvoiceEventData: () => void;
  getContractsEventData: () => void;
  getInterviewEventData: () => void;
};

const CalendarAvailabilityModule =
  createContext<CalendarAvailabilityModuleContextType | null>(null);

export const useCalendarAvailability = () =>
  useContext(
    CalendarAvailabilityModule
  ) as CalendarAvailabilityModuleContextType;

export const CalendarAvailabilityProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  const [isAvailability, setIsAvailability] = useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [invoiceEventLoading, setInvoiceEventLoading] =
    useState<boolean>(false);
  const [contractsEventLoading, setContractsEventLoading] =
    useState<boolean>(false);
  const [interviewEventLoading, setInterviewEventLoading] =
    useState<boolean>(false);

  const [interviewEvents, setInterviewEvents] = useState<
    InterviewEventsResponse[]
  >([]);
  const [contractsEvents, setContractsEvents] = useState<
    ContractsEventsResponse[]
  >([]);
  const [invoiceEvents, setInvoiceEvents] = useState<
    InvoiceDueEventsResponse[]
  >([]);

  const [date, setDate] = useState<Date>(moment(new Date()).toDate());
  const [eventType, setEventType] = useState<keyof typeof CalendarEventTypes>(
    CalendarEventTypes.All
  );
  const [calendarView, setCalendarView] = useState<(typeof Views)[Keys]>(
    Views.WEEK
  );
  const [availability, setAvailability] = useState<DayAvailability[]>([]);

  const openCloseDisputedResolutionDrawer = () => {
    dispatch(setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen));
    setIsAvailability(!isAvailability);
  };

  const getStartEndDate = () => {
    if (calendarView === Views.WEEK) {
      const from = moment(date)?.startOf("week");
      const to = moment(date)?.endOf("week");
      const startWeekDate = moment(from.format()).unix();
      const endWeekDate = moment(to.format()).unix();

      return {
        start_date: startWeekDate,
        end_date: endWeekDate,
      };
    }
    if (calendarView === Views.MONTH) {
      const from = moment(date)?.startOf("month");
      const to = moment(date)?.endOf("month");
      const startMonthDate = moment(from.format()).unix();
      const endMonthDate = moment(to.format()).unix();
      return {
        start_date: startMonthDate,
        end_date: endMonthDate,
      };
    }
  };

  const getInvoiceEventData = async () => {
    setInvoiceEventLoading(true);
    try {
      const { start_date, end_date } = getStartEndDate();
      const response = await CalendarServices.getInvoiceDueEvents({
        start_date,
        end_date,
      });
      setInvoiceEvents(response);
    } catch (error) {
      console.error(error);
    } finally {
      setInvoiceEventLoading(false);
    }
  };

  const getContractsEventData = async () => {
    setContractsEventLoading(true);
    try {
      const { start_date, end_date } = getStartEndDate();
      const response = await CalendarServices.getContractsEvents({
        start_date,
        end_date,
      });
      setContractsEvents(response);
    } catch (error) {
      console.error(error);
    } finally {
      setContractsEventLoading(false);
    }
  };

  const getInterviewEventData = async () => {
    setInterviewEventLoading(true);
    try {
      const { start_date, end_date } = getStartEndDate();
      const response = await CalendarServices.getInterviewEvents({
        start_date,
        end_date,
      });
      setInterviewEvents(response[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setInterviewEventLoading(false);
    }
  };

  return (
    <CalendarAvailabilityModule.Provider
      value={{
        isAvailability,
        loading,
        interviewEventLoading,
        invoiceEventLoading,
        contractsEventLoading,
        contractsEvents,
        interviewEvents,
        calendarView,
        invoiceEvents,
        date,
        eventType,
        availability,
        selectedTimezone,
        setAvailability,
        setContractsEvents,
        setInvoiceEvents,
        setInterviewEvents,
        setSelectedTimezone,
        setIsAvailability,
        setCalendarView,
        setDate,
        setEventType,
        openCloseDisputedResolutionDrawer,
        getContractsEventData,
        getInterviewEventData,
        getInvoiceEventData,
      }}
    >
      {children}
    </CalendarAvailabilityModule.Provider>
  );
};
