import { Avatar, Box, Skeleton, Stack } from "@mui/material";
import moment from "moment";
import AtsRow from "../../../../../components/AtsRow";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { useMessages } from "../../../../../providers/MessagesProvider";
import { BG_COLORS } from "../../../../../theme";
import { Messages } from "../../../../../types/messagesTypes";
import { getDateFormatMMMMDYYYYY } from "../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";

interface ConversationProps {
  conversationInfo: Messages;
}

const ConversationBox = ({ conversationInfo }: ConversationProps) => {
  const { messenger, isConversationsLoading } = useMessages();

  const senderImage =
    isNotNullOrUndefined(conversationInfo.sender) &&
    isNotNullOrUndefined(conversationInfo.sender.picture)
      ? conversationInfo.sender.picture
      : null;
  const firstName = isNotNullOrUndefined(conversationInfo.sender)
    ? conversationInfo.sender.firstName ?? ""
    : "";
  const lastName = isNotNullOrUndefined(conversationInfo.sender)
    ? conversationInfo.sender.lastName ?? ""
    : "";
  const name = `${firstName} ${lastName}`;
  const message = conversationInfo.content ?? "";
  const timeStamp = isNotNullOrUndefined(conversationInfo.updatedAt)
    ? getDateFormatMMMMDYYYYY(conversationInfo.updatedAt)
    : "";
  const time = isNotNullOrUndefined(conversationInfo.updatedAt)
    ? moment(conversationInfo.updatedAt).format("h:mm a")
    : "";

  return (
    <>
      {conversationInfo.showTime && (
        <AtsRow justifyContent={"center"}>
          {isConversationsLoading ? (
            <Skeleton variant="text" width={50} />
          ) : (
            <AtsTitleText text={timeStamp} fs={12} fw={500} textColor="#000" />
          )}
        </AtsRow>
      )}
      <Stack pb={"30px"}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={"10px"}>
          <Box>
            {isConversationsLoading ? (
              <Skeleton variant="circular" width={24} height={24} />
            ) : senderImage ? (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
                src={senderImage}
              />
            ) : (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            )}
          </Box>
          <Stack gap={"8px"} pt={"4px"}>
            <AtsRow gap={"10px"}>
              <>
                {isConversationsLoading ? (
                  <Skeleton variant="text" width={80} />
                ) : (
                  <AtsTitleText text={name} fs={14} fw={500} textColor="#000" />
                )}
                {isConversationsLoading ? (
                  <Skeleton variant="text" width={40} />
                ) : (
                  <AtsTitleText
                    text={time}
                    fs={12}
                    fw={400}
                    textColor="rgba(0, 0, 0, 0.4)"
                  />
                )}
              </>
            </AtsRow>
            {isConversationsLoading ? (
              <Skeleton variant="rectangular" width={200} height={40} />
            ) : (
              <Stack
                sx={{
                  px: "16px",
                  py: "8px",
                  bgcolor:
                    conversationInfo?.sender?.id !== messenger?.recipient?.id
                      ? BG_COLORS.GRAY
                      : BG_COLORS.PRIMARY,
                  borderRadius: "0px 8px 8px 8px",
                }}
              >
                <AtsTitleText
                  text={message}
                  fs={14}
                  fw={400}
                  textColor="#000"
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ConversationBox;
