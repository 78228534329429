import { Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS } from "../../../theme";
import { JobCategoriesResponse } from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import RadioInput from "../components/RadioInput";
import { TitleIdObjResponse } from "../../../types/commonType";

const requirementData = [
  "No degree reqirement",
  "Associate degree",
  "Bechelor degree",
  "Master degree",
  "Doctorate degree",
];

const EducationalRequrement = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [loading, setLoading] = useState<boolean>(false);
  const [educationalRequirements, setEducationalRequirements] = useState<
    TitleIdObjResponse[]
  >([]);

  const handleChange = (value: JobCategoriesResponse) => {
    if (postOpportunity.job_priority_id === value.id) {
      const opportunityObj = {
        ...postOpportunity,
        job_education_requirements_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_education_requirements_id: value.id,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const fetchEducationalRequirement = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getEducationalRequirements();
      setEducationalRequirements(response.results);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEducationalRequirement();
  }, []);
  return (
    <>
      <JobCardWrapper
        header="Do you have educational requirements?"
        subText="Choose the degree that best aligns with your needs"
      >
        <Stack pr={1} gap={2}>
          {loading
            ? Array.from(new Array(5)).map((_, index) => (
                <Stack
                  gap={2}
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    px: 2,
                    py: 1.5,
                    cursor: "pointer",
                  }}
                >
                  <Skeleton variant="circular" width={10} height={10} />

                  <Skeleton
                    variant="text"
                    width={300}
                    sx={{ fontSize: "1rem" }}
                  />
                </Stack>
              ))
            : educationalRequirements.map((item, index) => (
                <RadioInput
                  key={index}
                  title={item.title}
                  checked={
                    postOpportunity.job_education_requirements_id === item.id
                  }
                  onSelect={() => handleChange(item)}
                />
              ))}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default EducationalRequrement;
