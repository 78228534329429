import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS } from "../../../theme";
import { JobCategoriesResponse } from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import SmallCard from "../components/SmallCard";

const JobDescribes = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [loading, setLoading] = useState<boolean>(false);
  const [jobBenifits, setJobBenifits] = useState<JobCategoriesResponse[]>([]);

  const handleChange = (value: string) => {
    const preBenefits = [...postOpportunity.job_benefits_id];
    if (preBenefits.includes(value)) {
      const updatedBenefits = preBenefits.filter((day) => day !== value);
      const opportunityObj = {
        ...postOpportunity,
        job_benefits_id: updatedBenefits,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const updatedBenefits = [...preBenefits, value];
      const opportunityObj = {
        ...postOpportunity,
        job_benefits_id: updatedBenefits,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const fetchJobBenifits = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobBenifits({
        items_per_page: 50,
      });
      const iconMap = {
        Dental: "icon-profile-tick",
        Healthcare: "icon-lifebuoy",
        Vision: "icon-glass-1",
        "Life Insurance": "icon-lifebuoy",
        "Stock Options": "",
        "Tuition Reimbursement": "icon-calendar",
        Medical: "icon-health",
        "Family leave": "icon-layer",
        "Extended leave": "icon-verify",
        "401k ": "icon-chart-square",
        Prescriptions: "icon-clipboard-text",
        "Concierge ": "icon-profile-2user",
        Education: "icon-award",
        Gym: "icon-weight-1",
        "Paid time off": "icon-calendar-tick",
        Retreats: "icon-map",
        "Well being": "icon-bubble",
        "Long-term Disability": "icon-calendar",
        "401K": "icon-chart-square",
        Concierge: "icon-profile-2user",
        test: "icon-calendar",
      };
      const resposeWithIcon = response.results.map((category) => {
        const icon = iconMap[category.title];
        return icon ? { ...category, icon } : category;
      });
      setJobBenifits(resposeWithIcon);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobBenifits();
  }, []);
  return (
    <>
      <JobCardWrapper
        header="Do you offer any benefits?"
        subText="Select all that apply below to help your job stand out."
      >
        <Stack pr={1} mb={1}>
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(8)).map((_, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        border: BORDERS.GRAY,
                      }}
                    >
                      <Stack gap={1}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </Stack>
                    </Box>
                  </Grid>
                ))
              : jobBenifits.map((item, index) => (
                  <SmallCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    onSelect={() => handleChange(item.id)}
                    checked={postOpportunity.job_benefits_id.includes(item.id)}
                  />
                ))}
          </Grid>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobDescribes;
