import { ListItemIcon } from "@mui/material";
import AtsIcon from "../../../../components/AtsIcon";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import { JobCategoriesResponse } from "../../../../types/jobsTypes";

interface IBenefitsProps {
  benefit: JobCategoriesResponse;
}

const JobBenefits = ({ benefit }: IBenefitsProps) => {
  return (
    <>
      <div className="coverageCard includeSvg">
        <ListItemIcon sx={{ minWidth: "20px", mt: 0 }}>
          <AtsIcon
            icon={benefit.icon ?? "icon-sun-fog"}
            fs={25}
            color={COLORS.BLACK}
          />
        </ListItemIcon>
        <AtsTitleText
          text={`${benefit.title} Coverage`}
          fs={12}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </div>
    </>
  );
};

export default JobBenefits;
