import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { ProjectTimesheetTabsEnum } from "../../../../../../enums/projectEnums";
import { DisputeResolutionProvider } from "../../../../../../providers/DisputeResolutionProvider";
import { useTimesheet } from "../../../../../../providers/TimesheetProvider";
import {
  BG_COLORS,
  BORDERS,
  BOX_SHADOW,
  COLORS,
} from "../../../../../../theme";
import JobsSwitch from "../../../../../Dashboard/PostedJobs/JobsSwitch";
import ContractorDetailsDrawer from "../ContractorDetails/ContractorDetailsDrawer";
import ApprovedTimesheet from "./modules/ApprovedTimesheet";
import DisputedTimesheet from "./modules/DisputedTimesheet";
import PendingTimeSheet from "./modules/PendingTimeSheet";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import { useParams } from "react-router-dom";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";
import { Skeleton } from "@mui/material";

const Timesheets = () => {
  const { id } = useParams();
  const {
    getContractorsByProjectId,
    selectedStatus,
    setSelectedStatus,
    onNextClick,
    onPrevClick,
    dateText,
    date,
    setInputValue,
    inputValue,
    contractorsTimesheet,
    loading,
    selectedUsers,
    timeEntryLoading,
    timelogApproveLoading,
    setCandidateTimelog,
    setSelectedUsers,
    getTimeEntriesOfContractor,
    onApproveTimesheet,
  } = useTimesheet();
  const debounceValue = useDebounce(inputValue);

  useEffect(() => {
    getContractorsByProjectId(id);
  }, [selectedStatus, date, debounceValue]);

  const taskStatuses = Object.keys(ProjectTimesheetTabsEnum).map((status) => ({
    title: status,
    type: ProjectTimesheetTabsEnum[status],
  }));
  const contractorDetailsDrawerMemo = useMemo(
    () => <ContractorDetailsDrawer />,
    []
  );
  const getTimeEntries = async (email) => {
    const response = await getTimeEntriesOfContractor(email, id);
    return response;
  };

  const approveAll = async () => {
    const findSelectedContractorTimesheets = contractorsTimesheet
      .filter((value) => selectedUsers.includes(value.uid))
      .map((value) => value.email);

    const uploadChooseProjectImgPromises =
      findSelectedContractorTimesheets.map(getTimeEntries);
    const resultsForProjectImg: any = await Promise.all(
      uploadChooseProjectImgPromises
    );
    const flatArray = resultsForProjectImg.flatMap((group) =>
      group.map((item) => {
        return item;
      })
    );
    if (flatArray && flatArray.length) {
      const getNid = flatArray.map((value) => Number(value.nid));
      if (getNid) {
        onApproveTimesheet(getNid, id);
      }
    }
    console.log("flatArray", flatArray);
  };
  return (
    <>
      <Stack mb={"24px"}>
        <AtsTitleText fs={18} fw={600} text={`10 timesheets`} />
      </Stack>
      <Stack direction={"row"} sx={{ pb: "25px" }}>
        <JobsSwitch
          width="62px"
          jobs={taskStatuses}
          active={selectedStatus}
          onChange={(value) => {
            setCandidateTimelog([]);
            setSelectedUsers([]);
            setSelectedStatus(value as ProjectTimesheetTabsEnum);
          }}
        />
      </Stack>
      {selectedStatus === ProjectTimesheetTabsEnum.Pending && (
        <Stack direction={"row"} sx={{ pb: "25px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"9px"}
            sx={{
              width: "100%",
              padding: "9px 16px 10px 16px",
              borderRadius: "6px",
              bgcolor: "#ffefea",
            }}
          >
            <AtsIcon
              icon="icon-danger"
              color="#EC7249"
              height="16px"
              fw={600}
            />
            <AtsTitleText
              fs={14}
              fw={400}
              textColor="#000"
              text={
                "All timesheets must be approved within 48 hours; after which they will be auto-approved."
              }
            />
          </Stack>
        </Stack>
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{ pb: "24px" }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"20px"} height={30}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            minHeight={30}
            width={232}
            sx={{
              px: "16px",
              border: BORDERS.GRAY,
              borderRadius: "38px",
            }}
          >
            <input
              type="text"
              className="customInput"
              placeholder="Search contractor name"
              style={{
                width: "100%",
              }}
              value={inputValue}
              onChange={(e) => {
                const value = e.target.value;
                setInputValue(value);
              }}
            />
            <AtsIcon icon="icon-search-normal-1" height="16px" />
          </Stack>
        </Stack>
        {contractorsTimesheet.length > 0 &&
          selectedStatus === ProjectTimesheetTabsEnum.Pending && (
            <Stack direction={"row"} alignItems={"center"} gap={"24px"}>
              {/* <AtsRoundedButton
                text="Remind to approve"
                buttonHeight={29}
                buttonWidth={166}
                buttonVariant="blackOutline"
                isInitial
              /> */}
              <AtsRoundedButton
                text="Approve all"
                buttonHeight={29}
                buttonWidth={132}
                disabled={
                  selectedUsers.length === 0 ||
                  timeEntryLoading ||
                  timelogApproveLoading
                }
                loading={timeEntryLoading || timelogApproveLoading}
                onClick={approveAll}
              />
            </Stack>
          )}
      </Stack>
      <Stack
        sx={{
          height: "calc(100% - 235px)",
          border: BORDERS.GRAY,
          borderRadius: "6px",
          boxShadow: BOX_SHADOW.GRAY,
        }}
      >
        {loading ? (
          <Stack
            gap={"10px"}
            className="scrollHide"
            sx={{
              overflowX: "scroll",
              height: "100%",
              flex: 1,
            }}
          >
            {Array.from(new Array(5)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={"100%"}
                height={40}
              />
            ))}
          </Stack>
        ) : contractorsTimesheet.length ? (
          <>
            <AtsRowBetween
              sx={{
                px: "22px",
                py: "19px",
                bgcolor: BG_COLORS.GRAY,
                borderBottom: BORDERS.GRAY,
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
              }}
            >
              <>
                <AtsTitleText
                  text={dateText}
                  fs={16}
                  fw={500}
                  textColor="#000"
                />
                <Stack direction={"row"} gap={"10px"}>
                  <IconButton
                    sx={{
                      border: BORDERS.GRAY,
                      p: 1,
                    }}
                    onClick={() => {
                      setSelectedUsers([]);
                      onPrevClick();
                    }}
                  >
                    <ArrowBackIosNew
                      sx={{ fontSize: 12, color: COLORS.PRIMARY }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: BORDERS.GRAY,
                      p: 1,
                    }}
                    onClick={() => {
                      setSelectedUsers([]);
                      onNextClick();
                    }}
                  >
                    <ArrowForwardIos
                      sx={{ fontSize: 12, color: COLORS.PRIMARY }}
                    />
                  </IconButton>
                </Stack>
              </>
            </AtsRowBetween>
            <Box
              sx={{
                height: "calc(100% - 80px)",
                overflow: "auto",
              }}
            >
              <Box px={"30px"} pb={"27px"}>
                {selectedStatus === ProjectTimesheetTabsEnum.Approved ? (
                  <ApprovedTimesheet />
                ) : selectedStatus === ProjectTimesheetTabsEnum.Disputed ? (
                  <DisputeResolutionProvider>
                    <DisputedTimesheet />
                  </DisputeResolutionProvider>
                ) : (
                  <PendingTimeSheet />
                )}
              </Box>
            </Box>
          </>
        ) : (
          <AtsEmptyState
            sx={{
              height: "100%",
            }}
            imgHeight={68}
            imgWidth={74}
            text="No timesheet recently."
            subText=""
          />
        )}
      </Stack>
      {contractorDetailsDrawerMemo}
    </>
  );
};

export default Timesheets;
