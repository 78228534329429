import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { InvoiceServices } from "../Api/invoiceServices";
import { useDebounce } from "../hooks/useDebounce";
import {
  InvoiceDetailsResponse,
  InvoiceInfoResponse,
  InvoiceListResponse,
  InvoicesListParams,
} from "../types/invoiceTypes";
import { BillingTabsEnums } from "./BillingProvider";
import { InvoiceStatusEnum } from "../enums/invoiceEnums";
import toast from "react-hot-toast";

type InvoicesModuleContextType = {
  loading: boolean;
  isInvoiceView: boolean;
  detailsLoading: boolean;
  invoiceId: string;
  selectedInvoice: InvoiceListResponse | null;
  invoices: InvoiceListResponse[];
  invoiceDetails: InvoiceDetailsResponse[];
  invoiceFrom: InvoiceInfoResponse;
  invoiceTo: InvoiceInfoResponse;
  invoiceDate: {
    invoiceDate: string;
    dueDate: string;
  };
  selectedInvoiceTypeTab: string;
  setIsInvoiceView: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedInvoiceTypeTab: React.Dispatch<React.SetStateAction<string>>;
  setSelectedInvoice: React.Dispatch<
    React.SetStateAction<InvoiceListResponse | null>
  >;
  selectedBillingTab: BillingTabsEnums;
  setSelectedBillingTab: React.Dispatch<React.SetStateAction<BillingTabsEnums>>;
  getInvoiceDetails: (invoice_id: string) => void;
  getInvoiceData: (status: string) => void;
  proceedToPayment: () => void;
};

const InvoicesModule = createContext<InvoicesModuleContextType | null>(null);

export const useInvoices = () =>
  useContext(InvoicesModule) as InvoicesModuleContextType;

export const InvoicesProvider = ({ children }: any) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [selectedBillingTab, setSelectedBillingTab] =
    useState<BillingTabsEnums>(BillingTabsEnums.BillingDetails);
  const [isInvoiceView, setIsInvoiceView] = useState<boolean>(false);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<string>("");
  const [invoices, setInvoices] = useState<InvoiceListResponse[]>([]);
  const [invoiceFrom, setInvoiceFrom] = useState<InvoiceInfoResponse>({
    companyName: "",
    addressLine: "",
    cityStateZip: "",
    phone: "",
    email: "",
    website: "",
  });
  const [invoiceTo, setInvoiceTo] = useState<InvoiceInfoResponse>({
    companyName: "",
    addressLine: "",
    cityStateZip: "",
    phone: "",
    email: "",
    website: "",
  });
  const [invoiceDate, setInvoiceDate] = useState<{
    invoiceDate: string;
    dueDate: string;
  }>({
    invoiceDate: "",
    dueDate: "",
  });
  const [invoiceDetails, setInvoiceDetails] = useState<
    InvoiceDetailsResponse[]
  >([]);
  const [selectedInvoice, setSelectedInvoice] =
    useState<InvoiceListResponse | null>(null);
  const [selectedInvoiceTypeTab, setSelectedInvoiceTypeTab] =
    useState<string>("contract");
  const debounceSearch = useDebounce(invoiceId);

  const decodeHtml = (html: string): string => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const extractBillingInfo = (
    htmlString: string
  ): {
    companyName: string;
    addressLine: string;
    cityStateZip: string;
    phone: string;
    email: string;
    website: string;
  } => {
    const sanitizedHtml = htmlString.replace(/^<p>|<\/p>$/g, "");
    const decodedHtml = decodeHtml(sanitizedHtml);
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodedHtml, "text/html");

    const getTextContent = (selector: string): string | null => {
      const element = doc.querySelector(selector) as HTMLElement | null;
      return element ? element.innerText : "";
    };

    return {
      companyName: getTextContent(".billed-left p:nth-child(1)") || "",
      addressLine: getTextContent(".billed-left p:nth-child(2)") || "",
      cityStateZip: getTextContent(".billed-left p:nth-child(3)") || "",
      phone: getTextContent(".billed-right p:nth-child(1)") || "",
      email: getTextContent(".billed-right p:nth-child(2)") || "",
      website: getTextContent(".billed-right p:nth-child(3)") || "",
    };
  };

  const getInvoiceDetails = async (invoice_id: string) => {
    setDetailsLoading(true);
    try {
      const response = await InvoiceServices.getInvoiceData({
        nid: invoice_id,
      });

      if (response.length) {
        const invoiceData = response[0];
        setInvoiceDate({
          invoiceDate: invoiceData?.date ?? "",
          dueDate: invoiceData?.due_date ?? "",
        });
        const billFromDetails = extractBillingInfo(invoiceData.bill_from);
        const billedToDetails = extractBillingInfo(invoiceData.billed_to);
        setInvoiceFrom(billFromDetails);
        setInvoiceTo(billedToDetails);
      }

      setInvoiceDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setDetailsLoading(false);
    }
  };

  const getInvoiceData = async (status: string) => {
    setLoading(true);
    try {
      const body = {
        ...(pathname !== "/billing" && { project_id: id }),
        ...(pathname !== "/billing" && { type: selectedInvoiceTypeTab }),

        ...(invoiceId.length && { invoice_no: invoiceId }),
        ...(status.length && { status }),
      } as InvoicesListParams;
      const response = await InvoiceServices.getInvoices(body);
      setInvoices(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const proceedToPayment = () => {
    if (invoiceDetails && invoiceDetails.length) {
      if (
        invoiceDetails[0].payment_link &&
        invoiceDetails[0].payment_link.length
      ) {
        window.open(invoiceDetails[0].payment_link, "_blank");
      } else {
        toast.error("Payment link is empty");
      }
    }
  };

  useEffect(() => {
    getInvoiceData(pathname !== "/billing" ? "" : InvoiceStatusEnum.not_paid);
  }, [debounceSearch, selectedInvoiceTypeTab]);

  return (
    <InvoicesModule.Provider
      value={{
        isInvoiceView,
        detailsLoading,
        invoiceDetails,
        invoiceDate,
        loading,
        invoices,
        invoiceId,
        invoiceTo,
        invoiceFrom,
        selectedInvoiceTypeTab,
        selectedInvoice,
        selectedBillingTab,
        setSelectedBillingTab,
        setSelectedInvoice,
        setIsInvoiceView,
        setSelectedInvoiceTypeTab,
        setInvoiceId,

        getInvoiceDetails,
        getInvoiceData,
        proceedToPayment,
      }}
    >
      {children}
    </InvoicesModule.Provider>
  );
};
