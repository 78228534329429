import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../components/AtsTitleText";
import UserImageForProject from "../../../../Projects/modules/ProjectModules/UserImageForProject";
import { ProjectListProps } from "../../../../../types/projectTypes";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";

interface IProps {
  projectsDetails: ProjectListProps;
}

const WorkingOn = ({ projectsDetails }: IProps) => {
  const image = isNotNullOrUndefined(projectsDetails.project_image)
    ? projectsDetails.project_image.length > 0
      ? projectsDetails.project_image
      : require("../../../../../assets/images/projectImg.png")
    : require("../../../../../assets/images/projectImg.png");
  return (
    <>
      <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
        <Box
          sx={{
            width: 25,
            height: 25,
          }}
        >
          <img
            width={"100%"}
            style={{ borderRadius: "50%" }}
            src={image}
            alt=""
          />
        </Box>
        <Stack gap={"15px"}>
          <Stack gap={"5px"}>
            <AtsTitleText
              fs={12}
              fw={600}
              textColor={"#000"}
              text={projectsDetails.title}
              noTextWrap
            />
            <AtsTitleText
              fs={10}
              fw={400}
              textColor={"#000"}
              text="View details"
              noTextWrap
            />
          </Stack>
          <UserImageForProject project_id={projectsDetails.nid} />
        </Stack>
      </Stack>
    </>
  );
};

export default WorkingOn;
