import ReactApexChart from "react-apexcharts";

interface IProps {
  data: Record<string, Record<string, number>>;
}

const WorkforceExperienceGraph = ({ data }: IProps) => {
  const value = Object.values(data)
    .flatMap((value) => {
      const number = Object.values(value);

      return number;
    })
    .map((val) => Math.round(val));
  const counts = value.reduce((acc, num) => {
    acc[num] = (acc[num] || 0) + 1; // Increment count or initialize it to 1
    return acc;
  }, {});
  console.log("number", counts["6"]);
  const seriesData = [
    {
      category: "0",
      number: counts["0"] || 0,
    },
    {
      category: "2",
      number: counts["2"] || 0,
    },
    {
      category: "4",
      number: counts["4"] || 0,
    },
    {
      category: "6",
      number: counts["6"] || 0,
    },
    {
      category: "8",
      number: counts["8"] || 0,
    },
    {
      category: "10",
      number: counts["10"] || 0,
    },
    {
      category: "12",
      number: counts["12"] || 0,
    },
    {
      category: "14",
      number: counts["14"] || 0,
    },
    {
      category: "16",
      number: counts["16"] || 0,
    },
    {
      category: "18",
      number: counts["18"] || 0,
    },
    {
      category: "20",
      number: counts["20"] || 0,
    },
    {
      category: "22",
      number: counts["22"] || 0,
    },
    {
      category: "24",
      number: counts["24"] || 0,
    },
    {
      category: "26",
      number: counts["26"] || 0,
    },
  ];
  const maxValue = Math.max(...seriesData.map((value) => value.number));
  const options: ApexCharts.ApexOptions = {
    chart: {
      fontFamily: "Inter",
      type: "bar",
      height: 200,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,
        columnWidth: "95%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        dataLabels: {
          hideOverflowingLabels: true,
        },
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: seriesData.map((value) => value.category),
    },

    legend: {
      position: "bottom",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 500,

      markers: {
        shape: "circle",
      },
      //   offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      data: seriesData.map((item) => ({
        x: item.category,
        y: item.number,
        fillColor: item.number === maxValue ? "#2563EB" : "#BFDBFE",
      })),
    },
  ];
  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={200}
      />
    </>
  );
};

export default WorkforceExperienceGraph;
