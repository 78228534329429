import { Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS } from "../../../theme";
import { JobCategoriesResponse } from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import RadioInput from "../components/RadioInput";
import { TitleIdObjResponse } from "../../../types/commonType";

const yearsData = [
  "Intern (0-1 year)",
  "Junior (1-3 years)",
  "Mid-level (4-6 years)",
  "Senior (7 + years)",
  "Executive",
];

const YearsOfExperience = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [loading, setLoading] = useState<boolean>(false);
  const [yearOfExeperiance, setYearOfExeperiance] = useState<
    TitleIdObjResponse[]
  >([]);

  const handleChange = (value: JobCategoriesResponse) => {
    if (postOpportunity.job_priority_id === value.id) {
      const opportunityObj = {
        ...postOpportunity,
        job_years_experience_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_years_experience_id: value.id,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const fetchYearOfExpe = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getYearOfExeperience();
      setYearOfExeperiance(response.results);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchYearOfExpe();
  }, []);
  return (
    <>
      <JobCardWrapper
        header="How many years of experience?"
        subText="Select the experience level that corresponds with your project requirement"
      >
        <Stack pr={1} gap={2}>
          {loading
            ? Array.from(new Array(5)).map((_, index) => (
                <Stack
                  gap={2}
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    px: 2,
                    py: 1.5,
                    cursor: "pointer",
                  }}
                >
                  <Skeleton variant="circular" width={10} height={10} />

                  <Skeleton
                    variant="text"
                    width={300}
                    sx={{ fontSize: "1rem" }}
                  />
                </Stack>
              ))
            : yearOfExeperiance.map((item, index) => (
                <RadioInput
                  key={index}
                  title={item.title}
                  checked={postOpportunity.job_years_experience_id === item.id}
                  onSelect={() => handleChange(item)}
                />
              ))}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default YearsOfExperience;
