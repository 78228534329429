import { motion } from "framer-motion";

interface IAnimationProps {
  children: JSX.Element;
}

const AtsAnimation = ({ children }: IAnimationProps) => {
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",

      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <>
      <motion.div
        variants={showAnimation}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        {children}
      </motion.div>
    </>
  );
};

export default AtsAnimation;
