import { Box, Stack } from "@mui/material";
import AtsRoundedButton from "../../../../../../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  OnboardingStatusEnum,
  useOnboarding,
} from "../../../../../../../../providers/OnboardingProvider";
import ConfirmPosition from "./modules/ConfirmPosition";
import OnboardingSteps from "./modules/OnboardingSteps";
import OnboardingSuccess from "./modules/OnboardingSuccess";
import { setupEquipmentValidation } from "../../../../../../../../utils/validationUtils";
import { useAppSelector } from "../../../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../../../redux/slices/contractorSlice";

const OnboardingTab = () => {
  const {
    selectedOnboardingStep,
    onNext,
    onBack,
    createOnboardingState,
    createOnboardingLoading,
    setError,
    onCreateOnboarding,
    responsibilitiesLoading,
  } = useOnboarding();
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);

  const onNextFunc = () => {
    if (
      selectedOnboardingStep === OnboardingStatusEnum.ConfirmPositionDetails
    ) {
      onNext(OnboardingStatusEnum.SetupITEquipment);
    } else if (
      selectedOnboardingStep === OnboardingStatusEnum.SetupITEquipment
    ) {
      const errorState = setupEquipmentValidation(createOnboardingState);
      setError(errorState.error);
      if (errorState.isValid) {
        onNext(OnboardingStatusEnum.SelectPolicyDocuments);
      }
    } else if (
      selectedOnboardingStep === OnboardingStatusEnum.SelectPolicyDocuments
    ) {
      onNext(OnboardingStatusEnum.Responsibilites);
    } else if (
      selectedOnboardingStep === OnboardingStatusEnum.Responsibilites
    ) {
      onNext(OnboardingStatusEnum.OnboardingSchedule);
    } else {
      onNext(OnboardingStatusEnum.ConfirmPositionDetails);
    }
  };
  const onBackFunc = () => {
    if (selectedOnboardingStep === OnboardingStatusEnum.SetupITEquipment) {
      onBack(OnboardingStatusEnum.ConfirmPositionDetails);
    } else if (
      selectedOnboardingStep === OnboardingStatusEnum.SelectPolicyDocuments
    ) {
      onBack(OnboardingStatusEnum.SetupITEquipment);
    } else if (
      selectedOnboardingStep === OnboardingStatusEnum.Responsibilites
    ) {
      onBack(OnboardingStatusEnum.SelectPolicyDocuments);
    } else if (
      selectedOnboardingStep === OnboardingStatusEnum.OnboardingSchedule
    ) {
      onBack(OnboardingStatusEnum.Responsibilites);
    } else {
      onBack(OnboardingStatusEnum.ConfirmPositionDetails);
    }
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Box mb={"30px"}>
        <AtsTitleText text={"Onboarding"} fs={18} fw={600} />
      </Box>
      <Stack
        sx={{
          height: "calc(100% - 150px)",
        }}
      >
        {selectedOnboardingStep ===
        OnboardingStatusEnum.ConfirmPositionDetails ? (
          <ConfirmPosition />
        ) : selectedOnboardingStep ===
          OnboardingStatusEnum.OnboardingSuccess ? (
          <OnboardingSuccess />
        ) : (
          <OnboardingSteps />
        )}
      </Stack>
      <AtsRowBetween
        sx={{
          mt: "30px",
        }}
      >
        <>
          {selectedOnboardingStep !==
          OnboardingStatusEnum.ConfirmPositionDetails ? (
            <AtsRoundedButton
              text="Back"
              buttonVariant="outline"
              buttonHeight={34}
              buttonWidth={79}
              onClick={onBackFunc}
              disabled={responsibilitiesLoading}
              loading={responsibilitiesLoading}
            />
          ) : (
            <Box></Box>
          )}
          {selectedOnboardingStep ===
          OnboardingStatusEnum.OnboardingSchedule ? (
            <AtsRoundedButton
              text={isContractorOnboarded ? "Update" : "Send"}
              buttonHeight={34}
              buttonWidth={113}
              buttonVariant="darkGreenContained"
              onClick={() =>
                onCreateOnboarding(isContractorOnboarded ? "Update" : "Create")
              }
              disabled={createOnboardingLoading}
              loading={createOnboardingLoading}
            />
          ) : (
            <AtsRoundedButton
              text="Next"
              buttonHeight={34}
              buttonWidth={113}
              onClick={onNextFunc}
              disabled={responsibilitiesLoading}
              loading={responsibilitiesLoading}
            />
          )}
        </>
      </AtsRowBetween>
    </Stack>
  );
};

export default OnboardingTab;
