import { Box, Skeleton, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../theme";
import DonutChart from "./DonutChart";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProjectOverviewServices } from "../../../../../../Api/projectOverviewServices";
import {
  CandidatesDemographicsResponse,
  GraphData,
} from "../../../../../../types/projectOverviewTypes";
import AtsRowBetween from "../../../../../../components/AtsRowBetween";
import AtsRow from "../../../../../../components/AtsRow";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";

const initialGraphData: GraphData[] = [
  { title: "Years of experience", data: [] },
  { title: "Top job titles", data: [] },
  { title: "Locations", data: [] },
  { title: "Top skills", data: [] },
];

const CandidatesDemographics = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [candidatesDemographics, setCandidatesDemographics] = useState<
    GraphData[]
  >([]);
  const getCandidatesDemographics = async () => {
    setLoading(true);
    try {
      const response =
        await ProjectOverviewServices.getShortlistedCandidatesDemographics({
          project_id: id,
        });

      if (response.results.length) {
        const data = response.results[0];
        const colors = ["#2563EB", "#818CF8", "#38BDF8", "#93C5FD"];
        const graphData: GraphData[] = [
          { title: "Years of experience", data: [] },
          { title: "Top job titles", data: [] },
          { title: "Locations", data: [] },
          { title: "Top skills", data: [] },
        ];
        const bindData = (
          key: keyof CandidatesDemographicsResponse,
          graphIndex: number
        ): void => {
          const entries = Object.entries(data[key]);
          graphData[graphIndex].data = entries.map(
            ([labels, series], index) => ({
              labels,
              series,
              color: colors[index % colors.length],
            })
          );
        };

        bindData("experiences", 0);
        bindData("job_titles", 1);
        bindData("locations", 2);
        bindData("skills", 3);
        const updatredData = graphData.map((value) => {
          if (value.title === "Top skills") {
            const sortedData = value.data.sort((a, b) => b.series - a.series);
            const sliceTopSkills = sortedData.slice(0, 5);
            return {
              ...value,
              data: sliceTopSkills,
            };
          }
          return value;
        });
        setCandidatesDemographics(updatredData);
      } else {
        setCandidatesDemographics([]);
      }
    } catch (error) {
      console.error(error);
      setCandidatesDemographics([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getCandidatesDemographics();
  }, []);
  console.log("candidatesDemographics", candidatesDemographics);
  return (
    <>
      <Box mb={"37px"} mt={"50px"}>
        <AtsTitleText
          text={"Saved candidates demographics"}
          fs={16}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Box>
      <Stack direction={"row"} flexWrap={"wrap"}>
        {loading
          ? initialGraphData.map((data, index) => (
              <Stack
                key={index}
                sx={{
                  flex: {
                    xs: "1 1 100%",
                    sm: "1 1 calc(50% - 1px)",
                    md: "1 1 calc(33.33% - 1px)",
                    // lg: "1 1 calc(25% - 1px)",
                    // xl: "1 1 calc(20% - 1px)",
                  },
                  minWidth: {
                    xs: "100%",
                    sm: "calc(50% - 1px)",
                    md: "calc(33.33% - 1px)",
                    // lg: "calc(25% - 1px)",
                    // xl: "calc(20% - 1px)",
                  },
                  px: {
                    xs: "20px",
                    sm: "30px",
                    md: "40px",
                    lg: "50px",
                    xl: "60px",
                  },
                  py: {
                    xs: "10px",
                    sm: "15px",
                    md: "20px",
                    lg: "25px",
                    xl: "30px",
                  },
                  ...((index === 0 || index === 1) && {
                    borderBottom: BORDERS.GRAY,
                  }),
                  ...((index === 0 || index === 2) && {
                    borderRight: { sm: BORDERS.GRAY, xs: "none" },
                  }),
                }}
              >
                <Box textAlign={"center"} my={"30px"}>
                  <AtsTitleText
                    text={data.title}
                    fs={14}
                    fw={600}
                    textColor={"#000"}
                  />
                </Box>
                <Skeleton variant="rounded" width={365} height={145} />
              </Stack>
            ))
          : candidatesDemographics.length > 0 &&
            candidatesDemographics.map((data, index) => (
              <Stack
                key={index}
                sx={{
                  flex: {
                    xs: "1 1 100%",
                    sm: "1 1 calc(50% - 1px)",
                    md: "1 1 calc(33.33% - 1px)",
                    // lg: "1 1 calc(25% - 1px)",
                    // xl: "1 1 calc(20% - 1px)",
                  },
                  minWidth: {
                    xs: "100%",
                    sm: "calc(50% - 1px)",
                    md: "calc(33.33% - 1px)",
                    // lg: "calc(25% - 1px)",
                    // xl: "calc(20% - 1px)",
                  },
                  px: {
                    xs: "20px",
                    sm: "30px",
                    md: "40px",
                    lg: "50px",
                    xl: "60px",
                  },
                  py: {
                    xs: "10px",
                    sm: "15px",
                    md: "20px",
                    lg: "25px",
                    xl: "30px",
                  },
                  ...((index === 0 || index === 1) && {
                    borderBottom: BORDERS.GRAY,
                  }),
                  ...((index === 0 || index === 2) && {
                    borderRight: { sm: BORDERS.GRAY, xs: "none" },
                  }),
                }}
              >
                <Box textAlign={"center"} my={"30px"}>
                  <AtsTitleText
                    text={data.title}
                    fs={14}
                    fw={600}
                    textColor={"#000"}
                  />
                </Box>
                {data?.data.length ? (
                  <AtsRowBetween>
                    <>
                      <Box
                        sx={{
                          width: "50%",
                        }}
                      >
                        <DonutChart
                          key={index}
                          title={data.title || ""}
                          series={data?.data.map((value) => value.series) || []}
                          labels={data?.data.map((value) => value.labels) || []}
                          colors={data?.data.map((value) => value.color) || []}
                        />
                      </Box>
                      <Stack
                        className="scrollHide"
                        gap={"10px"}
                        sx={{
                          width: "50%",
                          maxHeight: 150,
                          overflowY: "auto",
                        }}
                      >
                        {data?.data.map((value, index) => (
                          <AtsRow gap={"10px"} key={index}>
                            <>
                              <Box
                                sx={{
                                  bgcolor: value.color,
                                  width: 13,
                                  minWidth: 13,
                                  height: 13,
                                  borderRadius: "50%",
                                }}
                              ></Box>
                              <AtsRow gap={"5px"}>
                                <>
                                  <AtsTitleText
                                    text={value.labels}
                                    fs={12}
                                    fw={500}
                                    textColor={COLORS.DARK_GRAY}
                                  />
                                </>
                              </AtsRow>
                            </>
                          </AtsRow>
                        ))}
                      </Stack>
                    </>
                  </AtsRowBetween>
                ) : (
                  <AtsEmptyState
                    textFs={12}
                    imgHeight={50}
                    text={`No ${data.title} data`}
                    subText=""
                  />
                )}
              </Stack>
            ))}
      </Stack>
    </>
  );
};

export default CandidatesDemographics;
