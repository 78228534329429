import React, { ReactNode } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { Stack } from "@mui/material";

interface IProps {
  header: string;
  subText?: string;
  children: JSX.Element;
  renderBottomView?: () => ReactNode;
}

const JobCardWrapper = ({
  header,
  subText,
  children,
  renderBottomView,
}: IProps) => {
  return (
    <>
      <Stack gap={"18px"} sx={{ height: "100%" }}>
        <AtsTitleText text={header} fw={600} fs={20} />
        {subText && <AtsTitleText text={subText} fs={14} fw={400} />}
        <Stack
          gap={2}
          pt={1}
          sx={{
            height: "calc(100% - 50px)",
            overflow: "auto",
          }}
        >
          {children}
        </Stack>
        {renderBottomView && renderBottomView()}
      </Stack>
    </>
  );
};

export default JobCardWrapper;
