import { Box, Stack } from "@mui/material";
import moment from "moment";
import { ComponentType, useMemo } from "react";
import {
  Calendar,
  CalendarProps,
  Components,
  EventProps,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AtsTitleText from "../../../components/AtsTitleText";
import { useCalendarAvailability } from "../../../providers/CalendarAvailabilityProvider";
import { COLORS } from "../../../theme";
import {
  AppointmentStatusCode,
  EventItem,
  InvoiceDueEventsResponse,
} from "../../../types/calendarTypes";
import AppointmentEvent from "../components/AppointmentEvent";
import "../css/calendar.css";
import { AtsThreeDotsWaves } from "../../../components/AtsThreeDotsWaves";
import DetailsDrawer from "../../Jobs/Submissions/pipeline/Candidate/CandidateDetails/DetailsDrawer";

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

const localizer = momentLocalizer(moment);

// export const EVENTS: EventItem[] = [
//   {
//     start: moment("2024-11-26T10:56:00").toDate(),
//     end: moment("2024-11-26T11:56:00").toDate(),
//     data: {
//       appointment: {
//         id: 1,
//         status: AppointmentStatusCode.Overdue,
//         text: "Invoice Overdue",
//         length: "(2)",
//       },
//     },
//     resourceId: 1,
//     allDay: true,
//   },
//   {
//     start: moment("2024-08-26T10:30:00").toDate(),
//     end: moment("2024-08-26T11:15:00").toDate(),
//     data: {
//       appointment: {
//         id: 2,
//         status: AppointmentStatusCode.due,
//         text: "Invoice Due",
//         length: "",
//       },
//     },
//     resourceId: 2,
//     allDay: true,
//   },
//   {
//     start: moment("2024-08-26T10:30:00").toDate(),
//     end: moment("2024-08-26T11:15:00").toDate(),
//     data: {
//       appointment: {
//         id: 2,
//         status: AppointmentStatusCode.due,
//         text: "Invoice Due",
//         length: "",
//       },
//     },
//     resourceId: 3,
//     allDay: true,
//   },

//   {
//     start: moment("2024-08-27T10:30:00").toDate(),
//     end: moment("2024-08-27T11:15:00").toDate(),
//     data: {
//       appointment: {
//         id: 2,
//         status: AppointmentStatusCode.contractStart,
//         text: "Contract Start",
//         length: "(2)",
//       },
//     },
//     resourceId: 5,
//     allDay: true,
//   },
//   {
//     start: moment("2024-11-28").toDate(),
//     end: moment("2024-11-28").toDate(),
//     data: {
//       appointment: {
//         id: 2,
//         status: AppointmentStatusCode.contractEnd,
//         text: "Contract End",
//         length: "",
//       },
//     },
//     resourceId: 6,
//     allDay: true,
//   },
//   {
//     start: moment("2024-11-26T10:56:00").toDate(),
//     end: moment("2024-11-26T11:56:00").toDate(),
//     data: {
//       appointment: {
//         id: 2,
//         status: AppointmentStatusCode.interview,
//         text: "CTO - Interview",
//         length: "",
//       },
//     },
//     resourceId: 7,
//     allDay: false,
//   },
//   {
//     start: moment("2024-08-29T09:30:00").toDate(),
//     end: moment("2024-08-29T10:15:00").toDate(),
//     data: {
//       appointment: {
//         id: 2,
//         status: AppointmentStatusCode.interview,
//         text: "CTO - Interview",
//         length: "",
//       },
//     },
//     resourceId: 8,
//     allDay: false,
//   },
// ];
interface CustomComponents extends Components<any, object> {
  event?: ComponentType<EventProps<EventItem>>;
  week?: {
    header?: ComponentType<any>;
  };
}
const CalendarView = (props: Omit<CalendarProps, "localizer">) => {
  const {
    date,
    calendarView,
    setCalendarView,
    setDate,
    interviewEvents,
    contractsEvents,
    invoiceEventLoading,
    contractsEventLoading,
    interviewEventLoading,
    invoiceEvents,
  } = useCalendarAvailability();
  const selectedEvent = (e) => {
    console.log("e", e);
  };

  const interviewEventsData = interviewEvents.map((item, index) => {
    const start = moment.utc(item.interview_date_time).toDate();
    const end = moment(start)
      .add(parseInt(item.interview_duration, 10), "minutes")
      .toDate();
    return {
      start,
      end,
      data: {
        appointment: {
          id: item.nid,
          cadidateData: interviewEvents.filter(
            (value) => value.nid === item.nid
          ),
          status: AppointmentStatusCode.interview,
          text: item.interview_subject || "Interview",
          length: ``,
        },
      },
      resourceId: `${item.nid}${index}`,
      allDay: false,
    };
  });

  console.log("interviewEvents", interviewEvents, interviewEventsData);
  const contractEvents = contractsEvents.flatMap((entry) => [
    ...entry.contract_start.map((contract) => ({
      start: moment(contract.contract_start_date).toDate(),
      end: moment(contract.contract_start_date).toDate(),
      data: {
        appointment: {
          id: contract.nid,
          cadidateData: entry.contract_start,
          status: AppointmentStatusCode.contractStart,
          text: "Contract Start",
          length: `(${entry.contract_start.length})`,
        },
      },
      resourceId: contract.nid,
      allDay: true,
    })),
    ...entry.contract_end.map((contract) => ({
      start: moment(contract.contract_end_date).toDate(),
      end: moment(contract.contract_end_date).toDate(),
      data: {
        appointment: {
          id: contract.nid,
          cadidateData: entry.contract_end,
          status: AppointmentStatusCode.contractEnd,
          text: "Contract End",
          length: `(${entry.contract_end.length})`,
        },
      },
      resourceId: contract.nid,
      allDay: true,
    })),
  ]);

  const groupInvoicesByDate = (invoices: any[]) => {
    const sameDateMap: Record<string, any[]> = {};
    const uniqueData: any[] = [];

    invoices.forEach((invoice) => {
      const dueDate = moment
        .unix(parseInt(invoice.due_date, 10))
        .startOf("day");
      const dueDateKey = dueDate.toISOString();

      const eventData = {
        start: dueDate.toDate(),
        end: dueDate.toDate(),
        data: {
          appointment: {
            id: invoice.invoice_id,
            cadidateData: invoice,
            status: "DUE",
            text: "Invoice Due",
            length: "",
          },
        },
        resourceId: invoice.invoice_id,
        allDay: true,
      };

      if (!sameDateMap[dueDateKey]) {
        sameDateMap[dueDateKey] = [];
      }
      sameDateMap[dueDateKey].push(eventData);
    });

    const sameDate = [];
    Object.keys(sameDateMap).forEach((key) => {
      if (sameDateMap[key].length > 1) {
        sameDate.push(...sameDateMap[key]);
      } else {
        uniqueData.push(sameDateMap[key][0]);
      }
    });

    return [
      {
        sameDate,
        uniqueData,
      },
    ];
  };

  const currentDate = new Date();

  const overdueInvoices = invoiceEvents.filter((invoice) => {
    const dueDate = new Date(parseInt(invoice.due_date) * 1000);

    return dueDate < currentDate;
  });
  const dueInvoices = invoiceEvents.filter((invoice) => {
    const dueDate = new Date(parseInt(invoice.due_date) * 1000);

    return dueDate > currentDate;
  });
  const dueInvoiceData = groupInvoicesByDate(dueInvoices);
  const overdueInvoiceData = groupInvoicesByDate(overdueInvoices);
  const dueInvoiceEventsData = dueInvoiceData.flatMap((entry) => {
    const createEvent = (dateGroup, groupName) => {
      if (!dateGroup || dateGroup.length === 0) return null;

      const groupedData = {
        start: dateGroup[0].start,
        end: dateGroup[0].start,
        data: {
          appointment: {
            id: groupName,
            cadidateData: dateGroup,
            status: AppointmentStatusCode.due,
            text: "Invoice Due",
            length: `(${dateGroup.length})`,
          },
        },
        resourceId: groupName,
        allDay: true,
      };

      return groupedData;
    };

    return [
      createEvent(entry.sameDate, "sameDate"),
      createEvent(entry.uniqueData, "uniqueData"),
    ].filter(Boolean);
  });
  const overdueInvoiceEventsData = overdueInvoiceData.flatMap((entry) => {
    const createEvent = (dateGroup, groupName) => {
      if (!dateGroup || dateGroup.length === 0) return null;

      const groupedData = {
        start: dateGroup[0].start,
        end: dateGroup[0].start,
        data: {
          appointment: {
            id: groupName,
            cadidateData: dateGroup,
            status: AppointmentStatusCode.Overdue,
            text: "Invoice Overdue",
            length: `(${dateGroup.length})`,
          },
        },
        resourceId: groupName,
        allDay: true,
      };

      return groupedData;
    };

    return [
      createEvent(entry.sameDate, "sameDate"),
      createEvent(entry.uniqueData, "uniqueData"),
    ].filter(Boolean);
  });
  const EVENTS: EventItem[] = [
    ...interviewEventsData,
    ...contractEvents,
    ...dueInvoiceEventsData,
    ...overdueInvoiceEventsData,
  ];
  const CustomWeekHeader = ({ label }) => {
    const [date, day] = label.split(" ");
    return (
      <Stack gap={"4px"}>
        <Box
          className="dateButton"
          sx={{
            width: 44,
            height: 44,
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AtsTitleText className="colorText" text={date} fw={600} fs={16} />
        </Box>
        <AtsTitleText
          className="dayText"
          text={day}
          fw={400}
          fs={14}
          textColor={COLORS.LIGHT_GRAY}
        />
      </Stack>
    );
  };

  const components: CustomComponents = {
    event: ({ event }: EventProps<EventItem>) => {
      const data = event?.data;
      if (data?.appointment)
        return (
          <AppointmentEvent
            appointment={event}
            isMonthView={calendarView === Views.MONTH}
            isAllday={event?.allDay}
          />
        );

      return null;
    },
    week: {
      header: CustomWeekHeader, // Use the custom week header component
    },
  };
  const candidatDrawerMemo = useMemo(() => <DetailsDrawer />, []);
  return (
    <>
      <div
        className=""
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        {(invoiceEventLoading ||
          interviewEventLoading ||
          contractsEventLoading) && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(255, 255, 255, 0.8)",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AtsThreeDotsWaves />
          </div>
        )}
        <Calendar
          {...props}
          localizer={localizer}
          views={[Views.WEEK, Views.MONTH]}
          events={EVENTS}
          defaultDate={"2022-10-10"}
          defaultView={Views.WEEK}
          min={moment("2022-10-10T09:00:00").toDate()}
          max={moment("2022-10-10T23:00:00").toDate()}
          onSelectEvent={selectedEvent}
          // resources={view === Views.DAY ? RESOURCES : undefined}
          // Custom Props

          // Components
          components={components}
          // Toolbar
          toolbar={false}
          date={date}
          view={calendarView}
          onView={setCalendarView}
          onNavigate={setDate}
          startAccessor="start"
          endAccessor="end"
          // step={STEP}
          // timeslots={TIME_SLOTS}
        />
      </div>
      {candidatDrawerMemo}
    </>
  );
};

export default CalendarView;
