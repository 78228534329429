import { ExpandMore, HighlightOffRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  IconButton,
  Stack,
} from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { setIsQuestionRoute } from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch } from "../../../redux/store";
import { COLORS } from "../../../theme";
import AtsTextArea from "../../../components/AtsTextArea";

const frequentQuestions = [
  {
    header: "Does posting a job obligate me to hire someone?",
    des: "No, posting a job on Colleagues doesn't mean you are obligated to hire someone. But we think you'll find who you're looking for on Colleagues.",
  },
  {
    header: "How soon should I expect to find someone?",
    des: "No, posting a job on Colleagues doesn't mean you are obligated to hire someone. But we think you'll find who you're looking for on Colleagues.",
  },
  {
    header: "What is an Opportunity Marketplace?",
    des: "No, posting a job on Colleagues doesn't mean you are obligated to hire someone. But we think you'll find who you're looking for on Colleagues.",
  },
];

const Question = () => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        maxWidth: "450px",
        mx: "auto",
        mt: 5,
        height: "calc(100% - 40px)",
        px: 1,
      }}
    >
      <Stack gap={2} pt={5}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText text={"How can we help?"} fw={600} fs={20} />
          <IconButton onClick={() => dispatch(setIsQuestionRoute(false))}>
            <HighlightOffRounded sx={{ color: COLORS.LIGHT_GRAY }} />
          </IconButton>
        </Stack>
        <AtsTitleText text={"Frequent questions"} fw={600} fs={"0.875rem"} />
        <Stack mt={2}>
          {frequentQuestions.map((question, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  color: COLORS.LIGHT_GRAY,
                }}
              >
                {question.header}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "0.675rem",
                  fontWeight: 500,
                  color: COLORS.LIGHT_GRAY,
                }}
              >
                {question.des}
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Stack mt={3} gap={1}>
          <AtsTextArea
            isBlackText
            placeholder="Let us know what questions you have, and how we can help?"
            header="Message us"
            cols={12}
            rows={4}
            className="textArea"
          />
          <AtsTitleText
            isTextEnd
            text={`0/500`}
            fs={"0.625rem"}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default Question;
