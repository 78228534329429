import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRow from "../../../components/AtsRow";
import { COLORS } from "../../../theme";
import { UserUpdateRequest } from "../../../types/userManagementTypes";
import linkdinImg from "../../../assets/images/linkdin.jpg";
import JobTitleSelector from "../../PostOpportunity/components/JobTitleSelector";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";

const MyPersonalInfo = () => {
  const {
    accountDetailsState,
    handleAccountDetailsChange,
    updateUserLoading,
    updateUserDetails,
    accountDetailsError,
  } = useCreateAccount();

  const renderTextInputComponent = (
    title: string,
    value: string | number,
    key: keyof UserUpdateRequest,
    handleChange: (key: string, value: string) => void,
    updateUserLoading?: boolean
  ) => {
    return (
      <Stack gap={"5px"}>
        <AtsRow gap={"5px"}>
          <>
            {key === "linkedin_profile_url" && (
              <img width={16} height={16} src={linkdinImg} alt="images" />
            )}
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
            >
              {title}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>
          </>
        </AtsRow>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {key === "job_title" ? (
            <JobTitleSelector
              height="41px"
              isHideSearchIcon
              isShowDropdownIcon
              selected={value as string}
              onSelected={(select) => handleChange(key, select)}
            />
          ) : (
            <AtsTextInput
              placeholder={`Enter ${title}`}
              height="41px"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          )}
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={accountDetailsError[key]}
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
    );
  };
  return (
    <>
      <Stack gap={"30px"}>
        <Box px={"10px"}>
          <AtsTitleText
            text={"Personal Information"}
            fs={18}
            fw={600}
            textColor="#000"
          />
        </Box>
        <Grid container rowSpacing={"30px"} columnSpacing={"40px"} px={"10px"}>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "First name",
              accountDetailsState?.first_name ?? "",
              "first_name",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Last name",
              accountDetailsState?.last_name ?? "",
              "last_name",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Title",
              accountDetailsState?.job_title ?? "",
              "job_title",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "LinkedIn profile",
              accountDetailsState?.linkedin_profile_url ?? "",
              "linkedin_profile_url",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextInputComponent(
              "Phone no.",
              accountDetailsState?.phone_number ?? "",
              "phone_number",
              handleAccountDetailsChange,
              updateUserLoading
            )}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default MyPersonalInfo;
