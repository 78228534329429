import axios from "axios";
import { PaginatedResponse } from "../types/commonType";
import {
  AssessmentsResponse,
  CandidateDetailsProps,
  CandidateRattingListParams,
  CandidateRattingReviewListResponse,
  CandidateSkillsMatchParams,
  CandidateSkillsMatchResponse,
  GetCandidateDetialsParamsRequest,
  GetSubmissionParamsRequest,
  GetSubmissionResponse,
  LogoutParamRequest,
  RatingSummary,
  Skill,
  UpdateRateOptionRequest,
} from "../types/submissionTypes";
import { PerformanceReviewRequest } from "../types/contractorTypes";

const Endpoint = {
  BASE: "/ats/api/v1/",
  SUBMISSIONS: "job-submissions",
  LOGOUT: "user/logout?_format=json",
  CADIDATE_ASSESSMENTS: "get-candidate-assessments?_format=json",
  CANDIDAET_DETAILS_BY_ID: "candidate/get-by-id",
  CANDIDAET_SKILLS_MATCH: "candidate-skills-match",
  CANDIDATE_RATTING: "candidate-reviews",
  CANDIDATE_RATTING_SUMMARY: "candidate-reviews-summary",
  RATE_OPTION: "rate-options",
  TOP_COUNT: "top-candidate-other-jobs-count",
  UPDATE_RATE_OPTION: "candidate-rate/create-update",
  UPDATE_CANDIDATE_REVIEW: "candidate-review/update",
  CREATE_CANDIDATE_REVIEW: "candidate-review/create",
  UPDATE_CLIENT_SUBMISSION_REVIEW: "client-submission/review/update",
};

const SUBMISSIONS_URL = Endpoint.BASE + Endpoint.SUBMISSIONS;
const CANDIDATE_RATTING_URL = Endpoint.BASE + Endpoint.CANDIDATE_RATTING;
const RATE_OPTION_URL = Endpoint.BASE + Endpoint.RATE_OPTION;
const TOP_COUNT_URL = Endpoint.BASE + Endpoint.TOP_COUNT;
const UPDATE_RATE_OPTION_URL = Endpoint.BASE + Endpoint.UPDATE_RATE_OPTION;
const CANDIDATE_RATTING_SUMMARY_URL =
  Endpoint.BASE + Endpoint.CANDIDATE_RATTING_SUMMARY;
const CANDIDAET_SKILLS_MATCH_URL =
  Endpoint.BASE + Endpoint.CANDIDAET_SKILLS_MATCH;
const CANDIDAET_DETAILS_BY_ID_URL =
  Endpoint.BASE + Endpoint.CANDIDAET_DETAILS_BY_ID;
const CADIDATE_ASSESSMENTS_URL = Endpoint.BASE + Endpoint.CADIDATE_ASSESSMENTS;
const UPDATE_CANDIDATE_REVIEW_URL =
  Endpoint.BASE + Endpoint.UPDATE_CANDIDATE_REVIEW;
const CREATE_CANDIDATE_REVIEW_URL =
  Endpoint.BASE + Endpoint.CREATE_CANDIDATE_REVIEW;

const UPDATE_CLIENT_SUBMISSION_REVIEW_URL =
  Endpoint.BASE + Endpoint.UPDATE_CLIENT_SUBMISSION_REVIEW;

export class SubmissionService {
  static async getSubmissionByJobId(
    params: Partial<GetSubmissionParamsRequest>
  ): Promise<PaginatedResponse<GetSubmissionResponse>> {
    const endpoint = SUBMISSIONS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getCandidateDetailsById(
    params: Partial<GetCandidateDetialsParamsRequest>
  ): Promise<PaginatedResponse<CandidateDetailsProps>> {
    const endpoint = CANDIDAET_DETAILS_BY_ID_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getCandidateSkillsMatch(
    params: Partial<CandidateSkillsMatchParams>
  ): Promise<CandidateSkillsMatchResponse> {
    const endpoint = CANDIDAET_SKILLS_MATCH_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateRattingList(
    params: Partial<CandidateRattingListParams>
  ): Promise<PaginatedResponse<CandidateRattingReviewListResponse>> {
    const endpoint = CANDIDATE_RATTING_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateRattingSummary(
    params: Partial<CandidateSkillsMatchParams>
  ): Promise<RatingSummary> {
    const endpoint = CANDIDATE_RATTING_SUMMARY_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getRateOptions(): Promise<PaginatedResponse<Skill>> {
    const endpoint = RATE_OPTION_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getTopCountCandidate(
    params: Partial<CandidateSkillsMatchParams>
  ): Promise<{ results: number }> {
    const endpoint = TOP_COUNT_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getAssessmentsOfCandidate(
    params: Partial<CandidateSkillsMatchParams>
  ): Promise<PaginatedResponse<AssessmentsResponse>> {
    const endpoint = CADIDATE_ASSESSMENTS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateRateOptions(request: UpdateRateOptionRequest) {
    const endpoint = UPDATE_RATE_OPTION_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createCandidateReview(
    request: Partial<PerformanceReviewRequest>
  ) {
    const endpoint = CREATE_CANDIDATE_REVIEW_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateCandidateReview(request: PerformanceReviewRequest) {
    const endpoint = UPDATE_CANDIDATE_REVIEW_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async logOut(params: Partial<LogoutParamRequest>) {
    const endpoint = Endpoint.LOGOUT;
    const csrf_token = localStorage.getItem("csrf_token");
    const response = await axios.get(endpoint, {
      params,
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": `${csrf_token}`,
      },
    });
    return response.data;
  }

  static async updateClientSubmissionReview(request: { id: string }) {
    const endpoint = UPDATE_CLIENT_SUBMISSION_REVIEW_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
