import axios from "axios";
import {
  ChatProps,
  CreateConversationRequest,
  CreateGroupRequest,
  GroupUsers,
  MessagesResponse,
  SendMessageReaponse,
  SendMessageRequest,
} from "../types/messagesTypes";
import { COLLEAGUES_DEV_ENDPOINT } from "../utils/url";

const Endpoint = {
  COLLEAGGUES_BASE: `${COLLEAGUES_DEV_ENDPOINT}/v1/`,
  SEND_MESSAGE: "message/",
  CONVERSATION: "conversation",
  CREATE_OR_UPDATE_GROUP: "conversation/group",
  CONVERSATIONS_BY_ID: "message/by/conversation",
  USER_LIST: "users/list",
};

const USER_LIST_URL = Endpoint.COLLEAGGUES_BASE + Endpoint.USER_LIST;
const CONVERSATION_URL = Endpoint.COLLEAGGUES_BASE + Endpoint.CONVERSATION;
const CREATE_OR_UPDATE_GROUP_URL =
  Endpoint.COLLEAGGUES_BASE + Endpoint.CREATE_OR_UPDATE_GROUP;
const CONVERSATIONS_BY_ID_URL =
  Endpoint.COLLEAGGUES_BASE + Endpoint.CONVERSATIONS_BY_ID;
const SEND_MESSAGE_URL = Endpoint.COLLEAGGUES_BASE + Endpoint.SEND_MESSAGE;

export class MessageServices {
  static async getMessengersList(): Promise<ChatProps[]> {
    const response = await axios.get(CONVERSATION_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Authorization: `Bearer ${dummyToken}`,
      },
    });
    return response.data;
  }

  static async createConversation(
    request: CreateConversationRequest
  ): Promise<ChatProps> {
    const URL = CONVERSATION_URL;
    const response = await axios.post(URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Authorization: `Bearer ${dummyToken}`,
      },
    });
    return response.data;
  }

  static async getConversationsByMessengerId(
    id: string
  ): Promise<MessagesResponse> {
    const URL = `${CONVERSATIONS_BY_ID_URL}/${id}`;
    const response = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Authorization: `Bearer ${dummyToken}`,
      },
    });
    return response.data;
  }

  static async sendMessage(
    request: Partial<SendMessageRequest>
  ): Promise<SendMessageReaponse> {
    const endpoint = SEND_MESSAGE_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Authorization: `Bearer ${dummyToken}`,
      },
    });
    return response.data;
  }

  static async createGroup(request: Partial<CreateGroupRequest>) {
    const endpoint = CREATE_OR_UPDATE_GROUP_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Authorization: `Bearer ${dummyToken}`,
      },
    });
    return response.data;
  }

  static async updateGroup(request: Partial<CreateGroupRequest>) {
    const endpoint = `${CREATE_OR_UPDATE_GROUP_URL}/${request.id}`;
    const response = await axios.put(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Authorization: `Bearer ${dummyToken}`,
      },
    });
    return response.data;
  }

  static async userList(): Promise<GroupUsers[]> {
    const endpoint = `${USER_LIST_URL}`;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
