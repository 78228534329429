import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Skeleton,
  Stack,
} from "@mui/material";

import FlagredIcon from "../../../src/assets/icons/flagRed.svg";
import ThreeDotIcon from "../../../src/assets/icons/threeDot.svg";

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobsService } from "../../Api/jobsListingServices";
import AtsCard from "../../components/AtsCard";
import AtsIcon from "../../components/AtsIcon";
import AtsIconLabel from "../../components/AtsIconLabel";
import AtsRoundedButton from "../../components/AtsRoundedButton";
import AtsTitleText from "../../components/AtsTitleText";
import { getJobState } from "../../redux/slices/jobSlice";
import { useAppSelector } from "../../redux/store";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import {
  JobCategoriesResponse,
  JobDetailsResponse,
} from "../../types/jobsTypes";
import {
  commaSeparatedAddress,
  isNotNullOrUndefined,
} from "../../utils/stringUtils";
import { getJobStatus } from "../Dashboard/PostedJobs/PostedCard";
import AboutCompany from "./allJobs/jobDetails/AboutCompany";
import JobBenefits from "./allJobs/jobDetails/JobBenefits";
import JobCounts from "./allJobs/jobDetails/JobCounts";
import JobSalary from "./allJobs/jobDetails/JobSalary";
import { renderDateComponent, renderJobDataComponent } from "./Job";
import JobDetailsLoadingPage from "./JobDetailsLoadingPage";
import { MoreVert } from "@mui/icons-material";
import AtsCollapse from "../../components/AtsCollapse";
import AtsCustomButton from "../../components/AtsCustomButton";
import { Edit2 } from "iconsax-react";
import { useClickOutSide } from "../../utils/common";
import { ATS_ROUTES } from "../../router/routes";

interface IProps {
  selectedJob: string;
}

const JobDetails = ({ selectedJob }: IProps) => {
  const navigate = useNavigate();
  const { statisticsCount } = useAppSelector(getJobState);
  const [loading, setLoading] = useState<boolean>(false);
  const [jobDetails, setJobDetails] = useState<JobDetailsResponse | null>(null);
  const [jobBenifits, setJobBenifits] = useState<JobCategoriesResponse[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  const toggleCollapse = () => {
    setShow(!show);
  };

  const getJobDetails = async (nid: string) => {
    setLoading(true);

    try {
      const response = await JobsService.getJobDetailsById({ nid });
      if (response.results.length) {
        const details = response.results[0];
        if (details.benefits.length) {
          const iconMap = {
            Dental: "icon-profile-tick",
            Healthcare: "icon-lifebuoy",
            Vision: "icon-glass-1",
            "Life Insurance": "icon-lifebuoy",
            "Stock Options": "",
            "Tuition Reimbursement": "icon-calendar",
            Medical: "icon-health",
            "Family leave": "icon-layer",
            "Extended leave": "icon-verify",
            "401k ": "icon-chart-square",
            Prescriptions: "icon-clipboard-text",
            "Concierge ": "icon-profile-2user",
            Education: "icon-award",
            Gym: "icon-weight-1",
            "Paid time off": "icon-calendar-tick",
            Retreats: "icon-map",
            "Well being": "icon-bubble",
            "Long-term Disability": "icon-calendar",
            "401K": "icon-chart-square",
            Concierge: "icon-profile-2user",
            test: "icon-calendar",
          };
          const resposeWithIcon = details.benefits.map((category) => {
            const icon = iconMap[category];
            return icon ? { title: category, icon } : { title: category };
          });
          setJobBenifits(resposeWithIcon);
        }
        setJobDetails(details);
      } else {
        setJobDetails(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedJob.length) {
      getJobDetails(selectedJob);
    } else {
      setJobDetails(null);
    }
  }, [selectedJob]);

  const aboutCompanyMemo = useMemo(() => <AboutCompany />, []);

  return (
    <>
      {loading ? (
        <JobDetailsLoadingPage />
      ) : (
        <Stack
          gap={1}
          sx={{
            pb: "3px",
          }}
        >
          {jobDetails && (
            <AtsCard isOnlyBorder>
              <Box
                sx={{
                  px: 3,
                  py: 2,
                }}
              >
                <Stack gap={2}>
                  <Stack
                    direction={"row"}
                    sx={{}}
                    alignItems={"center"}
                    gap={3}
                    py={0}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"21px"}>
                      <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                        {loading ? (
                          <Box sx={{ width: "100%" }}>
                            <Skeleton width={60} />
                          </Box>
                        ) : (
                          <>
                            <Box
                              sx={{
                                width: "6px",
                                height: "6px",
                                bgcolor: getJobStatus(jobDetails?.job_status)
                                  .color,
                                borderRadius: "20px",
                              }}
                            ></Box>
                            <AtsTitleText
                              fs={11}
                              fw={700}
                              textColor={
                                getJobStatus(jobDetails?.job_status).color
                              }
                              text={getJobStatus(jobDetails?.job_status).text}
                            />
                          </>
                        )}
                      </Stack>
                      {loading ? (
                        <Box sx={{ width: "100%" }}>
                          <Skeleton width={100} />
                        </Box>
                      ) : (
                        jobDetails?.job_priority === "High" && (
                          <>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={0.5}
                            >
                              <img src={FlagredIcon} alt="Nvidia Logo" />
                              <AtsTitleText
                                fs={11}
                                fw={700}
                                textColor="#da4545"
                                text="HIGH PRIORITY"
                              />
                            </Stack>
                          </>
                        )
                      )}
                    </Stack>

                    <Box ref={domNode} sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          p: 0,
                        }}
                        color="inherit"
                        onClick={() => toggleCollapse()}
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                      <Box
                        sx={{
                          position: "absolute",
                          bgcolor: "white",
                          right: "0px",
                          top: "30px",
                          zIndex: 1,
                          width: "fit-content",
                          borderRadius: "6px",
                        }}
                      >
                        <AtsCollapse in={show}>
                          <Box
                            sx={{
                              border: BORDERS.GRAY,
                              borderRadius: "6px",
                              boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                              padding: "10px",
                              bgcolor: "white",
                            }}
                          >
                            <Stack gap={1}>
                              <AtsCustomButton
                                width={"auto"}
                                hoverColor={BG_COLORS.PRIMARY}
                                // onClick={toggleDrawer}
                                onClick={() => {
                                  const jobByIdRoutes =
                                    ATS_ROUTES.POST_OPPORTUNITY_BY_ID.replace(
                                      ":jobId",
                                      jobDetails?.nid
                                    );
                                  navigate(jobByIdRoutes);
                                }}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={1}
                                >
                                  <Edit2 size={12} />
                                  <AtsTitleText text="Edit" fs={12} fw={600} />
                                </Stack>
                              </AtsCustomButton>
                            </Stack>
                          </Box>
                        </AtsCollapse>
                      </Box>
                    </Box>
                  </Stack>

                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    {loading ? (
                      <Box sx={{ width: "100%" }}>
                        <Skeleton width={150} />
                      </Box>
                    ) : (
                      <AtsTitleText fs={22} fw={600} text={jobDetails?.title} />
                    )}
                  </Stack>
                  {renderDateComponent(
                    loading,
                    "Starts",
                    jobDetails?.job_start_date,
                    12
                  )}
                  <Stack
                    direction={"row"}
                    rowGap={"10px"}
                    flexWrap={"wrap"}
                    // sx={{ width: "100%", minHeight: "36px" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      columnGap={5}
                      rowGap={"10px"}
                      flexWrap={"wrap"}
                    >
                      {renderJobDataComponent(
                        loading,
                        "location",
                        commaSeparatedAddress({
                          location: jobDetails.job_address_locality,
                          country: jobDetails?.job_address_country,
                        })
                      )}
                      {isNotNullOrUndefined(jobDetails?.hours_per_week) &&
                        renderJobDataComponent(
                          loading,
                          "clock",
                          isNotNullOrUndefined(jobDetails?.hours_per_week)
                            ? parseFloat(
                                jobDetails?.hours_per_week.split(" ")[0]
                              ) >= 30
                              ? "Full-time"
                              : "Part-time"
                            : ""
                        )}
                      {jobDetails?.job_type &&
                        renderJobDataComponent(
                          loading,
                          "document-text",
                          jobDetails?.job_type
                        )}
                      {jobDetails?.work_mode &&
                        renderJobDataComponent(
                          loading,
                          "monitor",
                          jobDetails?.work_mode
                        )}
                      {renderJobDataComponent(
                        loading,
                        "info-circle",
                        jobDetails?.nid
                      )}
                    </Stack>
                    {statisticsCount.submissions_count > 0 ? (
                      <AtsRoundedButton
                        text="View all Submissions"
                        onClick={() =>
                          navigate(`/submissions/${jobDetails?.nid}`)
                        }
                        buttonHeight={28}
                        isInitial
                      />
                    ) : (
                      ""
                    )}
                  </Stack>

                  <Stack>
                    <JobCounts nid={jobDetails?.nid} loading={loading} />
                  </Stack>

                  <Stack sx={{ pb: 0, pt: 3 }}>
                    <AtsTitleText fs={16} fw={600} text="Description" />
                    <Stack className="innerHTML">
                      {loading ? (
                        <Skeleton height={80} />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobDetails?.published_description,
                          }}
                        ></div>
                      )}
                    </Stack>
                  </Stack>
                  <Stack sx={{ pb: 0, pt: 3 }}>
                    <AtsTitleText fs={16} fw={600} text="What you'll do" />
                    <Stack className="innerHTML">
                      {loading ? (
                        <Skeleton height={80} />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobDetails?.what_you_will_do,
                          }}
                        ></div>
                      )}
                    </Stack>
                  </Stack>
                  {jobDetails?.required_skills?.length > 0 && (
                    <>
                      <Stack sx={{ pb: 0, pt: 0 }}>
                        <AtsTitleText fs={16} fw={600} text="Skills" />
                      </Stack>

                      <Stack direction={"row"} flexWrap={"wrap"}>
                        {loading ? (
                          <Stack direction={"row"} gap={"15px"}>
                            {Array.from(new Array(4)).map((_, index) => (
                              <Skeleton
                                width={120}
                                height={29}
                                variant="rectangular"
                              />
                            ))}
                          </Stack>
                        ) : (
                          jobDetails?.required_skills?.map((skill: any) => {
                            return <AtsIconLabel key={skill} value={skill} />;
                          })
                        )}
                      </Stack>
                    </>
                  )}
                  {jobDetails?.benefits?.length > 0 && (
                    <>
                      <Stack sx={{ pb: 0, pt: 0 }}>
                        <AtsTitleText fs={16} fw={600} text="What's Included" />
                      </Stack>
                      <Stack>
                        <Grid
                          container
                          columnSpacing={"30px"}
                          rowSpacing={"20px"}
                        >
                          {loading
                            ? Array.from(new Array(4)).map((_, index) => (
                                <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  xl={3}
                                  xxl={2}
                                >
                                  <Skeleton
                                    width="100%"
                                    variant="rectangular"
                                    height={85}
                                  />
                                </Grid>
                              ))
                            : jobBenifits.map((benefit, index) => (
                                <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  xl={3}
                                  xxl={2}
                                >
                                  <JobBenefits benefit={benefit} />
                                </Grid>
                              ))}
                          {}
                        </Grid>
                      </Stack>
                    </>
                  )}

                  <Box mt={"34px"}>
                    <JobSalary jobDetails={jobDetails} loading={loading} />
                  </Box>
                  {/* <Box mt={"24px"} mb={"34px"}>
                    <JobSchedule jobDetails={jobDetails} loading={loading} />
                  </Box> */}

                  {aboutCompanyMemo}
                </Stack>
              </Box>
            </AtsCard>
          )}
        </Stack>
      )}
    </>
  );
};

export default JobDetails;
