import JobCardWrapper from "../components/JobCardWrapper";
import StepCard from "../components/StepCard";

const JobStep3 = () => {
  return (
    <JobCardWrapper header="">
      <StepCard
        step="3"
        title="Finish up and publish"
        subText="Make sure your details are correct, and publish your job."
      />
    </JobCardWrapper>
  );
};

export default JobStep3;
