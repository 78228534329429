import { Box, Modal, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import {
  getProjectDetailsState,
  setCurrentIndex,
  setProjectDocsList,
  setSelectedProjectDetailsTab,
} from "../../../../../../redux/slices/projectDetailsSlice";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../../../../theme";
import { ProjectService } from "../../../../../../Api/projectServices";
import {
  Attachment,
  CreateAndUpdateProjectDocsRequest,
  DocsProps,
} from "../../../../../../types/projectTypes";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";

const ProjectDocuments = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [file, setFile] = useState(false);
  const {
    projectDocuments,
    selectedProjectDetailsTab,
    projectDocsList,
    currentIndex,
    projectTab,
  } = useAppSelector(getProjectDetailsState);
  const [policyDocs, setPolicyDocs] = useState<DocsProps[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocsProps | null>(null);
  const [selectedAttachment, setSelectedAttachment] =
    useState<Attachment | null>(null);

  const isDocs = (id: string) => {
    return projectDocsList?.some((docs) => docs.document_type_id === id);
  };

  useEffect(() => {
    const filteredProjectDocs = projectDocuments
      .filter((docs) => {
        return docs.id === selectedProjectDetailsTab.id;
      })
      .map((docs) => {
        if (isDocs(docs.id)) {
          const findList = projectDocsList.find((document) => {
            return document.document_type_id === docs.id;
          });
          const attachments = findList.attachments;
          const document_urls = findList.document_urls;
          const nid = findList.nid;
          return {
            ...docs,
            attachments,
            document_urls,
            nid,
          };
        } else {
          return {
            ...docs,
            attachments: [],
            document_urls: [],
            nid: "",
          };
        }
      });
    setPolicyDocs(filteredProjectDocs);
  }, [selectedProjectDetailsTab, projectDocsList]);

  const handleBinaryUpload = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await ProjectService.uploadDocumentForProject(
            arrayBuffer,
            file?.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        } finally {
          // setLoading(false);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  // const createAndUpdateDocument = async () => {
  //   // setLoading(true)
  //   const nid = documents?.nid || documents?.nid?.length ? documents?.nid : "";
  //   const document_type_id =
  //     typeof documents?.id === "string" ? Number(documents?.id) : documents?.id;
  //   const document_type_parent_id =
  //     typeof documents?.parent_id === "string"
  //       ? Number(documents?.parent_id)
  //       : documents?.parent_id;
  //   const title = documents?.name;
  //   const description = documents?.description;
  //   const attachments_fid = documents?.attachments.map((attachment) =>
  //     typeof attachment?.fid === "string"
  //       ? Number(attachment.fid)
  //       : attachment.fid
  //   );
  //   const document_urls = documents?.document_urls;

  //   const policyObj = {
  //     ...(nid?.length && { nid: Number(nid) }),
  //     project_id: Number(id),
  //     document_type_id,
  //     document_type_parent_id,
  //     title,
  //     description,
  //     attachments_fid,
  //     document_urls,
  //   } as Partial<CreateAndUpdateProjectDocsRequest>;
  //   if (documents?.nid?.length) {
  //     await ProjectService.updateProjectDocument(policyObj);
  //   } else {
  //     await ProjectService.createProjectDocument(policyObj);
  //   }
  //   getDocumentList();
  //   setFile(null);
  //   try {
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     // setLoading(false)
  //   }
  // };

  const handleFiles = async (selectedFiles) => {
    setImageUploading(true);
    const fileArray = Array.from(selectedFiles);

    const uploadRequirmentPromises = fileArray.map(handleBinaryUpload);
    const resultsForDoc: any = await Promise.all(uploadRequirmentPromises);
    const file = resultsForDoc.map((item) => ({
      fid: item?.fid[0]?.value || null,
      url: item?.uri[0]?.value || "",
    }));
    const prePolicyDocs = [...policyDocs];
    const policy = { ...policyDocs[0] };

    const updatePolicy = {
      ...policy,
      attachments: [...policy.attachments, ...file],
    } as DocsProps;
    prePolicyDocs.splice(0, 1, updatePolicy);
    setPolicyDocs(prePolicyDocs);
    setImageUploading(false);
    setFile(true);
  };

  const openModal = (attachment: Attachment, documents) => {
    setOpen(true);
    setSelectedAttachment(attachment);
    setDocuments(documents);
  };

  const deleteDocument = async (documents: DocsProps, selectedAttachment) => {
    // setLoading(true)
    const nid =
      typeof documents?.nid === "string"
        ? Number(documents?.nid)
        : documents?.nid;
    const document_type_id =
      typeof documents?.id === "string" ? Number(documents?.id) : documents?.id;
    const document_type_parent_id =
      typeof documents?.parent_id === "string"
        ? Number(documents?.parent_id)
        : documents?.parent_id;
    const title = documents?.name;
    const description = documents?.description;
    const attachments_fid = documents?.attachments
      .filter((preAttachment) => preAttachment.fid !== selectedAttachment.fid)
      .map((attachment) =>
        typeof attachment?.fid === "string"
          ? Number(attachment.fid)
          : attachment.fid
      );
    const document_urls = documents?.document_urls;

    const policyObj = {
      nid,
      project_id: Number(id),
      document_type_id,
      document_type_parent_id,
      title,
      description,
      attachments_fid,
      document_urls,
    } as Partial<CreateAndUpdateProjectDocsRequest>;
    await ProjectService.updateProjectDocument(policyObj);
    const filterPolicy = policyDocs.map((policy) => ({
      ...policy,
      attachments: policy.attachments.filter(
        (attachment) => attachment.fid !== selectedAttachment?.fid
      ),
    }));
    setPolicyDocs(filterPolicy);
    try {
    } catch (error) {
      console.error(error);
    } finally {
      // setOpen(false);
    }
  };

  const removeAttachment = (docInx, index) => {
    const spreadPolicy = [...policyDocs];
    const prePolicy = { ...policyDocs[index] };
    const spreadAttachment = [...prePolicy.attachments];
    const preAttachment = {
      ...prePolicy,
      attachments: spreadAttachment.filter((attach) => attach.fid !== docInx),
    } as DocsProps;
    spreadPolicy.splice(index, 1, preAttachment);
    setPolicyDocs(spreadPolicy);
  };

  const renderFileComponent = (documents, attachment, childIndex, index) => {
    return (
      <>
        <Stack
          direction={"row"}
          sx={{
            px: "20px",
            py: "10px",
            bgcolor: "white",
            borderRadius: "8px",
            border: BORDERS.GRAY,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"start"}
            gap={"15px"}
            width={"100%"}
          >
            <img
              src={require("../../../../../../assets/images/pdfIcon.png")}
              alt="Nvidia Logo"
              style={{ width: 21 }}
            />
            <Stack
              gap={"10px"}
              sx={{
                flexGrow: 1,
              }}
            >
              <AtsTitleText
                text={attachment.url.split("/").pop()}
                fs={14}
                fw={500}
                textColor={COLORS.BLACK}
              />
              <Box className="truncate_two_line">
                <AtsTitleText
                  text={"Create a user-friendly dashboard with real-time an..."}
                  fs={12}
                  fw={500}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Box>
            </Stack>
            {documents?.nid?.length > 0 ? (
              <Box
                sx={{
                  borderLeft: BORDERS.GRAY,
                }}
              >
                <Box
                  sx={{
                    px: "10px",

                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: BORDERS.GRAY,
                  }}
                >
                  <AtsCustomButton px={0.5} py={0}>
                    <AtsIcon icon="icon-refresh" height="16px" />
                  </AtsCustomButton>
                </Box>
                <Box
                  sx={{
                    px: "10px",

                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AtsCustomButton
                    px={0.5}
                    py={0.5}
                    onClick={() => openModal(attachment, documents)}
                  >
                    <AtsIcon icon="icon-trash" height="16px" />
                  </AtsCustomButton>
                </Box>
              </Box>
            ) : (
              <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                <AtsCustomButton
                  px={0.5}
                  py={0.5}
                  onClick={() => removeAttachment(attachment.fid, index)}
                >
                  <AtsIcon icon="icon-close-circle" height="16px" />
                </AtsCustomButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </>
    );
  };
  const getDocumentList = async () => {
    try {
      const response = await ProjectService.getDocumentByProjectId({
        project_id: id,
      });

      const documents = response.results.map((docs) => ({
        ...docs,
        attachments: docs.attachments.map((attachment) => ({
          ...attachment,
          nid: docs.nid,
        })),
      }));
      dispatch(setProjectDocsList(documents));
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const createAndUpdateDocument = async () => {
    setLoading(true);
    const nid =
      policyDocs[0]?.nid || policyDocs[0]?.nid?.length
        ? policyDocs[0]?.nid
        : "";
    const document_type_id =
      typeof policyDocs[0]?.id === "string"
        ? Number(policyDocs[0]?.id)
        : policyDocs[0]?.id;
    const document_type_parent_id =
      typeof policyDocs[0]?.parent_id === "string"
        ? Number(policyDocs[0]?.parent_id)
        : policyDocs[0]?.parent_id;
    const title = policyDocs[0]?.name;
    const description = policyDocs[0]?.description;
    const attachments_fid = policyDocs[0]?.attachments.map((attachment) =>
      typeof attachment?.fid === "string"
        ? Number(attachment.fid)
        : attachment.fid
    );
    const document_urls = policyDocs[0]?.document_urls;

    const policyObj = {
      ...(nid?.length && { nid: Number(nid) }),
      project_id: Number(id),
      document_type_id,
      document_type_parent_id,
      title,
      description,
      attachments_fid,
      document_urls,
    } as Partial<CreateAndUpdateProjectDocsRequest>;
    if (policyDocs[0]?.nid?.length) {
      await ProjectService.updateProjectDocument(policyObj);
    } else {
      await ProjectService.createProjectDocument(policyObj);
    }
    getDocumentList();
    setFile(false);
    try {
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const backItem = () => {
    dispatch(setCurrentIndex(currentIndex - 1));
    const findProjectTab = projectTab.find(
      (_, index) => index === currentIndex - 1
    );
    dispatch(setSelectedProjectDetailsTab(findProjectTab));
  };
  const nextItem = () => {
    dispatch(setCurrentIndex(currentIndex + 1));
    const findProjectTab = projectTab.find(
      (_, index) => index === currentIndex + 1
    );
    dispatch(setSelectedProjectDetailsTab(findProjectTab));
  };
  return (
    <>
      <Stack
        gap={"10px"}
        sx={{
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText
            text={selectedProjectDetailsTab.name}
            fs={20}
            fw={600}
          />
        </Stack>
        <div
          className="documents"
          dangerouslySetInnerHTML={{
            __html: selectedProjectDetailsTab.description,
          }}
        ></div>
        <Box
          sx={{
            height: "calc(100% - 130px)",
            overflow: "auto",
          }}
        >
          <Stack gap={"10px"}>
            {policyDocs?.map((docs, index) => {
              return docs?.attachments?.map((attachment, childIndex) => {
                return renderFileComponent(docs, attachment, childIndex, index);
              });
            })}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <label htmlFor="fileUpload">
                <Stack
                  direction={"row"}
                  gap={"7px"}
                  alignItems={"center"}
                  sx={{
                    border: BORDERS.PRIMARY,
                    borderRadius: "50px",
                    px: "15px",
                    py: "8px",
                    cursor:
                      imageUploading || loading ? "not-allowed" : "pointer",
                  }}
                >
                  <AtsIcon
                    icon="icon-paperclip"
                    height="16px"
                    color={COLORS.PRIMARY}
                  />
                  <AtsTitleText
                    text={
                      imageUploading || loading
                        ? " Uploading...."
                        : "Upload More Files"
                    }
                    textColor={COLORS.PRIMARY}
                    fw={600}
                  />
                </Stack>
                <input
                  multiple
                  type="file"
                  name=""
                  accept=".pdf,.csv,.doc"
                  id={`fileUpload`}
                  hidden
                  // ref={fileInputRef}
                  onChange={(e) =>
                    imageUploading || loading
                      ? () => {}
                      : handleFiles(e.target.files)
                  }
                />
              </label>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pt={1}
        >
          <AtsRoundedButton
            text="Back"
            buttonVariant="outline"
            buttonHeight={34}
            buttonWidth={79}
            onClick={backItem}
          />
          {file && (
            <AtsRoundedButton
              text="Save Details"
              buttonVariant="outline"
              buttonHeight={34}
              buttonWidth={128}
              disabled={loading}
              onClick={createAndUpdateDocument}
            />
          )}
          <AtsRoundedButton
            text="Next"
            buttonHeight={34}
            buttonWidth={113}
            onClick={nextItem}
          />
        </Stack>

        <Modal open={open} onClose={() => setOpen(!open)}>
          <Stack sx={style} gap={"20px"}>
            <AtsTitleText
              text={"Are you sure delete this file ?"}
              fs={20}
              fw={700}
            />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                borderTop: BORDERS.GRAY,
                pt: 2,
              }}
            >
              <AtsRoundedButton
                buttonVariant="dangerContained"
                text="Delete"
                buttonWidth={100}
                buttonHeight={32}
                onClick={async () => {
                  await deleteDocument(documents, selectedAttachment);
                  setOpen(!open);
                }}
              />
              <AtsRoundedButton
                buttonVariant="outline"
                text="Cancel"
                buttonWidth={100}
                buttonHeight={32}
                onClick={() => setOpen(!open)}
              />
            </Stack>
          </Stack>
        </Modal>
      </Stack>
    </>
  );
};

export default ProjectDocuments;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};
