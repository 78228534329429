import { Box, Grid, Stack } from "@mui/material";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";

interface Props {
  checked: boolean;
  title: string;
  icon: string;
  description: string | null;
  onSelect: () => void;
}

const SmallCard = ({ checked, title, description, icon, onSelect }: Props) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={4}
        sx={{ cursor: "pointer" }}
        onClick={onSelect}
      >
        <Box
          sx={{
            px: "23px",
            py: "20px",
            borderRadius: "10px",
            border: checked ? BORDERS_2X.PRIMARY : BORDERS.GRAY,
            height: 77,
          }}
        >
          <Stack gap={1}>
            <AtsIcon
              icon={icon ?? "icon-sun-fog"}
              fs={25}
              color={checked ? COLORS.PRIMARY : COLORS.BLACK}
            />
            <AtsTitleText
              text={title}
              fs={14}
              fw={600}
              textColor={checked ? COLORS.PRIMARY : COLORS.BLACK}
            />
            <AtsTitleText
              text={description ?? "-"}
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

export default SmallCard;
