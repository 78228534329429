import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Views } from "react-big-calendar";
import moment from "moment";
import { CalendarEventTypes } from "../../types/calendarTypes";

type Keys = keyof typeof Views;

interface CalendarSliceState {
  calendarView: (typeof Views)[Keys];
  date: Date;
  eventType: keyof typeof CalendarEventTypes;
}

const initialState: CalendarSliceState = {
  calendarView: Views.WEEK,
  date: moment(new Date()).toDate(),
  eventType: CalendarEventTypes.All,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setCalendarView: (state: CalendarSliceState, action) => {
      const { payload } = action;
      state.calendarView = payload;
    },
    setDate: (state: CalendarSliceState, action) => {
      const { payload } = action;
      state.date = payload;
    },
    setEventType: (state: CalendarSliceState, action) => {
      const { payload } = action;
      state.eventType = payload;
    },

    setInitialCalendarState: () => initialState,
  },
});

export const {
  setCalendarView,
  setDate,
  setInitialCalendarState,
  setEventType,
} = calendarSlice.actions;
export const getCalendarState = (state: RootState) => state.calendar;
export default calendarSlice.reducer;
