import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsLabel from "../../../../../../../../../components/AtsLabel";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import { convertTimestampToDate } from "../../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";

const ConfirmPosition = () => {
  const { isCandidateDetailsLoading } = useAppSelector(getSubmissionState);
  const { contractorPlacmentDetails, isContractorOnboarded } = useAppSelector(
    getContractorDetailsState
  );

  const TextWrapper = ({ text, value }) => {
    return (
      <Stack gap={"6px"}>
        <AtsTitleText
          text={text}
          fs={12}
          fw={500}
          textColor={COLORS.LIGHT_GRAY}
        />
        {isCandidateDetailsLoading ? (
          <Skeleton variant="rounded" width={100} />
        ) : (
          <AtsTitleText text={value} fs={14} fw={600} />
        )}
      </Stack>
    );
  };
  return (
    <>
      <Stack gap={"18px"}>
        <AtsRow gap={"10px"}>
          <>
            <AtsTitleText
              text={"Confirm Position Details"}
              fs={16}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
            {isContractorOnboarded && (
              <AtsLabel
                fs={12}
                px={2}
                text={"Confirmed"}
                variant="success"
                isCapital
                height="25px"
              />
            )}
          </>
        </AtsRow>
        <Box
          sx={{ border: BORDERS.GRAY, borderRadius: "10px", padding: "30px" }}
        >
          <Stack gap={"50px"}>
            <Grid container columnSpacing={"82px"} rowSpacing={"10px"}>
              <Grid item xs={12} sm={6} md={4}>
                <TextWrapper
                  text={"Job title"}
                  value={
                    contractorPlacmentDetails?.job_title.length
                      ? contractorPlacmentDetails?.job_title
                      : "--"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextWrapper
                  text={"Department / Team"}
                  value={
                    contractorPlacmentDetails?.category.length
                      ? contractorPlacmentDetails?.category
                      : "--"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextWrapper
                  text={"Start date"}
                  value={
                    contractorPlacmentDetails?.start_date.length
                      ? convertTimestampToDate(
                          contractorPlacmentDetails?.start_date
                        )
                      : "--"
                  }
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={"82px"} rowSpacing={"10px"}>
              <Grid item xs={12} sm={6} md={4}>
                <TextWrapper
                  text={"Employment Type"}
                  value={
                    isNotNullOrUndefined(
                      contractorPlacmentDetails?.hours_per_week
                    )
                      ? parseFloat(contractorPlacmentDetails?.hours_per_week) >=
                        30
                        ? "Full-time"
                        : "Part-time"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextWrapper
                  text={"Employment Status"}
                  value={
                    contractorPlacmentDetails?.onboarding_status.length
                      ? contractorPlacmentDetails?.onboarding_status
                      : "--"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextWrapper
                  text={"Work Schedule (hrs)"}
                  value={`${
                    contractorPlacmentDetails?.hours_per_week.length
                      ? contractorPlacmentDetails?.hours_per_week
                      : "0"
                  } hrs`}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default ConfirmPosition;
