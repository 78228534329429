import { createContext, useContext, useState } from "react";

type NotificationsModuleContextType = {
  selectedNotificationTab: string;
  setSelectedNotificationTab: React.Dispatch<React.SetStateAction<string>>;
};

const NotificationsModule =
  createContext<NotificationsModuleContextType | null>(null);

export const useNotifications = () =>
  useContext(NotificationsModule) as NotificationsModuleContextType;

export const NotificationsProvider = ({ children }: any) => {
  const [selectedNotificationTab, setSelectedNotificationTab] = useState("ALL");
  return (
    <NotificationsModule.Provider
      value={{
        selectedNotificationTab,
        setSelectedNotificationTab,
      }}
    >
      {children}
    </NotificationsModule.Provider>
  );
};
