import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsSlider from "../../../components/AtsSlider";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../redux/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import { createProjectParams } from "../../../types/projectTypes";

const BudgetRange = () => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);
  const [price, setPrice] = useState<number[]>([1, 1000000]);

  const handleSetPrice = (newValue: number[]) => {
    const lowValue = newValue[0].toString();
    const highValue = newValue[1].toString();
    dispatch(
      setCreateProjectState({
        ...createProjectState,
        budget_high: highValue,
        budget_low: lowValue,
      } as createProjectParams)
    );
  };
  const handleChangeRate = (key: keyof createProjectParams, value: string) => {
    dispatch(
      setCreateProjectState({
        ...createProjectState,
        [key]: value,
      } as createProjectParams)
    );
  };
  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setPrice(newValue as number[]);
    handleSetPrice(newValue as number[]);
  };

  useEffect(() => {
    setPrice([
      Number(createProjectState.budget_low),
      Number(createProjectState.budget_high),
    ]);
  }, [createProjectState.budget_low, createProjectState.budget_high]);

  return (
    <>
      <Stack
        gap={1.5}
        sx={{
          px: 5,
          py: 6.2,
          bgcolor: "#f5f5f5",
          borderRadius: "10px",
        }}
      >
        <AtsSlider
          max={5000000}
          min={1}
          onChange={handlePriceChange}
          value={price}
        />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <RenderBudgetRateComponent
            value={createProjectState.budget_low}
            width={85}
            handleRate={(value) => {
              handleChangeRate("budget_low", value);
            }}
          />
          <AtsTitleText text={"to"} fs={"0.625rem"} />
          <RenderBudgetRateComponent
            value={createProjectState.budget_high}
            width={140}
            handleRate={(value) => {
              handleChangeRate("budget_high", value);
            }}
          />
        </Stack>
        <Box sx={{ textAlign: "center" }}>
          <AtsTitleText fs={14} fw={400} text={`per year`} />
        </Box>
      </Stack>
    </>
  );
};

export default BudgetRange;

const RenderBudgetRateComponent = ({
  value,
  width,
  handleRate,
}: {
  value: string;
  width: number;
  handleRate: (value: string) => void;
}) => {
  const formatNumber = (value) => {
    if (!value) return 0;
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = new Intl.NumberFormat("en-US").format(integerPart);
    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart.substring(0, 2)}`
      : formattedInteger;
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const formattedAmount = input
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    handleRate(formattedAmount);
  };

  return (
    <Stack gap={0.5}>
      <Stack
        direction={"row"}
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "6px",
          minHeight: 47,
          // minWidth: width,
          width: 124,
          position: "relative",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            width: "27px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
        >
          <AtsTitleText
            text={"$"}
            fs={18}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Box>
        <Box
          sx={{
            bgcolor: "#fff",
            fontSize: 18,
            color: COLORS.BLACK,
            fontWeight: 600,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <input
            type="text"
            className="rateInput"
            style={{
              fontWeight: 700,
              fontSize: 14,
              width: "90%",
            }}
            value={formatNumber(value)}
            onChange={handleChange}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
