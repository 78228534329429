import { InfoOutlined } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../../../providers/TimesheetProvider";
import {
  getContractorTimesheetState,
  setSelectedDisputeFlowTab,
  setShowDisputeModal,
} from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";

const TimeshhetActionFilter = () => {
  const dispatch = useAppDispatch();
  const { showDisputeModal } = useAppSelector(getContractorTimesheetState);

  const {
    contractorTimeEntries,
    selectedTimelogOfContractor,
    dateText,
    onPrevClick,
    onNextClick,
    selectedContractorTimesheet,
  } = useTimesheet();
  const isNotApprovedAndDipute = contractorTimeEntries.some((log) => {
    return (
      Number(log.approved_time.length ? log.approved_time : "0") === 0 &&
      Number(log.disputed_time.length ? log.disputed_time : "0") === 0
    );
  });
  const onOpenCloseModal = () => {
    dispatch(setShowDisputeModal(!showDisputeModal));
    dispatch(setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries));
    // dispatch()
  };
  const name = selectedContractorTimesheet?.first_name
    ? `${selectedContractorTimesheet?.first_name}'s`
    : "";
  return (
    <>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        pb={"19px"}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        <AtsTitleText text={`${name} Timesheet`} fs={18} fw={600} />
        <Stack direction="row" alignItems={"center"} gap={"24px"}>
          {isNotApprovedAndDipute && selectedTimelogOfContractor.length > 0 ? (
            <Button
              onClick={onOpenCloseModal}
              sx={{
                border: "1px solid #e63030 !important",
                bgcolor: "white !important",
                color: "#e63030 !important",
                fontFamily: "Inter",
                borderRadius: "20px",
                textTransform: "capitalize",
                px: "13px",
                py: "5px",
                height: 29,
                width: 102,
                ":hover": {
                  border: "1px solid #e63030 !important",
                  bgcolor: "white !important",
                  color: "#e63030 !important",
                },
              }}
              endIcon={
                <InfoOutlined
                  sx={{
                    fontSize: 16,
                    transform: "rotate(-180deg)",
                    color: "#e63030",
                  }}
                />
              }
            >
              Dispute
            </Button>
          ) : (
            ""
          )}

          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              border: BORDERS.PRIMARY,
              borderRadius: "40px",

              px: "8px",
              height: "28px",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <AtsCustomButton
                px={0}
                py={0}
                onClick={() => {
                  onPrevClick();
                  // getTimeEntriesOfContractor(selectedContractorTimesheet.email);
                }}
              >
                <AtsIcon icon="icon-arrow-left-2" height="16px" />
              </AtsCustomButton>
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                  <AtsCustomButton px={0} py={0}>
                    <AtsIcon
                      icon="icon-calendar-1"
                      height="16px"
                      color={COLORS.PRIMARY}
                    />
                  </AtsCustomButton>
                  <AtsTitleText
                    text={dateText}
                    fs={12}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                  />
                </Stack>
              </button>
              {/* <AtsDateRangePicker
                handleEvent={handleEvent}
                handleClear={() => {}}
              >
                
              </AtsDateRangePicker> */}
              <AtsCustomButton
                px={0}
                py={0}
                onClick={() => {
                  onNextClick();
                  // getTimeEntriesOfContractor(selectedContractorTimesheet.email);
                }}
              >
                <AtsIcon icon="icon-arrow-right-3" height="16px" />
              </AtsCustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default TimeshhetActionFilter;
