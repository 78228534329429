import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

export const getTime = (date: string): string => {
  return moment(date).format("hh:mm a");
};
export const getDateFormatMMMMDYYYYY = (date: string): string => {
  return moment(date).format("MMMM D, YYYY");
};
export const getDateFormatMMMDYYYYY = (date: string): string => {
  return moment(date).format("MMM D, YYYY");
};

export const getDateFormatDDDDMMMMD = (date: Dayjs | null): string => {
  return date ? dayjs(date).format("dddd, MMMM D") : "";
};
export const dateFormatDDDDMMMMD = (dateStr: any): string => {
  const date = new Date(dateStr);

  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
};

export const disableWeekends = (date) => {
  const day = date.day();
  return day === 0 || day === 6;
};
export const tileDisabled = ({ date }: { date: Date }) => {
  return date.getDay() === 0 || date.getDay() === 6;
};
export const dateFormater = (date: string) => {
  const createdAt: Date = new Date(date);

  const currentDate: Date = new Date();

  const diffInMs: number = currentDate.getTime() - createdAt.getTime();

  const diffInMinutes: number = diffInMs / (1000 * 60);
  const diffInHours: number = diffInMs / (1000 * 60 * 60);
  const diffInDays: number = diffInMs / (1000 * 60 * 60 * 24);
  const diffInWeeks: number = diffInMs / (1000 * 60 * 60 * 24 * 7);
  const diffInMonths: number = diffInMs / (1000 * 60 * 60 * 24 * 30.44);

  let result: string;

  if (diffInMonths >= 1) {
    result = `${Math.floor(diffInMonths)}m`;
  } else if (diffInWeeks >= 1) {
    result = `${Math.floor(diffInWeeks)}w`;
  } else if (diffInDays >= 1) {
    result = `${Math.floor(diffInDays)}d`;
  } else if (diffInHours >= 1) {
    result = `${Math.floor(diffInHours)}h`;
  } else {
    result = `${Math.floor(diffInMinutes)}m`;
  }

  return result;
};

export const dayAndTimeFormatter = (date: string) => {
  const formattedDate = moment(date).calendar(null, {
    sameDay: "[Today] hh:mm A",
    nextDay: "[Tomorrow] hh:mm A",
    nextWeek: "dddd hh:mm A",
    lastDay: "[Yesterday] hh:mm A",
    lastWeek: "[Last] dddd hh:mm A",
    sameElse: "YYYY-MM-DD hh:mm A",
  });
  return formattedDate;
};

export const formatDateRange = (startDate: string, endDate: string): string => {
  const formattedStartDate = moment(startDate).format("MMM D");
  const formattedEndDate = moment(endDate).format("MMM D");
  const year = moment(endDate).format("YYYY");

  return `${formattedStartDate} - ${formattedEndDate}, ${year}`;
};
export const formatTimeRange = (start: Date, end: Date): string => {
  const startTime = moment(start).format("hh:mm");
  const endTime = moment(end).format("hh:mm a");

  return `${startTime} - ${endTime}`;
};

export const getPercentageFromStartToEnd = (
  startDate: string,
  endDate: string
): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const today = new Date();

  const totalDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

  const daysFromStartToToday =
    (today.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

  const percentage = (daysFromStartToToday / totalDays) * 100;

  return Math.min(Math.max(percentage, 0), 100);
};

export const getTimePeriod = (startDate: string, endDate: string): string => {
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);

  let years: number = end.getFullYear() - start.getFullYear();
  let months: number = end.getMonth() - start.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }
  if (months === 0) {
    return `${years} years`;
  }
  return `${years} years, ${months} months`;
};
export const convertTimestampToDate = (date: string | number) => {
  const timestamp = typeof date === "string" ? Number(date) : date;
  const formattedDate = moment.unix(timestamp).format("MMM DD, YYYY");
  return formattedDate;
};
