import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { TimesheetServices } from "../../../../../../../../../Api/timesheetServices";
import { QuestionHelpIcon } from "../../../../../../../../../assets/icons/Icons";
import PdfIcon from "../../../../../../../../../assets/images/pdfIcon.png";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTextArea from "../../../../../../../../../components/AtsTextArea";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import {
  EvidencesProps,
  useTimesheet,
} from "../../../../../../../../../providers/TimesheetProvider";
import { setSelectedDisputeFlowTab } from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";
import { DisputeTimesheetRequest } from "../../../../../../../../../types/timesheetTypes";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { evidanceValidation } from "../../../../../../../../../utils/validationUtils";

const EvidenceDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const {
    setEvidencesWithNote,
    evidencesWithNote,
    selectedTimelogOfContractorDetails,
    createDisputeLoading,
    createDispute,
  } = useTimesheet();
  const [imageUploading, setImageUploading] = useState(false);
  const [error, setError] = useState({});
  const { font14 } = FontUtil();

  const onDispute = async () => {
    const validation = evidanceValidation(evidencesWithNote);
    setError(validation.error);
    if (validation.isValid) {
      const time_logs = selectedTimelogOfContractorDetails.map((timesheet) => ({
        nid: Number(timesheet.nid),
        disputed_hrs: Number(timesheet.disputed_hrs),
        disputed_mins: Number(timesheet.disputed_mins),
        dispute_description: timesheet.dispute_description,
      }));
      const evidences = evidencesWithNote.evidences.map(
        (evidance) => evidance.fid
      );

      const updateObj = {
        evidences,
        note: evidencesWithNote.note,
        time_logs,
      } as DisputeTimesheetRequest;
      await createDispute(updateObj, id);
    }

    // dispatch(setTimesheetsData(disputedData));
  };

  const handleBinaryUpload = async (file) => {
    if (!file) return;
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "text/csv",
    ];

    if (file && !allowedTypes.includes(file.type)) {
      alert("Only CSV, PDF, JPG, and PNG files are allowed");
      return;
    }
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await TimesheetServices.uploadEvidancesForTimesheet(
            arrayBuffer,
            file?.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles) => {
    setImageUploading(true);
    const fileArray = Array.from(selectedFiles);

    const uploadRequirmentPromises = fileArray.map(handleBinaryUpload);
    try {
      const resultsForDoc: any = await Promise.all(uploadRequirmentPromises);
      const file = resultsForDoc.map((item) => ({
        fid: item?.fid[0]?.value || null,
        url: item?.uri[0]?.value || "",
      }));

      const updateEvidances = {
        ...evidencesWithNote,
        evidences: file,
      } as EvidencesProps;
      setEvidencesWithNote(updateEvidances);
    } catch (error) {}

    setImageUploading(false);
  };

  const disputed_hrs = selectedTimelogOfContractorDetails
    .filter((log) => log.disputed_hrs.length)
    .reduce((total, log) => total + parseFloat(log.disputed_hrs), 0);
  const disputed_mins = selectedTimelogOfContractorDetails
    .filter((log) => log.disputed_mins.length)
    .reduce((total, log) => total + parseFloat(log.disputed_mins), 0);
  return (
    <>
      <Box
        px={"30px"}
        mt={"30px"}
        sx={{
          height: "calc(100% - 265px)",
        }}
      >
        <Stack
          className="scrollHide"
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              pb: "20px",
            }}
          >
            <Typography
              sx={{
                ...font14,
                fontWeight: 400,
                color: "#000",
              }}
            >
              You have selected{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedTimelogOfContractorDetails.length} entries{" "}
              </span>{" "}
              to dispute
            </Typography>
          </Box>
          <AtsRowBetween
            sx={{
              minHeight: 43,
              px: "14px",
              bgcolor: "#fff3f3",
              borderRadius: "6px",
              mb: "40px",
            }}
          >
            <>
              <AtsTitleText
                text={"Total time disputed"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
              <AtsTitleText
                text={`${disputed_hrs ? disputed_hrs : 0}.${
                  disputed_mins ? disputed_mins : 0
                } hrs`}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
            </>
          </AtsRowBetween>
          <Stack
            gap={"9px"}
            mb={"40px"}
            sx={{
              position: "relative",
            }}
          >
            <AtsRowBetween>
              <>
                <Typography
                  sx={{
                    ...font14,
                    fontWeight: 600,
                    color: COLORS.BLACK,
                  }}
                >
                  Attach your evidence
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <AtsRow gap={"2.5px"}>
                  <>
                    <QuestionHelpIcon />
                    <AtsTitleText
                      text={"help"}
                      fs={12}
                      fw={600}
                      textColor={COLORS.PRIMARY}
                    />
                  </>
                </AtsRow>
              </>
            </AtsRowBetween>

            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <Box
              sx={{
                width: 80,
                height: 80,
                borderRadius: "10px",
                // border: BORDERS.GRAY,
              }}
            >
              <label
                htmlFor="chooseVideo"
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  border: "1px dashed #3366cc",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                // onDrop={handleDrop}
                // onDragOver={handleDragOver}
              >
                <AtsIcon icon="icon-add" height="16px" fs={16} color={"#000"} />

                <AtsTitleText
                  text={"Upload"}
                  fs={14}
                  fw={400}
                  textColor={"#000"}
                />
                <input
                  multiple
                  type="file"
                  name=""
                  accept=".csv, .pdf, .jpg, .jpeg, .png"
                  id="chooseVideo"
                  hidden
                  onChange={(e) =>
                    imageUploading ? () => {} : handleFiles(e.target.files)
                  }
                />
              </label>
            </Box>
            <AtsRow rowGap={"8px"} columnGap={"10px"} flexWrap={"wrap"}>
              <>
                {imageUploading
                  ? Array.from(new Array(4)).map((_, index) => (
                      <Skeleton variant="rounded" width={50} height={50} />
                    ))
                  : evidencesWithNote.evidences.map((evidance) => (
                      <Stack gap={"3px"} key={evidance.fid}>
                        <Box
                          sx={{
                            width: 50,
                            height: 50,
                          }}
                        >
                          {evidance.url.split(".")[1] === "pdf" ? (
                            <AtsRow
                              justifyContent={"center"}
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "5px",
                                border: BORDERS.GRAY,
                              }}
                            >
                              <img
                                src={PdfIcon}
                                alt="Nvidia Logo"
                                style={{ width: 21 }}
                              />
                            </AtsRow>
                          ) : (
                            <img
                              src={evidance?.url}
                              alt="evi"
                              width={"100%"}
                              height={"100%"}
                              style={{
                                borderRadius: "7px",
                              }}
                            />
                          )}
                        </Box>
                        <AtsTitleText
                          fs={12}
                          fw={500}
                          text={`${evidance.url.substring(0, 5)}.${
                            evidance.url.split(".")[1]
                          }`}
                        />
                      </Stack>
                    ))}
              </>
            </AtsRow>
            {!evidencesWithNote.evidences.length && (
              <AtsTitleText
                text={
                  "supported formats: CSV, PDF, JPG, PNG | Max file size: 10 mb"
                }
                fs={11}
                fw={500}
                textColor={COLORS.DARK_GRAY}
              />
            )}
            <Stack
              textAlign={"start"}
              sx={{
                position: "absolute",
                bottom: -15,
                right: 0,
              }}
            >
              <AtsTitleText
                text={error["evidences"]}
                fs={12}
                fw={500}
                textColor={COLORS.RED_LIGHT}
              />
            </Stack>
          </Stack>
          <Stack
            gap={"9px"}
            mb={"40px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              sx={{
                ...font14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
            >
              Send note to Esteemed
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>

            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsTextArea
              cols={12}
              rows={4}
              className="textArea"
              value={evidencesWithNote.note}
              onChange={(e) => {
                const value = e.target.value;
                const updateEvidances = {
                  ...evidencesWithNote,
                  note: value,
                } as EvidencesProps;
                setEvidencesWithNote(updateEvidances);
              }}
            />
            <Stack
              textAlign={"start"}
              sx={{
                position: "absolute",
                bottom: -15,
                right: 0,
              }}
            >
              <AtsTitleText
                text={error["note"]}
                fs={12}
                fw={500}
                textColor={COLORS.RED_LIGHT}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <AtsRowBetween px={"50px"} pt={"30px"} pb={"50px"}>
        <>
          <AtsCustomButton
            px={0}
            py={0}
            onClick={() =>
              dispatch(
                setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries)
              )
            }
          >
            <AtsTitleText text={"Back"} fw={400} textColor="#050505" />
          </AtsCustomButton>
          <AtsRow gap={"50px"}>
            <>
              <AtsCustomButton px={0} py={0}>
                <AtsTitleText text={"Cancel"} fw={400} textColor="#050505" />
              </AtsCustomButton>
              <AtsRoundedButton
                buttonVariant="redContained"
                text="Dispute"
                fw={400}
                isInitial
                buttonWidth={createDisputeLoading ? 150 : 127}
                buttonHeight={37}
                onClick={onDispute}
                disabled={createDisputeLoading}
                loading={createDisputeLoading}
              />
            </>
          </AtsRow>
        </>
      </AtsRowBetween>
    </>
  );
};

export default EvidenceDetails;
