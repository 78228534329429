import { Box, Typography } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { Stack } from "@mui/material";
import { InfoCircle } from "iconsax-react";
import { BG_COLORS, BORDERS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";
import { Skeleton } from "@mui/material";

const LoadingMSAContract = () => {
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 188,
          mx: "9px",
          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 252,
                minHeight: 188,
                bgcolor: BG_COLORS.PRIMARY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: -4,
                  left: 0,
                }}
              >
                <img
                  src={require("../assets/images/documentEdit.png")}
                  alt=""
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <img src={require("../assets/images/talentMSA.png")} alt="" />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 188,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      gap={"55px"}
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      <Stack gap={"15px"}>
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={"Included in your contract"}
                          textColor={"#000"}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={80}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      minWidth: 190,
                      maxWidth: 210,
                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pt: "25px",
                      pr: "19px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      gap={"7px"}
                    >
                      <Box>
                        <InfoCircle size={16} variant="Bold" color="#e63030" />
                      </Box>
                      <Stack gap={"18px"}>
                        <AtsTitleText
                          text={`Your Master Services Agreement (MSA) is ready for e-signature. 
Please e-sign it now to access the Dashboard.`}
                          fs={12}
                          fw={400}
                        />

                        <Skeleton
                          variant="rectangular"
                          width={119}
                          height={26}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
    </>
  );
};

export default LoadingMSAContract;
