import { ReactNode } from "react";

import Header from "./Header/Header";
import Sidebar from "./Sidebar";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "../redux/store";
import { getAuthState } from "../redux/slices/authSlice";

interface IProps {
  children: ReactNode;
}

const ATS_Layout = ({ children }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { isOpen } = useAppSelector(getAuthState);
  return (
    <div
      style={{ paddingTop: "env(safe-area-inset-top)" }}
      className="main-container"
    >
      <>
        {!isMobile && <Sidebar />}
        <div
          style={{
            height: "100%",
            width: isMobile
              ? "100%"
              : `calc(100% - ${isOpen ? "250px" : "85px"})`,
          }}
        >
          <Header />
          <div
            style={{
              height: "calc(100% - 67px)",
              width: "100%",
              backgroundColor: "#f5f6f8",
            }}
          >
            {children}
          </div>
        </div>
      </>
    </div>
  );
};

export default ATS_Layout;
