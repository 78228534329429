import { Box, Collapse, IconButton, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectService } from "../../../../../Api/projectServices";
import AtsTextInput from "../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../theme";
import {
  ProjectIdParams,
  ProjectListParams,
  ProjectListProps,
  TeamMemberProps,
} from "../../../../../types/projectTypes";
import { useClickOutSide } from "../../../../../utils/common";
import InfiniteScroll from "react-infinite-scroll-component";
import AtsRow from "../../../../../components/AtsRow";
import { isNotNullOrUndefined } from "../../../../../utils/stringUtils";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AtsRowBetween from "../../../../../components/AtsRowBetween";

interface IProps {
  onSelect?: (selected: string[]) => void;
  selected: string[];
  project_id: string;
  selectedTeamMembers?: TeamMemberProps[];
  setSelectedTeamMembers: React.Dispatch<
    React.SetStateAction<TeamMemberProps[]>
  >;
}

const MembersSelector = ({
  onSelect,
  selected,
  project_id,
  selectedTeamMembers,
  setSelectedTeamMembers,
}: IProps) => {
  const [projects, setProjects] = useState<TeamMemberProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const debouncedSearch = useDebounce(searchValue);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  // const loadMoreItems = (event: any) => {
  //   if (
  //     event.target.scrollTop + event.target.clientHeight >=
  //     event.target.scrollHeight
  //   ) {
  //     if (totalItem !== projects.length) {
  //       setPage(page + 1);
  //     }
  //   }
  // };

  const fetchMoreData = () => {
    if (totalItem !== projects.length) {
      setPage((prevPage) => prevPage + 1); // Increment the page
    }
  };

  const handleSelect = (selectedId: string) => {
    let updatedSelected = [...selected];
    if (updatedSelected.includes(selectedId)) {
      updatedSelected = updatedSelected.filter((id) => id !== selectedId);
    } else {
      updatedSelected.push(selectedId);
    }
    const findUser = projects.find((user) => user.uid === selectedId);
    setSelectedTeamMembers([...selectedTeamMembers, findUser]);
    setSearchValue("");
    if (onSelect) {
      onSelect(updatedSelected);
    }
  };

  const handleDelete = (selectedId: string) => {
    const updatedSelected = selected.filter((id) => id !== selectedId);
    const updatedSelectedTeamMembers = selectedTeamMembers.filter(
      (members) => members.uid !== selectedId
    );
    if (onSelect) {
      onSelect(updatedSelected);
    }
    setSelectedTeamMembers(updatedSelectedTeamMembers);
  };

  const getProjects = async () => {
    setIsLoading(true);
    try {
      const projectState = {
        page,
        items_per_page: 5,
        project_id,
        ...(searchValue.length && { title: debouncedSearch }),
      } as Partial<ProjectIdParams>;
      const response = await ProjectService.getActiveProjectMember(
        projectState
      );
      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setProjects(response.results);
        } else {
          setProjects([...projects, ...response.results]);
        }
        setFocusedIndex(0);
      } else {
        setProjects([]);
        setTotalItem(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //   useEffect(() => {
  //     if (selected.length) {
  //       const findeProject = selectedTeamMembers.find(
  //         (project) => project.nid === selected
  //       );
  //       setSelectedProject(findeProject);
  //     }
  //   }, [selected]);

  useEffect(() => {
    project_id.length && getProjects();
  }, [debouncedSearch, page]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (projects.length === 0) return;

      switch (event.key) {
        case "ArrowUp":
          setFocusedIndex((prevIndex) =>
            prevIndex === null || prevIndex === 0
              ? projects.length - 1
              : prevIndex - 1
          );
          break;
        case "ArrowDown":
          setFocusedIndex((prevIndex) =>
            prevIndex === null || prevIndex === projects.length - 1
              ? 0
              : prevIndex + 1
          );
          break;
        case "Enter":
          if (focusedIndex !== null) {
            handleSelect(projects[focusedIndex].uid);
            setShow(false);
          }
          break;
        default:
          break;
      }
    };

    if (show) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedIndex, projects, show]);
  return (
    <>
      <div ref={domNode} style={{ position: "relative" }}>
        <AtsRowBetween
          sx={{
            minHeight: "42px",
            height: "auto",
            border: BORDERS.GRAY,

            borderRadius: "8px",
            px: "20px",
          }}
        >
          <>
            <AtsRow
              sx={{
                flex: 1,
              }}
              columnGap={"5px"}
              rowGap={"3px"}
              flexWrap={"wrap"}
            >
              <>
                {selected.map((id) => {
                  const selectedUser = selectedTeamMembers?.find(
                    (user) => user.uid === id
                  );
                  return (
                    selectedUser && (
                      <AtsRow
                        key={id}
                        gap={"5px"}
                        sx={{
                          bgcolor: BG_COLORS.PRIMARY,
                          py: "2px",
                          pl: "15px",
                          pr: "10px",
                          borderRadius: "20px",
                        }}
                      >
                        <>
                          <AtsTitleText
                            text={`${selectedUser?.first_name ?? ""} ${
                              selectedUser?.last_name ?? ""
                            }`}
                            fs={12}
                            fw={500}
                            textColor={COLORS.PRIMARY}
                          />
                          <IconButton
                            sx={{ p: "3px" }}
                            onClick={() => handleDelete(id)}
                          >
                            <Close
                              sx={{ fontSize: 14, color: COLORS.PRIMARY }}
                            />
                          </IconButton>
                        </>
                      </AtsRow>
                    )
                  );
                })}
                <input
                  className="customInput"
                  placeholder={"Search project name"}
                  value={searchValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setShow(true);
                    } else {
                      setShow(false);
                    }
                    setSearchValue(value);
                    setPage(0);
                  }}
                  style={{
                    flex: 1,
                    height: "25px",
                  }}
                />
              </>
            </AtsRow>
            <IconButton
              sx={{
                p: "3px",
              }}
              onClick={() => setShow(!show)}
            >
              {show ? (
                <KeyboardArrowUp
                  sx={{
                    // fontSize: 14,
                    color: COLORS.DARK_GRAY,
                  }}
                />
              ) : (
                <KeyboardArrowDown
                  sx={{
                    // fontSize: 14,
                    color: COLORS.DARK_GRAY,
                  }}
                />
              )}
            </IconButton>
          </>
        </AtsRowBetween>

        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: "6px",
          }}
          in={show}
        >
          <Stack
            id="projectDiv"
            // onScroll={loadMoreItems}
            style={{
              maxHeight: 200,
              padding: "10px",
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreData}
              hasMore={projects.length < totalItem}
              loader={<h4>Loading...</h4>}
              scrollableTarget="projectDiv"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Stack gap={"5px"}>
                {projects.length ? (
                  projects.map((option: TeamMemberProps, index) => (
                    <MenuItem
                      key={index}
                      value={option.uid}
                      onClick={() => {
                        handleSelect(option.uid);
                        setShow(false);
                      }}
                      sx={{
                        borderRadius: "6px",
                        bgcolor: selected.includes(option.uid)
                          ? BG_COLORS.PRIMARY
                          : focusedIndex === index
                          ? "rgba(0, 0, 0, 0.1)" // Highlight focused item
                          : BG_COLORS.GRAY,
                      }}
                    >
                      <AtsRow gap={"10px"}>
                        <>
                          <Box
                            sx={{
                              width: 25,
                              height: 25,
                              borderRadius: "50%",
                              border: BORDERS.GRAY,
                            }}
                          >
                            <img
                              width={"100%"}
                              style={{
                                borderRadius: "50%",
                              }}
                              src={
                                isNotNullOrUndefined(option.image)
                                  ? option.image
                                  : require("../../../../../assets/images/projectImg.png")
                              }
                              alt=""
                            />
                          </Box>
                          <AtsTitleText
                            text={option.first_name}
                            fs={14}
                            fw={500}
                            textColor={
                              selected.includes(option.uid)
                                ? COLORS.PRIMARY
                                : COLORS.BLACK
                            }
                          />
                        </>
                      </AtsRow>
                    </MenuItem>
                  ))
                ) : (
                  <AtsTitleText text={"No Result"} />
                )}
              </Stack>
            </InfiniteScroll>
          </Stack>
        </Collapse>
      </div>
    </>
  );
};

export default MembersSelector;
