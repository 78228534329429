import { Box, Stack } from "@mui/material";
import { NoInterviewIcon } from "../../../../../../../../../../assets/icons/Icons";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import AtsText from "../../../../../../../../../../components/AtsText";
import { useAppDispatch } from "../../../../../../../../../../redux/store";
import { BORDERS } from "../../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../../utils/FontUtil";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";

const NoOffers = () => {
  const { formattedCandidateDetails } = useMakeAnOffer();
  const { font14 } = FontUtil();
  return (
    <Box
      sx={{
        maxWidth: "295px",
        mx: "auto",
      }}
    >
      <Stack
        mt={3}
        direction={"row"}
        gap={3}
        sx={{
          alignItems: "cneter",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <NoInterviewIcon />
      </Stack>
      <Stack
        textAlign={"center"}
        pt={3}
        pb={5}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        <AtsText
          text={`No offers made to ${formattedCandidateDetails.name}`}
          sx={{
            ...font14,
            color: "black",
            fontWeight: 400,
          }}
        />
      </Stack>
      {/* <Stack direction={"row"} justifyContent={"center"} gap={2} pt={5}>
        <Box>
          <AtsRoundedButton
            text="Contract"
            buttonVariant="outline"
            onClick={() => {
              // dispatch(setSelectedOfferTab(0));
            }}
          />
        </Box>
        <Box>
          <AtsRoundedButton
            text="Direct Hire"
            buttonVariant="outline"
            onClick={() => {
              // dispatch(setSelectedOfferTab(1));
            }}
          />
        </Box>
      </Stack> */}
    </Box>
  );
};

export default NoOffers;
