import { Box, Collapse, Stack } from "@mui/material";
import React, { useState } from "react";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsCustomButton from "../../../components/AtsCustomButton";
import { ArrowDown2, Setting4 } from "iconsax-react";
import AtsTitleText from "../../../components/AtsTitleText";
import { useClickOutSide } from "../../../utils/common";
import { BORDERS, COLORS } from "../../../theme";

const ContractFilterSection = () => {
  const [show, setShow] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <>
      <AtsRowBetween>
        <>
          <AtsCustomButton px={0.5} hoverColor="transaparant">
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <Setting4 size={16} />
              <AtsTitleText text={"Filter"} />
            </Stack>
          </AtsCustomButton>
          <Box
            ref={domNode}
            sx={{
              position: "relative",
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                cursor: "pointer",
                px: "14px",
                py: "6px",
              }}
              onClick={() => setShow(!show)}
            >
              <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                <AtsTitleText
                  text={"Sort by:"}
                  textColor={COLORS.DARK_GRAY}
                  fs={14}
                  fw={500}
                />
                <AtsTitleText
                  text={"Newest"}
                  textColor={COLORS.DARK_GRAY}
                  fs={14}
                  fw={400}
                />
              </Stack>
              <Stack>
                <ArrowDown2 size={16} />
              </Stack>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bgcolor: "white",
                right: "0px",
                // top: "50px",
                zIndex: 9999,
                width: "-webkit-fill-available",
              }}
            >
              <Collapse
                in={show}
                sx={{
                  border: BORDERS.GRAY,

                  borderRadius: "5px",
                }}
              >
                <Stack
                  gap={"7px"}
                  sx={{
                    p: "10px",
                  }}
                >
                  {["Newest", "Oldest"].map((value, index) => (
                    <Stack
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <AtsTitleText key={index} text={value} fs={12} fw={500} />
                    </Stack>
                  ))}
                </Stack>
              </Collapse>
            </Box>
          </Box>
        </>
      </AtsRowBetween>
    </>
  );
};

export default ContractFilterSection;
