import { Stack, useMediaQuery, useTheme } from "@mui/material";
import RegistrationForm from "./RegistrationForm";

export interface RegistrastionProps {
  route: "acceptInvite" | "resetPassword";
}

const Registration = ({ route }: RegistrastionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      style={{ paddingTop: "env(safe-area-inset-top)" }}
      className="main-container"
    >
      <Stack direction={"row"} sx={{ width: "100%" }}>
        {!isMobile && (
          <Stack
            sx={{
              width: "50%",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
              src={require("../assets/images/loginImg.png")}
              alt="wsteemed"
            />
          </Stack>
        )}
        <Stack
          sx={{
            width: isMobile ? "100%" : "50%",
            display: "flex",
            background: "#fff",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <RegistrationForm route={route} />
        </Stack>
      </Stack>
    </div>
  );
};

export default Registration;
