import { Box, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

import { useState } from "react";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../../../../components/AtsRow";
import { useInterviews } from "../../../../../../../../../providers/InterviewsProvider";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { CreateScoreCardOfCandidateParams } from "../../../../../../../../../types/interviewTypes";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import JobsSwitch from "../../../../../../../../Dashboard/PostedJobs/JobsSwitch";
import InterviewDetails from "./InterviewDetails";
import Scorecard from "./Scorecard";

const InterviewInfo = () => {
  const { candidateDetails } = useAppSelector(getSubmissionState);
  const [active, setActive] = useState<string>("INTERVIEW_DETAILS");
  const {
    scroreCardOfInterviewDetails,
    scoreCardType,
    interviewInfo,
    createScorecardForCandidate,
    createScorecardLoading,
  } = useInterviews();
  const tabs = [
    {
      title: "Interview Details",
      type: "INTERVIEW_DETAILS",
    },
    {
      title: "Scorecard",
      type: "SCORECARD",
    },
  ];
  const isRatting = scoreCardType.some((type) => type.rating !== 0);

  const onSubmitRate = async () => {
    const filteredRate = scoreCardType
      .filter((type) => type.rating !== 0)
      .map((type) => ({
        type_id: Number(type.id),
        rating: type.rating,
        comments: type.comments,
      }));
    const obj = {
      candidate_id: candidateDetails?.id,
      interview_id: interviewInfo.nid,
      scorecard: filteredRate,
    } as CreateScoreCardOfCandidateParams;
    await createScorecardForCandidate(obj);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            borderRadius: 3,
            borderTopLeftRadius: 0,
            mb: 2,
            mx: 1,
          }}
        >
          <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
            <Stack sx={{ width: "100%" }}>
              <Box sx={{ py: 2, px: 2.5 }}>
                <AtsRow sx={{ mt: 2 }}>
                  <JobsSwitch
                    width="80px"
                    height="30px"
                    jobs={tabs}
                    active={active}
                    onChange={(selected) => {
                      setActive(selected);
                    }}
                  />
                </AtsRow>
                {active === "SCORECARD" ? <Scorecard /> : <InterviewDetails />}
                {active === "SCORECARD" &&
                  !isNotNullOrUndefined(scroreCardOfInterviewDetails) && (
                    <AtsRow justifyContent={"end"}>
                      <>
                        <AtsRoundedButton
                          text="Submit"
                          buttonHeight={32}
                          disabled={!isRatting || createScorecardLoading}
                          onClick={onSubmitRate}
                          loading={createScorecardLoading}
                        />
                      </>
                    </AtsRow>
                  )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

export default InterviewInfo;
