import JobCardWrapper from "../components/JobCardWrapper";
import StepCard from "../components/StepCard";

const JobStep2 = () => {
  return (
    <JobCardWrapper header="">
      <StepCard
        step="2"
        title="Help your job stand out"
        subText="Add an intro video and some images to help your listing stand out. Then, select skills, add a job title,and a job description."
      />
    </JobCardWrapper>
  );
};

export default JobStep2;
