import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MakeAnOfferServices } from "../../../../../../../Api/makeAnOfferServices";
import AtsAntSwitch from "../../../../../../../components/AtsAntSwitch";
import AtsCheckbox from "../../../../../../../components/AtsCheckbox";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsTextInput from "../../../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useMakeAnOffer } from "../../../../../../../providers/MakeAnOfferProvider";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { TermContentResponse } from "../../../../../../../types/makeAnOfferTypes";
import FontUtil from "../../../../../../../utils/FontUtil";
import JobDetailsForMakeAnOffer from "../../../../../../Jobs/Submissions/pipeline/Candidate/CandidateDetails/CandidateDetailsTabs/MakeAnOfferTab/components/JobDetailsForMakeAnOffer";
import { useRequestToConvert } from "../../../../../../../providers/RequestToConvert";
import AtsDatePicker from "../../../../../../../components/AtsDatePicker";

const text = `If you want to hire your Contractor as a permanent employee before they've worked six months, you'll need to pay a conversion fee, based on their annual compensation. Once your request to convert your Contractor is approved by your Account Manager, and after paying your Conversion Fee, your Contractor will transfer fully to you, and you will becaome responsible for their salary.`;

const RequestConvertLeftSection = () => {
  const { font16 } = FontUtil();
  const [phone, setPhone] = useState("");
  const { formattedCandidateDetails } = useMakeAnOffer();
  const { annualRate, setAnnualRate, setStartDate, startDate } =
    useRequestToConvert();
  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<TermContentResponse[]>([]);

  const getContentTerms = async () => {
    setLoading(true);
    try {
      const response = await MakeAnOfferServices.getTermContent();
      setTerms(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getContentTerms();
  }, []);

  return (
    <>
      <Stack
        sx={{
          // maxWidth: isLargeScreen ? "80%" : isDesktopScreen ? "85%" : "100%",
          height: "93%",
          overflow: "auto",
          pr: 2,
        }}
      >
        <Stack
          sx={{
            px: "30px",
            py: "30px",
            mr: "16px",
            border: BORDERS.GRAY,
            borderRadius: "12px !important",
            position: "relative",
            mb: "30px",
          }}
          gap={"30px"}
        >
          <AtsTitleText
            text={"What is the annual salary that you're offering?"}
            fs={16}
            fw={600}
          />
          <Stack gap={1}>
            <Grid container columnSpacing={"50px"} rowSpacing={"20px"}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4} xxl={4}>
                <RenderBudgetRateComponent
                  value={annualRate}
                  handleRate={(value) => setAnnualRate(value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4} xxl={4}>
                <Stack gap={0.5}>
                  <AtsTitleText
                    text={"Start date"}
                    isMandatory
                    fs={12}
                    fw={500}
                  />
                  <AtsDatePicker
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                  />
                </Stack>
              </Grid>
            </Grid>
            <AtsTitleText
              lineHeight={1.5}
              text={text}
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
          </Stack>
        </Stack>
        {/* <Stack
          sx={{
            px: "30px",
            py: "30px",
            mr: "16px",
            border: BORDERS.GRAY,
            borderRadius: "12px !important",
            position: "relative",
          }}
          gap={"20px"}
        >
          <AtsTitleText text={"Track updates"} fs={16} fw={600} />

          <Grid container columnSpacing={"50px"} rowSpacing={"20px"}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4} xxl={4}>
              <Stack gap={1}>
                <AtsRow gap={"5px"}>
                  <>
                    <AtsAntSwitch />
                    <AtsTitleText
                      text={"Your phone number"}
                      fs={12}
                      fw={600}
                      textColor={COLORS.BLACK}
                    />
                  </>
                </AtsRow>

                <PhoneInput
                  country={"us"}
                  enableSearch
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />

                <AtsTitleText
                  text={"Confirm your mobile number to receive updates."}
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4} xxl={4}>
              <Stack gap={1}>
                <AtsRow gap={"5px"}>
                  <>
                    <AtsAntSwitch />
                    <AtsTitleText
                      text={"Your email"}
                      fs={12}
                      fw={600}
                      textColor={COLORS.BLACK}
                    />
                  </>
                </AtsRow>

                <AtsTextInput
                  value={formattedCandidateDetails.email}
                  // onChange={(phone) => setPhone(phone)}
                />
                <AtsTitleText
                  text={"Confirm your email to receive updates."}
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
            </Grid>
          </Grid>
          <AtsRow gap={"10px"}>
            <>
              <AtsCheckbox p={0} />
              <AtsTitleText
                text={"Receive offer tracking updates"}
                fs={12}
                fw={500}
                textColor="#000"
              />
            </>
          </AtsRow>
          <Box
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
            }}
          >
            <AtsTitleText text={"Skip"} fs={12} fw={400} textColor="#000" />
          </Box>
        </Stack> */}
        <Stack
          mt={"30px"}
          gap={"32px"}
          sx={{
            height: "calc(100% - 140px)",

            pr: 2,
            pb: "10px",
          }}
        >
          <Accordion
            sx={{
              boxShadow: "none",
              border: BORDERS.GRAY,
              borderRadius: "12px !important",
              "&.Mui-expanded": {
                margin: "0px !important",
              },
              "::before": {
                height: "0px !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                ...font16,
                color: COLORS.BLACK,
                fontWeight: 600,
                px: "30px",
              }}
            >
              Job details
            </AccordionSummary>
            <AccordionDetails
              sx={{
                px: "30px",
              }}
            >
              {/* <JobDetailsForMakeAnOffer /> */}
            </AccordionDetails>
          </Accordion>
          {loading ? (
            <Skeleton variant="rectangular" height={30} width={"100%"} />
          ) : (
            terms.length > 0 &&
            terms.map((term) => (
              <Accordion
                key={term?.title}
                sx={{
                  boxShadow: "none",
                  border: BORDERS.GRAY,
                  borderRadius: "12px !important",
                  "&.Mui-expanded": {
                    margin: "0px !important",
                  },
                  "::before": {
                    height: "0px !important",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    ...font16,
                    color: COLORS.BLACK,
                    fontWeight: 600,
                    px: "30px",
                  }}
                >
                  {term?.title}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    px: "30px",
                  }}
                >
                  <div
                    className="jobDetails"
                    dangerouslySetInnerHTML={{ __html: term?.body }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default RequestConvertLeftSection;

const RenderBudgetRateComponent = ({
  value,
  handleRate,
}: {
  value: string;
  handleRate: (value: string) => void;
}) => {
  const formatNumber = (value) => {
    if (!value) return 0;
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = new Intl.NumberFormat("en-US").format(integerPart);
    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart.substring(0, 2)}`
      : formattedInteger;
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const formattedAmount = input
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    handleRate(formattedAmount);
  };

  return (
    <Stack gap={0.5}>
      <AtsTitleText text={"Annual rate"} isMandatory fs={12} fw={500} />
      <Stack
        direction={"row"}
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "6px",
          minHeight: 37,
          // minWidth: width,
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            width: "27px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
        >
          <AtsTitleText
            text={"$"}
            fs={18}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Box>
        <Box
          sx={{
            bgcolor: "#fff",
            fontSize: 18,
            color: COLORS.BLACK,
            fontWeight: 600,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <input
            type="text"
            className="rateInput"
            style={{
              fontWeight: 600,
              fontSize: 14,
              width: "90%",
              color: COLORS.BLACK,
            }}
            value={formatNumber(value)}
            onChange={handleChange}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
