import { Close, ExpandMore, Send } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Divider,
  IconButton,
  Modal,
  Skeleton,
  Stack,
} from "@mui/material";
import { RefreshIcon } from "../../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../theme";
import {
  Attachment,
  CreateAndUpdateProjectDocsRequest,
  DocsProps,
} from "../../../../../../types/projectTypes";
import DragAndDrop from "./DragAndDrop";
import { useState } from "react";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import { useParams } from "react-router-dom";
import { ProjectService } from "../../../../../../Api/projectServices";
import { useAppDispatch } from "../../../../../../redux/store";
import { setProjectDocsList } from "../../../../../../redux/slices/projectDetailsSlice";

interface IDocAccordionProps {
  expanded: boolean;
  loading?: boolean;
  showButton?: boolean;
  documents: DocsProps;
  handleChange: (event, isShow) => void;
  handleFileChange: (event) => void;
  handleDocumentURL: (event) => void;
  handleDrop: (event) => void;
  deleteDocument: (document, selectedAttachment) => void;
  deleteDocumentUrl: (url: string, index: number) => void;
  getDocumentList: () => void;
  removeAttachment: (index: number | string) => void;
  // files?: any[];
  setFile: React.Dispatch<React.SetStateAction<any>>;
}

const DocsAccordion = ({
  expanded,
  documents,
  loading,
  showButton,
  // files,
  // setFiles,
  handleChange,
  handleDrop,
  handleFileChange,
  handleDocumentURL,
  deleteDocument,
  deleteDocumentUrl,
  getDocumentList,
  removeAttachment,
  setFile,
}: IDocAccordionProps) => {
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [openDocModal, setOpenDocModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const [selectedAttachment, setSelectedAttachment] =
    useState<Attachment | null>(null);
  const [url, setUrl] = useState<string>("");
  const handleDocChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const regex = /^https?:\/\/[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(inputValue)) {
        setError("");
        handleDocumentURL(inputValue);
        setInputValue("");
      } else {
        setError("Please enter valid URL");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const createAndUpdateDocument = async () => {
    // setLoading(true)

    const nid = documents?.nid || documents?.nid?.length ? documents?.nid : "";
    const document_type_id =
      typeof documents?.id === "string" ? Number(documents?.id) : documents?.id;
    const document_type_parent_id =
      typeof documents?.parent_id === "string"
        ? Number(documents?.parent_id)
        : documents?.parent_id;
    const title = documents?.name;
    const description = documents?.description;
    const attachments_fid = documents?.attachments.map((attachment) =>
      typeof attachment?.fid === "string"
        ? Number(attachment.fid)
        : attachment.fid
    );
    const document_urls = documents?.document_urls;

    const policyObj = {
      ...(nid?.length && { nid: Number(nid) }),
      project_id: Number(id),
      document_type_id,
      document_type_parent_id,
      title,
      description,
      attachments_fid,
      document_urls,
    } as Partial<CreateAndUpdateProjectDocsRequest>;
    if (documents?.nid?.length) {
      await ProjectService.updateProjectDocument(policyObj);
    } else {
      await ProjectService.createProjectDocument(policyObj);
    }
    getDocumentList();
    setFile(null);
    try {
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false)
    }
  };

  const openModal = (attachment: Attachment) => {
    setOpen(true);
    setSelectedAttachment(attachment);
  };
  const openDeleteDocsModal = (url: string, index: number) => {
    deleteDocumentUrl(url, index);
    // setOpen(true);
    // setUrl(url);
  };

  return (
    <>
      <Accordion
        disableGutters
        expanded={expanded}
        onChange={handleChange}
        sx={{
          "&.MuiPaper-root": {
            boxShadow: "none !important",
            borderBottom: BORDERS.GRAY,
            backgroundColor: "transparent",
            "::before": {
              bgcolor: "transparent ",
            },
          },
        }}
      >
        <AccordionSummary
          sx={{
            px: 0,
          }}
          expandIcon={<ExpandMore />}
        >
          <AtsTitleText
            text={documents.name}
            fs={16}
            fw={600}
            textColor={COLORS.PRIMARY}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            px: 0,
          }}
        >
          <Stack gap={"15px"}>
            <Stack gap={"7px"}>
              <AtsTitleText
                text={"Upload File"}
                textColor="#000"
                fs={13}
                fw={600}
              />
              <Stack
                gap={"10px"}
                sx={{
                  borderRadius: "10px",
                  border: "1px dashed #6d6d6d",
                }}
              >
                {loading ? (
                  <Skeleton variant="rounded" width={"100%"} height={51} />
                ) : (
                  <DragAndDrop
                    handleDragOver={handleDragOver}
                    handleDrop={handleDrop}
                    handleFileChange={handleFileChange}
                  />
                )}
              </Stack>
              <AtsTitleText
                text={"Supported formats: PDF, docs, CSV"}
                textColor={COLORS.LIGHT_GRAY}
                fs={12}
                fw={500}
              />
            </Stack>
            {documents.attachments.map((attachment, index) => {
              return (
                <Stack
                  key={index}
                  sx={{
                    px: "10px",
                    py: "8px",
                    bgcolor: BG_COLORS.GRAY,
                    borderRadius: "6px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <img
                        src={require("../../../../../../assets/images/pdfIcon.png")}
                        alt="Nvidia Logo"
                        style={{ width: 15 }}
                      />
                      <AtsTitleText
                        text={attachment.url.split("/").pop()}
                        textColor="#000"
                        fs={13}
                        fw={600}
                      />
                    </Stack>
                    {documents?.nid?.length > 0 ? (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <AtsCustomButton px={0.5} py={0}>
                          <RefreshIcon />
                        </AtsCustomButton>
                        <Divider
                          variant="fullWidth"
                          orientation="vertical"
                          flexItem
                        />
                        <AtsCustomButton
                          px={0.5}
                          py={0.5}
                          onClick={() => openModal(attachment)}
                        >
                          <AtsIcon icon="icon-trash" height="16px" />
                        </AtsCustomButton>
                      </Stack>
                    ) : (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <AtsCustomButton
                          px={0.5}
                          py={0.5}
                          onClick={() => removeAttachment(attachment.fid)}
                        >
                          <AtsIcon icon="icon-close-circle" height="16px" />
                        </AtsCustomButton>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              );
            })}

            <Stack
              sx={{
                position: "relative",
                mt: "30px",
                mb: "20px",
              }}
            >
              <Divider variant="fullWidth" orientation="horizontal" flexItem />
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  right: "50%",
                  bottom: -5,
                  width: "20px",
                  bgcolor: "white",
                  textAlign: "center",
                }}
              >
                <AtsTitleText text={"OR"} textColor="#000" fs={10} fw={400} />
              </Box>
            </Stack>
            <Stack gap={"7px"}>
              <AtsTitleText
                text={"Document URL"}
                textColor="#000"
                fs={13}
                fw={600}
              />
              <Stack
                sx={{
                  px: "10px",
                  py: "8px",
                  bgcolor: BG_COLORS.GRAY,
                  borderRadius: "6px",
                }}
              >
                <input
                  placeholder="Enter document url here"
                  type="text"
                  className="customInput"
                  style={{
                    backgroundColor: BG_COLORS.GRAY,
                    height: 22,
                  }}
                  value={inputValue}
                  onChange={handleDocChange}
                  onKeyDown={handleKeyDown}
                />
              </Stack>
            </Stack>
            {documents.document_urls.map((docs, index) => {
              return (
                <Stack
                  key={index}
                  sx={{
                    px: "10px",
                    py: "8px",
                    bgcolor: BG_COLORS.GRAY,
                    borderRadius: "6px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <AtsTitleText
                        text={docs}
                        textColor="#000"
                        fs={13}
                        fw={600}
                      />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <AtsCustomButton px={0.5} py={0}>
                        <RefreshIcon />
                      </AtsCustomButton>
                      <Divider
                        variant="fullWidth"
                        orientation="vertical"
                        flexItem
                      />
                      <AtsCustomButton
                        px={0.5}
                        py={0.5}
                        onClick={() => openDeleteDocsModal(docs, index)}
                      >
                        <AtsIcon icon="icon-trash" height="16px" />
                      </AtsCustomButton>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
            {error.length > 0 && <Alert severity="error">{error}</Alert>}
            {showButton && (
              <Stack direction={"row"} alignItems={"center"}>
                <AtsRoundedButton
                  text={`Save`}
                  buttonWidth={100}
                  buttonHeight={32}
                  onClick={() => createAndUpdateDocument()}
                />
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Modal open={open} onClose={() => setOpen(!open)}>
        <Stack sx={style} gap={"20px"}>
          <AtsTitleText
            text={"Are you sure to delete this file ?"}
            fs={20}
            fw={700}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              borderTop: BORDERS.GRAY,
              pt: 2,
            }}
          >
            <AtsRoundedButton
              buttonVariant="dangerContained"
              text="Delete"
              buttonWidth={100}
              buttonHeight={32}
              onClick={async () => {
                await deleteDocument(documents, selectedAttachment);
                setOpen(!open);
              }}
            />
            <AtsRoundedButton
              buttonVariant="outline"
              text="Cancel"
              buttonWidth={100}
              buttonHeight={32}
              onClick={() => setOpen(!open)}
            />
          </Stack>
        </Stack>
      </Modal>
      <Modal open={openDocModal} onClose={() => setOpenDocModal(!openDocModal)}>
        <Stack sx={style} gap={"20px"}>
          <AtsTitleText
            text={"Are you sure to delete this document ?"}
            fs={20}
            fw={700}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              borderTop: BORDERS.GRAY,
              pt: 2,
            }}
          >
            <AtsRoundedButton
              buttonVariant="dangerContained"
              text="Delete"
              buttonWidth={100}
              buttonHeight={32}
              onClick={async () => {
                await deleteDocument(documents, selectedAttachment);
                setOpenDocModal(!openDocModal);
              }}
            />
            <AtsRoundedButton
              buttonVariant="outline"
              text="Cancel"
              buttonWidth={100}
              buttonHeight={32}
              onClick={() => setOpen(!open)}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default DocsAccordion;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};
