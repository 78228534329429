import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_GATEWAY } from "../utils/url";
import { IAuthProps } from "../Auth/LoginForm";

const Endpoint = {
  BASE: "/user/mail-login",
};

export const authService = createApi({
  reducerPath: "authService",
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY,
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data: IAuthProps) => {
        return {
          url: `${Endpoint.BASE}?_format=json`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useLoginMutation } = authService;
