import { AccountCircle, Star } from "@mui/icons-material";
import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubmissionService } from "../../../../../Api/submissionServices";
import AtsIcon from "../../../../../components/AtsIcon";
import AtsText from "../../../../../components/AtsText";
import AtsTitleText from "../../../../../components/AtsTitleText";
import {
  getInterviewState,
  setCreateInterview,
} from "../../../../../redux/slices/interviewSlice";
import {
  getSubmissionState,
  setCandidateDetails,
  setIsCandidateDetailsDrawerOpen,
  setIsCandidateDetailsLoading,
  setSkill,
  setSubmissionDetails,
} from "../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { COLORS } from "../../../../../theme";
import { CreateUpdateInterviewParams } from "../../../../../types/interviewTypes";
import { GetSubmissionResponse } from "../../../../../types/submissionTypes";
import FontUtil from "../../../../../utils/FontUtil";
import {
  formateOneDigitNum,
  isNotNullOrUndefined,
} from "../../../../../utils/stringUtils";
import { GrowthIcon, HomeIcon, InfoIcon } from "../../../assets/Icons";
import AtsUserNameTextAvatar from "../../../../../components/AtsUserNameTextAvatar";

interface Props {
  submission: GetSubmissionResponse;
}

const CandidateColumn = ({ submission }: Props) => {
  const { nid } = useParams();
  const dispatch = useAppDispatch();
  const { candidateDetails, isCandidateDetailsLoading, skill } =
    useAppSelector(getSubmissionState);
  const { createInterview } = useAppSelector(getInterviewState);
  const { font12 } = FontUtil();

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const getCandidateDetials = async (id: string) => {
    dispatch(setIsCandidateDetailsLoading(true));
    try {
      const response = await SubmissionService.getCandidateDetailsById({ id });
      if (response.results.length) {
        dispatch(setCandidateDetails(response.results[0]));
        const candidate_email = response.results[0]?.email_address;
        const updateCreateInterview = {
          ...createInterview,
          candidate_email,
          job_id: nid,
        } as CreateUpdateInterviewParams;
        dispatch(setCreateInterview(updateCreateInterview));
        dispatch(setCandidateDetails(response.results[0]));
      } else {
        dispatch(setCandidateDetails(null));
      }
    } catch (error) {
    } finally {
      dispatch(setIsCandidateDetailsLoading(false));
    }
  };

  const handleMouseEnter = (
    id: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    if (id && id.length) {
      setPopoverOpen(true);
      setAnchorEl(event.currentTarget);
      getCandidateDetials(id);
    }
  };

  const handleMouseLeave = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  const getSkills = async (candidate_id: string) => {
    setLoading(true);
    try {
      const response = await SubmissionService.getCandidateSkillsMatch({
        job_id: nid,
        candidate_id,
      });
      dispatch(
        setSubmissionDetails({
          ...submission,
        } as GetSubmissionResponse)
      );
      dispatch(setSkill(response));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    submission?.candidate_id && getSkills(submission?.candidate_id);
  }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            cursor: "pointer",
            display: "inline-block",
            padding: 10,
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <Stack>
              {isNotNullOrUndefined(submission?.image) ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 58,
                    width: 58,
                    mr: "12px",
                  }}
                >
                  <Avatar
                    src={submission?.image}
                    sx={{
                      width: 58,
                      height: 58,
                    }}
                  />
                </Box>
              ) : (
                <Stack
                  sx={{
                    mr: "12px",
                    width: 58,
                    height: 58,
                  }}
                >
                  <AtsUserNameTextAvatar
                    fs={20}
                    name={`${submission.candidate_first_name} ${submission.candidate_last_name}`}
                  />
                </Stack>
              )}
            </Stack>
            <Stack gap={1}>
              <div
                className=""
                style={{ position: "relative" }}
                onMouseEnter={(e) =>
                  handleMouseEnter(submission.candidate_id, e)
                }
                onMouseLeave={handleMouseLeave}
              >
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor="#000"
                  text={`${submission.candidate_first_name} ${submission.candidate_last_name}`}
                />
                {isPopoverOpen && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 50,
                      pt: "22px",
                      pl: "22px",
                      pr: "8px",
                      pb: "25px",
                      width: 400,
                      bgcolor: "white",
                      boxShadow: "0 4px 14px 0 rgba(0, 0, 0, 0.35)",
                      borderRadius: "10px",
                      cursor: "auto",
                      zIndex: 9999,
                    }}
                  >
                    <Stack direction={"row"} gap={2}>
                      <Stack>
                        {isCandidateDetailsLoading ? (
                          <Skeleton variant="circular" width={78} height={78} />
                        ) : isNotNullOrUndefined(candidateDetails?.image) ? (
                          <img
                            src={candidateDetails?.image}
                            alt="img"
                            style={{
                              height: "auto",
                              marginRight: "10px",
                              width: "78px",
                              borderRadius: "100%",
                            }}
                          />
                        ) : (
                          <img
                            src={require("../../../../../assets/images/candidate.png")}
                            alt="Nvidia Logo"
                            style={{
                              height: "auto",
                              marginRight: "10px",
                              width: "78px",
                              borderRadius: "100%",
                            }}
                          />
                        )}
                      </Stack>
                      <Stack>
                        {isCandidateDetailsLoading ? (
                          <Skeleton width={150} height={24} />
                        ) : (
                          <AtsTitleText
                            fs={16}
                            fw={700}
                            textColor={COLORS.BLACK}
                            text={
                              candidateDetails?.first_name +
                              " " +
                              candidateDetails?.last_name
                            }
                          />
                        )}
                        <Box sx={{ pt: "6px", pb: "10px" }}>
                          {isCandidateDetailsLoading ? (
                            <Skeleton width={120} height={20} />
                          ) : (
                            <AtsTitleText
                              text={candidateDetails?.job_title}
                              fs={14}
                              fw={400}
                              textColor={COLORS.LIGHT_GRAY}
                            />
                          )}
                        </Box>
                        <Stack direction={"row"} gap={1} pb={"10px"}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                            pl={0}
                          >
                            {isCandidateDetailsLoading ? (
                              <Skeleton width={180} height={20} />
                            ) : (
                              <>
                                <HomeIcon />
                                <AtsTitleText
                                  text={`Live in ${candidateDetails?.address_city}, ${candidateDetails?.address_country_code}`}
                                  fs={12}
                                  fw={600}
                                  textColor={COLORS.LIGHT_GRAY}
                                />
                              </>
                            )}
                          </Stack>
                        </Stack>
                        <Stack
                          direction={"row"}
                          sx={{ alignItems: "center" }}
                          gap={1}
                        >
                          {isCandidateDetailsLoading ? (
                            <Skeleton width={150} height={20} />
                          ) : (
                            <>
                              <GrowthIcon />
                              <AtsTitleText
                                text={"Top candidate in "}
                                fs={12}
                                fw={400}
                                boldFw={700}
                                textColor={"#000"}
                                boldText="2 other Jobs"
                              />
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Box py={"26px"}>
                      <Stack direction={"row"}>
                        <Stack gap={"6px"} sx={{ minWidth: 130 }}>
                          {isCandidateDetailsLoading ? (
                            <>
                              <Skeleton width={90} height={20} />
                              <Skeleton width={80} height={20} />
                            </>
                          ) : (
                            <>
                              <AtsTitleText
                                text={"Joining Info"}
                                fs={12}
                                fw={400}
                                textColor={COLORS.LIGHT_GRAY}
                              />
                              <AtsTitleText
                                text={
                                  candidateDetails?.availability &&
                                  candidateDetails?.availability[0]?.available
                                }
                                fs={14}
                                fw={500}
                                textColor={COLORS.BLACK}
                              />
                            </>
                          )}
                        </Stack>
                        <Stack gap={"6px"} sx={{ minWidth: 120, pl: "10px" }}>
                          {isCandidateDetailsLoading ? (
                            <>
                              <Skeleton width={90} height={20} />
                              <Skeleton width={80} height={20} />
                            </>
                          ) : (
                            <>
                              <AtsTitleText
                                text={"Availability"}
                                fs={12}
                                fw={400}
                                textColor={COLORS.LIGHT_GRAY}
                              />
                              <AtsTitleText
                                text={getAvailability(
                                  candidateDetails?.availability &&
                                    candidateDetails?.availability[0]
                                      ?.availability
                                )}
                                fs={14}
                                fw={500}
                                textColor={COLORS.BLACK}
                              />
                            </>
                          )}
                        </Stack>
                        <Stack gap={"6px"} sx={{ minWidth: 120, pl: "10px" }}>
                          {isCandidateDetailsLoading ? (
                            <>
                              <Skeleton width={90} height={20} />
                              <Skeleton width={80} height={20} />
                            </>
                          ) : (
                            <>
                              <AtsTitleText
                                text={"No. of Key Skills"}
                                fs={12}
                                fw={400}
                                textColor={COLORS.LIGHT_GRAY}
                              />
                              <AtsTitleText
                                text={`${skill?.matched}/${skill?.total}`}
                                fs={14}
                                fw={500}
                                textColor={COLORS.BLACK}
                              />
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                    <Stack gap={"6px"}>
                      <Stack
                        direction={"row"}
                        gap={"6px"}
                        alignItems={"center"}
                      >
                        {isCandidateDetailsLoading ? (
                          <Skeleton width={120} height={20} />
                        ) : (
                          <>
                            <InfoIcon />
                            <AtsTitleText
                              text={"Why hire me?"}
                              fs={12}
                              fw={600}
                              textColor={COLORS.PRIMARY}
                            />
                          </>
                        )}
                      </Stack>
                      {isCandidateDetailsLoading ? (
                        <Skeleton width={"100%"} height={60} />
                      ) : (
                        isNotNullOrUndefined(
                          candidateDetails?.field_ats_can_work_with_me
                        ) && (
                          <Typography
                            sx={{ fontSize: font12, fontWeight: 400 }}
                          >
                            {candidateDetails?.field_ats_can_work_with_me}
                            <span
                              style={{
                                color: COLORS.PRIMARY,
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Read More
                            </span>
                          </Typography>
                        )
                      )}
                    </Stack>
                    <Box
                      sx={{
                        position: "absolute",
                        cursor: "pointer",
                        right: 10,
                        top: 10,
                      }}
                      onClick={(e) => {
                        // dispatch(
                        //   setSubmissionDetails({
                        //     ...submission,
                        //     matched: skill?.matched ?? 0,
                        //     total: skill?.total ?? 0,
                        //   } as GetSubmissionResponse)
                        // );
                        e.stopPropagation();
                        handleMouseLeave();
                        dispatch(setIsCandidateDetailsDrawerOpen(true));
                      }}
                    >
                      {isCandidateDetailsLoading ? (
                        <Skeleton width={24} height={24} />
                      ) : (
                        <AtsIcon icon="icon-export-3" color={COLORS.PRIMARY} />
                      )}
                    </Box>
                  </Box>
                )}
              </div>

              <AtsTitleText
                fs={12}
                fw={500}
                textColor={COLORS.LIGHT_GRAY}
                text={submission?.candidate_job_title}
              />
              <Stack direction={"row"} gap={0.3}>
                <Star sx={{ fontSize: 15, color: "#ffcd34" }} />
                <AtsText
                  sx={{ fontSize: 12, fontWeight: 400, color: "#000" }}
                  text={
                    submission?.rating
                      ? formateOneDigitNum(submission?.rating)
                      : 0
                  }
                />
                <AtsText
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#6d6d6d",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                  }}
                  text="|"
                />
                {loading ? (
                  <Skeleton width={50} />
                ) : (
                  <AtsText
                    sx={{ fontSize: 12, fontWeight: 400, color: "#000" }}
                    text={`${skill?.matched}/${skill?.total} Skills`}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default CandidateColumn;

export const getAvailability = (availability: string) => {
  if (!availability) return "";
  const numberOnly = availability.split(" ")[0];
  const convertNum = parseInt(numberOnly);
  if (convertNum > 30) {
    return "Full - Time";
  } else {
    return "Part - Time";
  }
};
