import { IconButton, Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import JobCardWrapper from "../components/JobCardWrapper";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";
import { Add, Remove } from "@mui/icons-material";

const DaysPerWeek = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);

  const handleHours = (action: string) => {
    const parseHours = Number(postOpportunity.job_hours_per_day);
    if (action === "MINUS") {
      const minusValue = parseHours - 1;
      const opportunityObj = {
        ...postOpportunity,
        job_hours_per_day: minusValue.toString(),
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const addValue = parseHours + 1;
      const opportunityObj = {
        ...postOpportunity,
        job_hours_per_day: addValue.toString(),
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };
  return (
    <>
      <JobCardWrapper
        header="How many hours per day?"
        subText="Select how many hours per day you'll need the resource"
      >
        <Stack pr={1} gap={2}>
          <Stack gap={1}>
            <Stack
              direction={"row"}
              gap={4}
              alignItems={"center"}
              sx={{
                border: BORDERS_2X.GRAY,
                px: 1,
                borderRadius: 2,
                width: "fit-content",
                minHeight: 49,
              }}
            >
              <IconButton
                disabled={postOpportunity.job_hours_per_day === "0"}
                onClick={() => handleHours("MINUS")}
              >
                <Remove sx={{ color: "#231F20" }} />
              </IconButton>
              <AtsTitleText
                text={postOpportunity.job_hours_per_day}
                textColor="#231F20"
                fs={16}
                fw={600}
              />
              <IconButton
                onClick={() => handleHours("ADD")}
                disabled={postOpportunity.job_hours_per_day === "8"}
              >
                <Add sx={{ color: "#231F20" }} />
              </IconButton>
            </Stack>
            <AtsTitleText text={`Hour(s) per day`} fs={12} fw={400} />
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default DaysPerWeek;
