import { Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../../../../components/AtsTitleText";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import { BORDERS, COLORS } from "../../../../../../../../../../theme";
import { formattedAmount } from "../../../../../../../../../../utils/common";

const OfferDetailsInfo = () => {
  const { formattedCandidateDetails, selectedOffer } = useMakeAnOffer();
  const durationText =
    selectedOffer?.payment_type !== "Hourly" ? "Duration" : "Start Date";
  const rate =
    selectedOffer?.payment_type !== "Hourly"
      ? `$ ${formattedAmount(selectedOffer?.salary)}/ yr`
      : `$ ${formattedAmount(selectedOffer?.rate)}/ per hr`;
  return (
    <>
      <Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText
            fs={18}
            fw={600}
            text="Offer Details"
            textColor="#000"
          />
        </Stack>
        <Stack gap={2} sx={{ pt: 2, pb: 2, borderBottom: BORDERS.GRAY }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsTitleText
              fs={14}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text={durationText}
            />
            <AtsTitleText
              fs={14}
              fw={400}
              text={formattedCandidateDetails?.duration}
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsTitleText
              fs={14}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Hour per week"
            />
            <AtsTitleText
              fs={14}
              fw={400}
              text={formattedCandidateDetails?.hourPerWeek}
            />
          </Stack>
        </Stack>
        <Stack gap={2} sx={{ pb: 2, mt: 2, borderBottom: BORDERS.GRAY }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsTitleText
              fs={14}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text={
                selectedOffer?.payment_type !== "Hourly"
                  ? "Salary"
                  : "Rate per hour"
              }
              isUnderline
            />
            <AtsTitleText fs={14} fw={400} text={rate} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default OfferDetailsInfo;
