import { Box, Skeleton, Stack } from "@mui/material";

import AtsCard from "../AtsCard";

const LoadingReminderCard = () => {
  return (
    <Box
      px={1}
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      <AtsCard
        sx={{
          height: "86px",
        }}
      >
        <Stack direction={"row"} gap={1}>
          {/* Icon Skeleton */}
          <Skeleton variant="circular" width={24} height={24} />
          <Stack gap={1}>
            <Skeleton variant="text" width="150px" height={20} />
            <Skeleton
              variant="rounded"
              width="80px"
              height="24px"
              sx={{
                px: "10px",
                py: "3px",
                borderRadius: "4px",
              }}
            />
          </Stack>
        </Stack>
      </AtsCard>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 9,
          width: "24px",
          height: "20px",
          textAlign: "center",
          borderBottomRightRadius: "10px",
          borderTopLeftRadius: "10px",
          cursor: "pointer",
          bgcolor: "var(--skeleton-bg)",
        }}
      >
        <Skeleton
          variant="circular"
          width={12}
          height={12}
          sx={{
            position: "absolute",
            top: "4px",
            left: "6px",
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingReminderCard;
