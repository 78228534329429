import JobCardWrapper from "../components/JobCardWrapper";
import StepCard from "../components/StepCard";

const JobStep1 = () => {
  return (
    <JobCardWrapper header="">
      <StepCard
        step="1"
        title="Tell us about your opportunity"
        subText="Choose your job’s category, type, location, and rate."
      />
    </JobCardWrapper>
  );
};

export default JobStep1;
