import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRow from "../../../components/AtsRow";
import { formattedAmount } from "../../../utils/common";
import { InfoCircle } from "iconsax-react";
import { useInsights } from "../../../providers/InsightsProvider";
import { Skeleton } from "@mui/material";
import HiringFunnelLoadingState from "../components/HiringFunnelLoadingState";
import AtsEmptyState from "../../../components/AtsEmptyState";
import HiringFunnelList from "../components/HiringFunnelList";

const HiringFunnel = () => {
  const { othersInsightsData, othersInsightsLoading } = useInsights();

  return (
    <>
      <Box
        sx={{
          p: "25px",
          border: BORDERS.GRAY,
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        <Stack gap={"15px"}>
          <AtsTitleText text={"Hiring funnel"} fs={18} fw={600} />
          {othersInsightsLoading ? (
            [...Array(5)].map((_, index) => (
              <HiringFunnelLoadingState key={index} />
            ))
          ) : othersInsightsData &&
            !Array.isArray(othersInsightsData.summary_and_hiring_funnel) ? (
            <HiringFunnelList
              hiringFunnelData={othersInsightsData.summary_and_hiring_funnel}
            />
          ) : (
            <AtsEmptyState
              text="No Hiring funnels data"
              subText=""
              imgHeight={100}
              imgWidth={100}
            />
          )}
          {}
        </Stack>
      </Box>
    </>
  );
};

export default HiringFunnel;
