import React, { useRef } from "react";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { COLORS } from "../../../../../../theme";
import { Stack } from "@mui/material";

interface DragDropProps {
  handleDrop: (e) => void;
  handleDragOver: (e) => void;
  handleFileChange: (e) => void;
  fileInputRef?: any;
}

const DragAndDrop = ({
  handleDragOver,
  handleDrop,
  handleFileChange,
}: // fileInputRef,
DragDropProps) => {
  const randomNumber = Math.random();
  return (
    <>
      <label
        htmlFor={`chooseVideo-${randomNumber}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "center",
          cursor: "pointer",
          marginBottom: 0,
          padding: "15px 0px",
        }}
      >
        <AtsIcon
          icon="icon-arrow-up-1"
          height="18px"
          fs={18}
          color={COLORS.LIGHT_GRAY}
          fw={400}
        />

        <Stack direction={"row"} gap={"5px"} mt={"4px"}>
          <AtsTitleText text={"Drag & Drop or"} fs={12} fw={600} />
          <AtsTitleText
            text={"Upload from your device"}
            fs={12}
            fw={600}
            textColor={COLORS.PRIMARY}
            isLink
            isUnderline
            // onClick={triggerFileInput}
          />
        </Stack>
        <input
          multiple
          type="file"
          name=""
          accept=".pdf,.csv,.doc"
          id={`chooseVideo-${randomNumber}`}
          hidden
          // ref={fileInputRef}
          onChange={handleFileChange}
        />
      </label>
    </>
  );
};

export default DragAndDrop;
