import { Box, Stack } from "@mui/material";
import AtsRoundedSearchInput from "../../../components/AtsRoundedSearchInput";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import { BillingTabsEnums } from "../../../providers/BillingProvider";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { BORDERS } from "../../../theme";
import BillingInvoicesList from "../components/BillingInvoicesList";

const InvoicesList = () => {
  const { selectedBillingTab, invoiceId, setInvoiceId, invoices } =
    useInvoices();
  return (
    <>
      <Stack gap={"16px"}>
        <AtsTitleText
          fs={18}
          fw={600}
          textColor={"#000"}
          text={
            selectedBillingTab === BillingTabsEnums.BillingDetails
              ? "Invoices Issued"
              : "Upcoming Invoices"
          }
        />
        <Box
          sx={{
            border: BORDERS.GRAY,
            borderRadius: "6px",
            px: "30px",
            pt: "35px",
            pb: "24px",
          }}
        >
          <AtsRowBetween
            sx={{
              pb: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <>
              <AtsTitleText
                fs={16}
                fw={600}
                textColor={"#000"}
                text={`${invoices?.length} ${
                  invoices?.length === 1 ? "invoice" : "invoices"
                }`}
              />
              <AtsRoundedSearchInput
                placeholder="Search invoice number"
                value={invoiceId}
                onChange={(e) => setInvoiceId(e.target.value)}
              />
            </>
          </AtsRowBetween>
          <BillingInvoicesList />
        </Box>
      </Stack>
    </>
  );
};

export default InvoicesList;
