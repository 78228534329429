import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface MakeAnOferState {
  isOpenMakeAnOfferDialog: boolean;
}

const initialState: MakeAnOferState = {
  isOpenMakeAnOfferDialog: false,
};

const makeAnOfferSlice = createSlice({
  name: "makeAnOffer",
  initialState,
  reducers: {
    setIsOpenMakeAnOfferDialog: (state: MakeAnOferState, action) => {
      const { payload } = action;
      state.isOpenMakeAnOfferDialog = payload;
    },
  },
});

export const { setIsOpenMakeAnOfferDialog } = makeAnOfferSlice.actions;
export const getMakeAnOfferState = (state: RootState) => state.makeAnOffer;
export default makeAnOfferSlice.reducer;
