/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import AtsText from "../../../components/AtsText";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";
import FontUtil from "../../../utils/FontUtil";
import { ResourcesProps } from "./ResourcesTips";
import { Close } from "@mui/icons-material";
import { getDateFormatMMMMDYYYYY } from "../../../utils/dateUtils";
import AtsTitleText from "../../../components/AtsTitleText";

interface ICardProps {
  text: string;
  image: string;
  buttonText: string;
  resourcesData: Partial<ResourcesProps>;
  loading: boolean;
}

const ResourcesTipsCard = ({
  text,
  image,
  buttonText,
  resourcesData,
  loading,
}: ICardProps) => {
  const { font14, font12, font16, font24 } = FontUtil();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Card
        sx={{
          // maxWidth: 345,

          borderRadius: "10px",
        }}
      >
        <CardActionArea
          className="cardHover"
          sx={{
            ":hover": {
              bgcolor: "transparent !important", // Set the hover background color to transparent
            },
          }}
        >
          {loading ? (
            <Skeleton variant="rectangular" width={"100%"} height={180} />
          ) : (
            <CardMedia component="img" image={image} />
          )}
          <CardContent
            sx={{
              minHeight: 42,
              ".MuiCardActionArea-root": {
                ":hover": {
                  bgcolor: "white !important",
                },
              },
            }}
          >
            {loading ? (
              <Skeleton variant="text" width={"100%"} height={"100%"} />
            ) : (
              <AtsText
                text={text}
                sx={{
                  ...font14,
                  fontWeight: 600,
                }}
              />
            )}
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            px: 2,
            pb: 2,
          }}
        >
          {loading ? (
            <Skeleton variant="text" width={"50%"} height={"100%"} />
          ) : (
            <AtsText
              onClick={() => setOpen(true)}
              text={buttonText}
              sx={{
                ...font12,
                fontWeight: 600,
                color: COLORS.PRIMARY,
                cursor: "pointer",
              }}
            />
          )}
        </CardActions>
      </Card>
      <Dialog open={open} fullScreen onClose={() => setOpen(false)}>
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 5,
            py: 2,
            borderBottom: BORDERS.GRAY,
          }}
        >
          <Box
            sx={{
              height: "50px",
            }}
          >
            <img
              height={"100%"}
              src={require("../../../assets/images/logo.png")}
              alt="esteemed"
            />
          </Box>
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 83px)",
            overflow: "scroll",
          }}
        >
          <Container maxWidth="md">
            <Stack
              my={2}
              pt={5}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Stack gap={2} textAlign={"center"}>
                <AtsText
                  text={resourcesData?.category ?? ""}
                  sx={{ ...font16, color: COLORS.GREEN, fontWeight: 400 }}
                />
                <AtsTitleText
                  text={resourcesData?.title ?? ""}
                  fs={48}
                  fw={600}
                  textColor={COLORS.BLACK}
                />
                <Stack direction={"row"} justifyContent={"center"} gap={1}>
                  <AtsText
                    text={resourcesData?.authorName ?? ""}
                    sx={{
                      ...font24,
                      color: COLORS.BLACK,
                      fontWeight: 600,
                      pr: 2,
                      borderRight: BORDERS_2X.DARK_GRAY,
                    }}
                  />

                  <AtsText
                    sx={{
                      ...font24,
                      color: COLORS.BLACK,
                      fontWeight: 600,
                      pl: 1,
                    }}
                    text={
                      resourcesData?.created
                        ? getDateFormatMMMMDYYYYY(resourcesData?.created)
                        : ""
                    }
                  />
                </Stack>
              </Stack>
              <Stack sx={{ py: 3, px: 5 }}>
                <img
                  src={resourcesData?.image ?? ""}
                  alt="resorcesImg"
                  style={{
                    borderRadius: "10px",
                  }}
                />
              </Stack>
              <Stack sx={{ px: 5 }}>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: resourcesData?.body ?? "",
                  }}
                ></div>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </Dialog>
    </>
  );
};

export default ResourcesTipsCard;
