/* eslint-disable jsx-a11y/iframe-has-title */
import { SaveAlt } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";

import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import AtsBackLink from "../../../../../../components/AtsBackLink";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { useInvoices } from "../../../../../../providers/InvoicesProvider";
import { BORDERS, COLORS } from "../../../../../../theme";
import { PROJECT_BASE_URL } from "../../../../../../utils/url";
import InvoiceInfo from "../components/DetailsOfInvoiceComponents/InvoiceInfo";
import InvoiceItemsList from "../components/DetailsOfInvoiceComponents/InvoiceItemsList";
import TotalOfInvoice from "../components/DetailsOfInvoiceComponents/TotalOfInvoice";

interface IProps {
  bottom: number;
}

const InvoiceDetails = ({ bottom }: IProps) => {
  const {
    setIsInvoiceView,
    getInvoiceDetails,
    selectedInvoice,
    invoiceDetails,
    proceedToPayment,
  } = useInvoices();

  useEffect(() => {
    selectedInvoice && getInvoiceDetails(selectedInvoice.invoice_no);
  }, []);
  const token = localStorage.getItem("accessToken");
  const invoiceRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef: invoiceRef,
  });

  return (
    <Box
      mb={"10px"}
      sx={{
        height: "100%",
      }}
    >
      {" "}
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={{ xs: "center", md: "space-between" }}
        pt={"4px"}
        pb={"18px"}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          gap={"20px"}
        >
          <AtsTitleText
            text={`#${selectedInvoice?.invoice_no}`}
            fs={18}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Divider variant="fullWidth" orientation="vertical" flexItem />
          <AtsRoundedButton
            text={selectedInvoice?.status}
            buttonVariant={
              selectedInvoice?.status === "Not paid"
                ? "primaryContained"
                : "greenContained"
            }
            buttonHeight={30}
          />
          {selectedInvoice?.status === "Not paid" && (
            <>
              <Divider variant="fullWidth" orientation="vertical" flexItem />
              <AtsRoundedButton
                text="Proceed to Payment"
                buttonHeight={30}
                buttonWidth={175}
                onClick={proceedToPayment}
              />
            </>
          )}
        </Stack>
        <Box>
          <AtsBackLink
            text="Back to invoices"
            onClick={() => setIsInvoiceView(false)}
          />
        </Box>
      </Stack>
      <Box
        className="scrollHide"
        sx={{
          borderRadius: "6px",
          border: BORDERS.GRAY,
          position: "relative",
          // pb: "54px",
          bgcolor: "white",
          // height: "calc(100% - 52px)",
          height: "91%",
          overflow: "auto",
        }}
      >
        <div
          ref={invoiceRef}
          className=""
          style={{
            paddingTop: "39px",
            paddingBottom: "54px",
          }}
        >
          <AtsRowBetween px={"40px"}>
            <>
              <img
                src={require("../../../../../../assets/images/logo.png")}
                alt="Nvidia Logo"
                style={{ width: 257 }}
              />
              <Stack>
                <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                  {invoiceDetails.length > 0 && (
                    <AtsCustomButton
                      px={0.5}
                      py={0.5}

                      // onClick={handleDownloadPdf}
                    >
                      <a
                        href={`${PROJECT_BASE_URL}${invoiceDetails[0].pdf_link}?token=${token}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SaveAlt
                          sx={{
                            fontSize: 16,
                            color: COLORS.LIGHT_GRAY,
                          }}
                        />
                      </a>
                    </AtsCustomButton>
                  )}
                  <AtsCustomButton px={0.5} py={0.5} onClick={handlePrint}>
                    <AtsIcon icon="icon-printer" height="14px" />
                  </AtsCustomButton>
                </Stack>
              </Stack>
            </>
          </AtsRowBetween>
          <Box pb={"50px"} px={"40px"}>
            <InvoiceInfo />
          </Box>
          <Box pb={"50px"} px={"40px"}>
            <InvoiceItemsList />
          </Box>
          <Box px={"40px"}>
            <TotalOfInvoice />
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default InvoiceDetails;
