interface IProps {
  strokeColor?: string;
  isActive?: boolean;
  className?: string;
  width?: string;
  height?: string;
}

export const StarIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01 2.548 9.035 4.6c.14.286.513.56.828.612l1.86.31c1.19.198 1.47 1.061.613 1.913l-1.446 1.447c-.245.245-.38.717-.304 1.055l.415 1.791c.326 1.418-.426 1.966-1.68 1.225l-1.745-1.032c-.315-.187-.834-.187-1.155 0l-1.744 1.032c-1.248.741-2.006.187-1.68-1.225l.414-1.79c.076-.339-.058-.811-.303-1.056L1.662 7.436c-.851-.852-.577-1.715.613-1.913l1.86-.31c.31-.052.683-.326.823-.612l1.027-2.053c.56-1.115 1.47-1.115 2.024 0z"
        fill="#FFB72D"
      />
    </svg>
  );
};
export const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m6.713 2.38-4.62 3.7c-.52.413-.853 1.286-.74 1.94l.887 5.306c.16.947 1.067 1.713 2.027 1.713h7.466c.954 0 1.867-.773 2.027-1.713l.887-5.307c.106-.653-.227-1.526-.74-1.94l-4.62-3.693c-.714-.573-1.867-.573-2.574-.007z"
        stroke="#6D6D6D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.833A1.667 1.667 0 1 0 8 7.5a1.667 1.667 0 0 0 0 3.333z"
        stroke="#6D6D6D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const WarningIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.666c3.666 0 6.666-3 6.666-6.666 0-3.667-3-6.667-6.666-6.667-3.667 0-6.667 3-6.667 6.667 0 3.666 3 6.666 6.667 6.666zM8 5.333v3.333"
        stroke="#EC7249"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.996 10.667h.006"
        stroke="#EC7249"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const GrowthIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="4" fill="#FFC9C9" />
      <path
        d="m13.8 6-5.04 5.04-1.92-2.88L3 12"
        stroke="red"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M11.4 6h2.4v2.4"
        stroke="red"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const InfoIcon = ({ strokeColor = "#36C" }: IProps) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1.666c-3.208 0-5.833 2.625-5.833 5.834 0 3.208 2.625 5.833 5.833 5.833s5.833-2.625 5.833-5.833c0-3.209-2.625-5.834-5.833-5.834zM7 9.833V6.916"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.003 5.167h-.005"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
