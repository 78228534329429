import { Box, Collapse, IconButton, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectService } from "../../Api/projectServices";
import AtsTextInput from "../../components/AtsTextInput";
import AtsTitleText from "../../components/AtsTitleText";
import { useDebounce } from "../../hooks/useDebounce";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import { ProjectListParams, ProjectListProps } from "../../types/projectTypes";
import { useClickOutSide } from "../../utils/common";
import InfiniteScroll from "react-infinite-scroll-component";
import AtsRow from "../../components/AtsRow";
import { isNotNullOrUndefined } from "../../utils/stringUtils";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AtsRowBetween from "../../components/AtsRowBetween";

interface IProps {
  onSelect?: (selected: string) => void;
  selected: string;
  preProject?: ProjectListProps[];
  setPreProjects: React.Dispatch<React.SetStateAction<ProjectListProps[]>>;
}

const ProjectSelector = ({
  onSelect,
  selected,
  setPreProjects,
  preProject,
}: IProps) => {
  const [projects, setProjects] = useState<ProjectListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedProject, setSelectedProject] =
    useState<ProjectListProps | null>(null);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const debouncedSearch = useDebounce(searchValue);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const fetchMoreData = () => {
    if (totalItem !== projects.length) {
      setPage((prevPage) => prevPage + 1); // Increment the page
    }
  };

  const handleSelect = (select: string) => {
    const findeProject = projects.find((project) => project.nid === select);
    setSelectedProject(findeProject);
    setPreProjects(projects);
    setSearchValue("");
    if (onSelect) {
      onSelect(select);
    }
  };
  const handleDelete = () => {
    setSelectedProject(null);
    if (onSelect) {
      onSelect("");
    }
  };

  const getProjects = async () => {
    setIsLoading(true);
    try {
      const projectState = {
        page,
        items_per_page: 5,
        ...(searchValue.length && { title: debouncedSearch }),
      } as Partial<ProjectListParams>;
      const response = await ProjectService.getProjectList(projectState);
      console.log("response.results", response.results);
      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setProjects(response.results);
        } else {
          setProjects([...projects, ...response.results]);
        }
        setFocusedIndex(0);
      } else {
        setProjects([]);
        setTotalItem(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selected.length) {
      const findeProject = preProject.find(
        (project) => project.nid === selected
      );
      setSelectedProject(findeProject);
    }
  }, [selected]);

  useEffect(() => {
    getProjects();
  }, [debouncedSearch, page]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (projects.length === 0) return;

      switch (event.key) {
        case "ArrowUp":
          setFocusedIndex((prevIndex) =>
            prevIndex === null || prevIndex === 0
              ? projects.length - 1
              : prevIndex - 1
          );
          break;
        case "ArrowDown":
          setFocusedIndex((prevIndex) =>
            prevIndex === null || prevIndex === projects.length - 1
              ? 0
              : prevIndex + 1
          );
          break;
        case "Enter":
          if (focusedIndex !== null) {
            handleSelect(projects[focusedIndex].nid);
            setShow(false);
          }
          break;
        default:
          break;
      }
    };

    if (show) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedIndex, projects, show]);
  return (
    <>
      <div ref={domNode} style={{ position: "relative" }}>
        <AtsRowBetween
          sx={{
            height: "42px",
            border: BORDERS.GRAY,

            borderRadius: "8px",
            px: "20px",
          }}
        >
          <>
            <AtsRow
              sx={{
                flex: 1,
              }}
            >
              <>
                {isNotNullOrUndefined(selectedProject) && (
                  <AtsRow
                    gap={"5px"}
                    sx={{
                      bgcolor: BG_COLORS.PRIMARY,
                      py: "2px",
                      pl: "8px",
                      borderRadius: "5px",
                    }}
                  >
                    <>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          border: BORDERS.GRAY,
                        }}
                      >
                        <img
                          width={"100%"}
                          style={{
                            borderRadius: "50%",
                          }}
                          src={
                            isNotNullOrUndefined(selectedProject.project_image)
                              ? selectedProject.project_image
                              : require("../../assets/images/projectImg.png")
                          }
                          alt=""
                        />
                      </Box>
                      <AtsTitleText
                        text={selectedProject.title}
                        fs={12}
                        fw={500}
                        textColor={COLORS.PRIMARY}
                      />
                      <IconButton
                        sx={{
                          p: "3px",
                        }}
                        onClick={handleDelete}
                      >
                        <Close
                          sx={{
                            fontSize: 14,
                            color: COLORS.PRIMARY,
                          }}
                        />
                      </IconButton>
                    </>
                  </AtsRow>
                )}
                <input
                  className="customInput"
                  placeholder={"Search project name"}
                  value={searchValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setShow(true);
                    } else {
                      setShow(false);
                    }
                    setSearchValue(value);
                    setPage(0);
                  }}
                  style={{
                    flex: 1,
                    height: "25px",
                  }}
                />
              </>
            </AtsRow>
            <IconButton
              sx={{
                p: "3px",
              }}
              onClick={() => setShow(!show)}
            >
              {show ? (
                <KeyboardArrowUp
                  sx={{
                    // fontSize: 14,
                    color: COLORS.DARK_GRAY,
                  }}
                />
              ) : (
                <KeyboardArrowDown
                  sx={{
                    // fontSize: 14,
                    color: COLORS.DARK_GRAY,
                  }}
                />
              )}
            </IconButton>
          </>
        </AtsRowBetween>

        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: "6px",
          }}
          in={show}
        >
          <Stack
            id="projectDiv"
            // onScroll={loadMoreItems}
            style={{
              maxHeight: 200,
              padding: "10px",
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreData}
              hasMore={projects.length < totalItem}
              loader={<h4>Loading...</h4>}
              scrollableTarget="projectDiv"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Stack gap={"5px"}>
                {projects.length ? (
                  projects.map((option: ProjectListProps, index) => (
                    <MenuItem
                      key={index}
                      value={option.nid}
                      onClick={() => {
                        handleSelect(option.nid);
                        setShow(false);
                      }}
                      sx={{
                        borderRadius: "6px",
                        bgcolor:
                          selected === option.nid
                            ? BG_COLORS.PRIMARY
                            : focusedIndex === index
                            ? "rgba(0, 0, 0, 0.1)" // Highlight focused item
                            : BG_COLORS.GRAY,
                      }}
                    >
                      <AtsRow gap={"10px"}>
                        <>
                          <Box
                            sx={{
                              width: 25,
                              height: 25,
                              borderRadius: "50%",
                              border: BORDERS.GRAY,
                            }}
                          >
                            <img
                              width={"100%"}
                              style={{
                                borderRadius: "50%",
                              }}
                              src={
                                isNotNullOrUndefined(option.project_image)
                                  ? option.project_image
                                  : require("../../assets/images/projectImg.png")
                              }
                              alt=""
                            />
                          </Box>
                          <AtsTitleText
                            text={option.title}
                            fs={14}
                            fw={500}
                            textColor={
                              selected === option.nid
                                ? COLORS.PRIMARY
                                : COLORS.BLACK
                            }
                          />
                        </>
                      </AtsRow>
                    </MenuItem>
                  ))
                ) : (
                  <AtsTitleText text={"No Result"} />
                )}
              </Stack>
            </InfiniteScroll>
          </Stack>
        </Collapse>
      </div>
    </>
  );
};

export default ProjectSelector;
