import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import { BORDERS, COLORS } from "../theme";

interface ITimePicker extends TimePickerProps<Dayjs> {
  fs?: string;
  fw?: number;
  height?: string;
}

const AtsTimePicker = ({
  fs = "16px",
  fw = 500,
  height = "40px",
  ...rest
}: ITimePicker) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          {...rest}
          slots={{}}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: fs,

              color: "#000",
              fontWeight: fw,
              py: "6.5px",
              "::placeholder": {
                fontSize: "12px",
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: BORDERS.GRAY,
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              height,
              "&.Mui-focused fieldset": {
                border: BORDERS.GRAY,
              },
              ":hover fieldset": {
                border: BORDERS.GRAY,
              },
              "& .MuiInputBase-inputMultiline": {
                "::placeholder": {
                  fontSize: "14px",
                  color: COLORS.LIGHT_GRAY,
                  fontWeight: 400,
                },
              },
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
                color: COLORS.BLACK,
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default AtsTimePicker;
