import { Box, Collapse, Stack } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import { useMemo, useState } from "react";
import AtsRoundedSearchInput from "../../../components/AtsRoundedSearchInput";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { useClickOutSide } from "../../../utils/common";
import ProjectSelectForSOWContract from "../components/ProjectSelectForSOWContract";
import ContractorSelector from "../components/ContractorSelector";
import ContractsSortingSelector from "../components/ContractsSortingSelector";

const StatementWorkFilter = () => {
  const [show, setShow] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const projectSelectorMemo = useMemo(
    () => <ProjectSelectForSOWContract />,
    []
  );
  const contractorSelectorMemo = useMemo(() => <ContractorSelector />, []);
  const contractsSortingSelectorMemo = useMemo(
    () => <ContractsSortingSelector />,
    []
  );

  return (
    <>
      <AtsRowBetween>
        <>
          <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
            {projectSelectorMemo}
            {contractorSelectorMemo}
          </Stack>
          {contractsSortingSelectorMemo}
        </>
      </AtsRowBetween>
    </>
  );
};

export default StatementWorkFilter;
