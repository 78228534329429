import axios, { AxiosHeaders } from "axios";
import { PaginatedResponse, TitleIdObjResponse } from "../types/commonType";
import {
  CompanyDetailsResponse,
  GetJobCategoriesParamsRequest,
  GetJobDetailsByIdRequest,
  GetJobgsListParamsRequest,
  GetJobTitleParamsRequest,
  JobCategoriesResponse,
  JobDetailsResponse,
  JobIndustriesResponse,
  JobListing,
  JobListingResponse,
  JobStatisticsResponse,
} from "../types/jobsTypes";
import { PostOpportunityProps } from "../redux/slices/postOpportunitySlice";

const Endpoint = {
  BASE: "/ats/api/v1/",
  ALL_JOBS: "jobs/get/",
  MY_JOB: "my-jobs/get?_format=json",
  ARCHIVED_JOB: "my-archived-jobs/get?_format=json",
  ALL_JOB: "all-jobs/get?_format=json",
  JOB_DETAILS: "job-details/get?_format=json",
  JOB_TITLES: "job-titles/get",
  JOB_INDUSTRY: "job-industry/get",
  JOB_SKILLS: "skills/get",
  JOB_HOURS_PER_WEEK: "job-hours-per-week",
  JOB_DAYS_PER_WEEK: "job-days-per-week",
  JOB_PAYMENT_TYPE: "job-payment-type",
  JOB_BENIFITS: "job-benifits",
  JOB_CATEGORY: "job-category?_format=json",
  JOB_TYPES: "job-types?_format=json",
  JOB_PRIORITY: "job-priority?_format=json",
  JOB_CONTRACT_TYPES: "job-contract-types?_format=json",
  JOB_PERIOD_OF_TIME: "job-period-of-time?_format=json",
  JOB_STATISTICS: "job-statistics/get?_format=json",
  COMPANY_DETAILS: "company-details/get?_format=json",
  JOB_WORK_MODE: "job-work-mode?_format=json",
  CREATE_OPPORTUNITY: "opportunity/create?_format=json",
  UPDATE_OPPORTUNITY: "opportunity/update?_format=json",
  EDUCATIONAL_REQUIRMENT: "educational-requirements",
  YEAR_OF_EXEPERIANCE: "years-of-experience",
};

const ALL_JOBS_URL = Endpoint.BASE + Endpoint.ALL_JOBS;
const MY_JOB_URL = Endpoint.BASE + Endpoint.MY_JOB;
const ARCHIVED_JOB_URL = Endpoint.BASE + Endpoint.ARCHIVED_JOB;
const ALL_JOB_URL = Endpoint.BASE + Endpoint.ALL_JOB;
const JOB_DETAILS_URL = Endpoint.BASE + Endpoint.JOB_DETAILS;
const JOB_INDUSTRY_URL = Endpoint.BASE + Endpoint.JOB_INDUSTRY;
const JOB_HOURS_PER_WEEK_URL = Endpoint.BASE + Endpoint.JOB_HOURS_PER_WEEK;
const JOB_DAYS_PER_WEEK_URL = Endpoint.BASE + Endpoint.JOB_DAYS_PER_WEEK;
const JOB_PAYMENT_TYPE_URL = Endpoint.BASE + Endpoint.JOB_PAYMENT_TYPE;
const JOB_BENIFITS_URL = Endpoint.BASE + Endpoint.JOB_BENIFITS;
const JOB_SKILLS_URL = Endpoint.BASE + Endpoint.JOB_SKILLS;
const JOB_TITLES_URL = Endpoint.BASE + Endpoint.JOB_TITLES;
const JOB_CATEGORY_URL = Endpoint.BASE + Endpoint.JOB_CATEGORY;
const JOB_TYPES_URL = Endpoint.BASE + Endpoint.JOB_TYPES;
const JOB_PRIORITY_URL = Endpoint.BASE + Endpoint.JOB_PRIORITY;
const JOB_CONTRACT_TYPES_URL = Endpoint.BASE + Endpoint.JOB_CONTRACT_TYPES;
const JOB_PERIOD_OF_TIME_URL = Endpoint.BASE + Endpoint.JOB_PERIOD_OF_TIME;
const JOB_STATISTICS_URL = Endpoint.BASE + Endpoint.JOB_STATISTICS;
const COMPANY_DETAILS_URL = Endpoint.BASE + Endpoint.COMPANY_DETAILS;
const JOB_WORK_MODE_URL = Endpoint.BASE + Endpoint.JOB_WORK_MODE;
const CREATE_OPPORTUNITY_URL = Endpoint.BASE + Endpoint.CREATE_OPPORTUNITY;
const UPDATE_OPPORTUNITY_URL = Endpoint.BASE + Endpoint.UPDATE_OPPORTUNITY;
const EDUCATIONAL_REQUIRMENT_URL =
  Endpoint.BASE + Endpoint.EDUCATIONAL_REQUIRMENT;
const YEAR_OF_EXEPERIANCE_URL = Endpoint.BASE + Endpoint.YEAR_OF_EXEPERIANCE;

export class JobsService {
  static async getAllJobList(
    request: Partial<GetJobgsListParamsRequest>
  ): Promise<PaginatedResponse<JobListing>> {
    const endpoint = ALL_JOBS_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Basic ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobList(
    request: Partial<GetJobgsListParamsRequest>
  ): Promise<PaginatedResponse<JobListingResponse>> {
    const endpoint = MY_JOB_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobListForArchived(
    request: Partial<GetJobgsListParamsRequest>
  ): Promise<PaginatedResponse<JobListingResponse>> {
    const endpoint = ARCHIVED_JOB_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getJobListForAllJobs(
    request: Partial<GetJobgsListParamsRequest>
  ): Promise<PaginatedResponse<JobListingResponse>> {
    const endpoint = ALL_JOB_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobDetailsById(
    request: Partial<GetJobDetailsByIdRequest>
  ): Promise<PaginatedResponse<JobDetailsResponse>> {
    const endpoint = JOB_DETAILS_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobStatisticsById(
    request: Partial<GetJobDetailsByIdRequest>
  ): Promise<PaginatedResponse<JobStatisticsResponse>> {
    const endpoint = JOB_STATISTICS_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getCompanyDetails(
    request: Partial<GetJobDetailsByIdRequest>
  ): Promise<PaginatedResponse<CompanyDetailsResponse>> {
    const endpoint = COMPANY_DETAILS_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobIndustries(): Promise<JobIndustriesResponse[]> {
    const endpoint = JOB_INDUSTRY_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getJobCategories(
    request: Partial<GetJobCategoriesParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_CATEGORY_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobTypes(
    request: Partial<GetJobCategoriesParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_TYPES_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobTerms(
    request: Partial<GetJobCategoriesParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_CONTRACT_TYPES_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getPeriodOfTime(
    request: Partial<GetJobCategoriesParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_PERIOD_OF_TIME_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobPriorities(
    request: Partial<GetJobCategoriesParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_PRIORITY_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getEducationalRequirements(): Promise<
    PaginatedResponse<TitleIdObjResponse>
  > {
    const endpoint = EDUCATIONAL_REQUIRMENT_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getYearOfExeperience(): Promise<
    PaginatedResponse<TitleIdObjResponse>
  > {
    const endpoint = YEAR_OF_EXEPERIANCE_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobTitle(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobIndustriesResponse>> {
    const endpoint = JOB_TITLES_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobSkills(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobIndustriesResponse>> {
    const endpoint = JOB_SKILLS_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Basic ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobHoursPerWeek(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_HOURS_PER_WEEK_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobDaysPerWeek(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_DAYS_PER_WEEK_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobBenifits(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_BENIFITS_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getWorkMode(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_WORK_MODE_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getJobPaymentType(
    request: Partial<GetJobTitleParamsRequest>
  ): Promise<PaginatedResponse<JobCategoriesResponse>> {
    const endpoint = JOB_PAYMENT_TYPE_URL;
    const response = await axios.get(endpoint, {
      params: request,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async createOpportunity(request: Partial<PostOpportunityProps>) {
    const endpoint = CREATE_OPPORTUNITY_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async updateOpportunity(request: Partial<PostOpportunityProps>) {
    const endpoint = UPDATE_OPPORTUNITY_URL;
    const response = await axios.patch(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
