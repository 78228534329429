import React from "react";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRow from "../../../../../../../../components/AtsRow";
import { Box, Skeleton } from "@mui/material";
import { COLORS } from "../../../../../../../../theme";
import { DisputeResolutionProps } from "../DisputeResolutionDrawer";

const DisputeUpdate = ({
  status,
  loading,
  disputedTimesheet,
}: DisputeResolutionProps) => {
  return (
    <>
      <AtsTitleText
        text={"Dispute resolution"}
        fs={18}
        fw={600}
        textColor={"#000"}
      />
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"From Esteemed:"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <Box sx={{}}>
            {loading ? (
              <Skeleton variant="text" width={200} />
            ) : (
              <AtsTitleText
                text={disputedTimesheet ? disputedTimesheet.title : "--"}
                fs={14}
                fw={400}
                textColor={"#1c1c1c"}
              />
            )}
          </Box>
        </>
      </AtsRow>
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Time reduced by contractor:"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <Box sx={{}}>
            {loading ? (
              <Skeleton variant="text" width={50} />
            ) : (
              <AtsTitleText
                text={
                  disputedTimesheet
                    ? `${disputedTimesheet.total_disputed_time} hrs`
                    : "0 hrs"
                }
                fs={14}
                fw={400}
                textColor={"#e63030"}
              />
            )}
          </Box>
        </>
      </AtsRow>
    </>
  );
};

export default DisputeUpdate;
