import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CompanyInfoParams, CompanyInfoResponse } from "../types/companyTypes";
import { API_GATEWAY } from "../utils/url";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  COMPANY_INFO: "company-info?_format=json",
};
const COMPANY_INFO_URL = Endpoint.BASE + Endpoint.COMPANY_INFO;
export const companyDetailsService = createApi({
  reducerPath: "companyDetailsService",
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCompanyInfo: builder.query<
      Promise<CompanyInfoResponse>,
      Partial<CompanyInfoParams>
    >({
      query: (params) => {
        return {
          url: COMPANY_INFO_URL,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const { useGetCompanyInfoQuery } = companyDetailsService;
