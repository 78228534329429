export enum InvoiceStatusEnum {
  paid = "paid",
  not_paid = "not_paid",
  received = "received",
}
export enum InvoiceTypeEnum {
  contract = "Contract",
  direct = "Direct Hire",
  converted = "Converted",
}
