import axios from "axios";
import { MSAcontractResponse } from "../types/contractTypes";
import {
  CandidatesDemographicsResponse,
  JobSubmissionCountResponse,
  ProjectOverviewCommanParams,
  ProjectPipelineStagesResponse,
  RecentActivitiesResponse,
  RecentlyPostedJobsResponse,
} from "../types/projectOverviewTypes";
import { PaginatedResponse } from "../types/commonType";

const Endpoint = {
  BASE: "ats/api/v1/",
  RECENT_JOBS: "recent-jobs",
  RECENT_ACTIVITIES: "project/recent-activities",
  HIRING_PIPELINE_STAGES: "hiring-pipeline-stages",
  SHORTLISTED_CANDIDATES_DEMOGRAPHICS: "shortlisted-candidates/demographics",
  SUBMISSION_COUNT_BY_JOB_IDS: "client-submissions-count-by-job",
};

const RECENT_JOBS_URL = Endpoint.BASE + Endpoint.RECENT_JOBS;
const SUBMISSION_COUNT_BY_JOB_IDS_URL =
  Endpoint.BASE + Endpoint.SUBMISSION_COUNT_BY_JOB_IDS;
const RECENT_ACTIVITIES_URL = Endpoint.BASE + Endpoint.RECENT_ACTIVITIES;
const HIRING_PIPELINE_STAGES_URL =
  Endpoint.BASE + Endpoint.HIRING_PIPELINE_STAGES;
const SHORTLISTED_CANDIDATES_DEMOGRAPHICS_URL =
  Endpoint.BASE + Endpoint.SHORTLISTED_CANDIDATES_DEMOGRAPHICS;

export class ProjectOverviewServices {
  static async getRecentJobs(
    params: Partial<ProjectOverviewCommanParams>
  ): Promise<RecentlyPostedJobsResponse[]> {
    const endpoint = RECENT_JOBS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getSubmissionCountByJobIds(params: {
    job_ids: string;
  }): Promise<JobSubmissionCountResponse[]> {
    const endpoint = SUBMISSION_COUNT_BY_JOB_IDS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getRecentActivities(
    params: Partial<ProjectOverviewCommanParams>
  ): Promise<RecentActivitiesResponse[]> {
    const endpoint = RECENT_ACTIVITIES_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getHiringPipelineStages(
    params: Partial<ProjectOverviewCommanParams>
  ): Promise<PaginatedResponse<ProjectPipelineStagesResponse>> {
    const endpoint = HIRING_PIPELINE_STAGES_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getShortlistedCandidatesDemographics(
    params: Partial<ProjectOverviewCommanParams>
  ): Promise<PaginatedResponse<CandidatesDemographicsResponse>> {
    const endpoint = SHORTLISTED_CANDIDATES_DEMOGRAPHICS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
