import { Box } from "@mui/material";
import React from "react";
import { BG_COLORS } from "../../../../../../../../../theme";

interface Props {
  progress: number;
}

const RatingComponent = ({ progress }: Props) => {
  return (
    <>
      <Box
        sx={{
          height: "5px",
          width: "100%",
          bgcolor: BG_COLORS.GRAY,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: `${progress}%`,
            bgcolor: "#000",
            borderRadius: 1,
          }}
        ></Box>
      </Box>
    </>
  );
};

export default RatingComponent;
