import { MakeAnOfferProvider } from "../../../../../../providers/MakeAnOfferProvider";
import CandidateDetailsDrawer from "./CandidateDetailsDrawer";
import ScheduleInterviewDialog from "./CandidateDetailsTabs/InterViewTab/modules/ScheduleInterviewDialog";
import MakeAnOfferDialog from "./CandidateDetailsTabs/MakeAnOfferTab/module/MakeAnOfferDialog";

const DetailsDrawer = () => {
  return (
    <>
      <MakeAnOfferProvider>
        <>
          <CandidateDetailsDrawer />
          <MakeAnOfferDialog />
          <ScheduleInterviewDialog />
        </>
      </MakeAnOfferProvider>
    </>
  );
};

export default DetailsDrawer;
