import { Collapse, CollapseProps } from "@mui/material";
import React from "react";

interface IProps extends CollapseProps {
  children: JSX.Element;
}

const AtsCollapse = ({ children, ...rest }: IProps) => {
  return (
    <>
      <Collapse
        sx={{
          boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
          borderRadius: "6px",
        }}
        {...rest}
      >
        {children}
      </Collapse>
    </>
  );
};

export default AtsCollapse;
