import { Close } from "@mui/icons-material";
import {
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { CloseCircle, SearchNormal1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { UserManagementServices } from "../../../Api/userManagementServices";
import AtsAddressInput from "../../../components/AtsAddressInput";
import AtsAntSwitch from "../../../components/AtsAntSwitch";
import AtsBackLink from "../../../components/AtsBackLink";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsDatePicker from "../../../components/AtsDatePicker";
import AtsRow from "../../../components/AtsRow";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import { useDebounce } from "../../../hooks/useDebounce";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import {
  ProfileToggleState,
  UsedPlatformEnums,
  useMyProfile,
} from "../../../providers/MyProfileProvider";
import { BG_COLORS, BORDERS, BOX_SHADOW, COLORS } from "../../../theme";
import {
  GetLanguageResponse,
  GetSchoolResponse,
} from "../../../types/userManagementTypes";
import biography from "../assets/biography.svg";
import decade from "../assets/decade.svg";
import fact from "../assets/fact.svg";
import language from "../assets/language.svg";
import live from "../assets/live.svg";
import music from "../assets/music.svg";
import obsesed from "../assets/obsesed.svg";
import pets from "../assets/pets.svg";
import school from "../assets/school.svg";
import time from "../assets/time.svg";
import work from "../assets/work.svg";
import AboutYou from "../components/AboutYou";
import Interestes from "../components/Interestes";
import ProfileModal from "../components/ProfileModal";
import AtsRoundedButton from "../../../components/AtsRoundedButton";

export interface PlatformProps {
  icon: any;
  text: string;
  subText: string;
  state: string;
}

const usedPlatforms: PlatformProps[] = [
  {
    icon: school,
    text: "Where I went to school?",

    subText:
      "Whether it's home school, secondary school or trade school, name the school that made you who you are.",
    state: UsedPlatformEnums.school,
  },
  {
    icon: work,
    text: "My work",
    subText: "",
    state: UsedPlatformEnums.work,
  },
  {
    icon: live,
    text: "Where I live?",
    subText:
      "Tell us a little bit about yourself so your future Hosts or guests can get to know you.",
    state: UsedPlatformEnums.live,
  },
  {
    icon: language,
    text: "Languages I speak",
    subText: "",
    state: UsedPlatformEnums.languagesSpeak,
  },
  {
    icon: decade,
    text: "Decade I was born",
    subText: "",
    state: UsedPlatformEnums.born,
  },
  {
    icon: music,
    text: "My favourite song in secondary school",
    subText: "",
    state: UsedPlatformEnums.favoriteSong,
  },
  {
    icon: obsesed,
    text: `I'm obsessed with`,
    subText: "",
    state: UsedPlatformEnums.obsessedWith,
  },
  {
    icon: fact,
    text: "My fun fact",
    subText: "",
    state: UsedPlatformEnums.funFact,
  },
  {
    icon: fact,
    text: "My most useless skill",
    subText: "",
    state: UsedPlatformEnums.unlessSkill,
  },
  {
    icon: biography,
    text: "My biography title would be",
    subText: "",
    state: UsedPlatformEnums.biographyTitle,
  },
  {
    icon: time,
    text: "I spend too much time",
    subText: "",
    state: UsedPlatformEnums.spendTime,
  },
  {
    icon: pets,
    text: "Pets",
    subText: "",
    state: UsedPlatformEnums.pets,
  },
];

const YourProfile = () => {
  const { setSelectedPlatform, selectedPlatform } = useCreateAccount();
  const {
    updateProfileState,
    handleProfileChange,
    setProfileToggleState,
    isEdit,
    onUpdate,
    loading,
  } = useMyProfile();
  const [open, setOpen] = useState(false);
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSchoolValue, setSearchSchoolValue] = useState("");
  const [searchLanguageValue, setSearchLanguageValue] = useState("");
  const [schoolData, setSchoolData] = useState<GetSchoolResponse[]>([]);
  const [languageData, setLanguageData] = useState<GetLanguageResponse[]>([]);
  const debounceValue = useDebounce(searchSchoolValue);

  const toggelOpenClose = () => {
    setOpen(!open);
  };

  const handleLanguageChange = (value: string) => {
    setSearchLanguageValue(value);
    if (value.length) {
      setShowLanguageDropdown(true);
    } else {
      setShowLanguageDropdown(false);
      handleProfileChange("languages", []);
    }
  };
  const handleChange = (value: string) => {
    setSearchSchoolValue(value);
    if (value.length) {
      setShowSchoolDropdown(true);
    } else {
      setShowSchoolDropdown(false);
      // handleProfileChange("where_i_went_to_school", "");
    }
  };

  const handleSelect = (value: string) => {
    let updatedSelected = [...updateProfileState.languages];
    if (updatedSelected.includes(value)) {
      updatedSelected = updatedSelected.filter((id) => id !== value);
    } else {
      updatedSelected.push(value);
    }
    handleProfileChange("languages", updatedSelected);
  };

  const getSchoolData = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await UserManagementServices.getSchool({ query });
      setSchoolData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLanguageData = async () => {
    setIsLoading(true);
    try {
      const response = await UserManagementServices.getLanguage();
      setLanguageData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    searchSchoolValue.length && getSchoolData(searchSchoolValue);
  }, [debounceValue]);

  useEffect(() => {
    getLanguageData();
  }, []);

  const handleDelete = (value: string) => {
    const languages = [...updateProfileState.languages];
    const updatedSelected = languages.filter((id) => id !== value);
    handleProfileChange("languages", updatedSelected);
  };

  const commonInputKeys = () => {
    if (selectedPlatform?.state === UsedPlatformEnums.work) {
      return {
        value: updateProfileState.my_work,
        keyValue: "my_work" as any,
      };
    } else if (selectedPlatform?.state === UsedPlatformEnums.favoriteSong) {
      return {
        value: updateProfileState.favorite_song,
        keyValue: "favorite_song" as any,
      };
    } else if (selectedPlatform?.state === UsedPlatformEnums.obsessedWith) {
      return {
        value: updateProfileState.obessed_with,
        keyValue: "obessed_with" as any,
      };
    } else if (selectedPlatform?.state === UsedPlatformEnums.unlessSkill) {
      return {
        value: updateProfileState.useless_skill,
        keyValue: "useless_skill" as any,
      };
    } else if (selectedPlatform?.state === UsedPlatformEnums.funFact) {
      return {
        value: updateProfileState.fun_fact,
        keyValue: "fun_fact" as any,
      };
    } else if (selectedPlatform?.state === UsedPlatformEnums.biographyTitle) {
      return {
        value: updateProfileState.biography_title,
        keyValue: "biography_title" as any,
      };
    } else if (selectedPlatform?.state === UsedPlatformEnums.spendTime) {
      return {
        value: updateProfileState.spend_to_much_time,
        keyValue: "spend_to_much_time" as any,
      };
    } else {
      return {
        value: updateProfileState.pets,
        keyValue: "pets" as any,
      };
    }
  };
  const getComponent = () => {
    if (selectedPlatform?.state === UsedPlatformEnums.live) {
      return (
        <Stack>
          <AtsAddressInput
            onSearch={(value) => handleProfileChange("where_i_live", value)}
            search={updateProfileState.where_i_live}
            handleAddressChange={() => {}}
          />
        </Stack>
      );
    } else if (selectedPlatform?.state === UsedPlatformEnums.school) {
      return (
        <Stack>
          <AtsRow
            sx={{
              px: "10px",
              py: "14px",
              borderRadius: "8px",
              border: BORDERS.GRAY,
            }}
          >
            <>
              <AtsTitleText
                text={"Where I went to school:"}
                fs={14}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
              />
              <input
                type="text"
                value={searchSchoolValue}
                className="customInput"
                onChange={(e) => handleChange(e.target.value)}
              />
            </>
          </AtsRow>
          <Collapse
            in={showSchoolDropdown}
            sx={{
              boxShadow: BOX_SHADOW.GRAY,
            }}
          >
            <div
              id="projectDiv"
              style={{
                maxHeight: 200,
                padding: "10px",
                overflow: "auto",
              }}
              className=""
            >
              {isLoading ? (
                <Stack gap={"10px"}>
                  {Array.from(new Array(5)).map((_, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        p: 0,
                      }}
                    >
                      <AtsRow direction={"row"} gap={2}>
                        <>
                          <Skeleton variant="rounded" width={20} height={20} />

                          <AtsRow gap={"10px"}>
                            <>
                              <Skeleton
                                variant="rounded"
                                width={20}
                                height={20}
                              />
                              <Skeleton
                                variant="text"
                                width={100}
                                height={20}
                              />
                            </>
                          </AtsRow>
                        </>
                      </AtsRow>
                    </MenuItem>
                  ))}
                </Stack>
              ) : schoolData?.length > 0 ? (
                schoolData?.map((option, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={option.domain}
                      onClick={() => {
                        setSearchSchoolValue(option.name);
                        handleProfileChange(
                          "where_i_went_to_school",
                          option.name
                        );
                      }}
                      sx={{
                        p: 0,
                      }}
                    >
                      <AtsRow direction={"row"} gap={2}>
                        <>
                          <AtsCheckbox
                            checked={
                              option.name ===
                              updateProfileState.where_i_went_to_school
                            }
                          />

                          <AtsRow gap={"10px"}>
                            <>
                              <img
                                src={option.logo}
                                alt={option.logo}
                                width={20}
                                height={20}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              <AtsTitleText text={option.name} />
                            </>
                          </AtsRow>
                        </>
                      </AtsRow>
                    </MenuItem>
                  );
                })
              ) : (
                <AtsTitleText text={"No Data"} />
              )}
            </div>
          </Collapse>
        </Stack>
      );
    } else if (selectedPlatform?.state === UsedPlatformEnums.languagesSpeak) {
      return (
        <Stack gap={"10px"}>
          <AtsRow
            gap={"5px"}
            alignItems={"center"}
            sx={{
              px: "15px",
              minHeight: 40,
              borderRadius: "40px",
              border: BORDERS.GRAY,
            }}
          >
            <>
              <SearchNormal1 size={16} color={COLORS.DARK_GRAY} />
              <input
                type="text"
                value={searchLanguageValue}
                className="customInput"
                placeholder="Search for a language"
                style={{ flexGrow: 1 }}
                onChange={(e) => handleLanguageChange(e.target.value)}
              />
              <AtsRow
                alignItems={"center"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSearchLanguageValue("");
                  setShowLanguageDropdown(false);
                }}
              >
                <CloseCircle variant="Bold" size={16} />
              </AtsRow>
            </>
          </AtsRow>
          {updateProfileState.languages.length > 0 && (
            <AtsRow gap={"5px"} sx={{ flexWrap: "wrap" }}>
              <>
                {updateProfileState.languages.map((value, index) => (
                  <AtsRow
                    key={index}
                    gap={"5px"}
                    sx={{
                      bgcolor: BG_COLORS.PRIMARY,
                      py: "2px",
                      pl: "15px",
                      pr: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <>
                      <AtsTitleText
                        text={value}
                        fs={12}
                        fw={500}
                        textColor={COLORS.PRIMARY}
                      />
                      <IconButton
                        sx={{ p: "3px" }}
                        onClick={() => handleDelete(value)}
                      >
                        <Close sx={{ fontSize: 14, color: COLORS.PRIMARY }} />
                      </IconButton>
                    </>
                  </AtsRow>
                ))}
              </>
            </AtsRow>
          )}
          <Collapse
            in={showLanguageDropdown}
            sx={{
              boxShadow: BOX_SHADOW.GRAY,
            }}
          >
            <div
              id="projectDiv"
              style={{
                maxHeight: 200,
                padding: "10px",
                overflow: "auto",
              }}
              className=""
            >
              {isLoading ? (
                <Stack gap={"5px"}>
                  {Array.from(new Array(5)).map((_, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        p: 0,
                      }}
                    >
                      <AtsRow direction={"row"} gap={2}>
                        <>
                          <Skeleton variant="rounded" width={20} height={20} />

                          <AtsRow gap={"10px"}>
                            <>
                              <Skeleton
                                variant="rounded"
                                width={20}
                                height={20}
                              />
                              <Skeleton
                                variant="text"
                                width={100}
                                height={20}
                              />
                            </>
                          </AtsRow>
                        </>
                      </AtsRow>
                    </MenuItem>
                  ))}
                </Stack>
              ) : languageData?.length > 0 ? (
                languageData
                  .filter((value) => {
                    return value.name
                      .toLowerCase()
                      .includes(searchLanguageValue.toLowerCase());
                  })
                  ?.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={option.name}
                        onClick={() => {
                          handleSelect(option.name);
                        }}
                        sx={{
                          px: 0,
                          py: "5px",
                          ...(languageData.length - 1 !== index && {
                            borderBottom: BORDERS.GRAY,
                          }),
                        }}
                      >
                        <AtsRowBetween
                          sx={{
                            width: "100%",
                          }}
                        >
                          <>
                            <AtsRow gap={"10px"}>
                              <>
                                <AtsTitleText text={option.name} />
                              </>
                            </AtsRow>
                            <AtsCheckbox
                              checked={updateProfileState.languages.includes(
                                option.name
                              )}
                            />
                          </>
                        </AtsRowBetween>
                      </MenuItem>
                    );
                  })
              ) : (
                <AtsTitleText text={"No Data"} />
              )}
            </div>
          </Collapse>
        </Stack>
      );
    } else if (selectedPlatform?.state === UsedPlatformEnums.born) {
      return (
        <Stack gap={"10px"}>
          <Stack gap={"5px"}>
            <AtsTitleText
              text={"Select date of birth"}
              fs={12}
              fw={600}
              textColor={COLORS.BLACK}
            />
            <AtsDatePicker
              value={dayjs(
                updateProfileState.dob.length
                  ? updateProfileState.dob
                  : new Date()
              )}
              onChange={(value) => {
                const selectedDate = dayjs(value).format("YYYY-MM-DD");
                handleProfileChange("dob", selectedDate);
              }}
            />
          </Stack>
          <AtsRowBetween
            alignItems={"center"}
            sx={{
              pt: "15px",
            }}
          >
            <>
              <Stack gap={"5px"}>
                <AtsTitleText
                  text={"Show the decade I was born"}
                  fs={12}
                  fw={600}
                  textColor={COLORS.BLACK}
                />
                <AtsTitleText
                  text={`Born in the ${
                    updateProfileState.dob.length
                      ? dayjs(updateProfileState.dob).format("YYYY")
                      : "00"
                  }s`}
                  fs={12}
                  fw={400}
                  textColor={COLORS.DARK_GRAY}
                />
              </Stack>
              <AtsAntSwitch
                checked={updateProfileState.show_decade === "1" ? true : false}
                onChange={(e) => {
                  const isShow = e.target.checked;
                  handleProfileChange("show_decade", isShow ? "1" : "0");
                }}
              />
            </>
          </AtsRowBetween>
        </Stack>
      );
    } else {
      return (
        <AtsTextInput
          placeholder={`Enter ${selectedPlatform?.text}`}
          value={commonInputKeys().value}
          onChange={(e) =>
            handleProfileChange(commonInputKeys().keyValue, e.target.value)
          }
        />
      );
    }
  };

  return (
    <>
      <Stack gap={"20px"}>
        <AtsRowBetween>
          <>
            <AtsTitleText
              text={"Your profile"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsBackLink
              text="Back"
              onClick={() => {
                if (isEdit) {
                  setProfileToggleState(ProfileToggleState.Edit);
                } else {
                  setProfileToggleState(ProfileToggleState.Initial);
                }
              }}
            />
          </>
        </AtsRowBetween>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: COLORS.DARK_GRAY,
          }}
        >
          The information you share will be used across the platform to help
          other people get to know you.{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Learn more
          </span>
        </Typography>
      </Stack>
      <Stack mt={"30px"}>
        <Grid container columnSpacing={"30px"} rowSpacing={"10px"}>
          {usedPlatforms.map((platform, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <AtsRow
                gap={"12px"}
                sx={{
                  px: "10px",
                  py: "20px",
                  borderBottom: BORDERS.GRAY,
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggelOpenClose();

                  setSelectedPlatform(platform);
                }}
              >
                <>
                  <img
                    src={platform?.icon}
                    width={25}
                    height={25}
                    alt={platform?.icon}
                  />
                  <AtsTitleText
                    text={platform.text}
                    fs={14}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                  />
                </>
              </AtsRow>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack mt={"50px"} gap={"50px"}>
        <AboutYou />
      </Stack>
      <Stack mt={"50px"} gap={"50px"}>
        <Interestes />
      </Stack>
      <AtsRow
        sx={{
          justifyContent: "end",
        }}
      >
        <AtsRoundedButton
          text="Done"
          buttonWidth={94}
          buttonHeight={30}
          onClick={onUpdate}
          disabled={loading}
          loading={loading}
        />
      </AtsRow>
      <ProfileModal
        open={open}
        header={selectedPlatform?.text ?? ""}
        subText={selectedPlatform?.subText ?? ""}
        toggelOpenClose={toggelOpenClose}
        renderConponents={getComponent()}
        onSave={toggelOpenClose}
      />
    </>
  );
};

export default YourProfile;
