import moment from "moment";
import { createContext, useContext, useState } from "react";
import { ProjectListStatusEnum } from "../enums/projectEnums";
import { ProjectFilterState } from "../types/projectTypes";
import { formatDateRange } from "../utils/dateUtils";

export const initilaProjectFilterstate = {
  title: "",
  end_date: "",
  start_date: "",
  country_code: [],
  budget_high: 10,
  budget_low: 1,
  status: ProjectListStatusEnum.IN_PROGRESS,
} as ProjectFilterState;

type ProjectFilterModuleContextType = {
  range: number[];
  search: string;
  selectedDate: string;
  selected: string;
  projectFilter: ProjectFilterState;
  setRange: React.Dispatch<React.SetStateAction<number[]>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setProjectFilter: React.Dispatch<React.SetStateAction<ProjectFilterState>>;
  handlePriceChange: (event: Event, newValue: number | number[]) => void;
  handleDayButtonSelect: (day: string, dateFrom: number) => void;
  handleEvent: (event: any, picker: any) => void;
  onClear: () => void;
};

const ProjectFilter = createContext<ProjectFilterModuleContextType | null>(
  null
);

export const useProjectFilter = () =>
  useContext(ProjectFilter) as ProjectFilterModuleContextType;

export const ProjectFilterProvider = ({ children }: any) => {
  const [range, setRange] = useState([1, 10]);
  const [search, setSearch] = useState<string>("");
  const [projectFilter, setProjectFilter] = useState<ProjectFilterState>(
    initilaProjectFilterstate
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    formatDateRange(new Date().toISOString(), new Date().toISOString())
  );
  const [selected, setSelected] = useState<string>("");

  //////////////// Budget \\\\\\\\\\\\\\\\\\\\\
  const handleSetPrice = (newValue: number[]) => {
    const lowValue = newValue[0];
    const highValue = newValue[1];

    setProjectFilter({
      ...projectFilter,
      budget_high: highValue,
      budget_low: lowValue,
    } as ProjectFilterState);
  };
  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setRange(newValue as number[]);
    handleSetPrice(newValue as number[]);
  };
  //////////////// Budget \\\\\\\\\\\\\\\\\\\\\

  /////////////// DATE range \\\\\\\\\\\\\\\\\\\\\

  const handleDayButtonSelect = (day: string, dayFrom: number) => {
    if (selected === day) {
      setSelected("");
      const dateSelection = {
        ...projectFilter,
        start_date: "",
        end_date: "",
      } as ProjectFilterState;
      setProjectFilter(dateSelection);
    } else {
      setSelected(day);
      dayFrom !== 0 && handleLastSevenDay(dayFrom);
    }
  };

  const handleLastSevenDay = (dateFrom: number) => {
    const today = new Date();
    const seventhDay = new Date();
    seventhDay.setDate(today.getDate() + dateFrom);
    const formattedTodayDate = moment(today).format("YYYY-MM-DD");
    const formattedSevenDate = moment(seventhDay).format("YYYY-MM-DD");
    const dateSelection = {
      ...projectFilter,
      start_date: formattedTodayDate,
      end_date: formattedSevenDate,
    } as ProjectFilterState;
    setProjectFilter(dateSelection);
  };
  const handleEvent = (event, picker) => {
    setSelected("");
    const datePicker = formatDateRange(picker.startDate, picker.endDate);
    setSelectedDate(datePicker);
    const dateSelection = {
      ...projectFilter,
      start_date: moment(picker.startDate).format("YYYY-MM-DD"),
      end_date: moment(picker.endDate).format("YYYY-MM-DD"),
    } as ProjectFilterState;
    setProjectFilter(dateSelection);
  };
  /////////////// DATE range \\\\\\\\\\\\\\\\\\\\\

  const onClear = () => {
    // handleDayButtonSelect("", 0);
    setSelected("");
    setRange([1, 10]);
    setSearch("");
    setSelectedDate("Select Date");
    setProjectFilter(initilaProjectFilterstate);
  };

  return (
    <ProjectFilter.Provider
      value={{
        range,
        search,
        selected,
        projectFilter,
        selectedDate,
        setSearch,
        setSelectedDate,
        setRange,
        setSelected,
        setProjectFilter,
        handlePriceChange,
        handleDayButtonSelect,
        handleEvent,
        onClear,
      }}
    >
      {children}
    </ProjectFilter.Provider>
  );
};
