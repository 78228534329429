import { Box } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import ReactApexChart from "react-apexcharts";
import AtsRow from "../../../components/AtsRow";
import { ArrowCircleDown2, ArrowDown2, Setting3 } from "iconsax-react";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsCustomButton from "../../../components/AtsCustomButton";

interface IProps {
  hadCountData: Record<
    string,
    {
      open_positions: number;
      contract: number;
      direct: number;
    }
  >;
}

const HeadCountGraph = ({ hadCountData }: IProps) => {
  const categories = Object.keys(hadCountData);

  const contractors = categories.map(
    (category) => hadCountData[category].contract
  );
  const directHires = categories.map(
    (category) => hadCountData[category].direct
  );
  const openPositions = categories.map(
    (category) => hadCountData[category].open_positions
  );
  const options: ApexCharts.ApexOptions = {
    chart: {
      fontFamily: "Inter",
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        dataLabels: {
          hideOverflowingLabels: true,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: categories,
      labels: {
        formatter: (value) => {
          return value.replace(" Dept.", "");
        },
        // rotate: 0,
      },
    },
    colors: ["#BFDBFE", "#60A5FA", "#2563EB"],
    legend: {
      position: "bottom",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 500,

      markers: {
        shape: "circle",
      },
      //   offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "Contractors",
      data: contractors,
    },
    {
      name: "Direct Hires",
      data: directHires,
    },
    {
      name: "Open Positions",
      data: openPositions,
    },
  ];
  return (
    <>
      <Stack gap={"20px"}>
        <AtsRowBetween>
          <>
            <AtsRow gap={"5px"}>
              <>
                <AtsTitleText
                  text={"Last 3 Months"}
                  textColor={COLORS.PRIMARY}
                  fs={11}
                  fw={700}
                />
                <ArrowDown2 fontWeight={700} size={12} color={COLORS.PRIMARY} />
              </>
            </AtsRow>
            <AtsRow gap={"2px"}>
              <>
                <AtsCustomButton px={"3px"} py={"3px"}>
                  <ArrowCircleDown2
                    fontWeight={700}
                    size={20}
                    color={COLORS.BLACK}
                  />
                </AtsCustomButton>
                <AtsCustomButton px={"3px"} py={"3px"}>
                  <Setting3 fontWeight={700} size={20} color={COLORS.BLACK} />
                </AtsCustomButton>
              </>
            </AtsRow>
          </>
        </AtsRowBetween>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </Stack>
    </>
  );
};

export default HeadCountGraph;
