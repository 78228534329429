import { Stack } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import AtsAnimation from "../../../components/AtsAnimation";
import AtsText from "../../../components/AtsText";
import { COLORS } from "../../../theme";

interface IJobs {
  title: string;
  type: string;
}
interface JobsProps {
  jobs: IJobs[];
  active: string;
  width?: string;
  height?: string;
  onChange: (value: string) => void;
}

const JobsSwitch = ({
  jobs,
  active,
  onChange,
  width,
  height = "26px",
}: JobsProps) => {
  return (
    <>
      <Stack
        sx={{
          border: "2px solid #dcdfe7",
          borderRadius: "20px",
        }}
      >
        <AnimatePresence>
          <Stack
            sx={{
              bgcolor: "#dcdfe7",
              borderRadius: "20px",
            }}
            direction={"row"}
          >
            {jobs.map((job, index) => (
              <AtsAnimation key={index}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    bgcolor: active === job.type ? "white" : "#dcdfe7",
                    borderRadius: "20px",
                    ...(width && { width }),
                    height,
                    cursor: "pointer",
                    px: "20px",
                  }}
                  onClick={() => onChange(job.type)}
                >
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      color:
                        active === job.type ? COLORS.PRIMARY : COLORS.DARK_GRAY,
                    }}
                    text={job.title}
                  />
                </Stack>
              </AtsAnimation>
            ))}
          </Stack>
        </AnimatePresence>
      </Stack>
    </>
  );
};

export default JobsSwitch;
