export const ATS_ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  JOBS: "/jobs",
  SUBMISSIONS: "/submissions/:nid",
  PROJECTS: "/projects",
  PROJECT_DETAILS: "/project-details/:id",
  CALENDAR: "/calendar",
  MESSAGING: "/messaging",
  INSIGHTS: "/insights",
  AVAILABILITY: "/availability",
  BILLING: "/billing",
  ADMIN_CENTER: "/admin-center",
  HELP: "/help",
  MY_PROFILE: "/my-profile",
  MY_TEAM: "/my-team",
  GETTING_STARTED: "/gettting-started",
  COMMUNITY: "/community",
  POST_OPPORTUNITY: "/post-opportunity",
  POST_OPPORTUNITY_BY_ID: "/post-opportunity/:jobId",
  USER_MANAGEMENT: "/user-management",
  USER_ROLES: "/user-roles",
  CONTRACTS: "/contracts",
  CONTRACTS_DETAILS: "/contracts/:contractType/:contractId",

  NO_ACCESS: "/no-access",
  CREATE_PROJECT: "/create-project",
  ACCEPT_INVITE: "/accept-invite",
  RESET_PASSWORD: "/reset-password",
  CREATE_ACCOUNT: "/create-account",
  NOTIFICATIONS: "/notifications",
};
