import { Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS } from "../../../theme";
import { JobCategoriesResponse } from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import RadioInput from "../components/RadioInput";

const JobResourceTime = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [loading, setLoading] = useState<boolean>(false);
  const [periodOfTime, setPeriodOfTime] = useState<JobCategoriesResponse[]>([]);

  const handleChange = (value: string) => {
    if (postOpportunity.job_period_of_time_id === value) {
      const opportunityObj = {
        ...postOpportunity,
        job_period_of_time_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_period_of_time_id: value,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const fetchPeriodOfTime = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getPeriodOfTime({
        items_per_page: 50,
      });
      if (response.results.length) {
        const filterdData = response.results.filter(
          (res) =>
            res.title === "Hourly as needed" ||
            res.title === "Hourly, to be determined"
        );
        setPeriodOfTime(filterdData);
      }
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPeriodOfTime();
  }, []);
  return (
    <>
      <JobCardWrapper header="For how long do you need the resource?">
        <Stack pr={1} gap={2}>
          {loading
            ? Array.from(new Array(2)).map((_, index) => (
                <Stack
                  gap={2}
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    px: 2,
                    py: 1.5,
                    cursor: "pointer",
                  }}
                >
                  <Skeleton variant="circular" width={10} height={10} />

                  <Skeleton
                    variant="text"
                    width={300}
                    sx={{ fontSize: "1rem" }}
                  />
                </Stack>
              ))
            : periodOfTime.map((item, index) => (
                <RadioInput
                  key={index}
                  title={item.title}
                  checked={postOpportunity.job_period_of_time_id === item.id}
                  onSelect={() => handleChange(item.id)}
                />
              ))}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobResourceTime;
