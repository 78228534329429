import React, { createContext, useContext, useState, useCallback } from "react"; // Adjust import as necessary
import { useMessages } from "./MessagesProvider";
import { CallingResponse, ChatProps } from "../types/messagesTypes";

interface AgoraOptionProps {
  appId: string;
  channel: string;
  token: string;
  uid: number;
}

// Define the context type
interface CallingContextType {
  token: string | null;
  callStatus: string | null;
  isVoiceCalling: boolean;
  openCallingModal: boolean;
  calling: boolean;
  muteAudio: boolean;
  callStarted: boolean;
  storeCallInfo: CallingResponse | null; // Replace `any` with appropriate type for storeCallInfo
  callingProfile: string;
  agoraOption: AgoraOptionProps | null;
  setCalling: (payload: boolean) => void;
  setAcceptCall: () => Promise<void>;
  setCallingProfile: (id: string, messengersList: ChatProps[]) => string;
  setIncomingOrOutGoingCall: (dto: CallingResponse) => Promise<void>;
  setCallIsDisconnected: () => void;
  setIsVoiceCalling: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCallingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const CallingModule = createContext<CallingContextType | undefined>(undefined);

// Create the provider component
export const CallingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [callStatus, setCallStatus] = useState<string | null>(null);
  const [isVoiceCalling, setIsVoiceCalling] = useState(true);
  const [calling, setCalling] = useState(false);
  const [muteAudio, setMuteAudio] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [storeCallInfo, setStoreCallInfo] = useState<CallingResponse | null>(
    null
  );
  const [callingType, setCallingType] = useState<string>("");
  const [openCallingModal, setOpenCallingModal] = useState<boolean>(false);
  const [agoraOption, setAgoraOption] = useState<AgoraOptionProps | null>(null);

  // Set calling state
  const updateCalling = useCallback((payload: boolean) => {
    setCalling(payload);
  }, []);

  // Set the call status to started
  const setAcceptCall = useCallback(async () => {
    if (storeCallInfo && !callStarted) {
      setCallStarted(true);
    }
  }, [storeCallInfo, callStarted]);

  // Set calling profile based on conversation store
  const setCallingProfile = (id: string, messengersList: ChatProps[]) => {
    const findProfile = messengersList.find(
      (item: any) => item?.recipient?.id === id
    );
    return (
      findProfile?.recipient?.picture ??
      "https://cdn.quasar.dev/team/razvan_stoenescu.jpeg"
    );
  };

  // Handle incoming or outgoing call
  const setIncomingOrOutGoingCall = useCallback(
    async (dto: CallingResponse) => {
      // Adjust type as needed
      console.log(dto);
      setStoreCallInfo(dto);
      if (dto) {
        setAgoraOption({
          appId: "63b155697e404804870e0cb6b71dbec4",
          channel: dto.conversationId,
          token: dto.token,
          uid: 0,
        });
      }
      setToken(dto.token);
      if (dto?.mode === "INCOMING") {
        setCallStatus("INCOMING");
      }
      setCalling(true);
      setIsVoiceCalling(dto.type === "VOICE");
    },
    []
  );

  ///////////////// on Accept and reject call \\\\\\\\\\\\\\\\\\\\\\

  ///////////////// on Accept and reject call \\\\\\\\\\\\\\\\\\\\\\

  // Reset call state
  const setCallIsDisconnected = useCallback(() => {
    setToken(null);
    setCallStatus(null);
    setCalling(false);
    setMuteAudio(false);
    setCallStarted(false);
    setStoreCallInfo(null);
  }, []);

  return (
    <CallingModule.Provider
      value={{
        token,
        callStatus,
        isVoiceCalling,
        calling,
        muteAudio,
        callStarted,
        storeCallInfo,
        agoraOption,
        openCallingModal,
        callingProfile: "",
        setCalling: updateCalling,
        setAcceptCall,
        setCallingProfile,
        setIncomingOrOutGoingCall,
        setCallIsDisconnected,
        setIsVoiceCalling,
        setOpenCallingModal,
      }}
    >
      {children}
    </CallingModule.Provider>
  );
};

export const useCallingStore = () => {
  const context = useContext(CallingModule);
  if (context === undefined) {
    throw new Error("useCallingStore must be used within a CallingProvider");
  }
  return context;
};
