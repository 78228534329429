import React, { useEffect, useState } from "react";
import AtsAutoComplete from "../../../components/AtsAutoComplete";
import { JobsService } from "../../../Api/jobsListingServices";
import { JobIndustriesResponse } from "../../../types/jobsTypes";
import { useDebounce } from "../../../hooks/useDebounce";
import { TitleIdOptionProps } from "../../../types/commonType";

interface IProps {
  onSelected: (select: string) => void;
  selected: string;
}

const JobSkillsSelector = ({ onSelected, selected }: IProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [jobSkills, setJobSkills] = useState<TitleIdOptionProps[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const debouncedSearch = useDebounce(searchValue);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== jobSkills.length) {
        setPage(page + 1);
      }
    }
  };

  const handleSelect = (select: string) => {
    setSearchValue(select);
    onSelected(select);
  };

  const fetchPeriodOfTime = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobSkills({
        ...(searchValue.length && { name: debouncedSearch }),
        page,
      });

      const options = response.results.map(
        (item) => ({ id: item.name, title: item.name } as TitleIdOptionProps)
      );
      if (page === 0) {
        setJobSkills(options);
      } else {
        setJobSkills([...jobSkills, ...options]);
      }
      setTotalItem(Number(response.pager.count));
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriodOfTime();
  }, [debouncedSearch, page]);

  useEffect(() => {
    if (!selected.length) {
      handleSelect("");
    }
  }, [selected]);
  return (
    <div>
      <AtsAutoComplete
        handleChange={(value) => {
          setSearchValue(value);
          setPage(0);
        }}
        options={jobSkills}
        searchValue={searchValue}
        selected={selected}
        onScroll={loadMoreItems}
        onSelect={(selected) => handleSelect(selected)}
        placeHolder="Enter some skills that describe your abilities"
      />
    </div>
  );
};

export default JobSkillsSelector;
