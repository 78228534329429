import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setJobDetailsById,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS } from "../../../theme";
import {
  JobCategoriesResponse,
  JobDetailByIdResponse,
  JobDetailsResponse,
} from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";
import SmallCard from "../components/SmallCard";
import { htmlString } from "../../../utils/stringUtils";

const JobDescribes = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById } = useAppSelector(
    getPostOpportunityState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<JobCategoriesResponse[]>([]);

  const handleChange = (value: JobCategoriesResponse) => {
    if (postOpportunity.job_category_id === value.id) {
      const opportunityObj = {
        ...postOpportunity,
        job_category_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          job_category: "",
        } as JobDetailByIdResponse)
      );
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_category_id: value.id,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
      dispatch(
        setJobDetailsById({
          ...jobDetailsById,
          job_category: value.title,
        } as JobDetailByIdResponse)
      );
    }
  };

  const fetchJobCategories = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobCategories({
        items_per_page: 50,
      });
      const categories = response.results;

      const iconMap = {
        Business: "icon-presention-chart",
        "Operations / HR": "icon-devices-1",
        Management: "icon-personalcard",
        Production: "icon-trend-up",
        "Creative ": "icon-camera",
        "Public Relations": "icon-presention-chart",
        Technology: "icon-code-1",
        Finance: "icon-chart-square",
        "Admin &amp; Support": "icon-call-calling",
        "admin-support": "icon-call-calling",
        Legal: "icon-courthouse",
        Marketing: "icon-graph",
        Sales: "icon-status-up",
        Advertising: "icon-ranking-1",
        Writing: "icon-document-copy",
        Design: "icon-mask-1",
        "People Ops": "icon-profile-2user",
        Engineering: "icon-layer",
        Education: "icon-award",
        Workplace: "icon-buildings",
        Lifestyle: "icon-sun-fog",
      };
      const resposeWithIcon = categories.map((category) => {
        const icon = iconMap[category.title];
        return icon ? { ...category, icon } : category;
      });
      setCategories(resposeWithIcon);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobCategories();
  }, []);

  return (
    <>
      <JobCardWrapper header="Which of these best describes your job?">
        <Stack pr={1} mb={1}>
          <Grid container spacing={"20px"}>
            {loading
              ? Array.from(new Array(8)).map((_, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        border: BORDERS.GRAY,
                      }}
                    >
                      <Stack gap={1}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </Stack>
                    </Box>
                  </Grid>
                ))
              : categories.map((item, index) => (
                  <SmallCard
                    key={index}
                    title={htmlString(item.title)}
                    description={htmlString(item.description)}
                    icon={item.icon}
                    onSelect={() => handleChange(item)}
                    checked={postOpportunity.job_category_id === item.id}
                  />
                ))}
          </Grid>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobDescribes;
