import { Box, Skeleton, Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsUserNameTextAvatar from "../../../components/AtsUserNameTextAvatar";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { COLORS } from "../../../theme";
import { useRef } from "react";
import Edit from "../assets/edit.svg";
import { getAuthState } from "../../../redux/slices/authSlice";
import { useAppSelector } from "../../../redux/store";

const MyProfileInfo = () => {
  const { userInfo } = useAppSelector(getAuthState);
  const fileInputRef = useRef(null);
  const {
    accountDetailsState,
    companyInfoState,
    handleFiles,
    userImageState,
    fileLoading,
  } = useCreateAccount();
  const userName = `${accountDetailsState.first_name} ${accountDetailsState.last_name}`;
  const address = `${companyInfoState?.company_address?.locality ?? ""}, ${
    companyInfoState?.company_address?.country_code ?? ""
  }`;
  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <Stack gap={"32px"}>
      {fileLoading ? (
        <Skeleton variant="circular" width={200} height={200} />
      ) : (
        <Box
          sx={{
            width: 200,
            height: 200,
            position: "relative",
          }}
        >
          <AtsUserNameTextAvatar
            name={userName}
            image={
              userInfo.picture && userInfo.picture.length > 0
                ? userInfo.picture
                : undefined
            }
            fs={56}
          />

          <Box
            sx={{
              position: "absolute",
              bottom: 10,
              right: 15,
              padding: "5px",
              backgroundColor: "white",
              borderRadius: "50%",
              cursor: "pointer",
              height: 24,
              width: 24,
              boxShadow: "0 4px 5px 0 rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label
              htmlFor="userImage"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              style={{
                marginBottom: 0,
              }}
            >
              <img src={Edit} alt="" />
              <input
                type="file"
                name=""
                accept=".jpg, .jpeg, .png"
                id="userImage"
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </label>
          </Box>
        </Box>
      )}
      <Stack textAlign={"center"} gap={"10px"}>
        <AtsTitleText text={userName} fs={18} fw={600} textColor="#000" />
        <Stack textAlign={"center"} gap={"2px"}>
          <AtsTitleText
            text={accountDetailsState.job_title}
            fs={14}
            fw={500}
            textColor={COLORS.DARK_GRAY}
          />
          <AtsTitleText
            text={address}
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MyProfileInfo;
