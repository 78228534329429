import { Box, Grid, Skeleton, Stack } from "@mui/material";
import AtsCard from "../../components/AtsCard";
import { BORDERS } from "../../theme";

const JobCardLoading = () => {
  return (
    <div style={{ cursor: "pointer" }}>
      <AtsCard>
        <>
          <Stack gap={1} className="" py={1} px={"5px"}>
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              gap={2}
              pt={1}
              pb={"15px"}
              sx={{ borderBottom: BORDERS.GRAY }}
            >
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
            </Stack>
            <Stack pt={1} pb={2} gap={1}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <Skeleton width={100} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* First Grid Item */}
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <Skeleton width={100} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* First Grid Item */}
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <Skeleton width={100} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* First Grid Item */}
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <Skeleton width={100} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              pt={1}
            >
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Skeleton width={100} />
              </Box>
            </Stack>
          </Stack>
        </>
      </AtsCard>
    </div>
  );
};

export default JobCardLoading;
