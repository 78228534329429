import {
  ArrowForwardIos,
  DownloadForOfflineOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useState } from "react";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import OfferStatusStepper from "./OfferStatusStepper";
import { ArrowCircleDown2, ArrowRight2 } from "iconsax-react";
import { COLORS } from "../../../../../../../../../../theme";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../redux/store";
import {
  setContractorEffortsDetails,
  setContractorOnboardingDetails,
  setContractorPlacmentDetails,
  setIsContractorDetailsDrawerOpen,
  setIsContractorOnboarded,
  setSelectedContractorDrawerTab,
} from "../../../../../../../../../../redux/slices/contractorSlice";
import {
  getSubmissionState,
  setCandidateDetails,
  setIsCandidateDetailsDrawerOpen,
  setIsCandidateDetailsLoading,
} from "../../../../../../../../../../redux/slices/submissionSlice";
import { setCondidateTabValue } from "../../../../../../../../../../redux/slices/candidateSlice";
import { TimesheetServices } from "../../../../../../../../../../Api/timesheetServices";
import { OnboardingServices } from "../../../../../../../../../../Api/onboardingServices";
import { SubmissionService } from "../../../../../../../../../../Api/submissionServices";
import { getAuthState } from "../../../../../../../../../../redux/slices/authSlice";

const StatusDetails = () => {
  const { userInfo } = useAppSelector(getAuthState);
  const { submissionDetails } = useAppSelector(getSubmissionState);
  const { selectedOffer, offerDetails } = useMakeAnOffer();
  const dispatch = useAppDispatch();

  const allStatusStep = [
    "Offer Extended",

    "Under Negotiation",

    "Offer Revised",
    "Offer Accepted",
  ];
  const expiteStatusStep = ["Offer Extended", "Expired"];
  const rejectedStatusStep = ["Offer Extended", "Rejected"];

  const getEfforts = async (email: string) => {
    try {
      const response = await TimesheetServices.getCandidateEfforts({
        email,
        project_id: offerDetails?.project_id,
        company_id: userInfo?.company?.id,
      });
      if (response) {
        dispatch(setContractorEffortsDetails(response));
      }
    } catch (error) {}
  };
  const getPlacement = async (email: string) => {
    try {
      const response = await OnboardingServices.getCandidatePlacementDetails({
        project_id: offerDetails?.project_id,
        email,
      });
      if (response && response.length) {
        dispatch(setContractorPlacmentDetails(response[0]));
        return response;
      }
    } catch (error) {}
  };
  const getContractOnboardingDetails = async (email: string) => {
    try {
      const response = await OnboardingServices.getOnboardingDetails({
        project_id: offerDetails?.project_id,
        email,
      });
      if (response) {
        dispatch(setContractorOnboardingDetails(response));
        dispatch(setIsContractorOnboarded(true));
      }
    } catch (error) {
      dispatch(setContractorOnboardingDetails(null));
      dispatch(setIsContractorOnboarded(false));
    }
  };

  const getCandidateDetials = async (id: string) => {
    dispatch(setIsCandidateDetailsLoading(true));
    try {
      const response = await SubmissionService.getCandidateDetailsById({ id });
      if (response.results.length) {
        dispatch(setCandidateDetails(response.results[0]));
        const placementResponse = await getPlacement(
          response.results[0].email_address
        );
        await getEfforts(response.results[0].email_address);
        if (placementResponse) {
          placementResponse[0]?.onboarding_status !== "Pending" &&
            getContractOnboardingDetails(response.results[0].email_address);
        }
      } else {
        dispatch(setCandidateDetails(null));
      }
    } catch (error) {
    } finally {
      dispatch(setIsCandidateDetailsLoading(false));
    }
  };
  const onBoardContract = () => {
    if (submissionDetails && submissionDetails.candidate_id) {
      getCandidateDetials(submissionDetails.candidate_id);
    }
    dispatch(setIsContractorDetailsDrawerOpen(true));
    dispatch(setIsCandidateDetailsDrawerOpen(false));
    dispatch(setCondidateTabValue(0));
    dispatch(setSelectedContractorDrawerTab(5));
  };

  return (
    <>
      <Stack
        className="scrollHide"
        sx={{ minWidth: 196, height: 270, overflow: "auto" }}
      >
        <OfferStatusStepper
          activeStep={allStatusStep.indexOf(selectedOffer.status)}
          stepData={allStatusStep}
        />
      </Stack>
      {selectedOffer.status === "Offer Accepted" && (
        <Stack gap={2} pt={"40px"} mx={1}>
          {/* <AtsRoundedButton
              buttonWidth={180}
              buttonHeight={34}
              text="Download Offer"
              buttonVariant="outline"
              startIcon={<ArrowCircleDown2 size={16} color={COLORS.PRIMARY} />}
            /> */}
          <AtsRoundedButton
            buttonWidth={180}
            buttonHeight={34}
            text="Onboard Contractor"
            fs={12}
            fw={500}
            endIcon={<ArrowRight2 size={16} color={"white"} />}
            onClick={onBoardContract}
          />
        </Stack>
      )}
    </>
  );
};

export default StatusDetails;
