import dayjs, { Dayjs } from "dayjs";
import { createContext, useContext, useState } from "react";

type RequestToConvertContextType = {
  openRequestConvertDialog: boolean;
  isSendOffer: boolean;
  annualRate: string;
  startDate: Dayjs;
  setOpenRequestConvertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSendOffer: React.Dispatch<React.SetStateAction<boolean>>;
  setAnnualRate: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs>>;
};

const RequestToConvertModule =
  createContext<RequestToConvertContextType | null>(null);

export const useRequestToConvert = () =>
  useContext(RequestToConvertModule) as RequestToConvertContextType;

export const RequestToConvertProvider = ({ children }: any) => {
  const [openRequestConvertDialog, setOpenRequestConvertDialog] =
    useState<boolean>(false);
  const [isSendOffer, setIsSendOffer] = useState<boolean>(false);
  const [annualRate, setAnnualRate] = useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());

  return (
    <RequestToConvertModule.Provider
      value={{
        openRequestConvertDialog,
        isSendOffer,
        annualRate,
        startDate,
        setStartDate,
        setOpenRequestConvertDialog,
        setIsSendOffer,
        setAnnualRate,
      }}
    >
      {children}
    </RequestToConvertModule.Provider>
  );
};
