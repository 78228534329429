import { Box, Skeleton, Stack } from "@mui/material";
import moment from "moment";
import CompanyIcon from "../../../../../../../../assets/images/company1.png";
import Harverd from "../../../../../../../../assets/images/harverd.png";
import AtsIconLabel from "../../../../../../../../components/AtsIconLabel";
import AtsText from "../../../../../../../../components/AtsText";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../../providers/TimesheetProvider";
import { getSubmissionState } from "../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../redux/store";
import { BORDERS } from "../../../../../../../../theme";
import { getTimePeriod } from "../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";
import AboutModule from "./modules/AboutModule";
import ProfileModule from "./modules/ProfileModule";
import AtsEmptyState from "../../../../../../../../components/AtsEmptyState";

const ProfileTab = () => {
  const { candidateDetails, isCandidateDetailsLoading } =
    useAppSelector(getSubmissionState);
  const { pdfRef } = useTimesheet();

  const renderProfileSection = () => {
    return (
      <>
        <Section title={`Profile`} pt="0px">
          <ProfileModule />
        </Section>
        <Section title={`About ${candidateDetails?.first_name ?? ""}`}>
          <AboutModule />
        </Section>
        <Section title="Top Skills">
          <Stack direction={"row"} flexWrap={"wrap"}>
            {isCandidateDetailsLoading ? (
              <Skeleton width={"100%"} height={40} />
            ) : candidateDetails?.ats_can_skills.length ? (
              candidateDetails?.ats_can_skills.map((skill) => (
                <AtsIconLabel key={skill.id} value={skill.title} />
              ))
            ) : (
              <Stack
                sx={{
                  width: "100%",
                }}
              >
                <AtsEmptyState
                  imgHeight={50}
                  imgWidth={50}
                  text="No top skills recently."
                  subText=""
                />
              </Stack>
            )}
          </Stack>
        </Section>

        <Section title="Experience">
          <Stack gap={"22px"}>
            {candidateDetails?.experience &&
            candidateDetails?.experience.length > 0 ? (
              candidateDetails?.experience.map((exp, index) => {
                const logo =
                  isNotNullOrUndefined(exp?.logo) && exp.logo.length
                    ? exp.logo
                    : CompanyIcon;
                const company = isNotNullOrUndefined(exp?.company)
                  ? exp.company + "|"
                  : "";
                const job_title = isNotNullOrUndefined(exp?.job_title)
                  ? exp.job_title
                  : "";
                const description = isNotNullOrUndefined(exp?.description)
                  ? exp.description
                  : "";
                const start_date = isNotNullOrUndefined(exp?.start_date)
                  ? moment(exp.start_date).format("MMM YYYY")
                  : "";
                const end_date = isNotNullOrUndefined(exp?.end_date)
                  ? moment(exp.end_date).format("MMM YYYY")
                  : "";
                const timePeriod =
                  isNotNullOrUndefined(exp?.start_date) &&
                  isNotNullOrUndefined(exp?.end_date)
                    ? `(${getTimePeriod(exp?.start_date, exp?.end_date)})`
                    : "";
                return (
                  <CustomListItem
                    key={index}
                    logo={logo}
                    title={`${company} ${job_title}`}
                    date={`${start_date} - ${end_date} ${timePeriod} `}
                    description={description}
                  />
                );
              })
            ) : (
              <AtsEmptyState
                imgHeight={50}
                imgWidth={50}
                text="No experience recently."
                subText=""
              />
            )}
          </Stack>
        </Section>

        <Section title="Education">
          <Stack gap={"22px"}>
            {candidateDetails?.education.length ? (
              candidateDetails?.education?.map((education, index) => {
                const logo =
                  isNotNullOrUndefined(education?.logo) && education.logo.length
                    ? education.logo
                    : Harverd;
                const school = isNotNullOrUndefined(education?.school)
                  ? education.school
                  : "";
                const field_of_study = isNotNullOrUndefined(education?.degree)
                  ? ` | ${education.degree}`
                  : "";
                const description = isNotNullOrUndefined(education?.description)
                  ? education.description
                  : "";
                const start_date = isNotNullOrUndefined(education?.start_date)
                  ? moment(education.start_date).format("MMM YYYY")
                  : "";
                const end_date = isNotNullOrUndefined(education?.end_date)
                  ? moment(education.end_date).format("MMM YYYY")
                  : "";
                const timePeriod =
                  isNotNullOrUndefined(education?.start_date) &&
                  isNotNullOrUndefined(education?.end_date)
                    ? `(${getTimePeriod(
                        education?.start_date,
                        education?.end_date
                      )})`
                    : "";
                return (
                  <CustomListItem
                    key={index}
                    logo={logo}
                    title={`${school} ${field_of_study}`}
                    date={`${start_date} - ${end_date} ${timePeriod} `}
                    description={description}
                    isHTMLTag
                  />
                );
              })
            ) : (
              <AtsEmptyState
                imgHeight={50}
                imgWidth={50}
                text="No education recently."
                subText=""
              />
            )}
          </Stack>
        </Section>
        <Section title="Certifications" isHideBorderBottom>
          <Stack gap={"22px"}>
            {candidateDetails?.certifications.length ? (
              candidateDetails?.certifications?.map((cret, index) => {
                const logo =
                  isNotNullOrUndefined(cret?.logo) && cret.logo.length
                    ? cret.logo
                    : CompanyIcon;

                const cert_id = isNotNullOrUndefined(cret?.cert_id)
                  ? cret.cert_id
                  : "";
                const description = isNotNullOrUndefined(cret?.description)
                  ? cret.description
                  : "";
                const cert_end_date = isNotNullOrUndefined(cret?.cert_end_date)
                  ? moment(cret.cert_end_date).format("MMM YYYY")
                  : "";
                const cert_start_date = isNotNullOrUndefined(
                  cret?.cert_start_date
                )
                  ? moment(cret.cert_start_date).format("MMM YYYY")
                  : "";

                const timePeriod =
                  isNotNullOrUndefined(cret?.cert_start_date) &&
                  isNotNullOrUndefined(cret.cert_end_date)
                    ? `(${getTimePeriod(
                        cret?.cert_start_date,
                        cret.cert_end_date
                      )})`
                    : "";

                return (
                  <CustomListItem
                    key={index}
                    logo={logo}
                    title={cert_id}
                    date={
                      !isNotNullOrUndefined(cret.cert_end_date)
                        ? `${cert_start_date} - Present`
                        : `${cert_start_date} - ${cert_end_date} ${timePeriod} `
                    }
                    description={description}
                  />
                );
              })
            ) : (
              <AtsEmptyState
                imgHeight={50}
                imgWidth={50}
                text="No certifications recently."
                subText=""
              />
            )}
          </Stack>
        </Section>
      </>
    );
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          width: 900,
        }}
        ref={pdfRef}
      >
        <Stack p={"20px"} gap={"20px"}>
          <Box>
            <img
              src={require("../../../../../../../../assets/images/logo.png")}
              alt="Nvidia Logo"
              style={{ width: 257 }}
            />
          </Box>
          {renderProfileSection()}
        </Stack>
      </div>
      <div>{renderProfileSection()}</div>
    </>
  );
};

export default ProfileTab;
const Section = ({
  title,
  children,
  isHideBorderBottom = false,
  pt = "30px",
}) => (
  <Stack
    gap={2}
    pt={pt}
    pb={"30px"}
    sx={!isHideBorderBottom && { borderBottom: BORDERS.GRAY }}
  >
    <AtsTitleText fs={20} fw={600} text={title} />
    {children}
  </Stack>
);
const CustomListItem = ({
  logo,
  title,
  date,
  description,
  isHTMLTag,
}: {
  logo: string;
  title: string;
  date: string;
  description: string;
  isHTMLTag?: boolean;
}) => (
  <Stack direction={"row"} gap={"21px"}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        // border: BORDERS.GRAY,
        width: 52,
        height: 52,
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: 52,
        }}
      />
    </Box>

    <Stack>
      <AtsText
        sx={{
          fontSize: 16,
          fontWeight: 600,
          color: "#231f20",
          lineHeight: "1.38",
        }}
        text={title}
      />
      <AtsText
        sx={{
          fontSize: 14,
          color: "#6d6d6d",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
        text={date}
      />
      {isHTMLTag ? (
        <Stack className="education">
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </Stack>
      ) : (
        <AtsText
          sx={{
            fontSize: 12,
            color: "#231f20",
            lineHeight: 1.58,
          }}
          text={description}
        />
      )}
    </Stack>
  </Stack>
);
