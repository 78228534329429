import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsBackLink from "../../../../components/AtsBackLink";
import AtsChip from "../../../../components/AtsChip";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../theme";
import FontUtil from "../../../../utils/FontUtil";
import TaskCard from "../TaskCard";
import { toLowerCase } from "../../../../utils/stringUtils";
import EmptyTaskState from "./EmptyTaskState";
import { ReminderServices } from "../../../../Api/reminderServices";
import { useAppSelector } from "../../../../redux/store";
import { getAuthState } from "../../../../redux/slices/authSlice";
import {
  GetRemindersParams,
  RemindersResponse,
  UpdateRemindersRequest,
} from "../../../../types/reminderTypes";
import LoadingTaskCard from "../LoadingTaskCard";
import { getDateFormatMMMDYYYYY, getTime } from "../../../../utils/dateUtils";
import { UserManagementServices } from "../../../../Api/userManagementServices";
import toast from "react-hot-toast";
import { StatusEnum } from "../../../../enums/commonEnums";

interface Props {
  isOpen: boolean;
}

interface TaskProps {
  status: string;
  task: string;
  date: string;
  day: string;
  id: number;
}

const TASKS: TaskProps[] = [
  {
    status: "PENDING",
    task: "Screening of current application",
    date: "May 27, 2024 - 8:00 AM",
    day: "TODAY",
    id: 1,
  },
  {
    status: "CLOSED",
    task: "Sr. Java Developer Offer latter review",
    date: "May 27, 2024 - 8:00 AM",
    day: "TODAY",
    id: 2,
  },
  {
    status: "PENDING",
    task: "Drupal interview",
    date: "May 27, 2024 - 8:00 AM",
    day: "TODAY",
    id: 3,
  },
  {
    status: "PENDING",
    task: "Jobs design in react app",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 4,
  },
  {
    status: "CLOSED",
    task: "Report card in colleagues app",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 5,
  },
  {
    status: "PENDING",
    task: "Sr. Dot NET Developer Offer latter review",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 6,
  },
  {
    status: "PENDING",
    task: "Screening of current application",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 7,
  },
  {
    status: "CLOSED",
    task: "Screening of current application",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 8,
  },
];

const TaskSidebar = ({ isOpen }: Props) => {
  const { font10 } = FontUtil();
  const { userInfo } = useAppSelector(getAuthState);
  const [taskList, setTaskList] = useState<RemindersResponse[]>([]);
  const [activeStatus, setActiveStatus] = useState<string>("pending");
  const [allTasks, setAllTasks] = useState<RemindersResponse[]>([]);
  const [closingTaskId, setClosingTaskId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onClickStatus = (status: string) => {
    setActiveStatus(status);
    const taskFilter = taskList.filter((task) => task.status === status);
    setTaskList(status === "all" ? taskList : taskFilter);
  };

  const getCount = (status: string) => {
    if (status === "all") return allTasks.length;
    return allTasks.filter((task) => task.status === status).length;
  };

  const statusCount = (
    count: number | string,
    status: string,
    value: string
  ) => {
    const isActive = activeStatus === value;
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={0.5}
        sx={{
          height: 22,
          borderRight: value !== "ALL" ? BORDERS.GRAY : "none",
          cursor: "pointer",
          ...(value === "PENDING" ? { pr: 1.3 } : { px: 1.3 }),
        }}
        onClick={() => onClickStatus(value)}
      >
        <AtsTitleText
          text={status}
          fs={10}
          fw={isActive ? 700 : 400}
          textColor={isActive ? COLORS.BLACK : COLORS.LIGHT_GRAY}
        />
        <AtsChip
          text={count}
          sx={{
            height: "17px",
            bgcolor: isActive ? "var(--primary)" : "white",
            color: isActive ? "white" : "balck",
            ...font10,
          }}
        />
      </Stack>
    );
  };

  const onCloseTask = (id: string) => {
    setClosingTaskId(id);
    setTimeout(() => {
      const updatedTasks = allTasks.map((task) =>
        task.nid === id ? { ...task, status: "closed" } : task
      );
      setAllTasks(updatedTasks);
      setClosingTaskId(null); // Reset the closing state
      const taskFilter = updatedTasks.filter(
        (task) => activeStatus === "all" || task.status === activeStatus
      );
      setTaskList(taskFilter);
      onClick("closed", id);
    }, 300);
  };

  const getUserList = async () => {
    try {
      const response = await UserManagementServices.getActiveUsers(
        StatusEnum.ACTIVE
      );
      return response.results.map((val) => val.email);
    } catch (error) {
      console.error(error);
    }
  };

  const onClick = async (keyVal: keyof UpdateRemindersRequest, nid: string) => {
    setLoading(true);
    const body = { nid } as Partial<UpdateRemindersRequest>;
    if (keyVal === "assignees") {
      const teamMemeberEmail = await getUserList();
      const emailObject = teamMemeberEmail.reduce((obj, email, index) => {
        obj[index] = email;
        return obj;
      }, {});
      body[keyVal] = emailObject;
    } else {
      body[keyVal] = 1;
    }
    try {
      await ReminderServices.updateReminders(body);
      toast.success("Update successfully!");
      firstTimeGetReminders();
      getReminders();
    } catch (error) {}
  };

  const getReminders = async () => {
    setLoading(true);
    try {
      const body = {
        company_id: userInfo?.company?.id,
        status: activeStatus,
      } as GetRemindersParams;
      const response = await ReminderServices.getReminders(body);
      setTaskList(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const firstTimeGetReminders = async () => {
    try {
      const body = {
        company_id: userInfo?.company?.id,
        status: "all",
      } as GetRemindersParams;
      const response = await ReminderServices.getReminders(body);
      setAllTasks(response);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    firstTimeGetReminders();
  }, []);
  useEffect(() => {
    getReminders();
  }, [activeStatus]);

  const renderTaskCard = (task: RemindersResponse) => {
    const dateFormat = task.due_date
      ? getDateFormatMMMDYYYYY(task.due_date)
      : "";
    const timeFormat = task.due_date ? getTime(task.due_date) : "";

    const dueDateText = `${dateFormat} - ${timeFormat}`;
    return (
      <Stack
        key={task.nid}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...(closingTaskId === task.nid && {
            transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
          }),
          transform: closingTaskId === task.nid ? "translateX(100%)" : "none",
          opacity: closingTaskId === task.nid ? 0 : 1,
        }}
      >
        <TaskCard
          index={task.nid}
          status={task.status}
          taskDes={task.message}
          dueTime={dueDateText}
          onClose={onCloseTask}
          onClick={onClick}
        />
      </Stack>
    );
  };

  // const renderTaskDayTitle = (title: string) => {
  //   return taskList.filter((task) => task.day === title).length ? (
  //     <Stack sx={{ pt: title === "TOMORROW" ? 4 : 0 }} px={isOpen ? 5 : 1}>
  //       <AtsTitleText
  //         text={toLowerCase(title)}
  //         fs={12}
  //         fw={700}
  //         textColor={"#98999a"}
  //       />
  //     </Stack>
  //   ) : (
  //     ""
  //   );
  // };

  return (
    <>
      <Box
        gap={1}
        pt={"20px"}
        sx={{
          height: "100%",
        }}
      >
        <Stack
          sx={{ pb: 2, pt: 1 }}
          px={isOpen ? 5 : 1}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <AtsTitleText fs={24} fw={600} text="Reminders" />
        </Stack>
        <Stack direction={"row"} pb={2} px={isOpen ? 5 : 1}>
          {statusCount(getCount("pending"), "Pending", "pending")}
          {statusCount(getCount("closed"), "Closed", "closed")}
          {statusCount(getCount("all"), "All", "all")}
        </Stack>
        <Stack
          className="tasksBar"
          gap={2}
          sx={{
            pt: 2,
            pb: 1,
            overflow: "auto",
            height: "calc(100% - 183px)",
          }}
        >
          {/* <EmptyTaskState /> */}
          {/* {renderTaskDayTitle("TODAY")}
          {taskList
            .filter((task) => task.day === "TODAY")
            .map((task, index) => renderTaskCard(task))}
          {renderTaskDayTitle("TOMORROW")} */}
          {loading ? (
            Array.from(new Array(4)).map((_, index) => (
              <Stack
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <LoadingTaskCard />
              </Stack>
            ))
          ) : taskList.length ? (
            taskList.map((task, index) => renderTaskCard(task))
          ) : (
            <EmptyTaskState />
          )}
        </Stack>
        <Stack direction={"row"} justifyContent={"center"} py={2}>
          <AtsBackLink text="Show all" isRightIcon />
        </Stack>
      </Box>
    </>
  );
};

export default TaskSidebar;
