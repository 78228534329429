import axios from "axios";
import {
  GetRemindersParams,
  RemindersResponse,
  UpdateRemindersRequest,
} from "../types/reminderTypes";
import { SubmissionPrioritiesResponse } from "../types/topPrioritiesTypes";
import {
  GetOtherInsightsResponse,
  GetRequisitionResponse,
} from "../types/insightsTypes";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  GET_REQUISITIONS_LOCATION: "insights/job-requisitions-locations",
  GET_COMPANY_INSIGHTS: "company-insights/get",
  GET_REMOTE_LOCATION: "insights/job-remote-locations",
};

const GET_REQUISITIONS_LOCATION_URL =
  Endpoint.BASE + Endpoint.GET_REQUISITIONS_LOCATION;
const GET_COMPANY_INSIGHTS_URL = Endpoint.BASE + Endpoint.GET_COMPANY_INSIGHTS;
const GET_REMOTE_LOCATION_URL = Endpoint.BASE + Endpoint.GET_REMOTE_LOCATION;

export class InsightsServices {
  static async getRequisitionsLocation(params: {
    company_id: string;
  }): Promise<GetRequisitionResponse[]> {
    const response = await axios.get(GET_REQUISITIONS_LOCATION_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getCompanyInsights(params: {
    company_id: string;
  }): Promise<GetOtherInsightsResponse> {
    const response = await axios.get(GET_COMPANY_INSIGHTS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getRemoteLocation(params: {
    company_id: string;
  }): Promise<RemindersResponse[]> {
    const response = await axios.get(GET_REMOTE_LOCATION_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
