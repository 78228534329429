import { createContext, useContext, useEffect } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { setIsAuth } from "../redux/slices/authSlice";

type AutoLogoutModuleContextType = {
  scheduleAutoLogout: (time: number) => void;
  handleLogout: () => void;
};

const AutoLogoutModule = createContext<AutoLogoutModuleContextType | null>(
  null
);

export const useAutoLogout = () =>
  useContext(AutoLogoutModule) as AutoLogoutModuleContextType;

export const AutoLogoutProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eightHoursInMs = 8 * 60 * 60 * 1000;

  useEffect(() => {
    const loginTime = localStorage.getItem("loginTime");

    if (loginTime) {
      const expiryTime = new Date(parseInt(loginTime, 10) + eightHoursInMs);
      const currentTime = new Date();
      if (currentTime >= expiryTime) {
        handleLogout();
      } else {
        const remainingTime = expiryTime.getTime() - currentTime.getTime();
        scheduleAutoLogout(remainingTime);
      }
    }
  }, []);

  const clearCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });
  };

  const handleLogout = () => {
    dispatch(setIsAuth(false));
    localStorage.clear();
    sessionStorage.clear();
    axios.defaults.headers.common["Authorization"] = "";
    clearCookies();
    navigate("/login");
  };

  const scheduleAutoLogout = (time: number) => {
    setTimeout(() => {
      handleLogout();
    }, time);
  };

  return (
    <AutoLogoutModule.Provider
      value={{
        scheduleAutoLogout,
        handleLogout,
      }}
    >
      {children}
    </AutoLogoutModule.Provider>
  );
};
