import axios from "axios";
import {
  BillingInfoResponse,
  CreateUpdateBillingInfoRequest,
} from "../types/billingTypes";
import { CompanyInfoResponse } from "../types/companyTypes";

export const Endpoint = {
  BASE: "/ats/api/v1/",

  GET_BILLING: "billing-contact/get",
  CREATE_BILLING: "billing-contact/create",
  UPDATE_BILLING: "billing-contact/update",
};

const GET_BILLING_URL = Endpoint.BASE + Endpoint.GET_BILLING;
const CREATE_BILLING_URL = Endpoint.BASE + Endpoint.CREATE_BILLING;
const UPDATE_BILLING_URL = Endpoint.BASE + Endpoint.UPDATE_BILLING;

export class BillingServices {
  static async getBillingInfo(): Promise<BillingInfoResponse | any> {
    const response = await axios.get(GET_BILLING_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createBillingInfo(
    request: Partial<CreateUpdateBillingInfoRequest>
  ): Promise<CompanyInfoResponse> {
    const response = await axios.post(CREATE_BILLING_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateBillingInfo(
    request: Partial<CreateUpdateBillingInfoRequest>
  ): Promise<CompanyInfoResponse> {
    const response = await axios.patch(UPDATE_BILLING_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
