import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ATS_ROUTES } from "./routes";
import { useAppSelector } from "../redux/store";
import { getAuthState } from "../redux/slices/authSlice";

interface IProps {
  children: ReactNode;
}

const PrivateRoutes = ({ children }: IProps) => {
  const { pathname } = useLocation();
  const { isAuth, userInfo } = useAppSelector(getAuthState);
  console.log("🚀 ~ PrivateRoutes ~ userInfo:", userInfo);
  if (isAuth && (pathname === "/" || pathname === ATS_ROUTES.LOGIN))
    return <Navigate to={ATS_ROUTES.DASHBOARD} replace />;
  if (
    isAuth &&
    userInfo?.sub_role &&
    userInfo?.sub_role?.role_name &&
    userInfo?.sub_role?.role_name !== "Admin" &&
    (pathname === ATS_ROUTES.USER_MANAGEMENT ||
      pathname === ATS_ROUTES.USER_ROLES)
  )
    return <Navigate to={ATS_ROUTES.NO_ACCESS} replace />;
  if (isAuth) return <>{children}</>;

  return <Navigate to={ATS_ROUTES.LOGIN} replace />;
};

export default PrivateRoutes;
