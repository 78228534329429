import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import { JobIndustriesResponse } from "../../../types/jobsTypes";
import ButtonSelection from "../components/ButtonSelection";
import JobCardWrapper from "../components/JobCardWrapper";
import JobSkillsSelector from "../components/JobSkillsSelector";

const JobSkills = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById } = useAppSelector(
    getPostOpportunityState
  );
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [jobSkills, setJobSkills] = useState<JobIndustriesResponse[]>([]);

  const handleChange = (value: string) => {
    const preSkills = [...postOpportunity.job_require_skills];
    if (preSkills.includes(value)) {
      const updatedSKill = preSkills.filter((day) => day !== value);
      const opportunityObj = {
        ...postOpportunity,
        job_require_skills: updatedSKill.filter((str) => str !== ""),
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const updatedSKill = [...preSkills, value];
      const opportunityObj = {
        ...postOpportunity,
        job_require_skills: updatedSKill.filter((str) => str !== ""),
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const fetchJobSkills = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobSkills({
        name: jobDetailsById.job_category,
        page,
        items_per_page: 10,
      });
      setJobSkills(response.results);

      setTotalItem(response.pager.pages);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobSkills();
  }, []);
  return (
    <>
      <JobCardWrapper header="What skills does your job require?">
        <Stack mt={2}>
          <Stack gap={2}>
            <JobSkillsSelector
              selected={""}
              onSelected={(seleted) => handleChange(seleted)}
            />
            <Stack
              direction={"row"}
              gap={1}
              flexWrap={"wrap"}
              pb={2}
              sx={{ borderBottom: BORDERS.GRAY }}
            >
              {postOpportunity.job_require_skills?.length > 0 &&
                postOpportunity.job_require_skills
                  .filter((str) => str !== "")
                  .map((skill, index) => (
                    <ButtonSelection
                      key={index}
                      onSelect={() => handleChange(skill)}
                      text={skill}
                      isSelected={true}
                    />
                  ))}
            </Stack>
          </Stack>
          <Stack gap={1} pt={2}>
            <AtsTitleText
              text={"Related options"}
              fs={"0.875rem"}
              fw={600}
              textColor={COLORS.LIGHT_GRAY}
            />
            <Stack
              sx={{
                height: 200,
              }}
            >
              <Stack
                sx={{
                  height: "calc(100%- 32px)",
                  overflow: "scroll",
                }}
              >
                <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                  {loading
                    ? Array.from(new Array(8)).map((_, index) => (
                        <AtsRoundedButton
                          key={index}
                          text={"...loading"}
                          loading={loading}
                          buttonVariant={"whiteContained"}
                        />
                      ))
                    : jobSkills.map((skill, index) => (
                        <ButtonSelection
                          onSelect={() => handleChange(skill.name)}
                          key={index}
                          text={skill.name}
                          loading={loading}
                          isSelected={postOpportunity.job_require_skills.includes(
                            skill.name
                          )}
                        />
                      ))}
                </Stack>
              </Stack>
              {/* <Stack pt={3}>
                <Pagination
                  page={page + 1}
                  count={totalItem}
                  onChange={(_, page) => {
                    setPage(page - 1);
                  }}
                  sx={{
                    ".Mui-selected": {
                      bgcolor: COLORS.PRIMARY,
                      color: "white",
                    },
                  }}
                />
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default JobSkills;
