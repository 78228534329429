import { Menu } from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { UsersIcon } from "../../assets/icons/Icons";
import AtsRoundedButton from "../../components/AtsRoundedButton";
import InviteMembers from "../../pages/InviteMembers/InviteMembers";
import { setInitialInviteMemeberState } from "../../redux/slices/inviteMembersSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { BORDERS } from "../../theme";
import AtsSidebar from "../components/AtsSidebar";
import "../css/header.css";
import Notifications from "./Notifications/Notifications";
import UserInfo from "./UserInfo";
import { getAuthState } from "../../redux/slices/authSlice";

const Header = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenInviteMembers, setIsOpenInviteMembers] =
    useState<boolean>(false);
  return (
    <>
      <Stack
        direction={"row"}
        className="header"
        sx={{ justifyContent: "space-between" }}
      >
        <Box>
          {isMobile && (
            <IconButton size="large" onClick={() => setOpen(!open)}>
              <Menu />
            </IconButton>
          )}
        </Box>
        <Stack direction={"row"} alignItems={"center"} gap={2} mx={3}>
          {userInfo?.sub_role &&
            userInfo?.sub_role?.role_name === "Admin" &&
            (isMobile ? (
              <IconButton
                size="large"
                sx={{
                  border: BORDERS.PRIMARY,
                }}
              >
                <UsersIcon />
              </IconButton>
            ) : (
              <AtsRoundedButton
                text="Invite Members"
                buttonVariant="outline"
                startIcon={<UsersIcon />}
                buttonHeight={32}
                buttonWidth={161}
                onClick={() => setIsOpenInviteMembers(!isOpenInviteMembers)}
              />
            ))}

          <Notifications />
          <UserInfo />
        </Stack>
        <Drawer
          PaperProps={{
            sx: {
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
              width: isMobile ? "80%" : "70%",
              overflow: "hidden",
            },
          }}
          anchor="left"
          open={open}
          onClose={() => setOpen(!open)}
        >
          <AtsSidebar isOpen={open} />
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              width: "680px",
              overflow: "hidden",
            },
          }}
          anchor="right"
          open={isOpenInviteMembers}
          onClose={() => {
            dispatch(setInitialInviteMemeberState());
            setIsOpenInviteMembers(!isOpenInviteMembers);
          }}
        >
          <InviteMembers
            isOpen={isOpenInviteMembers}
            setOpen={setIsOpenInviteMembers}
          />
        </Drawer>
      </Stack>
    </>
  );
};

export default Header;
