import axios from "axios";
import {
  AllUserAvailabilityResponse,
  CalendarEventResponse,
  ContractsEventsResponse,
  EventsParams,
  GetAvailabilityOfUsersRequest,
  GetCandidateInterviewByJobParams,
  GetCandidateInterviewListByJobResponse,
  GetDayAvailabilityResponse,
  GetUnAvailabilityOfUsersResponse,
  GoogleCalendarEventResponse,
  GoogleEventRequest,
  InterviewEventsResponse,
  InvoiceDueEventsResponse,
} from "../types/calendarTypes";
import { COLLEAGUES_DEV_ENDPOINT, PROJECT_BASE_URL } from "../utils/url";
import { PaginatedResponse } from "../types/commonType";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  COLLEAGUES_DEV_BASE: "/v1/",
  GET_AVILABILITY: "availability/get",
  SET_AVILABILITY: "set-availability",
  GET_AVILABILITY_OF_USERS: "get-availability",
  GET_UNAVILABILITY_OF_USERS: "get-un-available-timeslots",
  CALENDAR_CONNECTED: "google-auth/find",
  GET_ALL_CALENDAR_EVENTS: "interview/calendar/google",
  INTERVIEW_BY_JOB: "candidate-interviews-by-job",
  TIMEZONE: "get/timezones",
  CREATE_GOOGLE_EVENT: "google-calendar/event",
  INTERVIEW_EVENT: "interviews-events",
  INVOICE_EVENT: "invoices-events",
  CONTRACTS_EVENT: "contracts-events",
};

const INVOICE_EVENT_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.INVOICE_EVENT;
const INTERVIEW_EVENT_URL = Endpoint.BASE + Endpoint.INTERVIEW_EVENT;
const CONTRACTS_EVENT_URL = Endpoint.BASE + Endpoint.CONTRACTS_EVENT;
const TIMEZONE_URL = Endpoint.BASE + Endpoint.TIMEZONE;
const GET_AVILABILITY_URL = Endpoint.BASE + Endpoint.GET_AVILABILITY;
const INTERVIEW_BY_JOB_URL = Endpoint.BASE + Endpoint.INTERVIEW_BY_JOB;
const GET_AVILABILITY_OF_USERS_URL =
  Endpoint.BASE + Endpoint.GET_AVILABILITY_OF_USERS;
const GET_UNAVILABILITY_OF_USERS_URL =
  Endpoint.BASE + Endpoint.GET_UNAVILABILITY_OF_USERS;
const SET_AVILABILITY_URL = Endpoint.BASE + Endpoint.SET_AVILABILITY;
const CALENDAR_CONNECTED_URL =
  COLLEAGUES_DEV_ENDPOINT +
  Endpoint.COLLEAGUES_DEV_BASE +
  Endpoint.CALENDAR_CONNECTED;
const GET_ALL_CALENDAR_EVENTS_URL =
  COLLEAGUES_DEV_ENDPOINT +
  Endpoint.COLLEAGUES_DEV_BASE +
  Endpoint.GET_ALL_CALENDAR_EVENTS;
const CREATE_GOOGLE_EVENT_URL =
  COLLEAGUES_DEV_ENDPOINT +
  Endpoint.COLLEAGUES_DEV_BASE +
  Endpoint.CREATE_GOOGLE_EVENT;

export class CalendarServices {
  static async getAvailability(): Promise<GetDayAvailabilityResponse> {
    const response = await axios.get(GET_AVILABILITY_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async calendarConnected() {
    const response = await axios.get(CALENDAR_CONNECTED_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateInterviewByJob(
    params: Partial<GetCandidateInterviewByJobParams>
  ): Promise<PaginatedResponse<GetCandidateInterviewListByJobResponse>> {
    const response = await axios.get(INTERVIEW_BY_JOB_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async setAvailability(
    request: Partial<GetDayAvailabilityResponse>
  ): Promise<GetDayAvailabilityResponse> {
    const response = await axios.post(SET_AVILABILITY_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getAvailabilityOfUsers(
    request: Partial<GetAvailabilityOfUsersRequest>
  ): Promise<AllUserAvailabilityResponse> {
    const response = await axios.post(GET_AVILABILITY_OF_USERS_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async createGoolgeEvent(
    request: Partial<GoogleEventRequest>
  ): Promise<CalendarEventResponse> {
    const response = await axios.post(CREATE_GOOGLE_EVENT_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getUnAvailabilityOfUsers(
    request: Partial<GetAvailabilityOfUsersRequest>
  ): Promise<GetUnAvailabilityOfUsersResponse> {
    const response = await axios.post(GET_UNAVILABILITY_OF_USERS_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getAllCalendarEventsOfUsers(
    request: Partial<GetAvailabilityOfUsersRequest>
  ): Promise<GoogleCalendarEventResponse[]> {
    const response = await axios.post(GET_ALL_CALENDAR_EVENTS_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getTimeZone(): Promise<{ data: string[] }> {
    const response = await axios.get(TIMEZONE_URL);
    return response.data;
  }

  static async getInvoiceDueEvents(
    params: Partial<EventsParams>
  ): Promise<InvoiceDueEventsResponse[]> {
    const response = await axios.get(INVOICE_EVENT_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getContractsEvents(
    params: Partial<EventsParams>
  ): Promise<ContractsEventsResponse[]> {
    const response = await axios.get(CONTRACTS_EVENT_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getInterviewEvents(
    params: Partial<EventsParams>
  ): Promise<InterviewEventsResponse[][]> {
    const response = await axios.get(INTERVIEW_EVENT_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
