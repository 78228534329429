import { Grid, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import AtsDatePicker from "../../../../../../../../../components/AtsDatePicker";
import AtsTimePicker from "../../../../../../../../../components/AtsTimePicker";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";

const OrientationSession = () => {
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);
  const {
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    editState,
  } = useOnboarding();
  const isEdit = isContractorOnboarded && !editState.isEditOrientationSession;
  return (
    <>
      <Stack gap={"30px"}>
        <Grid container columnSpacing={"40px"} rowSpacing={"30px"}>
          <Grid item xs={12} sm={6}>
            <Stack gap={"5px"}>
              <AtsTitleText text={"Select Date"} fs={14} fw={600} />
              {isEdit ? (
                <AtsTitleText
                  text={selectedDate.format("DD/MM/YYYY")}
                  fs={14}
                  fw={500}
                />
              ) : (
                <AtsDatePicker
                  format="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={(newValue: Dayjs | null) =>
                    setSelectedDate(newValue)
                  }
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack gap={"5px"}>
              <AtsTitleText text={"Select Time"} fs={14} fw={600} />
              {isEdit ? (
                <AtsTitleText
                  text={selectedTime.format("hh:mm a")}
                  fs={14}
                  fw={500}
                />
              ) : (
                <AtsTimePicker
                  value={selectedTime}
                  onChange={(newValue: Dayjs | null) =>
                    setSelectedTime(newValue)
                  }
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default OrientationSession;
