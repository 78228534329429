import { Close, HeadsetMic } from "@mui/icons-material";
import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsRow from "../../../../components/AtsRow";
import AtsRowBetween from "../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useMessages } from "../../../../providers/MessagesProvider";
import { BORDERS, BORDERS_2X, COLORS } from "../../../../theme";
import FontUtil from "../../../../utils/FontUtil";
import WorkingOn from "./components/WorkingOn";
import AtsIcon from "../../../../components/AtsIcon";
import { ProjectService } from "../../../../Api/projectServices";
import { ProjectListProps } from "../../../../types/projectTypes";
import { isNotNullOrUndefined } from "../../../../utils/stringUtils";
import { useAppSelector } from "../../../../redux/store";
import { getAuthState } from "../../../../redux/slices/authSlice";
import moment from "moment";

const ProjectDetails = () => {
  const { font12 } = FontUtil();
  const { userInfo } = useAppSelector(getAuthState);
  const { handleShowProjectDetails, messenger, projectId } = useMessages();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [desc, setDesc] = useState<string>("");
  const [projectsDetails, setProjectsDetails] =
    useState<ProjectListProps | null>(null);

  const getSubDesc = (description: string) => {
    if (showMore) {
      setDesc(description);
    } else if (description.length > 200) {
      const subDes = description.substring(0, 200);
      setDesc(`${subDes}...`);
    } else {
      setDesc(description);
    }
  };

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    isNotNullOrUndefined(projectsDetails) &&
      getSubDesc(projectsDetails.description);
  }, [showMore, projectsDetails]);

  const fetchProjectDetails = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({
        nid: messenger?.atsProjectid,
      });
      setProjectsDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    isNotNullOrUndefined(messenger?.atsProjectid) && fetchProjectDetails();
  }, []);

  const accountManaget = isNotNullOrUndefined(userInfo.account_managers)
    ? userInfo.account_managers.find((manager) =>
        manager.primary === 1 ? manager : null
      )
    : null;
  return (
    <>
      <AtsRowBetween
        sx={{
          px: "30px",
          pt: "16px",
        }}
      >
        <>
          <AtsTitleText text={"Project details"} fs={18} fw={600} noTextWrap />
          <AtsCustomButton
            px={0}
            py={0}
            onClick={() => handleShowProjectDetails(false)}
          >
            <Close />
          </AtsCustomButton>
        </>
      </AtsRowBetween>
      <Stack
        sx={{
          px: "30px",
          pt: "27px",
          pb: "15px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack pb={"10px"}>
          <AtsTitleText
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
            text="Working on"
            noTextWrap
          />
        </Stack>
        {loading ? (
          <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
            <Box
              sx={{
                width: 25,
                height: 25,
              }}
            >
              <Skeleton variant="circular" width={25} height={25} />
            </Box>
            <Stack gap={"15px"}>
              <Stack gap={"5px"}>
                <Skeleton variant="text" width={100} height={25} />
                <Skeleton variant="text" width={60} height={25} />
              </Stack>
            </Stack>
          </Stack>
        ) : (
          isNotNullOrUndefined(projectsDetails) && (
            <WorkingOn projectsDetails={projectsDetails} />
          )
        )}
      </Stack>
      <Stack
        sx={{
          px: "30px",
          py: "18px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack gap={"10px"}>
          <AtsTitleText
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
            text="Discription"
            noTextWrap
          />
          {loading ? (
            <Skeleton variant="rounded" width={"100%"} height={120} />
          ) : (
            isNotNullOrUndefined(projectsDetails) && (
              <Typography
                sx={{
                  fontSize: font12,
                  fontWeight: 400,
                  lineHeight: "21px",
                }}
              >
                {desc}
                {"  "}
                {projectsDetails.description.length > 200 &&
                  (showMore ? (
                    <span
                      style={{
                        color: COLORS.PRIMARY,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleToggle}
                    >
                      Read Less
                    </span>
                  ) : (
                    <span
                      style={{
                        color: COLORS.PRIMARY,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleToggle}
                    >
                      Read More
                    </span>
                  ))}
              </Typography>
            )
          )}
        </Stack>
      </Stack>
      <AtsRow
        sx={{
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>
          <Stack
            gap={"5px"}
            sx={{
              px: "30px",
              py: "18px",
              borderRight: BORDERS.GRAY,
              width: "50%",
            }}
          >
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Start date"
              noTextWrap
            />
            {isNotNullOrUndefined(projectsDetails) && loading ? (
              <Skeleton variant="text" width={80} height={25} />
            ) : (
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={moment(projectsDetails?.start_date).format("DD/MM/YYYY")}
                noTextWrap
              />
            )}
          </Stack>
          <Stack
            gap={"5px"}
            sx={{
              px: "30px",
              py: "18px",
              width: "50%",
            }}
          >
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="End date"
              noTextWrap
            />
            {isNotNullOrUndefined(projectsDetails) && loading ? (
              <Skeleton variant="text" width={80} height={25} />
            ) : (
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={moment(projectsDetails?.end_date).format("DD/MM/YYYY")}
                noTextWrap
              />
            )}
          </Stack>
        </>
      </AtsRow>
      <Stack
        sx={{
          px: "30px",
          pt: "27px",
          pb: "15px",
          borderBottom: BORDERS_2X.GRAY,
        }}
      >
        <AtsRow gap={"5px"}>
          <>
            <AtsIcon
              icon="icon-messages"
              height="20px"
              fs={20}
              fw={600}
              color="#000"
            />
            <AtsTitleText
              fs={12}
              fw={500}
              textColor={"#000"}
              text={`Schedule a meeting with ${
                messenger?.recipient?.firstName ?? ""
              }`}
              noTextWrap
            />
            <AtsIcon
              icon="icon-arrow-right-3"
              height="16px"
              fs={16}
              color="#000"
            />
          </>
        </AtsRow>
      </Stack>
      <Stack
        sx={{
          px: "30px",
          py: "25px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack gap={"10px"}>
          <AtsTitleText
            fs={14}
            fw={500}
            textColor={"#000"}
            text={`Get support anytime`}
            noTextWrap
          />
          <AtsTitleText
            fs={12}
            fw={400}
            textColor={"#000"}
            text={`If you need help, we're available and redy to help however we can.`}
          />
        </Stack>
      </Stack>
      <Stack
        sx={{
          px: "30px",
          py: "25px",
          borderBottom: BORDERS.GRAY,
        }}
        gap={"10px"}
      >
        <AtsTitleText
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          text="Account Manager"
          noTextWrap
        />
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={"10px"}
          sx={{
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          {loading ? (
            <Skeleton variant="circular" width={30} height={30} />
          ) : (
            <Avatar sx={{ width: 30, height: 30 }} />
          )}

          <Stack gap={"8px"} flex={1}>
            {loading ? (
              <Skeleton variant="text" width={60} height={25} />
            ) : (
              <AtsTitleText
                text={
                  isNotNullOrUndefined(accountManaget)
                    ? `${accountManaget?.first_name ?? ""} ${
                        accountManaget?.last_name ?? ""
                      }`
                    : ""
                }
                fs={16}
                fw={600}
                textColor="#000"
              />
            )}
            <AtsTitleText
              text={"Connect now"}
              fs={12}
              fw={400}
              textColor={"#000"}
            />
          </Stack>
          <HeadsetMic
            sx={{
              fontSize: 18,
              color: COLORS.BLACK,
            }}
          />
        </Stack>
      </Stack>
      <AtsRowBetween
        sx={{
          px: "30px",
          pt: "20px",
          pb: "15px",
        }}
      >
        <>
          <AtsRow gap={"5px"}>
            <>
              <AtsIcon
                icon="icon-message-question"
                height="20px"
                fs={20}
                color="#000"
              />
              <AtsTitleText
                fs={12}
                fw={500}
                textColor={"#000"}
                text={`Visit our Help Center`}
                noTextWrap
              />
            </>
          </AtsRow>
          <AtsIcon
            icon="icon-arrow-right-3"
            height="16px"
            fs={16}
            color="#000"
          />
        </>
      </AtsRowBetween>
    </>
  );
};

export default ProjectDetails;
