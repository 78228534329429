import React from "react";
import ProjectDetails from "./modules/ProjectDetails/ProjectDetails";
import ContractorDetails from "./modules/ContractorDetails/ContractorDetails";
import { useMessages } from "../../providers/MessagesProvider";
import { motion } from "framer-motion";

const DetailsDrawer = () => {
  const { showProjectDetails, showContractorDetails } = useMessages();
  return (
    <>
      <motion.div
        animate={{
          width: showContractorDetails || showProjectDetails ? "300px" : "0px",
          transition: {
            duration: 0.3,
          },
        }}
        className={`taskbar`}
      >
        {showProjectDetails ? <ProjectDetails /> : <ContractorDetails />}
      </motion.div>
    </>
  );
};

export default DetailsDrawer;
