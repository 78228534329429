import { Close } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsModal from "../../../../components/AtsModal";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import AtsRow from "../../../../components/AtsRow";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useMessages } from "../../../../providers/MessagesProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../../theme";
import ProjectSelector from "../../../Projects/ProjectSelector";
import {
  CreateGroupRequest,
  GroupUsers,
} from "../../../../types/messagesTypes";
import { useAppSelector } from "../../../../redux/store";
import { getAuthState } from "../../../../redux/slices/authSlice";
import { CreateUpdateEnum } from "../../../../enums/commonEnums";
import { ProjectListProps } from "../../../../types/projectTypes";

const steps = ["CHOOSE_PROJECT", "GROUP_NAME"];

const user3 = {
  id: "ac5dcfa7-728f-448b-bc55-504c9613e41a",
  version: 16,
  createdBy: "shaishpatel@gmail.com",
  updatedBy: "shaishpatel@gmail.com",
  createdAt: "2024-08-20T07:46:46.552Z",
  updatedAt: "2024-10-03T11:10:07.686Z",
  keycloakId: "dbb8f22a-49cb-4e4c-ba41-ee431eaff7da",
  candidateId: null,
  referralId: null,
  firstName: "Shailendra",
  lastName: "Chodavadiya",
  email: "shaishpatel@gmail.com",
  mobileNumber: null,
  username: "shaishpatel_4p6h7t",
  picture:
    "https://esteemed-colleagues-app.s3.amazonaws.com/images/profiles/c4861128-c1ee-4559-970c-6562f2ff2e69-blob",
  personalizedEvent: false,
  provider: "email",
  isVerified: false,
  governmentInfoId: null,
  emergencyContactId: null,
  addressId: null,
  notificationId: null,
  sellerPreferenceId: null,
  globalPreferenceId: null,
  deactivatedAt: null,
  jobCategoryId: null,
  atsJobCategoryId: null,
  jobTitle: "Java Developer",
  experienceInYears: null,
  minHourlyRate: null,
  maxHourlyRate: null,
  skills: [
    "accounting",
    "financial management",
    "problem solving",
    "software reprogramming",
    "computerized accounting systems",
    "leadership",
    "training",
    "strategic planning",
    "customer service",
    "human relations",
    "data analysis",
    "quality control",
    "technical support",
    "HR policies",
    "customer relations",
    "project management",
  ],
  points: null,
  jobType: null,
  jobLocationSettings: null,
  jobLocationId: null,
  currentLocationId: null,
  immediateAvailability: null,
  startDate: null,
  links: [],
  languages: null,
  minAnnualSalary: null,
  maxAnnualSalary: null,
  about:
    "The resume details a professional individual extensively experienced in various fields including accounting, financial management, information systems, and human resources, with a strong academic background from Bellevue University. With leadership roles in different jobs and practical skills in both technical and customer service arenas, the candidate exhibits a well-rounded profile suitable for diverse professional environments.",
  atsCandidateId: "63960",
  isVip: false,
  isActivated: true,
  industry: null,
  profileStatus: "pending",
  profileSource: "colleagues",
  aboutMyWorkProfile: null,
  profileCoverLetter: null,
  isBlocked: false,
  isPictureHuman: true,
};

const dummyImage = `https://esteemed-colleagues-app.s3.amazonaws.com/images/profiles/843d7151-fc45-4cd3-b08b-0f20665043c2-%C3%90%C2%9F%C3%90%C2%B0%C3%91%C2%80%C3%90%C2%BA%20%C3%90%C2%A5%C3%90%C2%B8%C3%91%C2%89%C3%90%C2%BD%C3%90%C2%B8%C3%90%C2%BA%C3%90%C2%BE%C3%90%C2%B2%205.jpg`;

const CreateGroupModal = () => {
  const { userInfo } = useAppSelector(getAuthState);
  const {
    handleOpenCreateGroupModal,
    openCreateGroupModal,
    createOrUpdateGroup,
    createGroupLoading,
  } = useMessages();
  const [selectedStep, setSelectedStep] = useState("CHOOSE_PROJECT");
  const [chatName, setChatName] = useState<string>("");
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [preProjects, setPreProjects] = useState<ProjectListProps[]>([]);

  const onNextBackClick = (value: string) => {
    setSelectedStep(value);
  };

  const onClose = () => {
    handleOpenCreateGroupModal(false);
  };

  const onCreate = () => {
    const user = {
      id: userInfo?.uid,
      firstName: userInfo?.first_name ?? "",
      lastName: userInfo?.last_name ?? "",
      email: userInfo?.email ?? "",
      picture: userInfo?.linkedin_profile_url ?? dummyImage,
    } as GroupUsers;

    const user2 = {
      id: "14895",
      email: "shailendra.b.chodavadiya@gmail.com",
      lastName: "Shailendra",
      firstName: "patel",
      picture:
        "https://esteemed-colleagues-app.s3.amazonaws.com/images/profiles/6fc1840d-8abf-4a03-9865-055ce97aba8f-blob",
    } as GroupUsers;

    // const chatNameCreate = `${chatName}?projectId=${selectedProjectId}`;
    const createGroupObj = {
      chatName,
      users: [user, user2],
      isGroupChat: true,
      atsProjectId: selectedProjectId,
    } as Partial<CreateGroupRequest>;
    createOrUpdateGroup(createGroupObj, CreateUpdateEnum.Create);
    onClose();
  };
  return (
    <>
      <AtsModal width={633} open={openCreateGroupModal} onClose={onClose}>
        <Box height={"100%"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              text={"Create a group"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsCustomButton px={0} py={0} onClick={onClose}>
              <Close />
            </AtsCustomButton>
          </Stack>
          {selectedStep === "GROUP_NAME" ? (
            <Stack pt={"40px"} pb={"80px"} px={"30px"} gap={"10px"}>
              <Stack gap={"6px"}>
                <AtsTitleText
                  text={"Group Name"}
                  fs={14}
                  fw={600}
                  textColor="#000"
                />
                <AtsRow
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "9px",
                    height: 42,
                    px: "12px",
                  }}
                >
                  <>
                    <input
                      value={chatName}
                      type="text"
                      placeholder="eg: group name abc"
                      className="customInput"
                      style={{
                        flex: 1,
                      }}
                      onChange={(e) => setChatName(e.target.value)}
                    />
                    <AtsTitleText
                      text={"80"}
                      fs={16}
                      fw={400}
                      textColor="#000"
                    />
                  </>
                </AtsRow>
              </Stack>
              <AtsTitleText
                text={
                  "Groups are where conversations happen around a topic. Use a name that is easy to find and understand."
                }
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
            </Stack>
          ) : (
            <Stack pt={"40px"} pb={"80px"} px={"30px"} gap={"10px"}>
              <Stack gap={"6px"}>
                <AtsTitleText
                  text={"Choose Project"}
                  fs={14}
                  fw={600}
                  textColor="#000"
                />
                <ProjectSelector
                  selected={selectedProjectId}
                  onSelect={(selected) => setSelectedProjectId(selected)}
                  setPreProjects={setPreProjects}
                  preProject={preProjects}
                />
              </Stack>
              <AtsTitleText
                text={"All groups can be created only within projects."}
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
            </Stack>
          )}
          <Stack direction="row" gap={1}>
            {steps.map((step, index) => (
              <Box
                key={index}
                sx={{
                  height: "5px",
                  width: "100%",
                  bgcolor: "#dadada",
                  borderRadius: 0,
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: `100%`,
                    bgcolor:
                      selectedStep === "GROUP_NAME" && index === 0
                        ? "#3c854d"
                        : step === selectedStep && "#3c854d",
                  }}
                />
              </Box>
            ))}
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", py: 2, px: 6 }}
          >
            <Stack
              sx={{
                bgcolor: "transparent",
                borderRadius: "20px",
                pt: "5px",
                pb: "1px",
                px: "17px",
                cursor:
                  selectedStep === "CHOOSE_PROJECT" ? "not-allowed" : "pointer",
                ":hover": { bgcolor: BG_COLORS.GRAY },
              }}
              onClick={
                selectedStep === "CHOOSE_PROJECT"
                  ? () => {}
                  : () => onNextBackClick("CHOOSE_PROJECT")
              }
            >
              <AtsTitleText text="Back" fs={16} fw={600} textColor="#1e1e1e" />
            </Stack>
            {selectedStep === "GROUP_NAME" ? (
              <Box>
                <AtsRoundedButton
                  buttonHeight={30}
                  disabled={chatName.length === 0}
                  text="Create"
                  onClick={onCreate}
                  loading={createGroupLoading}
                />
              </Box>
            ) : (
              <AtsRoundedButton
                buttonWidth={68}
                buttonHeight={30}
                disabled={selectedProjectId.length === 0}
                text="Next"
                onClick={() => onNextBackClick("GROUP_NAME")}
                // loading={isLoading}
              />
            )}
          </Stack>
        </Box>
      </AtsModal>
    </>
  );
};

export default CreateGroupModal;
