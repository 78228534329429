import { Box, Collapse, Grid, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsText from "../../../../components/AtsText";
import { JobsService } from "../../../../Api/jobsListingServices";
import { CountProps } from "../../allJobs/jobDetails/JobCounts";
import { BG_COLORS, BORDERS, COLORS } from "../../../../theme";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useParams } from "react-router-dom";
import { StatisticsCountEnum } from "../../../../enums/jobsEnums";

export const initialCountDetails = [
  {
    title: "New Submission",
    value: 0,
    color: "#b2edff",
  },
  {
    title: "Interviewing",
    value: 0,
    color: "#ddb2ff",
  },
  {
    title: "Screened",
    value: 0,
    color: "#febf90",
  },
  {
    title: "Shortlisted",
    value: 0,
    color: "#b0f6d0",
  },
  {
    title: "Offers Extended",
    value: 0,
    color: "#f8ea6c",
  },
  {
    title: "Offer Accepted",
    value: 0,
    color: "#b2a6fa",
  },
  {
    title: "Hired",
    value: 0,
    color: "#81ea8c",
  },
];

const round = ["All", "Round 1", "Round 2", "Round 3"];

const CountCard = () => {
  const { nid } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<string>("New Submission");
  const [selectedRound, setSelectedRound] = useState<string>("All");
  const [count, setCount] = useState<CountProps[]>([]);

  const fetchStatisticsDetails = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobStatisticsById({
        nid,
      });
      if (response.results.length) {
        const count = response.results[0];
        const initialCountDetails = [
          {
            title: StatisticsCountEnum.NewSubmission,
            value: count.submissions_count,
            color: "#b2edff",
          },
          {
            title: StatisticsCountEnum.Interviewing,
            value: count.interview_count,
            color: "#ddb2ff",
          },
          {
            title: StatisticsCountEnum.Screened,
            value: count.screened_count,
            color: "#febf90",
          },
          {
            title: StatisticsCountEnum.Shortlisted,
            value: count.shortlist_count,
            color: "#b0f6d0",
          },
          {
            title: StatisticsCountEnum.OffersExtended,
            value: count.offer_extented_count,
            color: "#f8ea6c",
          },
          {
            title: StatisticsCountEnum.OfferAccepted,
            value: count.offer_accepted_count,
            color: "#b2a6fa",
          },
          {
            title: StatisticsCountEnum.Hired,
            value: count?.hired_count ?? 0,
            color: "#81ea8c",
          },
        ];
        setCount(initialCountDetails);
      } else {
        setCount(initialCountDetails);
      }
    } catch (error) {
      console.error(error);
      setCount(initialCountDetails);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    nid && fetchStatisticsDetails();
  }, [nid]);
  return (
    <>
      <Stack
        sx={{
          overflowX: "auto",
          pt: 1,
          pb: 2,
          width: "100%",
        }}
      >
        <Stack direction={"row"} gap={1}>
          {loading
            ? initialCountDetails.map((count, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: "0 0 auto",
                    border: BORDERS.GRAY,
                    borderRadius: 2,
                    py: "14px",
                    px: "16px",
                    minWidth: 103,

                    boxShadow: `rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;`,
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        bgcolor: count.color,
                        borderRadius: "30px",
                      }}
                    ></Box>
                    <Skeleton
                      variant="text"
                      width={"100%"}
                      sx={{ fontSize: "1rem" }}
                    />
                  </Stack>
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                      color: "#4f4f4f",
                      mt: 1,
                    }}
                    text={count.title}
                  />
                </Box>
              ))
            : count.map((count, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: "0 0 auto",
                    border:
                      selectedCard === count.title
                        ? BORDERS.PRIMARY
                        : BORDERS.GRAY,
                    borderRadius: 2,
                    py: "14px",
                    px: "16px",
                    minWidth: 103,
                    cursor: "pointer",
                    boxShadow:
                      selectedCard === count.title
                        ? "none"
                        : `rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;`,
                  }}
                  onClick={() => setSelectedCard(count.title)}
                >
                  <Stack gap={1}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <Box
                        sx={{
                          width: "15px",
                          height: "15px",
                          bgcolor: count.color,
                          borderRadius: "30px",
                        }}
                      ></Box>
                      <AtsTitleText
                        fs={18}
                        fw={500}
                        textColor={
                          selectedCard === count.title
                            ? COLORS.PRIMARY
                            : COLORS.BLACK
                        }
                        text={count.value}
                      />
                    </Stack>
                    <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={
                        selectedCard === count.title
                          ? COLORS.PRIMARY
                          : COLORS.BLACK
                      }
                      text={count.title}
                    />
                  </Stack>
                </Box>
              ))}
        </Stack>
        <Collapse in={selectedCard === "Interviewing"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={2}
            px={2}
            mt={2}
            mb={1}
            py={"10px"}
            sx={{
              bgcolor: BG_COLORS.GRAY,
              border: BORDERS.GRAY,
              borderRadius: 1,
              boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <AtsTitleText
              fs={12}
              fw={500}
              textColor={COLORS.BLACK}
              text={"Select Sub - Level:"}
            />
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {round.map((item, index) => (
                <Stack
                  key={index}
                  sx={{
                    border:
                      selectedRound === item ? BORDERS.DARK_GRAY : BORDERS.GRAY,
                    borderRadius: "20px",
                    px: 1,
                    py: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedRound(item)}
                >
                  <AtsTitleText
                    fs={12}
                    fw={400}
                    textColor={COLORS.BLACK}
                    text={item}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Collapse>
      </Stack>
    </>
  );
};

export default CountCard;
