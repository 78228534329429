import { Box, Skeleton, Stack } from "@mui/material";
import { CloseCircle, SearchNormal1 } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { UserManagementServices } from "../../../Api/userManagementServices";
import AtsBackLink from "../../../components/AtsBackLink";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { useMyProfile } from "../../../providers/MyProfileProvider";
import { BORDERS, COLORS } from "../../../theme";
import { GetInterestResponse } from "../../../types/userManagementTypes";

interface IProps {
  interestes: GetInterestResponse[];
  setInterestes: React.Dispatch<React.SetStateAction<GetInterestResponse[]>>;
}

const InterestList = ({ interestes, setInterestes }: IProps) => {
  const { updateProfileState, handleProfileChange } = useMyProfile();
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleChange = (value: { title: string; id: string }) => {
    const selectedInto = [...updateProfileState.you_are_into];
    if (selectedInto.map((val) => val.id).includes(value.id)) {
      const filterInterestes = selectedInto.filter(
        (preVal) => preVal.id !== value.id
      );
      handleProfileChange("you_are_into", filterInterestes);
    } else {
      handleProfileChange("you_are_into", [...selectedInto, value]);
    }
  };

  const filterValue = interestes?.filter((val) => {
    if (search === "") {
      return val;
    } else if (val.title.toLowerCase().includes(search.toLowerCase())) {
      return val;
    }
    return false;
  });

  const getInterestData = async () => {
    setLoading(true);
    try {
      const response = await UserManagementServices.getInterests();
      setInterestes(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInterestData();
    setShowAll(false);
    setSearch("");
  }, []);
  return (
    <>
      <Stack gap={"30px"}>
        {showAll ? (
          <>
            <AtsRow>
              <AtsBackLink text="Back" onClick={() => setShowAll(false)} />
            </AtsRow>
            <Stack
              direction={"row"}
              alignItems={"center"}
              minHeight={35}
              sx={{
                px: "16px",
                border: BORDERS.GRAY,
                borderRadius: "38px",
              }}
            >
              <SearchNormal1 size={16} />
              <input
                type="text"
                className="customInput"
                placeholder={"Search for Interests"}
                style={{
                  width: "100%",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <CloseCircle variant="Bold" size={16} />
            </Stack>
            <Stack sx={{ maxHeight: 272, overflow: "auto" }}>
              {filterValue.map((value, index) => (
                <AtsRow
                  gap={"20px"}
                  key={index}
                  sx={{
                    py: "20px",
                    borderBottom: BORDERS.GRAY,
                  }}
                >
                  <>
                    <Box>
                      <AtsCheckbox
                        checked={updateProfileState.you_are_into
                          .map((val) => val.id)
                          .includes(value.id)}
                        onChange={(e) => handleChange(value)}
                      />
                    </Box>
                    <AtsRow gap={"10px"}>
                      <>
                        {value.icon && (
                          <img src={value.icon} height={20} width={20} alt="" />
                        )}
                        <AtsTitleText
                          text={value.title}
                          fs={18}
                          fw={400}
                          textColor={COLORS.DARK_GRAY}
                        />
                      </>
                    </AtsRow>
                  </>
                </AtsRow>
              ))}
            </Stack>
          </>
        ) : (
          <>
            <AtsRow gap={"11px"} flexWrap={"wrap"}>
              <>
                {loading
                  ? Array.from(new Array(6)).map((_, index) => (
                      <AtsRow
                        key={index}
                        gap={"8px"}
                        sx={{
                          borderRadius: "50px",
                          border: BORDERS.GRAY,
                          px: "16px",
                          py: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <>
                          <Skeleton variant="circular" width={20} height={20} />
                          <Skeleton variant="text" width={80} height={20} />
                        </>
                      </AtsRow>
                    ))
                  : interestes.slice(0, 10).map((item, index) => (
                      <AtsRow
                        key={index}
                        gap={"8px"}
                        sx={{
                          borderRadius: "50px",
                          border: updateProfileState.you_are_into
                            .map((val) => val.id)
                            .includes(item.id)
                            ? BORDERS.PRIMARY
                            : BORDERS.GRAY,
                          px: "16px",
                          py: "10px",
                          cursor: "pointer",
                          maxHeight: "14px",
                        }}
                        onClick={() => handleChange(item)}
                      >
                        <>
                          {" "}
                          {item.icon && (
                            <img
                              src={item.icon}
                              height={20}
                              width={20}
                              alt=""
                            />
                          )}
                          <AtsTitleText
                            text={item.title}
                            fs={12}
                            fw={400}
                            textColor={
                              updateProfileState.you_are_into
                                .map((val) => val.id)
                                .includes(item.id)
                                ? COLORS.PRIMARY
                                : "#000"
                            }
                          />
                        </>
                      </AtsRow>
                    ))}
              </>
            </AtsRow>
            <AtsRow>
              <AtsTitleText
                text={"Show all"}
                textColor={COLORS.PRIMARY}
                isLink
                isUnderline
                isCursor
                fs={14}
                fw={500}
                onClick={() => setShowAll(true)}
              />
            </AtsRow>
          </>
        )}
      </Stack>
    </>
  );
};

export default InterestList;
