import { Box, SxProps } from "@mui/material";
import React from "react";
import { BORDERS } from "../theme";

interface ICardProps {
  children: JSX.Element;
  sx?: SxProps;
  isActive?: boolean;
  isOnlyBorder?: boolean;
}

const AtsCard = ({ children, isActive, isOnlyBorder, sx }: ICardProps) => {
  return (
    <Box
      sx={{
        border: isActive || isOnlyBorder ? BORDERS.PRIMARY : BORDERS.GRAY,
        borderRadius: "10px",
        boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        padding: "13px 17px",
        bgcolor: isActive ? "#ebf2ff" : "white",
        position: "relative",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default AtsCard;
