import { Box, Skeleton, Stack } from "@mui/material";
import DollarIcon from "../../../../assets/icons/dollar-circle.svg";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import { JobDetailsResponse } from "../../../../types/jobsTypes";
import { formattedAmount } from "../../../../utils/common";

interface JobSalaryProps {
  jobDetails: JobDetailsResponse;
  loading?: boolean;
}

const JobSalary = ({ jobDetails, loading }: JobSalaryProps) => {
  const getSalaryPerJobType = () => {
    if (jobDetails?.job_type === "Direct hire") {
      return `$ ${formattedAmount(
        jobDetails?.salary_low ?? 0
      )} - $ ${formattedAmount(jobDetails?.salary_high ?? 0)} /yr`;
    } else {
      return `$ ${formattedAmount(
        jobDetails?.pay_rate_low ?? 0
      )} - $ ${formattedAmount(jobDetails?.pay_rate_high ?? 0)} /hrs`;
    }
  };
  return (
    <Stack gap={2}>
      <AtsTitleText text="Salary" fs={16} fw={600} textColor={COLORS.BLACK} />
      <Stack direction={"row"} gap={3} alignItems={"center"}>
        <Box>
          <img src={DollarIcon} alt="Nvidia Logo" />
        </Box>
        <Stack gap={1}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            {loading ? (
              <Skeleton variant="rectangular" width={150} height={20} />
            ) : (
              <AtsTitleText text={getSalaryPerJobType()} fs={12} fw={400} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default JobSalary;
