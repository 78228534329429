import { Box, Container, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { ProjectService } from "../../Api/projectServices";
import AtsCustomButton from "../../components/AtsCustomButton";
import AtsIcon from "../../components/AtsIcon";
import AtsRoundedButton from "../../components/AtsRoundedButton";
import AtsRow from "../../components/AtsRow";
import AtsRowBetween from "../../components/AtsRowBetween";
import AtsTitleText from "../../components/AtsTitleText";
import { useProjectFilter } from "../../providers/ProjectFilterProvider";
import { ATS_ROUTES } from "../../router/routes";
import { COLORS } from "../../theme";
import { ProjectListParams, ProjectListProps } from "../../types/projectTypes";
import { getPercentageFromStartToEnd } from "../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../utils/stringUtils";
import ProjectCard from "./modules/ProjectModules/ProjectCard";
import ProjectFilterSection from "./modules/ProjectModules/ProjectFilterSection";
import ProjectTopContainer from "./modules/ProjectModules/ProjectTopContainer";
import LoadingProjectCard from "./modules/ProjectModules/LoadingProjectCard";
import { ProjectListStatusEnum } from "../../enums/projectEnums";
import { useDebounce } from "../../hooks/useDebounce";
import AtsEmptyState from "../../components/AtsEmptyState";

const Projects = () => {
  const navigate = useNavigate();
  const { onClear, projectFilter } = useProjectFilter();
  const [projects, setProjects] = useState<ProjectListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [status, setStatus] = useState<ProjectListStatusEnum>(
    ProjectListStatusEnum.IN_PROGRESS
  );

  const debouceSearch = useDebounce(projectFilter.title);
  // const [status, setStatus] = useState<ProjectListStatusEnum>(ProjectListStatusEnum.IN_PROGRESS);

  const fetchMoreData = () => {
    console.log("fetchMoreData", totalItem, projects.length);
    if (totalItem !== projects.length) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const apiCall = async () => {
    const projectState = {
      status: status,
      page,
      ...(projectFilter.end_date.length && {
        end_date: projectFilter.end_date,
      }),
      ...(projectFilter.start_date.length && {
        start_date: projectFilter.start_date,
      }),
      ...(projectFilter.budget_high > 10 && {
        budget_high: projectFilter.budget_high,
      }),
      ...(projectFilter.budget_low > 1 && {
        budget_low: projectFilter.budget_low,
      }),
      ...(projectFilter.country_code.length && {
        country_code: projectFilter.country_code.join(","),
      }),
      ...(isNotNullOrUndefined(projectFilter?.title) &&
        projectFilter?.title.length && {
          title: projectFilter?.title,
        }),
    } as ProjectListParams;
    const response = await ProjectService.getProjectList(projectState);
    if (response.results.length) {
      if (typeof response.pager.count === "number") {
        setTotalItem(response.pager.count);
      } else {
        setTotalItem(Number(response.pager.count));
      }
      if (page === 0) {
        setProjects(response.results);
      } else {
        setProjects([...projects, ...response.results]);
      }
    } else {
      setProjects([]);
      setTotalItem(0);
    }
  };
  const getProjectsMore = async () => {
    console.log("getProject");
    setLoading(true);
    try {
      await apiCall();
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getProjects = async () => {
    console.log("getProject");
    setIsLoading(true);
    try {
      await apiCall();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const debouncedGetProjects = debounce(getProjectsMore, 500);

  useEffect(() => {
    page === 0 && getProjects();
  }, [status, projectFilter, debouceSearch]);

  useEffect(() => {
    page !== 0 && getProjectsMore();
  }, [projectFilter, page, debouceSearch]);
  return (
    <div
      className="main-container"
      style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          mb: 4,
          pr: "14px !important",
        }}
      >
        <Stack sx={{ mb: 1.5, pr: "10px" }}>
          <ProjectTopContainer
            setPage={setPage}
            setStatus={setStatus}
            status={status}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} sx={{ pr: "10px" }}>
          <AtsRowBetween
            direction={"row"}
            sx={{ minHeight: 35, minWidth: 305 }}
            alignItems={"center"}
            gap={0.3}
          >
            <>
              <AtsRow>
                <>
                  <AtsCustomButton px={0.5}>
                    <AtsIcon icon="icon-setting-4" height="20px" fw={600} />
                  </AtsCustomButton>
                  <AtsTitleText
                    text={"Filter"}
                    fs={14}
                    fw={500}
                    textColor={COLORS.BLACK}
                  />
                </>
              </AtsRow>
              <Box
                sx={{
                  mr: "30px",
                }}
              >
                <AtsRoundedButton
                  text="Clear"
                  buttonHeight={25}
                  buttonWidth={72}
                  fs={12}
                  buttonVariant="outline"
                  onClick={onClear}
                />
              </Box>
            </>
          </AtsRowBetween>
          <Stack
            direction={"row"}
            sx={{ minHeight: 35, flexGrow: 1 }}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={0.3}
          >
            <AtsTitleText
              text={`${totalItem} Project(s)`}
              fs={14}
              fw={500}
              textColor={COLORS.BLACK}
            />
            <Stack
              direction={"row"}
              sx={{ minHeight: 35 }}
              alignItems={"center"}
              gap={1}
            >
              <AtsTitleText
                text={"Sort by:"}
                fs={14}
                fw={500}
                textColor={COLORS.BLACK}
              />
              <AtsTitleText
                text={"Last used by me"}
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
              />
              <AtsCustomButton px={0.5}>
                <AtsIcon icon="icon-arrow-down-1" height="20px" fw={600} />
              </AtsCustomButton>
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 156px)",
            mt: "10px",
          }}
        >
          <Stack direction={"row"} gap={"22px"} sx={{ height: "100%" }}>
            <Stack
              sx={{
                height: "98%",
                width: 272,
                bgcolor: "white",
                borderRadius: "10px",
                mt: "10px",
              }}
              gap={"30px"}
            >
              <ProjectFilterSection setPage={setPage} />
            </Stack>

            <Stack
              className="scrollHide"
              id="scrollableDiv"
              sx={{
                height: "98%",
                overflow: "auto",
                flex: 1,
                mt: "10px",
              }}
            >
              {isLoading ? (
                Array.from(new Array(2)).map((_, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.02, translateY: -5 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    style={{
                      height: "100%",
                      paddingBottom: 2 - 1 !== index ? "30px" : "0px",
                    }}
                  >
                    <LoadingProjectCard loading={isLoading} />
                  </motion.div>
                ))
              ) : projects.length ? (
                <InfiniteScroll
                  dataLength={projects.length}
                  next={fetchMoreData}
                  hasMore={projects.length < totalItem}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  {projects.map((project, index) => (
                    <motion.div
                      key={project.nid}
                      whileHover={{ scale: 1.02, translateY: -5 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      style={{
                        height: "100%",
                        paddingBottom:
                          projects.length - 1 !== index ? "30px" : "0px",
                      }}
                    >
                      <ProjectCard
                        isEdit
                        getProjects={getProjects}
                        projectData={project}
                        projectId={project.nid}
                        progress={
                          project?.start_date && project?.end_date
                            ? getPercentageFromStartToEnd(
                                project?.start_date,
                                project?.end_date
                              )
                            : 0
                        }
                        image={project.project_image}
                        projectName={project.title}
                        location={project.location?.country}
                        pNumber={project?.nid}
                        description={project?.description}
                        teamMember={project?.team_members}
                        // loading={loading}
                        onClick={() =>
                          navigate(
                            ATS_ROUTES.PROJECT_DETAILS.replace(
                              ":id",
                              project.nid
                            )
                          )
                        }
                      />
                    </motion.div>
                  ))}
                </InfiniteScroll>
              ) : (
                <AtsEmptyState
                  sx={{
                    height: "100%",
                  }}
                  imgWidth={74}
                  imgHeight={68}
                  text="No projects created recently."
                  subText={`Start creating a new project!`}
                />
              )}
            </Stack>
          </Stack>
        </Box>
      </Container>
    </div>
  );
};

export default Projects;
