import { Box, Stack } from "@mui/material";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { BORDERS } from "../../../theme";
import MyAccountInfo from "../components/MyAccountInfo";
import MyPersonalInfo from "../components/MyPersonalInfo";

const AccountTab = () => {
  const { updateUserLoading, updateUserDetails } = useCreateAccount();
  return (
    <>
      <Box
        sx={{
          pb: "49px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <MyAccountInfo />
      </Box>
      <Box
        sx={{
          py: "49px",
        }}
      >
        <MyPersonalInfo />
      </Box>
      <Stack direction={"row"} justifyContent={"end"} pt={"20px"} px={"10px"}>
        <AtsRoundedButton
          text="Update"
          buttonWidth={120}
          buttonHeight={34}
          disabled={updateUserLoading}
          loading={updateUserLoading}
          onClick={async () => {
            await updateUserDetails();
          }}
        />
      </Stack>
    </>
  );
};

export default AccountTab;
