import { Box } from "@mui/material";

const RecentlyPostedJobsEmptyState = () => {
  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "#f5f5f5" }}
    >
      <img
        src={require("../../../assets/emptyStateImages/emptyJobCard.png")}
        alt=""
      />
      {/* <Box
        sx={{
          position: "relative",
          //   width: "300px", // Match the image width
          height: "auto",
          overflow: "hidden",
        }}
      >
        <img
          src={require("../../../assets/emptyStateImages/emptyJobCard.png")}
          alt=""
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%", // Half width
            height: "100%",
            background: `linear-gradient(to right, rgba(245, 245, 245, 0) 0%, #f6f6f6 100%)`,
            pointerEvents: "none",
          }}
        />
      </Box> */}
    </Box>
  );
};

export default RecentlyPostedJobsEmptyState;
