import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getAvailability } from "../pages/Jobs/Submissions/pipeline/Candidate/CandidateColumn";
import { getSubmissionState } from "../redux/slices/submissionSlice";
import { useAppSelector } from "../redux/store";
import { formateOneDigitNum, isNotNullOrUndefined } from "../utils/stringUtils";
import { getDateFormatMMMDYYYYY } from "../utils/dateUtils";
import { JobTypeEnum } from "../enums/jobsEnums";
import { formattedAmount } from "../utils/common";
import { MakeAnOfferServices } from "../Api/makeAnOfferServices";
import { useLocation, useParams } from "react-router-dom";
import {
  GetOfferDetailsResponse,
  GetOffersResponse,
  UpdateOfferRequest,
} from "../types/makeAnOfferTypes";
import { toast } from "react-toastify";
import { CreateInvoiceRequest } from "../types/invoiceTypes";
import { InvoiceServices } from "../Api/invoiceServices";
import { useCreateAccount } from "./CreateAccountProvider";

export enum OfferTypeEnum {
  JobOffers = "Job Offers",
  allOffers = "All Offers",
}

interface FormattedCandidateInfo {
  hourPerWeek: string;
  job_type: string;
  name: string;
  address: string;
  designation: string;
  image: string;
  rating: string;
  duration: string;
  rate: string;
  annualrate: string;
  directHireFeee: string;
  email: string;
  employmentType: string;
}
export const initialCandidateInfo = {
  name: "",
  address: "",
  designation: "",
  image: "",
  rating: "",
  hourPerWeek: "",
  job_type: "",
  duration: "",
  rate: "",
  annualrate: "",
  directHireFeee: "",
  email: "",
  employmentType: "",
};
type MakeAnOfferContextType = {
  selectedOfferTab: number;
  isCompleteRequest: boolean;
  loading: boolean;
  isOfferDetails: boolean;
  offerStatusLoading: boolean;
  offerDetails: GetOfferDetailsResponse | null;
  offerStatusList: {
    [key: string]: string;
  } | null;
  offerType: OfferTypeEnum;
  offers: GetOffersResponse[];
  selectedOffer: GetOffersResponse;
  setSelectedOffer: React.Dispatch<React.SetStateAction<GetOffersResponse>>;
  setSelectedOfferTab: React.Dispatch<React.SetStateAction<number>>;
  setIsCompleteRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOfferDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setOfferType: React.Dispatch<React.SetStateAction<OfferTypeEnum>>;
  formattedCandidateDetails: FormattedCandidateInfo;
  getAllOffersOfCandidate: () => void;
  getOfferStatus: () => void;
  getOfferDetails: () => void;
  updateCandidateOffer: (status: string, bill_rate: number) => void;
};

const MakeAnOfferModule = createContext<MakeAnOfferContextType | null>(null);

export const useMakeAnOffer = () =>
  useContext(MakeAnOfferModule) as MakeAnOfferContextType;

export const MakeAnOfferProvider = ({ children }: any) => {
  const { nid } = useParams();
  const { setSelectedProjectId } = useCreateAccount();
  const [selectedOfferTab, setSelectedOfferTab] = useState<number>(0);
  const [isCompleteRequest, setIsCompleteRequest] = useState<boolean>(false);
  const [isOfferDetails, setIsOfferDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [offerStatusLoading, setOfferDetailsLoading] = useState<boolean>(false);
  const [offers, setOffers] = useState<GetOffersResponse[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<GetOffersResponse | null>(
    null
  );
  const [offerDetails, setOfferDetails] =
    useState<GetOfferDetailsResponse | null>(null);
  const [offerStatusList, setOfferStatusList] = useState<{
    [key: string]: string;
  } | null>(null);
  const [offerType, setOfferType] = useState<OfferTypeEnum>(
    OfferTypeEnum.JobOffers
  );

  const [formattedCandidateDetails, setFormattedCandidateDetails] =
    useState<FormattedCandidateInfo>(initialCandidateInfo);
  const { jobDetailsForSubmission, submissionDetails, candidateDetails } =
    useAppSelector(getSubmissionState);

  const getCandidateDetails = () => {
    const job_type = isNotNullOrUndefined(jobDetailsForSubmission?.job_type)
      ? jobDetailsForSubmission?.job_type
      : "--";
    const hourPerWeek = isNotNullOrUndefined(
      jobDetailsForSubmission?.hours_per_week
    )
      ? jobDetailsForSubmission?.hours_per_week
      : "--";
    const startDate = isNotNullOrUndefined(
      jobDetailsForSubmission?.job_start_date
    )
      ? getDateFormatMMMDYYYYY(jobDetailsForSubmission?.job_start_date)
      : "--";
    const endDate = isNotNullOrUndefined(jobDetailsForSubmission?.job_end_date)
      ? getDateFormatMMMDYYYYY(jobDetailsForSubmission?.job_end_date)
      : "--";
    const duration =
      job_type === JobTypeEnum.DIRECT_HIRE
        ? startDate
        : `${startDate} to ${endDate}`;
    const rate = `$ ${
      isNotNullOrUndefined(submissionDetails?.bill_rate)
        ? submissionDetails?.bill_rate
        : 0
    } `;
    const billRate = `$ ${
      isNotNullOrUndefined(submissionDetails?.bill_rate)
        ? formattedAmount(Number(submissionDetails?.bill_rate) * 2080)
        : 0
    } `;
    const percentage = isNotNullOrUndefined(submissionDetails?.perm_fee_perc)
      ? Number(submissionDetails?.perm_fee_perc)
      : 0;
    const billRateFee = `$ ${
      isNotNullOrUndefined(submissionDetails?.bill_rate)
        ? formattedAmount(
            (Number(submissionDetails?.bill_rate) * 2080 * percentage) / 100
          )
        : 0
    } `;
    const billSalaryFee = `$ ${
      isNotNullOrUndefined(submissionDetails?.bill_salary)
        ? formattedAmount(
            (Number(submissionDetails?.bill_salary) * percentage) / 100
          )
        : 0
    } `;
    const billSalary = `$ ${
      isNotNullOrUndefined(submissionDetails?.bill_salary)
        ? formattedAmount(Number(submissionDetails?.bill_salary))
        : 0
    } `;
    const annualrate =
      job_type === JobTypeEnum.DIRECT_HIRE ? billSalary : billRate;
    const directHireFeee =
      job_type === JobTypeEnum.DIRECT_HIRE ? billSalaryFee : billRateFee;

    const employmentType = getAvailability(
      candidateDetails?.availability &&
        candidateDetails?.availability[0]?.availability
    );

    const firstName = isNotNullOrUndefined(candidateDetails?.first_name)
      ? candidateDetails?.first_name
      : "";
    const lastName = isNotNullOrUndefined(candidateDetails?.last_name)
      ? candidateDetails?.last_name
      : "";
    const designation = isNotNullOrUndefined(candidateDetails?.job_title)
      ? candidateDetails?.job_title
      : "";
    const email = isNotNullOrUndefined(candidateDetails?.email_address)
      ? candidateDetails?.email_address
      : "";
    const address = `${candidateDetails?.address_city ?? ""}${
      candidateDetails?.address_city ? "," : ""
    } ${candidateDetails?.address_state_code ?? ""}${
      candidateDetails?.address_state_code ? "," : ""
    } ${candidateDetails?.address_country_code ?? ""}`;

    const rating = isNotNullOrUndefined(submissionDetails?.rating)
      ? formateOneDigitNum(submissionDetails?.rating)
      : "";

    const image = isNotNullOrUndefined(candidateDetails?.image)
      ? candidateDetails?.image
      : null;
    const name = `${firstName} ${lastName}`;

    setFormattedCandidateDetails({
      name,
      address,
      designation,
      image,
      rating,
      hourPerWeek,
      job_type,
      duration,
      rate,
      annualrate,
      directHireFeee,
      employmentType,
      email,
    });
  };

  const getAllOffersOfCandidate = async () => {
    setLoading(true);
    try {
      if (offerType === OfferTypeEnum.JobOffers) {
        const response = await MakeAnOfferServices.getCandidateOfferListByJobId(
          { job_id: nid, candidate_id: submissionDetails?.candidate_id }
        );
        setOffers(response.results);
        setLoading(false);
      } else {
        const response = await MakeAnOfferServices.getCandidateOfferList({
          candidate_id: submissionDetails?.candidate_id,
        });
        setOffers(response.results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getOfferStatus = async () => {
    try {
      const response = await MakeAnOfferServices.getOfferStatus();
      setOfferStatusList(response.status_options);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const updateCandidateOffer = async (status: string, bill_rate: number) => {
    setOfferDetailsLoading(true);
    const updateObj = {
      offer_id: selectedOffer.nid,
      status,
      bill_rate: offerDetails?.employment_type === "36" ? 0 : bill_rate,
      bill_salary: offerDetails?.employment_type !== "36" ? 0 : bill_rate,
    } as UpdateOfferRequest;
    try {
      const response = await MakeAnOfferServices.updateOffer(updateObj);
      if (
        status === "Offer Accepted" &&
        offerDetails?.employment_type === "36"
      ) {
        const body = {
          first_name: isNotNullOrUndefined(candidateDetails?.first_name)
            ? candidateDetails?.first_name
            : "",
          last_name: isNotNullOrUndefined(candidateDetails?.last_name)
            ? candidateDetails?.last_name
            : "",
          email: isNotNullOrUndefined(candidateDetails?.email_address)
            ? candidateDetails?.email_address
            : "",
          offer_rate: bill_rate.toString(),
          percentage: offerDetails?.perm_fee_perc,
          project_id: offerDetails?.project_id,
          type: offerDetails?.is_converted === "0" ? "direct" : "converted",
        } as CreateInvoiceRequest;
        await InvoiceServices.createInvoice(body);
      }
      toast.success(response.message);
      getOfferDetails();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getOfferDetails = async () => {
    setOfferDetailsLoading(true);
    try {
      const response = await MakeAnOfferServices.getOfferDetailsById({
        offer_id: selectedOffer.nid,
      });
      setOfferDetails(response);
      setSelectedProjectId(response.project_id);
    } catch (error) {
      console.error(error);
    } finally {
      setOfferDetailsLoading(false);
    }
  };

  useEffect(() => {
    setOfferType(OfferTypeEnum.JobOffers);
    if (nid) {
      getOfferStatus();
    }
  }, [nid]);

  useEffect(() => {
    if (candidateDetails && submissionDetails && jobDetailsForSubmission) {
      getCandidateDetails();
    }
  }, [candidateDetails, submissionDetails, jobDetailsForSubmission]);

  return (
    <MakeAnOfferModule.Provider
      value={{
        offers,
        offerType,
        selectedOfferTab,
        isCompleteRequest,
        isOfferDetails,
        loading,
        selectedOffer,
        offerStatusList,
        offerDetails,
        offerStatusLoading,
        getOfferStatus,
        setSelectedOfferTab,
        setIsCompleteRequest,
        setIsOfferDetails,
        setOfferType,
        setSelectedOffer,
        formattedCandidateDetails,
        getAllOffersOfCandidate,
        getOfferDetails,
        updateCandidateOffer,
      }}
    >
      {children}
    </MakeAnOfferModule.Provider>
  );
};
