import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { OnboardingServices } from "../../../../../../Api/onboardingServices";
import { SubmissionService } from "../../../../../../Api/submissionServices";
import { TimesheetServices } from "../../../../../../Api/timesheetServices";
import { FilterIcon } from "../../../../../../assets/icons/Icons";
import AtsCard from "../../../../../../components/AtsCard";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";
import AtsNoUserImage from "../../../../../../components/AtsNoUserImage";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../providers/TimesheetProvider";
import { getAuthState } from "../../../../../../redux/slices/authSlice";
import {
  setContractorEffortsDetails,
  setContractorOnboardingDetails,
  setContractorPlacmentDetails,
  setIsContractorDetailsDrawerOpen,
  setIsContractorOnboarded,
  setSelectedContractorDrawerTab,
} from "../../../../../../redux/slices/contractorSlice";
import {
  setCandidateDetails,
  setIsCandidateDetailsLoading,
} from "../../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BORDERS } from "../../../../../../theme";
import { IColumnProps } from "../../../../../../types/commonType";
import { OnboardingCandidatesListResponse } from "../../../../../../types/onboardingTypes";
import { isNotNullOrUndefined } from "../../../../../../utils/stringUtils";
import ContractorDetailsDrawer from "../ContractorDetails/ContractorDetailsDrawer";
import AtsUserNameTextAvatar from "../../../../../../components/AtsUserNameTextAvatar";

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Team Member",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "200px",
    minWidth: "200px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const Onboarding = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const { setSelectedContractorTimesheet } = useTimesheet();
  const [onboardingList, setOnboardingList] = useState<
    OnboardingCandidatesListResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getOnboardingList = async () => {
    setLoading(true);
    try {
      const response = await OnboardingServices.getOnboardingCandidatesList({
        project: id,
        status: "pending",
      });
      setOnboardingList(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOnboardingList();
  }, []);

  const getPlacement = async (email: string) => {
    try {
      const response = await OnboardingServices.getCandidatePlacementDetails({
        project_id: id,
        email,
      });
      if (response && response.length) {
        dispatch(setContractorPlacmentDetails(response[0]));
        return response;
      }
    } catch (error) {}
  };

  const getEfforts = async (email: string) => {
    try {
      const response = await TimesheetServices.getCandidateEfforts({
        company_id: userInfo?.company?.id,
        project_id: id,
        email,
      });
      if (response) {
        dispatch(setContractorEffortsDetails(response));
      }
    } catch (error) {}
  };
  const getContractOnboardingDetails = async (email: string) => {
    try {
      const response = await OnboardingServices.getOnboardingDetails({
        project_id: id,
        email,
      });
      if (response) {
        dispatch(setContractorOnboardingDetails(response));
        dispatch(setIsContractorOnboarded(true));
      }
    } catch (error) {
      dispatch(setContractorOnboardingDetails(null));
      dispatch(setIsContractorOnboarded(false));
    }
  };

  const openContractDetailsDrawer = (
    contractor: OnboardingCandidatesListResponse
  ) => {
    setSelectedContractorTimesheet(contractor);
    getCandidateDetials(contractor.candidate_id);
    dispatch(setIsContractorDetailsDrawerOpen(true));
    dispatch(setSelectedContractorDrawerTab(5));
  };

  const getCandidateDetials = async (id: string) => {
    dispatch(setIsCandidateDetailsLoading(true));
    try {
      const response = await SubmissionService.getCandidateDetailsById({ id });
      if (response.results.length) {
        dispatch(setCandidateDetails(response.results[0]));
        const placementResponse = await getPlacement(
          response.results[0].email_address
        );
        await getEfforts(response.results[0].email_address);
        if (placementResponse) {
          placementResponse[0]?.onboarding_status !== "Pending" &&
            getContractOnboardingDetails(response.results[0].email_address);
        }
      } else {
        dispatch(setCandidateDetails(null));
      }
    } catch (error) {
    } finally {
      dispatch(setIsCandidateDetailsLoading(false));
    }
  };

  const customizeRow = (
    column: IColumnProps,
    contractor: OnboardingCandidatesListResponse,
    index: number
  ) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  padding: 10,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                  <Stack>
                    {isNotNullOrUndefined(contractor.candidate_picture) &&
                    contractor.candidate_picture.length > 0 ? (
                      <img
                        src={contractor.candidate_picture}
                        alt=""
                        width={58}
                        height={58}
                        style={{
                          borderRadius: "50%",
                          marginRight: "12px",
                        }}
                      />
                    ) : (
                      <Stack
                        sx={{
                          mr: "12px",
                          width: 58,
                          height: 58,
                        }}
                      >
                        <AtsUserNameTextAvatar
                          name={contractor.candidate_name}
                        />
                      </Stack>
                    )}
                  </Stack>
                  <Stack gap={1}>
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      textColor="#000"
                      text={`${contractor.candidate_name} `}
                    />

                    {/* <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={selectedContractor?.title ?? ""}
                    /> */}
                    {/* <AtsTitleText
                      fs={12}
                      fw={500}
                      text={contractor?.team ?? "Java Team"}
                    /> */}
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={contractor.candidate_email}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            {contractor.onboarding_status === "pending" && (
              <AtsRoundedButton
                text="Start onboarding"
                isInitial
                fs={12}
                fw={400}
                buttonVariant="outline"
                onClick={() => openContractDetailsDrawer(contractor)}
              />
            )}
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = onboardingList.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            className="table-row"
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: "pointer",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) =>
              customizeRow(val, value, index)
            )}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px 5px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  const contractorDetailsDrawerMemo = useMemo(
    () => <ContractorDetailsDrawer />,
    []
  );
  return (
    <>
      {loading ? (
        <AtsDataTable
          // isTopBorder
          isNotStickyHeader
          data={[]}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={loading}
        />
      ) : onboardingList.length ? (
        <>
          <Stack mb={"20px"}>
            <AtsTitleText
              fs={18}
              fw={600}
              text={`${onboardingList?.length} Onboarding`}
            />
          </Stack>
          <Stack
            sx={{
              height: "calc(100% - 51px)",
            }}
          >
            <Stack sx={{ height: "100%" }}>
              <AtsCard sx={{ mb: "10px" }} isOnlyBorder>
                <Box
                  sx={{
                    px: "19px",
                    pb: "27px",

                    // py: 2,
                  }}
                >
                  <AtsDataTable
                    // isTopBorder
                    isNotStickyHeader
                    data={onboardingList}
                    theadComponent={headerComponent}
                    rowComponent={renderRowComponent}
                    loading={loading}
                  />
                </Box>
              </AtsCard>
            </Stack>
          </Stack>
          {contractorDetailsDrawerMemo}
        </>
      ) : (
        <AtsEmptyState
          sx={{
            height: "100%",
          }}
          imgHeight={68}
          imgWidth={74}
          text="No onboarding recently."
          subText="Keep an eye here for new onboarding!"
        />
      )}
    </>
  );
};

export default Onboarding;
