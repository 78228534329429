import { Skeleton, Stack } from "@mui/material";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import { DisputedStatusEnum } from "../../../../../../../enums/projectEnums";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";

interface IProps {
  email: string;
}

const StatusColumn = ({ email }: IProps) => {
  const { timelogLoading, candidateTimelog } = useTimesheet();

  return (
    <>
      <Stack direction={"row"} gap={1}>
        {timelogLoading ? (
          <Skeleton variant="text" width={50} height={25} />
        ) : candidateTimelog.length > 0 ? (
          candidateTimelog.map((log) => {
            if (log.mail === email) {
              return (
                <AtsRoundedButton
                  text={log.status}
                  buttonVariant={
                    (log.status as string) === DisputedStatusEnum.Resolved
                      ? "greenContained"
                      : (log.status as string) === DisputedStatusEnum.Disputed
                      ? "primaryContained"
                      : "dangerContained"
                  }
                  buttonHeight={27}
                  fs={12}
                  fw={600}
                />
              );
            } else {
              return "";
            }
          })
        ) : (
          ""
        )}
      </Stack>
    </>
  );
};

export default StatusColumn;
