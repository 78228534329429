import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import AtsPageContainer from "../../components/AtsPageContainer";
import { MyProfileTabsEnums } from "../../enums/myProfileEnums";
import { useMyProfile } from "../../providers/MyProfileProvider";
import { BORDERS } from "../../theme";
import MyProfileInfo from "./components/MyProfileInfo";
import AccountTab from "./modules/AccountTab";
import CreateProfileTab from "./modules/CreateProfileTab";
import MyCompanyInfoTab from "./modules/MyCompanyInfoTab";
import MyProfileTabs from "./modules/MyProfileTabs";

const MyProfile = () => {
  const { selectedMyProfileTab } = useMyProfile();
  const CompanyTabMemo = useMemo(() => <MyCompanyInfoTab />, []);
  const ProfileTabMemo = useMemo(() => <CreateProfileTab />, []);
  const AccountTabMemo = useMemo(() => <AccountTab />, []);
  return (
    <AtsPageContainer>
      <Box height={"100%"}>
        <MyProfileTabs />
        <Box
          sx={{
            height: "calc(100% - 100px)",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            gap={"20px"}
            height={"100%"}
          >
            <Box
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "10px",
                bgcolor: "white",
                pt: "40px",
                px: "49px",
                pb: "25px",
              }}
            >
              <MyProfileInfo />
            </Box>
            <Box
              sx={{
                height: "100%",
                bgcolor: "white",
                border: BORDERS.GRAY,
                borderRadius: "10px",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  height: "90%",
                  overflow: "auto",
                  mx: "30px",
                  my: "30px",
                }}
              >
                {selectedMyProfileTab === MyProfileTabsEnums.Company
                  ? CompanyTabMemo
                  : selectedMyProfileTab === MyProfileTabsEnums.Profile
                  ? ProfileTabMemo
                  : AccountTabMemo}
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </AtsPageContainer>
  );
};

export default MyProfile;
