import React from "react";
import ReactApexChart from "react-apexcharts";
import { useInsights } from "../../../../providers/InsightsProvider";
import moment from "moment";
import { Box } from "@mui/material";
import { BORDERS } from "../../../../theme";

const OverviewBarChart = () => {
  const { startDate, endDate } = useInsights();

  const getDatesInRange = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const formattedDates = dateArray.map((date) =>
      moment(date).format("MMM DD, YYYY")
    );
    return formattedDates;
  };

  const dateCategories = getDatesInRange(
    new Date(startDate),
    new Date(endDate)
  );
  const options: ApexCharts.ApexOptions = {
    chart: {
      fontFamily: "Inter",
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        columnWidth: "50%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        dataLabels: {
          hideOverflowingLabels: true,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: dateCategories,
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return `${value}h`;
        },
      },
    },
    colors: ["#BFDBFE", "#60A5FA", "#2563EB"],
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "Contractors",
      data: Array(dateCategories.length)
        .fill(0)
        .map(() => Math.floor(Math.random() * 10)), // Example data
    },
    {
      name: "Direct Hires",
      data: Array(dateCategories.length)
        .fill(0)
        .map(() => Math.floor(Math.random() * 10)), // Example data
    },
    {
      name: "Open Positions",
      data: Array(dateCategories.length)
        .fill(0)
        .map(() => Math.floor(Math.random() * 10)), // Example data
    },
  ];
  return (
    <Box
      sx={{
        pb: "10px",
        borderBottom: BORDERS.GRAY,
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default OverviewBarChart;
