/* eslint-disable jsx-a11y/iframe-has-title */
import { Box, Grid, Stack } from "@mui/material";
import { useMemo } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import SupportCard from "./components/SupportCard";
import ChatWithAccountManager from "./modules/ChatWithAccountManager";

const EmployerSupport = () => {
  const chatComponent = useMemo(() => <ChatWithAccountManager />, []);

  return (
    <Stack>
      <Stack gap={2} pb={2}>
        <AtsTitleText
          text="Employer Support"
          fs={24}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <AtsTitleText
          text="We are here to help"
          fs={16}
          fw={600}
          textColor={COLORS.DARK_GRAY}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          {chatComponent}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <SupportCard
              header="Contact employer support"
              subText="Esteemed Employers can access 1-1 help from a trained specialist in just a few clicks."
              onClick={() => {}}
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EmployerSupport;
