import { useInvoices } from "../../../../../providers/InvoicesProvider";
import InvoiceDetails from "./modules/InvoiceDetails";
import InvoicesList from "./modules/InvoicesList";

const Invoices = () => {
  const { isInvoiceView } = useInvoices();
  return (
    <>{isInvoiceView ? <InvoiceDetails bottom={-285} /> : <InvoicesList />}</>
  );
};

export default Invoices;
