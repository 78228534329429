import { Close, KeyboardArrowDown } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubmissionService } from "../../../../../../Api/submissionServices";
import {
  MakeAnOfferIcon,
  ScheduleIcon,
} from "../../../../../../assets/icons/Icons";
import ThreeDotIcon from "../../../../../../assets/icons/threeDot.svg";
import StarIconRating from "../../../../../../assets/images/startIcon.svg";
import TopCandidate from "../../../../../../assets/images/topCandidate.svg";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsText from "../../../../../../components/AtsText";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import AtsUserNameTextAvatar from "../../../../../../components/AtsUserNameTextAvatar";
import { RateAsEnum } from "../../../../../../enums/jobsEnums";
import {
  getCandidateState,
  setCondidateTabValue,
} from "../../../../../../redux/slices/candidateSlice";
import { setIsOpenInterviewDetailsDialog } from "../../../../../../redux/slices/interviewSlice";
import { setIsOpenMakeAnOfferDialog } from "../../../../../../redux/slices/makeAnOfferSlice";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
  setSubmissionDetails,
  setSubmissions,
} from "../../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../theme";
import {
  GetSubmissionResponse,
  Skill,
  UpdateRateOptionRequest,
} from "../../../../../../types/submissionTypes";
import { getDateFormatMMMMDYYYYY } from "../../../../../../utils/dateUtils";
import {
  formateOneDigitNum,
  isNotNullOrUndefined,
} from "../../../../../../utils/stringUtils";
import { renderStatisticsBgcolor } from "../CandidateList";

const matchData = [
  {
    key: RateAsEnum.GOOD_FIT,
  },
  {
    key: RateAsEnum.NOT_FIT,
  },
  {
    key: RateAsEnum.MAYBE,
  },
];

const CandidateDetailsSection = () => {
  const { nid } = useParams();
  const dispatch = useAppDispatch();
  const {
    isCandidateDetailsDrawerOpen,
    isCandidateDetailsLoading,
    submissionDetails,
    candidateDetails,
    candidateId,
  } = useAppSelector(getSubmissionState);

  const { submissionId, jobId } = useAppSelector(getCandidateState);
  const [selectedSkills, setSelectedSkills] = useState<string>(
    submissionDetails?.rate_as?.length ? submissionDetails?.rate_as : "Unrated"
  );
  const [skills, setSkills] = useState<Skill[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [countLoading, setCountLoading] = useState<boolean>(false);

  const getSubmissionsList = async (candidate_id: string) => {
    try {
      const response = await SubmissionService.getSubmissionByJobId({
        job_id: jobId ? jobId : nid,
      });
      const submissionData = response?.results;
      if (submissionData?.length) {
        const findSubmission = submissionData.find(
          (submission) => submission.candidate_id === candidate_id
        );
        dispatch(
          setSubmissionDetails({
            ...submissionDetails,
            rate_as: findSubmission?.rate_as,
            stage: findSubmission?.stage,
          } as GetSubmissionResponse)
        );
        dispatch(setSubmissions(submissionData));
      }
    } catch (error) {}
  };

  const updateRate = async (rate_id: string) => {
    setLoading(true);
    try {
      const request = {
        candidate_id: Number(submissionDetails?.candidate_id),
        job_id: Number(jobId ? jobId : nid),
        rate_id: Number(rate_id),
      } as UpdateRateOptionRequest;
      await SubmissionService.updateRateOptions(request);
      await getSubmissionsList(submissionDetails?.candidate_id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeySkillsChange = (event) => {
    const findSkill = skills.find(
      (skill) => skill.title === event.target.value
    );
    setSelectedSkills(event.target.value);
    if (findSkill) {
      updateRate(findSkill.id);
    }
  };

  const getRateOption = async () => {
    setLoading(true);
    try {
      const response = await SubmissionService.getRateOptions();
      setSkills(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getTopCount = async () => {
    setCountLoading(true);
    try {
      const response = await SubmissionService.getTopCountCandidate({
        job_id: jobId ? jobId : nid,
        candidate_id: submissionDetails?.candidate_id,
      });
      setCount(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setCountLoading(false);
    }
  };

  const updateClientSubmission = async () => {
    try {
      const response = await SubmissionService.updateClientSubmissionReview({
        id: submissionId,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCountLoading(false);
    }
  };

  useEffect(() => {
    getRateOption();
  }, []);
  useEffect(() => {
    getTopCount();
    updateClientSubmission();
  }, []);
  console.log("jobId", jobId);
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
      >
        <AtsTitleText
          text={"Candidate Detail"}
          fs={22}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={0.3}
          sx={{ cursor: "pointer" }}
        >
          {/* <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#000",
            }}
            text="1"
          />
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color: "#000",
              marginRight: "4px",
            }}
            text="of"
          />
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#000",
            }}
            text="59"
          />
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: "#000",
              marginRight: "10px",
            }}
            text="Candidates"
          />
          <Stack
            gap={0}
            direction={"row"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            marginRight={"12px"}
          >
            <ChevronLeft
              sx={{ width: "17px", height: "39px", color: "#6d6d6d" }}
            />
            <AtsText
              sx={{
                fontSize: 12,
                color: "#6d6d6d",
                marginRight: "15px",
              }}
              text="Prev"
            />
            <AtsText
              sx={{
                fontSize: 12,
                color: "#000",
              }}
              text="Next"
            />
            <ChevronRight
              sx={{ width: "17px", height: "39px", color: "#000" }}
            />
          </Stack> */}
          <IconButton
            color="inherit"
            onClick={() => {
              dispatch(
                setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen)
              );
              dispatch(setCondidateTabValue(0));
            }}
          >
            <Close
              sx={{
                fontSize: "25px",
              }}
            />
          </IconButton>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </Stack>
      </Stack>

      <Stack
        py={2}
        sx={{ borderBottom: 1, borderColor: "#f5f5f5", pl: 4, pr: 4 }}
      >
        <Grid
          container
          spacing={2}
          style={{ position: "relative", height: "-webkit-fill-available" }}
        >
          <Grid item xs={12} md={6} sm={6} sx={{ alignContent: "center" }}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <Stack>
                {isCandidateDetailsLoading ? (
                  <Skeleton variant="circular" width={110} height={110} />
                ) : isNotNullOrUndefined(candidateDetails?.image) ? (
                  <img
                    src={candidateDetails?.image}
                    alt="Nvidia Logo"
                    style={{
                      height: "auto",
                      marginRight: "10px",
                      width: "97px",
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <Stack
                    sx={{
                      width: 97,
                      height: 97,
                      marginRight: "10px",
                    }}
                  >
                    <AtsUserNameTextAvatar
                      fs={24}
                      name={`${candidateDetails?.first_name} ${candidateDetails?.last_name}`}
                    />
                  </Stack>
                )}
              </Stack>
              <Stack>
                {isCandidateDetailsLoading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  <AtsText
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#000000",
                      mb: 0.5,
                    }}
                    text={`${candidateDetails?.first_name ?? ""} ${
                      candidateDetails?.last_name ?? ""
                    }`}
                  />
                )}
                <AtsText
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#6d6d6d",
                    mb: 0,
                  }}
                  text={candidateDetails?.job_title ?? ""}
                />
                <Stack direction={"row"} gap={0.3} py={1}>
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    text={
                      submissionDetails?.rating
                        ? formateOneDigitNum(submissionDetails?.rating)
                        : 0
                    }
                  />
                  <img
                    src={StarIconRating}
                    alt="Nvidia Logo"
                    style={{ width: 15 }}
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#6d6d6d",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                    text="|"
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#6d6d6d",
                    }}
                    text="Applied:"
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    text={getDateFormatMMMMDYYYYY(
                      submissionDetails?.applied_on
                    )}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{ alignContent: "center", width: "100%" }}
                >
                  <Grid item xs>
                    {loading ? (
                      <Skeleton variant="rounded" width={120} height={32} />
                    ) : (
                      <FormControl fullWidth size="small">
                        <Select
                          labelId="skills-label"
                          value={selectedSkills}
                          onChange={handleKeySkillsChange}
                          IconComponent={KeyboardArrowDown}
                          displayEmpty
                          renderValue={(selected) => {
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap="8px"
                              >
                                {selectedSkills !== "Unrated" && (
                                  <AtsIcon
                                    height="18px"
                                    icon={`icon-${
                                      selected === RateAsEnum.NOT_FIT
                                        ? "dislike"
                                        : selected === RateAsEnum.MAYBE
                                        ? "message-question"
                                        : "like-1"
                                    }`}
                                  />
                                )}
                                <AtsTitleText
                                  text={selectedSkills}
                                  fs={12}
                                  fw={400}
                                />
                              </Stack>
                            );
                          }}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "0px 0px 0px 0px",
                            borderRadius: "20px",
                            backgroundColor: "inherit",
                            color: "#6d6d6d",
                            width: "122px",
                            height: "32px",
                            border: "1px solid #dadada",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiSelect-select": {
                              borderRadius: "20px",
                              minHeight: "0.5rem !important",
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                borderRadius: "8px", // Rounded corners for the dropdown menu
                                "& .MuiMenuItem-root": {
                                  fontSize: "12px",
                                },
                              },
                            },
                          }}
                        >
                          {skills.map((match) => (
                            <MenuItem key={match.id} value={match.title}>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={"8px"}
                              >
                                <AtsIcon
                                  height="20px"
                                  icon={`icon-${
                                    match.title === RateAsEnum.NOT_FIT
                                      ? "dislike"
                                      : match.title === RateAsEnum.MAYBE
                                      ? "message-question"
                                      : "like-1"
                                  }`}
                                />
                                <AtsTitleText
                                  lineHeight={"0px"}
                                  text={match.title}
                                  fs={12}
                                  fw={400}
                                />
                              </Stack>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs="auto" sx={{ alignContent: "center" }}>
                    {loading ? (
                      <Skeleton variant="rounded" width={80} height={32} />
                    ) : (
                      <AtsRoundedButton
                        buttonHeight={30}
                        fs={"12"}
                        fw={600}
                        text={submissionDetails?.stage}
                        buttonVariant={renderStatisticsBgcolor(
                          submissionDetails?.stage
                        )}
                      />
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={2}
              sx={{ py: 0, position: "absolute", right: 0 }}
              className="messageBtnTopRight"
            >
              {/* <AtsRoundedButton
                onClick={() => dispatch(setCondidateTabValue(5))}
                text=""
                buttonWidth={"185px"}
                buttonVariant="whiteContained"
                startIcon={<MessageIcon />}
              /> */}
              <AtsRoundedButton
                onClick={() => {
                  if (isNotNullOrUndefined(candidateDetails)) {
                    dispatch(setIsOpenMakeAnOfferDialog(true));
                  }
                }}
                buttonWidth={"149px"}
                text="Make an Offer"
                buttonVariant="outline"
                isInitial
                startIcon={<MakeAnOfferIcon />}
              />

              <AtsRoundedButton
                onClick={() => dispatch(setIsOpenInterviewDetailsDialog(true))}
                buttonWidth={"185px"}
                text="Schedule Interview"
                startIcon={<ScheduleIcon />}
              />

              <ListItemIcon sx={{ minWidth: "20px", mt: 0 }}>
                <img
                  src={ThreeDotIcon}
                  alt="Three Dot"
                  style={{ height: "33px", width: "26px" }}
                />
              </ListItemIcon>
            </Stack>

            <div style={{ height: "-webkit-fill-available" }}>
              <Stack
                direction={"row"}
                sx={{ py: 0, position: "absolute", bottom: 0, right: 0 }}
              >
                {countLoading ? (
                  <Skeleton variant="text" width={200} height={30} />
                ) : (
                  count > 0 && (
                    <Stack direction={"row"} sx={{ alignContent: "center" }}>
                      <img
                        src={TopCandidate}
                        alt="Path"
                        style={{ height: "auto", marginRight: "5px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: "#000",
                        }}
                      >
                        Top Candidate{" "}
                        <span
                          style={{
                            fontWeight: 600,
                          }}
                        >{`${count} other jobs`}</span>
                      </Typography>
                    </Stack>
                  )
                )}
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CandidateDetailsSection;
