import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import React from "react";
import {
  FilterIcon,
  InvoiceListIcon,
} from "../../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { InvoiceTypeEnum } from "../../../../../../enums/invoiceEnums";
import { useInvoices } from "../../../../../../providers/InvoicesProvider";
import { BORDERS, COLORS } from "../../../../../../theme";
import { IColumnProps } from "../../../../../../types/commonType";
import JobsSwitch from "../../../../../Dashboard/PostedJobs/JobsSwitch";
import AtsRoundedSearchInput from "../../../../../../components/AtsRoundedSearchInput";
import { InvoiceListResponse } from "../../../../../../types/invoiceTypes";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "invoice_no",
    header: "Invoice no.",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "date",
    header: "Invoice Date",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "amount",
    header: "Amount",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const InvoicesList = () => {
  const {
    invoiceId,
    setInvoiceId,
    setIsInvoiceView,
    selectedInvoiceTypeTab,
    setSelectedInvoiceTypeTab,
    setSelectedInvoice,
    loading,
    invoices,
  } = useInvoices();

  const invoiceDetailsInfo = (invoice: InvoiceListResponse) => {
    setSelectedInvoice(invoice);
    setIsInvoiceView(true);
  };
  const customizeRow = (column: IColumnProps, invoice: InvoiceListResponse) => {
    switch (column.key) {
      case "invoice_no":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack>
                <InvoiceListIcon />
              </Stack>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={500}
                  textColor={"#000"}
                  text={`#${invoice.invoice_no}`}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "date":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={invoice.date}
              />
            </Stack>
          </td>
        );
      case "amount":
        const amount = `$ ${invoice.amount}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText fs={12} fw={400} textColor={"#000"} text={amount} />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={12}
                fw={600}
                text={invoice.status}
                buttonVariant={getStatusOfInvoice(invoice.status)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton
              px={0.6}
              onClick={() => invoiceDetailsInfo(invoice)}
            >
              <AtsIcon icon="icon-eye" height="16px" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = invoices.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Stack gap={"20px"} pb={"24px"}>
        {invoices?.length > 0 && (
          <AtsTitleText
            text={`${invoices?.length} ${
              invoices?.length === 1 ? "invoice" : "invoices"
            }`}
            fs={18}
            fw={600}
            textColor={COLORS.BLACK}
          />
        )}
        <AtsRowBetween>
          <>
            <JobsSwitch
              width="60px"
              jobs={Object.keys(InvoiceTypeEnum).map((value) => ({
                title: InvoiceTypeEnum[value],
                type: value,
              }))}
              active={selectedInvoiceTypeTab}
              onChange={(value) => {
                setSelectedInvoiceTypeTab(value as InvoiceTypeEnum);
              }}
            />
            <AtsRoundedSearchInput
              placeholder="Search invoice no."
              value={invoiceId}
              onChange={(e) => setInvoiceId(e.target.value)}
            />
          </>
        </AtsRowBetween>
      </Stack>
      <Box>
        {loading ? (
          <AtsDataTable
            isTopBorder
            data={[]}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : invoices.length ? (
          <AtsDataTable
            isTopBorder
            data={invoices}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        ) : (
          <AtsEmptyState
            sx={{
              height: "400px",
            }}
            imgHeight={68}
            imgWidth={74}
            text={`No ${selectedInvoiceTypeTab} invoices recently.`}
            subText=""
          />
        )}
      </Box>
    </>
  );
};

export default InvoicesList;

export const getStatusOfInvoice = (status: string) => {
  if (status === "paid") {
    return "greenContained";
  }
  return "primaryContained";
};
