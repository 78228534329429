import { Box, Grid, Stack } from "@mui/material";
import { FileIcon, MarkerIcon, TimeIcon } from "../../../assets/icons/Icons";
import FlagredIcon from "../../../assets/icons/flagRed.svg";
import AtsCard from "../../../components/AtsCard";
import AtsText from "../../../components/AtsText";
import { getPostOpportunityState } from "../../../redux/slices/postOpportunitySlice";
import { useAppSelector } from "../../../redux/store";
import { COLORS } from "../../../theme";
import { JobListingResponse } from "../../../types/jobsTypes";
import JobCardWrapper from "../components/JobCardWrapper";

const ReviewListing = () => {
  const { jobDetailsById } = useAppSelector(getPostOpportunityState);
  const job = {
    nid: jobDetailsById?.nid,
    job_location: `${jobDetailsById?.work_mode}`,
    job_priority: jobDetailsById?.job_priority,
    job_status: jobDetailsById?.job_status,
    job_type: jobDetailsById?.job_type,
    title: jobDetailsById?.title,
    work_mode: jobDetailsById?.work_mode,
  } as JobListingResponse;
  return (
    <>
      <JobCardWrapper
        header="Review your listing"
        subText={`Here's what your job posting will look like. Confirm everything.`}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <AtsCard>
              <Stack gap={1}>
                <Stack direction={"row"} alignItems={"center"} gap={3} py={1}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Box
                      sx={{
                        width: "6px",
                        height: "6px",
                        bgcolor: COLORS.GREEN,
                        borderRadius: "20px",
                      }}
                    ></Box>
                    <AtsText
                      sx={{
                        fontSize: 11,
                        fontWeight: 700,
                        color: COLORS.GREEN,
                        clear: "both",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: 90,
                        textWrap: "nowrap",
                      }}
                      text={`ACTIVE`}
                    />
                  </Stack>
                  {jobDetailsById.job_priority.length > 0 &&
                    jobDetailsById.job_priority === "Hot" && (
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <img src={FlagredIcon} alt="Nvidia Logo" />
                        <AtsText
                          sx={{
                            fontSize: 11,
                            fontWeight: 700,
                            color: COLORS.RED_LIGHT,
                            textTransform: "uppercase",
                          }}
                          text={`HIGH PRIORITY`}
                        />
                      </Stack>
                    )}
                </Stack>
                <Stack sx={{}}>
                  <AtsText
                    text={job?.title}
                    sx={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#231f20",
                    }}
                  />
                </Stack>
                <Stack py={2} gap={1}>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <MarkerIcon />
                    <AtsText
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: COLORS.LIGHT_GRAY,
                      }}
                      text={`${job.job_location}`}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={2}>
                    <TimeIcon />
                    <AtsText
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: COLORS.LIGHT_GRAY,
                      }}
                      text={`${
                        job.job_type === "Direct hire"
                          ? "Permanent"
                          : job.job_type
                      }`}
                    />
                  </Stack>
                  <Stack direction={"row"} gap={1.7}>
                    <FileIcon />
                    <AtsText
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: COLORS.LIGHT_GRAY,
                      }}
                      text={`R-11023`}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </AtsCard>
          </Grid>
        </Grid>
      </JobCardWrapper>
    </>
  );
};

export default ReviewListing;
