import { Grid, Stack } from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import AtsText from "../../../components/AtsText";
import FontUtil from "../../../utils/FontUtil";
import ResourcesTipsCard from "./ResourcesTipsCard";

export interface ResourcesProps {
  title: string;
  id: string;
  body: string;
  authorEmail: string;
  authorName: string;
  category: string;
  categoryId: string;
  image: string;
  authorBio: null;
  news: string;
  created: string;
  user: {
    firstName: string;
    lastName: string;
    username: null;
    email: string;
  };
}

const ResourcesTips = () => {
  const { font24 } = FontUtil();
  const [loading, setLoading] = useState<boolean>(false);
  const [remoteCultureState, setRemoteCultureState] = useState<
    Partial<ResourcesProps>
  >({});
  const [interviewAIState, setInterviewAIState] = useState<
    Partial<ResourcesProps>
  >({});
  const [hiringPlanState, setHiringPlanState] = useState<
    Partial<ResourcesProps>
  >({});

  const getRemoteCulture = async () => {
    const response = await axios.get(
      "https://cprodapi.esteemed.io/v1/blogpost/by/100146837"
    );
    return response;
  };
  const getInterviewMadeByAI = async () => {
    const response = await axios.get(
      "https://cprodapi.esteemed.io/v1/blogpost/by/100146842"
    );
    return response;
  };
  const getHiringPlan = async () => {
    const response = await axios.get(
      "https://cprodapi.esteemed.io/v1/blogpost/by/100146856"
    );
    return response;
  };

  const getResources = async () => {
    setLoading(true);
    try {
      const [remoteResponse, interviewMadeByAIResponse, hiringPlanResponse] =
        await Promise.all([
          getRemoteCulture(),
          getInterviewMadeByAI(),
          getHiringPlan(),
        ]);
      setRemoteCultureState(remoteResponse.data);
      setInterviewAIState(interviewMadeByAIResponse.data);
      setHiringPlanState(hiringPlanResponse.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <>
      <Stack gap={2} pb={2}>
        <AtsText
          text="Resources and Tips"
          sx={{
            ...font24,
            fontWeight: 600,
            color: "#231f20",
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <motion.div
              whileHover={{ scale: 1.02, translateY: -5 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
                cursor: "pointer",
                boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <ResourcesTipsCard
                text={remoteCultureState?.title}
                image={remoteCultureState?.image}
                buttonText={"Read More"}
                resourcesData={remoteCultureState}
                loading={loading}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <motion.div
              whileHover={{ scale: 1.02, translateY: -5 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
                cursor: "pointer",
                boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <ResourcesTipsCard
                text={interviewAIState?.title}
                image={interviewAIState?.image}
                buttonText={"Read More"}
                resourcesData={interviewAIState}
                loading={loading}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <motion.div
              whileHover={{ scale: 1.02, translateY: -5 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
                cursor: "pointer",
                boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <ResourcesTipsCard
                text={hiringPlanState?.title}
                image={hiringPlanState?.image}
                buttonText={"Read More"}
                resourcesData={hiringPlanState}
                loading={loading}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ResourcesTips;
