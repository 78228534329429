import { Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import AtsTitleText from "../../components/AtsTitleText";
import StatementWorkFilter from "./modules/StatementWorkFilter";
import StatementOfWorkCard from "./modules/StatementOfWorkCard";
import {
  SortContractEnums,
  useContracts,
} from "../../providers/ContractsProvider";
import LoadingSOWContract from "./modules/LoadingSOWContract";

const StatementsOfWork = () => {
  const {
    getSOW_Contracts,
    sowLoading,
    sowContracts,
    selectedContractors,
    selectedProjects,
    selectedSortBy,
  } = useContracts();
  useEffect(() => {
    getSOW_Contracts({});
  }, []);

  const filteredContracts = useMemo(() => {
    const filterContracts = (contracts: typeof sowContracts) =>
      contracts.filter(
        ({ candidate_id, project_id }) =>
          (!selectedContractors.length ||
            selectedContractors.includes(candidate_id)) &&
          (!selectedProjects.length || selectedProjects.includes(project_id))
      );

    const sortContracts = (contracts: typeof sowContracts, isNewest: boolean) =>
      contracts.sort((a, b) =>
        isNewest
          ? new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
          : new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );

    const filtered = filterContracts(sowContracts);
    return selectedSortBy === SortContractEnums.Newest
      ? sortContracts(filtered, true)
      : sortContracts(filtered, false);
  }, [sowContracts, selectedContractors, selectedProjects, selectedSortBy]);
  return (
    <>
      {/* <Stack px={"9px"}>
        <AtsTitleText
          text={"16 Active SOWs"}
          textColor="#000"
          fs={18}
          fw={600}
        />
      </Stack> */}
      <Stack gap={"20px"}>
        <Stack px={"9px"}>
          <StatementWorkFilter />
        </Stack>
        <Stack gap={"30px"}>
          {sowLoading
            ? Array.from(new Array(3)).map((_, index) => (
                <LoadingSOWContract key={index} />
              ))
            : filteredContracts.map((item) => (
                <StatementOfWorkCard
                  key={item.candidate_id || item.project_id}
                  data={item}
                />
              ))}
        </Stack>
      </Stack>
    </>
  );
};

export default StatementsOfWork;
