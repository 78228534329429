import React from "react";
import { COLORS } from "../theme";
import { styled } from "@mui/material";

interface Props {
  icon: string;
  color?: string;
  height?: string;
  width?: string;
  fs?: number;
  fw?: number;
  pr?: string;
}

const AtsIcon = ({
  icon,
  color = COLORS.BLACK,
  fs = 16,
  height,
  width,
  fw,
  pr,
}: Props) => {
  const StyledIcon = styled("i")(({ theme }) => ({
    color,
    fontSize: fs,
    ...(height && { height }),
    ...(width && { width }),
    ...(pr && { paddingRight: pr }),
    "::before": {
      ...(fw && { fontWeight: `${fw} !important` }),
    },
  }));
  return (
    <>
      <StyledIcon className={icon}></StyledIcon>
    </>
  );
};

export default AtsIcon;
