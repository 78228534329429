import { Stack, Typography } from "@mui/material";
import React from "react";
import { BulbIcon } from "../assets/Icons";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";

interface Props {
  header: string;
  subText: string;
  linkText?: string;
}

const ImportantThings = ({ header, subText, linkText }: Props) => {
  return (
    <>
      <Stack direction={"row"} gap={1} alignItems={"flex-start"}>
        <BulbIcon />
        <Stack gap={1}>
          <AtsTitleText text={header} fs={14} fw={700} textColor="#4b4748" />
          <Stack direction={"row"} gap={0.5}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: "#413d3e",
              }}
            >
              {subText}
              {linkText && (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: 12,
                    fontWeight: 400,
                    color: COLORS.PRIMARY,
                  }}
                >
                  {linkText}
                </span>
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ImportantThings;
