import { Box, Stack } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import AtsRow from "../../../../components/AtsRow";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";

const OverViewDonutChart = () => {
  const timeData = [
    {
      project: "Cisco Tech",
      time: 6,
      color: "#2563EB",
    },
    {
      project: "Globex Interactive",
      time: 5,
      color: "#818CF8",
    },
    {
      project: "Pi Project",
      time: 3,
      color: "#38BDF8",
    },
    {
      project: "A12 Project",
      time: 4,
      color: "#93C5FD",
    },
  ];
  const colors = ["#2563EB", "#818CF8", "#38BDF8", "#93C5FD"];
  const sanitizedSeries =
    timeData
      .map((value) => value.time)
      ?.filter((value) => typeof value === "number" && value !== null) || [];
  const sanitizedLabels =
    timeData
      .map((value) => value.project)
      ?.filter((label) => typeof label === "string" && label.trim() !== "") ||
    [];

  if (
    sanitizedSeries.length === 0 ||
    sanitizedLabels.length === 0 ||
    sanitizedSeries.length !== sanitizedLabels.length
  ) {
    return <div>Error: Invalid data</div>;
  }
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "donut",
      height: 300,
      fontFamily: "Inter",
    },
    labels: sanitizedLabels,
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        const seriesIndex = opts.seriesIndex;
        const total = opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        const percentage = (
          (opts.w.globals.series[seriesIndex] / total) *
          100
        ).toFixed(2);
        return `${percentage}%`;
      },
    },
    stroke: {
      width: 0,
    },
    colors: colors,
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <>
      <AtsRow py={"30px"}>
        <>
          <Box
            sx={{
              width: "30%",
            }}
          >
            <div id="chart">
              <ReactApexChart
                options={options}
                series={sanitizedSeries}
                type="donut"
                height={300}
              />
            </div>
          </Box>
          <Stack
            gap={"16px"}
            sx={{
              width: "70%",
            }}
          >
            {timeData.map((value, index) => {
              const total = timeData
                .map((value) => value.time)
                .reduce((a, b) => a + b, 0);
              const percentage = ((value.time / total) * 100).toFixed(2);
              return (
                <AtsRow key={index} gap={"10px"}>
                  <>
                    <Box
                      sx={{
                        width: 150,
                        textAlign: "end",
                      }}
                    >
                      <AtsTitleText
                        text={value.project}
                        fs={12}
                        fw={500}
                        textColor={COLORS.DARK_GRAY}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 55,
                      }}
                    >
                      <AtsTitleText
                        text={`${value.time}:00:00`}
                        fs={12}
                        fw={500}
                        textColor={"#000"}
                      />
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        height: 16,
                        bgcolor: "whitesmoke",
                        borderRadius: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          width: `${percentage}%`,
                          bgcolor: value.color,
                          borderRadius: "5px",
                        }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{
                        width: 55,
                      }}
                    >
                      <AtsTitleText
                        text={`${percentage}%`}
                        fs={12}
                        fw={500}
                        textColor={"#000"}
                      />
                    </Box>
                  </>
                </AtsRow>
              );
            })}
          </Stack>
        </>
      </AtsRow>
    </>
  );
};

export default OverViewDonutChart;
