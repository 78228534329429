import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { TimesheetServices } from "../../../../../../../Api/timesheetServices";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useDisputeResolution } from "../../../../../../../providers/DisputeResolutionProvider";
import { BG_COLORS, BORDERS } from "../../../../../../../theme";
import { DisputedData } from "../../../../../../../types/timesheetTypes";
import DisputeUpdate from "./DisputeResolution/DisputeUpdate";
import Evidence from "./DisputeResolution/Evidence";
import Summary from "./DisputeResolution/Summary";
import TimelineTabs from "./DisputeResolution/TimelineTabs";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import { useParams } from "react-router-dom";

export interface DisputeResolutionProps {
  loading: boolean;
  status: string;
  disputedTimesheet: DisputedData | null;
}

const DisputeResolutionDrawer = ({ nid, status }: { nid: string; status }) => {
  const { id } = useParams();
  const { openCloseDisputedResolutionDrawer, openDrawer, show } =
    useDisputeResolution();
  const { onResolveTimesheet, timelogApproveLoading } = useTimesheet();
  const [disputedDetailsLoading, setDisputedDetailsLoading] = useState(false);
  const [disputedTimesheet, setDisputedTimesheet] =
    useState<DisputedData | null>(null);

  const getDisputedDetails = async (nid: string) => {
    setDisputedDetailsLoading(true);

    try {
      const response = await TimesheetServices.getDisputedDetails({ nid });
      setDisputedTimesheet(response);
    } catch (error) {
      console.error(error);
    } finally {
      setDisputedDetailsLoading(false);
    }
  };

  useEffect(() => {
    nid.length && getDisputedDetails(nid);
  }, [nid]);

  return (
    <>
      <Drawer
        sx={{}}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            width: "670px",
            overflow: "hidden",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={openCloseDisputedResolutionDrawer}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            outline: "0",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            pb: 2,
            height: "100%",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
          >
            <AtsTitleText
              text={"Dispute resolution"}
              fs={18}
              fw={600}
              textColor={"#000"}
            />
            <IconButton
              color="inherit"
              onClick={openCloseDisputedResolutionDrawer}
            >
              <Close
                sx={{
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </Stack>
          <TimelineTabs
            status={status}
            loading={disputedDetailsLoading}
            disputedTimesheet={disputedTimesheet}
          />
          <Box
            sx={{
              px: "30px",
              my: "30px",
              height: `calc(100% - ${show ? "272px" : "275px"})`,
              overflow: "auto",
            }}
          >
            <Stack
              gap={"16px"}
              sx={{
                pb: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <DisputeUpdate
                status={status}
                loading={disputedDetailsLoading}
                disputedTimesheet={disputedTimesheet}
              />
            </Stack>
            <Stack
              gap={"16px"}
              sx={{
                py: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <Summary
                status={status}
                loading={disputedDetailsLoading}
                disputedTimesheet={disputedTimesheet}
              />
            </Stack>
            <Stack
              gap={"16px"}
              sx={{
                py: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <Evidence
                status={status}
                loading={disputedDetailsLoading}
                disputedTimesheet={disputedTimesheet}
              />
            </Stack>
          </Box>
          <AtsRowBetween
            sx={{
              py: "10px",
              px: "20px",
              boxShadow: "0 -2px 6px 0 rgba(0, 0, 0, 0.12)",
              bgcolor: BG_COLORS.GRAY,
            }}
          >
            <>
              {status === "disputed" ? (
                <AtsRoundedButton
                  text="Withdraw"
                  buttonVariant="outline"
                  buttonWidth={124}
                  disabled={timelogApproveLoading}
                  loading={timelogApproveLoading}
                  onClick={async () => {
                    await onResolveTimesheet(Number(nid), id);
                    openCloseDisputedResolutionDrawer();
                  }}
                />
              ) : (
                <Box></Box>
              )}
              <AtsCustomButton px={0} py={"10px"}>
                <AtsRow gap={"5px"}>
                  <>
                    <AtsIcon icon="icon-call" height="16px" fs={16} fw={600} />
                    <AtsTitleText
                      text={"Schedule a call"}
                      fs={14}
                      fw={400}
                      textColor={"#000"}
                    />
                  </>
                </AtsRow>
              </AtsCustomButton>
            </>
          </AtsRowBetween>
        </Box>
      </Drawer>
    </>
  );
};

export default DisputeResolutionDrawer;
