import { Box, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectService } from "../../../../../../Api/projectServices";
import AtsCard from "../../../../../../components/AtsCard";
import AtsEmptyState from "../../../../../../components/AtsEmptyState";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { ProjectContractorsStatusEnum } from "../../../../../../enums/projectEnums";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import {
  getContractorDetailsState,
  setSelectedContractor,
} from "../../../../../../redux/slices/contractorSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../theme";
import {
  ContractorRoleResponse,
  RolesForProjectParams,
} from "../../../../../../types/projectTypes";
import ContractorsActiveList from "./modules/ContractorsActiveList";
import RolesForProject from "./modules/RolesForProject";

const Contractors = () => {
  const dispatch = useAppDispatch();
  const { selectedContractor } = useAppSelector(getContractorDetailsState);
  const { id } = useParams();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("xxl"));
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [rolesList, setRolesList] = useState<ContractorRoleResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== rolesList.length) {
        setPage(page + 1);
      }
    }
  };
  const getRoles = async () => {
    try {
      setLoading(true);
      const requestObj = {
        project_id: id,
        ...(search.length && { title: search }),
      } as RolesForProjectParams;
      const response = await ProjectService.getRolesForProject(requestObj);
      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setRolesList(response.results);
          Object.keys(selectedContractor).length === 0 &&
            dispatch(setSelectedContractor(response.results[0]));
        } else {
          setRolesList((prevList) => [...prevList, ...response.results]);
        }
      } else {
        setTotalItem(0);
        dispatch(setSelectedContractor({}));
        setRolesList([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRoles();
  }, [page, debouncedSearch]);
  return (
    <>
      {rolesList?.length > 0 && (
        <Stack mb={"20px"}>
          <AtsTitleText
            fs={18}
            fw={600}
            text={`${rolesList?.length} Contractors`}
          />
        </Stack>
      )}

      <Stack
        sx={{
          height: "calc(100% - 51px)",
        }}
      >
        {loading ? (
          <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
            <Box
              sx={{
                width: isLarge ? 280 : 260,
                height: "100%",
              }}
            >
              <div
                // ref={jobListRef}
                // onScroll={loading ? () => {} : loadMoreItems}
                className="scrollHide"
                style={{
                  overflowX: "scroll",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Stack
                  gap={"13px"}
                  sx={{
                    // height: "100%",
                    pr: 2,
                    pb: "10px",
                  }}
                >
                  <AtsCard
                    sx={{
                      mb: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        pt: "3px",
                        pb: "3px",
                      }}
                    >
                      <Stack
                        gap={"10px"}
                        className=""
                        pb={"16px"}
                        sx={{
                          borderBottom: BORDERS.GRAY,
                        }}
                        px={"5px"}
                      >
                        <Stack>
                          <Skeleton />
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"8px"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"8px"}
                          >
                            <AtsIcon icon="icon-clock" height="16px" />

                            <Skeleton width={80} />
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"8px"}
                          >
                            <AtsIcon icon="icon-document-text" height="16px" />

                            <Skeleton width={80} />
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        pt={"12px"}
                      >
                        <Box></Box>

                        <Skeleton width={80} />
                      </Stack>
                    </Box>
                  </AtsCard>
                </Stack>
              </div>
            </Box>
            <Stack
              gap={"10px"}
              className="scrollHide"
              sx={{
                overflowX: "scroll",
                height: "100%",
                flex: 1,
              }}
            >
              {Array.from(new Array(5)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={"100%"}
                  height={40}
                />
              ))}
            </Stack>
          </Stack>
        ) : rolesList.length ? (
          <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
            <Box
              sx={{
                width: isLarge ? 280 : 260,
                height: "100%",
              }}
            >
              <div
                // ref={jobListRef}
                // onScroll={loading ? () => {} : loadMoreItems}
                className="scrollHide"
                style={{
                  overflowX: "scroll",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Stack
                  gap={"13px"}
                  sx={{
                    // height: "100%",
                    pr: 2,
                    pb: "10px",
                  }}
                >
                  <Stack direction={"row"} gap={"25px"} alignItems={"center"}>
                    {rolesList.length > 0 && (
                      <Stack
                        direction={"row"}
                        gap={2}
                        alignItems={"center"}
                        sx={{
                          width: 250,
                          // minHeight: 28,
                        }}
                      >
                        <Stack sx={{ width: "40%" }}>
                          <AtsTitleText
                            text={`${totalItem ?? 0} Roles`}
                            fs={14}
                            fw={600}
                            textColor={COLORS.BLACK}
                          />
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                          width={"60%"}
                        >
                          <AnimatePresence>
                            {showInput ? (
                              <Stack
                                direction={"row"}
                                gap={1}
                                sx={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  border: BORDERS.GRAY,
                                  bgcolor: "white",
                                  pl: 1,
                                  // height: 25,
                                }}
                              >
                                <input
                                  type="text"
                                  className="customInput"
                                  style={{
                                    width: "80%",
                                  }}
                                  value={search}
                                  onChange={(e) => {
                                    setPage(0);
                                    setSearch(e.target.value);
                                  }}
                                />
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  // mt={0.5}
                                  pl={2}
                                  sx={{ cursor: "pointer", width: "20%" }}
                                  onClick={() => setShowInput(false)}
                                >
                                  <AtsIcon icon="icon-search-normal-1" />
                                </Stack>
                              </Stack>
                            ) : (
                              <Stack
                                mt={0.5}
                                pl={2}
                                sx={{ cursor: "pointer", width: "15%" }}
                                onClick={() => setShowInput(true)}
                              >
                                <AtsIcon icon="icon-search-normal-1" />
                              </Stack>
                            )}
                          </AnimatePresence>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                  {rolesList.map((role) => (
                    <RolesForProject
                      roleData={role}
                      selectedRole={selectedContractor}
                      onSelect={() => dispatch(setSelectedContractor(role))}
                      loading={loading}
                    />
                  ))}
                </Stack>
              </div>
            </Box>
            <Stack
              gap={"40px"}
              className="scrollHide"
              sx={{
                overflowX: "scroll",
                height: "100%",
                flex: 1,
              }}
            >
              <ContractorsActiveList
                status={ProjectContractorsStatusEnum.ACTIVE}
              />
              <Stack gap={"13px"}>
                <ContractorsActiveList
                  status={ProjectContractorsStatusEnum.INACTIVE}
                />
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <AtsEmptyState
            sx={{
              height: "100%",
            }}
            imgHeight={68}
            imgWidth={74}
            text="No role recently."
            subText=""
          />
        )}
      </Stack>
    </>
  );
};

export default Contractors;
