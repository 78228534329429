import { Box, Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { useEffect, useState } from "react";
import { CreateUpdateBillingInfoRequest } from "../../../types/billingTypes";
import { CompanyAddressProps } from "../../../types/companyTypes";
import AtsRow from "../../../components/AtsRow";
import AtsTextInput from "../../../components/AtsTextInput";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { Skeleton } from "@mui/material";

interface IProps {
  isEdit: boolean;
  bilingDetailsError: any;
}

const BillingInfo = ({ isEdit, bilingDetailsError }: IProps) => {
  const {
    billingInfoState,
    handleBillingDetailsChange,
    setSearchQueryForBilling,
    searchQueryForBilling,
    billingInfoLoading,
  } = useCreateAccount();
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSearchQueryForBilling(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";
      let country_code = "";

      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
          country_code = component.short_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        }
      }
      const address = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
        country_code,
      } as CompanyAddressProps;
      handleBillingDetailsChange("address", address);
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const renderTextWrap = (keyValue, text, value) => {
    return (
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        gap={"50px"}
        pb={"20px"}
      >
        <>
          <Box
            sx={{
              width: 125,
            }}
          >
            <AtsTitleText
              fs={14}
              fw={500}
              textColor={COLORS.LIGHT_GRAY}
              text={text}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              position: "relative",
            }}
          >
            {isEdit ? (
              keyValue === "address" ? (
                <Autocomplete
                  onLoad={handleLoad}
                  onPlaceChanged={handlePlaceSelect}
                >
                  <AtsTextInput
                    height="41px"
                    value={searchQueryForBilling}
                    onChange={(e) => setSearchQueryForBilling(e.target.value)}
                  />
                </Autocomplete>
              ) : (
                <AtsTextInput
                  value={value}
                  onChange={(e) =>
                    handleBillingDetailsChange(keyValue, e.target.value)
                  }
                />
              )
            ) : billingInfoLoading ? (
              <Skeleton width={"50%"} />
            ) : (
              <AtsTitleText fs={14} fw={400} text={value} />
            )}
            <Stack
              textAlign={"start"}
              sx={{
                position: "absolute",
              }}
            >
              <AtsTitleText
                text={bilingDetailsError[keyValue]}
                fs={12}
                fw={500}
                textColor={COLORS.RED_LIGHT}
              />
            </Stack>
          </Box>
        </>
      </Stack>
    );
  };

  if (!isLoaded) {
    return <Skeleton />;
  }
  return (
    <>
      {renderTextWrap("first_name", "First Name:", billingInfoState.first_name)}
      {renderTextWrap("last_name", "Last Name:", billingInfoState.last_name)}
      {renderTextWrap("address", "Billing address:", searchQueryForBilling)}
      {renderTextWrap("email_1", "Contact::", billingInfoState.email_1)}
      {renderTextWrap(
        "email_2",
        "Alternate Contact:",
        billingInfoState.email_2
      )}
    </>
  );
};

export default BillingInfo;
