export enum StatusEnum {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
}
export enum CreateUpdateEnum {
  Create = "Create",
  Update = "Update",
}
export enum TaskStatusEnum {
  All = "all",
  Active = "active",
  Archived = "archived",
}
export enum InterviewStatusEnum {
  SchedulingInProgress = "Scheduling in progress",
  Scheduled = "Scheduled",
  Completed = "Completed",
  Canceled = "Canceled",
  ToBeRescheduled = "To be Rescheduled",
  Confirmed = "Confirmed",
}
