import { Box, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import AverageRatingView from "./modules/AverageRatingView";
import TotalReviews from "./modules/TotalReviews";
import { useMemo } from "react";

const ReviewTab = () => {
  const averageRattingMemo = useMemo(() => <AverageRatingView />, []);
  const totalReviewsMemo = useMemo(() => <TotalReviews />, []);
  return (
    <>
      <Stack sx={{ height: "100%" }}>
        <AtsTitleText
          text="Performance Reviews"
          fs={20}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Box
          sx={{
            pt: 2,
            pb: 6,
            borderBottom: BORDERS.GRAY,
          }}
        >
          <AtsTitleText
            text="Average Rating"
            fs={14}
            fw={400}
            textColor={COLORS.BLACK}
          />
          {averageRattingMemo}
        </Box>
        <Box
          sx={{
            height: "calc(100% - 293px)",
            overflow: "auto",
          }}
        >
          {totalReviewsMemo}
        </Box>
      </Stack>
    </>
  );
};

export default ReviewTab;
