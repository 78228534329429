import { ChevronRight } from "@mui/icons-material";
import { Button, Grid, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobsService } from "../../../Api/jobsListingServices";
import AtsText from "../../../components/AtsText";
import AtsTitleText from "../../../components/AtsTitleText";
import { getJobState, setSelectedJobTab } from "../../../redux/slices/jobSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { ATS_ROUTES } from "../../../router/routes";
import { BORDERS, COLORS } from "../../../theme";
import { JobListingResponse } from "../../../types/jobsTypes";
import FontUtil from "../../../utils/FontUtil";
import JobsSwitch from "./JobsSwitch";
import PostedCard from "./PostedCard";
import AtsEmptyState from "../../../components/AtsEmptyState";
import { Box } from "@mui/material";
import RecentlyPostedJobsEmptyState from "./RecentlyPostedJobsEmptyState";
import AtsRow from "../../../components/AtsRow";
import LoadingPostedJobCard from "./LoadingPostedJobCard";

const JOBS = [
  {
    title: "My Jobs",
    type: "MY",
  },
  {
    title: "All Jobs",
    type: "ALL",
  },
];

const RecentlyPostedJobs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedJobTab } = useAppSelector(getJobState);
  const { font12, font14, font24 } = FontUtil();
  const [loading, setLoading] = useState<boolean>(true);
  const [jobList, setJobList] = useState<JobListingResponse[]>([]);

  const getMyJobs = async () => {
    setLoading(true);
    try {
      const response =
        selectedJobTab === "MY"
          ? await JobsService.getJobList({})
          : await JobsService.getJobListForAllJobs({});
      setJobList(response.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getMyJobs();
  }, [selectedJobTab]);

  return (
    <Stack>
      <Stack
        direction={"row"}
        sx={{ flexWrap: "wrap" }}
        pb={2}
        justifyContent={"space-between"}
      >
        <AtsText
          text="Recently Posted Jobs"
          sx={{
            ...font24,
            fontWeight: 600,
            color: "#231f20",
          }}
        />
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <JobsSwitch
            jobs={JOBS}
            active={selectedJobTab}
            onChange={(value) => dispatch(setSelectedJobTab(value))}
          />
        </Stack>
      </Stack>
      {/* <AtsRow gap={"50px"}>
        <>
          <Stack gap={"20px"}>
            <AtsEmptyState
              textFs={14}
              isStartAlign
              imgWidth={30}
              imgHeight={30}
              text="No jobs posted recently."
              subText="Keep an eye here for new opportunities!"
            />
            <Button
              sx={{
                border: `1px dashed ${COLORS.DARK_GRAY}`,
                borderRadius: "70px",
                height: "30px",
                width: "135px",
                color: "black",
                fontSize: 12,
                fontWeight: 400,
                textTransform: "initial",
              }}
            >
              Post opportunity
            </Button>
          </Stack>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <RecentlyPostedJobsEmptyState />
          </Box>
        </>
      </AtsRow> */}
      <Grid container spacing={2}>
        {loading ? (
          new Array(3).fill(0).map((_, index) => (
            <Grid
              item
              sx={{ width: "100%" }}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key={index}
            >
              <LoadingPostedJobCard key={index} />
            </Grid>
          ))
        ) : jobList && jobList.length > 0 ? (
          jobList
            .slice(0, 3)
            ?.map((job, index) => (
              <PostedCard key={index} index={index} job={job} />
            ))
        ) : (
          <AtsRow gap={"50px"}>
            <>
              <Stack gap={"20px"}>
                <AtsEmptyState
                  textFs={14}
                  isStartAlign
                  imgWidth={30}
                  imgHeight={30}
                  text="No jobs posted recently."
                  subText="Keep an eye here for new opportunities!"
                />
                <Button
                  sx={{
                    border: `1px dashed ${COLORS.DARK_GRAY}`,
                    borderRadius: "70px",
                    height: "30px",
                    width: "135px",
                    color: "black",
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "initial",
                  }}
                >
                  Post opportunity
                </Button>
              </Stack>
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <RecentlyPostedJobsEmptyState />
              </Box>
            </>
          </AtsRow>
        )}
      </Grid>

      <Stack
        mt={2}
        pb={"12px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{ cursor: "pointer", borderBottom: BORDERS.GRAY }}
        onClick={() => navigate(ATS_ROUTES.JOBS)}
      >
        <AtsTitleText fs={12} textColor={COLORS.PRIMARY} text="Show All" />
        <ChevronRight sx={{ ...font14, color: "var(--primary)" }} />
      </Stack>
    </Stack>
  );
};

export default RecentlyPostedJobs;
