import { Box, Stack } from "@mui/material";
import AtsRowBetween from "../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BORDERS } from "../../../../theme";
import TimeTabFilterSection from "../../components/TimeTabFilterSection";
import TopOverviewSection from "./TopOverviewSection";
import OverviewBarChart from "./OverviewBarChart";
import OverViewDonutChart from "./OverViewDonutChart";
import ContractorActivitiesList from "./ContractorActivitiesList";

const TimeTab = () => {
  return (
    <>
      <Stack gap={"20px"}>
        <Box
          sx={{
            border: BORDERS.GRAY,
            borderRadius: "10px",
            bgcolor: "white",
          }}
        >
          <AtsRowBetween
            sx={{
              px: "25px",
              pt: "25px",
              pb: "20px",
            }}
          >
            <>
              <AtsTitleText text={"Overview"} fs={18} fw={600} />
              <TimeTabFilterSection />
            </>
          </AtsRowBetween>
          <TopOverviewSection />
          <OverviewBarChart />
          <OverViewDonutChart />
        </Box>
        <Box
          sx={{
            border: BORDERS.GRAY,
            borderRadius: "10px",
            bgcolor: "white",
          }}
        >
          <AtsRowBetween
            sx={{
              px: "25px",
              pt: "25px",
              pb: "20px",
            }}
          >
            <>
              <AtsTitleText text={"Contractor activities"} fs={18} fw={600} />
              <TimeTabFilterSection />
            </>
          </AtsRowBetween>
          <Box
            sx={{
              mx: "25px",
              mb: "25px",
              overflow: "auto",
            }}
          >
            <ContractorActivitiesList />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default TimeTab;
