import { Box, Stack } from "@mui/material";
import download from "downloadjs";
import { useState } from "react";
import Earth from "../../../../../../../../assets/images/earth.png";
import OpenLink from "../../../../../../../../assets/images/openLink.png";
import PdfIcon from "../../../../../../../../assets/images/pdfIcon.png";
import AtsCustomButton from "../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { getSubmissionState } from "../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import AtsEmptyState from "../../../../../../../../components/AtsEmptyState";
const Resume = () => {
  const { candidateDetails } = useAppSelector(getSubmissionState);
  const [selectedResume, setSelectedResume] = useState(null);
  // const resume = [
  //   {
  //     title: "Resume",
  //     pdfLabel: "Trevor Cunninghan Resume.pdf",
  //     size: "432 KB",
  //     btnText: "Download",
  //     icon: PdfIcon,
  //   },
  //   {
  //     title: "Portfolio",
  //     pdfLabel: "Portfolio : Trevor Cunninghan | VP - Administration",
  //     size: "432 KB",
  //     btnText: "Open Link",
  //     icon: Earth,
  //   },
  // ];

  const handleShow = (resumeId: string) => {
    setSelectedResume((prev) => (prev === resumeId ? null : resumeId));
  };

  const handleDownload = (fileUrl: string, fileName: string) => {
    if (!fileUrl) return;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        download(blob, fileName, "application/pdf");
      })
      .catch((error) => console.error("Error downloading the file", error));
  };
  return (
    <>
      <Stack
        gap={1}
        sx={{
          borderBottom: BORDERS.GRAY,
          pb: 3,
        }}
      >
        <Stack pb={2}>
          <AtsTitleText fs={22} fw={600} textColor="#000" text={"Resume"} />
        </Stack>
        <Stack gap={"16px"}>
          {candidateDetails?.ats_formatted_resume &&
            candidateDetails.ats_formatted_resume.length > 0 &&
            candidateDetails.ats_formatted_resume.map((resume) => (
              <Stack
                sx={{
                  position: "relative",
                  height: "auto",
                }}
              >
                <Stack
                  key={resume.resume_id}
                  direction={"row"}
                  alignItems={"flex-start"}
                >
                  <Stack direction={"row"} gap={"21px"} sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        border: BORDERS.GRAY,
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "52px",
                        height: "52px",
                      }}
                    >
                      <img
                        src={PdfIcon}
                        alt="Nvidia Logo"
                        style={{ width: 21 }}
                      />
                    </Box>
                    <Stack gap={"10px"}>
                      <AtsTitleText
                        fs={16}
                        fw={600}
                        text={resume?.url ? resume.url.split("public/")[1] : ""}
                      />
                      <AtsTitleText
                        fs={14}
                        fw={400}
                        textColor={COLORS.DARK_GRAY}
                        text={`${resume.is_default} KB`}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    {/* <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      sx={{ pr: 2 }}
                    >
                      <AtsCustomButton
                        px={0}
                        py={0}
                        onClick={() =>
                          handleDownload(
                            resume.url,
                            resume.url.split("public/")[1]
                          )
                        }
                      >
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <AtsIcon
                            icon="icon-arrow-down-2"
                            color={COLORS.PRIMARY}
                            height="16px"
                          />
                          <AtsTitleText
                            fs={14}
                            fw={600}
                            textColor={COLORS.PRIMARY}
                            text={"Download"}
                          />
                        </Stack>
                      </AtsCustomButton>
                    </Stack> */}
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      sx={{ pr: 2 }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href={resume.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <img
                            src={OpenLink}
                            alt="Nvidia Logo"
                            style={{ width: 18 }}
                          />
                          <AtsTitleText
                            fs={14}
                            fw={600}
                            textColor={COLORS.PRIMARY}
                            text={"Open Link"}
                          />
                        </Stack>
                      </a>
                    </Stack>
                    {/* <a
                      style={{
                        textDecoration: "none",
                      }}
                      href={resume.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ pl: 1 }}
                      >
                        <AtsIcon
                          icon="icon-eye"
                          color={COLORS.BLACK}
                          height="16px"
                        />
                        <AtsTitleText fs={14} fw={600} text={"View"} />
                      </Stack>
                    </a> */}
                  </Stack>
                </Stack>

                {/* <Collapse in={selectedResume === resume.resume_id}>
                  <Box
                    sx={{
                      borderRadius: "6px",
                      border: BORDERS.GRAY,
                    }}
                  >
                    <PdfViewer pdfFile={resume.url} />
                  </Box>
                </Collapse> */}
              </Stack>
            ))}
        </Stack>
      </Stack>

      {candidateDetails?.portfolio && candidateDetails.portfolio.length > 0 ? (
        <Stack
          gap={1}
          sx={{
            borderBottom: BORDERS.GRAY,
            py: "30px",
          }}
        >
          <Stack pb={2}>
            <AtsTitleText
              fs={22}
              fw={600}
              textColor="#000"
              text={"Portfolio"}
            />
          </Stack>
          <Stack gap={"16px"}>
            {candidateDetails.portfolio.map((resume, index) => (
              <Stack
                key={index}
                sx={{
                  position: "relative",
                  height: "auto",
                }}
              >
                <Stack direction={"row"} alignItems={"flex-start"}>
                  <Stack direction={"row"} gap={"21px"} sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        border: BORDERS.GRAY,
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "52px",
                        height: "52px",
                      }}
                    >
                      <img
                        src={Earth}
                        alt="Nvidia Logo"
                        style={{ width: 21 }}
                      />
                    </Box>
                    <Stack gap={"10px"}>
                      <AtsTitleText
                        fs={16}
                        fw={600}
                        text={resume?.title ?? ""}
                      />
                      <AtsTitleText
                        fs={14}
                        fw={400}
                        textColor={COLORS.DARK_GRAY}
                        text={`0 KB`}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      sx={{ pr: 2 }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href={resume.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <img
                            src={OpenLink}
                            alt="Nvidia Logo"
                            style={{ width: 18 }}
                          />
                          <AtsTitleText
                            fs={14}
                            fw={600}
                            textColor={COLORS.PRIMARY}
                            text={"Open Link"}
                          />
                        </Stack>
                      </a>
                    </Stack>

                    {/* <a
                      style={{
                        textDecoration: "none",
                      }}
                      href={resume.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ pl: 1 }}
                      >
                        <AtsIcon
                          icon="icon-eye"
                          color={COLORS.BLACK}
                          height="16px"
                        />
                        <AtsTitleText fs={14} fw={600} text={"View"} />
                      </Stack>
                    </a> */}
                  </Stack>
                </Stack>

                {/* <Collapse in={selectedResume === resume.resume_id}>
                  <Box
                    sx={{
                      borderRadius: "6px",
                      border: BORDERS.GRAY,
                    }}
                  >
                    <PdfViewer pdfFile={resume.url} />
                  </Box>
                </Collapse> */}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ) : (
        <Box pt={"20px"}>
          <AtsEmptyState
            text="No Portfolio"
            subText=""
            imgHeight={50}
            imgWidth={50}
          />
        </Box>
      )}
    </>
  );
};

export default Resume;
