import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import StepComponent from "../../../../components/common/StepComponent";
import {
  getProjectsState,
  initailCreateProjectState,
  setActiveProjectStep,
  setActiveProjectStep2,
  setActiveProjectStep3,
  setCreatedProject,
  setCreateProjectState,
  setIsCreatedProject,
} from "../../../../redux/slices/projectsSlice";
import { ProjectService } from "../../../../Api/projectServices";
import axios from "axios";
import {
  CreateAndUpdateProjectDocsRequest,
  CreateMemberRequest,
  createProjectParams,
} from "../../../../types/projectTypes";
import { getAuthState } from "../../../../redux/slices/authSlice";
import { toast } from "react-toastify";

const CreateProjectStepper = () => {
  const dispatch = useAppDispatch();
  const {
    activeProjectStep,
    activeProjectStep2,
    activeProjectStep3,
    isEditProjectStep,
    createProjectState,
    requiredDocument,
    chooseProfileImg,
    selectedProjectDetailsConfirm,
  } = useAppSelector(getProjectsState);
  const { userInfo } = useAppSelector(getAuthState);

  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (isEditProjectStep) {
      dispatch(setActiveProjectStep(3));
      dispatch(setActiveProjectStep2(3));
      dispatch(setActiveProjectStep3(3));
    } else {
      if (activeProjectStep < 3) {
        dispatch(setActiveProjectStep(activeProjectStep + 1));
      } else if (activeProjectStep === 3 && activeProjectStep2 < 3) {
        dispatch(setActiveProjectStep2(activeProjectStep2 + 1));
      } else if (activeProjectStep2 === 3 && activeProjectStep3 < 3) {
        dispatch(setActiveProjectStep3(activeProjectStep3 + 1));
      }
    }
  };

  const handleBack = () => {
    if (activeProjectStep3 > 0) {
      dispatch(setActiveProjectStep3(activeProjectStep3 - 1));
    } else if (activeProjectStep2 > 0) {
      dispatch(setActiveProjectStep2(activeProjectStep2 - 1));
    } else if (activeProjectStep > 0) {
      dispatch(setActiveProjectStep(activeProjectStep - 1));
    }
  };

  const createDocument = async (project_id: string | number) => {
    setLoading(true);

    const document_type_id = 1;
    const document_type_parent_id = "";
    const title = createProjectState?.document?.title;
    const description = createProjectState?.document?.description;
    const attachments_fid = requiredDocument.map((attachment) =>
      typeof attachment?.fid === "string"
        ? Number(attachment.fid)
        : attachment.fid
    );
    const document_urls = [];

    const policyObj = {
      project_id:
        typeof project_id === "string" ? Number(project_id) : project_id,
      document_type_id,
      document_type_parent_id,
      title,
      description,
      attachments_fid,
      document_urls,
    } as Partial<CreateAndUpdateProjectDocsRequest>;

    await ProjectService.createProjectDocument(policyObj);

    try {
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addTeamMembers = async (project_id: string | number) => {
    setLoading(true);

    const policyObj = {
      project_id:
        typeof project_id === "string" ? Number(project_id) : project_id,
      is_in_active: 0,
      emails: createProjectState?.team_members,
    } as CreateMemberRequest;

    await ProjectService.updateProjectMember(policyObj);

    try {
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const updateProjectState = {
        title: createProjectState?.title,
        description: createProjectState?.description,
        company_id: userInfo?.company?.id,
        client_email: userInfo?.email,
        start_date: createProjectState.start_date,
        end_date: createProjectState?.end_date,
        status: createProjectState?.status,
        budget_low: createProjectState?.budget_low,
        budget_high: createProjectState?.budget_high,
        visibility: createProjectState?.visibility,
        location: createProjectState?.location,
        project_image_fid: chooseProfileImg[0]?.fid,
      } as createProjectParams;
      const response = await ProjectService.createProject(updateProjectState);
      if (response?.data) {
        await createDocument(response.data.nid);
        if (createProjectState && createProjectState.team_members.length) {
          await addTeamMembers(response.data.nid);
        }
        dispatch(setIsCreatedProject(true));
        dispatch(setCreatedProject(response.data));
        dispatch(setCreateProjectState(initailCreateProjectState));
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error during file uploads:", error);
    } finally {
      setLoading(false);
    }
  };

  const progress = (activeProjectStep / 3) * 100;
  const progress2 = (activeProjectStep2 / 3) * 100;
  const progress3 = (activeProjectStep3 / 3) * 100;

  const checkBudgetLowMin = () => {
    if (Number(createProjectState.budget_low) < 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkBudgetHighMax = () => {
    if (Number(createProjectState.budget_low) > 5000000) {
      return true;
    } else {
      return false;
    }
  };
  const checkPayRateLowHigh = () => {
    if (
      Number(createProjectState.budget_low) >=
      Number(createProjectState.budget_high)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const budgetDisable = () => {
    return checkPayRateLowHigh() || checkBudgetLowMin() || checkBudgetHighMax();
  };
  const disabled = () => {
    const conditions = [
      !createProjectState.title.length && activeProjectStep === 1,
      !createProjectState.description.length && activeProjectStep === 1,
      !createProjectState.visibility.length && activeProjectStep === 2,
      !createProjectState.start_date.length && activeProjectStep === 3,
      !createProjectState.end_date.length && activeProjectStep === 3,
      !createProjectState.document.title.length && activeProjectStep2 === 3,
      !createProjectState.document.description.length &&
        activeProjectStep2 === 3,
      !requiredDocument.length && activeProjectStep2 === 3,
      selectedProjectDetailsConfirm.length !== 3 && activeProjectStep3 === 3,
    ];
    return conditions.some(Boolean);
  };

  return (
    <>
      <StepComponent
        steps={[progress, progress2, progress3]}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        isActiveStep={activeProjectStep === 1}
        isShowSubmitBtn={
          activeProjectStep === 3 &&
          activeProjectStep2 === 3 &&
          activeProjectStep3 === 3
        }
        isDisabled={disabled() || budgetDisable() || loading}
        isLoading={loading}
      />
    </>
  );
};

export default CreateProjectStepper;

export const getStepCondition = (type) => {
  return 6;
};
