import { Box, Tab, Tabs, TabsProps } from "@mui/material";

interface AtsTabsProps extends TabsProps {
  tabData: string[];
}

const AtsTabs = ({ tabData, ...rest }: AtsTabsProps) => {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          {...rest}
          sx={{
            "& .Mui-selected": {
              color: "var(--primary) !important",
              fontWeight: 600,
            },
            "& .MuiTabs-indicator": {
              height: "3px",
              borderRadius: "10px",
              bgcolor: "var(--primary)",
            },
          }}
        >
          {tabData.map((tab) => (
            <Tab key={tab} label={tab} sx={{ textTransform: "capitalize" }} />
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default AtsTabs;
