import { Box, Skeleton, Typography, TypographyProps } from "@mui/material";
import { BORDERS, BORDERS_2X, COLORS } from "../theme";
import FontUtil from "../utils/FontUtil";

interface ITextProps extends TypographyProps {
  text: string | number | any;
  loading?: boolean;
  isUnderline?: boolean;
  isLink?: boolean;
  isCursor?: boolean;
  isUpperCase?: boolean;
  isMandatory?: boolean;
  isCapitalize?: boolean;
  isTextEnd?: boolean;
  fw?: string | number;
  boldFw?: string | number;
  fs?: string | number;
  noTextWrap?: boolean;
  lineHeight?: string | number;
  textColor?: string;
  boldText?: string;
  children?: JSX.Element;
}

const AtsTitleText = ({
  text,
  loading,
  fw = 500,
  fs = 14,
  textColor = COLORS.BLACK,
  isCursor,
  isTextEnd,
  isUnderline,
  isUpperCase,
  isCapitalize,
  isLink,
  lineHeight,
  children,
  boldFw,
  boldText,
  noTextWrap,
  isMandatory,
  ...rest
}: ITextProps) => {
  const {
    font10,
    font11,
    font12,
    font13,
    font14,
    font16,
    font17,
    font18,
    font20,
    font22,
    font24,
    font30,
    font34,
    font36,
    font48,
    font56,
  } = FontUtil();

  const getFont = (fs: string | number) => {
    if (typeof fs === "string") {
      return { fontSize: fs };
    } else {
      switch (fs) {
        case 10:
          return font10;
        case 11:
          return font11;
        case 12:
          return font12;
        case 13:
          return font13;
        case 14:
          return font14;
        case 16:
          return font16;
        case 17:
          return font17;
        case 18:
          return font18;
        case 20:
          return font20;
        case 22:
          return font22;
        case 24:
          return font24;
        case 30:
          return font30;
        case 34:
          return font34;
        case 36:
          return font36;
        case 48:
          return font48;
        case 56:
          return font56;
        default:
          return font14;
      }
    }
  };

  return (
    <Typography
      variant="h1"
      sx={{
        ...getFont(fs),
        fontWeight: fw,
        color: textColor,
        ...(isCursor && { cursor: "pointer" }),
        ...(isTextEnd && { textAlign: "end" }),
        ...(isUpperCase && { textTransform: "uppercase" }),
        ...(isCapitalize && { textTransform: "capitalize" }),
        ...(lineHeight && { lineHeight }),
        ...(noTextWrap && { textWrap: "nowrap" }),
      }}
      {...rest}
    >
      {loading ? <Skeleton variant="rounded" width={100} /> : text}
      {boldText && <span style={{ fontWeight: boldFw }}>{boldText}</span>}
      {isMandatory && <span style={{ color: "red" }}>*</span>}
      {isUnderline && (
        <Box
          sx={{
            borderBottom: isLink ? BORDERS.PRIMARY : BORDERS.DARK_GRAY,
            pt: 0.2,
          }}
        ></Box>
      )}
      {children && children}
    </Typography>
  );
};

export default AtsTitleText;
