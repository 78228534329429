import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface WebSocketState {
  isConnected: boolean;
  webSocket: WebSocket | null;
}

const initialState: WebSocketState = {
  isConnected: false,
  webSocket: null,
};

const webSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    SOCKET_CONNECT(state, action: PayloadAction<{ event: any; socket: any }>) {
      state.isConnected = true;
      state.webSocket = action.payload.socket;
    },
    SOCKET_DISCONNECT(state) {
      state.isConnected = false;
      state.webSocket = null;
    },
    // Additional WebSocket-related actions can go here
  },
});

export const { SOCKET_CONNECT, SOCKET_DISCONNECT } = webSocketSlice.actions;
export const getWebSocketState = (state: RootState) => state.webSocket;
export default webSocketSlice.reducer;
