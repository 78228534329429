import React from "react";
import AtsDataTable from "../../../../components/AtsDataTable";
import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsTitleText from "../../../../components/AtsTitleText";
import { FilterIcon } from "../../../../assets/icons/Icons";
import { IColumnProps } from "../../../../types/commonType";
import { BORDERS, COLORS } from "../../../../theme";
import { isNotNullOrUndefined } from "../../../../utils/stringUtils";
import AtsRow from "../../../../components/AtsRow";
import AtsRowBetween from "../../../../components/AtsRowBetween";
import AtsUserNameTextAvatar from "../../../../components/AtsUserNameTextAvatar";

const dummyData = [
  {
    candidate_id: 1,
    first_name: "Alex",
    last_name: "Alex",
    title: "Sr. Java Developer",
    latest_activity: "Cisco Tech - UX Research",
    latest_activity_time: "04:00:00",
    total_tracked: "47:00:00",
  },
  {
    candidate_id: 2,
    first_name: "Aayush",
    last_name: "Mittal",
    title: "Sr. Java Developer",
    latest_activity: "A12 Project - UX Research",
    latest_activity_time: "04:00:00",
    total_tracked: "47:00:00",
  },
  {
    candidate_id: 1,
    first_name: "Shailesh",
    last_name: "Patel",
    title: "Sr. Java Developer",
    latest_activity: "Pi Project - Graphic Design",
    latest_activity_time: "04:00:00",
    total_tracked: "47:00:00",
  },
];

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Contractor Nmae",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "latest_activity",
    header: "Last Activity",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "total_tracked",
    header: "Total Tracked",
    width: "auto",
    minWidth: "200px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const ContractorActivitiesList = () => {
  const customizeRow = (column: IColumnProps, contractor, index: number) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  padding: 10,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                  <Stack>
                    <Stack>
                      {isNotNullOrUndefined(contractor.image) ? (
                        <img
                          src={contractor.image}
                          alt=""
                          width={58}
                          height={58}
                          style={{
                            borderRadius: "50%",
                            marginRight: "12px",
                          }}
                        />
                      ) : (
                        <Stack
                          sx={{
                            mr: "12px",
                            width: 58,
                            height: 58,
                          }}
                        >
                          <AtsUserNameTextAvatar
                            name={`${contractor.first_name} ${contractor.last_name}`}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Stack gap={1}>
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      textColor="#000"
                      text={`${contractor.first_name} ${contractor.last_name}`}
                    />

                    <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={contractor?.title ?? ""}
                    />
                    {/* <AtsTitleText
                      fs={12}
                      fw={500}
                      text={contractor?.team ?? "Java Team"}
                    /> */}
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </td>
        );
      case "latest_activity":
        return (
          <td
            style={{ ...cellStyles, borderRight: BORDERS.GRAY }}
            key={column.key}
          >
            <AtsRowBetween gap={1}>
              <>
                <Stack gap={"3px"}>
                  <AtsTitleText
                    fs={10}
                    fw={400}
                    textColor={COLORS.LIGHT_GRAY}
                    text={`(no description)`}
                  />
                  <AtsRow gap={"5px"}>
                    <>
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          bgcolor: "#60A5FA",
                        }}
                      ></Box>
                      <AtsTitleText
                        fs={12}
                        fw={400}
                        text={contractor.latest_activity}
                      />
                    </>
                  </AtsRow>
                </Stack>
                <AtsTitleText
                  fs={14}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                  text={contractor.latest_activity_time}
                />
              </>
            </AtsRowBetween>
          </td>
        );
      case "total_tracked":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsRow gap={"10px"}>
              <>
                <AtsTitleText
                  fs={14}
                  fw={500}
                  text={contractor.total_tracked}
                />
                <Tooltip title="This is a step bar tooltip">
                  <Button
                    sx={{
                      padding: "0px",
                      width: "100%",
                    }}
                  >
                    <AtsRow
                      sx={{
                        height: 16,
                        flexGrow: 1,
                        bgcolor: "whitesmoke",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <>
                        <Box
                          sx={{
                            width: "30%",
                            bgcolor: "#2563EB",
                            height: "100%",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "30%",
                            bgcolor: "#818CF8",
                            height: "100%",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "20%",
                            bgcolor: "#38BDF8",
                            height: "100%",
                          }}
                        ></Box>
                      </>
                    </AtsRow>
                  </Button>
                </Tooltip>
              </>
            </AtsRow>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = dummyData.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: "pointer",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) =>
              customizeRow(val, value, index)
            )}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px 15px 10px",
            textAlign: "start",
            ...(column.key === "latest_activity" && {
              borderRight: BORDERS.GRAY,
            }),
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <AtsDataTable
        isTopBorder
        isNotStickyHeader
        data={dummyData}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={false}
      />
    </>
  );
};

export default ContractorActivitiesList;
