import React, { useEffect, useState } from "react";
import { useClickOutSide } from "../../../../../../../../../utils/common";
import { Box, MenuItem, Skeleton, Stack } from "@mui/material";
import {
  isNotNullOrUndefined,
  toLowerCase,
} from "../../../../../../../../../utils/stringUtils";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import AtsCheckbox from "../../../../../../../../../components/AtsCheckbox";
import { OptionProps } from "../../../../../../../../../types/commonType";
import { ProjectService } from "../../../../../../../../../Api/projectServices";
import { TeamMemberProps } from "../../../../../../../../../types/projectTypes";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import { getAuthState } from "../../../../../../../../../redux/slices/authSlice";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { useInterviews } from "../../../../../../../../../providers/InterviewsProvider";
import {
  getInterviewState,
  setInterviewer,
} from "../../../../../../../../../redux/slices/interviewSlice";

interface SelectedInterviewerProps {
  selected: string[];
  onChange: (selected: string) => void;
  placeHolder?: string;
}

const SelectInterviewer = ({
  selected,
  onChange,
  placeHolder = "Select Interviewer",
}: SelectedInterviewerProps) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const { interviewer } = useAppSelector(getInterviewState);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== interviewer.length) {
        setPage(page + 1);
      }
    }
  };

  const getInterviewerList = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectMember({ page });
      if (response && response.results?.length) {
        const agentOptionList = response.results.map(
          (agent: TeamMemberProps) => {
            return {
              value: agent.email,
              label: `${agent?.first_name ?? ""} ${agent?.last_name ?? ""}`,
            };
          }
        );
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          dispatch(setInterviewer([...agentOptionList]));
        } else {
          dispatch(setInterviewer([...interviewer, ...agentOptionList]));
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getList = setTimeout(() => {
      getInterviewerList();
    }, 400);
    return () => clearTimeout(getList);
  }, [page]);

  const onHandleChange = (selectValue) => {
    onChange(selectValue);
  };
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <>
      <div ref={domNode} style={{ position: "relative" }}>
        <Stack
          sx={{
            borderRadius: "8px",
            border: BORDERS.GRAY,
            pl: 2,
            pr: 1,
            py: "2px",
            transition: "all 0.3s ease-in-out",
            height: "auto",
            cursor: "pointer",
          }}
          onClick={() => setShow(!show)}
        >
          <Stack
            direction={"row"}
            minHeight={32}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              gap={1}
              sx={{
                flexWrap: "wrap",
                py: 0.5,
                // ...(selected?.length > 4 && { flexWrap: 'wrap', py: 1 }),
              }}
            >
              {selected?.length ? (
                selected.map((value: string, index: number) => {
                  const interviewerFind = interviewer.find(
                    (item) => item.value === value
                  );
                  return (
                    <AtsRoundedButton
                      text={
                        isNotNullOrUndefined(interviewerFind)
                          ? interviewerFind?.label
                          : `${userInfo?.first_name ?? ""} ${
                              userInfo?.last_name ?? ""
                            }(You)`
                      }
                      buttonVariant="primaryContained"
                      buttonHeight={27}
                      fs={12}
                      fw={600}
                    />
                  );
                })
              ) : (
                <AtsTitleText text={placeHolder} color={COLORS.DARK_GRAY} />
              )}
            </Stack>

            <KeyboardArrowDown
              onClick={() => setShow(!show)}
              sx={{
                fontSize: "1rem",
                color: "rgba(0, 0, 0, 0.54)",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",

                ...(show ? { rotate: "180deg" } : ""),
              }}
            />
          </Stack>
        </Stack>

        <Box
          sx={{
            px: "10px",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "10px",
            }}
            in={show}
          >
            {loading ? (
              <Stack gap={"5px"}>
                {Array.from(new Array(5)).map((_, index) => (
                  <Skeleton variant="rectangular" />
                ))}
              </Stack>
            ) : (
              <Box sx={{ py: 1 }}>
                {/* {handleChange && (
                <Box
                  sx={{
                    px: 1,
                  }}
                >
                  {' '}
                  <input
                    className="VegaInput"
                    placeholder="search..."
                    type="text"
                    style={{
                      height: '35px',
                      width: '100%',
                      borderRadius: '9px',
                      border: '1px solid #C5C8D0',
                      paddingLeft: '10px',
                    }}
                    value={searchValue}
                    onHandleChange={handleChange}
                  />
                </Box>
              )} */}
                <div
                  onScroll={loadMoreItems}
                  style={{
                    maxHeight: 200,
                    padding: "10px",
                    overflow: "auto",
                  }}
                  className=""
                >
                  {(interviewer?.length ?? 0) > 0 ? (
                    interviewer?.map((option, index) => {
                      const isChecked = selected?.length
                        ? selected?.includes(option.value)
                        : false;
                      return (
                        <MenuItem
                          key={index}
                          value={option.value}
                          onClick={() => onHandleChange(option.value)}
                          sx={{
                            p: 0,
                          }}
                        >
                          <AtsRow direction={"row"} gap={2}>
                            <>
                              <AtsCheckbox checked={isChecked} />

                              <AtsTitleText text={option.label} />
                            </>
                          </AtsRow>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <AtsTitleText text={"No Data"} />
                  )}
                </div>
              </Box>
            )}
          </Collapse>
        </Box>
      </div>
    </>
  );
};

export default SelectInterviewer;
