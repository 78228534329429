import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "../../../assets/icons/Icons";
import AtsText from "../../../components/AtsText";
import { BORDERS, COLORS } from "../../../theme";
import { useClickOutSide } from "../../../utils/common";
import { disableWeekends } from "../../../utils/dateUtils";
import FontUtil from "../../../utils/FontUtil";

interface InterviewDatePickerProps {
  selectedDate: Dayjs;
  setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}
const InterviewDatePicker = ({
  selectedDate,
  setSelectedDate,
}: InterviewDatePickerProps) => {
  const { font12, font14 } = FontUtil();
  const [show, setShow] = useState(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const prevDate = () => {
    setSelectedDate((prev) => {
      let newDate = prev.subtract(1, "day");
      while (newDate.day() === 6 || newDate.day() === 0) {
        newDate = newDate.subtract(1, "day");
      }
      return newDate;
    });
  };

  const nextDate = () => {
    setSelectedDate((prev) => {
      let newDate = prev.add(1, "day");
      while (newDate.day() === 6 || newDate.day() === 0) {
        newDate = newDate.add(1, "day");
      }
      return newDate;
    });
  };

  const handleDateChange = (date: Dayjs) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const ExampleCustomInput = () => (
    <div ref={domNode}>
      <Stack
        sx={{ position: "relative", minHeight: "30px" }}
        direction={"row"}
        alignItems={"center"}
      >
        <IconButton
          sx={{
            cursor: "pointer",
            py: 0,
          }}
          onClick={prevDate}
        >
          <KeyboardArrowLeft />
        </IconButton>

        <Stack
          gap={1}
          direction={"row"}
          alignItems={"center"}
          sx={{ minWidth: 180 }}
        >
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setShow(!show)}
          >
            <CalendarIcon />
          </Box>
          <AtsText
            text={selectedDate.format("dddd, MMMM D, YYYY")}
            sx={{
              ...font12,
              fontWeight: 500,
              color: COLORS.DARK_GRAY,
              lineHeight: 0,
            }}
          />
        </Stack>
        <IconButton
          sx={{
            cursor: "pointer",
            py: 0,
            pl: 0,
          }}
          onClick={nextDate}
        >
          <KeyboardArrowRight />
        </IconButton>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            right: "0px",
            top: "50px",
            zIndex: 9999,
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "6px",
            }}
            in={show}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                sx={{
                  margin: 0,
                  ".MuiPickersCalendarHeader-root": {
                    marginTop: 0,
                    borderBottom: BORDERS.GRAY,
                  },
                  ".Mui-selected": {
                    color: "#3366cc !important",
                    bgcolor: "#e2ebff !important",
                    fontWeight: 600,
                  },
                  ".MuiPickersCalendarHeader-label": {
                    ...font14,
                    color: COLORS.BLACK,
                  },
                }}
                value={selectedDate}
                onChange={handleDateChange}
                minDate={dayjs(new Date())}
                shouldDisableDate={disableWeekends}
              />
            </LocalizationProvider>
          </Collapse>
        </Box>
      </Stack>
    </div>
  );

  return (
    <>
      <Box
        sx={{
          bgcolor: "#dcdfe7",
          borderRadius: "20px",
          position: "relative",
        }}
      >
        <ExampleCustomInput />
      </Box>
    </>
  );
};

export default InterviewDatePicker;
