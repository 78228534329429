import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { JobsService } from "../../Api/jobsListingServices";
import StepWrapper from "../../components/common/StepWrapper";
import {
  getPostOpportunityState,
  initialPostOpportunity,
  setActiveStep,
  setActiveStep2,
  setActiveStep3,
  setIsPosted,
  setIsQuestionRoute,
  setJobDetailsById,
  setPostOpportunity,
  setSelectedConfirm,
} from "../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ATS_ROUTES } from "../../router/routes";
import "./css/PostOpportunity.css";
import AlmostThere from "./module/AlmostThere";
import ChooseIndustry from "./module/ChooseIndustry";
import ChooseJobTitle from "./module/ChooseJobTitle";
import DaysPerWeek from "./module/DaysPerWeek";
import EducationalRequrement from "./module/EducationalRequrement";
import HoursPerDay from "./module/HoursPerDay";
import HoursPerWeek from "./module/HoursPerWeek";
import JobBenefits from "./module/JobBenefits";
import JobDescribes from "./module/JobDescribes";
import JobDescription from "./module/JobDescription";
import JobLocation from "./module/JobLocation";
import JobPriority from "./module/JobPriority";
import JobResourceTime from "./module/JobResourceTime";
import JobSkills from "./module/JobSkills";
import JobStartEndDate from "./module/JobStartEndDate";
import JobStep1 from "./module/JobStep1";
import JobStep2 from "./module/JobStep2";
import JobStep3 from "./module/JobStep3";
import JobTerm from "./module/JobTerm";
import JobType from "./module/JobType";
import OfficeTime from "./module/OfficeTime";
import OpportunityPosted from "./module/OpportunityPosted";
import Projects from "./module/Projects";
import Question from "./module/Question";
import ReviewListing from "./module/ReviewListing";
import Stepper from "./module/Stepper";
import VicePresidentBusinessDevlopment from "./module/VicePresidentBusinessDevlopment";
import WorkMode from "./module/WorkMode";
import YearsOfExperience from "./module/YearsOfExperience";
import YourBudget from "./module/YourBudget";
import { JobDetailsResponse } from "../../types/jobsTypes";
import { isNotNullOrUndefined } from "../../utils/stringUtils";

const PostOpportunity = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    activeStep,
    activeStep2,
    activeStep3,
    isPosted,
    isQuestionRoute,
    jobDetailsById,
  } = useAppSelector(getPostOpportunityState);
  const [loading, setLoading] = useState(false);

  const JobLocationMemo = useMemo(() => <JobLocation />, []);
  const OfficeTimeMemo = useMemo(() => <OfficeTime />, []);
  const WorkModeMemo = useMemo(() => <WorkMode />, []);
  const JobPriorityMemo = useMemo(() => <JobPriority />, []);
  const JobResourceTimeMemo = useMemo(() => <JobResourceTime />, []);
  const JobTermMemo = useMemo(() => <JobTerm />, []);
  const JobTypeMemo = useMemo(() => <JobType />, []);
  const JobDescribesMemo = useMemo(() => <JobDescribes />, []);
  const JobStep1Memo = useMemo(() => <JobStep1 />, []);
  const ChooseIndustryMemo = useMemo(() => <ChooseIndustry />, []);
  const JobSkillsMemo = useMemo(() => <JobSkills />, []);
  const JobDescriptionMemo = useMemo(() => <JobDescription />, []);
  const ChooseJobTitleMemo = useMemo(() => <ChooseJobTitle />, []);
  const JobStep2Memo = useMemo(() => <JobStep2 />, []);
  const ProjectsMemo = useMemo(() => <Projects />, []);
  const VicePresidentBusinessDevlopmentMemo = useMemo(
    () => <VicePresidentBusinessDevlopment />,
    []
  );
  const EducationalMemo = useMemo(() => <EducationalRequrement />, []);
  const YearsOfExperienceMemo = useMemo(() => <YearsOfExperience />, []);
  const ReviewListingMemo = useMemo(() => <ReviewListing />, []);
  const AlmostThereMemo = useMemo(() => <AlmostThere />, []);
  const JobBenefitsMemo = useMemo(() => <JobBenefits />, []);
  const YourBudgetMemo = useMemo(() => <YourBudget />, []);
  const HoursPerDayMemo = useMemo(() => <HoursPerDay />, []);
  const DaysPerWeekMemo = useMemo(() => <DaysPerWeek />, []);
  const HoursPerWeekMemo = useMemo(() => <HoursPerWeek />, []);
  const JobStep3Memo = useMemo(() => <JobStep3 />, []);
  const JobStartEndDateMemo = useMemo(() => <JobStartEndDate />, []);

  const getRemoveHtmlTag = (html: string) => {
    const removeTags = html.replace(/<\/?[^>]+(>|$)/g, "");

    let decoded = removeTags;
    const tempElement = document.createElement("div");
    let previousDecoded;

    do {
      previousDecoded = decoded;
      tempElement.innerHTML = decoded;
      decoded = tempElement.textContent || tempElement.innerText;
    } while (decoded !== previousDecoded); // Repeat until fully decoded

    return decoded.trim();
  };

  const getJobDetails = async (nid: string) => {
    setLoading(true);

    try {
      const response = await JobsService.getJobDetailsById({ nid });
      if (response.results.length) {
        const jobDetails = response.results[0];
        const job_what_you_ll_do = isNotNullOrUndefined(
          jobDetails?.what_you_will_do
        )
          ? jobDetails?.what_you_will_do?.length
            ? getRemoveHtmlTag(jobDetails?.what_you_will_do)
            : ""
          : "";
        console.log("job_what_you_ll_do", job_what_you_ll_do);
        const initialPostOpportunity = {
          nid: jobDetails.nid,
          job_category_id: jobDetails?.job_category_id ?? "",
          job_title: jobDetails?.title ?? "",
          job_decription: isNotNullOrUndefined(
            jobDetails?.published_description
          )
            ? jobDetails?.published_description?.length
              ? getRemoveHtmlTag(jobDetails?.published_description)
              : ""
            : "",
          job_type_id: jobDetails?.job_type_id ?? "",
          job_often: jobDetails?.job_often?.length
            ? [jobDetails?.job_often]
            : [],
          job_contract_type_id: jobDetails?.job_contract_type_id ?? "",
          job_period_of_time_id: "",
          job_priority_id: jobDetails?.job_priority_id ?? "",
          job_work_mode_id: jobDetails?.job_work_mode_id ?? "",
          job_address: {
            country: jobDetails?.job_address_country ?? "",
            administrative_area:
              jobDetails?.job_address_administrative_area ?? "",
            locality: jobDetails?.job_address_locality ?? "",
            postal_code: jobDetails?.job_address_postal_code ?? "",
            address_line1: jobDetails?.job_address_address_line1 ?? "",
            country_code: "",
          },
          job_what_you_ll_do,
          job_require_skills: jobDetails?.required_skills ?? [],
          job_industry: jobDetails?.job_industry ?? "",
          job_hours_per_week_id: jobDetails?.job_hours_per_week_id ?? "",
          job_days_per_week_id: "",
          job_start_date: jobDetails?.job_start_date ?? "",
          job_end_date: jobDetails?.job_end_date ?? "",
          job_hours_per_day: jobDetails?.hours_per_day ?? "0",
          job_payment_type_id: jobDetails?.job_payment_type_id ?? "",
          job_pay_rate_high: jobDetails?.pay_rate_high ?? "50",
          job_pay_rate_low: jobDetails?.pay_rate_low ?? "1",
          job_salary_high: jobDetails?.salary_high ?? "100000",
          job_salary_low: jobDetails?.salary_low ?? "100",
          job_benefits_id:
            jobDetails?.job_benefits_id && jobDetails?.job_benefits_id.length
              ? jobDetails?.job_benefits_id.split(", ")
              : [],
          job_check:
            jobDetails?.job_check && jobDetails?.job_check.length
              ? jobDetails?.job_check.split(", ")
              : [],
          job_education_requirements_id:
            jobDetails?.education_requirements ?? "",
          job_years_experience_id: jobDetails?.job_years_experience_id ?? "",
          job_project_id: jobDetails?.project_id ?? "",
        };
        const details = {
          ...jobDetailsById,
          job_type: jobDetails?.job_type ?? "",
          work_mode: jobDetails?.work_mode ?? "",
          title: jobDetails?.title ?? "",
        } as JobDetailsResponse;
        dispatch(setPostOpportunity(initialPostOpportunity));
        dispatch(setJobDetailsById(details));
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setPostOpportunity(initialPostOpportunity));
    dispatch(setIsPosted(false));
    dispatch(setIsQuestionRoute(false));
    dispatch(setSelectedConfirm([]));
    dispatch(setActiveStep(1));
    dispatch(setActiveStep2(0));
    dispatch(setActiveStep3(0));
    if (jobId) {
      getJobDetails(jobId);
    }
  }, [jobId]);

  // const memoMap = {
  //   "Direct hire": {
  //     1: JobStep1Memo,
  //     2: JobDescribesMemo,
  //     3: JobTypeMemo,
  //     4: JobTermMemo,
  //     5: JobPriorityMemo,
  //     6: WorkModeMemo,
  //     7: OfficeTimeMemo,
  //     8: JobLocationMemo,
  //   },
  //   default: {
  //     1: JobStep1Memo,
  //     2: JobDescribesMemo,
  //     3: JobTypeMemo,
  //     4: JobTermMemo,
  //     5: JobResourceTimeMemo,
  //     6: HoursPerWeekMemo,
  //     7: DaysPerWeekMemo,
  //     8: HoursPerDayMemo,
  //     9: JobPriorityMemo,
  //     10: WorkModeMemo,
  //     11: OfficeTimeMemo,
  //     12: JobLocationMemo,
  //   },
  // };

  const memoMap = {
    Hybrid: {
      1: JobStep1Memo,
      2: ProjectsMemo,
      3: JobDescribesMemo,
      4: JobTypeMemo,
      5:
        jobDetailsById.job_type !== "Direct hire"
          ? HoursPerWeekMemo
          : JobPriorityMemo,
      6: jobDetailsById.job_type !== "Direct hire" ? JobPriorityMemo : null,
      7: WorkModeMemo,
      8: OfficeTimeMemo,
      9: JobLocationMemo,
    },
    default: {
      1: JobStep1Memo,
      2: ProjectsMemo,
      3: JobDescribesMemo,
      4: JobTypeMemo,
      5:
        jobDetailsById.job_type !== "Direct hire"
          ? HoursPerWeekMemo
          : JobPriorityMemo,
      6: jobDetailsById.job_type !== "Direct hire" ? JobPriorityMemo : null,
      7: WorkModeMemo,
      8: JobLocationMemo,
    },
  };

  const step2Map = {
    1: JobStep2Memo,
    2: ChooseJobTitleMemo,
    3: JobDescriptionMemo,
    4: JobSkillsMemo,
    5: ChooseIndustryMemo,
    6: JobStartEndDateMemo,
  };

  const step3Map = {
    1: JobStep3Memo,
    2: EducationalMemo,
    3: YearsOfExperienceMemo,
    4: YourBudgetMemo,
    5: JobBenefitsMemo,
    6: AlmostThereMemo,
    7: ReviewListingMemo,
    8: VicePresidentBusinessDevlopmentMemo,
    // 9: ProjectsMemo,
  };

  const renderComponent = () => {
    const stepsMap =
      jobDetailsById.work_mode === "Hybrid"
        ? memoMap["Hybrid"]
        : memoMap.default;

    const findNextValidStep = (map, step) => {
      while (map[step] === null) {
        step++;
      }
      return map[step];
    };
    if (activeStep <= getStep1(jobDetailsById.work_mode) && activeStep2 === 0) {
      return findNextValidStep(stepsMap, activeStep) || JobStep1Memo;
    } else if (
      activeStep === getStep1(jobDetailsById.work_mode) &&
      activeStep2 <= 6 &&
      activeStep3 === 0
    ) {
      return step2Map[activeStep2] || JobStep2Memo;
    } else if (activeStep2 === 6 && activeStep3 <= 8) {
      return step3Map[activeStep3] || JobStep3Memo;
    }
  };

  return (
    <StepWrapper
      headerText="Post an Opportunity"
      backText="Back to jobs"
      backRoute={() => navigate(ATS_ROUTES.JOBS)}
      questionAction={() => dispatch(setIsQuestionRoute(true))}
      isQuestionRoute={isQuestionRoute}
      isFinalStep={isPosted}
      QuestionComponent={Question}
      FinalStepComponent={OpportunityPosted}
      StepperComponent={Stepper}
      renderStepComponent={renderComponent}
      isImgShow={activeStep !== 1 && activeStep2 !== 1 && activeStep3 !== 1}
    />
  );
};

export default PostOpportunity;

export const getStep1 = (work_mode: string) => {
  return work_mode === "Hybrid" ? 9 : 8;
};
