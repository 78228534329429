import { useEffect, useState } from "react";
import { ProjectService } from "../../../../Api/projectServices";
import AtsTitleText from "../../../../components/AtsTitleText";
import { TeamMemberProps } from "../../../../types/projectTypes";
import { isNotNullOrUndefined } from "../../../../utils/stringUtils";
import { Skeleton } from "@mui/material";
import AtsUserNameTextAvatar from "../../../../components/AtsUserNameTextAvatar";

interface IProps {
  project_id: string;
}

const UserImageForProject = ({ project_id }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [activeProjectMember, setActiveProjectMember] = useState<
    TeamMemberProps[]
  >([]);

  const getImages = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getActiveProjectMember({
        project_id,
      });
      setActiveProjectMember(response.results);
      const count =
        typeof response.pager.count === "string"
          ? Number(response.pager.count)
          : response.pager.count;
      setTotalItem(count);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <div className="avatar-group">
        {loading
          ? Array.from(new Array(2)).map((_, index) => (
              <div key={index} className="avatar">
                <Skeleton
                  variant="circular"
                  sx={{
                    width: 34,
                    height: 34,
                  }}
                />
              </div>
            ))
          : activeProjectMember.length > 0 &&
            activeProjectMember.slice(0, 4).map((item, index) => {
              return (
                <div key={item.uid} className="avatar">
                  {isNotNullOrUndefined(item.image) ? (
                    <img src={item.image} alt={`Image1${index}`} />
                  ) : (
                    <div className="plan">
                      <AtsUserNameTextAvatar
                        fs={12}
                        name={`${item.first_name} ${item.last_name}`}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        {activeProjectMember.length > 4 && (
          <div className="avatar">
            <div className="plan">
              <AtsTitleText
                text={`+ ${totalItem - 4}`}
                textColor="#000"
                fs={12}
                fw={600}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserImageForProject;
