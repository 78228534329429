import { Stack, Tab, Tabs } from "@mui/material";
import { useMemo } from "react";
import {
  getCandidateState,
  setCondidateTabValue,
} from "../../../../../../redux/slices/candidateSlice";
import {
  setInterviewTab,
  setIsInterviewDetails,
  setNextTab,
  TabSelect,
} from "../../../../../../redux/slices/interviewSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { COLORS } from "../../../../../../theme";
import DetailsTab from "./CandidateDetailsTabs/DetailTab/DetailsTab";
import InterviewTab from "./CandidateDetailsTabs/InterViewTab/InterviewTab";
import MakeAnOfferTab from "./CandidateDetailsTabs/MakeAnOfferTab/MakeAnOfferTab";
import Resume from "./CandidateDetailsTabs/ResumeTab/Resume";
import ReviewTab from "./CandidateDetailsTabs/ReviewTab/ReviewTab";
import AssessmentsTab from "./CandidateDetailsTabs/AssessmentsTab/AssessmentsTab";
import { InterviewsProvider } from "../../../../../../providers/InterviewsProvider";

const TABS = [
  { title: "Details" },
  { title: "Resume" },
  { title: "Assessment" },
  { title: "Reviews" },
  { title: "Interviews" },
  { title: "Offers" },
];

const CandidateFullDetails = () => {
  const dispatch = useAppDispatch();
  const { condidateTabValue } = useAppSelector(getCandidateState);
  const handleCondidateTabChange = (event, newValue) => {
    dispatch(setCondidateTabValue(newValue));
    dispatch(setNextTab(TabSelect.ATENDEE_DETAILS));
    dispatch(setInterviewTab(0));
    dispatch(setIsInterviewDetails(false));
  };

  const makeAnOfferMemo = useMemo(() => <MakeAnOfferTab />, []);
  const DetailsTabMemo = useMemo(() => <DetailsTab />, []);
  const ResumeMemo = useMemo(() => <Resume />, []);
  const ReviewTabMemo = useMemo(() => <ReviewTab />, []);
  const InterviewTabMemo = useMemo(
    () => (
      <InterviewsProvider>
        <InterviewTab />
      </InterviewsProvider>
    ),
    []
  );
  const AssessmentsTabMemo = useMemo(() => <AssessmentsTab />, []);
  return (
    <>
      <Stack
        sx={{
          height: "calc(100% - 260px)",
          p: "30px",
        }}
      >
        <Stack direction={"row"} height={"100%"} gap={"50px"}>
          <Stack
            sx={{
              minWidth: "170px",
              maxHeight: "299px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={condidateTabValue}
              onChange={handleCondidateTabChange}
              aria-label="Vertical tabs example"
              sx={{
                ".MuiTab-root": {
                  textAlign: "left",
                  alignItems: "flex-start",
                  pl: 3,
                  textTransform: "capitalize",
                },
                ".Mui-selected": {
                  color: "#3366cc !important",
                },
                ".MuiTabs-indicator": {
                  left: 0,
                  height: "35px !important",
                  width: "5px",
                  backgroundColor: COLORS.PRIMARY,
                  borderRadius: "10px",
                  marginTop: "7px",
                },
              }}
              TabIndicatorProps={{
                sx: {
                  left: 0,
                  width: "5px",
                },
              }}
            >
              {TABS.map((tab, index) => (
                <Tab key={index} label={tab.title} />
              ))}
            </Tabs>
          </Stack>
          <Stack
            className="scrollHide"
            style={{
              overflow: "auto",
              height: "100%",
              flex: 1,
            }}
          >
            {condidateTabValue === 0
              ? DetailsTabMemo
              : condidateTabValue === 1
              ? ResumeMemo
              : condidateTabValue === 3
              ? ReviewTabMemo
              : condidateTabValue === 4
              ? InterviewTabMemo
              : condidateTabValue === 5
              ? makeAnOfferMemo
              : AssessmentsTabMemo}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default CandidateFullDetails;
