import React, { useState } from "react";
import OnboardingWrapper from "./OnboardingWrapper";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import FormWrapper from "./FormWrapper";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import AtsTextInput from "../../../../../../../../../components/AtsTextInput";
import UploadInput from "./UploadInput";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { OnboardingServices } from "../../../../../../../../../Api/onboardingServices";
import {
  ErrorState,
  FileItem,
} from "../../../../../../../../../types/commonType";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import { CreateUpdateOnboardingRequest } from "../../../../../../../../../types/onboardingTypes";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsLabel from "../../../../../../../../../components/AtsLabel";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { Edit2 } from "iconsax-react";

const SetupItEquipment = () => {
  const {
    setDeviceSetupFiles,
    deviceSetupFiles,
    setCreateOnboardingState,
    createOnboardingState,
    editState,
    handleCreateOnboardingState,
    handleEditState,
  } = useOnboarding();
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileLength, setFileLength] = useState<number>(0);
  const { isContractorOnboarded } = useAppSelector(getContractorDetailsState);

  const handleBinaryUploadForLogo = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await OnboardingServices.uploadDeviceSetupFiles(
            arrayBuffer,
            file.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles) => {
    setFileLoading(true);
    try {
      const fileArray = Array.from(selectedFiles) as File[];
      setFileLength(fileArray?.length);
      const uploadChooseProjectImgPromises = fileArray.map(
        handleBinaryUploadForLogo
      );
      const resultsForProjectImg = await Promise.all(
        uploadChooseProjectImgPromises
      );

      const result = resultsForProjectImg.map((item: FileItem) => {
        const fidValue = item?.fid[0]?.value;
        const fileNameValue = item?.uri[0]?.url;
        const fileName = item?.filename[0]?.value;
        return fidValue && fileNameValue && fileName
          ? { fid: fidValue, url: fileNameValue, fileName }
          : null;
      });
      setDeviceSetupFiles([...deviceSetupFiles, ...result]);
      setCreateOnboardingState({
        ...createOnboardingState,
        device_setup_instruction_files: result.map((value) =>
          value.fid.toString()
        ),
      });
    } catch (error) {
      setFileLoading(false);
    } finally {
      setFileLoading(false);
    }
  };
  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDelete = (index: number) => {
    const preFiles = [...deviceSetupFiles];
    preFiles.splice(index, 1);
    setDeviceSetupFiles(preFiles);
  };

  const renderFIleComponent = (file, index) => {
    let fileName = file.fileName.substring(0, 20) + "...";

    return (
      <Stack
        direction={"row"}
        sx={{
          pl: "20px",
          py: "10px",
          pr: !editState.isEditEquipment ? "20px" : "0px",
          bgcolor: "white",
          borderRadius: "8px",
          border: BORDERS.GRAY,
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"15px"}>
          <img
            src={require("../../../../../../../../../assets/images/pdfIcon.png")}
            alt="Nvidia Logo"
            style={{ width: 21 }}
          />
          <Stack gap={"10px"}>
            <AtsTitleText
              text={fileName}
              fs={14}
              fw={500}
              textColor={COLORS.BLACK}
            />
          </Stack>
          {!isContractorOnboarded ||
            (editState.isEditEquipment && (
              <Box
                sx={{
                  borderLeft: BORDERS.GRAY,
                }}
              >
                <Box
                  sx={{
                    px: "10px",

                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AtsCustomButton
                    px={0.5}
                    py={0.5}
                    onClick={() => handleDelete(index)}
                  >
                    <AtsIcon icon="icon-trash" height="16px" />
                  </AtsCustomButton>
                </Box>
              </Box>
            ))}
        </Stack>
      </Stack>
    );
  };

  return (
    <OnboardingWrapper heading="Provide detailed information on setting up IT and Equipment">
      <Stack gap={"40px"}>
        <FormWrapper
          heading="Equipment Details"
          renderRightComponent={
            isContractorOnboarded ? (
              <AtsRow gap={"10px"}>
                <>
                  <AtsLabel
                    fs={12}
                    px={2}
                    text={"Confirmed"}
                    variant="success"
                    isCapital
                    height="25px"
                  />
                  {editState.isEditEquipment ? (
                    <AtsRoundedButton
                      text="Save"
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="darkGreenContained"
                      onClick={() => handleEditState("isEditEquipment", false)}
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Edit"
                      startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="whiteContained"
                      onClick={() => handleEditState("isEditEquipment", true)}
                    />
                  )}
                </>
              </AtsRow>
            ) : (
              <></>
            )
          }
        >
          <Stack gap={"30px"} pt={"20px"}>
            <Stack gap={"5px"}>
              <AtsTitleText
                text={"Equipments needed by contractor"}
                fs={14}
                fw={600}
              />
              {isContractorOnboarded && !editState.isEditEquipment ? (
                <AtsTitleText
                  text={createOnboardingState?.equipment_needed}
                  fs={14}
                  fw={500}
                />
              ) : (
                <AtsTextInput
                  placeholder="eg: Laptop-Dell-XPS 13 9315, Phone-Samsung-Galaxy-M34"
                  value={createOnboardingState?.equipment_needed}
                  onChange={(e) => {
                    handleCreateOnboardingState(
                      "equipment_needed",
                      e.target.value
                    );
                  }}
                />
              )}
            </Stack>
            <Stack gap={"5px"}>
              <AtsTitleText
                text={"Instructions for setting up work devices"}
                fs={14}
                fw={600}
              />
              {isContractorOnboarded && !editState.isEditEquipment ? (
                <AtsTitleText
                  text={createOnboardingState?.device_setup_instruction}
                  fs={14}
                  fw={500}
                />
              ) : (
                <UploadInput
                  keyValue="device_setup_instruction"
                  placeholder="Enter details here"
                  handleFileChange={handleFileChange}
                  value={createOnboardingState?.device_setup_instruction}
                  handleChange={(e) => {
                    handleCreateOnboardingState(
                      "device_setup_instruction",
                      e.target.value
                    );
                  }}
                />
              )}
              <Stack direction={"row"} flexWrap={"wrap"} gap={"10px"} sx={{}}>
                {fileLoading ? (
                  Array.from(new Array(fileLength)).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rounded"
                      height={50}
                      width={240}
                    />
                  ))
                ) : !deviceSetupFiles.length ? (
                  <AtsTitleText text={"No files chosen"} fs={12} />
                ) : (
                  deviceSetupFiles.map((file, index) =>
                    renderFIleComponent(file, index)
                  )
                )}
              </Stack>
            </Stack>
          </Stack>
        </FormWrapper>
        <FormWrapper
          heading="IT Support Contact Details"
          renderRightComponent={
            isContractorOnboarded ? (
              <AtsRow gap={"10px"}>
                <>
                  <AtsLabel
                    fs={12}
                    px={2}
                    text={"Confirmed"}
                    variant="success"
                    isCapital
                    height="25px"
                  />
                  {editState.isEditItSupport ? (
                    <AtsRoundedButton
                      text="Save"
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="darkGreenContained"
                      onClick={() => handleEditState("isEditItSupport", false)}
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Edit"
                      startIcon={<Edit2 size={14} color={COLORS.BLACK} />}
                      buttonHeight={25}
                      fs={12}
                      buttonVariant="whiteContained"
                      onClick={() => handleEditState("isEditItSupport", true)}
                    />
                  )}
                </>
              </AtsRow>
            ) : (
              <></>
            )
          }
        >
          <Grid
            container
            columnSpacing={"40px"}
            rowSpacing={"30px"}
            pt={"20px"}
          >
            <Grid
              item
              xs={12}
              sm={
                isContractorOnboarded ? (editState.isEditItSupport ? 6 : 4) : 6
              }
            >
              <MendotaryField
                isContractorOnboarded={isContractorOnboarded}
                isEdit={editState.isEditItSupport}
                title={"Name"}
                placeholder={"Enter name"}
                value={createOnboardingState?.it_support_contact_name}
                keyValue={"it_support_contact_name"}
                onChange={(value) =>
                  handleCreateOnboardingState("it_support_contact_name", value)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={
                isContractorOnboarded ? (editState.isEditItSupport ? 6 : 4) : 6
              }
            >
              <MendotaryField
                isContractorOnboarded={isContractorOnboarded}
                isEdit={editState.isEditItSupport}
                title={"Email"}
                placeholder={"Enter email"}
                value={createOnboardingState?.it_support_contact_email}
                keyValue={"it_support_contact_email"}
                onChange={(value) =>
                  handleCreateOnboardingState("it_support_contact_email", value)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={
                isContractorOnboarded ? (editState.isEditItSupport ? 6 : 4) : 6
              }
            >
              <MendotaryField
                isContractorOnboarded={isContractorOnboarded}
                isEdit={editState.isEditItSupport}
                title={"Phone number"}
                placeholder={"Enter phone number"}
                keyValue={"it_support_contact_phone"}
                value={createOnboardingState?.it_support_contact_phone}
                onChange={(value) =>
                  handleCreateOnboardingState("it_support_contact_phone", value)
                }
              />
            </Grid>
          </Grid>
        </FormWrapper>
      </Stack>
    </OnboardingWrapper>
  );
};

export default SetupItEquipment;

const MendotaryField = ({
  title,
  placeholder,
  value,
  keyValue,
  onChange,
  isEdit,
  isContractorOnboarded,
}: {
  title: string;
  placeholder: string;
  value: string;
  keyValue: keyof CreateUpdateOnboardingRequest;
  onChange: (value: string) => void;
  isEdit: boolean;
  isContractorOnboarded: boolean;
}) => {
  const { error } = useOnboarding();
  return (
    <Stack key={keyValue} gap={"5px"} sx={{ position: "relative" }}>
      <Typography
        sx={{
          color: COLORS.BLACK,
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        {title}{" "}
        {isContractorOnboarded ? (
          isEdit && (
            <span
              style={{
                color: "red",
              }}
            >
              *
            </span>
          )
        ) : (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        )}
      </Typography>
      {isContractorOnboarded ? (
        isEdit ? (
          <AtsTextInput
            type={keyValue === "it_support_contact_phone" ? "number" : "text"}
            key={keyValue}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        ) : (
          <AtsTitleText text={value} fs={14} fw={500} />
        )
      ) : (
        <AtsTextInput
          type={keyValue === "it_support_contact_phone" ? "number" : "text"}
          key={keyValue}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )}
      {error && (
        <Stack sx={{ position: "absolute", left: 0, bottom: -15 }}>
          <AtsTitleText
            text={error[keyValue]}
            fs={12}
            fw={500}
            textColor={COLORS.RED_LIGHT}
          />
        </Stack>
      )}
    </Stack>
  );
};
