import { useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import StepComponent from "../../../components/common/StepComponent";
import { JobTypeEnum } from "../../../enums/jobsEnums";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setActiveStep,
  setActiveStep2,
  setActiveStep3,
  setIsPosted,
  setJobDetailsById,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getStep1 } from "../PostOpportunity";
import { useParams } from "react-router-dom";

const Stepper = () => {
  const dispatch = useAppDispatch();
  const {
    activeStep,
    activeStep2,
    activeStep3,
    isDisable,
    postOpportunity,
    jobDetailsById,
    selectedConfirm,
    isEdit,
  } = useAppSelector(getPostOpportunityState);
  const { jobId } = useParams();

  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (isEdit) {
      dispatch(setActiveStep(getStep1(jobDetailsById.work_mode)));
      if (activeStep2 === 2) {
        dispatch(setActiveStep2(3));
        dispatch(setActiveStep3(0));
      } else {
        dispatch(setActiveStep2(6));
        dispatch(setActiveStep3(8));
      }
    } else {
      if (activeStep < getStep1(jobDetailsById.work_mode)) {
        dispatch(setActiveStep(activeStep + 1));
      } else if (
        activeStep === getStep1(jobDetailsById.work_mode) &&
        activeStep2 < 6
      ) {
        dispatch(setActiveStep2(activeStep2 + 1));
      } else if (activeStep2 === 6 && activeStep3 < 9) {
        dispatch(setActiveStep3(activeStep3 + 1));
      }
    }
  };

  const handleBack = () => {
    if (activeStep3 > 0) {
      dispatch(setActiveStep3(activeStep3 - 1));
    } else if (activeStep2 > 0) {
      dispatch(setActiveStep2(activeStep2 - 1));
    } else if (activeStep > 0) {
      dispatch(setActiveStep(activeStep - 1));
    }
  };
  console.log("postOpportunity", postOpportunity);
  const handleCreateOrUpdate = async (isUpdate = false) => {
    setLoading(true);
    try {
      const modifyOpportunity = {
        ...postOpportunity,
        ...(jobDetailsById.job_type === JobTypeEnum.DIRECT_HIRE && {
          job_hours_per_week_id: "119636",
        }),
      } as PostOpportunityProps;
      const response = isUpdate
        ? await JobsService.updateOpportunity(modifyOpportunity)
        : await JobsService.createOpportunity(modifyOpportunity);

      if (response) {
        const getResponse = await JobsService.getJobDetailsById({
          nid: response.data.nid,
        });
        dispatch(setJobDetailsById(getResponse.results[0]));
      }
      handleNext();
      dispatch(setIsPosted(true));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    handleCreateOrUpdate(jobId ? true : false);
  };

  const progress = (activeStep / getStep1(jobDetailsById.work_mode)) * 100;
  const progress2 = (activeStep2 / 6) * 100;
  const progress3 = (activeStep3 / 8) * 100;

  const isDirectHire = jobDetailsById.job_type === JobTypeEnum.DIRECT_HIRE;

  const checkSalaryLowHigh = () => {
    if (
      Number(postOpportunity.job_salary_low) >=
      Number(postOpportunity.job_salary_high)
    ) {
      // dispatch(
      //   setBudgetError(
      //     `Please enter enter less than amount from salary rate high`
      //   )
      // );
      return true;
    } else {
      // dispatch(setBudgetError(""));
      return false;
    }
  };

  const checkSalaryLowMin = () => {
    if (Number(postOpportunity.job_salary_low) < 100) {
      // dispatch(setBudgetError("Please enter enter greter than $ 100 amount"));
      return true;
    } else {
      // dispatch(setBudgetError(""));
      return false;
    }
  };

  const checkSalaryHighMax = () => {
    if (Number(postOpportunity.job_salary_high) > 5000000) {
      // dispatch(setBudgetError("Please enter enter less than $ 5000000 amount"));
      return true;
    } else {
      // dispatch(setBudgetError(""));
      return false;
    }
  };

  const checkPayRateLowHigh = () => {
    if (
      Number(postOpportunity.job_pay_rate_low) >=
      Number(postOpportunity.job_pay_rate_high)
    ) {
      // dispatch(
      //   setBudgetError(`Please enter enter less than amount from pay rate high`)
      // );
      return true;
    } else {
      // dispatch(setBudgetError(""));
      return false;
    }
  };

  const checkPayRateLowMin = () => {
    if (Number(postOpportunity.job_pay_rate_low) < 1) {
      // dispatch(setBudgetError("Please enter enter greter than $ 1 amount"));
      return true;
    } else {
      // dispatch(setBudgetError(""));

      return false;
    }
  };

  const checkPayRateHighMax = () => {
    if (Number(postOpportunity.job_pay_rate_high) > 250) {
      // dispatch(setBudgetError("Please enter enter less than $ 100 amount"));
      return true;
    } else {
      // dispatch(setBudgetError(""));

      return false;
    }
  };

  const budgetDisable = () => {
    if (activeStep3 !== 4) return false;

    return (
      (isDirectHire &&
        (checkSalaryLowHigh() ||
          checkSalaryLowMin() ||
          checkSalaryHighMax())) ||
      (!isDirectHire &&
        (checkPayRateLowHigh() ||
          checkPayRateLowMin() ||
          checkPayRateHighMax()))
    );
  };

  const disabled = () => {
    const conditions = [
      !postOpportunity.job_title.length && activeStep2 === 2,
      !postOpportunity.job_decription.length && activeStep2 === 3,
      !postOpportunity.job_what_you_ll_do.length && activeStep2 === 3,
      !postOpportunity.job_start_date.length && activeStep2 === 6,
      !postOpportunity.job_end_date.length && activeStep2 === 6,
      !postOpportunity.job_type_id.length && activeStep === 4,
      !postOpportunity.job_category_id.length && activeStep === 3,
      !postOpportunity.job_work_mode_id.length && activeStep === 7,
      !postOpportunity.job_address.country.length &&
        activeStep === getStep1(jobDetailsById.work_mode),
      selectedConfirm.length !== 4 && activeStep3 === 8,
      !postOpportunity.job_project_id.length && activeStep === 2,
    ];
    return conditions.some(Boolean);
  };

  return (
    <>
      <StepComponent
        isUpdate={jobId ? true : false}
        isLoading={loading}
        steps={[progress, progress2, progress3]}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        isActiveStep={activeStep === 1}
        isShowSubmitBtn={
          activeStep === getStep1(jobDetailsById.work_mode) &&
          activeStep2 === 6 &&
          activeStep3 === 8
        }
        isDisabled={
          isDisable ||
          budgetDisable() ||
          disabled() ||
          (activeStep === getStep1(jobDetailsById.work_mode) &&
            activeStep2 === 6 &&
            activeStep3 > 8)
        }
      />
    </>
  );
};

export default Stepper;

export const getStepCondition = (type) => {
  return 6;
};
