import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  ContractsTabsEnums,
  useContracts,
} from "../../../providers/ContractsProvider";
import { COLORS } from "../../../theme";
import JobsSwitch from "../../Dashboard/PostedJobs/JobsSwitch";

const ContractsTabs = () => {
  const { selectedContractTab, setSelectedContractTab } = useContracts();

  return (
    <>
      <AtsTitleText
        text="Contracts"
        fs={24}
        fw={600}
        textColor={COLORS.BLACK}
      />
      <AtsRowBetween sx={{ pt: "22px", pb: "19px" }}>
        <>
          <JobsSwitch
            width="120px"
            jobs={Object.values(ContractsTabsEnums).map((value) => ({
              title: value,
              type: value,
            }))}
            active={selectedContractTab}
            onChange={(value) => {
              setSelectedContractTab(value as ContractsTabsEnums);
            }}
          />
        </>
      </AtsRowBetween>
    </>
  );
};

export default ContractsTabs;
