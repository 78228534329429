import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetJobgsListParamsRequest,
  GetJobTitleParamsRequest,
  JobIndustriesResponse,
  JobListing,
} from "../types/jobsTypes";
import { API_GATEWAY } from "../utils/url";
import { PaginatedResponse } from "../types/commonType";

const Endpoint = {
  BASE: "ats/api/v1/jobs/get/",
  JOB_TITLES: "ats/api/v1/job-titles/get",
};

export const jobsServices = createApi({
  reducerPath: "jobsServices",
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getJobsList: builder.query<
      PaginatedResponse<JobListing>,
      GetJobgsListParamsRequest
    >({
      query: (params) => {
        return {
          url: `${Endpoint.BASE}`,
          method: "GET",
          params,
        };
      },
    }),
    getJobTitles: builder.query<
      PaginatedResponse<JobIndustriesResponse>,
      GetJobTitleParamsRequest
    >({
      query: (params) => {
        const { name } = params;
        if (name?.length) {
          params.name = name;
        }
        return {
          url: Endpoint.JOB_TITLES,
          params,
        };
      },
    }),
  }),
});

export const { useGetJobsListQuery, useGetJobTitlesQuery } = jobsServices;
