import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import { BORDERS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";

const LoadingProfileState = () => {
  return (
    <>
      <Stack gap={"20px"}>
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="rounded" width={127} height={30} />
        <Stack
          pb={"30px"}
          sx={{
            borderBottom: BORDERS.GRAY,
          }}
        >
          <Grid container columnSpacing={"30px"} rowSpacing={"10px"}>
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <AtsRow
                    gap={"12px"}
                    sx={{
                      px: "10px",
                      py: "10px",
                    }}
                  >
                    <>
                      <Skeleton variant="circular" width={25} height={25} />
                      <Skeleton variant="text" width={100} height={20} />
                    </>
                  </AtsRow>
                </Grid>
              ))}
          </Grid>
        </Stack>
        <Stack gap={"24px"}>
          <Stack gap={"13px"}>
            <Skeleton variant="text" width={200} height={30} />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"20px"}
            flexWrap={"wrap"}
          >
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={120}
                  height={40}
                  sx={{
                    borderRadius: "50px",
                  }}
                />
              ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default LoadingProfileState;
